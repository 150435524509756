import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Controller, useForm, FormProvider } from "react-hook-form";

import Input from "../../../../components/Input";
import { MandatoryLabel } from "../../../../components/MandatoryLabel";
import { getMandatoryFieldErrors } from "../../../../utils";
import { setDefaultValues } from "../../../../utils/wfpManagerConfigurationUtils";
import Loading from "../../../../components/Loading";
import Select from "../../../../components/Select";
import Checkbox from "../../../../components/Checkbox";

export const resolver = (values, t) => {
  let errors = {};
  if(values.name === "" || !values.name){
    errors.name = t("traderDetails.form.mandatoryFieldBlankError");
  }
  if(!values.type || (values.type.value === "" || values.type.value === null)){
    errors.type = t("traderDetails.form.mandatoryFieldBlankError");
  }
  return {values, errors};
};

const Form = ({
  initialValues,
  t,
  modalOptions,
  buttonRef,
  onSubmit,
  isFetching,
  administrativeAreas,
  administrativeAreaTypes,
}) => {

  const methods = useForm({resolver, context: t});
  const { control, handleSubmit, setValue, getValues, formState: { errors }, reset } = methods;

  useEffect(() => {
    setDefaultValues(modalOptions, initialValues, setValue, reset);
  }, [initialValues]);

  useEffect(() => {
    reset();
  }, [modalOptions.id]);

  if(isFetching){
    return <Loading isVisible />;
  }

  const defaultValues = {
    parent: {
      label: t("administrativeAreaTypes.noParent"),
      value: null,
    },
    name: "",
    active: false,
    admin_level: undefined,
  };

  const generateAreaOptions = (list, areaTypes) => {
    const options = list.filter(item => {
      if(initialValues){
        // Do not show self in list of parent options
        return item.id !== initialValues.id;
      }else{
        return true;
      }
    }).map((item) => {
      const areaType = areaTypes.find(type => type.id === item.type);
      return {
        label: `${item.name}${areaType ? ` (${areaType.name})` : ""}`,
        value: item.id,
        level: item.level,
      };
    },
    ).sort((a, b) => a.level > b.level ? 1 : -1);

    options.unshift({
      label: t("administrativeAreaTypes.noParent"),
      value: null,
    });
    return options;
  };

  const generateTypeOptions = (list) => {
    const options = list.map((item) => (
      {
        label: `${item.name}`,
        value: item.id,
      }),
    );
    return options;
  };

  const administrativeAreasOptions = generateAreaOptions(administrativeAreas, administrativeAreaTypes);

  const administrativeAreaTypesOptions = generateTypeOptions(administrativeAreaTypes);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          render={({ field, fieldState }) => {
            return (
              <Input
                label={<MandatoryLabel label={t("configuration.common.name")} />}
                id={"input-name"}
                name={"name"}
                isDisabled={modalOptions.type === "view"}
                input={field}
                meta={fieldState}
                errorMessage={getMandatoryFieldErrors(errors, t, getValues, "name")}
              />
            );
          }}
          defaultValue={defaultValues.name}
          name="name"
        />
        <Controller
          control={control}
          render={({ field }) => {
            return (
              <Select
                label={<MandatoryLabel label={t("administrativeAreaTypes.parent")} />}
                id={"input-parent"}
                name={"parent"}
                isDisabled={modalOptions.type === "view"}
                input={field}
                options={administrativeAreasOptions}
              />
            );
          }}
          defaultValue={defaultValues.parent}
          name="parent"
        />
        <Controller
          control={control}
          render={({ field }) => {
            return (
              <Select
                label={<MandatoryLabel label={t("administrativeAreas.type")} />}
                id={"input-type"}
                name={"type"}
                isDisabled={modalOptions.type === "view"}
                input={field}
                options={administrativeAreaTypesOptions}
                errorMessage={getMandatoryFieldErrors(errors, t, getValues, "type")}
              />
            );
          }}
          defaultValue={defaultValues.type}
          name="type"
        />
        <Controller
          control={control}
          render={({ field, fieldState }) => {
            return (
              <Checkbox
                label={t("configuration.common.active")}
                id={"active"}
                name={"active"}
                defaultChecked={defaultValues.active}
                isDisabled={modalOptions.type === "view"}
                input={field}
                meta={fieldState}
              />
            );
          }}
          defaultValue={defaultValues.active}
          name="active"
        />
        <button style={{ display: "none" }} ref={buttonRef} />
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  modalOptions: PropTypes.object.isRequired,
  fieldErrors: PropTypes.object,
  initialValues: PropTypes.object,
  t: PropTypes.func.isRequired,
  buttonRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.func.isRequired,
  administrativeAreas: PropTypes.array,
  administrativeAreaTypes: PropTypes.array,
};

export default withTranslation()(Form);
