import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PageStandard from "../../components/PageStandard";
import { getDeletedItemName, valueOrDefault } from "../../utils";
import List from "./components/List";
import PropTypes from "prop-types";
import { commodityQualityGradesDetailsReset, deleteCommodityQualityGradesDetails, listCommodityQualityGrades, listCommodityQualityGradesReset } from "../../actions";
import CommodityQualityGradesModal from "./components/Modal";
import Modal from "../../components/Modal";
// import ButtonsWrapper from "../../components/ButtonsWrapper";
// import Button from "../../components/Button";
// import { iconAdd } from "@wfp/icons";

export const WfpManagerCommodityQualityGradesView = ({
  isFetching,
  errorMessage,
  commodityQualityGrades,
  page,
  count,
  listCommodityQualityGrades,
  listCommodityQualityGradesReset,
  // commodityQualityGradesDetailsReset,
  deleteCommodityQualityGradesDetails,
}) => {
  const { t } = useTranslation();
  const [modalOptions, setModalOptions] = useState({isOpen: false, id: undefined, type: undefined});
  const [deleteModalOptions, setDeleteModalOptions] = useState({isOpen: false, id: undefined});

  // On component mount the data are fetched
  useEffect(() => {
    listCommodityQualityGrades();
    return () => {
      listCommodityQualityGradesReset();
    };
  }, []);

  // Commented out due to: User Story 200864: WFP Manager web - Hide commodity quality grade CTAs
  //   commodityQualityGradesDetailsReset();
  //   setModalOptions({isOpen: true, id: "add", type: "create"});
  // };

  const onDeleteSubmit = () => {
    deleteCommodityQualityGradesDetails(deleteModalOptions.id);
    setDeleteModalOptions({isOpen: false, id: undefined});
    listCommodityQualityGrades();
  };

  return (
    <PageStandard
      // additional={(
      //   <ButtonsWrapper>
      //     <Button icon={iconAdd} onClick={onClickAdd}>
      //       {t("common.add") + " " + t("configuration.item.commodityQualityGrades")}
      //     </Button>
      //   </ButtonsWrapper>
      // )}
      title={t("configuration.item.commodityQualityGrades")}
    >
      <List
        {...{
          isFetching,
          errorMessage,
          commodityQualityGrades,
          page,
          count,
          setModalOptions,
          setDeleteModalOptions,
        }}
      />
      <CommodityQualityGradesModal
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
      />
      <Modal
        isOpen={deleteModalOptions.isOpen}
        onValidate={onDeleteSubmit}
        title={t("common.delete") + " " + t("configuration.item.commodityQualityGrades")}
        danger={true}
        onCancel={() => setDeleteModalOptions({isOpen: false, id: undefined})}
        isLoading={false}
      >
        {t("configuration.common.deleteText") + getDeletedItemName(deleteModalOptions.id, commodityQualityGrades, "name")}
      </Modal>
    </PageStandard>
  );
};

WfpManagerCommodityQualityGradesView.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  commodityQualityGrades: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  listCommodityQualityGrades: PropTypes.func.isRequired,
  listCommodityQualityGradesReset: PropTypes.func.isRequired,
  commodityQualityGradesDetailsReset: PropTypes.func.isRequired,
  deleteCommodityQualityGradesDetails: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    commodityQualityGrades: state.listCommodityQualityGrades.data.results,
    isFetching: state.listCommodityQualityGrades.isFetching,
    errorMessage: state.listCommodityQualityGrades.errorMessage,
    page: 1,
    count: valueOrDefault(state.listCommodityQualityGrades.data.results.length, 0),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    listCommodityQualityGrades: () => dispatch(listCommodityQualityGrades()),
    listCommodityQualityGradesReset: () => dispatch(listCommodityQualityGradesReset()),
    commodityQualityGradesDetailsReset: () => dispatch(commodityQualityGradesDetailsReset()),
    deleteCommodityQualityGradesDetails: id => dispatch(deleteCommodityQualityGradesDetails(id)),
  };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(WfpManagerCommodityQualityGradesView);
