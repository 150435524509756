// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container_inW182F9AWb4MjZzZXZo .wfp--input-group-inside {\n  flex-direction: column;\n}\n.style_container_inW182F9AWb4MjZzZXZo .radioButtonWrapper {\n  margin-bottom: 8px;\n}", "",{"version":3,"sources":["webpack://./src/components/Radio/style.scss"],"names":[],"mappings":"AACE;EACE,sBAAA;AAAJ;AAGE;EACE,kBAAA;AADJ","sourcesContent":[".container {\n  :global(.wfp--input-group-inside) {\n    flex-direction: column;\n  }\n\n  :global(.radioButtonWrapper) {\n    margin-bottom: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container_inW182F9AWb4MjZzZXZo"
};
export default ___CSS_LOADER_EXPORT___;
