import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import { iconChevronRight, iconClose } from "@wfp/icons";

import Module from "../../../../components/Module";
import Loading from "../../../../components/Loading";
import FormWrapper from "../../../../components/FormWrapper";
import { displayErrorMessage } from "../../../../utils";
import { Grid, Row, Col } from "../../../../components/Grid";
import style from "./style.scss";
import MultilineInput from "../../../../components/MultilineInput";
import Spacer from "../../../../components/Spacer";
import Icon from "../../../../components/Icon";
import Button from "../../../../components/Button";
import ButtonsWrapper from "../../../../components/ButtonsWrapper";
import Modal from "../../../../components/Modal";
import {withTranslation} from "react-i18next";

// The form of a market offer send sms feature
export const Form = (
  {
    isFetching,
    errorMessage,
    marketOffer,
    onClickSendSms,
    selectedAggregator,
    onSelectAggregator,
    remainingAggregators,
    onUnselectAggregator,
    fieldsErrorMessage,
    isSuccessModalOpen,
    onConfirmSuccessModal,
    t,
  },
) => {

  if (isFetching) {
    return <Loading isVisible />;
  }

  if (errorMessage) {
    return (
      <>
        {displayErrorMessage(errorMessage)}
      </>
    );
  }

  const price = marketOffer.price +
    ` ${marketOffer.currency.code}/${marketOffer.unit_of_measure.code}`;

  return (
    <>
      <Grid>
        <Row>
          <Col sm={3}>
            <Module title={t("marketOfferSms.form.title")}>
              <span className={style.label}>{t("marketOfferSms.form.trader")}</span>
              <span className={style.value}>{marketOffer.trader.name || "--"}</span>
              <span className={style.label}>{t("marketOfferSms.form.commodity")}</span>
              <span className={style.value}>{marketOffer.buy_commodity.name || "--"}</span>
              <span className={style.label}>{t("marketOfferSms.form.minQuantity")}</span>
              <span className={style.value}>
                {`${marketOffer.minimum_quantity} ${marketOffer.unit_of_measure.code}` || "--"}
              </span>
              <span className={style.label}>{t("marketOfferSms.form.currency")}</span>
              <span className={style.value}>{marketOffer.currency.name || "--"}</span>
              <span className={style.label}>{t("marketOfferSms.form.price")}</span>
              <span className={style.value}>{price || "--"}</span>
              <span className={style.label}>{t("marketOfferSms.form.expiration")}</span>
              <span className={style.value}>{marketOffer.valid_until || "--"}</span>
              <span className={style.label}>{t("marketOfferSms.form.deliveryLocation")}</span>
              <span className={style.value}>{marketOffer.delivery_location || "--"}</span>
            </Module>
          </Col>
          <Col sm={9}>
            <Module
              title={t("marketOfferSms.form.yourMessage")}
              footer={
                <ButtonsWrapper>
                  <Button onClick={onClickSendSms}>{t("marketOfferSms.form.sendSMS")}</Button>
                </ButtonsWrapper>
              }
            >
              {displayErrorMessage(fieldsErrorMessage)}
              <Grid>
                <Row>
                  <Col sm={12}>
                    <FormWrapper>
                      <Field
                        component={MultilineInput}
                        name="text"
                        id="text"
                        label={t("marketOfferSms.form.textOfTheSMS")}
                        placeholder={t("marketOfferSms.form.typeYourMessageHere")}
                        maxChars={150}
                      />
                    </FormWrapper>
                  </Col>
                </Row>
              </Grid>
              <span className={style.aggregatorsLabel}>
                {t("marketOfferSms.form.selectAggregators")}
              </span>
              <Grid>
                <Row>
                  <Col sm={6}>
                    <div className={style.list}>
                      {
                        remainingAggregators.map(aggregator => (
                          <div
                            key={aggregator.id}
                            onClick={() => onSelectAggregator(aggregator)}
                          >
                            <span>{aggregator.name}</span>
                            <Icon
                              icon={iconChevronRight}
                              fill="#bbbbbb"
                              width="0.75rem"
                              height="0.75rem"
                            />
                          </div>
                        ))
                      }
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className={style.list}>
                      {
                        <div
                          key={selectedAggregator.id}
                          onClick={() => onUnselectAggregator(selectedAggregator)}
                        >
                          <span>{selectedAggregator.name}</span>
                          <Icon
                            icon={iconClose}
                            fill="#bbbbbb"
                            width="0.75rem"
                            height="0.75rem"
                          />
                        </div>
                      }
                    </div>
                  </Col>
                </Row>
              </Grid>
              <Spacer height="2rem" />
            </Module>
          </Col>
        </Row>
      </Grid>
      <Modal
        isOpen={isSuccessModalOpen}
        onValidate={onConfirmSuccessModal}
        title={t("marketOfferSms.form.smsHasBeenSent")}
        hideCancelButton
      >
        {t("marketOfferSms.form.successMessage")}
      </Modal>
    </>
  );
};

// propTypes for the Form component
Form.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  marketOffer: PropTypes.object.isRequired,
  onClickSendSms: PropTypes.func.isRequired,
  selectedAggregator: PropTypes.object.isRequired,
  remainingAggregators: PropTypes.array.isRequired,
  onSelectAggregator: PropTypes.func.isRequired,
  onUnselectAggregator: PropTypes.func.isRequired,
  fieldsErrorMessage: PropTypes.string.isRequired,
  isSuccessModalOpen: PropTypes.bool.isRequired,
  onConfirmSuccessModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

// defaultProps for the Form component
Form.defaultProps = {
  errorMessage: "",
};

// eslint-disable-next-line max-len
export default reduxForm({ form: "marketOfferSms", enableReinitialize: true })(withTranslation()(Form));
