import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Controller, useForm, FormProvider } from "react-hook-form";

import Input from "../../../../components/Input";
import Checkbox  from "../../../../components/Checkbox";
import { MandatoryLabel } from "../../../../components/MandatoryLabel";
import { getMandatoryFieldErrors } from "../../../../utils";
import { setDefaultValues } from "../../../../utils/wfpManagerConfigurationUtils";
import Loading from "../../../../components/Loading";

const defaultValues = {
  text: "",
  active: false,
};

export const resolver = (values, t) => {
  let errors = {};
  if(values.text === "" || !values.text){
    errors.text = t("traderDetails.form.mandatoryFieldBlankError");
  }
  return {values, errors};
};

const Form = ({ initialValues, t, modalOptions, buttonRef, onSubmit, isFetching }) => {

  const methods = useForm({resolver, context: t});
  const { control, handleSubmit, setValue, getValues, formState: { errors }, reset } = methods;

  useEffect(() => {
    setDefaultValues(modalOptions, initialValues, setValue, reset);
  }, [initialValues]);

  useEffect(() => {
    reset();
  }, [modalOptions.id]);

  if(isFetching){
    return <Loading isVisible />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          render={({ field, fieldState }) => {
            return (
              <Input
                name="text"
                id="input-text"
                label={<MandatoryLabel label={t("configuration.common.name")} />}
                errorMessage={getMandatoryFieldErrors(errors, t, getValues, "text")}
                isDisabled={modalOptions.type === "view"}
                input={field}
                meta={fieldState}
              />
            );
          }}
          defaultValue={defaultValues.text}
          name="text"
        />
        <Controller
          control={control}
          render={({ field, fieldState }) => {
            return (
              <Checkbox
                label={t("configuration.common.active")}
                id={"active"}
                name={"active"}
                defaultChecked={defaultValues.active}
                isDisabled={modalOptions.type === "view"}
                input={field}
                meta={fieldState}
              />
            );
          }}
          defaultValue={defaultValues.active}
          name="active"
        />
        <button style={{ display: "none" }} ref={buttonRef} />
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  modalOptions: PropTypes.object.isRequired,
  fieldErrors: PropTypes.object,
  initialValues: PropTypes.object,
  t: PropTypes.func.isRequired,
  buttonRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.func.isRequired,
};

export default withTranslation()(Form);
