import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { iconAdd } from "@wfp/icons";
import {
  listMarketOffers,
  listMarketOffersReset,
  listTraderRepresentatives,
  listTraderRepresentativesReset,
  listTraders,
  listTradersReset,
  tradersCurrentPage,
} from "../../actions";
import Button from "../../components/Button";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import PageStandard from "../../components/PageStandard";
import { getCountryObjectFromCache, valueOrDefault } from "../../utils";
import { adaptTraderRepresentatives, adaptTraders } from "../../utils/adapters";
import TraderDetailsModal from "../TraderDetailsModel";
import List from "./components/List";

export const Traders = ({
  isFetching,
  listTradersErrorMessage,
  listMarketOffersErrorMessage,
  listTraderRepresentativesErrorMessage,
  traders,
  representatives,
  offers,
  count,
  listMarketOffers,
  listMarketOffersReset,
  listTraders,
  listTradersReset,
  listTraderRepresentatives,
  listTraderRepresentativesReset,
  history,
  tradersCurrentPage,
}) => {
  const { t } = useTranslation();
  const [selectedTrader, setSelectedTrader] = useState({});

  const [paginationState, setPaginationState] = useState({
    page: 0,
    pageSize: 10,
    name: undefined,
  });

  useEffect(() => {
    listMarketOffers();
    listTraderRepresentatives();
    return () => {
      listMarketOffersReset();
      listTraderRepresentativesReset();
      tradersCurrentPage(0);
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setPaginationState({
      page: params.get("page")
        ? parseInt(params.get("page"), 10)
        : paginationState.page,
      pageSize:
        params.get("pageSize") && parseInt(params.get("pageSize"), 10) > 0
          ? parseInt(params.get("pageSize"), 10)
          : paginationState.pageSize,
    });
  }, [location.search]);

  useEffect(() => {
    listTraders(
      paginationState.page,
      paginationState.pageSize,
    );

    return () => {
      listTradersReset();
    };
  }, [paginationState]);

  const onPageChange = (page) => {
    const params = new URLSearchParams(location.search);

    // Step 2: Update or add new parameters
    params.set("page", page.page - 1);
    params.set("pageSize", page.pageSize);

    // Step 3: Navigate to the updated URL
    history.push(`${location.pathname}?${params.toString()}`);
  };

  const onClickAdd = () => {
    history.push("/wfpManager/traderDetails");
  };

  const onClickEdit = (id) => {
    history.push(`/wfpManager/traderDetails/${id}`);
  };

  const onClickView = record => {
    setSelectedTrader(record);
  };

  return (
    <PageStandard
      title={
        `${t("traders.title")} ${t("common.in")} ${getCountryObjectFromCache().name}`
      }
      additional={(
        <ButtonsWrapper>
          <Button icon={iconAdd} onClick={onClickAdd}>
            {t("traders.newTrader")}
          </Button>
        </ButtonsWrapper>
      )}
    >
      <List
        {...{
          isFetching,
          listTradersErrorMessage,
          listMarketOffersErrorMessage,
          listTraderRepresentativesErrorMessage,
          traders,
          page: paginationState.page,
          pageSize: paginationState.pageSize,
          onPageChange,
          count,
          onClickView,
          onClickEdit,
          offers,
        }}
      />
      <TraderDetailsModal
        hideActions
        isOpen={selectedTrader.id}
        trader={selectedTrader}
        isFetchingTraderRepresentatives={isFetching}
        representatives={representatives.filter(
          rep => rep.organization.id === selectedTrader.id,
        )}
        onValidate={() => setSelectedTrader({})}
        administrators={traders}
        onClickEdit={() => onClickEdit(selectedTrader.id)}
        offers={offers}
      />
    </PageStandard>
  );
};

// propTypes for the Traders component
Traders.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  listMarketOffersErrorMessage: PropTypes.string.isRequired,
  listTradersErrorMessage: PropTypes.string.isRequired,
  listTraderRepresentativesErrorMessage: PropTypes.string.isRequired,
  traders: PropTypes.array.isRequired,
  representatives: PropTypes.array.isRequired,
  offers: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  listMarketOffers: PropTypes.func.isRequired,
  listMarketOffersReset: PropTypes.func.isRequired,
  listTraders: PropTypes.func.isRequired,
  listTradersReset: PropTypes.func.isRequired,
  listTraderRepresentatives: PropTypes.func.isRequired,
  listTraderRepresentativesReset: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  tradersCurrentPage: PropTypes.func.isRequired,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = state => {
  return {
    // Traders management
    isFetching: (
      state.listTraders.isFetching
      || state.listMarketOffers.isFetching
      || state.listTraderRepresentatives.isFetching
    ),
    listMarketOffersErrorMessage: state.listMarketOffers.errorMessage,
    listTradersErrorMessage: state.listTraders.errorMessage,
    listTraderRepresentativesErrorMessage: state.listTraderRepresentatives.errorMessage,
    traders: adaptTraders(state.listTraders.data.results),
    representatives: adaptTraderRepresentatives(state.listTraderRepresentatives.data.results),
    offers: state.listMarketOffers.data.results,
    page: state.tradersCurrentPage.number,
    count: valueOrDefault(state.listTraders.data.count, 0),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    listMarketOffers: () => dispatch(listMarketOffers()),
    listMarketOffersReset: () => dispatch(listMarketOffersReset()),
    listTraders: (page, page_size) => dispatch(listTraders({ page: page + 1, page_size})),
    listTradersReset: () => dispatch(listTradersReset()),
    listTraderRepresentatives: () => dispatch(listTraderRepresentatives()),
    listTraderRepresentativesReset: () => dispatch(listTraderRepresentativesReset()),
    tradersCurrentPage: page => dispatch(tradersCurrentPage(page)),
  };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Traders));
