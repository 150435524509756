import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { iconArrowUp, iconArrowDown } from "@wfp/icons";
import { v4 as uuidv4 } from "uuid";

import {
  addAggregator,
  addAggregatorReset,
  aggregatorDetails,
  aggregatorDetailsReset,
  updateAggregator,
  updateAggregatorReset,
  listAggregatorAdministrators,
  listAggregatorAdministratorsReset,
  addAggregatorAdministrator,
  addAggregatorAdministratorReset,
  updateAggregatorAdministrator,
  updateAggregatorAdministratorReset,
  deleteAggregatorAdministrator,
  deleteAggregatorAdministratorReset,
  aggregatorAdministratorDetails,
  aggregatorAdministratorDetailsReset,
  listLanguages,
  uploadFarmers,
  uploadFarmersReset,
  treeAdministrativeAreas,
  listOrgTypes,
  listOrgLegalStatuses,
  listAggregatorUmbrellaOrganizations,
} from "../../actions";
import PageStandard from "../../components/PageStandard";
import Form from "./components/Form";
import UploadFarmersModal from "./components/UploadFarmersModal";
import TermsConfirmationModal from "./components/TermsConfirmationModal";
import SuccessUploadFarmersModal from "./components/SuccessUploadFarmersModal";
import {
  getStatusErrorMessage,
  valueOrDefault,
  isEmpty,
  arrayToString,
  completeUrl,
  getCountryObjectFromCache,
  getValidationErrorMessage,
} from "../../utils";
import {
  adaptLegalStatuses,
  adaptOption,
  adaptAggregatorAdministrators,
  adaptGenders,
  adaptCardTypes,
  adaptOrganizationTypes,
  adaptLanguages,
  adaptAdministrativeAreas,
  adaptPhoneNumber,
  removeCountryCode,
  adaptUmbrellaOrganizations,
  adaptLanguageForInitialValues,
  adaptLocationForInitialValues,
} from "../../utils/adapters";
import { AGGREGATOR_TIERS, CARD_TYPES, GENDERS } from "../../constants";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";

// A function to adapt values entered in form to a format accepted by the server
export const adaptFromValues = (formValues) => {
  return {
    name: valueOrDefault(formValues.name, undefined),
    other_info: valueOrDefault(formValues.other_info, undefined),
    contact_email: valueOrDefault(formValues.contact_email, undefined),
    managed_individuals: valueOrDefault(formValues.managed_individuals, []),
    phone: adaptPhoneNumber(formValues.phone),
    legal_address: valueOrDefault(formValues.legal_address, undefined),
    physical_address: valueOrDefault(formValues.physical_address, undefined),
    district: valueOrDefault(formValues.district, undefined),
    location: valueOrDefault(
      formValues.location && formValues.location.value
        ? formValues.location.value
        : formValues.location,
      undefined,
    ),
    legal_registration_status: valueOrDefault(
      formValues.legal_registration_status &&
        formValues.legal_registration_status.value,
      undefined,
    ),
    year_of_establishment: valueOrDefault(
      formValues.year_of_establishment,
      undefined,
    ),
    org_type: valueOrDefault(
      formValues.org_type && formValues.org_type.value,
      undefined,
    ),
    tier: valueOrDefault(formValues.tier && formValues.tier.value, undefined),
    wings_vendor_id: valueOrDefault(formValues.wings_vendor_id, undefined),
    legally_recognized: valueOrDefault(
      formValues.legally_recognized,
      undefined,
    ),
    is_wfp_vendor: valueOrDefault(formValues.is_wfp_vendor, false),
    membership_fee:
      formValues.has_membership_structure && formValues.membership_fee_needed
        ? valueOrDefault(formValues.membership_fee, undefined)
        : undefined,
    language: valueOrDefault(
      formValues.language && formValues.language.value,
      undefined,
    ),
    membership_fee_needed:
      formValues.has_membership_structure &&
      valueOrDefault(formValues.membership_fee_needed, false),
    has_membership_structure: valueOrDefault(
      formValues.has_membership_structure,
      false,
    ),
    umbrella_organization: valueOrDefault(
      formValues.umbrella_organization &&
        formValues.umbrella_organization.value,
      undefined,
    ),
  };
};

// A function to adapt values entered in form to a format accepted by the server
export const adaptAdministratorFromValues = (formValues, aggregator) => ({
  person: {
    uuid: valueOrDefault(formValues.uuid, uuidv4()),
    first_name: valueOrDefault(formValues.first_name, undefined),
    middle_name: valueOrDefault(formValues.middle_name, undefined),
    last_name: valueOrDefault(formValues.last_name, undefined),
    contact_phone: valueOrDefault(
      adaptPhoneNumber(formValues.contact_phone),
      undefined,
    ),
    identification_card_number: valueOrDefault(
      formValues.identification_card_number,
      undefined,
    ),
    gender: valueOrDefault(
      formValues.gender && formValues.gender.value,
      undefined,
    ),
    year_of_birth: valueOrDefault(formValues.year_of_birth, undefined),
  },
  account_type: valueOrDefault(formValues.account_type, undefined),
  email: valueOrDefault(formValues.email, undefined),
  role: valueOrDefault(formValues.role, undefined),
  aggregator: aggregator,
});

// A function to adapt values received from server to
// a format compatible with the form
export const adaptInitialFromValues = (
  data,
  orgTypes,
  legalStatuses,
  aggregatorUmbrellaOrganizations,
  administrativeAreas,
) =>
  valueOrDefault(
    !isEmpty(data) && {
      ...data,
      legal_registration_status: adaptOption(
        data.legal_registration_status &&
          legalStatuses.find(
            (status) => status.value === data.legal_registration_status,
          ),
        "value",
        "label",
      ),
      tier: valueOrDefault(
        adaptOption(
          data.tier &&
            AGGREGATOR_TIERS.find((tier) => tier.value === data.tier),
          "value",
          "label",
        ),
        undefined,
      ),
      org_type: adaptOption(
        data.org_type &&
          orgTypes.find((status) => status.value === data.org_type),
        "value",
        "label",
      ),
      language: adaptLanguageForInitialValues(data.language),
      phone: data.phone && removeCountryCode(data.phone),
      umbrella_organization: adaptOption(
        data.umbrella_organization &&
          aggregatorUmbrellaOrganizations.find(
            (org) => org.value === data.umbrella_organization,
          ),
        "value",
        "label",
      ),
      location: adaptLocationForInitialValues(data, administrativeAreas),
    },
    {},
  );

// A function to adapt values received from server to
// a format compatible with the form
export const adaptAdministratorInitialFromValues = (data) =>
  valueOrDefault(
    data &&
      data.person && {
      uuid: valueOrDefault(data.person && data.person.uuid, uuidv4()),
      first_name: valueOrDefault(
        data.person && data.person.first_name,
        undefined,
      ),
      middle_name: valueOrDefault(
        data.person && data.person.middle_name,
        undefined,
      ),
      last_name: valueOrDefault(
        data.person && data.person.last_name,
        undefined,
      ),
      contact_phone: valueOrDefault(
        data.person && removeCountryCode(data.person.contact_phone),
        undefined,
      ),
      location: valueOrDefault(
        data.person && data.person.location,
        undefined,
      ),
      role: valueOrDefault(data.role, undefined),
      identification_card_number: valueOrDefault(
        data.person && data.person.identification_card_number,
        undefined,
      ),
      gender: valueOrDefault(
        data.person &&
            data.person.gender &&
            adaptCardTypes(GENDERS).find(
              (gender) => data.person.gender === gender.value,
            ),
        undefined,
      ),
      year_of_birth: valueOrDefault(data.person.year_of_birth, undefined),
      email: valueOrDefault(data.email, undefined),
    },
    {},
  );

// A function to adapt field errors for Administrator
// record to a flat object
export const adaptAdministratorFieldErrors = (error) => {
  if (isEmpty(error)) {
    return {};
  }

  if (Object.hasOwnProperty.call(error, "data")) {
    return valueOrDefault(
      {
        ...error.data.person,
        ...error.data,
      },
      {},
    );
  }

  return valueOrDefault(
    {
      ...error.person,
      ...error,
    },
    {},
  );
};

// A function to extract errors in farmer uploading
export const getUploadFarmersErrorMessage = (data) => {
  if (data && data.errors && Object.keys(data.errors).length) {
    let errorMessage = "";
    Object.keys(data.errors).map((key) => {
      if (data.errors[key].length) {
        errorMessage += (errorMessage !== "" && "; ") || "";
        errorMessage +=
          `Row N. ${parseInt(key) + 1}: ` + arrayToString(data.errors[key]);
      }
    });
    return errorMessage;
  }
  return "";
};

// A function to display tne number of farmers uploaded
export const getUploadFarmersInformationMessage = (data) => {
  if (data && data.farmers && data.farmers.length) {
    return `N. ${data.farmers.length} farmers have been created`;
  }
  return "";
};

// A function to get the error message from the upload farmer
// response if there is a payload in the response
export const getResponseErrorMessage = (data) => {
  if (data && data.data && data.data.errors) {
    return arrayToString(data.data.errors);
  }
};

// This component is used to display the detail
// of a Aggregator Organization
export const AggregatorDetails = ({
  isFetching,
  errorMessage,
  addAggregator,
  addAggregatorReset,
  updateAggregator,
  updateAggregatorReset,
  history,
  legalStatuses,
  id,
  aggregatorDetails,
  aggregatorDetailsReset,
  initialFormValues,
  listAggregatorAdministrators,
  listAggregatorAdministratorsReset,
  addAggregatorAdministrator,
  addAggregatorAdministratorReset,
  updateAggregatorAdministrator,
  updateAggregatorAdministratorReset,
  deleteAggregatorAdministrator,
  aggregatorAdministrators,
  isFetchingAdministrator,
  errorMessageAdministrator,
  aggregatorAdministratorDetails,
  aggregatorAdministratorDetailsReset,
  cardTypes,
  initialFormValuesAdministrator,
  isOnEditAdministrator,
  isFetchingDeleteAdministrator,
  errorMessageDeleteAdministrator,
  organizationTypes,
  listLanguages,
  languages,
  deleteAggregatorAdministratorReset,
  isFetchingUploadFarmers,
  errorMessageUploadFarmers,
  uploadFarmers,
  uploadFarmersReset,
  informationMessageUploadFarmers,
  treeAdministrativeAreas,
  administrativeAreas,
  listOrgTypes,
  listOrgLegalStatuses,
  fieldErrorsAdministrator,
  aggregatorUmbrellaOrganizations,
  listAggregatorUmbrellaOrganizations,
}) => {
  // A part of state used to display or hide the upload Farmers modal
  const [isUploadFarmersModalOpen, setIsUploadFarmersModalOpen] =
    useState(false);

  // A part of state used to display or hide the success upload Farmers modal
  const [isSuccessUploadFarmersModalOpen, setIsSuccessUploadFarmersModalOpen] =
    useState(false);

  // This state is used to manage the current in edit record
  const [inEditAdministrator, setInEditAdministrator] = useState(undefined);

  // A state to decide if the delete modal is open or closed
  const [isDeleteAdministratorModalOpen, setIsDeleteAdministratorModalOpen] =
    useState(false);

  // A state to store the current record to delete
  const [currentToDeleteAdministrator, setCurrentToDeleteAdministrator] =
    useState(undefined);

  const [isTermsConfirmationModalVisible, setTermsConfirmationModalVisibility] =
    useState(false);

  const { t } = useTranslation();

  // On component mount the data are fetched or read
  // on component unmount data are reset
  useEffect(() => {
    if (id) {
      aggregatorDetails(id);
      listAggregatorAdministrators(id);
    }
    listLanguages();
    const country = getCountryObjectFromCache();
    if (country) {
      treeAdministrativeAreas(country.id);
      listOrgLegalStatuses();
      listOrgTypes();
    }
    listAggregatorUmbrellaOrganizations();

    return () => {
      addAggregatorReset();
      updateAggregatorReset();
      aggregatorDetailsReset();
      listAggregatorAdministratorsReset();
      addAggregatorAdministratorReset();
      updateAggregatorAdministratorReset();
      aggregatorAdministratorDetailsReset();
      deleteAggregatorAdministratorReset();
      uploadFarmersReset();
    };
  }, []);

  // On click validate a requests are made
  // to save Aggregator, after saving the
  // Aggregators list is displayed
  const onClickValidateAndClose = (formValues) => {
    saveOrganization(true, formValues);
  };

  // This function is used to save a Aggregator Organization
  const saveOrganization = (close, formValues) => {
    const redirect = (id) => {
      if (close) {
        history.push("/wfpManager/aggregators");
      } else {
        history.push(`/wfpManager/aggregatorDetails/${id}`, {
          fromStep: 2,
        });
        history.go();
      }
    };
    const data = adaptFromValues(formValues);
    let promise;
    if (id) {
      promise = updateAggregator(id, data);
    } else {
      promise = addAggregator(data);
    }
    promise.then((result) => {
      if (result) {
        redirect(result.data.id);
      }
    });
  };

  // On click open Aggregator Administrator modal the modal is displayed
  const onClickAddAdministrator = () => {
    setInEditAdministrator(undefined);
  };

  const onClickCloseAdministrator = () => {
    setInEditAdministrator(undefined);
    aggregatorAdministratorDetailsReset();
  };

  // On click validate Aggregator Administrator modal the modal is closed
  // if request is successful
  const onClickValidateAdministratorModal = (formValues) => {
    const reset = () => {
      history.push(`/wfpManager/aggregatorDetails/${id}`, {
        fromStep: 2,
      });
      listAggregatorAdministrators(id);
      setInEditAdministrator(undefined);
    };
    const values = adaptAdministratorFromValues(formValues, id);
    if (inEditAdministrator) {
      updateAggregatorAdministrator(inEditAdministrator, values).then(
        (result) => {
          if (result) {
            reset();
          }
        },
      );
    } else {
      addAggregatorAdministrator(values).then((result) => {
        if (result) {
          reset();
        }
      });
    }
  };

  // On click save and continue editing
  // the record of Organization is saved
  // and the user is then redirected to edit page
  const onClickValidateAndContinue = (formValues) => {
    saveOrganization(false, formValues);
  };

  // On click edit on Aggregator Administrator the modal
  // is displayed to allow user modify the record
  const onClickEditAdministrator = (record) => {
    aggregatorAdministratorDetails(record.id).then((result) => {
      if (result) {
        updateAggregatorAdministratorReset();
        addAggregatorAdministratorReset();
        setInEditAdministrator(record.id);
      }
    });
  };

  // When user clicks edit Aggregator, it is redirected
  // to Aggregator edit page
  const onClickDeleteAdministrator = (record) => {
    setIsDeleteAdministratorModalOpen(true);
    setCurrentToDeleteAdministrator(record);
  };

  // When user confirm that it want to delete the record
  // the record is actually deleted
  const onClickConfirmDeleteAdministrator = () => {
    deleteAggregatorAdministrator(currentToDeleteAdministrator.id).then(
      (result) => {
        if (result) {
          setIsDeleteAdministratorModalOpen(false);
          setCurrentToDeleteAdministrator(undefined);
          listAggregatorAdministrators(id);
          deleteAggregatorAdministratorReset();
        }
      },
    );
  };

  // When user press cancel on delete modal
  // the modal is closed and the current record
  // to delete is reset
  const onClickCancelDeleteAdministrator = () => {
    setIsDeleteAdministratorModalOpen(false);
    setCurrentToDeleteAdministrator(undefined);
    deleteAggregatorAdministratorReset();
  };

  // On click validate upload Farmers modal the modal is closed
  // if request is successful
  const onClickValidateUploadFarmersModal = () => {
    setTermsConfirmationModalVisibility(false);
    const reset = () => {
      setIsSuccessUploadFarmersModalOpen(true);
      setIsUploadFarmersModalOpen(false);
    };
    const files = document.getElementById("file").files;
    if (files.length) {
      const data = new FormData();
      data.append("file", files[0]);
      uploadFarmers(data, `${id}/import_farmers_from_xls/`).then((result) => {
        if (
          result &&
          result.data &&
          !getUploadFarmersErrorMessage(result.data)
        ) {
          reset();
        }
      });
    }
  };

  // When user press cancel on upload
  // and the form is reset
  const onClickCloseUploadFarmersModal = () => {
    setIsUploadFarmersModalOpen(false);
    setTermsConfirmationModalVisibility(false);
    uploadFarmersReset();
  };

  // On click open upload Farmers modal the modal is displayed
  const onClickUploadFarmers = () => {
    setIsUploadFarmersModalOpen(true);
    uploadFarmersReset();
  };

  // On click download template the link is open to download the file
  const onClickDownloadTemplate = () => {
    const country = getCountryObjectFromCache();
    const link = `${completeUrl(process.env.API_BASE_URL)}country/${
      country.code
    }/api/farmer-import-template/`;
    window.location.assign(link);
  };

  // When user press ok on upload farmers success modal
  const onClickValidateSuccessUploadFarmersModal = () => {
    setIsSuccessUploadFarmersModalOpen(false);
    uploadFarmersReset();
  };

  const startFromStep =
    history.location &&
    history.location.state &&
    history.location.state.fromStep;

  return (
    <PageStandard
      title={t("aggregatorDetails.pageTitle")}
      additional={
        <ButtonsWrapper>
          <Button icon={iconArrowDown} onClick={onClickDownloadTemplate}>
            {t("aggregatorDetails.downloadFarmersTemplate")}
          </Button>
          <Button icon={iconArrowUp} onClick={onClickUploadFarmers}>
            {t("aggregatorDetails.uploadFarmers")}
          </Button>
        </ButtonsWrapper>
      }
    >
      <Form
        id={id}
        initialValues={initialFormValues}
        isFetching={isFetching}
        errorMessage={errorMessage}
        onClickValidateAndClose={onClickValidateAndClose}
        onClickValidateAndContinue={onClickValidateAndContinue}
        onClickAddAdministrator={onClickAddAdministrator}
        aggregatorAdministrators={aggregatorAdministrators}
        legalStatuses={legalStatuses}
        onClickEditAdministrator={onClickEditAdministrator}
        onClickDeleteAdministrator={onClickDeleteAdministrator}
        isDeleteAdministratorModalOpen={isDeleteAdministratorModalOpen}
        onClickConfirmDeleteAdministrator={onClickConfirmDeleteAdministrator}
        onClickCancelDeleteAdministrator={onClickCancelDeleteAdministrator}
        currentToDeleteAdministrator={currentToDeleteAdministrator}
        isFetchingDeleteAdministrator={isFetchingDeleteAdministrator}
        errorMessageDeleteAdministrator={errorMessageDeleteAdministrator}
        organizationTypes={organizationTypes}
        languages={languages}
        administrativeAreas={administrativeAreas}
        initialStep={startFromStep}
        setFieldError={() => {}}
        aggregatorUmbrellaOrganizations={aggregatorUmbrellaOrganizations}
        initialFormValuesAdministrator={initialFormValuesAdministrator}
        isFetchingAdministrator={isFetchingAdministrator}
        errorMessageAdministrator={errorMessageAdministrator}
        fieldErrorsAdministrator={fieldErrorsAdministrator}
        onClickValidateAdministratorModal={onClickValidateAdministratorModal}
        cardTypes={cardTypes}
        isOnEditAdministrator={isOnEditAdministrator}
        onClickCloseAdministrator={() => onClickCloseAdministrator()}
      />
      <UploadFarmersModal
        isFetching={isFetchingUploadFarmers}
        errorMessage={errorMessageUploadFarmers}
        informationMessage={informationMessageUploadFarmers}
        isModalOpen={isUploadFarmersModalOpen}
        onClickValidate={() => setTermsConfirmationModalVisibility(true)}
        onClickClose={onClickCloseUploadFarmersModal}
      />
      <TermsConfirmationModal
        isModalOpen={isTermsConfirmationModalVisible}
        onClickValidate={onClickValidateUploadFarmersModal}
        onClickClose={onClickCloseUploadFarmersModal}
      />
      <SuccessUploadFarmersModal
        informationMessage={informationMessageUploadFarmers}
        isModalOpen={isSuccessUploadFarmersModalOpen}
        onClickValidate={onClickValidateSuccessUploadFarmersModal}
      />
    </PageStandard>
  );
};

// propTypes for the AggregatorDetails component
AggregatorDetails.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  addAggregator: PropTypes.func.isRequired,
  addAggregatorReset: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  legalStatuses: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  aggregatorDetails: PropTypes.func.isRequired,
  aggregatorDetailsReset: PropTypes.func.isRequired,
  updateAggregator: PropTypes.func.isRequired,
  updateAggregatorReset: PropTypes.func.isRequired,
  initialFormValues: PropTypes.object.isRequired,
  listAggregatorAdministrators: PropTypes.func.isRequired,
  listAggregatorAdministratorsReset: PropTypes.func.isRequired,
  addAggregatorAdministrator: PropTypes.func.isRequired,
  addAggregatorAdministratorReset: PropTypes.func.isRequired,
  updateAggregatorAdministrator: PropTypes.func.isRequired,
  updateAggregatorAdministratorReset: PropTypes.func.isRequired,
  deleteAggregatorAdministrator: PropTypes.func.isRequired,
  aggregatorAdministrators: PropTypes.array.isRequired,
  isFetchingAdministrator: PropTypes.bool.isRequired,
  errorMessageAdministrator: PropTypes.string.isRequired,
  aggregatorAdministratorDetails: PropTypes.func.isRequired,
  aggregatorAdministratorDetailsReset: PropTypes.func.isRequired,
  cardTypes: PropTypes.array.isRequired,
  initialFormValuesAdministrator: PropTypes.object.isRequired,
  isOnEditAdministrator: PropTypes.bool.isRequired,
  isFetchingDeleteAdministrator: PropTypes.bool.isRequired,
  errorMessageDeleteAdministrator: PropTypes.string.isRequired,
  organizationTypes: PropTypes.array.isRequired,
  listLanguages: PropTypes.func.isRequired,
  languages: PropTypes.array.isRequired,
  treeAdministrativeAreas: PropTypes.func.isRequired,
  administrativeAreas: PropTypes.array.isRequired,
  deleteAggregatorAdministratorReset: PropTypes.func.isRequired,
  isFetchingUploadFarmers: PropTypes.bool.isRequired,
  errorMessageUploadFarmers: PropTypes.string.isRequired,
  uploadFarmers: PropTypes.func.isRequired,
  uploadFarmersReset: PropTypes.func.isRequired,
  informationMessageUploadFarmers: PropTypes.string.isRequired,
  listOrgTypes: PropTypes.func.isRequired,
  listOrgLegalStatuses: PropTypes.func.isRequired,
  fieldErrorsAdministrator: PropTypes.object,
  aggregatorUmbrellaOrganizations: PropTypes.func.isRequired,
  listAggregatorUmbrellaOrganizations: PropTypes.func.isRequired,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = (state, props) => {
  return {
    // Current on edit aggregator
    id: valueOrDefault(props.match.params.id, ""),
    initialFormValues: adaptInitialFromValues(
      state.aggregatorDetails.data,
      adaptOrganizationTypes(state.listOrgTypes.data.results),
      adaptLegalStatuses(state.listOrgLegalStatuses.data.results),
      adaptUmbrellaOrganizations(
        state.listAggregatorUmbrellaOrganizations.data.results,
      ),
      adaptAdministrativeAreas(
        state.treeAdministrativeAreas.data.administrative_areas,
      ),
    ),
    // Loading and errors properties
    isFetching:
      state.addAggregator.isLoading ||
      state.updateAggregator.isLoading ||
      state.aggregatorDetails.isFetching ||
      state.listLanguages.isFetching ||
      state.listAggregatorAdministrators.isFetching ||
      state.listOrgTypes.isFetching ||
      state.treeAdministrativeAreas.isFetching ||
      state.listAggregatorUmbrellaOrganizations.isFetching,
    errorMessage:
      state.listAggregatorAdministrators.errorMessage ||
      state.aggregatorDetails.errorMessage ||
      state.listLanguages.errorMessage ||
      state.treeAdministrativeAreas.errorMessage ||
      state.listOrgTypes.errorMessage ||
      getStatusErrorMessage(state.addAggregator.error) ||
      getStatusErrorMessage(state.updateAggregator.error) ||
      "",
    // Related entities lists
    legalStatuses: adaptLegalStatuses(state.listOrgLegalStatuses.data.results),
    cardTypes: adaptCardTypes(CARD_TYPES),
    genders: adaptGenders(GENDERS),
    organizationTypes: adaptOrganizationTypes(state.listOrgTypes.data.results),
    languages: adaptLanguages(state.listLanguages.data.results),
    administrativeAreas: adaptAdministrativeAreas(
      state.treeAdministrativeAreas.data.administrative_areas,
    ),
    // Aggregator Administrators list properties
    aggregatorAdministrators: adaptAggregatorAdministrators(
      state.listAggregatorAdministrators.data.results,
    ),
    // Properties related to Administrator modal
    isFetchingAdministrator:
      state.addAggregatorAdministrator.isLoading ||
      state.updateAggregatorAdministrator.isLoading ||
      state.aggregatorAdministratorDetails.isFetching,
    errorMessageAdministrator:
      getStatusErrorMessage(state.addAggregatorAdministrator.error) ||
      getValidationErrorMessage(state.addAggregatorAdministrator.error) ||
      getStatusErrorMessage(state.updateAggregatorAdministrator.error) ||
      "",
    // Form errors in Aggregator Administrator form
    fieldErrorsAdministrator:
      adaptAdministratorFieldErrors(state.addAggregatorAdministrator.error) ||
      adaptAdministratorFieldErrors(
        state.updateAggregatorAdministrator.error,
      ) ||
      {},
    // Current on edit Aggregator Administrator
    initialFormValuesAdministrator: adaptAdministratorInitialFromValues(
      state.aggregatorAdministratorDetails.data,
    ),
    // Current on edit Administrator
    isOnEditAdministrator:
      state.aggregatorAdministratorDetails.data.id !== undefined,
    // Delete Administrator properties
    isFetchingDeleteAdministrator:
      state.deleteAggregatorAdministrator.isLoading,
    errorMessageDeleteAdministrator:
      state.deleteAggregatorAdministrator.errorMessage || "",
    // Upload Farmers properties
    isFetchingUploadFarmers: state.uploadFarmers.isLoading,
    errorMessageUploadFarmers:
      getStatusErrorMessage(state.uploadFarmers.error) ||
      getResponseErrorMessage(state.uploadFarmers.error) ||
      getUploadFarmersErrorMessage(state.uploadFarmers.data) ||
      "",
    informationMessageUploadFarmers:
      getUploadFarmersInformationMessage(state.uploadFarmers.data) || "",
    // orgLegalStatuses: state.listOrgLegalStatuses.data.results,
    // orgTypes: state.listOrgTypes.data.results,
    aggregatorUmbrellaOrganizations:
      state.listAggregatorUmbrellaOrganizations.data.results.map((e) => ({
        ...e,
        value: e.id,
        label: e.name,
      })),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    addAggregator: (data) => dispatch(addAggregator(data)),
    addAggregatorReset: () => dispatch(addAggregatorReset()),
    updateAggregator: (id, data) => dispatch(updateAggregator(id, data)),
    updateAggregatorReset: () => dispatch(updateAggregatorReset()),
    aggregatorDetails: (id) => dispatch(aggregatorDetails(id)),
    aggregatorDetailsReset: () => dispatch(aggregatorDetailsReset()),
    listAggregatorAdministrators: (aggregator) =>
      dispatch(listAggregatorAdministrators({ aggregator })),
    listAggregatorAdministratorsReset: () =>
      dispatch(listAggregatorAdministratorsReset()),
    addAggregatorAdministrator: (data) =>
      dispatch(addAggregatorAdministrator(data)),
    addAggregatorAdministratorReset: () =>
      dispatch(addAggregatorAdministratorReset()),
    updateAggregatorAdministrator: (id, data) =>
      dispatch(updateAggregatorAdministrator(id, data)),
    updateAggregatorAdministratorReset: () =>
      dispatch(updateAggregatorAdministratorReset()),
    deleteAggregatorAdministrator: (id) =>
      dispatch(deleteAggregatorAdministrator(id)),
    aggregatorAdministratorDetails: (id) =>
      dispatch(aggregatorAdministratorDetails(id)),
    aggregatorAdministratorDetailsReset: () =>
      dispatch(aggregatorAdministratorDetailsReset()),
    listLanguages: () => dispatch(listLanguages()),
    deleteAggregatorAdministratorReset: () =>
      dispatch(deleteAggregatorAdministratorReset()),
    uploadFarmers: (data, appendUrl) =>
      dispatch(uploadFarmers(data, appendUrl)),
    uploadFarmersReset: () => dispatch(uploadFarmersReset()),
    treeAdministrativeAreas: (countryId) =>
      dispatch(
        treeAdministrativeAreas({}, `${countryId}/administrative_areas/`),
      ),
    listOrgTypes: () => dispatch(listOrgTypes()),
    listOrgLegalStatuses: () => dispatch(listOrgLegalStatuses()),
    listAggregatorUmbrellaOrganizations: () =>
      dispatch(listAggregatorUmbrellaOrganizations()),
  };
};

// The component uses the redux store
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(AggregatorDetails));
