import React from "react";
import { connect } from "react-redux";
import {
  formatDateTimeDefault,
  formatNumber,
  getCurrencySymbolFromCache
} from "../../../../../utils";
import {Offer, OfferStatus} from "../../OfferCard/interfaces";
import style from "./style.scss";
import moment from "moment";
import {NegotiationProposal} from "./offer-negotiation-proposal";
import {useTranslation} from "react-i18next";
import {OFFER_TYPES} from "../../../../../constants";
import {OfferCommentDto} from "../OfferCommentCard";
import {UserGroup} from "../../../../../utils/roles";
import Button from "../../../../../components/Button";
import {
  getLocalisedName,
  getQualityLocalName,
} from "../../../../../utils/i18n";
import exclamation from "./exclamation.png"
import { AddressHolder } from "../../OfferCard";

const NegotiationCard = ({
  proposal,
  offer,
  showActions,
  onRecallOffer,
  onSendBackExchange,
  onAcceptOffer,
  onCounterOffer,
  listMineAggregators,
  listTraders,
  customExchangeLocations,
  customExchangeLocationTypes,
  administrativeAreas
}: {
  proposal: NegotiationProposal;
  offer: Offer;
  onRecallOffer: () => Promise<void>;
  onAcceptOffer: (offer: Offer, negotiation: NegotiationProposal) => Promise<void>;
  onCounterOffer?: () => void;
  onSendBackExchange?: () => void;
  showActions: boolean;
  listMineAggregators: [],
  listTraders: [],
  customExchangeLocations: [],
  customExchangeLocationTypes: [],
  administrativeAreas: []
}) => {
  const { t } = useTranslation();

  if (!offer || !proposal) {
      return <></>;
  }

  const isOwn = (proposalOrComment: OfferCommentDto | NegotiationProposal) => {
    return proposalOrComment.user.roles.includes(
        UserGroup.TRADER_REPR
    );
  }

  const offerTypeHeader = () => {
    const translation = (offer.offer_type === OFFER_TYPES.MARKET_OFFER)
        ? t("marketOfferView.marketOffer")
        : t("marketOfferView.directOffer");
    return translation.toUpperCase();
  }

  const getNegotiationCardClass = () => {
    return !isOwn(proposal)
        ? style.othersProposalContainer
        : style.ownProposalContainer;
  }

  const offerCurrency = getCurrencySymbolFromCache();

  const formatPriceUnits = (): string => (
    `${offer.currency?.symbol}/${offer.unit_of_measure?.code}`
  );

  const unit = offer?.unit_of_measure?.code;
  const quality = t(getQualityLocalName(offer?.quality));
  const commodityLocalName = getLocalisedName(offer?.buy_commodity);
  const acceptNegotiation = async () => {
    await onAcceptOffer(offer, proposal);
  }

  const isExchangeDateValid = () => (
      !!proposal.planned_exchange_time
      && (new Date(proposal.planned_exchange_time) > new Date())
  );

  const renderDeliveryLocation = () => {
    const location = {
      delivery_location: proposal.delivery_location,
      custom_delivery_location: proposal.custom_delivery_location,
      custom_delivery_location_type: proposal.custom_delivery_location_type
    }
    let addressHolder: AddressHolder = { name: '', physical_address: '' };
    switch(location.delivery_location) {
      case "drop_at_trader":
        addressHolder = listTraders.find(({id}) => id === offer.trader.id) as unknown as AddressHolder
        return (
          <span>
            {addressHolder.name || "Name unavailable"}
            <br/>
            {addressHolder.physical_address  || "Address unavailable"}
          </span>
        );
      case "pick_from_aggregator":
        addressHolder = listMineAggregators.find(({ id }) => id === offer.tagged_aggregator.id) as unknown as AddressHolder
        return (
          <span>
            {addressHolder.name || "Name unavailable"}
            <br/>
            {addressHolder.physical_address  || "Address unavailable"}
          </span>
        );
      case "custom_exchange_location":
        const customExchangeLocation = customExchangeLocations.find((loc: {id: number}) => loc.id === Number(location.custom_delivery_location)) as any;

        const customExchangeLocationType = customExchangeLocationTypes.find((type: {id: number}) => type.id === Number(location.custom_delivery_location_type)) as any;
        const area = customExchangeLocation ? administrativeAreas.find((area: {id: number}) => area.id === Number(customExchangeLocation.location)) as any : undefined;
        let name = customExchangeLocation?.name || ""
        let type = customExchangeLocationType?.name || ""
        return (
          <span>
            {name || "Name unavailable"}
            {
              type ? (
                <>
                  <br/>
                  {type}
                </>
              ) : undefined
            }
            {
              area ? (<>
                <br/>
                {area.name}
              </>) : undefined
            }
          </span>
        );
        default:
          return "Unknown delivery type"
    }
    
  }

  return (
    <div className={getNegotiationCardClass()}>

      <div className={`${style.status} ${proposal.uuid === 'hardcoded_original_offer' || isOwn(proposal) ? style.newStatus : style.warningStatus}`}>
        {/*hax for original offer*/}
        {proposal.uuid === 'hardcoded_original_offer' ? t("offerStatuses.new") : (
            proposal.proposal_type !== "EXCHANGE_NEGOTIATION" ? t("offerStatuses.negotiating") : t("offerStatuses.exchange_details_updated")
        )}
      </div>

      <div className={style.negotiationDetails}>
        <div className={style.proposalUser}> {proposal.user && proposal.user.first_name} {proposal.user && proposal.user.last_name}</div>
        <div className={style.proposalTime}>{ moment(proposal.backend_creation_time).fromNow() }</div>

        {proposal.uuid === 'hardcoded_original_offer' && <div className={style.offerNegotiable}>
          <span className={style.label}>
            {t("negotiationCard.negotiable")}
            :
          </span>
          <span>
            {t(offer.negotiable ? "negotiationCard.yes" : "negotiationCard.no")}
          </span>
        </div>}

        <div className={style.negotiatedQuantity}>
          <span className={style.label}>
            {t(proposal.uuid === 'hardcoded_original_offer' ? "negotiationCard.offerQuantity" : "negotiationCard.negotiatedQuantity")}
            :
          </span>
          <span className={showActions && (Number(proposal.maximum_quantity) !== Number(offer.maximum_quantity) || Number(proposal.minimum_quantity) !== Number(offer.minimum_quantity)) ? style.valueChanged : ""}>
          {proposal.maximum_quantity === proposal.minimum_quantity ? formatNumber(proposal.minimum_quantity) : `${formatNumber(proposal.minimum_quantity)} - ${formatNumber(proposal.maximum_quantity)}`}
          {offer.unit_of_measure?.code}
          </span>
        </div>
        <div className={style.negotiatedPrice}>
          <span className={style.label}>
            {t(proposal.uuid === 'hardcoded_original_offer' ? "negotiationCard.offerPrice" : "negotiationCard.negotiatedPrice", {
              currency: offer.currency.symbol,
              unit: offer.unit_of_measure.code
            })}
            :
          </span>
          <span className={showActions && (Number(proposal.price) !== Number(offer.price)) ? style.valueChanged : ""}>
          {offerCurrency} {formatNumber(proposal.price)} {`${formatPriceUnits()}`}
        </span>
        </div>
        <div className={style.exchangeDateTime}>
          <span className={style.label}>
            {t("negotiationCard.exchangeDateTime")}:
          </span>
          <span
              style={{display: "flex", alignItems: "center"}}
              className={showActions && (proposal.proposal_type !== "EXCHANGE_NEGOTIATION" && !isExchangeDateValid() || proposal.planned_exchange_time !== offer.planned_exchange_time) ? style.danger : ""}
          >
            {formatDateTimeDefault(moment(proposal.planned_exchange_time))} {showActions && proposal.proposal_type !== "EXCHANGE_NEGOTIATION" && !isExchangeDateValid() && <img style={{marginLeft: 4}}
                                                                                  src={exclamation}/>}
          </span>
        </div>
        <div className={style.deliveryLocation}>
          <span className={style.label}>
            {t("negotiationCard.deliveryLocation")}:
          </span>
          {renderDeliveryLocation()}
        </div>
        <div className={style.proposal}>
          <span className={style.label}>
            {t("negotiationCard.additionalComments")}:
          </span>
          <span>{proposal.text || "-"}</span>
        </div>
      </div>
      {showActions && (
        <div className={style.proposalActions}>
          <Button
              small={true}
              disabled={proposal.proposal_type !== "EXCHANGE_NEGOTIATION" && !isExchangeDateValid()}
              className={style.proposalActionsButton}
              kind="primary"
              onClick={acceptNegotiation}
          >
            {
              offer.status !== OfferStatus.ExchangeDetailsUpdated
                  ? t("negotiationCard.accept").toString()
                  : t("negotiationCard.confirmQuantity").toString()
            }
          </Button>
          {offer.status === OfferStatus.ExchangeDetailsUpdated && onSendBackExchange && (
              <Button
                  small={true}
                  className={style.proposalActionsButton}
                  kind="secondary"
                  onClick={onSendBackExchange}
              >
                {t("negotiationCard.disputeQuantity").toString()}
              </Button>
          )}
          {
            onCounterOffer && (
                <Button
                    small={true}
                    className={style.proposalActionsButton}
                    kind="secondary"
                    onClick={onCounterOffer}
                >
                  {t("negotiationCard.counterOffer").toString()}
                </Button>
            )
          }
          {offer.status !== OfferStatus.ExchangeDetailsUpdated && (
              <Button
                  small={true}
                  className={style.proposalActionsButton}
                  kind="danger"
                  onClick={onRecallOffer}
              >
                {t("negotiationCard.recall").toString()}
              </Button>
          )}
        </div>
      )}
    </div>
  );
};
export const mapStateToProps = (state: any) => {
  return {
    listMineAggregators: state.listMineAggregators.data.results,
    listTraders: state.listTraders.data.results,
    customExchangeLocations: state.listExchangeLocations.data.results,
    customExchangeLocationTypes: state.listExchangeLocationTypes.data.results,
    administrativeAreas: state.listAdministrativeAreas.data.results,
  };
};

export default connect(mapStateToProps)(NegotiationCard);
