// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_wrapper_nV3xHZdbbqLUEXw5jdjz {\n  width: 100%;\n}\n\n.style_helperText_zvxSHcKI54gV5w8UB39l {\n  font-size: 0.75rem;\n  color: var(--text-02);\n  line-height: 1.5;\n  z-index: 0;\n  max-height: 3rem;\n  margin-bottom: 0.5rem;\n}", "",{"version":3,"sources":["webpack://./src/components/Input/style.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,kBAAA;EACA,qBAAA;EACA,gBAAA;EACA,UAAA;EACA,gBAAA;EACA,qBAAA;AACF","sourcesContent":[".wrapper {\n  width: 100%;\n}\n\n.helperText { \n  font-size: 0.75rem;\n  color: var(--text-02);\n  line-height: 1.5;\n  z-index: 0;\n  max-height: 3rem;\n  margin-bottom: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "style_wrapper_nV3xHZdbbqLUEXw5jdjz",
	"helperText": "style_helperText_zvxSHcKI54gV5w8UB39l"
};
export default ___CSS_LOADER_EXPORT___;
