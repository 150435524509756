import React from "react";
import style from "./style.scss";

const EnvironmentIndicator = () => {
  return (
    <>
      {process.env.SENTRY_ENVIRONMENT && process.env.SENTRY_ENVIRONMENT !== "PROD" && (
        <div className={style.environmentIndicator}>
          <div className={style[process.env.SENTRY_ENVIRONMENT.toLocaleLowerCase()]}>
            {process.env.SENTRY_ENVIRONMENT}
          </div>
        </div>
      )}
    </>
  );
};

export default EnvironmentIndicator;
