import React from "react";
import PropTypes from "prop-types";
import { Container, Row as RGSRow, Col as RGSCol} from "react-grid-system";

// Grid component wrapper
export const Grid = ({ children, ...props }) => <Container {...props}>{children}</Container>;

// propTypes for the grid component
Grid.propTypes = {
  children: PropTypes.any.isRequired,
};

// Grid component wrapper
export const Row = ({ children, ...props }) => <RGSRow {...props}>{children}</RGSRow>;

// propTypes for the grid component
Row.propTypes = {
  children: PropTypes.any.isRequired,
};

// Grid component wrapper
export const Col = ({ children, ...props }) => <RGSCol {...props}>{children}</RGSCol>;

// propTypes for the grid component
Col.propTypes = {
  children: PropTypes.any.isRequired,
};
