import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import style from "./style.scss";

const SummaryCard = ({ heading, subheading, hValue, sValue }) => {
  const { t } = useTranslation();

  return (
    <div className={style.card}>
      <div className={style.heading}>
        {t("home.systemActivitySummary.cards." + heading)}
      </div>
      <div className={style.value}>{hValue}</div>
      {sValue !== undefined && (
        <div className={style.subheading}>
          {t("home.systemActivitySummary.cards." + subheading)}: {sValue}
        </div>
      )}
    </div>
  );
};

SummaryCard.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  hValue: PropTypes.number,
  sValue: PropTypes.number,
};

export default SummaryCard;
