import React from "react";
import PropTypes from "prop-types";
import { displayErrorHelpText } from "../../utils";
import style from "./style.scss";

// A wrapper of the TextInput component form WFP UI Kit
const Input = ({
  id,
  type,
  label,
  placeholder,
  errorMessage,
  isDisabled,
  input: { value, onFocus, onChange },
  meta: { error },
  helperText,
  testid,
}) => (
  <div className="wfp--form-item">
    {
      label
        ? <label htmlFor={id} className="wfp--label">{label}</label>
        : ""
    }
    {
      helperText
        ? <div className={style.helperText}>{helperText}</div>
        : ""
    }
    <div className={style.wrapper}>
      <input
        value={value}
        onFocus={onFocus}
        onChange={onChange}
        id={id}
        name={id}
        type={type}
        placeholder={placeholder}
        className="wfp--text-input"
        disabled={isDisabled}
        data-testid={testid}
      />
    </div>
    {displayErrorHelpText(errorMessage || error)}
  </div>
);

// propTypes for the Input component
Input.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.any,
  placeholder: PropTypes.string,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  helperText: PropTypes.string,
  testid: PropTypes.string,
};

// defaultProps for the Input component
Input.defaultProps = {
  type: "text",
  label: "",
  placeholder: "",
  errorMessage: "",
  isDisabled: false,
};

export default Input;
