import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { listCountries, updateManager, addManager } from "../../actions";
import { isEmpty } from "../../utils";
import Form from "./components/Form";
import { useTranslation } from "react-i18next";
import PageStandard from "../../components/PageStandard";
import { adaptOptions } from "../../utils/adapters";

// This component is used to display the list
// of commodities for the WFP Manager
export const ManagerDetails = ({
  history,
  formValues,
  addManager,
  updateManager,
  fieldErrors,
  countries,
  listCountries,
}) => {
  useEffect(() => {
    listCountries();
  }, []);

  const { t } = useTranslation();
  const manager =
    history && history.location.state && history.location.state.manager;

  const adaptFormValues = (formValues, existingManager) => {
    const formCountries =
      formValues.countries && formValues.countries.length
        ? formValues.countries.map((c) => c.value)
        : [];
    const existingCountries =
      existingManager &&
      existingManager.countries &&
      existingManager.countries.length
        ? existingManager.countries
        : [];

    const manager = {
      ...formValues,
      // user requirement to skip phone number field
      phone_number: "",
      countries: [...formCountries, ...existingCountries],
    };
    return manager;
  };

  const adaptFromInitial = (manager) => {
    if (!manager) {
      return;
    }
    return {
      ...manager,
      countries: adaptOptions(manager.countries || [], "id","name"),
    };
  };
  const onValidate = () => {
    let promise;
    if (manager && manager.id) {
      promise = updateManager(manager.id, adaptFormValues(formValues, manager));
    } else {
      promise = addManager(adaptFormValues(formValues));
    }

    promise.then((result) => {
      if (result) {
        history.push("/wfpManager/managers");
      }
    });
  };

  // const countriesCurrentUserCantAccess =
  //   manager && manager.countries && countries
  //     ? manager.countries.filter(
  //       (mc) => !countries.filter((c) => c.id === mc.id).length,
  //     )
  //     : [];
  // const disabledCountries = countriesCurrentUserCantAccess.map((c) => ({
  //   ...c,
  //   isDisabled: true,
  // }));
  // const countriesIncludingDisabled = countries && [
  //   ...countries,
  //   ...disabledCountries,
  // ];

  return (
    <PageStandard title={t("managerDetails.title")}>
      <Form
        fieldErrors={fieldErrors}
        countries={countries}
        onValidate={onValidate}
        initialValues={adaptFromInitial(manager)}
      ></Form>
    </PageStandard>
  );
};

// propTypes for the Commodities component
ManagerDetails.propTypes = {
  history: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  addManager: PropTypes.func.isRequired,
  updateManager: PropTypes.func.isRequired,
  fieldErrors: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  listCountries: PropTypes.func.isRequired,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = (state) => {
  return {
    // Commodities management
    formValues:
      (state.form.managerDetails && state.form.managerDetails.values) || {},
    fieldErrors:
      (!isEmpty(state.addManager.error) && state.addManager.error) ||
      (!isEmpty(state.updateManager.error) && state.updateManager.error) ||
      {},
    countries: state.userDetail.data.countries,
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    listCountries: () => dispatch(listCountries({ page_size: 999 })),
    updateManager: (id, manager) => dispatch(updateManager(id, manager)),
    addManager: (manager) => dispatch(addManager(manager)),
  };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(ManagerDetails);
