import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Loading from "../../../../components/Loading";
import Module from "../../../../components/Module";
import Table from "../../../../components/Table";
import { displayErrorMessage } from "../../../../utils";
import { generateViewButton } from "../../../../utils/table";

// The list of Aggregators that user can view and delete
export const List = ({
  isFetching,
  errorMessage,
  configurationItems,
  page,
  count,
  history,
  t,
}) => {

  if (isFetching) {
    return <Loading isVisible />;
  }

  if (errorMessage) {
    return <>{displayErrorMessage(errorMessage)}</>;
  }

  const openEditView = (record) => {
    history.push(record.frontend_endpoint);
  };

  const columns = [
    {
      Header: "configuration.common.name",
      accessor: "name",
      width: 200,
    },
    {
      Header: "configuration.list.column.number",
      accessor: "count",
      width: 40,
    },
    {
      Header: "configuration.list.column.description",
      accessor: "description",
      width: 200,
    },
    {
      Header: "",
      width: 80,
      alignRight: true,
      Cell: (obj) => generateViewButton(
        obj.row.original,
        openEditView,
        undefined,
        t,
      ),
    },
  ];

  return (
    <>
      <Module
        table={
          <Table
            {...{
              columns,
              data: configurationItems,
              loading: isFetching,
              page,
              totalItems: count,
              defaultPageSize: Math.ceil(configurationItems.length / 10) * 10,
            }}
          />
        }
      />
    </>
  );
};

// propTypes for the List component
List.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  configurationItems: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

// defaultProps for the List component
List.defaultProps = {
  errorMessage: "",
};

export default withTranslation()(withRouter(List));
