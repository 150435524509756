import React from "react";

import RepresentativeFooterComponent from "../../components/RepresentativeFooterComponent";

// The footer used in all pages to allow user access to utils and links
export const RepresentativeFooter = () => {
  return (
    <RepresentativeFooterComponent />
  );
};

export default RepresentativeFooter;
