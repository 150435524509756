import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  listCommodities,
  listCommoditiesReset,
  commoditiesCurrentPage,
  listUnitsOfMeasure,
  listCountries,
  updateCommodity,
  addCommodity,
} from "../../actions";
import { valueOrDefault, isEmpty } from "../../utils";
import { adaptCommoditiesList, adaptOptions } from "../../utils/adapters";
// import { useTranslation } from "react-i18next";
import Form from "./components/Form";
import { useTranslation } from "react-i18next";
import PageStandard from "../../components/PageStandard";
import { orderByLocalName } from "../../utils/i18n";

const commodityUnitType = "COMMODITY";

// This component is used to display the list
// of commodities for the WFP Manager
export const CommodityEdit = ({
  history,
  formValues,
  addCommodity,
  updateCommodity,
  fieldErrors,
  unitsOfMeasure,
  countries,
  listUnitsOfMeasure,
  listCountries,
}) => {
  useEffect(() => {
    listUnitsOfMeasure();
    listCountries();

    return () => {
      listCommoditiesReset();
    };
  }, []);

  const { t, i18n } = useTranslation();
  const commodity =
    history && history.location.state && history.location.state.commodity;

  const adaptFormValues = (formValues, existingCommodity) => {
    const commodity = {
      name: formValues.name,
      countries: formValues.countries
        ? formValues.countries.map((v) => v.value)
        : [],
      units: formValues.units ? formValues.units.map((u) => u.value) : [],
      wings_id: formValues.wings_id,
      name_translations: {
        [i18n.language]: formValues.name_translation,
        en: formValues.name,
        ...(existingCommodity ? existingCommodity.name_translations : {}),
      },
    };
    return commodity;
  };

  const adaptFromInitial = (commodity) => {
    return (
      commodity &&
      commodity.id && {
        ...commodity,
        name_translation: commodity.name_translations[i18n.language],
        countries: adaptOptions(
          countries.filter((c) => commodity.countries.includes(c.id)),
          "id",
          "name",
        ),
        units: adaptOptions(
          unitsOfMeasure.filter((u) => commodity.units.includes(u.name)),
          "id",
          "name",
        ),
      }
    );
  };
  const onValidate = () => {
    let promise;
    if (commodity && commodity.id) {
      promise = updateCommodity(
        commodity.id,
        adaptFormValues(formValues, commodity),
      );
    } else {
      promise = addCommodity(adaptFormValues(formValues));
    }

    promise.then((result) => {
      if (result) {
        history.push("/wfpManager/commodities");
      }
    });
  };

  const commodityUnits = unitsOfMeasure.filter(
    (u) => u.type === commodityUnitType,
  );

  return (
    <PageStandard title={t("commodityEdit.title")}>
      <Form
        fieldErrors={fieldErrors}
        countries={countries}
        unitsOfMeasure={commodityUnits}
        onValidate={onValidate}
        initialValues={adaptFromInitial(commodity)}
      ></Form>
    </PageStandard>
  );
};

// propTypes for the Commodities component
CommodityEdit.propTypes = {
  history: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  addCommodity: PropTypes.func.isRequired,
  updateCommodity: PropTypes.func.isRequired,
  fieldErrors: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  unitsOfMeasure: PropTypes.array.isRequired,
  listCountries: PropTypes.func.isRequired,
  listUnitsOfMeasure: PropTypes.func.isRequired,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = (state) => {
  return {
    // Commodities management
    formValues:
      (state.form.commodityEdit && state.form.commodityEdit.values) || {},
    fieldErrors:
      (!isEmpty(state.addCommodity.error) && state.addCommodity.error) ||
      (!isEmpty(state.updateCommodity.error) && state.updateCommodity.error) ||
      {},
    isFetching: state.listCommodities.isFetching,
    errorMessage: state.listCommodities.errorMessage,
    commodities: adaptCommoditiesList(
      orderByLocalName(state.listCommodities.data.results),
      state.listUnitsOfMeasure.data.results,
      state.listCountries.data.results,
    ),
    page: state.commoditiesCurrentPage.number,
    countries: state.listCountries.data.results,
    unitsOfMeasure: state.listUnitsOfMeasure.data.results,
    count: valueOrDefault(state.listCommodities.data.count, 0),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    listCommodities: (page) => dispatch(listCommodities({ page })),
    listUnitsOfMeasure: () => dispatch(listUnitsOfMeasure()),
    listCommoditiesReset: () => dispatch(listCommoditiesReset()),
    listCountries: () => dispatch(listCountries({ page_size: 999 })),
    commoditiesCurrentPage: (page) => dispatch(commoditiesCurrentPage(page)),
    updateCommodity: (id, commodity) =>
      dispatch(updateCommodity(id, commodity)),
    addCommodity: (commodity) => dispatch(addCommodity(commodity)),
  };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(CommodityEdit);
