// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_wrapper_VewwIcXO6oN4WYrBrTQQ {\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n}\n\n.style_icon_AtYtxwn8N1gO_WOeBRRR {\n  margin-bottom: 1em;\n}", "",{"version":3,"sources":["webpack://./src/containers/ErrorMessage/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".wrapper {\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center\n}\n\n.icon {\n  margin-bottom: 1em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "style_wrapper_VewwIcXO6oN4WYrBrTQQ",
	"icon": "style_icon_AtYtxwn8N1gO_WOeBRRR"
};
export default ___CSS_LOADER_EXPORT___;
