// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "ul.style_items_ZLX7ZJ6oPqQa1rBav2TE li {\n  padding: 0.4rem 0.6rem;\n  cursor: pointer;\n}\n\nul.style_items_ZLX7ZJ6oPqQa1rBav2TE li span {\n  margin-left: 0.5rem;\n  display: inline-block;\n}", "",{"version":3,"sources":["webpack://./src/components/PopupMenu/style.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,eAAA;AACF;;AAEA;EACE,mBAAA;EACA,qBAAA;AACF","sourcesContent":["ul.items li{\n  padding: 0.4rem 0.6rem;\n  cursor: pointer;\n}\n\nul.items li span{\n  margin-left: 0.5rem;\n  display: inline-block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"items": "style_items_ZLX7ZJ6oPqQa1rBav2TE"
};
export default ___CSS_LOADER_EXPORT___;
