import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import {getUserLanguage} from "./utils/i18n";

i18n
  .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "/static/locales/{{lng}}/translation.json",
    },
    interpolation: {escapeValue: false},
    fallbackLng: getUserLanguage(),
    debug: true,
    // interpolation: {
    //   escapeValue: false, // not needed for react as it escapes by default
    // },
  });

export default i18n;
