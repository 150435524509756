// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_alignRight_QDH6B7NbjMiKgbovk8Ng {\n  display: block;\n  width: 100%;\n  text-align: right;\n}\n\n.style_percentageContainer_OR2l9w5tJAZzCUcTH23z {\n  width: 100%;\n}\n\n.style_percentageWrapper_rqKe_07gqsHt7Wx_DLqv {\n  height: 8px;\n  background-color: #efefef;\n  border-radius: 4px;\n  margin-bottom: 0.2rem;\n  overflow: hidden;\n}\n\n.style_percentageValue_bgBloppSx73olCmccPQd {\n  height: 8px;\n  border-radius: 4px;\n}\n\n.style_percentageText_zr77z1yUG_afo6Lywyef {\n  font-size: 0.8rem;\n}\n\n.style_byUserValue_prNfFiFH30_6GjO67M6A {\n  font-weight: 400;\n  font-size: 14px;\n  color: var(--text-02);\n}\n\n.style_dateValue_uOWO6t7_pjiE8STcaKYH {\n  font-style: italic;\n  font-weight: 400;\n  font-size: 14px;\n  color: #000;\n  margin-bottom: 3px;\n}", "",{"version":3,"sources":["webpack://./src/utils/style.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,WAAA;EACA,iBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,WAAA;EACA,yBAAA;EACA,kBAAA;EACA,qBAAA;EACA,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,kBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;EACA,qBAAA;AACF;;AAEA;EACE,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,WAAA;EACA,kBAAA;AACF","sourcesContent":[".alignRight {\n  display: block;\n  width: 100%;\n  text-align: right;\n}\n\n.percentageContainer {\n  width: 100%;\n}\n\n.percentageWrapper {\n  height: 8px;\n  background-color: #efefef;\n  border-radius: 4px;\n  margin-bottom: 0.2rem;\n  overflow: hidden;\n}\n\n.percentageValue {\n  height: 8px;\n  border-radius: 4px;\n}\n\n.percentageText {\n  font-size: 0.8rem;\n}\n\n.byUserValue {\n  font-weight: 400;\n  font-size: 14px;\n  color: var(--text-02);\n}\n\n.dateValue {\n  font-style: italic;\n  font-weight: 400;\n  font-size: 14px;\n  color: #000;\n  margin-bottom: 3px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alignRight": "style_alignRight_QDH6B7NbjMiKgbovk8Ng",
	"percentageContainer": "style_percentageContainer_OR2l9w5tJAZzCUcTH23z",
	"percentageWrapper": "style_percentageWrapper_rqKe_07gqsHt7Wx_DLqv",
	"percentageValue": "style_percentageValue_bgBloppSx73olCmccPQd",
	"percentageText": "style_percentageText_zr77z1yUG_afo6Lywyef",
	"byUserValue": "style_byUserValue_prNfFiFH30_6GjO67M6A",
	"dateValue": "style_dateValue_uOWO6t7_pjiE8STcaKYH"
};
export default ___CSS_LOADER_EXPORT___;
