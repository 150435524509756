// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container_Mjb6vDTRXNsbYMYksjgQ {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  padding: 0 20px;\n  box-sizing: border-box;\n}\n\n.style_mobilePreviewContainer_ashvTmRfgWT9F4gbC4a_ {\n  width: 300px;\n  height: 400px;\n  overflow-y: scroll;\n  overflow-wrap: normal;\n  border: 1px solid #000000;\n  padding: 8px 8px 8px 8px;\n}\n\n.style_mobilePreviewWarning_hr3ETFUDsGbDiwiJRoe3 {\n  font-size: 13;\n}", "",{"version":3,"sources":["webpack://./src/containers/InfoHub/Components/Form/Components/InfoHubMobilePreviewStep/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,sBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,kBAAA;EACA,qBAAA;EACA,yBAAA;EACA,wBAAA;AACF;;AAEA;EACE,aAAA;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  padding: 0 20px;\n  box-sizing: border-box;\n}\n\n.mobilePreviewContainer {\n  width: 300px;\n  height: 400px;\n  overflow-y: scroll;\n  overflow-wrap: normal;\n  border: 1px solid #000000;\n  padding: 8px 8px 8px 8px;\n}\n\n.mobilePreviewWarning {\n  font-size: 13;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container_Mjb6vDTRXNsbYMYksjgQ",
	"mobilePreviewContainer": "style_mobilePreviewContainer_ashvTmRfgWT9F4gbC4a_",
	"mobilePreviewWarning": "style_mobilePreviewWarning_hr3ETFUDsGbDiwiJRoe3"
};
export default ___CSS_LOADER_EXPORT___;
