import {displayErrorHelpText} from "../../utils";
import {MultiSelect as MultiSelectComponent} from "react-multi-select-component";
import React, {useMemo} from "react";
import style from "./style.scss";
import {useTranslation} from "react-i18next";

interface Option {
    label: string;
    value: number | string;
}

interface Props {
    id: string;
    label: string;
    options: Option[];
    loading?: boolean;
    placeholder: string;
    errorMessage?: string;
    isDisabled?: boolean;
    input: { value: Option[], onChange: () => void };
    customClassName: string;
    helperText: string;
}

const MultiSelect: React.FC<Props> = ({
                    id,
                    label,
                    options,
                    loading,
                    placeholder,
                    errorMessage,
                    isDisabled,
                    input: { value, onChange },
                    customClassName,
                    helperText,
                }) => {
    const { t } = useTranslation();

    const defaultStrings = useMemo(() => ({
            "allItemsAreSelected": t("multiselect.default.allItemsAreSelected"),
            "clearSearch": t("multiselect.default.clearSearch"),
            "clearSelected": t("multiselect.default.clearSelected"),
            "noOptions": t("multiselect.default.noOptions"),
            "search": t("multiselect.default.search"),
            "selectAll": t("multiselect.default.selectAll"),
            "selectAllFiltered": t("multiselect.default.selectAllFiltered"),
            "selectSomeItems": placeholder,
            "create": t("multiselect.default.create"),
    }), [placeholder])

    return (
        <div className="wfp--form-item">
            <label htmlFor={id} className="wfp--label">
                {label}
            </label>
            {
                helperText
                    ? <div className={style.helperText}>{helperText}</div>
                    : ""
            }
            <div id={id} className={style.wrapper}>
                <MultiSelectComponent
                    labelledBy={id}
                    value={value || []}
                    onChange={onChange}
                    className={
                        customClassName
                            ? `wfp--react-multiselect-container ${customClassName}`
                            : "wfp--react-multiselect-container"
                    }
                    options={options}
                    isLoading={loading}
                    overrideStrings={defaultStrings}
                    disabled={isDisabled}
                />
            </div>
            {displayErrorHelpText(errorMessage)}
        </div>
    )
};


export default MultiSelect;
