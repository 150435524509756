import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  valueOrDefault,
  isEmpty,
  arrayToString,
} from "../../../../utils";
import {useTranslation} from "react-i18next";
import PageStandard from "../../../../components/PageStandard";
import Form from ".";
import {
  addInfoHubSubjectDetail,
  infoHubAddAggregatorToSubject,
  infoHubDeleteAggregatorFromSubject,
  infoHubSubjectDetail,
  infoHubSubjectDetailReset,
  listAggregators,
  listAggregatorsReset,
  listInfoHubSubjectAggregators,
  listInfoHubSubjectAggregatorsReset,
  listInfoHubTopics,
  listInfoHubTopicsReset,
  updateInfoHubSubjectDetail,
} from "../../../../actions";
import { getUserLanguage } from "../../../../utils/i18n";

// A function to adapt values entered in form to a format accepted by the server
export const adaptFromValues = formValues => {
  return {
    ...formValues,
    target: formValues && formValues.topics.length > 0 && formValues.topics[0].target || "AGGREGATOR",
    content_type: formValues && formValues.content_type && formValues.content_type.value || "",
    topics: formValues && formValues.topics.length > 0 && formValues.topics.map(topic => topic.value) || [],
    aggregators: formValues.aggregators && formValues.aggregators.map(aggregator => aggregator.value) || [],
    language: getUserLanguage(),
  };
};

// A function to adapt values received from server to
// a format compatible with the form
export const adaptInitialFormValues = (
  data,
  selectedAggregators,
  allAggregators,
) => {
  return valueOrDefault(!isEmpty(data) &&
  {
    ...data,
    target: data.topics && data.topics.length > 0 && data.topics[0].target || "AGGREGATOR",
    topics: valueOrDefault(data.topics && data.topics.map(topic => ({value: topic.id, label: topic.name, target: topic.target})), undefined),
    content_type: valueOrDefault(data.content_type && {value: data.content_type, label: data.content_type}, undefined),
    aggregators: valueOrDefault(Array.isArray(selectedAggregators) && selectedAggregators.map(aggregatorID => {
      const aggregator = allAggregators.find(aggregator => aggregator.id === aggregatorID);
      if(!aggregator) return;
      return {value: aggregatorID, label: aggregator.name};
    }), undefined),
  }, {});

};

// A function to get the error message from the upload farmer
// response if there is a payload in the response
export const getResponseErrorMessage = data => {
  if(data && Object.keys(data).length > 0) {
    let errors = "";
    Object.keys(data).forEach(key => {
      errors += `${key}: ${arrayToString(data[key])}\n`;
    });
    return errors;
  }
};

// This component is used to display the detail
// of a Aggregator Organization
export const InfoHubFormWrapper = (
  {
    isFetching,
    errorMessage,
    history,
    id,
    initialFormValues,
    contentTypes,
    topics,
    aggregators,
    listInfoHubTopics,
    listInfoHubTopicsReset,
    listAggregators,
    listAggregatorsReset,
    addInfoHubSubjectDetail,
    updateInfoHubSubjectDetail,
    infoHubSubjectDetail,
    infoHubSubjectDetailReset,
    infoHubAddAggregatorToSubject,
    listInfoHubSubjectAggregators,
    infoHubDeleteAggregatorFromSubject,
  },
) => {

  const isCopy = history.location
  && history.location.state
  && history.location.state.isCopy;

  const {t} = useTranslation();
  const [isBusy, setBusy] = useState(false);

  // On component mount the data are fetched or read
  // on component unmount data are reset
  useEffect(() => {
    if (id) {
      infoHubSubjectDetail(id);
      if (!isCopy) {
        listInfoHubSubjectAggregators(id);
      }
    }
    listInfoHubTopics();
    listAggregators();
    return () => {
      listInfoHubTopicsReset();
      listAggregatorsReset();
      infoHubSubjectDetailReset();
    };
  }, []);

  // On click validate a requests are made
  // to save Aggregator, after saving the
  // Aggregators list is displayed
  const onClickValidateAndClose = (formValues) => {
    saveSubject(formValues);
  };

  // This function is used to save a InfoHub Subject
  const saveSubject = (formValues) => {
    setBusy(true);
    const data = adaptFromValues(formValues);
    let promise;
    if (id && !isCopy) {
      promise = updateInfoHubSubjectDetail(id, data);
    } else {
      promise = addInfoHubSubjectDetail(data);
    }

    promise
      .then(result => {
        if(!result) return result;
        const currentAggregators =  initialFormValues.aggregators ? initialFormValues.aggregators.map(aggregator => aggregator.value) : [];
        const newAggregators = data.aggregators.filter(aggregator => !currentAggregators.includes(aggregator)).map(aggregator => ({subject: id && !isCopy ? id : result.data.id, aggregator}));
        let addResult;

        if(newAggregators.length > 0){
          addResult = infoHubAddAggregatorToSubject(newAggregators);
        }
        const deletedAggregators = currentAggregators.filter(aggregator => !data.aggregators.includes(aggregator));
        let deleteResult;
        if(deletedAggregators.length > 0){
          let params = {subject: id && !isCopy ? id : result.data.id, aggregators: deletedAggregators};
          deleteResult = infoHubDeleteAggregatorFromSubject(params);
        }
        return Promise.all([result, addResult, deleteResult]);
      })
      .finally(() => {
        setBusy(false);
      })
      .then(result => {
        if (result) {
          history.push("/wfpManager/infohub");
        }
      });
  };

  return (
    <PageStandard
      title={t("infoHub.form.pageTitle")}
    >
      <Form
        id={id}
        initialValues={initialFormValues}
        isFetching={isFetching || isBusy}
        errorMessage={errorMessage}
        onClickValidateAndClose={onClickValidateAndClose}
        setFieldError={()=>{}}
        contentTypes={contentTypes}
        topics={topics}
        aggregators={aggregators}
        isCopy={isCopy}
      />
    </PageStandard>
  );
};

// propTypes for the AggregatorDetails component
InfoHubFormWrapper.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  initialFormValues: PropTypes.object.isRequired,
  contentTypes: PropTypes.array.isRequired,
  topics: PropTypes.array.isRequired,
  aggregators: PropTypes.array.isRequired,
  listInfoHubTopics: PropTypes.func.isRequired,
  listInfoHubTopicsReset: PropTypes.func.isRequired,
  listAggregators: PropTypes.func.isRequired,
  listAggregatorsReset: PropTypes.func.isRequired,
  addInfoHubSubjectDetail: PropTypes.func.isRequired,
  updateInfoHubSubjectDetail: PropTypes.func.isRequired,
  infoHubSubjectDetail: PropTypes.func.isRequired,
  infoHubSubjectDetailReset: PropTypes.func.isRequired,
  infoHubAddAggregatorToSubject: PropTypes.func.isRequired,
  listInfoHubSubjectAggregators: PropTypes.func.isRequired,
  infoHubDeleteAggregatorFromSubject: PropTypes.func.isRequired,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = (state, props) => {
  const selectedAggregators = state.listInfoHubSubjectAggregators.data || state.listInfoHubSubjectAggregators.data.results || [];
  const allAggregators =  state.listAggregators.data.results || [];
  return {
    id: valueOrDefault(props.match.params.id, ""),
    contentTypes: [
      "Aggregator Information",
      "Farmer Training",
      "MCF CP Information",
      "MCF Participants Activities",
    ],
    topics: state.listInfoHubTopics.data.results || [],
    aggregators: allAggregators || [],
    initialFormValues: adaptInitialFormValues(state.infoHubSubjectDetail.data, selectedAggregators, allAggregators),
    isFetching: state.infoHubSubjectDetail.isFetching ||
      state.listInfoHubTopics.isFetching ||
      state.listAggregators.isFetching ||
      state.listInfoHubSubjectAggregators.isFetching,
    errorMessage: getResponseErrorMessage(state.infoHubSubjectDetail.error) ||
    getResponseErrorMessage(state.listInfoHubTopics.error) ||
    getResponseErrorMessage(state.listAggregators.error) ||
    getResponseErrorMessage(state.listInfoHubSubjectAggregators.error) ||
    getResponseErrorMessage(state.infoHubAddAggregatorToSubject.error),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    listInfoHubTopics: () => dispatch(listInfoHubTopics()),
    listInfoHubTopicsReset: () => dispatch(listInfoHubTopicsReset()),
    listAggregators: () => dispatch(listAggregators({ page_size: 9999 })),
    listAggregatorsReset: () => dispatch(listAggregatorsReset()),
    addInfoHubSubjectDetail: (data) => dispatch(addInfoHubSubjectDetail(data)),
    updateInfoHubSubjectDetail: (id, data) => dispatch(updateInfoHubSubjectDetail(id, data)),
    infoHubSubjectDetail: (id) => dispatch(infoHubSubjectDetail(id)),
    infoHubSubjectDetailReset: () => dispatch(infoHubSubjectDetailReset()),
    infoHubAddAggregatorToSubject: (data) => dispatch(infoHubAddAggregatorToSubject(data)),
    listInfoHubSubjectAggregators: (id) => dispatch(listInfoHubSubjectAggregators({subject: id}, "aggregators/")),
    listInfoHubSubjectAggregatorsReset: () => dispatch(listInfoHubSubjectAggregatorsReset()),
    infoHubDeleteAggregatorFromSubject: (params) => dispatch(infoHubDeleteAggregatorFromSubject(undefined, undefined, params)),
  };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InfoHubFormWrapper));
