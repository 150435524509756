// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_label_bFSD6A6aOMdX_Jf0jneG {\n  display: block;\n  margin-bottom: 0.5rem;\n  font-weight: bold;\n}\n\n.style_value_W_xZJNbZdjRM8rg6aJKA {\n  display: block;\n  margin-bottom: 0.95rem;\n}", "",{"version":3,"sources":["webpack://./src/containers/MarketOfferView/components/Details/style.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,qBAAA;EACA,iBAAA;AACF;;AAEA;EACE,cAAA;EACA,sBAAA;AACF","sourcesContent":[".label {\n  display: block;\n  margin-bottom: 0.5rem;\n  font-weight: bold;\n}\n\n.value {\n  display: block;\n  margin-bottom: 0.95rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "style_label_bFSD6A6aOMdX_Jf0jneG",
	"value": "style_value_W_xZJNbZdjRM8rg6aJKA"
};
export default ___CSS_LOADER_EXPORT___;
