// import Loading from "../../components/Loading";
import style from "./style.scss";
import Button from "../../../components/Button";
import { Deposit } from "./deposit-dtos";
import { useTranslation } from "react-i18next";
import {
  dateToString,
  formatNumber,
  getCurrencyCodeFromCache,
  getCurrencySymbolFromCache,
} from "../../../utils";
import React from "react";
import { getLocalisedName } from "../../../utils/i18n";
import { DATE_FORMAT_DEFAULT_WITH_TIME, DATE_FORMAT_ISO_STRING } from "../../../constants";
import { Carousel } from "./Carousel";

export const DepositCard = (props: {
  deposit: Deposit;
  // TODO
  // offers?: any[];
  userOrg?: number;
  onMakeOffer: (deposit: Deposit) => void;
  onClickAggregatorDetails?: (id: number) => void;
  isSummaryCard?: boolean;
}) => {
  const { t } = useTranslation();

  if (!props.deposit) {
    return <div>Card</div>;
  }

  const depositCurrency = getCurrencySymbolFromCache();
  const depositCurrencyCode = getCurrencyCodeFromCache();
  const offerNumber = (
      (!!props.deposit && !!props.deposit.direct_offers && props.deposit.direct_offers.length)
      + (!!props.deposit.market_order_id ? 1 : 0)
  );

  const getPriceNumber = (): string => {
    return formatNumber(props.deposit.price);
  }

  const formatQuantityNumber = () => {
      return formatNumber(props.deposit.quantity);
  }

  const formatPrice = (): string => {
    if (!props.deposit.unit_of_measure) {
      return "";
    }
    return getPriceNumber()
  }

  const formatQuantity = () => {
    if (!props.deposit || !props.deposit.unit_of_measure) {
      return;
    }

    return (formatNumber(props.deposit.quantity) + " " + props.deposit.unit_of_measure.code);
  }

  const formatDate = (date: string) => dateToString(
      date,
      DATE_FORMAT_ISO_STRING,
      DATE_FORMAT_DEFAULT_WITH_TIME,
  )

  const getStatusClass = () => {
    if (!offerNumber) {
      return style.blue;
    } else {
      return style.yellow;
    }
  }

  const getStatusText = () => {
    if (!offerNumber) {
      return t("farmersMarket.details.status.noOffersYet");
    } else {
      return `${offerNumber} ${t("farmersMarket.details.status.active")}`;
    }
  }

  const columns = [
    {
      "title": <>
        {t("farmersMarket.details.depositDetails")}
      </>,
      "style": style.depositDetails,
      "fields": [
        {label: "commodityName", value: getLocalisedName(props.deposit.commodity)},
        {label: "depositQuality", value: props.deposit.quality_grade.name || "Unknown quality"},
        {label: "depositQuantity", value: formatQuantity()},
        {
          label: "depositPrice",
          value: `${formatPrice()} ${depositCurrencyCode}/${props.deposit.unit_of_measure.code}`,
          translationProps: {
            currency: depositCurrencyCode,
            unit: props.deposit.unit_of_measure.code,
          }
        },
        // {label: "exchangeDateAndTime", value: formatDate(props.deposit.planned_exchange_time)},
        // {label: "exchangeLocation", value: renderDeliveryLocation()},
      ],
    },
    {
      "style": style.otherDetails,
      "fields": [
        {
          label: "offers",
          style: style.otherDetailCenter,
          value: (
              <div className={getStatusClass()}>
                {getStatusText()}
              </div>
          )
        },
        {
          label: "aggregator",
          style: style.otherDetailBaseline,
          value: (
              <div
                  onClick={() => {
                    if (!(typeof props.onClickAggregatorDetails === 'function')){
                      return;
                    } 
                    props.onClickAggregatorDetails(
                      props.deposit.aggregator?.id
                  )}}
                  className={`${style.detailValue} ${style.aggregatorName}`}
              >
                {props.deposit.aggregator?.name}
              </div>
          )
        },
      ],
    },
  ];

  const renderDetails = (details: any[]) => details.map(
      ({label, labelSuffix, value, style: itemStyle, translationProps}) => (
        <div
            key={`depositCard_details_${props.deposit.id}_${label}`}
            className={`${style.detailItem} ${itemStyle}`}
        >
          <div className={style.detailLabel}>
            {t(`farmersMarket.details.columns.${label}`, translationProps)}{labelSuffix}:
          </div>
          <div className={style.detailValue}>
            {value}
          </div>
        </div>
      )
  );

  return (
    <div className={`${style.container}`}>
    
    <div className={`${style.content} ${!props.isSummaryCard && style.contentPadding}`}>
      <div className={style.pictures}>
        {!props.isSummaryCard && <Carousel sizePx={200} width={160} height={185} imageUrls={props.deposit.picture_urls || []}/>}
      </div>
      <div style={{width: "100%", ...(props.isSummaryCard ? { borderBottom: "solid 1px #d3e1e8", paddingBottom: "1rem" } : {})}}>
        <div className={style.header}>
          <div className={style.depositType}>
            {t("farmersMarket.depositCard.deposit")} #{props.deposit.id}
          </div>
          <div className={style.depositTitle}>
            {t("marketOfferView.details.detailTitle", {
              quantity: formatQuantityNumber(),
              commodity: getLocalisedName(props.deposit.commodity),
              quality: props.deposit.quality,
              price: getPriceNumber(),
              unit: props.deposit.unit_of_measure.code,
              currency: depositCurrencyCode,
            })}
            <div className={style.depositLastUpdated}>
              {t("farmersMarket.depositCard.depositDate")}: {formatDate(props.deposit.backend_creation_time)}
            </div>
          </div>
        </div>
        <div className={style.details}>  
          <div className={style.columns}>
            {
              columns.map((column, i) => (
                  <div
                      key={`depositCard_${props.deposit.id}_column_${i}`}
                      className={column.style}
                  >
                    <div className={style.detailList}>
                      {renderDetails(column.fields)}
                    </div>
                  </div>
              ))
            }
            <div className={style.actions}>
              {!props.isSummaryCard && (
                  !props.deposit.has_direct_offer_same_trader ? (
                      <>
                        <Button
                            className={style.actionButton}
                            small={true}
                            kind={"secondary"}
                            onClick={() => props.onMakeOffer(props.deposit)}
                        >
                          {`${t("farmersMarket.depositCard.makeOffer")}`}
                        </Button>
                      </>
                  ) : (
                      <div className={style.organisationHasOffered}>
                        {t("farmersMarket.depositCard.existingOffer")}
                      </div>
                  )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

// // defaultProps for the Market component
// DepositsCard.defaultProps = {
//   errorMessageDeposits: "",
//   totalItems: 0,
//   sort: {
//     columnIndex: 6,
//     descending: true,
//   },
// };

export default DepositCard;
