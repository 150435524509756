import React, { useMemo } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Loading from "../../../../components/Loading";
import Module from "../../../../components/Module";
import Table from "../../../../components/Table";
import { displayErrorMessage } from "../../../../utils";
import { boolIconCell, generateDateTimeByUserCell, generateDeleteButton, generateViewButton } from "../../../../utils/table";
import { withRouter } from "react-router-dom";

// The list of Aggregators that user can view and delete
export const List = ({
  isFetching,
  errorMessage,
  exchangeLocations,
  page,
  count,
  setModalOptions,
  setDeleteModalOptions,
  administrativeAreas,
  exchangeLocationTypes,
}) => {
  const { t } = useTranslation();

  const administrativeAreasList = useMemo(() => {
    let result = [];

    function traverse(node) {

      const {children, ...noChildren} = node;
      result.push(noChildren);

      if (children && children.length) {
        children.forEach(child => traverse(child));
      }
    }

    if (administrativeAreas) {
      administrativeAreas.forEach(node => traverse(node));
    }

    return result;
  }, [administrativeAreas]);

  if (isFetching) {
    return <Loading isVisible />;
  }

  if (errorMessage) {
    return <>{displayErrorMessage(errorMessage)}</>;
  }

  const displayLocationName = (locationId) => {
    const administrativeArea = administrativeAreasList.find(area => area.value === locationId);
    return administrativeArea ? administrativeArea.label : "Unavailable";
  };

  const columns = [
    {
      Header: "exchangeLocations.outletLocationName",
      accessor: "name",
      width: 800,
    },
    {
      Header: "exchangeLocations.location",
      accessor: "location",
      // width: 400,
      Cell: ({ row: { original } }) => displayLocationName(original.location),
    },
    {
      Header: "exchangeLocations.linkedTraders",
      accessor: "linkedTraders",
      // width: 400,
      Cell: ({ row: { original } }) => original.assignments ? original.assignments.filter(assignment => assignment.types.length).length : "0",
    },
    ...exchangeLocationTypes.map((exchangeLocationType) => ({
      Header: exchangeLocationType.name,
      accessor: `linkedTradersToType${exchangeLocationType.id}`,
      // width: 400,
      Cell: ({ row: { original } }) => original.assignments ? original.assignments.filter((assignment) => assignment.types.some(type => type === exchangeLocationType.id)).length : "0",
    })),
    {
      Header: "exchangeLocations.status",
      accessor: "active",
      Cell: boolIconCell,
    },
    {
      Header: "configuration.list.column.lastUpdated",
      accessor: "lastUpdated",
      width: 400,
      Cell: ({ row: { original } }) => generateDateTimeByUserCell(original.last_update_datetime, original.last_update_user, t),
    },
    {
      Header: "configuration.list.column.created",
      accessor: "created",
      width: 400,
      Cell: ({ row: { original } }) => generateDateTimeByUserCell(original.creation_datetime, original.creation_user, t),
    },
    {
      width: 80,
      accessor: "view",
      Cell: ({row: { original: record }}) => generateViewButton(
        record,
        () => setModalOptions({id: record.id, isOpen: true, type: "view"}),
        "View",
        t,
      ),
      Header: "",
    },
    {
      width: 80,
      accessor: "view",
      Cell: ({row: { original: record }}) => generateViewButton(
        record,
        () => setModalOptions({id: record.id, isOpen: true, type: "edit"}),
        "Edit",
        t,
      ),
      Header: "",
    },
    {
      width: 80,
      accessor: "view",
      Cell: ({row: { original: record }}) => generateDeleteButton(
        record,
        () => setDeleteModalOptions({id: record.id, isOpen: true}),
        "Delete",
        t,
      ),
      Header: "",
    },
  ];

  return (
    <>
      <Module
        table={
          <Table
            {...{
              columns,
              data: exchangeLocations,
              loading: isFetching,
              page,
              totalItems: count,
            }}
          />
        }
      />
    </>
  );
};

// propTypes for the List component
List.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  exchangeLocations: PropTypes.list,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  setModalOptions: PropTypes.func.isRequired,
  setDeleteModalOptions: PropTypes.func.isRequired,
  administrativeAreas: PropTypes.array.isRequired,
  exchangeLocationTypes: PropTypes.array.isRequired,
};

// defaultProps for the List component
List.defaultProps = {
  errorMessage: "",
};

export default withTranslation()(withRouter(List));
