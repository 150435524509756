// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_filtersContainer_GplE0WucVDV4TI4W1ScU {\n  margin-top: -2rem;\n  padding: 0 1rem;\n}\n\n.style_filtersTitle_LpnHQsDoh9BY5W29VSwV {\n  font-size: 14px;\n  font-weight: 600;\n  margin-bottom: 1rem;\n}\n\n.style_searchButton_y_86K1hZkVc26L0dRCWT {\n  margin: 0 0.5rem;\n}\n\n.style_filterRow_wUpQ_npgrx6AvbY3LI8w {\n  display: flex;\n  flex-direction: row;\n}", "",{"version":3,"sources":["webpack://./src/containers/Aggregators/style.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,eAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;AACF","sourcesContent":[".filtersContainer {\n  margin-top: -2rem;\n  padding: 0 1rem;\n}\n\n.filtersTitle {\n  font-size: 14px;\n  font-weight: 600;\n  margin-bottom: 1rem;\n}\n\n.searchButton {\n  margin: 0 0.5rem;\n}\n\n.filterRow {\n  display: flex;\n  flex-direction: row;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filtersContainer": "style_filtersContainer_GplE0WucVDV4TI4W1ScU",
	"filtersTitle": "style_filtersTitle_LpnHQsDoh9BY5W29VSwV",
	"searchButton": "style_searchButton_y_86K1hZkVc26L0dRCWT",
	"filterRow": "style_filterRow_wUpQ_npgrx6AvbY3LI8w"
};
export default ___CSS_LOADER_EXPORT___;
