import React from "react";
import PropTypes from "prop-types";
import { Tag as WFPTag } from "@wfp/ui";

// A wrapper of the Tag component form WFP UI Kit
const Tag = ({ children, type, className }) => (
  <WFPTag type={type} className={className}>
    {children}
  </WFPTag>
);

// propTypes for the Tag component
Tag.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  className: PropTypes.string,
};

// defaultProps for the Tag component
Tag.defaultProps = {
  children: "Not found",
  type: "info",
};

export default Tag;
