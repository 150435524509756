// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_wrapper__K3hqfpeldxKPY8K4j1U {\n  margin: 2rem 0rem 4rem 0rem;\n}\n\n.style_secondaryNavigation_Y8DmCEwUhCpI0Ld2h5x6 {\n  border-bottom: 0px;\n}\n\n.style_tabs_OMFyDop6INw2yhbTOkKp {\n  margin-left: 1rem;\n}", "",{"version":3,"sources":["webpack://./src/components/PageStandardWithTabs/style.scss"],"names":[],"mappings":"AAAA;EACE,2BAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACC,iBAAA;AACD","sourcesContent":[".wrapper {\n  margin: 2rem 0rem 4rem 0rem;\n}\n\n.secondaryNavigation{\n  border-bottom: 0px;\n}\n\n.tabs{\n margin-left: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "style_wrapper__K3hqfpeldxKPY8K4j1U",
	"secondaryNavigation": "style_secondaryNavigation_Y8DmCEwUhCpI0Ld2h5x6",
	"tabs": "style_tabs_OMFyDop6INw2yhbTOkKp"
};
export default ___CSS_LOADER_EXPORT___;
