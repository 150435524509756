import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { countryMcfKpiDashboard } from "../../../../actions";
import { Col, Grid, Row } from "../../../../components/Grid";
import Loading from "../../../../components/Loading";
import { displayErrorMessage } from "../../../../utils";

import style from "../../style.scss";
import SummaryCard from "../SummaryCard";
import ReportsHyperlinkCard from "../ReportsHyperlinkCard";
import SummaryStatisticsCard from "../SummaryStatisticsCard";
import { getUserInformation } from "../../../../utils/authentication";

export const MastercardFundationSummaryView = ({
  isFetchingCountryMcfKpiDashboardData,
  errorMessageManagerCountryMcfKpiDashboard,
  countryMcfKpiDashboardData,
  getCountryMcfKpiDashboardData,
}) => {
  const [user, setUser] = useState(undefined);

  // Set country code
  useEffect(() => {
    const bootstrap = async () => {
      const user = await getUserInformation();
      getCountryMcfKpiDashboardData(user);
      setUser(user);
    };

    bootstrap();
  }, []);

  const extractDashboardData = (field, percentage = false) => {
    if (!countryMcfKpiDashboardData || !countryMcfKpiDashboardData[field])
      return 0;
    if (percentage) {
      return (Number(countryMcfKpiDashboardData[field]) * 100).toFixed(1) + "%";
    }
    return countryMcfKpiDashboardData[field];
  };

  const extractDashboardStats = (field, subfield) => {
    if (
      !countryMcfKpiDashboardData ||
      !countryMcfKpiDashboardData[field] ||
      !countryMcfKpiDashboardData[field][subfield]
    )
      return 0;
    return countryMcfKpiDashboardData[field][subfield];
  };

  const cardRows = [
    {
      cards: [
        {
          heading: "participantsRegistered",
          hValue: extractDashboardData("participants_registered_count"),
          subheading: "inPast7Days",
          // sValue: 0,
        },
        {
          heading: "trainingSessionCreated",
          hValue: extractDashboardData("training_sessions_created_count"),
          subheading: "inPast7Days",
          // sValue: 0,
        },
      ],
      Component: SummaryCard,
    },
    {
      cards: [
        {
          heading: "female",
          hValue: extractDashboardData("female_percentage", true),
          subheading: "inPast7Days",
          // sValue: 0,
        },
        {
          heading: "withDisabilities",
          hValue: extractDashboardData("disabled_people_percentage", true),
          subheading: "inPast7Days",
          // sValue: 0,
        },
        {
          heading: "agedUnder35Years",
          hValue: extractDashboardData("under_age_threshold_percentage", true),
          subheading: "inPast7Days",
          // sValue: 0,
        },
        {
          heading: "student",
          hValue: extractDashboardData("student_percentage", true),
          subheading: "inPast7Days",
          // sValue: 0,
        },
      ],
      Component: SummaryCard,
    },
    {
      cards: [
        {
          heading: "workStatus",
          records: [
            {
              value: extractDashboardStats(
                "work_status_breakdown",
                "UNEMPLOYED",
              ),
              label: "stats.notWorking",
            },
            {
              value: extractDashboardStats(
                "work_status_breakdown",
                "WAGE_EMPLOYED",
              ),
              label: "stats.wageEmployed",
            },
            {
              value: extractDashboardStats(
                "work_status_breakdown",
                "DAILY_WORKER",
              ),
              label: "stats.dailyWorker",
            },
            {
              value: extractDashboardStats(
                "work_status_breakdown",
                "SELF_EMPLOYED",
              ),
              label: "stats.selfemployed",
            },
          ].sort((rec1, rec2) => rec2.value - rec1.value),
        },
        {
          heading: "occupation",
          expandable: true,
          records: [
            {
              value: extractDashboardStats("FAMILY_FARM_WORKER", "UNEMPLOYED"),
              label: "stats.familyFarmWorker",
            },
            {
              value: extractDashboardStats(
                "occupation_breakdown",
                "DOMESTIC_WORKER",
              ),
              label: "stats.domesticWorker",
            },
            {
              value: extractDashboardStats("occupation_breakdown", "OTHER"),
              label: "stats.other",
            },
            {
              value: extractDashboardStats(
                "occupation_breakdown",
                "INDIVIDUAL_FARMER",
              ),
              label: "stats.producerIndividualFarmer",
            },
            {
              value: extractDashboardStats(
                "occupation_breakdown",
                "AGRO_DEALER",
              ),
              label: "stats.agroDealerInputSupplier",
            },
            {
              value: extractDashboardStats(
                "occupation_breakdown",
                "HANDICRAFT_WORKER",
              ),
              label: "stats.artisanHandicraftWorker",
            },
            {
              value: extractDashboardStats(
                "occupation_breakdown",
                "MATERIAL_PRODUCER",
              ),
              label: "stats.materialProducer",
            },
            {
              value: extractDashboardStats("occupation_breakdown", "AGENT"),
              label: "stats.agent",
            },
            {
              value: extractDashboardStats(
                "occupation_breakdown",
                "TRANSPORTER",
              ),
              label: "stats.transporter",
            },
            {
              value: extractDashboardStats("occupation_breakdown", "PROCESSOR"),
              label: "stats.processor",
            },
            {
              value: extractDashboardStats(
                "occupation_breakdown",
                "AGGREGATOR",
              ),
              label: "stats.aggregator",
            },
            {
              value: extractDashboardStats(
                "occupation_breakdown",
                "WHOLESALER",
              ),
              label: "stats.wholesaler",
            },
            {
              value: extractDashboardStats("occupation_breakdown", "RETAILER"),
              label: "stats.retailer",
            },
            {
              value: extractDashboardStats(
                "occupation_breakdown",
                "ADMINISTRATIVE_WORKER",
              ),
              label: "stats.administrativeWorker",
            },
            {
              value: extractDashboardStats(
                "occupation_breakdown",
                "SERVICE_PROVIDER",
              ),
              label: "stats.serviceProvider",
            },
            {
              value: extractDashboardStats(
                "occupation_breakdown",
                "CASUAL_LABOUR",
              ),
              label: "stats.casualLabour",
            },
            {
              value: extractDashboardStats(
                "occupation_breakdown",
                "UNEMPLOYED",
              ),
              label: "stats.unemployed",
            },
          ].sort((rec1, rec2) => rec2.value - rec1.value),
        },
      ],
      Component: SummaryStatisticsCard,
    },
    {
      cards: [
        {
          links: {
            dotsDashboard:
              "https://dots.wfp.org/workspace/module/view/latest/ri.workshop.main.module.152e6633-d6d3-413e-84f7-017f48715f5c",
            tableau:
              "https://analytics.wfp.org/views/DTUProductsBusinessMetrics/Farm2Go?%3Aembed=y&%3Aiid=1&%3AisGuestRedirectFromVizportal=y#1",
            yammer:
              "https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI5OTIzNTY4NDM1MiJ9/all",
          },
        },
      ],
      Component: ReportsHyperlinkCard,
      hide: user && user.user_profile === "mcf_administrator",
    },
  ];

  if (!user) {
    return <Loading />;
  }

  return (
    <div style={{ marginTop: "1rem" }}>
      {isFetchingCountryMcfKpiDashboardData ? (
        <Loading isVisible={true} />
      ) : (
        <Grid>
          <Row data-testid={"row"}>
            <Col sm={12}>
              {displayErrorMessage(errorMessageManagerCountryMcfKpiDashboard)}

              <div className={style.summaryCards}>
                {cardRows.map(
                  ({ cards, Component, hide }, i) =>
                    !hide && (
                      <div
                        key={`summaryCardRow-${i}`}
                        className={style.summaryCardRow}
                      >
                        {cards.map((card, j) => (
                          <Component key={`summaryCard-${i}-${j}`} {...card} />
                        ))}
                      </div>
                    ),
                )}
              </div>
            </Col>
          </Row>
        </Grid>
      )}
    </div>
  );
};

// propTypes for the SummaryView component
MastercardFundationSummaryView.propTypes = {
  getCountryMcfKpiDashboardData: PropTypes.func,
  isFetchingCountryMcfKpiDashboardData: PropTypes.bool,
  errorMessageManagerCountryMcfKpiDashboard: PropTypes.string,
  countryMcfKpiDashboardData: PropTypes.object,
  user: PropTypes.object,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = (state) => {
  return {
    isFetchingCountryMcfKpiDashboardData:
      state.countryMcfKpiDashboard.isFetching,
    errorMessageManagerCountryMcfKpiDashboard:
      state.countryMcfKpiDashboard.errorMessage,
    countryMcfKpiDashboardData: state.countryMcfKpiDashboard.data,
    user: state.userDetail.data,
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    getCountryMcfKpiDashboardData: (user) =>
      dispatch(
        countryMcfKpiDashboard(
          undefined,
          user.aggregator ? `?aggregator=${user.aggregator}` : undefined,
        ),
      ),
  };
};

// The component uses the redux store
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(MastercardFundationSummaryView));
