// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_multiCheckbox_K0PM5uUtXnoQGsSho1o6 {\n  margin-bottom: 1rem;\n}\n\n.style_checkboxItemsContainer_GOxYYolyBWmHbzqO1QcN {\n  display: flex;\n  flex-direction: row;\n}\n\n.style_sublabel_yc9u0jLtvTILNzDom7ca {\n  font-size: 12px;\n  line-height: 22.6px;\n  font-family: Open Sans;\n}\n\n.style_verticalCheckboxItemsContainer_SqDwlwCvQXeijNYsrLl4 {\n  display: flex;\n  flex-direction: column;\n  margin-top: 16px;\n}\n.style_verticalCheckboxItemsContainer_SqDwlwCvQXeijNYsrLl4 > .style_checkboxItem_XXS2hxmmxbJopLHDhjdF {\n  margin-bottom: 12px;\n}\n\n.style_checkboxItem_XXS2hxmmxbJopLHDhjdF {\n  display: flex;\n  flex-direction: row;\n  margin-right: 1em;\n}\n.style_checkboxItem_XXS2hxmmxbJopLHDhjdF .wfp--form-item {\n  margin-bottom: 0;\n}\n\n.style_wfpCheckboxDisabled_hh2_6g8IoiOe9CnvzOuy {\n  opacity: 0.9;\n}", "",{"version":3,"sources":["webpack://./src/components/MultiCheckbox/style.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,mBAAA;EACA,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;AACF;AACE;EACE,mBAAA;AACJ;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,iBAAA;AAAF;AAEE;EACE,gBAAA;AAAJ;;AAIA;EACE,YAAA;AADF","sourcesContent":[".multiCheckbox {\n  margin-bottom: 1rem;\n}\n\n.checkboxItemsContainer {\n  display: flex;\n  flex-direction: row;\n}\n\n.sublabel {\n  font-size: 12px;\n  line-height: 22.6px;\n  font-family: Open Sans;\n}\n\n.verticalCheckboxItemsContainer {\n  display: flex;\n  flex-direction: column;\n  margin-top: 16px;\n\n  & > .checkboxItem {\n    margin-bottom: 12px;\n  }\n}\n\n.checkboxItem {\n  display: flex;\n  flex-direction: row;\n  margin-right: 1em;\n\n  :global(.wfp--form-item) {\n    margin-bottom: 0;\n  }\n}\n\n.wfpCheckboxDisabled {\n  opacity: 0.9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"multiCheckbox": "style_multiCheckbox_K0PM5uUtXnoQGsSho1o6",
	"checkboxItemsContainer": "style_checkboxItemsContainer_GOxYYolyBWmHbzqO1QcN",
	"sublabel": "style_sublabel_yc9u0jLtvTILNzDom7ca",
	"verticalCheckboxItemsContainer": "style_verticalCheckboxItemsContainer_SqDwlwCvQXeijNYsrLl4",
	"checkboxItem": "style_checkboxItem_XXS2hxmmxbJopLHDhjdF",
	"wfpCheckboxDisabled": "style_wfpCheckboxDisabled_hh2_6g8IoiOe9CnvzOuy"
};
export default ___CSS_LOADER_EXPORT___;
