// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_wide_xxxC20gMAuWImSeHrFic {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  padding-top: 0.5rem;\n  padding-bottom: 0.5rem;\n}", "",{"version":3,"sources":["webpack://./src/containers/RepresentativeHome/components/OfferCommentsModal/OfferCommentForm/style.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,sBAAA;AACF","sourcesContent":[".wide {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  padding-top: 0.5rem;\n  padding-bottom: 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wide": "style_wide_xxxC20gMAuWImSeHrFic"
};
export default ___CSS_LOADER_EXPORT___;
