import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Column, usePagination, useTable } from "react-table";
import { Table as TableWFP, Pagination } from "@wfp/ui";
import { iconChevronDown, iconChevronUp } from "@wfp/icons";
import Icon from "../../../components/Icon";
import DepositCard from "../DepositCard";
import style from "./style.scss";
import DirectOfferDetails  from "../../DirectOfferDetails";
import Modal from "../../../components/Modal";

interface Props {
  data: any[];
  columns: Column<any>[];
  // loading: boolean;
  // defaultPageSize: number;
  // subComponent: Node;
  // expandAll: boolean;
  // showPagination: boolean;
  totalItems: number;
  page: number;
  onPageChange?: (page: { page: number; pageSize: number }) => void;
  sort: {
    columnIndex: number;
    descending: boolean;
  };
  onSortChange: (
    sort: { columnIndex: number; descending: boolean },
    pageSize: number
  ) => void;

  onMakeOfferClick: (deposit: any) => void;
  onClickAggregatorDetails: (id: number) => void;
}

export const DepositsList = ({
  data,
  columns,
  // loading,
  // defaultPageSize = 10,
  // subComponent,
  // expandAll = false,
  // showPagination = true,
  totalItems,
  page,
  sort,
  onPageChange,
  onSortChange,
  onMakeOfferClick,
  onClickAggregatorDetails,
}: Props) => {
  const { t } = useTranslation();
  const [directOfferModalOpen, setDirectOfferModalOpen] = useState(undefined);
  const memoColumns = React.useMemo(
    () =>
      columns && columns.length
        ? columns.map((column, index) => ({
            ...column,
            id: `id_${index}`,
            Header: t(column.Header as string),
          }))
        : [],
    [columns]
  );
  const memoData = React.useMemo(() => data, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data: memoData,
      columns: memoColumns,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    usePagination
  );

  const changePage: any = (page: { page: number; pageSize: number }) => {
    if (onPageChange) {
      onPageChange(page);
    } else {
      gotoPage(page.page - 1);
    }
    // Update PageSize
    if (pageSize !== page.pageSize) {
      setPageSize(page.pageSize);
    }
  };

  // Type of the HOC is an inferred anonymous type - todo fix this later.
  const DDDetails = DirectOfferDetails as any;

  return (
    <div className={style.container}>
      {data.map((datum, idx) => {
        return (
          <DepositCard
            onClickAggregatorDetails={onClickAggregatorDetails}
            onMakeOffer={(d: any) => {
              setDirectOfferModalOpen(d);
            }}
            key={`${datum.name}${idx}`}
            deposit={datum}
          />
        );
      })}
      <Pagination
        pageSize={pageSize}
        pageSizes={[10, 20, 30]}
        page={page}
        totalItems={totalItems}
        onChange={changePage}
      />
      <DDDetails isOpen={!!directOfferModalOpen} onCancel={() => {setDirectOfferModalOpen(undefined)}} deposit={directOfferModalOpen} />
    </div>
  );
};

export default DepositsList;
