import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../../components/Modal";
import PropTypes from "prop-types";
import Form from "../Form";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  addDepositDeletionReasonsDetail,
  depositDeletionReasonsDetail,
  depositDeletionReasonsDetailReset,
  updateDepositDeletionReasonsDetail,
} from "../../../../actions";
import { adaptInitialFormValues, getTitleName } from "../../../../utils/wfpManagerConfigurationUtils";

export const DepositDeletionReasonsEditModal = ({
  initialFormValues,
  isFetching,
  errorMessage,
  modalOptions,
  setModalOptions,
  depositDeletionReasonsDetail,
  depositDeletionReasonsDetailReset,
  updateDepositDeletionReasonsDetails,
  addDepositDeletionReasonsDetails,
}) => {
  const { t } = useTranslation();
  const buttonRef = useRef();
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    if(modalOptions.id){
      depositDeletionReasonsDetail(modalOptions.id);
    }
    return () => {
      depositDeletionReasonsDetailReset();
    };
  }, [modalOptions.id]);

  useEffect(() => {
    if(modalOptions.type != "create"){
      setInitialValues(adaptInitialFormValues(initialFormValues, modalOptions.id));
    }
  }, [initialFormValues]);

  const onSubmitEdit = (formValues) => {
    updateDepositDeletionReasonsDetails(modalOptions.id, formValues);
    setModalOptions({ type: undefined, id: undefined, isOpen: false });
  };

  const onSubmitCreate = (formValues) => {
    addDepositDeletionReasonsDetails(formValues);
    setModalOptions({ type: undefined, id: undefined, isOpen: false });
  };

  return (
    <Modal
      isOpen={modalOptions.isOpen}
      title={
        t("configuration.item.depositDeletionReasons") + " " + t(getTitleName(modalOptions.type))
      }
      onCancel={() => setModalOptions({ type: undefined, isOpen: false, id: undefined })}
      onValidate={() => buttonRef.current.click()}
    >
      <Form
        id={modalOptions.id}
        initialValues={modalOptions.type !== "create" ? initialValues : {}}
        isFetching={isFetching}
        errorMessage={errorMessage}
        modalOptions={modalOptions}
        onSubmit={modalOptions.type === "create" ? onSubmitCreate : onSubmitEdit}
        buttonRef={buttonRef}
      />
    </Modal>
  );
};

DepositDeletionReasonsEditModal.defaultProps = {};

DepositDeletionReasonsEditModal.propTypes = {
  initialFormValues: PropTypes.object,
  isFetching: PropTypes.bool,
  errorMessage: PropTypes.string,
  modalOptions: PropTypes.object,
  setModalOptions: PropTypes.func,
  depositDeletionReasonsDetail: PropTypes.func.isRequired,
  depositDeletionReasonsDetailReset: PropTypes.func.isRequired,
  updateDepositDeletionReasonsDetails: PropTypes.func.isRequired,
  addDepositDeletionReasonsDetails: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    initialFormValues: state.depositDeletionReasonsDetail.data,
    isFetching: state.depositDeletionReasonsDetail.isFetching,
    errorMessage: state.depositDeletionReasonsDetail.errorMessage,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    depositDeletionReasonsDetail: (id) => dispatch(depositDeletionReasonsDetail(id)),
    depositDeletionReasonsDetailReset: () => dispatch(depositDeletionReasonsDetailReset()),
    addDepositDeletionReasonsDetails: (values) =>
      dispatch(addDepositDeletionReasonsDetail(values, "", true)),
    updateDepositDeletionReasonsDetails: (id, values) =>
      dispatch(updateDepositDeletionReasonsDetail(id, values, "", true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DepositDeletionReasonsEditModal);
