// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_helperText_YFphLSHnWszgY11x9pVp {\n  font-family: \"Open Sans\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 26px;\n  /* Text/text-02 */\n  color: #5A6872;\n  margin-bottom: 1em;\n}\n\n.style_exchangeLocationTypeContainer_L0gM28p4pBrueBoofpJp {\n  display: flex;\n}\n.style_exchangeLocationTypeContainer_L0gM28p4pBrueBoofpJp > div {\n  margin-right: 1em;\n}\n\n.style_activeContainer_mhi7EBh5AHBe8yIKNVka .wfp--form-item {\n  align-items: flex-end;\n}", "",{"version":3,"sources":["webpack://./src/containers/WfpManagerExchangeLocationsView/components/Form/style.scss"],"names":[],"mappings":"AAAA;EACI,wBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EAEA,iBAAA;EACA,cAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,aAAA;AAAJ;AAEI;EACI,iBAAA;AAAR;;AAMI;EACI,qBAAA;AAHR","sourcesContent":[".helperText {\n    font-family: 'Open Sans';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 26px;\n\n    /* Text/text-02 */\n    color: #5A6872;\n    margin-bottom: 1em;\n}\n\n.exchangeLocationTypeContainer {\n    display: flex;\n\n    & > div {\n        margin-right: 1em;\n    }\n}\n\n.activeContainer {\n\n    :global(.wfp--form-item) {\n        align-items: flex-end;\n      }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helperText": "style_helperText_YFphLSHnWszgY11x9pVp",
	"exchangeLocationTypeContainer": "style_exchangeLocationTypeContainer_L0gM28p4pBrueBoofpJp",
	"activeContainer": "style_activeContainer_mhi7EBh5AHBe8yIKNVka"
};
export default ___CSS_LOADER_EXPORT___;
