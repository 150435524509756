import React from "react";
import PropTypes from "prop-types";

// A component used to make some blank space in modals
const Spacer = ({ height }) => (
  <div style={{ height }} />
);

// propTypes for the Spacer component
Spacer.propTypes = {
  height: PropTypes.string.isRequired,
};

export default Spacer;
