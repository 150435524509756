// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_wrapper_d76KrkjJIjuKHvc8aykO {\n  width: 100%;\n}\n\n.style_helperText_Bjg62TkJXAwLWqiYoSiK {\n  font-size: 0.75rem;\n  color: var(--text-02);\n  line-height: 1.5;\n  z-index: 0;\n  max-height: 3rem;\n  margin-bottom: 0.5rem;\n}\n\n.dropdown-container {\n  border-radius: 0 !important;\n  border: 1px solid var(--field-border-01) !important;\n}", "",{"version":3,"sources":["webpack://./src/components/MultiSelect/style.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,kBAAA;EACA,qBAAA;EACA,gBAAA;EACA,UAAA;EACA,gBAAA;EACA,qBAAA;AACF;;AAEA;EACE,2BAAA;EACA,mDAAA;AACF","sourcesContent":[".wrapper {\n  width: 100%;\n}\n\n.helperText {\n  font-size: 0.75rem;\n  color: var(--text-02);\n  line-height: 1.5;\n  z-index: 0;\n  max-height: 3rem;\n  margin-bottom: 0.5rem;\n}\n\n:global(.dropdown-container) {\n  border-radius: 0 !important;\n  border: 1px solid var(--field-border-01) !important;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "style_wrapper_d76KrkjJIjuKHvc8aykO",
	"helperText": "style_helperText_Bjg62TkJXAwLWqiYoSiK"
};
export default ___CSS_LOADER_EXPORT___;
