import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Controller, useForm, FormProvider, useWatch } from "react-hook-form";

import Input from "../../../../components/Input";
import { MandatoryLabel } from "../../../../components/MandatoryLabel";
import { getMandatoryFormFieldErrors } from "../../../../utils";
import { setDefaultValues } from "../../../../utils/wfpManagerConfigurationUtils";
import Loading from "../../../../components/Loading";
import style from "./style.scss";
import MultiSelect from "../../../../components/MultiSelect";
import Toggle from "../../../../components/Toggle";
import MultiCheckboxTable from "../../../../components/MultiCheckboxTable";
import TreePicker from "../../../../components/TreePicker";
import MultiCheckbox from "../../../../components/MultiCheckbox";

const defaultValues = {
  name: "",
  location: "",
  active: false,
};

export const resolver = (values, t) => {
  let errors = {};
  if(values.name === "" || !values.name){
    errors.name = t("traderDetails.form.mandatoryFieldBlankError");
  }
  if(values.location === "" || !values.location){
    errors.location = t("traderDetails.form.mandatoryFieldBlankError");
  }
  return {values, errors};
};

const Form = ({ initialValues, t, modalOptions, buttonRef, onSubmit, isFetching, administrativeAreas, traders, exchangeLocationTypes }) => {
  const methods = useForm({resolver: undefined, context: t});

  const { control, handleSubmit, setValue, getValues, formState: { errors, isSubmitted }, reset } = methods;

  const selectedTypeIds = useWatch({
    control,
    name: "types",
    defaultValue: [],
  });

  const selectedTraderOptions = useWatch({
    control,
    name: "traders",
    defaultValue: [],
  });

  const selectedTypes = useMemo(() => {
    return selectedTypeIds.map((id) => {
      return exchangeLocationTypes.find(type => type.id === id);
    });
  }, [selectedTypeIds, exchangeLocationTypes]);

  const selectedTraders = useMemo(() => {
    return selectedTraderOptions.map(option => {
      return traders.find(tra => tra.id === option.value);
    });
  }, [selectedTraderOptions, selectedTraders]);

  const tableColumns = useMemo(() => [
    {
      Header: t("viewExchangeDetailsModal.linkedTraders.table.traderName"),
      accessor: "name",
      Cell: ({value}) => value,
      width: 300,
      disableSortBy: true,
    },
  ], []);

  useEffect(() => {
    const initValues = {...initialValues, types: initialValues && initialValues.assignments ? initialValues.assignments.reduce((acc, curr) => {
      curr.list.forEach(item => {
        if (!acc.includes(item)) {
          acc.push(item);
        }
      });
      console.log(acc);
      return acc;
    }, []) : [],
    };
    setDefaultValues(modalOptions, initValues, setValue, reset);
  }, [initialValues]);

  useEffect(() => {
    reset();
  }, [modalOptions.id]);

  if(isFetching){
    return <Loading isVisible />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className={style.helperText}>{t("viewExchangeDetailsModal.helperText")}</p>
        <Controller
          control={control}
          render={({ field, fieldState }) => {
            return (
              <Input
                label={<MandatoryLabel label={t("viewExchangeDetailsModal.outletLocationName.label")} />}
                placeholder={t("viewExchangeDetailsModal.outletLocationName.placeholder")}
                id={"input-name"}
                name={"name"}
                isDisabled={modalOptions.type === "view"}
                input={field}
                meta={fieldState}
                errorMessage={isSubmitted && getMandatoryFormFieldErrors(errors, t, getValues, "name")}
              />
            );
          }}
          defaultValue={defaultValues.name}
          name="name"
          rules={{ required: true }}
        />
        <Controller
          control={control}
          render={({field}) => (
            <TreePicker
              input={field}
              label={<MandatoryLabel label={t("viewExchangeDetailsModal.functioningArea.label")} />}
              placeholder={t("viewExchangeDetailsModal.functioningArea.placeholder")}
              errorMessage={isSubmitted && getMandatoryFormFieldErrors(errors, t, getValues, "location")}
              isDisabled={modalOptions.type === "view"}
              id="location-treePicker"
              tree={administrativeAreas}
            />
          )}
          name="location"
          id="location"
          rules={{ required: true }}
        />

        <Controller
          control={control}
          render={({ field, fieldState }) => {
            return (
              <MultiCheckbox
                label={t("viewExchangeDetailsModal.type")}
                id={"exchange-location-types"}
                options={exchangeLocationTypes}
                name={"types"}
                labelInFront
                errorMessage={isSubmitted && getMandatoryFormFieldErrors(errors, t, getValues, "types")}
                // defaultChecked={defaultValues.active}
                isDisabled={modalOptions.type === "view"}
                input={field}
                meta={fieldState}
              />
            );
          }}
          defaultValue={defaultValues.types}
          name={"types"}
          rules={{ required: true }}
        />

        <Controller
          control={control}
          render={({ field, fieldState }) => {
            return (
              <MultiSelect
                name="traders"
                id="select-traders"
                label={<MandatoryLabel label={t("viewExchangeDetailsModal.linkedTraders.label")} />}
                placeholder={t("viewExchangeDetailsModal.linkedTraders.placeholder")}
                options={traders.map(trader => ({
                  label: trader.name,
                  value: trader.id,
                }))}
                helperText={t("viewExchangeDetailsModal.linkedTraders.helperText")}
                erroddrMessage={isSubmitted && getMandatoryFormFieldErrors(errors, t, getValues, "traders")}
                isDisabled={modalOptions.type === "view"}
                input={field}
                meta={fieldState}
              />
            );
          }}
          defaultValue={defaultValues.traders}
          name="traders"
          rules={{ required: true }}
        />
        {
          selectedTraders && selectedTraders.length && selectedTypes && selectedTypes.length ?
            <Controller
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <MultiCheckboxTable
                    name="assignments"
                    id="trader-location-type-assignments"
                    fields={selectedTypes}
                    columns={tableColumns}
                    records={selectedTraders}
                    label={<MandatoryLabel label={t("viewExchangeDetailsModal.linkedTraders.label")} />}
                    placeholder={t("viewExchangeDetailsModal.linkedTraders.placeholder")}
                    options={traders.map(trader => ({
                      label: trader.name,
                      value: trader.id,
                    }))}
                    helperText={t("viewExchangeDetailsModal.linkedTraders.helperText")}
                    errorMessage={isSubmitted && getMandatoryFormFieldErrors(errors, t, getValues, "assignments")}
                    isDisabled={modalOptions.type === "view"}
                    input={field}
                    meta={fieldState}
                  />
                );
              }}
              defaultValue={defaultValues.assignments}
              name="assignments"
            /> : undefined
        }
        <div className={style.activeContainer}>
          <Controller
            control={control}
            render={({ field, fieldState }) => {
              return (
                <Toggle
                  label={""}
                  id={"active"}
                  name={"active"}
                  defaultChecked={defaultValues.active}
                  isDisabled={modalOptions.type === "view"}
                  input={field}
                  meta={fieldState}
                  customLabels={{right: t("viewExchangeDetailsModal.activeLocation")}}
                />
              );
            }}
            defaultValue={defaultValues.active}
            name="active"
          />
        </div>

        <button style={{ display: "none" }} ref={buttonRef} />
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  modalOptions: PropTypes.object.isRequired,
  fieldErrors: PropTypes.object,
  initialValues: PropTypes.object,
  t: PropTypes.func.isRequired,
  buttonRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.func.isRequired,
  administrativeAreas: PropTypes.array.isRequired,
  exchangeLocationTypes: PropTypes.array,
  traders: PropTypes.array.isRequired,
};

Form.defaultProps = {
  exchangeLocationTypes: [],
};

export default withTranslation()(Form);
