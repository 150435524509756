import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";

import Select from "../../../../components/Select";
import Input from "../../../../components/Input";
import FormWrapper from "../../../../components/FormWrapper";
import { Grid, Row, Col } from "../../../../components/Grid";
import DatePicker from "../../../../components/DatePicker";
import Modal from "../../../../components/Modal";
import {useTranslation} from "react-i18next";

// A modal used to create and edit an Aggregator Administrator
export const AdministratorModal = (
  {
    isModalOpen,
    onClickValidate,
    genders,
    onClickClose,
  },
) => {

  const {t} = useTranslation();

  return (
    <Modal
      isOpen={isModalOpen}
      title={t("representativeAggregatorCreationRequestDetails.administratorModal.title")}
      onValidate={onClickValidate}
      onCancel={onClickClose}
      is
    >
      <FormWrapper>
        <Grid>
          <Row>
            <Col sm={4}>
              <Field
                component={Input}
                name="first_name"
                id="first_name"
                label={t("fieldName.firstName")}
              />
            </Col>
            <Col sm={4}>
              <Field
                component={Input}
                name="middle_name"
                id="middle_name"
                label={t("fieldName.middleName")}
              />
            </Col>
            <Col sm={4}>
              <Field
                component={Input}
                name="last_name"
                id="last_name"
                label={t("fieldName.lastName")}
              />
            </Col>
          </Row>
        </Grid>
        <Grid>
          <Row>
            <Col sm={4}>
              <Field
                component={Input}
                name="email"
                id="email"
                label={t("fieldName.email")}
              />
            </Col>
            <Col sm={4}>
              <Field
                component={Input}
                name="contact_phone"
                id="contact_phone"
                label={t("fieldName.phoneNumber")}
              />
            </Col>
            <Col sm={4}>
              <Field
                component={Input}
                name="location"
                id="location"
                label={t("fieldName.location")}
              />
            </Col>
          </Row>
        </Grid>
        <Grid>
          <Row>
            <Col sm={6}>
              <Field
                component={Input}
                name="identification_card_number"
                id="identification_card_number"
                label={t("fieldName.identificationCardNumber")}
              />
            </Col>
          </Row>
        </Grid>
        <Grid>
          <Row>
            <Col sm={6}>
              <Field
                component={Select}
                name="gender"
                id="gender"
                label={t("fieldName.gender")}
                options={genders}
                loading={false}
              />
            </Col>
            <Col sm={6}>
              <Field
                component={DatePicker}
                name="day_of_birth"
                id="day_of_birth"
                label={t("fieldName.dateOfBirth")}
                withPortal
              />
            </Col>
          </Row>
        </Grid>
        <Grid>
          <Row>
            <Col sm={12}>
              <Field
                component={Input}
                name="position"
                id="position"
                label={t("fieldName.position")}
              />
            </Col>
          </Row>
        </Grid>
      </FormWrapper>
    </Modal >
  );
};

// propTypes for the AdministratorModal component
AdministratorModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClickValidate: PropTypes.func.isRequired,
  genders: PropTypes.array.isRequired,
  onClickClose: PropTypes.func.isRequired,
};

export default reduxForm(
  { form: "administratorRequestDetails", enableReinitialize: true },
)(AdministratorModal);
