// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_environmentIndicator_RsMmLCQKUxFLupox6xP7 {\n  position: fixed;\n  width: 300px;\n  height: 50px;\n  background-color: grey;\n  bottom: 0px;\n  right: 0px;\n  text-align: center;\n  font-size: 20px;\n  pointer-events: none;\n  font-family: sans-serif;\n  text-transform: uppercase;\n  font-weight: 700;\n  line-height: 50px;\n  transform: translateX(95px) rotate(-45deg);\n  padding-left: 30px;\n  z-index: 100;\n}\n.style_environmentIndicator_RsMmLCQKUxFLupox6xP7 .style_dev_iPwntwFvdS98tfLB4rcA {\n  background: #ffbf3b;\n}\n.style_environmentIndicator_RsMmLCQKUxFLupox6xP7 .style_qa_vY0zntM3bUDnZtFKGhu6 {\n  background: #8ad220;\n}\n\n.style_test_MkCH01sYmgCDdPXs9M0N {\n  background: red;\n}", "",{"version":3,"sources":["webpack://./src/containers/EnvironmentIndicator/style.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,YAAA;EACA,sBAAA;EACA,WAAA;EACA,UAAA;EACA,kBAAA;EACA,eAAA;EACA,oBAAA;EACA,uBAAA;EACA,yBAAA;EACA,gBAAA;EACA,iBAAA;EACA,0CAAA;EACA,kBAAA;EACA,YAAA;AACF;AACE;EACE,mBAAA;AACJ;AAEE;EACE,mBAAA;AAAJ;;AAIA;EACE,eAAA;AADF","sourcesContent":[".environmentIndicator {\n  position: fixed;\n  width: 300px;\n  height: 50px;\n  background-color: grey;\n  bottom: 0px;\n  right: 0px;\n  text-align: center;\n  font-size: 20px;\n  pointer-events: none;\n  font-family: sans-serif;\n  text-transform: uppercase;\n  font-weight: 700;\n  line-height: 50px;\n  transform: translateX(95px) rotate(-45deg);\n  padding-left: 30px;\n  z-index: 100;\n\n  .dev {\n    background: #ffbf3b;\n  }\n  \n  .qa {\n    background: #8ad220;\n  }\n}\n\n.test {\n  background: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"environmentIndicator": "style_environmentIndicator_RsMmLCQKUxFLupox6xP7",
	"dev": "style_dev_iPwntwFvdS98tfLB4rcA",
	"qa": "style_qa_vY0zntM3bUDnZtFKGhu6",
	"test": "style_test_MkCH01sYmgCDdPXs9M0N"
};
export default ___CSS_LOADER_EXPORT___;
