import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { displayErrorHelpText } from "../../utils";
import { Checkbox as WFPCheckbox } from "@wfp/ui";
import style from "./style.scss";

interface Props {
  id: string;
  label: string;
  sublabel: string;
  vertical?: boolean;
  options: { id: number; name: string; disabled?: boolean }[];
  errorMessage?: string;
  isDisabled?: boolean;
  defaultValue?: number[] | undefined;
  input: {
    value?: number[] | undefined;
    onChange?: (val: number[] | undefined) => void;
  };
  meta: { error?: string };
  labelInFront?: boolean;
  testid?: string;
}

// A wrapper of the TextInput component form WFP UI Kit
const MultiCheckbox: React.FC<Props> = ({
  id,
  label,
  sublabel,
  vertical = false,
  errorMessage,
  options,
  isDisabled,
  input: { value, onChange },
  meta: { error },
  testid,
}) => {
    const [currentValue, setCurrentValue] = useState<number[] | undefined>(value || []);
    
    useEffect(() => {
        if (value !== currentValue && value !== undefined) {
            setCurrentValue(value);
        }
    }, [value, currentValue])

  const onChangeItem = (id: number) => {
    if (!currentValue) {
      const result = [id];
      setCurrentValue(result);
      onChange && onChange(result);
      return;
    }
    if (currentValue.includes(id)) {
      let result: number[] | undefined = currentValue.filter(
        (item) => item !== id
      );
      result = result.length ? result : undefined;
      setCurrentValue(result);
      onChange && onChange(result);
    } else {
      const result = [...currentValue, id];
      setCurrentValue(result);
      onChange && onChange(result);
    }
  };

  return (
    <div className={style.multiCheckbox} data-testid={testid}>
      <label htmlFor={id} className="wfp--label">
        {label}
      </label>
      {sublabel && <span className={style.sublabel}>{"\n" + sublabel}</span>}
      <div
        className={
          vertical
            ? style.verticalCheckboxItemsContainer
            : style.checkboxItemsContainer
        }
      >
        {options.map((option) => (
          <div key={`${option.id}`} className={style.checkboxItem}>
            <WFPCheckbox
              checked={
                currentValue && currentValue.some((val) => val === option.id)
              }
              wrapperClassName={option.disabled ? style.wfpCheckboxDisabled : undefined}
              style={{opacity: 0.5}}
              // defaultChecked={currentValue.some(val => val === option.id)}
              onChange={() => onChangeItem(option.id)}
              id={`${id}_option_${option.id}`}
              name={`${id}_option_${option.id}`}
              disabled={isDisabled || option.disabled}
              hideLabel={false}
              labelText={option.name}
              data-testid={testid ? `${testid}_${option.id}` : undefined}
            />
          </div>
        ))}
      </div>
      {displayErrorHelpText(errorMessage || error)}
    </div>
  );
};

// defaultProps for the Input component
MultiCheckbox.defaultProps = {
  label: "",
  errorMessage: "",
  isDisabled: false,
};

export default MultiCheckbox;
