// List of qualities available
export const QUALITIES = [
  {
    value: "G1",
    label: "Grade 1",
    translationKey: "grades.G1",
  },
  {
    value: "G2",
    label: "Grade 2",
    translationKey: "grades.G2",
  },
  {
    value: "G3",
    label: "Grade 3",
    translationKey: "grades.G3",
  },
  {
    value: "UD",
    label: "Undefined",
    translationKey: "grades.UD",
  },
];

// List of delivery locations available
export const DELIVERY_LOCATIONS = [
  {
    value: "drop_at_trader",
    label: "Drop off at Trader",
    translationKey: "deliveryLocations.dropAtTrader",
  },
  {
    value: "pick_from_aggregator",
    label: "Pick up from Aggregator",
    translationKey: "deliveryLocations.pickUpFromAggregator",
  },
];

// List of delivery locations available
export const NEGOTIABLE_OPTIONS = [
  {
    value: true,
    label: "Yes",
    translationKey: "common.yes",
  },
  {
    value: false,
    label: "No",
    translationKey: "common.no",
  },
];

// List of legal statuses available
export const LEGAL_STATUSES = [
  {
    id: "PRI",
    name: "Private",
  },
  {
    id: "PUB",
    name: "Public",
  },
];

// List of aggregator tiers

export const AGGREGATOR_TIERS = [
  {
    value: "Tier 1",
    label: "Tier 1",
    translationKey: "aggregatorTiers.tierOne",
  },
  {
    value: "Tier 2",
    label: "Tier 2",
    translationKey: "aggregatorTiers.tierTwo",
  },
  {
    value: "Tier 3",
    label: "Tier 3",
  },
];

// The list of genders
export const GENDERS = [
  {
    value: "M",
    label: "Male",
  },
  {
    value: "F",
    label: "Female",
  },
];

// The list of card types
export const CARD_TYPES =  [
  {
    value: "SCOPE",
    label: "Scope ID",
  },
  {
    value: "NATID",
    label: "National ID",
  },
];

// The list of organization types
export const ORGANIZATION_TYPES = [
  {
    id: "FAR",
    name: "Farmer Organization",
  },
  {
    id: "PRI",
    name: "Private Enterprise",
  },
];

// The list of membership fee statuses
export const MEMBERSHIP_FEE_STATUSES = [
  {
    value: "paid",
    label: "Paid",
  },
  {
    value: "unpaid",
    label: "Unpaid",
  },
  {
    value: "na",
    label: "Not eligible",
  },
];

export const ORDER_TYPES = {
  RANGE: "range",
  EXACT: "exact",
};

export const ORDER_TYPES_ARRAY = [
  {
    value: ORDER_TYPES.RANGE,
    label: "Range",
    translationKey: "orderTypes.range",
  },
  {
    value: ORDER_TYPES.EXACT,
    label: "Exact quantity",
    translationKey: "orderTypes.exact",
  },
];

// Date and time format
export const DATE_FORMAT_DEFAULT = "DD/MM/YYYY";
export const DATEPICKER_FORMAT_DEFAULT = "dd/MM/yyyy";
export const DATETIMEPICKER_FORMAT_DEFAULT = "dd/MM/yyyy h:mm aa";
export const DATE_FORMAT_DEFAULT_WITH_TIME = "DD/MM/YYYY - HH:mm";
export const DATE_FORMAT_INPUT = "YYYY-MM-DD";
export const DATE_FORMAT_STANDARD = "YYYYMMDD";
export const DATE_FORMAT_ISO_STRING = "YYYY-MM-DDTHH:mm:ss.cccZ";
export const DATE_FORMAT_TIMESTAMP = "DD-MM-YYYY HH:mm:ss UTC";

export const COMMODITY_UNITS = "COMMODITY";
export const LAND_UNITS = "LAND";

export const OFFER_TYPES = {
  MARKET_OFFER: "MARKET_OFFER",
  DIRECT_OFFER: "DIRECT_OFFER",
};

export const UserProfile = {
  ADMINISTRATOR: "administrator",
  REPRESENTATIVE_TRADER: "representative",
  WFP_MANAGER: "manager",
};

export const TIME_ZONES = [
  // TODO: Decide on how best to do this.
];

export const ServerUserProfile = {
  ADMINISTRATOR: "administrator",
  REPRESENTATIVE: "representative",
  WFP_MANAGER: "wfp_manager",
  GUEST: "guest",
  ExternalUser: "guest",
};
