import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { FormProvider, useForm, Controller } from "react-hook-form";
import Input from "../../../../components/Input";
import PhoneInput from "../../../../components/PhoneInput";
import FormWrapper from "../../../../components/FormWrapper";
import Loading from "../../../../components/Loading";
import {
  displayErrorMessage,
  getMandatoryFormFieldErrors,
  isEmpty,
} from "../../../../utils";
import { Grid, Row, Col } from "../../../../components/Grid";
import { withTranslation } from "react-i18next";
import { MandatoryLabel } from "../../../../components/MandatoryLabel";
import Button from "../../../../components/Button";
import { v4 } from "uuid";
import { Radio } from "../../../../components/Radio";

const getErrors = (object, key) =>
  object && object[key] && !isEmpty(object[key]) ? object[key] : "";

// A modal used to create and edit an Aggregator Administrator
export const AddAdministratorForm = ({
  isFetching,
  errorMessage,
  onClickValidate,
  isOnEdit,
  onClickClose,
  t,
  initialValues,
  fieldErrors,
  aggregator,
}) => {
  const buttonRef = useRef();
  const methods = useForm({ defaultValues: initialValues });
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
  } = methods;
  useEffect(() => {
    if (Object.keys(fieldErrors).length === 0) {
      reset(initialValues);
    }
  }, [initialValues]);

  const handleAdministratorCreation = (event) => {
    event.preventDefault();

    handleSubmit((form) => {
      const result = { ...form };
      if (
        aggregator.managed_individuals &&
        aggregator.managed_individuals.length === 1
      ) {
        switch (aggregator.managed_individuals[0]) {
        case "farmers":
        default: {
          result.account_type = "aggregator_admin";
          break;
        }
        case "mastercard_foundation_participants": {
          result.account_type = "mastercard_foundation_admin";
          break;
        }
        }
      }
      onClickValidate(result);
    })();
  };

  return (
    <div style={{ marginBottom: "2rem" }} key={v4()}>
      {isFetching ? (
        <Row center={"xs"}>
          <Loading isVisible />
        </Row>
      ) : (
        <FormWrapper>
          <FormProvider {...methods}>
            <form onSubmit={handleAdministratorCreation}>
              {displayErrorMessage(errorMessage)}
              {aggregator.managed_individuals &&
                aggregator.managed_individuals.length > 1 && (
                <Grid>
                  <Row>
                    <Col md={12}>
                      <Controller
                        control={control}
                        render={({ field, fieldState }) => (
                          <Radio
                            label={
                              <MandatoryLabel
                                label={t(
                                  "aggregatorDetails.administratorModal.accountType",
                                )}
                              />
                            }
                            options={[
                              {
                                id: "aggregator_admin",
                                value: "aggregator_admin",
                                name: t(
                                  "aggregatorDetails.administratorModal.aggregatorAdminType",
                                ),
                              },
                              {
                                id: "mastercard_foundation_admin",
                                value: "mastercard_foundation_admin",
                                name: t(
                                  "aggregatorDetails.administratorModal.mastercardFoundationAdminType",
                                ),
                              },
                            ]}
                            vertical
                            name={"account_type"}
                            labelInFront
                            errorMessage={getMandatoryFormFieldErrors(
                              errors,
                              t,
                              getValues,
                              "account_type",
                            )}
                            input={field}
                            meta={fieldState}
                          />
                        )}
                        rules={{ required: true }}
                        name="account_type"
                        id="account_type"
                      />
                    </Col>
                  </Row>
                </Grid>
              )}
              <Grid>
                <Row>
                  <Col md={6}>
                    <Controller
                      control={control}
                      render={({ field, fieldState }) => (
                        <Input
                          label={
                            <MandatoryLabel
                              label={t(
                                "aggregatorDetails.administratorModal.firstName",
                              )}
                            />
                          }
                          errorMessage={getMandatoryFormFieldErrors(
                            errors,
                            t,
                            getValues,
                            "first_name",
                          )}
                          id="first_name-input"
                          input={field}
                          meta={fieldState}
                        />
                      )}
                      rules={{ required: true }}
                      name="first_name"
                      id="first_name"
                    />
                  </Col>
                  <Col md={6}>
                    <Controller
                      control={control}
                      render={({ field, fieldState }) => (
                        <Input
                          label={
                            <MandatoryLabel
                              label={t(
                                "aggregatorDetails.administratorModal.lastName",
                              )}
                            />
                          }
                          id="last_name-input"
                          errorMessage={getMandatoryFormFieldErrors(
                            errors,
                            t,
                            getValues,
                            "last_name",
                          )}
                          input={field}
                          meta={fieldState}
                        />
                      )}
                      rules={{ required: true }}
                      name="last_name"
                      id="last_name"
                    />
                  </Col>
                </Row>
              </Grid>
              <Grid>
                <Row>
                  <Col md={12}>
                    <Controller
                      control={control}
                      render={({ field, fieldState }) => (
                        <Input
                          id="email"
                          label={
                            <MandatoryLabel
                              label={t(
                                "aggregatorDetails.administratorModal.email",
                              )}
                            />
                          }
                          isDisabled={isOnEdit}
                          errorMessage={getMandatoryFormFieldErrors(
                            errors,
                            t,
                            getValues,
                            "email",
                          )}
                          input={field}
                          meta={fieldState}
                        />
                      )}
                      rules={{ required: true }}
                      name="email"
                      id="email"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Controller
                      control={control}
                      render={({ field, fieldState }) => (
                        <PhoneInput
                          id="contact_phone-input"
                          label={t(
                            "aggregatorDetails.administratorModal.cellNumber",
                          )}
                          errorMessage={getErrors(fieldErrors, "contact_phone")}
                          input={field}
                          meta={fieldState}
                        />
                      )}
                      name="contact_phone"
                      id="contact_phone"
                    />
                  </Col>
                  <Col md={6}>
                    <Controller
                      control={control}
                      render={({ field, fieldState }) => (
                        <Input
                          id="role-input"
                          label={t("aggregatorDetails.administratorModal.role")}
                          errorMessage={getErrors(fieldErrors, "role")}
                          input={field}
                          meta={fieldState}
                        />
                      )}
                      name="role"
                      id="role"
                    />
                  </Col>
                </Row>
              </Grid>
              <Grid>
                <div
                  style={{
                    display: "flex",
                    textAlign: "right",
                    justifyContent: "flex-end",
                    maxWidth: "674px",
                  }}
                >
                  <div style={{ flexDirection: "row", display: "flex" }}>
                    <div style={{ paddingRight: "1rem" }}>
                      {isOnEdit && (
                        <Button
                          kind="secondary"
                          onClick={() => {
                            reset({});
                            onClickClose();
                          }}
                        >
                          {t("common.clearAll")}
                        </Button>
                      )}
                    </div>
                    <Button
                      // disabled={!isValid}
                      onClick={() => buttonRef.current.click()}
                    >
                      {isOnEdit
                        ? t(
                          "aggregatorDetails.administratorModal.updateAdministrator",
                        )
                        : t(
                          "aggregatorDetails.administratorModal.createAdministrator",
                        )}
                    </Button>
                  </div>
                </div>
              </Grid>
              <button ref={buttonRef} style={{ display: "none" }} />
            </form>
          </FormProvider>
        </FormWrapper>
      )}
    </div>
  );
};

// propTypes for the AdministratorModal component
AddAdministratorForm.propTypes = {
  id: PropTypes.number,
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onClickValidate: PropTypes.func.isRequired,
  onClickClose: PropTypes.func.isRequired,
  isOnEdit: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  fieldErrors: PropTypes.object,
  aggregator: PropTypes.object.isRequired,
};

// defaultProps for the AdministratorModal component
AddAdministratorForm.defaultProps = {
  errorMessage: "",
  fieldErrors: {},
};

export default withTranslation()(AddAdministratorForm);
