import React, { useState } from "react";
import PropTypes from "prop-types";

import style from "../../style.scss";
import Filters from "../Filters";
import { AddNewContentButton, formatNewFilters } from "../..";
import InfoHubTable from "../InfoHubTable";
import { valueOrDefault } from "../../../../utils";
import { listCountryInfoHubSubjects } from "../../../../actions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {byLastUpdateDateTime} from "../../../../utils/table";

const SelectedCountryTable = ({
  country,
  topics,
  infoHubSubjects,
  listCountryInfoHubSubjects,
  count,
  isFetching,
  setViewModalOptions,
  setConfirmModalOptions,
  types,
  history,
  t,
}) => {
  const [filters, setFilters] = useState({
    country: country,
    ordering: "last_update_datetime",
  });
  const [showFilters, setShowFilters] = useState(false);

  const loadPage = (paginationFilters) => {
    listCountryInfoHubSubjects({
      ...paginationFilters,
      ...filters,
    });
  };

  const updateFilters = (newFilters) => {
    const formattedFilters = formatNewFilters(newFilters);
    const aggregatedFilters = {
      ...filters,
      ...formattedFilters,
    };

    setFilters(aggregatedFilters);
    loadPage(aggregatedFilters);
  };

  const clearFilters = () => {
    setFilters({ country: country.id, ordering: "last_update_datetime"});
    loadPage({ country: country.id});
  };

  const filtersProps = {
    showFilters,
    setShowFilters,
    filters,
    setFilters,
    updateFilters,
    clearFilters,
    types: types,
    topics: topics.map(topic => ({
      label: topic.name,
      value: topic.id,
    })),
    status: [
      {
        label: "Draft",
        value: "draft",
      }, {
        label: "Published",
        value: "published",
      }, {
        label: "Withdrawn",
        value: "withdrawn",
      },
    ],
    listCountryInfoHubSubjects,
  };

  const tableProps = () => ({
    data: infoHubSubjects.sort(byLastUpdateDateTime),
    updateFilters,
    isFetching: isFetching,
    count: count,
    actions: {
      withdraw: (id) => setConfirmModalOptions({ isOpen: true, id: id, type: "withdraw" }),
      view: (id) => setViewModalOptions({ isOpen: true, id: id }),
      edit: (id) => history.push(`infohub/newContent/${id}`),
      publish: (id) =>  setViewModalOptions({ isOpen: true, id: id}),
    },
  });

  return (
    <>
      <div className={!showFilters ? style.filterContainerClosed : style.filterContainerOpen}>
        <Filters {...filtersProps} updateFilters={(filters) => updateFilters({  ...filters, country: country.id})}/>
        <AddNewContentButton history={history} t={t}/>
      </div>
      <InfoHubTable {...tableProps()} />
    </>
  );
};

SelectedCountryTable.propTypes = {
  t: PropTypes.func.isRequired,
  country: PropTypes.object.isRequired,
  topics: PropTypes.array.isRequired,
  infoHubSubjects: PropTypes.array,
  listCountryInfoHubSubjects: PropTypes.func.isRequired,
  count: PropTypes.number,
  isFetching: PropTypes.bool,
  setViewModalOptions: PropTypes.func.isRequired,
  setConfirmModalOptions: PropTypes.func.isRequired,
  types: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
};

export const mapStateToProps = (state) => ({
  infoHubSubjects: valueOrDefault(state.listCountryInfoHubSubjects.data.results, []),
  count: valueOrDefault(state.listCountryInfoHubSubjects.data.count, 0),
  isFetching: state.listCountryInfoHubSubjects.isFetching || state.listInfoHubTopics.isFetching,
  topics: valueOrDefault(state.listInfoHubTopics.data.results, []),

});

export const mapDispatchToProps = (dispatch) => ({
  listCountryInfoHubSubjects: (filters) => dispatch(listCountryInfoHubSubjects(filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(SelectedCountryTable)));
