import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Input from "../../../../components/Input";
import FormWrapper from "../../../../components/FormWrapper";
import Loading from "../../../../components/Loading";
import { displayErrorMessage, isEmpty } from "../../../../utils";
import { Grid, Row, Col } from "../../../../components/Grid";
import {withTranslation} from "react-i18next";
import { MandatoryLabel } from "../../../../components/MandatoryLabel";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { v4 } from "uuid";
import Button from "../../../../components/Button";

const getErrors = (object, key) => {
  return (object && object[key] && !isEmpty(object[key]))
    ? object[key]
    : "";
};

// A modal used to create and edit an Trader Representative
export const RepresentativeForm = (
  {
    isFetching,
    errorMessage,
    onClickValidate,
    fieldErrors,
    onClickClose,
    isOnEdit,
    t,
    initialValues,
  },
) => {
  const buttonRef = useRef();
  const methods = useForm({ defaultValues: initialValues });
  const { control, handleSubmit, reset } = methods;

  useEffect(() => {
    if(!fieldErrors){
      reset(initialValues);
    }
  }, [initialValues]);

  return (
    <div style={{marginBottom: "2rem"}} key={v4()}>
      {
        isFetching ? (
          <Row center={"xs"}>
            <Loading isVisible  />
          </Row>
        ) : (
          <FormWrapper>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onClickValidate)}>
                {displayErrorMessage(errorMessage)}
                <Grid>
                  <Row>
                    <Col sm={6}>
                      <Controller
                        control={control}
                        render={({field, fieldState}) => (
                          <Input
                            label={
                              <MandatoryLabel
                                label={t(
                                  "aggregatorDetails.administratorModal.firstName",
                                )}
                              />
                            }
                            errorMessage={getErrors(fieldErrors, "first_name")}
                            id="first_name-input"
                            input={field}
                            meta={fieldState}
                          />
                        )}
                        name="first_name"
                        id="first_name"
                      />
                    </Col>
                    <Col sm={6}>
                      <Controller
                        render={({field, fieldState}) => (
                          <Input
                            name="last_name"
                            id="last_name"
                            label={<MandatoryLabel label={t("fieldName.lastName")}/>}
                            errorMessage={getErrors(fieldErrors, "last_name")}
                            input={field}
                            meta={fieldState}
                          />
                        )}
                        control={control}
                        name="last_name"
                        id="last_name"
                      />
                    </Col>
                  </Row>
                </Grid>
                <Grid>
                  <Row>
                    <Col sm={12}>
                      <Controller
                        render={({field}) => (
                          <Input
                            name="email"
                            id="email"
                            label={<MandatoryLabel label={t("traderDetails.form.email")}/>}
                            isDisabled={isOnEdit}
                            errorMessage={getErrors(fieldErrors, "email")}
                            input={field}
                            meta={{error: ""}}
                          />
                        )}
                        control={control}
                        name="email"
                        id="email"
                      />
                    </Col>
                  </Row>
                </Grid>
                <Grid>
                  <div style={{display: "flex", textAlign: "right", justifyContent: "flex-end", maxWidth: "674px"}}>
                    <div style={{flexDirection: "row", display: "flex"}}>
                      <div style={{paddingRight: "1rem"}}>
                        {
                          isOnEdit && <Button kind="secondary" onClick={() =>{reset({}); onClickClose();}}>
                            {t("common.back")}
                          </Button>
                        }
                      </div>
                      <Button onClick={() => buttonRef.current.click()}>
                        { isOnEdit ? t("traderDetails.representativeModal.updateRepresentative") : t("traderDetails.representativeModal.createRepresentative")}
                      </Button>
                    </div>
                  </div>
                </Grid>
                <button ref={buttonRef} style={{display: "none"}}/>
              </form>
            </FormProvider>
          </FormWrapper>
        )
      }
    </div>
  );
};

// propTypes for the RepresentativeModal component
RepresentativeForm.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onClickValidate: PropTypes.func.isRequired,
  fieldErrors: PropTypes.object.isRequired,
  onClickClose: PropTypes.func.isRequired,
  isOnEdit: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

// defaultProps for the RepresentativeModal component
RepresentativeForm.defaultProps = {
  errorMessage: "",
};

export default withTranslation()(RepresentativeForm);
