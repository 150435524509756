import React from "react";
import { withRouter } from "react-router-dom";
import { iconLocked } from "@wfp/icons";
import PropTypes from "prop-types";

import Icon from "../../components/Icon";
import style from "./style.scss";
import {useTranslation} from "react-i18next";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import Button from "../../components/Button";

// A page displayed when user navigate to login forbidden route
export const LoginForbiddenGuest = ({ history }) => {
  const {t} = useTranslation();

  return (
    <div className={style.wrapper}>
      <div>
        <div className={style.icon}>
          <Icon
            icon={iconLocked}
            width="200"
            height="200"
          />
        </div>
        <h2 className="wfp--story__body">{t("loginForbidden.guestNotAllowedHeading")}</h2>
        <h3 className="wfp--story__body">{t("loginForbidden.guestNotAllowedText")}</h3>
        <ButtonsWrapper>
          <Button onClick={() => history.push("/logout")}>
            {`${t("loginForbidden.loginPage")}`}
          </Button>
        </ButtonsWrapper>
      </div>
    </div>
  );
};

LoginForbiddenGuest.propTypes = {
  history: PropTypes.object.isRequired,
};

// The router is used to allow user to navigate to home page
export default withRouter(LoginForbiddenGuest);
