import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  aggregatorDetails,
  aggregatorDetailsReset,
  listAggregatorMarketOrders,
  listAggregatorMarketOrdersReset,
  aggregatorMarketOrdersCurrentPage,
} from "../../actions";
import PageTabs from "../../components/PageTabs";
import Details from "./components/Details";
import Market from "./components/Market";
import { isEmpty } from "../../utils";
import { adaptAggregator, adaptMarketOrders } from "../../utils/adapters";
import {useTranslation} from "react-i18next";

// This component is used to display the detail
// of an aggregator
export const RepresentativeAggregatorView = (
  {
    id,
    aggregatorDetails,
    isFetchingAggregator,
    errorMessageAggregator,
    aggregator,
    aggregatorDetailsReset,
    listAggregatorMarketOrders,
    listAggregatorMarketOrdersReset,
    aggregatorMarketOrdersCurrentPage,
    marketOrdersPage,
    marketOrders,
  },
) => {
  // A state to determine the current active  tab
  const [currentTab, setCurrentTab] = useState(0);

  const {t} = useTranslation();

  // On component mount the data are fetched
  useEffect(() => {
    aggregatorDetails(id);
    listAggregatorMarketOrders(id, 1);
    aggregatorMarketOrdersCurrentPage(0);
    return () => {
      aggregatorDetailsReset();
      listAggregatorMarketOrdersReset();
    };
  }, []);

  // It changes the current tab
  const onClickDetailsTab = () => {
    setCurrentTab(0);
  };

  // It changes the current tab
  const onClickMarketTab = () => {
    setCurrentTab(1);
  };

  // On page change of market orders
  const onPageChangeMarketOrders = page => {
    aggregatorMarketOrdersCurrentPage(page);
    listAggregatorMarketOrders(id, page + 1);
  };

  return (
    <PageTabs
      title={t("aggregatorView.title")}
      tabs={[
        {
          id: "details",
          label: t("aggregatorView.tabs.details"),
          onClick: onClickDetailsTab,
        },
        {
          id: "market",
          label: t("aggregatorView.tabs.market"),
          onClick: onClickMarketTab,
        },
      ]}
    >
      <Details
        isVisible={currentTab === 0}
        isFetchingAggregator={isFetchingAggregator}
        errorMessageAggregator={errorMessageAggregator}
        aggregator={aggregator}
      />
      <Market
        isVisible={currentTab === 1}
        isFetchingAggregator={isFetchingAggregator}
        errorMessageAggregator={errorMessageAggregator}
        aggregator={aggregator}
        marketOrders={marketOrders}
        onPageChangeMarketOrders={onPageChangeMarketOrders}
        marketOrdersPage={marketOrdersPage}
      />
    </PageTabs>
  );
};

// propTypes for the RepresentativeAggregatorView component
RepresentativeAggregatorView.propTypes = {
  id: PropTypes.string.isRequired,
  aggregatorDetails: PropTypes.func.isRequired,
  isFetchingAggregator: PropTypes.bool.isRequired,
  errorMessageAggregator: PropTypes.string.isRequired,
  aggregator: PropTypes.object.isRequired,
  aggregatorDetailsReset: PropTypes.func.isRequired,
  listAggregatorMarketOrders: PropTypes.func.isRequired,
  listAggregatorMarketOrdersReset: PropTypes.func.isRequired,
  aggregatorMarketOrdersCurrentPage: PropTypes.func.isRequired,
  marketOrdersPage: PropTypes.number.isRequired,
  marketOrders: PropTypes.array.isRequired,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = (state, props) => {
  return {
    // Properties related to Aggregator id
    id: props.match.params.id,
    // Loading and error messages properties
    isFetchingAggregator:
      state.aggregatorDetails.isFetching ||
      state.listAggregatorMarketOrders.isFetching ||
      isEmpty(state.aggregatorDetails.data),
    errorMessageAggregator:
      state.aggregatorDetails.errorMessage||
      state.listAggregatorMarketOrders.errorMessage,
    // Aggregator details properties
    aggregator: adaptAggregator(state.aggregatorDetails.data),
    // Properties related to linked entities
    marketOrders: adaptMarketOrders(state.listAggregatorMarketOrders.data.results),
    // Pagination properties
    marketOrdersPage: state.aggregatorMarketOrdersCurrentPage.number,
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    aggregatorDetails: id => dispatch(aggregatorDetails(id)),
    aggregatorDetailsReset: () => dispatch(aggregatorDetailsReset()),
    listAggregatorMarketOrders: (aggregator, page) =>
      dispatch(listAggregatorMarketOrders({ aggregator, page })),
    listAggregatorMarketOrdersReset: () => dispatch(listAggregatorMarketOrdersReset()),
    aggregatorMarketOrdersCurrentPage: page => dispatch(aggregatorMarketOrdersCurrentPage(page)),
  };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(RepresentativeAggregatorView);
