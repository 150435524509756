import React from "react";
import PropTypes from "prop-types";

import { displayErrorHelpText, getCountryObjectFromCache } from "../../utils";
import style from "./style.scss";

// A wrapper of the TextInput component form WFP UI Kit
const PhoneInput = ({
  id,
  label,
  placeholder,
  errorMessage,
  isDisabled,
  input: { value, onFocus, onChange },
  meta: { error },
}) => {
  const country = getCountryObjectFromCache();
  const prefix = `+${country.phone_prefix}`;
  const flag = country.flag;
  return (
    <div className="wfp--form-item">
      {label ? (
        <label htmlFor={id} className="wfp--label">
          {label}
        </label>
      ) : (
        ""
      )}
      <div className={style.wrapper}>
        <span className={style.countryFlag}>{flag}</span>
        <span className={style.countryCode}>{prefix}</span>
        <input
          value={value}
          onFocus={onFocus}
          onChange={onChange}
          id={id}
          name={id}
          type="tel"
          placeholder={placeholder}
          className="wfp--text-input"
          disabled={isDisabled}
        />
        <span className="validity"></span>
      </div>
      {displayErrorHelpText(errorMessage || error)}
    </div>
  );
};

// propTypes for the Input component
PhoneInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.any,
  placeholder: PropTypes.string,
  input: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  meta: PropTypes.object,
};

// defaultProps for the Input component
PhoneInput.defaultProps = {
  type: "text",
  label: "",
  placeholder: "",
  errorMessage: "",
  isDisabled: false,
  meta: {},
};

export default PhoneInput;
