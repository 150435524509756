import React from "react";
import { withTranslation } from "react-i18next";
import Modal from "../../../../components/Modal";
import Input from "../../../../components/Input";
import { reduxForm, Field, InjectedFormProps } from "redux-form";
import FormWrapper from "../../../../components/FormWrapper";
import OfferCard from "../OfferCard";
import { Offer } from "../OfferCard/interfaces";
import style from "./style.scss";
import DepositCard from "../../../Deposits/DepositCard";
import ProgressBar from "./ProgressBar";
import { formatNumber } from "../../../../utils";
import { Col, Grid, Row } from "../../../../components/Grid";

export interface RecallProps {
  open: boolean;
  onValidate: () => void;
  offer: Offer;
  t: (s: string) => string;
}

class SeeProgressModal extends React.Component<
  InjectedFormProps<any, RecallProps> & RecallProps
> {
  render() {
    const { open, offer, onValidate, t } = this.props;

    const total =
      offer &&
      offer.orders
        .map((o) => parseInt(o.deposit.quantity))
        .reduce((a, b) => a + b, 0);

    const completed = offer && (total / parseInt(offer.maximum_quantity)) * 100;

    const min =
      offer &&
      (parseInt(offer.minimum_quantity) / parseInt(offer.maximum_quantity)) *
        100;
    return (
      <>
        <Modal
          hideCancelButton={true}
          modalLabel={`${t("marketOfferView.marketOffer")} #${
            offer && offer.id
          }`}
          isOpen={open}
          validateText={t("seeProgressModal.submitButtonText")}
          title={t("seeProgressModal.heading")}
          onValidate={onValidate}
          type={"warning"}
        >
          {(!offer || !offer.orders.length) && (
            <div className={style.instructionText}>
              {t("seeProgressModal.instructionText")}
            </div>
          )}
          {offer?.orders?.length ? (
            <div className={style.deposits}>
              <Grid>
                <Row>
                  <Col className={style.labels} sm={4}>
                    <b>Added:</b> {formatNumber(total)} {offer.unit_of_measure.code}
                  </Col>
                  <Col sm={4}> </Col>
                  <Col className={style.labels} sm={4}>
                    <b>Goal:</b> {formatNumber(offer.minimum_quantity)}&nbsp;
                    {offer.unit_of_measure.code}
                  </Col>
                </Row>
              </Grid>
              <ProgressBar completed={completed} min={min} />
              {offer.orders.map((o) => (
                <DepositCard
                  deposit={o.deposit}
                  onMakeOffer={() => {}}
                  isSummaryCard={true}
                />
              ))}
            </div>
          ) : undefined}
        </Modal>
      </>
    );
  }
}

export default withTranslation()(SeeProgressModal);
