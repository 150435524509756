import React from "react";
import PropTypes from "prop-types";
import { displayErrorHelpText } from "../../utils";
import { Checkbox as WFPCheckbox } from "@wfp/ui";

// A wrapper of the TextInput component form WFP UI Kit
const Checkbox = ({
  id,
  label,
  errorMessage,
  isDisabled,
  defaultValue,
  input: { value, onChange },
  meta: { error },
  labelInFront,
  testid,
}) => {

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        {label && !labelInFront ? (
          <label
            htmlFor={id}
            className="wfp--label"
            style={{
              paddingRight: !labelInFront && "1rem",
            }}
          >
            {label}
          </label>
        ) : (
          ""
        )}
        <WFPCheckbox
          checked={value}
          defaultChecked={defaultValue}
          onChange={onChange}
          id={id}
          name={id}
          disabled={isDisabled}
          hideLabel={true}
          labelText={""}
          data-testid={testid}
        />
        {label && labelInFront ? (
          <label
            htmlFor={id}
            className="wfp--label"
          >
            {label}
          </label>
        ) : (
          ""
        )}
      </div>
      {displayErrorHelpText(errorMessage || error)}
    </div>
  );
};

// propTypes for the Input component
Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.any,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  defaultValue: PropTypes.bool,
  labelInFront: PropTypes.bool,
  testid: PropTypes.string,
};
// defaultProps for the Input component
Checkbox.defaultProps = {
  label: "",
  placeholder: "",
  errorMessage: "",
  isDisabled: false,
  labelInFront: false,
};

export default Checkbox;
