import React from "react";
import PropTypes from "prop-types";

import Module from "../../../../components/Module";
import Loading from "../../../../components/Loading";
import { displayErrorMessage } from "../../../../utils";
import { generateViewButton } from "../../../../utils/table";
import Table from "../../../../components/Table";
import {withTranslation} from "react-i18next";

// The list of columns and their properties
export const columns = (onClickView, t) => [
  {
    Header: "column.aggregator",
    accessor: "aggregator.name",
  },
  {
    Header: "column.status",
    accessor: "status",
    width: 200,
  },
  {
    width: 80,
    Cell: obj => generateViewButton(obj.row.original, onClickView, undefined, t),
  },
];

// The list of Aggregator Creation Requests that user can view
export const List = (
  {
    isFetching,
    errorMessage,
    aggregatorCreationRequests,
    page,
    onPageChange,
    count,
    onClickView,
    t,
  },
) => {

  if (isFetching) {
    return <Loading isVisible />;
  }

  if (errorMessage) {
    return (
      <>
        {displayErrorMessage(errorMessage)}
      </>
    );
  }

  return (
    <>
      <Module
        title={t("aggregatorCreationRequests.list.title")}
        table={
          <Table
            loading={isFetching}
            columns={columns(onClickView, t)}
            data={aggregatorCreationRequests}
            totalItems={count}
            page={page}
            onPageChange={onPageChange}
          />
        }
      />
    </>
  );
};

// propTypes for the List component
List.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  aggregatorCreationRequests: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  onClickView: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

// defaultProps for the List component
List.defaultProps = {
  errorMessage: "",
};

export default withTranslation()(List);
