import React from "react";
import PropTypes from "prop-types";

import Module from "../../../../components/Module";
import Loading from "../../../../components/Loading";
import ShowHideArea from "../../../../components/ShowHideArea";
import { displayErrorMessage } from "../../../../utils";
import { Grid, Row, Col } from "../../../../components/Grid";
import style from "./style.scss";
import {withTranslation} from "react-i18next";

// The details of an aggregator
export const Details = (
  {
    isFetchingAggregator,
    errorMessageAggregator,
    aggregator,
    isVisible,
    t,
  },
) => {

  let content = undefined;

  if (isFetchingAggregator) {
    content = (
      <Loading isVisible />
    );
  } else if (errorMessageAggregator) {
    content = (
      <>
        {displayErrorMessage(errorMessageAggregator)}
      </>
    );
  } else {
    content = (
      <Module title={t("aggregatorView.details.title")}>
        <Grid>
          <Row>
            <Col sm={4}>
              <table className={style.details}>
                <tbody>
                  <tr>
                    <td>{t("column.profile")}</td>
                    <td>{aggregator.org_type}</td>
                  </tr>
                  <tr>
                    <td>{t("column.name")}</td>
                    <td>{aggregator.name}</td>
                  </tr>
                  <tr>
                    <td>{t("column.legalStatus")}</td>
                    <td>{aggregator.legal_status}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col sm={5}>
              <table className={style.details}>
                <tbody>
                  <tr>
                    <td>{t("column.tier")}</td>
                    <td>{aggregator.tier}</td>
                  </tr>
                  <tr>
                    <td>{t("column.commoditiesGrown")}</td>
                    <td>{aggregator.commodities_grown}</td>
                  </tr>
                  <tr>
                    <td>{t("column.contactEmail")}</td>
                    <td>{aggregator.contact_email}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col sm={3}>
              <table className={style.details}>
                <tbody>
                  <tr>
                    <td>{t("column.total", {
                      unit:
                              aggregator.country.land_unit_of_measurement &&
                              aggregator.country.land_unit_of_measurement.code ?
                                `(${aggregator.country.land_unit_of_measurement.code})` :
                                t("column.landSize")})}</td>
                    <td>{aggregator.total_land_size}</td>
                  </tr>
                  <tr>
                    <td>{t("column.totalNoOfShf")}</td>
                    <td>{aggregator.total_no_of_farmers}</td>
                  </tr>
                  <tr>
                    <td>{t("column.totalNoOfFemaleShf")}</td>
                    <td>{aggregator.no_of_women_farmers}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Grid>
      </Module>
    );
  }

  return (
    <ShowHideArea isVisible={isVisible}>
      {content}
    </ShowHideArea>
  );
};

// propTypes for the Details component
Details.propTypes = {
  isFetchingAggregator: PropTypes.bool.isRequired,
  errorMessageAggregator: PropTypes.string.isRequired,
  aggregator: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

// defaultProps for the Details component
Details.defaultProps = {
  errorMessageAggregator: "",
};

export default withTranslation()(Details);
