import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import style from "../SummaryCard/style.scss";

const ReportsHyperlinkCard = ({ links }) => {
  const {t} = useTranslation();

  return (
    <div className={style.card}>
      <div className={style.heading}>
        {t("home.reportsHyperlinkCard.externalReports")}
      </div>
      <div className={style.subheading}>
        <div>
          <a href={links.dotsDashboard} target="_blank" rel="noreferrer noopener">
            {t("home.reportsHyperlinkCard.dotsDashboard")}
          </a>
          <a href={links.tableau} target="_blank" rel="noreferrer noopener">
            {t("home.reportsHyperlinkCard.tableau")}
          </a>
        </div>
        <div>
          <a href={links.yammer} target="_blank" rel="noreferrer noopener">
            {t("home.reportsHyperlinkCard.yammer")}
          </a>
        </div>
      </div>
    </div>
  );
};

ReportsHyperlinkCard.propTypes = {
  links: PropTypes.object.isRequired,
};

export default ReportsHyperlinkCard;
