import React, { useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import Button from "../../../../components/Button";
import { Row } from "../../../../components/Grid";
import PropTypes from "prop-types";
import style from "./style.scss";
import { isEmpty } from "../../../../utils";
import DatePicker from "../../../../components/DatePicker";
import { iconArrowRight, iconCalendar, iconFilterGlyph } from "@wfp/icons";
import Icon from "../../../../components/Icon";
import { Controller, FormProvider, useForm } from "react-hook-form";
import RHFSelect from "../../../../components/Select/SelectUtils";

const adaptInitialFilters = (filters, topics, status) => {
  const data = {
    ...filters,
    content_type: filters.content_type && { label: filters.content_type, value: filters.content_type },
    topic: filters.topic && filters.topic.map((selectedFilter) => topics.find((t) => t.value === selectedFilter)),
    status: filters.status && status.find((s) => s.value === filters.status),
    creation_start: filters.creation_start && new Date(filters.creation_start),
    creation_end: filters.creation_end && new Date(filters.creation_end),
    last_updated_start: filters.last_updated_start && new Date(filters.last_updated_start),
    last_updated_end: filters.last_updated_end && new Date(filters.last_updated_end),
  };
  return data;
};
const Filters = (props) => {
  const { t } = props;
  const [refreshKey, setRefreshKey] = useState(new Date().valueOf());
  const buttonRef = useRef();

  const methods = useForm({defaultValues: adaptInitialFilters(props.filters, props.topics, props.status)});
  const { handleSubmit, control, watch } = methods;
  const getProps = (label, key, options) => (
    { key: `${refreshKey}-${key}`, name: key, label: t(label), options, control}
  );

  const watchValues = watch();

  const onSubmit = (values) => {
    props.updateFilters(values);
  };

  return (
    <div className={style.container}>
      <div className={style.button}>
        <Button small className={style.filterButton} iconReverse icon={iconFilterGlyph} onClick={() => props.setShowFilters(!props.showFilters)}>
          { !props.showFilters ? t("infoHub.filters.showFilters") : t("infoHub.filters.hideFilters")}
        </Button>
      </div>
      {props.showFilters &&
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row start="xs" className={style.options}>
              <RHFSelect {...getProps("infoHub.filters.contentType", "content_type", props.types)}/>
              <RHFSelect {...getProps("infoHub.filters.topic", "topic", props.topics)} isMulti/>
              <RHFSelect {...getProps("infoHub.filters.status", "status", props.status)} />
            </Row>

            <Row start="xs" className={style.options}>
              <Controller
                render={({field}) => (
                  <DatePicker
                    label={t("infoHub.filters.creationRange")}
                    input={field}
                    dateFormat="dd/MM/yyyy"
                    placeholder="Start Date"
                    id="creation_start"
                  />
                )}
                control={control}
                name="creation_start"
              />
              <Icon style={{marginTop: "2rem", marginLeft: "-0.5", marginRight: "0.5rem"}} width={40} height={15} icon={iconArrowRight} />
              <div className={style.noLabelDate}>
                <Row>
                  <Controller
                    render={({field}) => (
                      <DatePicker
                        label={t("")}
                        input={field}
                        dateFormat="dd/MM/yyyy"
                        placeholder="End Date"
                        id="creation_end"
                      />
                    )}
                    control={control}
                    name="creation_end"
                  />
                  <Icon style={{ marginTop: "9px", marginLeft: "1rem", marginRight: "1.5rem"}} icon={iconCalendar} />
                </Row>
              </div>
              <Controller
                render={({field}) => (
                  <DatePicker
                    label={t("infoHub.filters.lastEditRange")}
                    input={field}
                    dateFormat="dd/MM/yyyy"
                    placeholder="Start Date"
                    id="last_updated_start"
                  />
                )}
                control={control}
                name="last_updated_start"
              />
              <Icon style={{marginTop: "2rem", marginLeft: "-0.5", marginRight: "0.5rem"}} width={40} height={15} icon={iconArrowRight} />

              <div className={style.noLabelDate}>
                <Row>
                  <Controller
                    render={({field}) => (
                      <DatePicker
                        input={field}
                        dateFormat="dd/MM/yyyy"
                        placeholder="End Date"
                        id="last_updated_end"
                      />
                    )}
                    control={control}
                    name="last_updated_end"
                  />
                  <Icon style={{ marginTop: "9px", marginLeft: "1rem", marginRight: "1.5rem"}} icon={iconCalendar} />
                </Row>
              </div>
            </Row>

            <Row className={style.filterActionButtons}>
              <Button
                kind="ghost"
                small={true}
                onClick={() => {
                  setRefreshKey(new Date().valueOf());
                  props.clearFilters();
                }}
                disabled={isEmpty(watchValues)}
              >
                {t("filters.clearAll")}
              </Button>
              <Button
                onClick={() => buttonRef.current.click()}
                disabled={isEmpty(watchValues)}
                small={true}
              >
                {t("filters.apply")}
              </Button>
            </Row>
            <button type="submit" ref={buttonRef} style={{display: "none"}}/>
          </form>
        </FormProvider>
      }
    </div>
  );
};

Filters.propTypes = {
  filters: PropTypes.object,
  setFilters: PropTypes.func,
  updateFilters: PropTypes.func,
  clearFilters: PropTypes.func,
  types: PropTypes.array,
  topics: PropTypes.array,
  status: PropTypes.array,
  t: PropTypes.func,
  showFilters: PropTypes.bool,
  setShowFilters: PropTypes.func,
  listInfoHubSubjects: PropTypes.func,
};

export default withTranslation()(Filters);
