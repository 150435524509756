import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Module from "../../../../components/Module";
import Loading from "../../../../components/Loading";
import { displayErrorMessage } from "../../../../utils";
import { generateViewButton } from "../../../../utils/table";
import { OfferStatus } from "../../../RepresentativeHome/components/OfferCard/interfaces";
import NewTable from "../../../../components/NewTable";

// The list of Traders that user can view and delete
export const List = (
  {
    isFetching,
    listTradersErrorMessage,
    listMarketOffersErrorMessage,
    listTraderRepresentativesErrorMessage,
    traders,
    page,
    pageSize,
    onPageChange,
    count,
    onClickView,
    onClickEdit,
    offers,
  },
) => {
  const { t } = useTranslation();

  if (isFetching) {
    return <Loading isVisible />;
  }

  if (
    listTradersErrorMessage
    || listMarketOffersErrorMessage
    || listTraderRepresentativesErrorMessage
  ) {
    return (
      <>
        {displayErrorMessage(listTradersErrorMessage)}
        {displayErrorMessage(listMarketOffersErrorMessage)}
        {displayErrorMessage(listTraderRepresentativesErrorMessage)}
      </>
    );
  }

  const getRelevantOffers = (traderId) => offers.filter(
    (offer) => traderId === offer.trader.id,
  );

  const columns = [
    {
      Header: t("column.trader"),
      accessor: "name",
    },
    {
      Header: t("column.administrativeArea"),
      accessor: "location_details.name",
    },
    {
      Header: t("column.physicalAddress"),
      accessor: "physical_address",
    },
    {
      id: "offersMade",
      Header: t("column.offersMade"),
      Cell: (obj) => getRelevantOffers(obj.row.original.id).length,
      alignRight: true,
    },
    {
      id: "offersExchanged",
      Header: t("column.offersExchanged"),
      Cell: (obj) => getRelevantOffers(obj.row.original.id).filter((offer) => (
        offer.status === OfferStatus.ExchangeDone
      )).length,
      alignRight: true,
    },
    {
      id: "viewButton",
      width: 80,
      Cell: obj => generateViewButton(obj.row.original, onClickView, undefined, t),
      alignRight:true,
    },
    {
      id: "editButton",
      width: 80,
      Cell: obj => generateViewButton(obj.row.original.id, onClickEdit, t("common.edit"), t),
      alignCentre:true,
    },
  ];

  return (
    <>
      <Module
        table={
          <NewTable
            loading={isFetching}
            columns={columns}
            data={traders}
            totalItems={count}
            pageIndex={page}
            pageSize={pageSize}
            onPageChange={onPageChange}
          />
        }
      />
    </>
  );
};

// propTypes for the List component
List.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  listTradersErrorMessage: PropTypes.string,
  listMarketOffersErrorMessage: PropTypes.string,
  listTraderRepresentativesErrorMessage: PropTypes.string,
  traders: PropTypes.array.isRequired,
  offers: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  onClickView: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
};

// defaultProps for the List component
List.defaultProps = {
  listTradersErrorMessage: "",
  listMarketOffersErrorMessage: "",
  listTraderRepresentativesErrorMessage: "",
  traders: [],
  offers: [],
};

export default List;
