import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { iconCheckmark } from "@wfp/icons";
import { withRouter } from "react-router-dom";

import {
  aggregatorCreationRequestDetails,
  aggregatorCreationRequestApprove,
  aggregatorCreationRequestApproveReset,
  aggregatorCreationRequestReject,
  aggregatorCreationRequestRejectReset,
} from "../../actions";
import PageStandard from "../../components/PageStandard";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import Button from "../../components/Button";
import Details from "./components/Details";
import { isEmpty } from "../../utils";
import { adaptAggregatorCreationRequest } from "../../utils/adapters";
import {useTranslation} from "react-i18next";

// This component is used to display the detail
// of an aggregator creation request
export const WfpManagerAggregatorCreationRequestView = (
  {
    id,
    aggregatorCreationRequestDetails,
    isFetching,
    errorMessage,
    aggregatorCreationRequest,
    aggregatorCreationRequestApprove,
    aggregatorCreationRequestApproveReset,
    aggregatorCreationRequestReject,
    aggregatorCreationRequestRejectReset,
    history,
  },
) => {

  const {t} = useTranslation();

  // On component mount the data are fetched
  useEffect(() => {
    aggregatorCreationRequestDetails(id);
    return () => {
      aggregatorCreationRequestApproveReset();
      aggregatorCreationRequestRejectReset();
    };
  }, []);

  // On Click approve request the status changes to approved
  const onClickApprove = () => {
    aggregatorCreationRequestApprove(id).then(() => {
      history.push("/wfpManager/wfpManagerAggregatorCreationRequests");

    });
  };

  // On Click reject request the status changes to rejected
  const onClickReject = () => {
    aggregatorCreationRequestReject(id).then(() => {
      history.push("/wfpManager/wfpManagerAggregatorCreationRequests");

    });
  };

  return (
    <PageStandard
      title={t("aggregatorCreationRequestView.title")}
      additional={
        aggregatorCreationRequest.status === "submitted" ? (
          <ButtonsWrapper>
            <Button onClick={onClickReject}>{t("common.reject")}</Button>
            <Button icon={iconCheckmark} onClick={onClickApprove}>{t("common.approve")}</Button>
          </ButtonsWrapper>
        ) : undefined
      }
    >
      <Details
        isFetching={isFetching}
        errorMessage={errorMessage}
        aggregatorCreationRequest={aggregatorCreationRequest}
      />
    </PageStandard>
  );
};

// propTypes for the WfpManagerAggregatorCreationRequestView component
WfpManagerAggregatorCreationRequestView.propTypes = {
  id: PropTypes.string.isRequired,
  aggregatorCreationRequestDetails: PropTypes.func.isRequired,
  aggregatorCreationRequestApprove: PropTypes.func.isRequired,
  aggregatorCreationRequestApproveReset: PropTypes.func.isRequired,
  aggregatorCreationRequestReject: PropTypes.func.isRequired,
  aggregatorCreationRequestRejectReset: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  aggregatorCreationRequest: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = (state, props) => {
  return {
    // Properties related to aggregator creation request details
    id: props.match.params.id,
    isFetching:
      state.aggregatorCreationRequestDetails.isFetching ||
      state.aggregatorCreationRequestApprove.isLoading ||
      state.aggregatorCreationRequestReject.isLoading ||
      isEmpty(state.aggregatorCreationRequestDetails.data),
    errorMessage:
      state.aggregatorCreationRequestDetails.errorMessage ||
      state.aggregatorCreationRequestApprove.errorMessage ||
      state.aggregatorCreationRequestReject.errorMessage,
    aggregatorCreationRequest:
      adaptAggregatorCreationRequest(state.aggregatorCreationRequestDetails.data),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    aggregatorCreationRequestDetails: id => dispatch(aggregatorCreationRequestDetails(id)),
    aggregatorCreationRequestApprove: id =>
      dispatch(aggregatorCreationRequestApprove(id, undefined, "approve")),
    aggregatorCreationRequestApproveReset: () => dispatch(aggregatorCreationRequestApproveReset()),
    aggregatorCreationRequestReject: id =>
      dispatch(aggregatorCreationRequestReject(id, undefined, "reject")),
    aggregatorCreationRequestRejectReset: () => dispatch(aggregatorCreationRequestRejectReset()),
  };
};

// The component uses the redux store
export default
connect(mapStateToProps, mapDispatchToProps)(withRouter(WfpManagerAggregatorCreationRequestView));
