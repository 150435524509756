import React from "react";
import PropTypes from "prop-types";

import { Grid, Row, Col } from "../../../../components/Grid";
import Modal from "../../../../components/Modal";
import {withTranslation} from "react-i18next";

// A modal used to inform user about a successful upload farmer from a file
export const SuccessUploadFarmersModal = (
  {
    informationMessage,
    isModalOpen,
    onClickValidate,
    t,
  },
) => {

  return (
    <Modal
      isOpen={isModalOpen}
      title={t("aggregatorDetails.successUploadModal.title")}
      onValidate={onClickValidate}
      hideCancelButton
    >
      <Grid>
        <Row>
          <Col sm={12}>
            {informationMessage}
          </Col>
        </Row>
      </Grid>
    </Modal >
  );
};

// propTypes for the SuccessUploadFarmersModal component
SuccessUploadFarmersModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClickValidate: PropTypes.func.isRequired,
  informationMessage: PropTypes.string,
  t: PropTypes.func.isRequired,
};

// defaultProps for the SuccessUploadFarmersModal component
SuccessUploadFarmersModal.defaultProps = {
  errorMessage: "",
  informationMessage: "",
};

export default withTranslation()(SuccessUploadFarmersModal);
