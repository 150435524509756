import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../../components/Modal";
import PropTypes from "prop-types";
import Form from "../Form";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  addAdministrativeAreasDetails,
  administrativeAreasDetails,
  administrativeAreasDetailsReset,
  updateAdministrativeAreasDetails,
} from "../../../../actions";
import { adaptInitialFormValues, getTitleName } from "../../../../utils/wfpManagerConfigurationUtils";
import { getCountryObjectFromCache } from "../../../../utils";

export const adaptFormValues = (formValues) => {
  return {
    ...formValues,
    parent: formValues.parent.value,
    country: getCountryObjectFromCache().id,
    type: formValues.type.value,
  };
};

export const formatParentValues = (
  initialFormValues,
  modalOptions,
  administrativeAreas,
  administrativeAreaTypes,
  t,
) => {
  const data = adaptInitialFormValues(initialFormValues, modalOptions.id);
  let parent;
  let type;

  if(data){
    parent = data.parent !== null ? administrativeAreas.find(area => area.id === data.parent) : undefined;
    type = data.type !== null ? administrativeAreaTypes.find(type => type.id === data.type) : undefined;
  }
  return {
    ...data,
    parent: parent ? {
      label: parent.name,
      value: parent.id,
    } : {
      label: t("administrativeAreaTypes.noParent"),
      value: null,
    },
    type: type ? {
      label: type.name,
      value: type.id,
    } : {
      label: t("administrativeArea.type"),
      value: null,
    },
  };
};

export const AdministrativeAreasEditModal = ({
  administrativeAreas,
  administrativeAreaTypes,
  initialFormValues,
  isFetching,
  errorMessage,
  modalOptions,
  setModalOptions,
  administrativeAreasDetails,
  administrativeAreasDetailsReset,
  updateAdministrativeAreasDetails,
  addAdministrativeAreasDetails,
}) => {
  const { t } = useTranslation();
  const buttonRef = useRef();
  const [initialValues, setInitialValues] = useState(undefined);

  useEffect(() => {
    administrativeAreasDetails(modalOptions.id);
    return () => {
      administrativeAreasDetailsReset();
    };
  }, [modalOptions.id]);

  useEffect(() => {
    setInitialValues( modalOptions.type !== "create" ? formatParentValues(initialFormValues, modalOptions, administrativeAreas, administrativeAreaTypes, t) : undefined);
  }, [initialFormValues]);

  const onSubmitEdit = (formValues) => {
    updateAdministrativeAreasDetails(modalOptions.id, formValues);
  };

  const onSubmitCreate = (formValues) => {
    addAdministrativeAreasDetails(formValues);
  };

  const onSubmit = (formValues) => {
    const adaptedValues = adaptFormValues(formValues);
    switch(modalOptions.type){
    case "edit":
      onSubmitEdit(adaptedValues);
      break;
    case "create":
      onSubmitCreate(adaptedValues);
      break;
    default:
      break;
    }
    setModalOptions({ type: undefined, id: undefined, isOpen: false });
  };

  return (
    <Modal
      isOpen={modalOptions.isOpen}
      title={
        t("configuration.item.administrativeAreas") + " " + t(getTitleName(modalOptions.type))
      }
      onCancel={() => setModalOptions({ type: undefined, isOpen: false, id: undefined })}
      onValidate={() => buttonRef.current.click()}
      hideCancelButton={modalOptions.type === "view" ? true : false}
      validateText={modalOptions.type !== "view" ? t("negotiationCard.confirm") : t("common.back")}
    >
      <Form
        id={modalOptions.id}
        initialValues={initialValues}
        isFetching={isFetching}
        errorMessage={errorMessage}
        modalOptions={modalOptions}
        onSubmit={onSubmit}
        buttonRef={buttonRef}
        administrativeAreas={administrativeAreas}
        administrativeAreaTypes={administrativeAreaTypes}
      />
    </Modal>
  );
};
AdministrativeAreasEditModal.defaultProps = {};

AdministrativeAreasEditModal.propTypes = {
  initialFormValues: PropTypes.object,
  isFetching: PropTypes.bool,
  errorMessage: PropTypes.string,
  modalOptions: PropTypes.object,
  setModalOptions: PropTypes.func,
  administrativeAreasDetails: PropTypes.func.isRequired,
  administrativeAreasDetailsReset: PropTypes.func.isRequired,
  updateAdministrativeAreasDetails: PropTypes.func.isRequired,
  addAdministrativeAreasDetails: PropTypes.func.isRequired,
  administrativeAreas: PropTypes.array,
  administrativeAreaTypes: PropTypes.array,
};

export const mapStateToProps = (state) => {
  return {
    initialFormValues: state.administrativeAreasDetail.data,
    isFetching: state.administrativeAreasDetail.isFetching,
    errorMessage: state.administrativeAreasDetail.errorMessage,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    administrativeAreasDetails: (id) => dispatch(administrativeAreasDetails(id)),
    administrativeAreasDetailsReset: () => dispatch(administrativeAreasDetailsReset()),
    addAdministrativeAreasDetails: (values) =>
      dispatch(addAdministrativeAreasDetails(values, "", true)),
    updateAdministrativeAreasDetails: (id, values) =>
      dispatch(updateAdministrativeAreasDetails(id, values, "", true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdministrativeAreasEditModal);
