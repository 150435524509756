// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_smallLabel_tjdNzbJahBZrnMcP__9s {\n  font-family: \"Open Sans\", sans-serif;\n  font-size: 0.7rem;\n  color: #031c2d;\n  font-weight: 600;\n  display: inline-block;\n  vertical-align: baseline;\n  margin-top: 0.1rem;\n  margin-bottom: 0.6rem;\n}\n\n.style_wrapper_XaKJTTJ5gtsAr8IvSwky {\n  width: 100%;\n}\n\n.style_helperText_Taw0Kd6ynYkECzcCpIuL {\n  font-size: 0.75rem;\n  color: var(--text-02);\n  line-height: 1.5;\n  z-index: 0;\n  max-height: 3rem;\n  margin-bottom: 0.5rem;\n}", "",{"version":3,"sources":["webpack://./src/components/Select/style.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;EACA,qBAAA;EACA,wBAAA;EACA,kBAAA;EACA,qBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,kBAAA;EACA,qBAAA;EACA,gBAAA;EACA,UAAA;EACA,gBAAA;EACA,qBAAA;AACF","sourcesContent":[".smallLabel {\n  font-family: \"Open Sans\",sans-serif;\n  font-size: 0.7rem;\n  color: #031c2d;\n  font-weight: 600;\n  display: inline-block;\n  vertical-align: baseline;\n  margin-top: .1rem;\n  margin-bottom: .6rem;\n}\n\n.wrapper {\n  width: 100%;\n}\n\n.helperText { \n  font-size: 0.75rem;\n  color: var(--text-02);\n  line-height: 1.5;\n  z-index: 0;\n  max-height: 3rem;\n  margin-bottom: 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"smallLabel": "style_smallLabel_tjdNzbJahBZrnMcP__9s",
	"wrapper": "style_wrapper_XaKJTTJ5gtsAr8IvSwky",
	"helperText": "style_helperText_Taw0Kd6ynYkECzcCpIuL"
};
export default ___CSS_LOADER_EXPORT___;
