import React from "react";
import { withRouter } from "react-router-dom";
import { iconLocked } from "@wfp/icons";

import Icon from "../../components/Icon";
import style from "./style.scss";
import {useTranslation} from "react-i18next";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import Button from "../../components/Button";
import PropTypes from "prop-types";

// A page displayed when user navigate to login forbidden route
export const LoginForbidden = ({ history }) => {
  const {t} = useTranslation();

  return (
    <div className={style.wrapper}>
      <div>
        <div className={style.icon}>
          <Icon
            icon={iconLocked}
            width="200"
            height="200"
          />
        </div>
        <h1 className="wfp--story__title">{t("loginForbidden.title")}</h1>
        <h3 className="wfp--story__body">{t("loginForbidden.tryToGoBackTo")}</h3>
        <ButtonsWrapper>
          <Button onClick={() => history.push("/login")}>
            {`${t("loginForbidden.loginPage")}`}
          </Button>
        </ButtonsWrapper>
      </div>
    </div>
  );
};

LoginForbidden.propTypes = {
  history: PropTypes.object.isRequired,
};

// The router is used to allow user to navigate to home page
export default withRouter(LoginForbidden);
