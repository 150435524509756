// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_tooltip_tLBX8fBhIMb6wYbLCO5o {\n  top: 7px;\n  position: relative;\n  margin-left: 0.5rem;\n}\n\n.style_boldStart_ZPti4sSUljBAKBnMR71W {\n  font-weight: bold;\n}", "",{"version":3,"sources":["webpack://./src/containers/InfoHub/Components/Form/Components/InfoHubContentTypeStep/style.scss"],"names":[],"mappings":"AAAA;EACE,QAAA;EACA,kBAAA;EACA,mBAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".tooltip {\n  top: 7px;\n  position: relative;\n  margin-left: 0.5rem;\n};\n\n.boldStart {\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "style_tooltip_tLBX8fBhIMb6wYbLCO5o",
	"boldStart": "style_boldStart_ZPti4sSUljBAKBnMR71W"
};
export default ___CSS_LOADER_EXPORT___;
