import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import {
  listAggregatorCreationRequests,
  listAggregatorCreationRequestsReset,
  aggregatorCreationRequestCurrentPage,
} from "../../actions";
import PageStandard from "../../components/PageStandard";
import List from "./components/List";
import { valueOrDefault } from "../../utils";
import { adaptWfpManagerAggregatorCreationRequests } from "../../utils/adapters";
import {withTranslation} from "react-i18next";

// This component is used to display the list
// of aggregator creation requests for the Trader Organization
export const WfpManagerAggregatorCreationRequests = (
  {
    isFetching,
    errorMessage,
    aggregatorCreationRequests,
    page,
    count,
    listAggregatorCreationRequests,
    listAggregatorCreationRequestsReset,
    history,
    aggregatorCreationRequestCurrentPage,
    t,
  },
) => {
  // On component mount the data are fetched
  useEffect(() => {
    listAggregatorCreationRequests(1);
    return () => {
      listAggregatorCreationRequestsReset();
      aggregatorCreationRequestCurrentPage(0);
    };
  }, []);

  // A function used to manage pagination of aggregators page
  const onPageChange = page => {
    aggregatorCreationRequestCurrentPage(page);
    listAggregatorCreationRequests(page + 1);
  };

  // On click view aggregator the detail is displayed
  const onClickView = record => {
    history.push(`/wfpManager/wfpManagerAggregatorCreationRequestView/${record.id}`);
  };

  return (
    <PageStandard title={t("aggregatorCreationRequests.title")}>
      <List
        isFetching={isFetching}
        errorMessage={errorMessage}
        aggregatorCreationRequests={aggregatorCreationRequests}
        page={page}
        onPageChange={onPageChange}
        count={count}
        onClickView={onClickView}
      />
    </PageStandard>
  );
};

// propTypes for the WfpManagerAggregatorCreationRequests component
WfpManagerAggregatorCreationRequests.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  aggregatorCreationRequests: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  listAggregatorCreationRequests: PropTypes.func.isRequired,
  listAggregatorCreationRequestsReset: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  aggregatorCreationRequestCurrentPage: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = state => {
  return {
    // Aggregator Creation Requests management
    isFetching: state.listAggregatorCreationRequests.isFetching,
    errorMessage: state.listAggregatorCreationRequests.errorMessage,
    aggregatorCreationRequests:
      adaptWfpManagerAggregatorCreationRequests(state.listAggregatorCreationRequests.data.results),
    page: state.aggregatorCreationRequestCurrentPage.number,
    count: valueOrDefault(state.listAggregatorCreationRequests.data.count, 0),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    listAggregatorCreationRequests: page => dispatch(listAggregatorCreationRequests({ page })),
    listAggregatorCreationRequestsReset: () => dispatch(listAggregatorCreationRequestsReset()),
    aggregatorCreationRequestCurrentPage: page => dispatch(
      aggregatorCreationRequestCurrentPage(page),
    ),
  };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(withTranslation()(WfpManagerAggregatorCreationRequests)));
