import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PageStandard from "../../components/PageStandard";
import { getCountryObjectFromCache, getDeletedItemName, valueOrDefault } from "../../utils";
import List from "./components/List";
import PropTypes from "prop-types";
import { deleteExchangeLocationsDetails,
  exchangeLocationsDetailsReset,
  listExchangeLocations,
  listExchangeLocationsReset,
  listExchangeLocationTypes,
  listExchangeLocationTypesReset,
  listTraders,
  listTradersReset,
  treeAdministrativeAreas,
} from "../../actions";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import Button from "../../components/Button";
import { iconAdd } from "@wfp/icons";
import ExchangeLocationsEditModal from "./components/Modal";
import Modal from "../../components/Modal";
import { adaptAdministrativeAreas } from "../../utils/adapters";

export const WfpManagerExchangeLocationsView = ({
  isFetching,
  errorMessage,
  exchangeLocations,
  page,
  count,
  listExchangeLocationTypes,
  listExchangeLocationTypesReset,
  listExchangeLocations,
  listExchangeLocationsReset,
  exchangeLocationsDetailsReset,
  deleteExchangeLocationsDetails,
  exchangeLocationTypes,
  traders,
  listTraders,
  listTradersReset,
  administrativeAreas,
  treeAdministrativeAreas,
}) => {
  const { t } = useTranslation();
  const [modalOptions, setModalOptions] = useState({isOpen: false, id: undefined, type: undefined});
  const [deleteModalOptions, setDeleteModalOptions] = useState({isOpen: false, id: undefined});

  // On component mount the data are fetched
  useEffect(() => {
    listExchangeLocationTypes();
    listExchangeLocations();
    listTraders();
    const country = getCountryObjectFromCache();
    if (country) {
      treeAdministrativeAreas(country.id);
    }

    return () => {
      listTradersReset();
      listExchangeLocationsReset();
      listExchangeLocationTypesReset();
    };
  }, []);

  const onClickAdd = () => {
    exchangeLocationsDetailsReset();
    setModalOptions({isOpen: true, id: "add", type: "create"});
    listExchangeLocations();
  };

  const onDeleteSubmit = () => {
    deleteExchangeLocationsDetails(deleteModalOptions.id);
    setDeleteModalOptions({isOpen: false, id: undefined});
    listExchangeLocations();
  };

  return (
    <PageStandard
      title={t("configuration.item.exchangeLocations")}
      additional={(
        <ButtonsWrapper>
          <Button icon={iconAdd} onClick={onClickAdd}>
            {t("common.add") + " " + t("configuration.item.exchangeLocations")}
          </Button>
        </ButtonsWrapper>
      )}
    >
      <List
        {...{
          isFetching,
          errorMessage,
          exchangeLocations,
          page,
          count,
          setModalOptions,
          setDeleteModalOptions,
          administrativeAreas,
          exchangeLocationTypes: exchangeLocationTypes.sort((f1, f2) => f1.name.localeCompare(f2.name)),
        }}
      />
      <ExchangeLocationsEditModal
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        traders={traders}
        exchangeLocationTypes={exchangeLocationTypes}
        administrativeAreas={administrativeAreas}
      />
      <Modal
        isOpen={deleteModalOptions.isOpen}
        onValidate={onDeleteSubmit}
        title={t("common.delete") + " " + t("configuration.item.farmerDeletionReasons")}
        danger={true}
        onCancel={() => setDeleteModalOptions({isOpen: false, id: undefined})}
        isLoading={false}
      >
        {t("configuration.common.deleteText") + getDeletedItemName(deleteModalOptions.id, exchangeLocations, "name")}
      </Modal>
    </PageStandard>
  );
};

WfpManagerExchangeLocationsView.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  traders: PropTypes.array.isRequired,
  treeAdministrativeAreas: PropTypes.func.isRequired,
  administrativeAreas: PropTypes.array.isRequired,
  exchangeLocations: PropTypes.array.isRequired,
  exchangeLocationTypes: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  listTraders: PropTypes.func.isRequired,
  listTradersReset: PropTypes.func.isRequired,
  listExchangeLocations: PropTypes.func.isRequired,
  listExchangeLocationsReset: PropTypes.func.isRequired,
  listExchangeLocationTypes: PropTypes.func.isRequired,
  listExchangeLocationTypesReset: PropTypes.func.isRequired,
  exchangeLocationsDetailsReset: PropTypes.func.isRequired,
  deleteExchangeLocationsDetails: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    traders: state.listTraders.data.results,
    exchangeLocations: state.listExchangeLocations.data.results,
    exchangeLocationTypes: state.listExchangeLocationTypes.data.results,
    administrativeAreas: adaptAdministrativeAreas(
      state.treeAdministrativeAreas.data.administrative_areas,
    ),
    isFetching: state.listExchangeLocations.isFetching || state.listExchangeLocationTypes.isFetching,
    errorMessage: state.listExchangeLocations.errorMessage || state.listExchangeLocationTypes.errorMessage,
    page: 1,
    count: valueOrDefault(state.listExchangeLocations.data.results.length, 0),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    listTraders: () => dispatch(listTraders()),
    listTradersReset: () => dispatch(listTradersReset()),
    listExchangeLocations: () => dispatch(listExchangeLocations()),
    listExchangeLocationsReset: () => dispatch(listExchangeLocationsReset()),
    listExchangeLocationTypes: () => dispatch(listExchangeLocationTypes()),
    listExchangeLocationTypesReset: () => dispatch(listExchangeLocationTypesReset()),
    treeAdministrativeAreas: (countryId) =>
      dispatch(treeAdministrativeAreas({}, `${countryId}/administrative_areas/`)),
    exchangeLocationsDetailsReset: () => dispatch(exchangeLocationsDetailsReset()),
    deleteExchangeLocationsDetails: (id) => dispatch(deleteExchangeLocationsDetails(id, true)),
  };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(WfpManagerExchangeLocationsView);
