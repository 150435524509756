import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Controller, useForm, FormProvider } from "react-hook-form";

import Input from "../../../../components/Input";
import { MandatoryLabel } from "../../../../components/MandatoryLabel";
import { getMandatoryFieldErrors } from "../../../../utils";
import Checkbox from "../../../../components/Checkbox";
import { setDefaultValues } from "../../../../utils/wfpManagerConfigurationUtils";
import Loading from "../../../../components/Loading";

const defaultValues = {
  code: "",
  name: "",
  active: false,
  metric_conversion: 0,
};

export const resolver = (values, t) => {
  let errors = {};
  if(values.name === "" || !values.name){
    errors.name = t("traderDetails.form.mandatoryFieldBlankError");
  }
  if(values.code === "" || !values.code){
    errors.code = t("traderDetails.form.mandatoryFieldBlankError");
  }
  if(parseFloat(values.metric_conversion) <= 0 || !values.metric_conversion){
    errors.metric_conversion = t("unitsOfMeasure.metricConversionError");
  }
  return {values, errors};
};

const Form = ({ initialValues, t, modalOptions, buttonRef, onSubmit, isFetching }) => {
  const methods = useForm({resolver, context: t});

  const { control, handleSubmit, setValue, getValues, formState: { errors }, reset } = methods;

  useEffect(() => {
    setDefaultValues(modalOptions, initialValues, setValue, reset);
  }, [initialValues]);

  useEffect(() => {
    reset();
  }, [modalOptions.id]);

  if(isFetching){
    return <Loading isVisible />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          render={({ field, fieldState }) => {
            return (
              <Input
                label={<MandatoryLabel label={t("configuration.common.name")} />}
                id={"input-name"}
                name={"name"}
                isDisabled={modalOptions.type === "view"}
                input={field}
                meta={fieldState}
                errorMessage={getMandatoryFieldErrors(errors, t, getValues, "text")}
              />
            );
          }}
          defaultValue={defaultValues.name}
          name="name"
        />
        <Controller
          control={control}
          render={({ field, fieldState }) => {
            return (
              <Input
                name="code"
                id="input-code"
                label={<MandatoryLabel label={t("unitsOfMeasure.code")} />}
                errorMessage={getMandatoryFieldErrors(errors, t, getValues, "code")}
                isDisabled={modalOptions.type === "view"}
                input={field}
                meta={fieldState}
              />
            );
          }}
          defaultValue={defaultValues.code}
          name="code"
        />
        <Controller
          control={control}
          render={({ field, fieldState }) => {
            return (
              <Input
                name="metric_conversion"
                id="input-metric_conversion"
                label={<MandatoryLabel label={t("unitsOfMeasure.metricConversion")} />}
                errorMessage={getMandatoryFieldErrors(errors, t, getValues, "metric_conversion")}
                isDisabled={modalOptions.type === "view"}
                input={field}
                meta={fieldState}
                type="number"
              />
            );
          }}
          defaultValue={defaultValues.metric_conversion}
          name="metric_conversion"
        />
        <Controller
          control={control}
          render={({ field, fieldState }) => {
            return (
              <Checkbox
                label={t("configuration.common.active")}
                id={"active"}
                name={"active"}
                defaultChecked={defaultValues.active}
                isDisabled={modalOptions.type === "view"}
                input={field}
                meta={fieldState}
              />
            );
          }}
          defaultValue={defaultValues.active}
          name="active"
        />
        <button style={{ display: "none" }} ref={buttonRef} />
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  modalOptions: PropTypes.object.isRequired,
  fieldErrors: PropTypes.object,
  initialValues: PropTypes.object,
  t: PropTypes.func.isRequired,
  buttonRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.func.isRequired,
};

export default withTranslation()(Form);
