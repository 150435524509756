import React from "react";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Button from "../../../../components/Button";
import ButtonsWrapper from "../../../../components/ButtonsWrapper";
import FormWrapper from "../../../../components/FormWrapper";
import { Grid, Row } from "../../../../components/Grid";
import Input from "../../../../components/Input";
import { MandatoryLabel } from "../../../../components/MandatoryLabel";
import Module from "../../../../components/Module";
import PhoneInput from "../../../../components/PhoneInput";
import Select from "../../../../components/Select";
// import { TIME_ZONES } from "../../../../constants";
import { isEmpty } from "../../../../utils";
import { formatLanguageLabel, languages } from "../../../../utils/i18n";
import { hasFilledRequiredFields } from "../../../../utils/reduxForm";

import style from "./style.scss";

const fieldGroups = (trader) => [
  {
    title: "profile.form.traderDetails",
    fields: [
      {
        component: Input,
        name: "name",
        id: "name",
        label: "profile.form.traderName",
        mandatory: true,
      },
      {
        component: Input,
        name: "physical_address",
        id: "physical_address",
        label: "profile.form.physicalAddress",
        mandatory: true,
      },
    ],
    readonlyFields: [
      {
        label: "profile.form.administrativeArea",
        value: !isEmpty(trader) && trader.location_details.name,
      },
    ],
  },
  {
    title: "profile.form.traderRepresentativeDetails",
    fields: [
      {
        component: Input,
        name: "first_name",
        id: "first_name",
        label: "profile.form.name",
        mandatory: true,
      },
      {
        component: Input,
        name: "last_name",
        id: "last_name",
        label: "profile.form.surname",
        mandatory: true,
      },
      {
        component: PhoneInput,
        name: "contact_phone",
        id: "contact_phone",
        label: "profile.form.mobileNumber",
      },
      {
        component: Select,
        name: "language",
        id: "language",
        label: "profile.form.preferredLanguage",
        options: languages.map(({ value, ...lang}) => ({
          label: formatLanguageLabel(lang),
          value,
        })),
        mandatory: true,
      },
      // TODO: Finish after backend work is complete.
      // {
      //   component: Select,
      //   name: "tz_name",
      //   id: "tz_name",
      //   label: "profile.form.timezone",
      //   options: TIME_ZONES,
      // },
    ],
  },
];

const Form = ({
  isValid,
  onSubmit,
  t,
  trader,
  // traderRepresentative, // TODO: May be needed again for the TZ stuff.
  /* eslint-disable no-unused-vars */
  setIsValid,
  /* eslint-enable no-unused-vars */
}) => {

  return (
    <Module
      title={t("profile.form.header")}
      footer={
        <ButtonsWrapper>
          <Button
            disabled={!isValid}
            onClick={onSubmit}
          >
            {t("profile.form.updateProfile")}
          </Button>
        </ButtonsWrapper>
      }
    >
      <FormWrapper>
        {fieldGroups(trader).map(group => (
          <div
            key={group.title}
            className={style.fieldGroup}
          >
            <Grid>
              <Row className={style.fieldGroupHeader}>
                {t(group.title)}
              </Row>
              <div className={style.fields}>
                {group.fields.map(({ mandatory, label, ...field }) => (
                  <Row key={group.title + label}>
                    <Field
                      key={group.title + label}
                      label={mandatory ? <MandatoryLabel label={t(label)}/> : t(label)}
                      {...field}
                    />
                  </Row>
                ))}
              </div>
              {group.readonlyFields && (
                <div className={style.readonlyFields}>
                  {group.readonlyFields.map(({ label, value }) => (
                    <Row
                      className={style.readonlyField}
                      key={group.title + label}
                    >
                      <span className={style.readonlyLabel}>
                        {t(label)}:
                      </span>&nbsp;
                      {value}
                    </Row>
                  ))}
                </div>
              )}
            </Grid>
          </div>
        ))}
      </FormWrapper>
    </Module>
  );
};

Form.propTypes = {
  isValid: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  setIsValid: PropTypes.func,
  t: PropTypes.func.isRequired,
  trader: PropTypes.object.isRequired,
  traderRepresentative: PropTypes.object.isRequired,
};

Form.defaultProps = {
  trader: {},
  traderRepresentative: {},
};

const validate = (values, props) => {
  const errors = {};

  // Add validation rules here.
  if (values.contact_phone && values.contact_phone.length > 12) {
    errors.contact_phone = props.t("profile.errors.mobileNumberCannotExceed12Digits");
  }

  const requiredFields = fieldGroups()
    .flatMap(group => group.fields)
    .reduce((acc, cur) =>  cur.mandatory ? [...acc, cur.name] : acc, []);

  hasFilledRequiredFields(requiredFields, values, errors);

  props.setIsValid(isEmpty(errors));
  return errors;
};

export default reduxForm({
  form: "userDetails",
  enableReinitialize: true,
  validate,
})(withTranslation()(Form));
