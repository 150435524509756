import React from "react";
import PropTypes from "prop-types";

import Upload from "../../../../components/Upload";
import FormWrapper from "../../../../components/FormWrapper";
import Loading from "../../../../components/Loading";
import { displayErrorMessage, displayInformationMessage } from "../../../../utils";
import { Grid, Row, Col } from "../../../../components/Grid";
import Modal from "../../../../components/Modal";
import {withTranslation} from "react-i18next";

// A modal used to create and upload farmer from a file
export const UploadFarmersModal = (
  {
    isFetching,
    errorMessage,
    informationMessage,
    isModalOpen,
    onClickValidate,
    onClickClose,
    t,
  },
) => {

  return (
    <Modal
      isOpen={isModalOpen}
      title={t("aggregatorDetails.uploadFarmersModal.title")}
      onValidate={onClickValidate}
      onCancel={onClickClose}
    >
      {
        isFetching ? (
          <Loading isVisible />
        ) : (
          <FormWrapper>
            {displayErrorMessage(errorMessage)}
            {displayInformationMessage(informationMessage)}
            <Grid>
              <Row>
                <Col sm={12}>
                  <Upload name="file"
                    label={t("aggregatorDetails.uploadFarmersModal.fileToUpload")}
                  />
                </Col>
              </Row>
            </Grid>
          </FormWrapper>
        )
      }
    </Modal >
  );
};

// propTypes for the UploadFarmersModal component
UploadFarmersModal.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  onClickValidate: PropTypes.func.isRequired,
  onClickClose: PropTypes.func.isRequired,
  informationMessage: PropTypes.string,
  t: PropTypes.func.isRequired,
};

// defaultProps for the UploadFarmersModal component
UploadFarmersModal.defaultProps = {
  errorMessage: "",
  informationMessage: "",
};

export default withTranslation()(UploadFarmersModal);
