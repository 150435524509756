import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Loading from "../../../../components/Loading";
import Module from "../../../../components/Module";
import Table from "../../../../components/Table";
import { displayErrorMessage } from "../../../../utils";
import { withRouter } from "react-router-dom";
import { boolIconCell, generateDateTimeByUserCell } from "../../../../utils/table";
import { displaySelectedCountryTranslation } from "../../../../utils/i18n";

// The list of Aggregators that user can view and delete
export const List = ({
  isFetching,
  errorMessage,
  notificationEvents,
  notificationTextTemplates,
  page,
  count,
}) => {
  const { t } = useTranslation();

  if (isFetching) {
    return <Loading isVisible />;
  }

  if (errorMessage) {
    return <>{displayErrorMessage(errorMessage)}</>;
  }

  const columns = [
    {
      Header: "systemNotifications.systemEvent",
      accessor: "notification_event",
      Cell: ({value}) => {
        if(notificationEvents.length > 0){
          const matchingEvent = notificationEvents.find((event) => event.name === value);
          return matchingEvent && matchingEvent.label;
        }else{
          return t("systemNotifications.noEventFound");
        }
      },
      width: "100",
    },
    {
      Header: "systemNotifications.smsEnabled",
      accessor: "send_as_sms",
      Cell: boolIconCell,
    },
    {
      Header: "systemNotifications.emailEnabled",
      accessor: "send_as_email",
      Cell: boolIconCell,
    },
    {
      Header: "systemNotifications.sendToFarmers",
      accessor: "send_to_farmers",
      Cell: boolIconCell,
    },
    {
      Header: "systemNotifications.sendToAggregatorAdministrators",
      accessor: "send_to_aggregator_administrators",
      Cell: boolIconCell,
    },
    {
      Header: "systemNotifications.sendToTraderRepresentatives",
      accessor: "send_to_trader_representatives",
      Cell: boolIconCell,
    },
    {
      Header: "systemNotifications.sendToWfpManagers",
      accessor: "send_to_wfp_managers",
      Cell: boolIconCell,
    },
    {
      Header: "systemNotifications.defaultText",
      accessor: "default_text",
      width: 800,
    },
    {
      Header: "systemNotifications.translatedText",
      accessor: "",
      width: 800,
      Cell: ({row: { original }}) => {
        return displaySelectedCountryTranslation(original, "translations", t);
      },
    },
    {
      Header: "configuration.list.column.lastUpdated",
      accessor: "lastUpdated",
      width: 400,
      Cell: ({ row: { original } }) => generateDateTimeByUserCell(original.last_update_datetime, original.last_update_user, t),
    },
    {
      Header: "configuration.list.column.created",
      accessor: "created",
      width: 400,
      Cell: ({ row: { original } }) => generateDateTimeByUserCell(original.creation_datetime, original.creation_user, t),
    },
  ];

  return (
    <>
      <Module
        table={
          <div style={{ overflow: "scroll"}}>
            <Table
              {...{
                columns,
                data: notificationTextTemplates,
                loading: isFetching,
                page,
                totalItems: count,
              }}
            />
          </div>
        }
      />
    </>
  );
};

// propTypes for the List component
List.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  notificationEvents: PropTypes.list,
  notificationTextTemplates: PropTypes.list,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  languages: PropTypes.array.isRequired,
};

// defaultProps for the List component
List.defaultProps = {
  errorMessage: "",
};

export default withTranslation()(withRouter(List));
