import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PageStandard from "../../components/PageStandard";
import { getDeletedItemName, valueOrDefault } from "../../utils";
import List from "./components/List";
import PropTypes from "prop-types";
import { listRecallReasons, listRecallReasonsReset, recallReasonsDetailsReset, deleteRecallReasonsDetails } from "../../actions";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import Button from "../../components/Button";
import {iconAdd} from "@wfp/icons";
import RecallReasonsEditModal from "../WfpManagerRecallReasonsView/components/Modal";
import Modal from "../../components/Modal";

export const WfpManagerRecallReasonsView = ({
  isFetching,
  errorMessage,
  recallReasons,
  page,
  count,
  listRecallReasons,
  listRecallReasonsReset,
  recallReasonsDetailsReset,
  deleteRecallReasonsDetails,
}) => {
  const { t } = useTranslation();
  const [modalOptions, setModalOptions] = useState({isOpen: false, id: undefined, type: undefined});
  const [deleteModalOptions, setDeleteModalOptions] = useState({isOpen: false, id: undefined});

  // On component mount the data are fetched
  useEffect(() => {
    listRecallReasons();
    return () => {
      listRecallReasonsReset();
    };
  }, []);

  const onClickAdd = () => {
    recallReasonsDetailsReset();
    setModalOptions({isOpen: true, id: "add", type: "create"});
    listRecallReasons();
  };

  const onDeleteSubmit = () => {
    deleteRecallReasonsDetails(deleteModalOptions.id);
    setDeleteModalOptions({isOpen: false, id: undefined});
    listRecallReasons();
  };

  return (
    <PageStandard
      additional={(
        <ButtonsWrapper>
          <Button icon={iconAdd} onClick={onClickAdd}>
            {t("common.add") + " " + t("configuration.item.traderOfferRecallReasons")}
          </Button>
        </ButtonsWrapper>
      )}
      title={t("configuration.item.traderOfferRecallReasons")}
    >
      <List
        {...{
          isFetching,
          errorMessage,
          recallReasons,
          page,
          count,
          setModalOptions,
          setDeleteModalOptions,

        }}
      />
      <RecallReasonsEditModal
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
      />
      <Modal
        isOpen={deleteModalOptions.isOpen}
        onValidate={onDeleteSubmit}
        title={t("common.delete") + " " + t("configuration.item.traderOfferRecallReasons")}
        type={"danger"}
        onCancel={() => setDeleteModalOptions({isOpen: false, id: undefined})}
        isLoading={false}
      >
        {t("configuration.common.deleteText") + getDeletedItemName(deleteModalOptions.id, listRecallReasons, "name")}
      </Modal>
    </PageStandard>
  );
};

WfpManagerRecallReasonsView.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  recallReasons: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  listRecallReasons: PropTypes.func.isRequired,
  listRecallReasonsReset: PropTypes.func.isRequired,
  recallReasonsDetailsReset: PropTypes.func.isRequired,
  deleteRecallReasonsDetails: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    recallReasons: state.listRecallReasons.data.results,
    isFetching: state.listRecallReasons.isFetching,
    errorMessage: state.listRecallReasons.errorMessage,
    page: 1,
    count: valueOrDefault(state.listRecallReasons.data.results.length, 0),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    listRecallReasons: () => dispatch(listRecallReasons()),
    listRecallReasonsReset: () => dispatch(listRecallReasonsReset()),
    recallReasonsDetailsReset: () => dispatch(recallReasonsDetailsReset()),
    deleteRecallReasonsDetails: id => dispatch(deleteRecallReasonsDetails(id, true)),
  };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(WfpManagerRecallReasonsView);
