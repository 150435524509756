import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Loading from "../../../../components/Loading";
import { MandatoryLabel } from "../../../../components/MandatoryLabel";
import { Controller, FormProvider, useForm } from "react-hook-form";
import MarkdownEditor from "../../../../components/MarkdownEditor";
import Select from "../../../../components/Select";
// import Input from "../../../../components/Input";
// import { getErrors } from "../../../../utils";

export const resolver = (values, t) => {
  let errors = {};
  if (values.short_text === "" || !values.short_text) {
    errors.short_text = t("traderDetails.form.mandatoryFieldBlankError");
  }
  if (values.long_text === "" || !values.long_text) {
    errors.long_text = t("traderDetails.form.mandatoryFieldBlankError");
  }
  return { values, errors };
};

const Form = ({
  initialValues,
  t,
  modalOptions,
  buttonRef,
  onSubmit,
  isFetching,
}) => {
  const methods = useForm({ resolver, context: t });

  const { control, handleSubmit, setValue, reset } = methods;

  const targetOptions = [
    {
      id: "aggregator_admins_farmers_traders",
      value: "aggregator_admins_farmers_traders",
      label: t(
        "termsAndConditionsText.list.options.aggregatorsTradersAndFarmers",
      ),
    },
    {
      id: "mastercard_foundation_admins",
      value: "mastercard_foundation_admins",
      label: t(
        "termsAndConditionsText.list.options.mastercardFoundationAdmins",
      ),
    },
    {
      id: "mastercard_foundation_participants",
      value: "mastercard_foundation_participants",
      label: t(
        "termsAndConditionsText.list.options.mastercardFoundationParticipants",
      ),
    },
  ];

  useEffect(() => {
    if (initialValues && Object.keys(initialValues).length > 0) {
      setValue("short_text", initialValues && initialValues.short_text);
      setValue("long_text", initialValues && initialValues.long_text);
      setValue(
        "target",
        initialValues &&
          targetOptions.find((option) => option.value === initialValues.target),
      );
    }
  }, [initialValues]);

  useEffect(() => {
    reset();
  }, [modalOptions.id]);

  if (isFetching) {
    return <Loading isVisible />;
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          render={({ field }) => {
            return (
              <Select
                label={
                  <MandatoryLabel
                    label={t("termsAndConditionsText.list.target")}
                  />
                }
                id={"input-target"}
                name={"target"}
                isDisabled={
                  modalOptions.type === "view" ||
                  (initialValues && Object.keys(initialValues).length > 0)
                }
                input={field}
                options={targetOptions}
              />
            );
          }}
          name="target"
        />
        <Controller
          control={control}
          render={({ field }) => {
            return (
              <div style={{ marginBottom: "1rem" }}>
                <div className="wfp--label">
                  <MandatoryLabel
                    label={t("termsAndConditionsText.list.shortText")}
                  />
                </div>
                <MarkdownEditor field={field} />
              </div>
            );
          }}
          name="short_text"
        />
        <Controller
          control={control}
          render={({ field }) => {
            return (
              <>
                <div className="wfp--label">
                  <MandatoryLabel
                    label={t("termsAndConditionsText.list.longText")}
                  />
                </div>
                <MarkdownEditor field={field} />
              </>
            );
          }}
          name="long_text"
        />
        {/* <Controller
          control={control}
          render={({ field, fieldErrors, fieldState }) => {
            return (
              <Input
                label={
                  <MandatoryLabel
                    label={t(
                      "termsAndConditionsText.list.version",
                    )}
                  />
                }
                errorMessage={getErrors(fieldErrors, "version")}
                id="version-input"
                input={field}
                meta={fieldState}
              />
            );
          }}
          name="version"
        /> */}
        <button style={{ display: "none" }} ref={buttonRef} />
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  modalOptions: PropTypes.object.isRequired,
  fieldErrors: PropTypes.object,
  initialValues: PropTypes.object,
  t: PropTypes.func.isRequired,
  buttonRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
};

export default withTranslation()(Form);
