import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { tooltipStyle } from "@wfp/ui";
import Tippy from "@tippy.js/react";
import { iconOverflowMenu } from "@wfp/icons";

import Icon from "../Icon";
import style from "./style.scss";

// The  list of items is generated here
export class PopupMenuItems extends Component {
  render() {
    const { items, tippy } = this.props;
    return (
      <ul className={style.items}>
        {
          items.filter(item => item.visible).map(item => (
            <li
              key={item.key}
              onClick={() => {
                tippy.hide();
                item.onClick(item.data);
              }}
            >
              <Icon
                icon={item.icon}
                width="16"
                height="16"
                fill="#031c2d"
              />
              <span>{item.label}</span>
            </li>
          ))
        }
      </ul>
    );
  }
}

// propTypes for the PopupMenuItems component
PopupMenuItems.propTypes = {
  items: PropTypes.array.isRequired,
  tippy: PropTypes.any.isRequired,
};

// A wrapper of the PopupMenu component form WFP UI Kit
// if no item is visible, the menu is hidden
const PopupMenu = ({ items }) => {
  // A part state used to store the tippy instance
  const [tippy, setTippy] = useState({});

  // A function used to get the tippy instance
  const onCreate = instance => {
    setTippy(instance);
  };

  return items.filter(item => item.visible).length &&
    <Tippy
      {...tooltipStyle}
      interactive
      trigger="click"
      content={<PopupMenuItems items={items} tippy={tippy} />}
      onCreate={onCreate}
    >
      <div >
        <Icon
          icon={iconOverflowMenu}
          width="16"
          height="16"
        />
      </div>
    </Tippy> || "";
};

// propTypes for the PopupMenu component
PopupMenu.propTypes = {
  items: PropTypes.array.isRequired,
};

export default PopupMenu;
