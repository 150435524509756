// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_button_X8OiVAOl5tGxVXCd5ctz {\n  display: flex;\n}\n\n.style_contextMenuItem_OQGCEBaVBmjWI0nHYopN {\n  display: flex;\n  align-items: baseline;\n  padding: 2px;\n}\n.style_contextMenuItemLabel_f5dZLiAi57rmXWXa6uTd {\n  margin-left: 16px;\n  color: #031c2d;\n}\n.style_contextMenuItemSelected_RqRvHTX__PQohWqybHTu {\n  font-weight: 600;\n}\n\n.style_nativeLabel__CrRiNDlzj83xIyaS8lQ {\n  font-style: italic;\n}\n\n.style_navigationButton_hggr4ZKrJZKvKJVjqnob {\n  background-color: #34a3d9;\n}", "",{"version":3,"sources":["webpack://./src/components/WfpManagerMainNavigation/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;EACA,qBAAA;EACA,YAAA;AACF;AACE;EACE,iBAAA;EACA,cAAA;AACJ;AAEE;EACE,gBAAA;AAAJ;;AAIA;EACE,kBAAA;AADF;;AAIA;EACE,yBAAA;AADF","sourcesContent":[".button {\n  display: flex;\n}\n\n.contextMenuItem {\n  display: flex;\n  align-items: baseline;\n  padding: 2px;\n\n  &Label {\n    margin-left: 16px;\n    color: #031c2d;\n  }\n\n  &Selected {\n    font-weight: 600;\n  }\n}\n\n.nativeLabel {\n  font-style: italic;\n}\n\n.navigationButton {\n  background-color: #34a3d9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "style_button_X8OiVAOl5tGxVXCd5ctz",
	"contextMenuItem": "style_contextMenuItem_OQGCEBaVBmjWI0nHYopN",
	"contextMenuItemLabel": "style_contextMenuItemLabel_f5dZLiAi57rmXWXa6uTd",
	"contextMenuItemSelected": "style_contextMenuItemSelected_RqRvHTX__PQohWqybHTu",
	"nativeLabel": "style_nativeLabel__CrRiNDlzj83xIyaS8lQ",
	"navigationButton": "style_navigationButton_hggr4ZKrJZKvKJVjqnob"
};
export default ___CSS_LOADER_EXPORT___;
