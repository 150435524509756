import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import TableComponent from "../../../../components/Table";
import style from "./style.scss";
import Tag from "../../../../components/Tag";
import { capitalizeFirstLetter } from "../../../../utils";
import Button from "../../../../components/Button";
import { generateDateTimeByUserCell } from "../../../../utils/table";
import { withRouter } from "react-router-dom";
import Loading from "../../../../components/Loading";

export const formatSubText = (value, type, key) => {
  if(!value) return "-";
  return (
    <div key={key} className={type === "TOPIC" ? style.subTextTopic : style.subText}>
      {value}
    </div>
  );
};

export const formatHeadingText  = (value) => {
  if (value === undefined) {
    return <div className={style.headingText} />;
  }
  return (
    <div className={style.headingText}>
      {value}
    </div>
  );
};

export const formatStatus = (value, t) => {
  switch (value) {
  case "draft":
    return (
      <Tag type={"warning"}>
        {capitalizeFirstLetter(value)}
      </Tag>
    );
  case "published":
    return (
      <Tag type={"info"}>
        {capitalizeFirstLetter(value)}
      </Tag>
    );
  case "withdrawn":
    return (
      <Tag type={"error"}>
        {capitalizeFirstLetter(value)}
      </Tag>
    );
  default:
    return (
      <Tag type={"error"}>
        {t("infoHub.statusError")}
      </Tag>
    );
  }
};

export const TextButton = ({ index, type, children, onClick}) =>(
  <div onClick={onClick} key={index} className={style[type]}>
    {capitalizeFirstLetter(children)}
  </div>
);

TextButton.propTypes = {
  index: PropTypes.number,
  type: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export const generateActions = (original, t, history, actions, isAllCountries) => {
  const actionComponents = [];
  if(!isAllCountries){

    switch(original.status) {
    case "draft":
      actionComponents.push({
        text: t("infoHub.table.actions.publish"),
        Component: Button,
        type: "primary",
        onClick: () => actions.publish(original.id),
      },
      {
        text: t("infoHub.table.actions.edit"),
        Component: TextButton,
        type: "primary",
        onClick: () => actions.edit(original.id),
      });
      break;
    case "published":
      actionComponents.push({
        text: t("infoHub.table.actions.view"),
        Component: Button,
        type: "secondary",
        onClick: () => actions.view(original.id),
      },
      {
        text: t("infoHub.table.actions.withdraw"),
        Component: TextButton,
        type: "primary",
        onClick: () => actions.withdraw(original.id),
      });
      break;
    case "withdrawn":
      actionComponents.push({
        text: t("infoHub.table.actions.view"),
        Component: Button,
        type: "secondary",
        onClick: () => actions.view(original.id),
      },
      {
        text: t("infoHub.table.actions.resume"),
        Component: TextButton,
        type: "primary",
        onClick: () => actions.edit(original.id),
      });
      break;
    }
  }
  else {
    switch(original.status) {
    case "published":
      actionComponents.push({
        text: t("infoHub.table.actions.copy"),
        Component: Button,
        type: "secondary",
        onClick: () => actions.copy(original.id),
      },
      {
        text: t("infoHub.table.actions.view"),
        Component: TextButton,
        type: "primary",
        onClick: () => actions.view(original.id),
      });
      break;
    case "withdrawn":
      actionComponents.push({
        text: t("infoHub.table.actions.view"),
        Component: TextButton,
        type: "secondary",
        onClick: () => actions.view(original.id),
      });
      break;
    }
  }
  return(
    <div className={style.actionsWrapper}>
      {actionComponents.map(({Component, text, ...props}, index) => {
        return (
          <div key={`action--wrapper--${index}`} className={style.action}>
            <Component key={`${typeof component}-${index}`} {...props} small={true}>
              {text}
            </Component>
          </div>
        );
      })}
    </div>
  );
};

const InfoHubTable = ({
  t,
  data = [],
  columns: additionalColumns = [],
  history,
  isFetching,
  count,
  actions,
  isAllCountries,
}) => {

  const columns = useMemo(() => [
    {
      Header: t("infoHub.table.column.subject"),
      accessor: "name",
      Cell: ({value}) => formatHeadingText(value),
      width: 300,
      disableSortBy: true,
    },
    {
      Header: t("infoHub.table.column.type"),
      accessor: "content_type",
      Cell: ({value}) => formatSubText(value, "TYPE", `content-type-${value}`),
      disableSortBy: true,
    },
    {
      Header: t("infoHub.table.column.topic"),
      accessor: "topics",
      Cell: ({value}) => (value || []) && value.map((item, index) => formatSubText(item.name, "TOPIC", `topic-${index}`)),
      disableSortBy: true,
    },
    ...additionalColumns,
    {
      Header: t("infoHub.table.column.created"),
      accessor: "created",
      Cell: ({row: { original }}) => generateDateTimeByUserCell(original.creation_datetime, original.creation_user, t),
      disableSortBy: true,
    },
    {
      Header: t("infoHub.table.column.lastModified"),
      accessor: "last_modified",
      Cell: ({row: { original }}) => generateDateTimeByUserCell(original.last_update_datetime, original.last_update_user, t),
      // disableSortBy: true,
    },
    {
      Header: t("infoHub.table.column.status"),
      accessor: "status",
      Cell: ({value}) => formatStatus(value, t),
      width: 50,
      disableSortBy: true,
    },
    {
      Header: "",
      accessor: "actions",
      Cell: ({row: { original }}) => generateActions(original, t, history, actions, isAllCountries),
      disableSortBy: true,
    },
  ]);

  if(isFetching){
    return <Loading isVisible/>;
  }

  return (
    <div className={style.table}>
      <TableComponent
        columns={columns}
        data={data}
        showPagination={true}
        totalItems={count}
        sortBy={[{id: "last_modified", desc: false}]}
        showSort={true}
      />
    </div>
  );
};

InfoHubTable.propTypes = {
  t: PropTypes.func.isRequired,
  columns: PropTypes.array,
  data: PropTypes.array,
  history: PropTypes.object,
  isFetching: PropTypes.bool,
  count: PropTypes.number,
  actions: PropTypes.object,
  isAllCountries: PropTypes.bool,
};

export default withTranslation()(withRouter(InfoHubTable));
