import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { setupCountry } from "../../utils/authentication";
import { adaptOptions } from "../../utils/adapters";
import Form from "./components/Form";
import { useForm } from "react-hook-form";

// This component is used to allow user to select a country
// used as filter for the next requests
export const CountrySelection = ({ history }) => {

  const [countries, setCountries] = useState([]);

  const methods = useForm();

  // On component mount the data are read
  useEffect(() => {
    setCountries(
      adaptOptions(JSON.parse(localStorage.getItem("countries")), "code", "name", "flag"),
    );
  }, []);

  // On click validate user is redirected to home page
  // after setup the header containing the selected country
  const onClickValidate = (formValues) => {
    setupCountry(formValues.country.value);
    localStorage.setItem("selected_country", formValues.country.value);
    localStorage.setItem("country_code", formValues.country.value);
    history.push("/wfpManager/");
  };

  return (
    <Form
      countries={countries}
      onClickValidate={onClickValidate}
      methods={methods}
    />
  );
};

// propTypes for the CountrySelection component
CountrySelection.propTypes = {
  history: PropTypes.object.isRequired,
};

// The component uses the redux store
export default withRouter(CountrySelection);
