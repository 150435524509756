// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_wrapper_yjzlwBheQmStfonrPxVw > * + * {\n  margin-left: 1rem;\n}", "",{"version":3,"sources":["webpack://./src/components/ButtonsWrapper/style.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF","sourcesContent":[".wrapper > *+* {\n  margin-left: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "style_wrapper_yjzlwBheQmStfonrPxVw"
};
export default ___CSS_LOADER_EXPORT___;
