import React from "react";

const Map = () => (
  <svg
    style={{ width: "500px", height: "200px" }}
    version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="1280.000000pt" height="640.000000pt"
    viewBox="0 0 1280.000000 640.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,640.000000) scale(0.100000,-0.100000)"
      fill="#ffffff"
      stroke="none"
    >
      <path d="M3165 5991 c-179 -128 -180 -129 -212 -197 -18 -37 -29 -72 -25 -76
5 -5 31 -11 58 -14 l49 -6 50 91 49 90 143 67 c79 37 143 70 143 74 0 7 -69
101 -73 99 -1 0 -83 -58 -182 -128z"
      />
      <path d="M10583 6097 c-17 -7 -34 -15 -37 -18 -3 -3 26 -15 64 -28 54 -17 71
-27 75 -44 3 -12 12 -55 20 -96 9 -41 17 -76 18 -76 6 -5 360 -26 364 -22 6 7
-17 119 -27 130 -4 5 -46 3 -94 -4 l-87 -12 -59 65 c-51 56 -70 69 -130 92
-39 14 -72 26 -73 25 -1 0 -16 -6 -34 -12z"
      />
      <path d="M4541 6060 c-30 -11 -161 -84 -290 -162 -362 -218 -352 -212 -347
-228 2 -8 29 -86 60 -172 30 -87 54 -158 51 -158 -2 0 -61 50 -131 110 -70 61
-130 110 -133 110 -3 -1 -33 -7 -66 -14 l-60 -12 3 -105 c2 -57 4 -105 5 -106
1 -1 31 4 65 12 48 10 65 10 68 1 2 -6 22 -68 43 -136 l38 -125 -50 -13 c-27
-7 -50 -12 -52 -10 -1 2 2 33 8 69 10 70 5 125 -16 152 -11 14 -16 14 -45 2
-30 -13 -32 -17 -21 -36 10 -19 4 -28 -52 -85 -35 -35 -69 -64 -76 -64 -7 0
-20 11 -29 25 -15 23 -14 25 20 60 38 38 52 72 43 108 -4 12 -23 102 -43 200
-20 97 -40 177 -44 177 -5 0 -33 -33 -64 -73 l-56 -73 39 -117 c22 -64 38
-117 35 -117 -2 0 -52 18 -110 40 -59 22 -112 40 -118 40 -6 0 -29 -27 -51
-60 l-40 -60 -187 0 c-104 0 -188 -3 -188 -7 -1 -5 -16 -25 -34 -47 l-33 -39
-48 29 -48 28 -96 -103 -96 -102 -42 6 c-41 7 -42 7 -57 -30 l-16 -37 -81 97
c-44 53 -79 99 -76 102 11 10 68 -9 97 -34 l30 -25 75 17 c137 30 139 60 11
154 -89 66 -97 70 -172 81 -139 19 -134 17 -134 55 0 27 -6 37 -36 54 -19 12
-40 21 -46 21 -6 0 -95 -39 -197 -86 l-186 -87 -144 -203 c-110 -156 -150
-204 -170 -209 -19 -4 -39 -27 -75 -83 l-48 -77 6 -75 c4 -41 12 -80 18 -87
17 -21 51 -15 112 18 33 17 69 29 84 27 23 -3 28 -12 49 -80 21 -69 26 -78 47
-78 41 0 55 11 62 46 3 19 29 63 59 100 l52 66 -18 74 -19 74 66 92 c37 51 77
107 90 125 l24 31 47 -14 c25 -8 48 -16 51 -18 2 -3 -35 -42 -82 -88 l-86 -83
10 -70 c8 -53 17 -76 37 -97 31 -33 55 -35 154 -12 99 22 132 4 67 -37 -26
-15 -43 -18 -76 -14 -51 8 -93 -17 -118 -71 -9 -19 -24 -39 -34 -44 -9 -5 -36
-38 -59 -74 -41 -62 -45 -66 -116 -89 l-73 -25 -38 24 c-27 16 -48 22 -66 18
-54 -11 -112 58 -78 92 15 15 -5 37 -28 30 -29 -10 -37 -42 -21 -89 12 -36 11
-41 -6 -53 -11 -8 -25 -12 -32 -9 -28 10 -65 -21 -143 -120 l-80 -102 -54 5
c-54 4 -54 4 -61 -27 -4 -20 -16 -36 -31 -43 -28 -13 -28 -22 -9 -103 22 -90
27 -85 -78 -85 -89 0 -94 -1 -146 -36 l-53 -36 6 -62 c4 -33 10 -78 13 -99 l6
-38 136 6 136 7 90 92 c83 85 98 96 197 140 81 37 114 47 134 42 15 -4 65 -46
114 -97 48 -49 92 -89 99 -89 6 0 11 -11 11 -25 0 -29 11 -32 32 -7 31 36 29
50 -12 83 -22 18 -42 39 -45 48 -2 9 -19 22 -36 30 -30 13 -69 63 -69 89 0 31
36 18 137 -50 78 -52 108 -78 105 -90 -2 -9 13 -42 33 -74 34 -53 38 -56 68
-50 35 7 47 20 47 54 0 20 5 22 55 22 49 0 95 18 142 55 2 1 -2 15 -9 31 -10
29 -8 35 42 98 49 63 55 67 84 61 57 -12 65 -16 68 -36 2 -11 15 -28 30 -37
25 -15 28 -15 48 3 13 11 18 25 14 37 -5 16 1 18 46 18 48 0 51 -1 45 -21 -6
-17 5 -34 56 -85 71 -71 75 -65 -51 -83 -57 -8 -69 -7 -96 10 l-32 19 -151
-57 -151 -57 0 -33 c0 -22 9 -43 28 -64 l27 -31 61 13 c50 10 73 10 135 -2
l74 -14 0 -35 c0 -31 -7 -39 -87 -98 l-87 -63 -96 25 c-127 34 -243 36 -271 5
-38 -43 -46 -42 -184 18 l-132 56 -46 70 -47 70 -95 -6 c-53 -3 -144 -17 -204
-31 -68 -17 -137 -26 -187 -26 -78 0 -80 -1 -143 -46 -61 -44 -65 -49 -85
-118 l-21 -71 -109 -85 c-126 -98 -116 -73 -126 -325 l-6 -150 93 -91 c58 -57
121 -107 168 -134 l75 -42 141 5 c133 5 144 6 203 36 l63 32 95 -37 c52 -21
98 -45 102 -53 5 -9 11 -54 14 -101 l6 -85 56 -52 55 -52 0 -203 0 -204 49
-146 c35 -104 61 -163 89 -203 33 -45 41 -66 47 -119 l6 -64 76 -7 c73 -7 77
-6 176 36 94 40 110 51 199 138 l97 94 47 141 47 141 93 95 93 96 -19 109
c-11 61 -20 119 -20 130 0 14 53 69 165 170 140 127 172 162 210 227 25 42 45
80 45 83 0 3 -52 5 -116 5 l-116 0 -87 80 c-82 75 -93 91 -193 268 -58 103
-109 197 -113 210 -7 23 -6 23 23 8 17 -9 37 -13 45 -10 15 5 30 -10 33 -32 1
-7 71 -108 155 -225 85 -117 157 -217 161 -222 4 -7 39 -2 95 13 119 31 326
140 359 189 13 20 24 40 24 45 0 5 8 18 17 28 16 18 14 21 -25 61 -23 24 -42
49 -42 56 0 14 -2 14 -88 -17 -29 -11 -63 -17 -76 -15 -13 3 -53 35 -89 71
-52 53 -66 74 -69 102 l-3 35 40 -3 c31 -3 59 -18 125 -68 55 -42 88 -60 94
-54 17 17 64 11 79 -10 19 -27 134 -60 212 -61 134 -1 159 -10 229 -77 58 -56
67 -61 90 -52 28 10 23 19 58 -115 5 -22 27 -61 48 -86 21 -25 53 -76 71 -112
18 -36 42 -70 53 -75 18 -8 24 -3 44 30 12 22 22 55 22 72 0 21 8 40 21 52 12
10 44 49 71 86 30 41 62 71 81 79 18 7 68 33 112 59 44 26 89 52 101 58 18 9
29 4 85 -45 35 -31 77 -79 93 -107 36 -65 32 -61 60 -48 23 10 25 8 51 -50 24
-53 27 -70 23 -129 -5 -70 2 -89 41 -101 8 -2 22 -25 30 -50 11 -33 29 -57 61
-82 24 -19 46 -33 48 -32 2 2 -4 30 -12 62 -11 40 -23 65 -41 77 -14 11 -39
38 -55 62 -24 35 -30 54 -30 95 l0 51 38 -7 c79 -13 162 -70 162 -110 0 -8 3
-14 8 -14 8 0 104 68 136 96 28 25 15 75 -38 145 -51 68 -55 85 -26 122 17 21
45 31 173 61 l153 37 87 80 c48 45 91 88 96 98 6 10 1 62 -13 144 l-23 128 38
27 c21 15 39 30 39 33 0 4 -32 15 -72 24 l-71 18 64 33 c74 38 79 39 79 19 0
-11 12 -15 44 -15 47 0 70 -17 56 -40 -7 -12 -2 -17 22 -26 25 -8 32 -17 34
-43 4 -35 20 -71 32 -71 15 0 72 59 72 74 0 9 -18 33 -40 55 -22 21 -40 44
-40 50 0 6 15 18 33 27 17 9 46 32 63 52 25 29 45 40 93 52 50 12 77 27 141
80 48 40 79 73 80 85 0 11 29 58 64 104 l63 84 -14 76 c-8 42 -17 79 -21 82
-4 4 -36 12 -72 19 -35 6 -82 21 -102 31 l-38 19 131 110 c72 61 138 110 147
110 9 0 120 -9 246 -20 176 -15 233 -17 243 -8 12 10 11 15 -9 29 -12 10 -21
19 -18 22 98 78 123 97 131 97 5 0 21 -7 34 -16 23 -15 27 -15 71 11 l47 27
-7 -25 c-14 -57 -27 -76 -121 -166 -62 -60 -94 -98 -91 -108 3 -8 -1 -28 -10
-46 -22 -42 -13 -136 18 -177 12 -17 25 -30 29 -30 4 0 26 33 49 73 23 39 52
81 64 92 33 30 81 161 69 188 -8 17 -4 31 15 63 25 40 27 42 91 48 36 3 88 6
115 6 41 0 58 7 110 44 33 24 85 65 114 91 38 34 63 48 92 52 22 3 40 7 40 9
0 2 -9 26 -20 54 -11 28 -20 52 -20 54 0 2 20 9 45 16 36 10 53 23 81 62 l37
49 31 -28 c17 -15 59 -55 93 -87 60 -58 63 -59 78 -41 17 23 33 76 37 125 3
31 -1 37 -34 54 l-37 21 -27 -25 c-15 -14 -28 -20 -31 -15 -2 6 -13 27 -26 48
-20 35 -42 48 -231 139 -226 108 -216 107 -238 36 -6 -21 -17 -38 -23 -38 -7
0 -38 11 -68 26 -55 25 -57 25 -162 13 l-106 -12 -40 39 c-21 21 -39 45 -39
54 0 10 -9 25 -20 35 -18 17 -28 17 -111 7 l-91 -11 -73 42 c-64 36 -75 46
-80 75 l-6 34 -79 -7 c-76 -7 -78 -6 -82 16 -2 19 -14 26 -71 40 l-68 17 -54
-78 c-60 -85 -53 -81 -197 -96 l-67 -7 -72 63 c-75 68 -74 63 -53 144 6 25 2
28 -71 62 -42 19 -78 34 -80 32 -2 -2 -10 -28 -17 -58 -17 -64 4 -63 -212 -15
l-149 34 -180 -75 c-99 -41 -181 -73 -182 -72 -1 2 -5 38 -7 80 -6 87 -13 78
98 113 49 16 51 18 67 69 23 70 22 111 -3 136 -23 23 -41 24 -145 9 -42 -6
-78 -9 -81 -6 -3 3 -1 22 5 44 9 32 8 40 -6 51 -24 17 -79 14 -142 -8z m-2309
-1304 c8 -12 11 -36 4 -36 -2 0 -11 -3 -19 -6 -10 -3 -18 5 -26 27 -6 18 -11
34 -11 36 0 8 45 -10 52 -21z m-107 -26 c17 -18 17 -21 1 -45 -13 -20 -22 -24
-39 -19 -27 8 -33 14 -41 41 -5 17 -1 23 21 31 39 14 38 14 58 -8z m2805 -395
c0 -29 -4 -36 -25 -41 -14 -3 -31 -15 -39 -25 -14 -21 -93 -52 -107 -43 -6 3
11 21 36 40 25 19 50 32 55 29 6 -3 14 5 19 18 8 20 46 57 58 57 2 0 3 -16 3
-35z m-2102 -372 c11 -10 23 -28 27 -41 5 -19 1 -25 -27 -36 -18 -8 -36 -17
-40 -21 -4 -4 11 -30 33 -57 26 -33 38 -57 34 -69 -3 -10 1 -32 9 -48 22 -42
21 -88 -3 -101 -27 -14 -114 -13 -129 2 -15 15 -15 60 0 93 10 22 8 29 -14 53
-14 15 -31 43 -37 62 -6 19 -23 46 -37 61 l-26 27 79 45 c88 51 102 54 131 30z
m322 -46 c0 -33 -25 -97 -38 -97 -3 0 -16 10 -29 22 -33 31 -31 73 5 92 46 25
62 21 62 -17z"
      />
      <path d="M10030 6065 c-10 -13 -10 -23 3 -55 15 -39 15 -40 -6 -40 -12 0 -48
6 -79 14 l-57 13 -48 -49 -47 -48 27 -28 c26 -25 29 -26 38 -9 8 13 23 17 64
17 45 0 57 -4 73 -25 l19 -24 68 22 c57 18 72 28 91 59 13 21 24 40 24 42 0 2
-15 7 -32 11 -60 12 -65 15 -88 65 -25 54 -31 58 -50 35z"
      />
      <path d="M10363 6037 c-45 -12 -84 -25 -88 -29 -14 -14 25 -66 54 -73 21 -5
37 -19 56 -51 20 -34 31 -44 43 -39 10 4 30 12 45 17 28 10 29 13 22 57 -13
91 -27 141 -38 140 -7 0 -49 -11 -94 -22z"
      />
      <path d="M6012 5974 c-29 -20 -30 -75 -1 -115 l20 -29 52 30 c76 43 86 77 31
109 -42 25 -72 27 -102 5z"
      />
      <path d="M10557 5923 c-7 -43 -3 -49 51 -73 l39 -18 11 36 c6 20 10 37 9 37
-1 1 -23 13 -49 28 -26 15 -49 27 -51 27 -2 0 -7 -17 -10 -37z"
      />
      <path d="M6187 5916 c-3 -8 0 -20 8 -26 17 -14 55 3 55 25 0 20 -56 20 -63 1z" />
      <path d="M6339 5878 c-7 -12 -2 -21 23 -36 27 -17 35 -18 60 -8 34 15 38 26
12 41 -11 5 -21 12 -24 15 -11 12 -63 4 -71 -12z"
      />
      <path d="M9569 5751 c-32 -50 -47 -94 -55 -161 -5 -44 -4 -46 40 -78 l45 -33
43 19 c60 26 113 105 132 197 8 38 14 70 13 71 -1 1 -44 8 -96 16 l-93 15 -29
-46z" />
      <path d="M10638 5733 c-25 -10 -40 -25 -47 -46 -6 -16 -11 -31 -11 -33 0 -2
17 -12 37 -23 l38 -19 45 23 c36 18 49 31 57 59 6 20 8 38 4 41 -22 13 -90 12
-123 -2z"
      />
      <path d="M10395 5693 c3 -27 8 -58 11 -70 4 -20 1 -22 -26 -17 -27 6 -30 4
-30 -19 0 -22 8 -28 66 -47 36 -12 67 -20 68 -19 2 2 16 28 30 57 l27 53 -21
35 c-12 19 -23 34 -25 34 -3 0 -27 9 -55 20 l-51 20 6 -47z"
      />
      <path d="M6130 5720 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"
      />
      <path d="M11154 5708 c-8 -25 -3 -29 69 -61 l57 -26 0 34 c0 40 -24 59 -82 68
-31 5 -38 2 -44 -15z"
      />
      <path d="M10871 5684 l-54 -36 7 -70 c6 -65 9 -73 62 -139 l56 -69 111 0 c94
0 124 -4 207 -30 102 -31 110 -38 110 -93 0 -31 5 -34 95 -76 52 -24 95 -47
95 -50 0 -8 -76 -61 -87 -61 -4 0 -28 14 -53 30 -25 16 -49 30 -55 30 -5 0
-41 -23 -78 -50 l-68 -50 28 -38 c15 -20 28 -38 29 -40 0 -1 32 5 69 13 l68
16 41 -40 c23 -23 45 -41 50 -41 8 0 323 186 339 200 5 4 -27 33 -70 63 l-79
56 -26 88 -27 88 -53 -3 c-52 -3 -54 -2 -135 65 -65 54 -82 74 -82 96 -1 32
-7 33 -108 13 l-72 -13 -6 28 c-9 39 -29 36 -57 -8 -27 -45 -32 -33 -26 64 l4
63 -72 0 c-51 0 -75 4 -84 15 -17 20 -18 20 -79 -21z"
      />
      <path d="M2890 5622 c-12 -31 -28 -76 -37 -100 -15 -41 -15 -44 3 -63 11 -11
46 -27 78 -36 51 -14 59 -15 73 -1 13 14 13 18 0 39 -8 13 -17 61 -21 108 -3
46 -7 86 -9 88 -3 2 -18 8 -35 12 -31 9 -31 9 -52 -47z"
      />
      <path d="M10070 5609 c-124 -32 -120 -32 -197 0 l-53 21 -30 -60 c-16 -33 -30
-61 -30 -64 0 -2 21 -7 47 -11 31 -4 70 -23 119 -56 69 -46 73 -51 94 -115
l22 -66 47 5 c25 4 83 9 128 13 l83 7 25 45 c14 25 24 51 22 56 -3 6 -34 19
-70 29 l-65 19 -7 104 c-4 57 -10 104 -14 103 -3 -1 -58 -14 -121 -30z"
      />
      <path d="M10605 5508 c-31 -139 -32 -158 -7 -158 10 0 21 -2 24 -6 10 -9 -45
-134 -59 -134 -8 0 -28 7 -45 16 -29 15 -36 15 -87 -1 -43 -13 -85 -16 -179
-13 l-123 3 -5 -25 c-3 -14 -7 -30 -9 -36 -2 -8 -32 -6 -102 8 -135 26 -153
32 -278 101 l-110 60 -213 6 c-139 4 -217 2 -222 -4 -5 -6 -12 -23 -15 -38 -4
-15 -11 -29 -15 -32 -4 -3 -126 27 -271 65 -145 39 -329 84 -409 102 l-145 31
-162 -99 c-89 -54 -163 -102 -163 -105 0 -3 29 -27 65 -53 81 -59 88 -69 41
-61 -40 8 -86 -14 -86 -41 0 -8 -6 -27 -14 -43 l-15 -27 75 -37 c41 -20 74
-40 74 -45 0 -4 -36 -49 -80 -99 l-80 -91 81 -106 c44 -58 84 -106 87 -106 3
0 15 11 27 25 l20 25 42 -33 c34 -26 43 -40 50 -78 l8 -46 52 38 c29 22 84 78
124 126 40 49 77 93 82 98 6 6 7 -7 3 -37 -4 -27 -4 -48 -1 -48 4 0 22 7 41
16 32 16 42 15 236 -24 112 -23 226 -44 254 -48 48 -6 54 -10 129 -95 59 -67
92 -117 134 -202 l56 -112 55 -15 c48 -14 59 -22 88 -64 37 -56 39 -66 13 -66
-16 0 -20 -15 -33 -112 -15 -109 -14 -114 7 -194 29 -105 31 -107 111 -184 37
-36 130 -142 206 -237 128 -160 181 -210 211 -200 6 2 -2 12 -18 23 -30 19
-165 217 -165 242 0 19 32 -6 167 -127 135 -122 187 -185 173 -211 -27 -50
-18 -57 133 -104 78 -25 176 -54 217 -64 52 -13 149 -58 318 -145 200 -103
248 -124 266 -117 17 6 32 3 55 -11 18 -11 31 -23 29 -27 -2 -4 -31 -61 -64
-127 l-61 -120 34 -90 c18 -49 50 -131 69 -182 l36 -92 116 -87 c64 -48 118
-88 120 -89 2 -2 -2 -104 -8 -228 -11 -223 -11 -227 -50 -342 l-39 -117 16
-75 c15 -70 15 -87 -1 -269 l-18 -195 28 -40 c33 -48 68 -74 88 -66 8 3 42 76
76 163 34 86 85 205 115 263 l53 106 124 64 c100 52 141 81 220 154 75 69 100
99 108 127 8 31 48 73 217 231 l206 194 84 173 83 174 -64 91 -64 92 -212 45
-211 44 -27 55 c-27 54 -27 54 -199 147 -164 88 -179 93 -295 116 l-123 24
-175 -44 c-147 -37 -180 -42 -209 -33 -23 7 -38 7 -51 -1 -15 -10 -28 -5 -74
31 -71 55 -72 56 -88 121 l-13 54 -51 -3 -51 -2 -17 44 c-16 42 -16 46 3 89
l19 46 -36 6 c-63 10 -72 8 -89 -29 -17 -35 -17 -35 -67 -28 -28 3 -73 11
-101 17 l-50 11 -5 125 c-3 69 -5 127 -3 129 5 7 391 93 426 94 32 1 44 -4 68
-31 16 -18 29 -42 29 -55 1 -12 16 -39 34 -61 l33 -38 12 30 c11 25 10 35 -4
63 -10 19 -26 44 -37 55 l-19 22 101 93 101 94 -5 60 c-7 69 4 96 28 76 19
-16 23 -13 124 77 53 48 82 82 82 96 0 13 20 37 51 63 l51 43 43 -30 42 -29
64 17 c83 23 90 45 20 62 l-51 12 0 58 0 57 -37 7 c-21 3 -51 6 -67 6 -17 0
-26 4 -20 8 5 5 103 39 219 76 115 37 211 69 213 71 3 3 -46 81 -65 104 -2 3
-28 -3 -59 -13 -31 -10 -57 -16 -59 -14 -2 2 -35 75 -75 163 -39 88 -72 161
-74 162 -1 1 -26 -5 -54 -14 l-53 -17 -118 118 -119 119 -64 -26 -63 -25 -3
-125 c-2 -111 0 -131 18 -164 11 -21 20 -41 20 -45 0 -4 -21 -19 -46 -34 -42
-25 -45 -30 -40 -58 11 -70 18 -186 10 -186 -5 0 -26 10 -48 23 -29 17 -48 41
-80 101 l-41 79 -200 114 c-110 63 -204 119 -210 124 -6 6 1 51 20 127 l29
117 78 81 c43 44 78 84 78 87 0 4 -19 28 -41 52 -48 52 -51 51 88 35 82 -9 83
-9 158 26 l76 34 -22 31 c-20 28 -20 32 -6 47 15 14 12 18 -41 49 -31 18 -60
33 -63 33 -3 0 -12 -25 -19 -55 -7 -31 -17 -58 -21 -61 -5 -3 -60 34 -124 82
l-115 87 -21 71 c-12 39 -26 77 -32 84 -15 19 -36 14 -42 -10z m-911 -354 c9
-3 16 -16 16 -29 0 -16 8 -25 26 -29 l27 -7 -28 -29 c-15 -15 -46 -33 -70 -39
-66 -16 -68 -15 -62 23 4 26 2 36 -11 41 -12 4 -4 14 33 40 27 19 50 35 51 35
1 0 9 -3 18 -6z m225 -329 c40 -10 82 -13 112 -9 47 6 49 6 49 -18 0 -21 -11
-28 -97 -60 -75 -26 -108 -34 -141 -30 l-44 4 38 39 c36 37 37 38 20 63 -19
30 -20 30 63 11z m589 -531 c19 -5 22 -13 21 -53 0 -25 -4 -55 -8 -66 -7 -17
-10 -14 -20 21 -6 22 -20 49 -31 59 -15 14 -17 21 -9 32 13 15 15 15 47 7z
m451 -303 c29 -22 81 -52 116 -67 40 -18 69 -37 79 -55 l17 -28 -31 11 c-27 9
-34 7 -55 -12 -19 -18 -27 -20 -44 -11 -12 7 -21 20 -21 31 0 29 -17 33 -53
13 -29 -18 -32 -23 -29 -64 l3 -44 -26 29 c-21 22 -25 37 -23 69 3 37 8 43 47
64 1 1 0 6 -3 12 -4 6 -18 6 -39 0 -21 -6 -50 -5 -85 3 l-54 12 24 18 c28 22
99 58 114 58 6 0 34 -18 63 -39z"
      />
      <path d="M247 5096 c-32 -6 -61 -16 -65 -22 -4 -6 -20 -14 -36 -18 -23 -6 -34
-2 -55 18 l-26 25 -30 -23 -29 -23 25 -13 c13 -7 51 -38 84 -70 57 -55 63 -58
122 -63 l63 -5 65 70 c36 39 65 73 65 77 0 9 -96 61 -111 60 -8 -1 -40 -6 -72
-13z" />
      <path d="M10960 4998 c0 -13 -9 -38 -20 -55 l-20 -33 25 -6 c14 -3 25 -9 25
-13 1 -29 50 -36 50 -6 0 8 15 12 44 11 24 -1 48 3 52 7 15 15 -13 42 -61 60
-27 10 -55 27 -63 38 -18 25 -32 24 -32 -3z"
      />
      <path d="M7850 4902 c0 -13 36 -43 43 -36 9 9 -16 44 -30 44 -7 0 -13 -4 -13
-8z"
      />
      <path d="M750 4556 c-5 -13 -18 -27 -29 -31 -24 -7 -28 -40 -6 -49 9 -3 12
-12 8 -22 -4 -10 0 -20 10 -26 12 -6 17 -22 17 -53 0 -40 2 -45 23 -45 35 0
39 -10 17 -40 -25 -35 -25 -36 0 -43 30 -8 26 -51 -5 -65 -26 -12 -37 -62 -12
-62 32 1 179 38 190 49 6 6 12 26 13 44 0 18 3 39 7 47 4 10 -20 45 -73 105
-64 72 -80 96 -80 121 0 17 -7 37 -15 44 -9 7 -13 21 -10 31 10 31 -43 26 -55
-5z"
      />
      <path d="M8447 4493 c-18 -17 -5 -33 23 -31 33 2 36 8 18 26 -13 13 -31 16
-41 5z"
      />
      <path d="M650 4373 c-14 -10 -38 -18 -55 -18 -30 0 -30 0 -33 -65 -2 -56 0
-67 17 -77 29 -18 111 17 111 47 0 11 -5 20 -10 20 -6 0 -1 18 12 39 19 33 20
41 9 55 -17 20 -21 20 -51 -1z"
      />
      <path d="M6157 4303 c-4 -3 -7 -47 -7 -97 0 -50 -8 -126 -17 -168 -9 -43 -15
-80 -12 -82 2 -2 14 -6 25 -10 18 -6 21 -3 17 17 -3 14 6 46 19 73 20 40 23
57 17 89 -4 22 -8 63 -9 90 -1 77 -13 109 -33 88z"
      />
      <path d="M12013 4108 c-29 -21 -61 -84 -48 -96 2 -3 46 -15 97 -27 88 -21 107
-20 108 6 0 3 -27 25 -59 49 -40 28 -61 51 -63 67 l-3 23 -32 -22z"
      />
      <path d="M9440 4102 c1 -14 26 -37 53 -49 33 -15 35 -4 5 24 -25 22 -58 37
-58 25z"
      />
      <path d="M11806 4081 c-3 -5 1 -11 9 -15 9 -3 15 0 15 9 0 16 -16 20 -24 6z" />
      <path d="M6460 3970 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
      />
      <path d="M11785 3950 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"
      />
      <path d="M11862 3929 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
0 -15 -5 -13 -11z"
      />
      <path d="M6126 3883 c-3 -4 -6 -14 -6 -24 0 -10 -13 -27 -30 -39 -47 -33 -37
-65 12 -39 14 8 23 8 31 0 17 -17 42 -13 61 8 10 11 28 22 41 25 33 8 41 36
11 36 -14 0 -45 9 -69 19 -25 11 -48 17 -51 14z"
      />
      <path d="M6036 3662 c-61 -70 -143 -134 -186 -147 -14 -4 -35 -14 -48 -21 -20
-13 -21 -16 -8 -30 13 -12 20 -13 37 -3 19 9 21 9 16 -4 -11 -28 5 -26 79 14
41 21 85 39 97 39 19 0 26 10 42 56 10 31 31 72 46 91 27 34 28 34 8 54 -10
10 -20 19 -21 19 -2 0 -30 -31 -62 -68z"
      />
      <path d="M1443 3613 c-28 -5 -30 -19 -4 -28 23 -7 53 4 48 18 -4 13 -15 15
-44 10z"
      />
      <path d="M5719 3424 c-10 -13 -11 -18 -2 -21 7 -2 9 -12 6 -21 -4 -13 0 -11
18 5 21 21 22 24 8 38 -14 15 -17 14 -30 -1z"
      />
      <path d="M5815 3420 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"
      />
      <path d="M5352 3118 c-17 -17 -15 -38 3 -38 12 0 35 28 35 44 0 11 -26 6 -38
-6z"
      />
      <path d="M11061 3036 c-10 -12 -3 -17 41 -32 29 -9 76 -28 103 -41 56 -26 105
-30 105 -9 0 21 -18 30 -116 59 -50 14 -97 29 -106 32 -8 3 -20 -1 -27 -9z"
      />
      <path d="M4921 2949 c-17 -14 -21 -23 -14 -30 14 -14 83 17 83 37 0 21 -41 16
-69 -7z"
      />
      <path d="M11460 2923 c-28 -5 -50 -21 -50 -34 0 -5 23 -9 51 -9 46 0 50 2 47
22 -3 24 -11 27 -48 21z"
      />
      <path d="M5363 2912 c-29 -3 -32 -7 -43 -54 -6 -28 -9 -52 -8 -53 11 -8 130
-75 134 -75 2 0 4 9 4 19 0 11 -15 28 -35 41 -38 24 -40 31 -24 78 14 41 10
48 -28 44z"
      />
      <path d="M11270 2890 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"
      />
      <path d="M5410 2691 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"
      />
      <path d="M5266 2641 c-11 -12 -16 -24 -12 -28 9 -9 39 22 34 37 -2 7 -10 4
-22 -9z"
      />
      <path d="M3843 2618 c-4 -7 -8 -15 -9 -18 0 -3 -4 -14 -9 -24 -11 -26 20 -49
46 -35 22 11 26 58 7 77 -15 15 -25 15 -35 0z"
      />
      <path d="M5468 2606 c-52 -31 -54 -34 -32 -51 20 -15 91 -20 110 -7 15 9 -12
82 -30 81 -6 0 -28 -11 -48 -23z"
      />
      <path d="M5120 2504 c-47 -30 -108 -65 -135 -78 -46 -21 -51 -27 -63 -72 -7
-27 -13 -53 -14 -59 0 -5 17 -20 38 -32 30 -18 55 -23 109 -23 l71 0 56 58
c53 53 88 117 88 160 0 34 -33 102 -49 101 -9 0 -54 -25 -101 -55z"
      />
      <path d="M4410 2479 c48 -68 156 -171 227 -216 40 -26 73 -53 73 -60 0 -11 12
-13 48 -11 74 5 65 30 -46 132 -50 47 -99 86 -108 86 -9 0 -39 20 -66 45 -28
25 -58 45 -68 45 -10 0 -32 3 -51 7 l-33 6 24 -34z"
      />
      <path d="M5304 2332 c-27 -42 -29 -52 -22 -91 9 -52 10 -53 32 -45 11 4 16 19
16 48 l0 41 22 -38 c32 -54 53 -41 34 21 -6 18 -4 22 13 22 32 0 25 20 -9 27
-48 9 -31 33 25 33 32 0 56 10 49 21 -3 5 -33 9 -67 9 l-62 0 -31 -48z"
      />
      <path d="M5574 2365 c-4 -9 -4 -18 -2 -21 9 -8 39 8 35 19 -6 18 -27 20 -33 2z" />
      <path d="M5752 2284 c3 -23 23 -33 218 -115 168 -70 225 -89 263 -89 28 0 73
-10 109 -25 34 -14 65 -22 70 -17 4 4 -29 44 -75 89 l-82 81 -121 36 c-90 27
-140 36 -195 36 -45 1 -84 6 -99 15 -14 8 -40 14 -58 15 -30 0 -33 -3 -30 -26z"
      />
      <path d="M5680 2260 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"
      />
      <path d="M4840 2166 c0 -24 38 -44 115 -60 68 -14 71 -14 93 6 34 33 27 45
-30 51 -177 20 -178 20 -178 3z"
      />
      <path d="M6375 2170 c3 -5 15 -10 26 -10 11 0 17 5 14 10 -3 6 -15 10 -26 10
-11 0 -17 -4 -14 -10z"
      />
      <path d="M5355 2102 c-29 -16 -29 -17 -7 -20 23 -4 52 13 52 29 0 13 -11 11
-45 -9z"
      />
      <path d="M5470 2060 c0 -5 11 -10 24 -10 14 0 28 5 31 10 4 6 -7 10 -24 10
-17 0 -31 -4 -31 -10z"
      />
      <path d="M5315 2041 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z" />
      <path d="M5735 1950 c-58 -48 -62 -50 -123 -50 -62 0 -63 0 -206 -96 -102 -68
-166 -103 -222 -122 l-79 -27 -7 -77 c-6 -71 -4 -85 28 -180 18 -56 34 -118
34 -138 l0 -37 74 -6 c66 -6 99 -1 297 43 122 28 230 50 240 50 9 0 71 -25
136 -55 101 -48 128 -66 190 -127 71 -70 72 -71 113 -64 27 4 47 2 56 -5 10
-9 26 -4 66 18 46 26 62 44 125 144 l71 114 -14 124 c-9 89 -18 129 -31 145
-10 11 -80 87 -156 167 -89 95 -135 152 -132 161 4 9 -7 25 -29 41 -19 15 -37
27 -39 27 -3 0 -10 -31 -17 -69 -9 -57 -16 -73 -37 -87 -25 -16 -28 -16 -80
10 -47 23 -53 29 -47 50 5 23 -1 28 -65 60 -39 20 -74 36 -78 36 -5 0 -35 -22
-68 -50z"
      />
      <path d="M2651 1931 c-12 -16 -41 -37 -66 -47 -25 -9 -45 -19 -45 -23 0 -3 -8
-54 -18 -113 l-19 -107 48 -20 c26 -12 50 -21 53 -21 3 0 37 62 75 138 62 123
69 143 64 180 -5 40 -7 42 -39 42 -25 0 -38 -7 -53 -29z"
      />
      <path d="M7000 1669 c0 -8 9 -20 20 -27 11 -7 20 -8 20 -4 0 5 -9 17 -20 27
-15 14 -20 15 -20 4z"
      />
      <path d="M7303 1165 l-33 -14 21 -35 c16 -27 18 -39 10 -55 -16 -29 -13 -49
10 -83 l22 -30 58 58 58 57 -51 59 c-28 32 -54 58 -57 58 -3 -1 -21 -7 -38
-15z" />
      <path d="M7194 938 c-38 -28 -89 -74 -112 -101 l-44 -50 30 -29 c28 -29 30
-29 64 -15 30 13 37 22 43 54 5 32 15 45 62 78 30 22 60 43 65 46 10 7 -19 69
-32 69 -4 0 -37 -23 -76 -52z"
      />
      <path d="M6241 962 c-8 -16 -7 -30 7 -60 14 -34 20 -39 38 -32 14 6 23 20 27
46 7 43 3 50 -35 59 -20 5 -29 2 -37 -13z"
      />
      <path d="M11542 371 l-43 -28 48 -31 c40 -26 55 -30 86 -25 53 8 54 19 2 32
-40 10 -44 14 -47 46 l-3 35 -43 -29z"
      />
    </g>
  </svg>
);

export default Map;
