import Table from "../../../../components/Table";
import Module from "../../../../components/Module";
import React from "react";
import {useTranslation} from "react-i18next";
import {extractRoleName} from "../../../../utils";
import Button from "../../../../components/Button";
import Loading from "../../../../components/Loading";

interface Props {
    isFetching: boolean;
    errorMessage: string;
    records: any[];
    submitUser: (userId: number) => void;
    setRejectModalOptions: Function;
}

export const List = (props: Props) => {

    const {t} = useTranslation();

    const columns = [
        {
            Header: "platformAccess.requestedOn",
            accessor: "date_joined",
            Cell: (record: any) => {
                return new Date(record.row.original.date_joined).toLocaleString()
            },
            width: 200,
        },
        {
            Header: "platformAccess.accessRequestedBy",
            accessor: "added_by",
        },
        {
            Header: "platformAccess.country",
            accessor: "country",
            width: 10
        },
        {
            Header: "column.role",
            accessor: "user_profile",
            Cell: (record: any) => extractRoleName(record.row.original.user_profile),
        },
        {
            Header: "fieldName.firstName",
            accessor: "first_name",
        },
        {
            Header: "fieldName.lastName",
            accessor: "last_name",
        },
        {
            Header: "platformAccess.email",
            accessor: "email",
        },
        {
            Header: "",
            accessor: "user_profile",
            width: 100,
            Cell: (record: any) => {
                return (
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <div style={{marginRight: 8}}>
                            <Button
                                kind={'primary'}
                                small
                                onClick={() => props.submitUser(record.row.original.id)}
                            >
                                {t("common.approve") as string}
                            </Button>
                        </div>
                        <div>
                            <Button kind={'danger'} small onClick={() =>
                                    props.setRejectModalOptions({id: record.row.original.id, isOpen: true})}>
                                {t("common.reject") as string}
                            </Button>
                        </div>
                    </div>
                )
            },
        },
    ];

    if(props.isFetching){
        return (
            <Loading isVisible/> 
        )
    }

    return <Module
        table={
            <Table
                columns={columns}
                data={props.records}
            />
        }
    />;
}
