// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_wrapper_DtYh1AecuObTldr6daUq {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  font-size: 0.875rem;\n}\n\n.style_countryFlag_x9jm7UtUP3AD5daELUkQ {\n  color: grey;\n  flex: 1;\n  height: 40px;\n  line-height: 40px;\n  padding-left: 4px;\n  padding-right: 2px;\n  border: 1px solid #77a0b6;\n  border-right: 0;\n}\n\n.style_countryCode_kIP_JxjIHB6y0KFuPW77 {\n  color: grey;\n  flex: 1;\n  height: 40px;\n  line-height: 40px;\n  padding-right: 4px;\n  border: 1px solid #77a0b6;\n  border-right: 0;\n  border-left: 0;\n}", "",{"version":3,"sources":["webpack://./src/components/PhoneInput/style.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;AACF;;AAEA;EACE,WAAA;EACA,OAAA;EACA,YAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;EACA,OAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;EACA,eAAA;EACA,cAAA;AACF","sourcesContent":[".wrapper {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  font-size: 0.875rem;\n}\n\n.countryFlag {\n  color: grey;\n  flex: 1;\n  height: 40px;\n  line-height: 40px;\n  padding-left: 4px;\n  padding-right: 2px;\n  border: 1px solid #77a0b6;\n  border-right: 0;\n}\n\n.countryCode {\n  color: grey;\n  flex: 1;\n  height: 40px;\n  line-height: 40px;\n  padding-right: 4px;\n  border: 1px solid #77a0b6;\n  border-right: 0;\n  border-left: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "style_wrapper_DtYh1AecuObTldr6daUq",
	"countryFlag": "style_countryFlag_x9jm7UtUP3AD5daELUkQ",
	"countryCode": "style_countryCode_kIP_JxjIHB6y0KFuPW77"
};
export default ___CSS_LOADER_EXPORT___;
