import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { iconAdd } from "@wfp/icons";
import PropTypes from "prop-types";

import PageStandard from "../../components/PageStandard";
import { getDeletedItemName, valueOrDefault } from "../../utils";
import List from "./components/List";
import {
  deleteFarmerIdentificationDetails,
  farmerIdentificationDetailsReset,
  listFarmerIdentification,
  listFarmerIdentificationReset,
} from "../../actions";
import FarmerIdentificationEditModal from "./components/Modal";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import Button from "../../components/Button";
import Modal from "../../components/Modal";

export const WfpManagerFarmerIdentificationView = ({
  isFetching,
  errorMessage,
  farmerIdentification,
  page,
  count,
  listFarmerIdentification,
  listFarmerIdentificationReset,
  farmerIdentificationDetailsReset,
  deleteFarmerIdentificationDetails,
}) => {
  const { t } = useTranslation();
  const [modalOptions, setModalOptions] = useState({isOpen: false, id: undefined, type: undefined});
  const [deleteModalOptions, setDeleteModalOptions] = useState({isOpen: false, id: undefined});
  // On component mount the data are fetched
  useEffect(() => {
    listFarmerIdentification();
    return () => {
      listFarmerIdentificationReset();
    };
  }, []);

  const onClickAdd = () => {
    farmerIdentificationDetailsReset();
    setModalOptions({isOpen: true, id: "add", type: "create"});
    listFarmerIdentification();
  };

  const onDeleteSubmit = () => {
    deleteFarmerIdentificationDetails(deleteModalOptions.id);
    setDeleteModalOptions({isOpen: false, id: undefined});
    listFarmerIdentification();
  };

  return (
    <PageStandard
      title={t("configuration.item.farmerIdentification")}
      additional={(
        <ButtonsWrapper>
          <Button icon={iconAdd} onClick={onClickAdd}>
            {t("farmerIdentification.newFarmerIdentification")}
          </Button>
        </ButtonsWrapper>
      )}
    >
      <List
        {...{
          isFetching,
          errorMessage,
          farmerIdentification,
          page,
          count,
          setModalOptions,
          setDeleteModalOptions,
        }}
      />
      <FarmerIdentificationEditModal
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
      />
      <Modal
        isOpen={deleteModalOptions.isOpen}
        onValidate={onDeleteSubmit}
        title={t("common.delete") + " " + t("configuration.item.farmerIdentification")}
        danger={true}
        onCancel={() => setDeleteModalOptions({isOpen: false, id: undefined})}
        isLoading={false}
      >
        {t("configuration.common.deleteText") + getDeletedItemName(deleteModalOptions.id, farmerIdentification, "name")}
      </Modal>
    </PageStandard>
  );
};

WfpManagerFarmerIdentificationView.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  farmerIdentification: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  listFarmerIdentification: PropTypes.func.isRequired,
  listFarmerIdentificationReset: PropTypes.func.isRequired,
  farmerIdentificationDetailsReset: PropTypes.func.isRequired,
  deleteFarmerIdentificationDetails: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    farmerIdentification: state.listFarmerIdentification.data.results,
    isFetching: state.listFarmerIdentification.isFetching,
    errorMessage: state.listFarmerIdentification.errorMessage,
    page: 1,
    count: valueOrDefault(state.listFarmerIdentification.data.results.length, 0),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    listFarmerIdentification: () => dispatch(listFarmerIdentification()),
    listFarmerIdentificationReset: () => dispatch(listFarmerIdentificationReset()),
    farmerIdentificationDetailsReset: () => dispatch(farmerIdentificationDetailsReset()),
    deleteFarmerIdentificationDetails: id => dispatch(deleteFarmerIdentificationDetails(id, true)),
  };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(WfpManagerFarmerIdentificationView);
