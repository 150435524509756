// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_errorTitle_oCtXMgaFC0kwqkGOcv2C {\n  font-weight: bold;\n  display: block;\n  margin: 0.5rem 0 0.5rem 0;\n}\n\n.style_errorLabel_At3xKLSpa9Q2CdqcyeNa {\n  font-weight: bold;\n  display: inline-block;\n  margin-left: 1rem;\n  margin-right: 0.5rem;\n  text-transform: capitalize;\n}", "",{"version":3,"sources":["webpack://./src/containers/RepresentativeAggregatorCreationRequestDetails/components/Form/style.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,cAAA;EACA,yBAAA;AACF;;AAEA;EACE,iBAAA;EACA,qBAAA;EACA,iBAAA;EACA,oBAAA;EACA,0BAAA;AACF","sourcesContent":[".errorTitle {\n  font-weight: bold;\n  display: block;\n  margin: 0.5rem 0 0.5rem 0;\n}\n\n.errorLabel {\n  font-weight: bold;\n  display: inline-block;\n  margin-left: 1rem;\n  margin-right: 0.5rem;\n  text-transform: capitalize;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorTitle": "style_errorTitle_oCtXMgaFC0kwqkGOcv2C",
	"errorLabel": "style_errorLabel_At3xKLSpa9Q2CdqcyeNa"
};
export default ___CSS_LOADER_EXPORT___;
