import React from 'react';
import { Col, Grid, Row } from '../../../../../components/Grid';
import { formatDateDefault } from '../../../../../utils';
import { OfferStatus } from '../../OfferCard/interfaces';
import style from './style.scss';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { UserGroup } from '../../../../../utils/roles';
import { NegotiationProposal } from '../NegotiatedDetailsCard/offer-negotiation-proposal';

export const OfferComment = ({comment, statusLabel}: { comment: OfferCommentDto, statusLabel: string}) => {
    const { t } = useTranslation();

    const getStatusClass = (status: OfferStatus | string) => {
        switch (status) {
        case OfferStatus.Accepted:
        case OfferStatus.OfferDone:
        case OfferStatus.ExchangeAgreed:
            return style.successStatus;
        case OfferStatus.Recalled:
        case OfferStatus.Rejected:
        case OfferStatus.ExchangeDetailsDisputed:
            return style.alertStatus;
        case OfferStatus.ExchangeDone:
            return style.expiredStatus;
        case OfferStatus.ExchangeDetailsUpdated:
            return style.warningStatus;
        default:
            return style.newStatus;
        }
    };
    const {i18n} = useTranslation();
    if (moment.locale() !== i18n.language) {
        moment.locale(i18n.language);
    }

    const isOwn = (proposalOrComment: OfferCommentDto | NegotiationProposal) => {
        const isRepComment = proposalOrComment.user.roles.includes(
          UserGroup.TRADER_REPR
        );
        return isRepComment;
    };

    return (
    <div className={isOwn(comment) ? style.ownComment : style.otherComment}>
        <div className={style.statusContainer}>
            <div className={`${style.status} ${getStatusClass(comment.trader_market_offer_status)}`}>{statusLabel}</div>
        </div>
        <div className={style.commentUser}> {comment.user && comment.user.first_name} {comment.user && comment.user.last_name}</div>
        <div className={style.commentTime}>{ moment(comment.backend_creation_time).fromNow() }</div>
        <div className={style.commentBody}>
            <span className={style.commentText}>{t("commentCard.comment")}:</span>
            <span className={style.commentText}>{comment.text}</span>
        </div>
    </div>)
};

export interface OfferCommentDto {
    text: string;
    uuid: string;
    trader_market_offer_id: number;
    trader_market_offer_status: string;
    backend_creation_time: string;
    frontend_creation_time: string;
    user: {
        first_name: string;
        last_name: string;
        roles: string[];
    }
}
