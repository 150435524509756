import React, { useEffect } from "react";

import { redirect } from "../../utils";
import PageInformation from "../../components/PageInformation";

// A function used to read the authentication URL
// starting from environment variables
export const getLogoutUrl = id_token_hint => (
  process.env.AUTHENTICATION_URL +
  "/oidc/logout?id_token_hint=" + id_token_hint +
  "&post_logout_redirect_uri=" +
  encodeURIComponent(`${window.location.origin}/ciam_logout_callback/`)
);

// A page displayed when user navigate to login after logout
export const Logout = () => {
  // Logging out from the application and clearing the local storage
  useEffect(() => {
    redirect(getLogoutUrl(localStorage.getItem("ciam_id_token")));
  }, []);

  return (
    <PageInformation title="Logging out from application" text="Wait while logging out..." />
  );
};

// The router is used to allow user to navigate to home page
export default Logout;
