import React from "react";
import PropTypes from "prop-types";
import { iconWfpLogoEmblem } from "@wfp/icons";
import { Loading } from "@wfp/ui";

import Icon from "../Icon";
import style from "./style.scss";

// A loading message shown over page content
const FullscreenLoading = ({ children, isVisible }) => (
  <>
    {
      isVisible
        ? <div className={style.wrapper}>
          <Icon
            icon={iconWfpLogoEmblem}
            width="150"
            height="150"
            style={{ marginBottom: "1rem" }}
            fill="#ffffff"
          />
          <p className={style.text}>{children}</p>
          <Loading withOverlay={false} small />
        </div>
        : undefined
    }
  </>
);

// propTypes for the FormGroup component
FullscreenLoading.propTypes = {
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool.isRequired,
};

// defaultProps for the FormGroup component
FullscreenLoading.defaultProps = {
  children: "Wait while loading",
};

export default FullscreenLoading;
