import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Loading from "../../../../components/Loading";
import Module from "../../../../components/Module";
import Table from "../../../../components/Table";
import { displayErrorMessage } from "../../../../utils";
import { generateDateTimeByUserCell, generateViewButton } from "../../../../utils/table";
import { withRouter } from "react-router-dom";
import MarkdownPreview from "../../../../components/MarkdownPreview";

// The list of Aggregators that user can view and delete
export const List = ({
  isFetching,
  errorMessage,
  termsAndConditionsTexts,
  page,
  count,
  setModalOptions,
}) => {
  const { t } = useTranslation();

  if (isFetching) {
    return <Loading isVisible />;
  }

  if (errorMessage) {
    return <>{displayErrorMessage(errorMessage)}</>;
  }

  const columns = [
    {
      Header: "termsAndConditionsText.list.shortText",
      accessor: "short_text",
      width: 400,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => <MarkdownPreview source={original.short_text} />,
    },
    {
      Header: "configuration.list.column.lastUpdated",
      accessor: "lastUpdated",
      width: 400,
      Cell: ({ row: { original } }) => generateDateTimeByUserCell(original.last_update_datetime, original.last_update_user, t),
    },
    {
      Header: "configuration.list.column.created",
      accessor: "created",
      width: 400,
      Cell: ({ row: { original } }) => generateDateTimeByUserCell(original.creation_datetime, original.creation_user, t),
    },
    {
      width: 80,
      accessor: "edit",
      Cell: ({row: { original: record }}) => generateViewButton(
        record,
        () => setModalOptions({id: record.id, isOpen: true, type: "edit"}),
        "Edit",
        t,
      ),
      Header: "",
    },

  ];

  return (
    <>
      <Module
        table={
          <Table
            {...{
              columns,
              data: termsAndConditionsTexts,
              loading: isFetching,
              page,
              totalItems: count,
            }}
          />
        }
      />
    </>
  );
};

// propTypes for the List component
List.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  termsAndConditionsTexts: PropTypes.array,
  page: PropTypes.number.isRequired,
  count: PropTypes.number,
  setModalOptions: PropTypes.func.isRequired,
};

// defaultProps for the List component
List.defaultProps = {
  errorMessage: "",
};

export default withTranslation()(withRouter(List));
