import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import { Blockquote, StepNavigation, StepNavigationItem } from "@wfp/ui";
import style from "./style.scss";
import Module from "../../../../components/Module";
import Loading from "../../../../components/Loading";
import { Grid, Row, Col } from "../../../../components/Grid";
import Button from "../../../../components/Button";
import { withTranslation } from "react-i18next";
import { HelpContext } from "../../../HelpMessageRoute";
import InfoHubContentTypeStep from "./Components/InfoHubContentTypeStep";
import { isEmpty } from "../../../../utils";
import { iconArrowRight, iconArrowLeft } from "@wfp/icons";
import InfoHubReviewStep from "./Components/InfoHubReviewStep";
import InfoHubMobilePreviewStep from "./Components/InfoHubMobilePreviewStep";

const getErrors = (object, key) =>
  (object && object[key] && !isEmpty(object[key]))
    ? object[key]
    : "";

const formResolver = (values, t) => {
  let errors = {};
  if(values.content_type === "" || !values.content_type){
    errors.content_type = t("traderDetails.form.mandatoryFieldBlankError");
  }
  if(values.name === "" || !values.name){
    errors.name = t("traderDetails.form.mandatoryFieldBlankError");
  }
  if(values.topics === "" || !values.topics){
    errors.topics = t("traderDetails.form.mandatoryFieldBlankError");
  }
  if(values.content === "" || !values.content){
    errors.content = t("traderDetails.form.mandatoryFieldBlankError");
  }
  if(values.aggregators === "" || !values.aggregators){
    errors.aggregators = t("traderDetails.form.mandatoryFieldBlankError");
  }
  return { values, errors};
};

// The details of a Aggregator Organization
export const Form = ({
  isFetching,
  errorMessage,
  onClickValidateAndClose,
  initialStep,
  t,
  initialValues,
  contentTypes,
  topics,
  aggregators,
  isCopy,
}) => {
  if (isFetching) {
    return <Loading isVisible />;
  }

  const buttonRef = useRef();

  const methods = useForm({
    defaultValues: initialValues,
    resolver: formResolver,
    context: t,
  });
  const { handleSubmit, reset, trigger, formState: { errors }, register, setValue } = methods;

  useEffect(() => {
    if(Object.values(initialValues).length > 0){
      reset(initialValues);
    }
    else {
      register("status");
    }
  }, [initialValues]);

  const nextButtonLabel = () => {
    switch(step){
    case 2:
      return t("infoHub.footer.publish");
    default:
      return t("common.next");
    }
  };

  const [step, setStep] = useState(initialStep || 0);

  const next = () => {
    if(step === 2){
      setValue("status", "published");
      buttonRef.current.click();
    }else{
      trigger();
      setStep(step + 1);
    }
  };

  const previous = () => {
    setStep(step - 1);
  };

  const saveAsDraft = () => {
    setValue("status", "draft");
    buttonRef.current.click();
  };

  const getStepHeading = (step) => {
    switch (step){
    case 0:
      return t("infoHub.heading.addDetails");
    case 1:
      return t("infoHub.heading.review");
    case 2:
      return t("infoHub.heading.mobilePreview");
    }
  };

  const getStepStatus = (i) => {
    if(step === i){
      return "not-started";
    } else if(step < i){
      return undefined;
    } else if (step > i){
      return "complete";
    }
  };

  const help = useContext(HelpContext);

  const totalNumberSteps = 3;

  return (
    <>
      <Grid>
        <Row>
          <Col sm={2}/>
          <Col sm={8}>{help}</Col>
        </Row>
        <Row>
          <Col sm={2}>
            <StepNavigation
              className={style.stepNav}
              onSelectionChange={(e) => setStep(e)}
              selectedPage={step}
            >
              {Array.from({length: totalNumberSteps}).map((_, i) => (
                <StepNavigationItem
                  key={i}
                  label={getStepHeading(i)}
                  page={i}
                  status={getStepStatus(i)}
                />
              ))}
            </StepNavigation>
          </Col>
          <Col sm={8}>
            <Module
              title={`${t("infoHub.form.step")} ${step + 1}/${totalNumberSteps} ${getStepHeading(step)}`}
              footer={
                <Grid fluid>
                  <Row align="left">
                    {
                      step !== 0 && (
                        <Col md={2.5} >
                          <Button iconReverse icon={iconArrowLeft} kind={"secondary"} onClick={previous}>
                            { t("infoHub.footer.previous") }
                          </Button>
                        </Col>
                      )
                    }
                    {step === 2 && (
                      <Col md={2.5} offset={{ md: 4.5 }}>
                        <Button kind={"secondary"} onClick={saveAsDraft}>
                          { t("infoHub.footer.saveAsDraft") }
                        </Button>
                      </Col>
                    )}
                    <Col>
                      <Button disabled={Object.keys(errors).length > 0 && step == 2} icon={iconArrowRight} kind={ step === 2 ? "primary" : "secondary"} onClick={next}>
                        { nextButtonLabel() }
                      </Button>
                    </Col>
                  </Row>
                </Grid>

              }
            >
              <FormProvider>
                <form onSubmit={handleSubmit(onClickValidateAndClose)}>
                  {errorMessage && errorMessage !== "" && <Blockquote kind="error" className={style.blockquote}>
                    {errorMessage}
                  </Blockquote>}
                  { step === 0 &&
                  <InfoHubContentTypeStep
                    methods={methods}
                    getErrors={getErrors}
                    contentTypes={contentTypes}
                    aggregators={aggregators}
                    topics={topics}
                    isCopy={isCopy}
                  />
                  }
                  { step === 1 &&
                    <InfoHubReviewStep
                      methods={methods}
                    />
                  }
                  { step === 2 &&
                    <InfoHubMobilePreviewStep
                      methods={methods}
                    />
                  }
                  <button ref={buttonRef} style={{display: "none"}}/>
                </form>
              </FormProvider>
            </Module>
          </Col>
        </Row>
      </Grid>
    </>
  );
};

// propTypes for the Form component
Form.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onClickValidateAndClose: PropTypes.func.isRequired,
  initialStep: PropTypes.number,
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  contentTypes: PropTypes.array,
  topics: PropTypes.array,
  aggregators: PropTypes.array,
  isCopy: PropTypes.bool,
};

// defaultProps for the Form component
Form.defaultProps = {
  errorMessage: "",
  id: "",
};

// eslint-disable-next-line max-len
export default withTranslation()(Form);
