/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { iconAdd } from "@wfp/icons";
import { connect } from "react-redux";
import PageStandard from "../../components/PageStandard";
import { getCountryObjectFromCache, valueOrDefault } from "../../utils";
import List from "./components/List";
import PropTypes from "prop-types";
import {
  listAggregatorUmbrellaOrganizations,
  listAggregatorUmbrellaOrganizationsReset,
  aggregatorUmbrellaOrganizationsDetailsReset,
  listAdministrativeAreas,
  listAdministrativeAreasReset,
  listAdministrativeAreaTypes,
  listAdministrativeAreaTypesReset,
  listAggregators,
  listAggregatorsReset,
} from "../../actions";

import AggregatorUmbrellaOrganizationsEditModal from "./components/Modal";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import Button from "../../components/Button";

export const WfpManagerAggregatorUmbrellaOrganizations = ({
  isFetching,
  errorMessage,
  aggregatorUmbrellaOrganizations,
  administrativeAreas,
  administrativeAreaTypes,
  aggregators,
  page,
  count,
  listAggregatorUmbrellaOrganizations,
  listAggregatorUmbrellaOrganizationsReset,
  listAdministrativeAreas,
  listAdministrativeAreasReset,
  listAdministrativeAreaTypes,
  listAdministrativeAreaTypesReset,
  listAggregators,
  listAggregatorsReset,
  aggregatorUmbrellaOrganizationsDetailsReset,
}) => {
  const { t } = useTranslation();
  const [modalOptions, setModalOptions] = useState({
    isOpen: false,
    id: undefined,
    type: undefined,
  });

  // On component mount the data are fetched
  useEffect(() => {
    listAggregatorUmbrellaOrganizations();
    listAdministrativeAreas();
    listAdministrativeAreaTypes();
    listAggregators();
    return () => {
      listAggregatorUmbrellaOrganizationsReset();
      listAdministrativeAreasReset();
      listAdministrativeAreaTypesReset();
      listAggregatorsReset();
    };
  }, []);

  const onClickAdd = () => {
    aggregatorUmbrellaOrganizationsDetailsReset();
    setModalOptions({ isOpen: true, id: "add", type: "create" });
  };

  return (
    <PageStandard
      additional={
        <ButtonsWrapper>
          <Button icon={iconAdd} onClick={onClickAdd}>
            {t(
              "aggregatorUmbrellaOrganizations.newAggregatorUmbrellaOrganization",
            )}
          </Button>
        </ButtonsWrapper>
      }
      title={t("configuration.item.aggregatorUmbrellaOrganizations")}
    >
      <List
        {...{
          isFetching,
          errorMessage,
          aggregatorUmbrellaOrganizations,
          administrativeAreas,
          administrativeAreaTypes,
          aggregators,
          page,
          count,
          modalOptions,
          setModalOptions,
        }}
      />
      <AggregatorUmbrellaOrganizationsEditModal
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
        administrativeAreas={administrativeAreas}
        administrativeAreaTypes={administrativeAreaTypes}
      />
    </PageStandard>
  );
};

WfpManagerAggregatorUmbrellaOrganizations.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  aggregatorUmbrellaOrganizations: PropTypes.array.isRequired,
  administrativeAreas: PropTypes.array.isRequired,
  administrativeAreaTypes: PropTypes.array.isRequired,
  aggregators: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  listAggregatorUmbrellaOrganizations: PropTypes.func.isRequired,
  listAggregatorUmbrellaOrganizationsReset: PropTypes.func.isRequired,
  listAdministrativeAreas: PropTypes.func.isRequired,
  listAdministrativeAreasReset: PropTypes.func.isRequired,
  listAdministrativeAreaTypes: PropTypes.func.isRequired,
  listAdministrativeAreaTypesReset: PropTypes.func.isRequired,
  listAggregators: PropTypes.func.isRequired,
  listAggregatorsReset: PropTypes.func.isRequired,
  aggregatorUmbrellaOrganizationsDetailsReset: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  const countryID = getCountryObjectFromCache().id;
  const filteredAdministrativeAreas = (
    state.listAdministrativeAreas.data.results || []
  ).filter((item) => item.country === countryID);
  const filteredAdministrativeAreaTypes = (
    state.listAdministrativeAreaTypes.data.results || []
  ).filter((item) => item.country === countryID);

  return {
    aggregatorUmbrellaOrganizations:
      state.listAggregatorUmbrellaOrganizations.data.results,
    administrativeAreas: filteredAdministrativeAreas,
    administrativeAreaTypes: filteredAdministrativeAreaTypes,
    aggregators: state.listAggregators.data.results,
    isFetching: state.listAggregatorUmbrellaOrganizations.isFetching,
    errorMessage: state.listAggregatorUmbrellaOrganizations.errorMessage,
    page: 1,
    count: valueOrDefault(
      state.listAggregatorUmbrellaOrganizations.data.results.length,
      0,
    ),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    listAggregatorUmbrellaOrganizations: () =>
      dispatch(listAggregatorUmbrellaOrganizations()),
    listAggregatorUmbrellaOrganizationsReset: () =>
      dispatch(listAggregatorUmbrellaOrganizationsReset()),
    aggregatorUmbrellaOrganizationsDetailsReset: () =>
      dispatch(aggregatorUmbrellaOrganizationsDetailsReset()),
    listAdministrativeAreas: () =>
      dispatch(listAdministrativeAreas({ page_size: 9999, page: 1 })),
    listAdministrativeAreasReset: () =>
      dispatch(listAdministrativeAreasReset()),
    listAdministrativeAreaTypes: () => dispatch(listAdministrativeAreaTypes()),
    listAdministrativeAreaTypesReset: () =>
      dispatch(listAdministrativeAreaTypesReset()),
    listAggregators: () => dispatch(listAggregators()),
    listAggregatorsReset: () => dispatch(listAggregatorsReset()),
  };
};

// The component uses the redux store
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WfpManagerAggregatorUmbrellaOrganizations);
