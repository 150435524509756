import {
  RESOURCE_DELETE_REQUEST_,
  RESOURCE_DELETE_SUCCESS_,
  RESOURCE_DELETE_ERROR_,
  RESOURCE_DELETE_RESET_,
} from "../actions/resourceDelete";

// A generic reducer used to delete a resource
const resourceDelete = resource => (
  state = { isLoading: false, errorMessage: "", error: undefined },
  action,
) => {
  switch (action.type) {
  case `${RESOURCE_DELETE_REQUEST_}${resource}`:
    return { ...state, isLoading: true, errorMessage: "", error: undefined };
  case `${RESOURCE_DELETE_SUCCESS_}${resource}`:
    return { ...state, isLoading: false, errorMessage: "", error: undefined };
  case `${RESOURCE_DELETE_ERROR_}${resource}`:
    return { ...state, isLoading: false, errorMessage: action.errorMessage, error: action.error };
  case `${RESOURCE_DELETE_RESET_}${resource}`:
    return { ...state, isLoading: false, errorMessage: "", error: undefined  };
  default:
    return state;
  }
};

export default resourceDelete;
