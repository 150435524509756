import { CURRENT_SORT_ } from "../actions/currentSort";

// A generic reducer used to store which is the current page of a paginated element
const currentSort =
  (element) =>
    (
      state = {
        sort: {
          columnIndex: 6,
          descending: true,
        },
      },
      action,
    ) => {
      switch (action.type) {
      case `${CURRENT_SORT_}${element}`:
        return { ...state, sort: {...action.sort} };
      default:
        return state;
      }
    };

export default currentSort;
