import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { iconWfpLogoEmblem } from "@wfp/icons";

import Button from "../../components/Button";
import Icon from "../../components/Icon";
import { generateNonce, getLoginUrl } from "../../utils/authentication";
import style from "./style.scss";

// This component is used to display the CIAM login page to the user
export const Login = ({ t }) => {
  // A state to check if the login page is displayed
  const [isLoading, setIsLoading] = useState(false);

  // Generate and store the nonce for authentication and forward the user to
  // CIAM.
  const onClick = () => {
    setIsLoading(true);
    const nonce = generateNonce();
    localStorage.setItem("nonce", nonce);
    window.open(getLoginUrl(nonce), "_self");
  };

  return (
    <div className={style.wrapper}>
      <div className={style.left}>
        <div className={style.logoOverlay}>
          <div className={style.logoContainer}>
            <Icon
              icon={iconWfpLogoEmblem}
              width="150"
              height="150"
              fill="#ffffff"
            />
            <span className={style.photoCredit}>
              Photo: WFP/Badre Bahaji
            </span>
          </div>
        </div>
      </div>
      <div className={style.right}>
        <div className={style.content}>
          <span className={style.title}>{t("login.title")}</span>
          <span className={style.info}>
            {t("login.description")}
          </span>
          <Button disabled={isLoading} onClick={onClick}>{t("login.loginButton")}</Button>
        </div>
      </div>
    </div>
  );
};

Login.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withRouter(withTranslation()(Login));
