import React from "react";
import style from "./style.scss";
import { iconEmail } from "@wfp/icons";
import { Module } from "@wfp/ui";
import Icon from "../../../components/Icon";
import { useTranslation } from "react-i18next";

const AccountDeletionConfirmation = () => {
  const { t } = useTranslation();
  return (
    <div className={style.container}>
      <Module className={style.moduleContainer}>
        <div className={style.container}>
          <div className={style.title}>
            {t("accountDeletion.confirmation.title")}
          </div>
          <Icon icon={iconEmail} width={"50"} height={"50"} fill={"#007DBC"} />
          <div className={style.subtitle}>
            {t("accountDeletion.confirmation.subtitle")}
          </div>
          <div className={style.description}>
            {t("accountDeletion.confirmation.description")}
          </div>
        </div>
      </Module>
    </div>
  );
};

export default AccountDeletionConfirmation;
