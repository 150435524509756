import React from "react";
import { displaySuccessMessage } from "../../../utils";
import { iconCheckmarkOutline } from "@wfp/icons";
import { Module } from "@wfp/ui";
import { useTranslation } from "react-i18next";
import Icon from "../../../components/Icon";
import PropTypes from "prop-types";
import style from "./style.scss";

const FeedbackSent = ({ serverSuccess }) => {
  const { t } = useTranslation();

  return (
    <>
      {serverSuccess && displaySuccessMessage(serverSuccess)}
      <div className={style.container}>
        <Module className={style.moduleContainer}>
          <div className={style.container}>
            <div className={style.title}>
              {t("accountDeletion.feedbackSent.title")}
            </div>
            <Icon
              icon={iconCheckmarkOutline}
              fill={"#007DBC"}
              width={100}
              height={100}
            />
            <div className={style.description}>
              <p>
                {t("accountDeletion.feedbackSent.description")}
              </p>
            </div>
          </div>
        </Module>
      </div>
    </>
  );
};

FeedbackSent.propTypes = {
  serverSuccess: PropTypes.string,
};

export default FeedbackSent;
