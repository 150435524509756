import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Button from "../../components/Button";
import { Col, Grid, Row } from "../../components/Grid";
import Avatar from "../../components/Avatar";
import Loading from "../../components/Loading";
import MapPin from "../../components/MapPin";
import Modal from "../../components/Modal";
import { OFFER_TYPES } from "../../constants";
import { formatNumber, getCurrencySymbolFromCache } from "../../utils";
import { adaptAggregatorAdministrators } from "../../utils/adapters";
import { translateNameFromListResponse } from "../../utils/i18n";
import AdminsTable from "../Aggregators/components/AdminsTable";
import { AggregatorAdmin } from "../Aggregators/components/AdminsTable/aggregator-admins-response";
import { OfferStatus } from "../RepresentativeHome/components/OfferCard/interfaces";
import { Aggregator, Deposit } from "./types";
import styles from "./style.scss";

export interface AggregatorDetailsModalProps {
  aggregator: Aggregator;
  isOpen: boolean;
  commodities: any[];
  onValidate: () => void;
  administrators: AggregatorAdmin[];
  isFetchingAggregatorAdministrators: boolean;
  onClickEdit?: (aggregator: Aggregator) => void;
  listAggregatorAdministrators: (id: number) => void;
  hideActions: boolean;
  offers: any[];
}

const AggregatorDetailsModal = ({
  aggregator,
  isOpen,
  onValidate,
  history,
  commodities,
  administrators,
  hideActions,
  isFetchingAggregatorAdministrators,
  onClickEdit,
  offers: allOffers,
}: AggregatorDetailsModalProps & RouteComponentProps) => {
  const { t } = useTranslation();

  const offers = allOffers.filter(
    ({ tagged_aggregator: { id } }) => id === aggregator.id
  );

  const adaptDepositsIntoGroupedCommodities = (
    deposits: Deposit[],
    commodities: any[]
  ) => {
    const availableDeposits = deposits && deposits.filter((d) => !d.deleted);

    if (!availableDeposits || !availableDeposits.length) {
      return [];
    }

    const commodityNames = new Set(
      availableDeposits
        .map((d) => d.commodity.name)
        .sort((a, b) => (a || b ? -1 : 1))
    );

    let allCommodityGrades = <></>;
    for (const commodity of commodityNames) {
      const depositsWithCommodity = availableDeposits.filter(
        (d) => d.commodity.name === commodity
      );

      const allGrades = adaptDepositsIntoGradedCommodities(
        depositsWithCommodity,
        commodities
      ).join(" • ");

      allCommodityGrades = (
        <>
          {allCommodityGrades} <div> {allGrades} </div>
        </>
      );
    }

    return allCommodityGrades;
  };

  const adaptDepositsIntoGradedCommodities = (
    deposits: Deposit[],
    commodities: any[]
  ): string[] => {
    const availableDeposits = deposits && deposits.filter((d) => !d.deleted);

    if (!availableDeposits || !availableDeposits.length) {
      return [];
    }

    const commodityGrades: Record<string, number> = {};

    for (const d of availableDeposits) {
      const key = `${translateNameFromListResponse(
        d.commodity.name,
        commodities
      )}  (${t(`grades.${d.quality}`)}) __q__ ${d.unit_of_measure.code}`;
      if (commodityGrades[key]) {
        commodityGrades[key] += d.quantity;
      } else {
        commodityGrades[key] = d.quantity;
        ``;
      }
    }

    const commodityGradesArray = Object.keys(commodityGrades).map((key) =>
      key.replace("__q__", formatNumber(commodityGrades[key]))
    );

    return Array.from(commodityGradesArray).sort((a, b) => (a || b ? -1 : 1));
  };

  const membershipInfo = (
    <div>
      <Avatar /> {t("aggregatorDetails.farmerMembershipStructure")} •{" "}
      {formatNumber(aggregator.membership_fee)} {getCurrencySymbolFromCache()}{" "}
      {` ${t("aggregatorDetails.perFarmerPerYear")}`}
    </div>
  );

  const aggregatorStats = (
    <Col sm={6}>
      <Row>
        <Col sm={12}>
          <h5>{t("aggregatorDetails.aggregatorStats.heading")}</h5>
          <span className={styles.boldText}>
            {t("aggregatorDetails.aggregatorStats.numberOfFarmers")}
          </span>{" "}
          : {aggregator.total_no_of_farmers}
        </Col>
        <Col sm={12}>
          <span className={styles.boldText}>
            {t("aggregatorDetails.aggregatorStats.offersExchanged")}
          </span>{" "}
          : {offers.filter((o) => o.status === OfferStatus.ExchangeDone).length}
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <span className={styles.boldText}>
            {t("aggregatorDetails.aggregatorStats.offersReceived")}
          </span>{" "}
          : {offers.length}
        </Col>
        <Col sm={12}>
          <span className={styles.boldText}>
            {t("aggregatorDetails.aggregatorStats.depositPurchaseRequests")}
          </span>{" "}
          :{" "}
          {
            offers.filter((o) => o.offer_type === OFFER_TYPES.DIRECT_OFFER)
              .length
          }
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <span className={styles.boldText}>
            {t("aggregatorDetails.aggregatorStats.traderDemands")}
          </span>{" "}
          :{" "}
          {
            offers.filter((o) => o.offer_type === OFFER_TYPES.MARKET_OFFER)
              .length
          }
        </Col>
      </Row>
    </Col>
  );

  return (
    <Modal
      onValidate={onValidate}
      validateText={t("common.done")}
      title={aggregator.name}
      modalLabel={t("aggregatorDetails.aggregatorDetails")}
      wide
      isOpen={isOpen}
      hideCancelButton={true}
    >
      {/* {onClickEdit && (
        <Button
          className={styles.editButton}
          onClick={() => onClickEdit(aggregator)}
          kind="secondary"
        >
          {`${t("common.edit")}`}
        </Button>
      )} */}
      <Row style={{ marginBottom: 16 }}>
        <Col sm={12}>
          {aggregator.managed_individuals?.includes("farmers") && (
            <span className={styles.organizationType}>
              {t("aggregatorDetails.aggregator")}
            </span>
          )}
          {aggregator.managed_individuals?.length > 1 && <br />}
          {aggregator.managed_individuals?.includes(
            "mastercard_foundation_participants"
          ) && (
            <span className={styles.organizationType}>
              {t("aggregatorDetails.mastercardFoundationPartner")}
            </span>
          )}
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <div>
            <MapPin />{" "}
            {aggregator.physical_address
              ? `${aggregator.physical_address}, `
              : ""}
            {aggregator.country && aggregator.country.name}
          </div>
        </Col>
      </Row>
      {/* <Row>
        <Col sm={12}>
          {aggregator.has_membership_structure && membershipInfo}
        </Col>
      </Row> */}
      <AdminsTable
        administrators={administrators}
        isFetchingAdministrators={isFetchingAggregatorAdministrators}
      />
      <Row>
        {isFetchingAggregatorAdministrators ? (
          <Col sm={3}>
            <Loading isVisible />
          </Col>
        ) : (
          aggregatorStats
        )}
        <Col sm={6}>
          <div>
            <h5 className={styles.commoditiesTitle}>
              {t("aggregatorDetails.availableDeposits")}
              {!hideActions && (
                <Button
                  className={styles.seeDepositsButton}
                  onClick={() => {
                    history.push("/representative/market", {
                      aggregator_id: aggregator.id,
                    });
                    onValidate();
                  }}
                  kind="secondary"
                >
                  {`${t("aggregatorDetails.seeCommodities")}`}
                </Button>
              )}
            </h5>
          </div>

          {adaptDepositsIntoGroupedCommodities(
            aggregator.deposits,
            commodities
          )}
        </Col>
      </Row>
      <div className={styles.modalContent}></div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    administrators: adaptAggregatorAdministrators(
      state.listAggregatorAdministrators.data.results
    ),
    offers: state.listMarketOffers.data.results,
    isFetchingAggregatorAdministrators:
      state.listAggregatorAdministrators.isFetching ||
      state.listMarketOffers.isFetching,
  };
};

export default connect(mapStateToProps)(withRouter(AggregatorDetailsModal));
