// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_alignRight_outVRIm8fFTdB9lzUtwW {\n  text-align: right !important;\n}\n\n.style_alignCentre_NYz9r60Q9Ca575kLtkfN {\n  text-align: center !important;\n}", "",{"version":3,"sources":["webpack://./src/components/NewTable/style.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;AACF;;AAEA;EACE,6BAAA;AACF","sourcesContent":[".alignRight {\n  text-align: right !important;\n}\n\n.alignCentre {\n  text-align: center !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alignRight": "style_alignRight_outVRIm8fFTdB9lzUtwW",
	"alignCentre": "style_alignCentre_NYz9r60Q9Ca575kLtkfN"
};
export default ___CSS_LOADER_EXPORT___;
