import React from "react";
import { useTranslation } from "react-i18next";
import { Column, usePagination, useTable, useSortBy } from "react-table";
import { Pagination, Table as TableWFP } from "@wfp/ui"
import style from "./style.scss";

interface Props {
    data: any[];
    columns: Column<any>[];
    // loading: boolean;
    defaultPageSize?: number;
    // subComponent: Node;
    // expandAll: boolean;
    showPagination?: boolean;
    totalItems?: number;
    // page: number;
    // onPageChange: () => void;
    showSort?: boolean;
    loadPage?: (filters: any) => void;
    sortBy?: {id: string, desc: boolean}[];
}

export const TableComponent = ({
    data,
    columns,
    // loading,
    defaultPageSize = 10,
    // subComponent,
    // expandAll = false,
    showPagination = true,
    totalItems,
    // page,
    // onPageChange,
    showSort = false,
    loadPage,
    sortBy,
}: Props) => {

  const { t } = useTranslation();
  const memoColumns = React.useMemo(
      () => !!(columns && columns.length)
          ? columns.map((column, index) => (
              {
                ...column,
                id: `id_${index}`,
                Header: (
                    typeof column.Header === "object"
                        ? column.Header
                        : t(column.Header as string)
                )
              }
          ))
          : [],
      [columns]
  );
  const memoSortBy = React.useMemo(
      () => !!(sortBy && sortBy.length) ? sortBy.map((sortingObject) => {
          const conColumn = memoColumns?.find(column => column.accessor === sortingObject.id);
          return {
              ...sortingObject,
              id: conColumn ? conColumn.id : sortingObject.id
          }
      }) : [],
      [sortBy, memoColumns]
  );

  const memoData = React.useMemo(() => data, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
      {
        data: memoData,
        columns: memoColumns,
        initialState: { pageIndex: 0, pageSize: defaultPageSize, sortBy: memoSortBy },
          disableSortBy: !sortBy,
      },
      useSortBy,
      usePagination,
  );

  const changePage: any = (page: {page: number, pageSize: number}) => {
    gotoPage(page.page - 1);

    // Update PageSize
    if (pageSize !== page.pageSize) {
      setPageSize(page.pageSize);
    }
    if(loadPage) loadPage({page_size: page.pageSize, page: page.page})
  };

  const getClassName = (column: Column, type: string) => {
       // @ts-ignore
      if(column.alignRight || (column.alignCellRight && type === "cell")){
        return style.alignRight
        // @ts-ignore
      } else if (column.alignCentre || (column.alignCellCentre && type === "cell")){
          return style.alignCentre
      }
  };

  return (
      <>
        <TableWFP {...getTableProps()}>
          <thead>
          {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                    <th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        // @ts-ignore
                        className={getClassName(column, "heading")}
                        style = {{ width: column.width}}
                    >
                      { showSort && column.canSort ? (
                        <div {...column.getSortByToggleProps()} style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
                          <div>
                            {column.render("Header")}
                          </div>
                          <div>
                            {column.isSorted
                              ? column.isSortedDesc
                              ? " ↓"
                              : " ↑"
                              : "↑↓"}
                          </div>
                        </div>
                        ) : (
                        column.render("Header")
                        )
                      }
                    </th>
                ))}
              </tr>
          ))}
          </thead>
          <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                      <td
                        {...cell.getCellProps()}
                        className={getClassName(cell.column, "cell")}
                      >
                        {cell.render('Cell')}
                      </td>
                  ))}
                </tr>
            );
          })}
          </tbody>
        </TableWFP>
        {showPagination && (
            <Pagination
                pageSize={pageSize}
                pageSizes={[10, 20, 30]}
                page={pageIndex + 1}
                totalItems={totalItems || data.length}
                onChange={changePage}
            />
        )}
      </>
  );
}

export default TableComponent;
