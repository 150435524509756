import React from "react";
import PropTypes from "prop-types";
import style from "./style.scss";

export const MandatoryLabel = (props) => (
  <span className={props.className}>
    {props.label} <span className={style.mandatoryAsterisk}>*</span>
  </span>
);

MandatoryLabel.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};
