import React from "react";
import PropTypes from "prop-types";

import Module from "../../../../components/Module";
import Loading from "../../../../components/Loading";
import TableInMemory from "../../../../components/Table";
import { displayErrorMessage } from "../../../../utils";
import { Grid, Row, Col } from "../../../../components/Grid";
import { generateViewButton } from "../../../../utils/table";
import style from "./style.scss";
import {withTranslation} from "react-i18next";

// The list of columns and their properties
export const columns = (onClickView, t) => [
  {
    Header: "marketOfferView.details.columns.aggregator",
    accessor: "name",
  },
  {
    Header: "marketOfferView.details.columns.noOfFarmerTargeted",
    accessor: "total_no_of_farmers",
    width: 100,
  },
  {
    Header: "marketOfferView.details.columns.noOfFarmerWhoAccepted",
    accessor: "order.farmers_contributors_count",
    width: 100,
  },
  {
    Header: "marketOfferView.details.columns.percentOfFemaleFarmers",
    accessor: "order.women_percentage",
    width: 100,
  },
  {
    Header: "marketOfferView.details.columns.aggregatedQty",
    accessor: "order.quantity",
    width: 100,
  },
  {
    Header: "marketOfferView.details.columns.pickedUpQty",
    accessor: "order.picked_up_quantity",
    width: 100,
  },
  {
    Header: "marketOfferView.details.columns.transactionValue",
    accessor: "order.current_value",
    width: 130,
  },
  {
    Header: "",
    width: 80,
    Cell: obj => generateViewButton(obj.row.original, onClickView, undefined,  t),
  },
];

// The details of a market offer
export const Details = (
  {
    isFetchingMarketOffer,
    errorMessageMarketOffer,
    marketOffer,
    onClickViewAggregator,
    t,
  },
) => {

  if (isFetchingMarketOffer) {
    return <Loading isVisible />;
  }

  if (errorMessageMarketOffer) {
    return (
      <>
        {displayErrorMessage(errorMessageMarketOffer)}
      </>
    );
  }

  const price = marketOffer.price +
    ` ${marketOffer.currency.code}/${marketOffer.unit_of_measure.code}`;

  return (
    <Grid>
      <Row>
        <Col sm={3}>
          <Module title={t("marketOfferView.details.title")}>
            <span className={style.label}>{t("marketOfferView.details.trader")}</span>
            <span className={style.value}>{marketOffer.trader.name || "--"}</span>
            <span className={style.label}>{t("marketOfferView.details.commodity")}</span>
            <span className={style.value}>{marketOffer.buy_commodity.name || "--"}</span>
            <span className={style.label}>{t("marketOfferView.details.minQuantity")}</span>
            <span className={style.value}>
              {`${marketOffer.minimum_quantity} ${marketOffer.unit_of_measure.code}` || "--"}
            </span>
            <span className={style.label}>{t("marketOfferView.details.maxQuantity")}</span>
            <span className={style.value}>
              {`${marketOffer.maximum_quantity} ${marketOffer.unit_of_measure.code}` || "--"}
            </span>
            <span className={style.label}>{t("marketOfferView.details.currency")}</span>
            <span className={style.value}>{marketOffer.currency.name || "--"}</span>
            <span className={style.label}>{t("marketOfferView.details.price")}</span>
            <span className={style.value}>{price || "--"}</span>
            <span className={style.label}>{t("marketOfferView.details.expiration")}</span>
            <span className={style.value}>{marketOffer.valid_until || "--"}</span>
            <span className={style.label}>{t("marketOfferView.details.deliveryLocation")}</span>
            <span className={style.value}>{marketOffer.delivery_location || "--"}</span>
            <span className={style.label}>{t("marketOfferView.details.contractNumber")}</span>
            <span className={style.value}>{marketOffer.contract_number || "--"}</span>
          </Module>
        </Col>
        <Col sm={9}>
          <Module
            title={t("marketOfferView.details.tableTitle")}
            table={
              <TableInMemory
                loading={false}
                columns={columns(onClickViewAggregator, t)}
                data={[marketOffer.tagged_aggregator]}
              />
            }
          />
        </Col>
      </Row>
    </Grid>
  );
};

// propTypes for the Details component
Details.propTypes = {
  isFetchingMarketOffer: PropTypes.bool.isRequired,
  errorMessageMarketOffer: PropTypes.string.isRequired,
  marketOffer: PropTypes.object.isRequired,
  onClickViewAggregator: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

// defaultProps for the Details component
Details.defaultProps = {
  errorMessageMarketOffer: "",
};

export default withTranslation()(Details);
