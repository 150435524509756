// Actions available to be used by the generic current page reducer
export const CURRENT_SORT_ = "CURRENT_SORT_";

// Action creator to set a current sort
export const currentSort = element => sort => {
  return {
    type: `${CURRENT_SORT_}${element}`,
    sort: {
      columnIndex: sort.columnIndex,
      descending: sort.descending,
    },
  };
};
