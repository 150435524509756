import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { reset } from "redux-form";
import moment from "moment";
import {v4 as uuidv4} from "uuid";

import {
  addAggregatorCreationRequest,
  addAggregatorCreationRequestReset,
  listLanguages,
  updateAggregatorCreationRequest,
  updateAggregatorCreationRequestReset,
  aggregatorCreationRequestDetails,
  aggregatorCreationRequestDetailsReset,
} from "../../actions";
import PageStandard from "../../components/PageStandard";
import Form from "./components/Form";
import AdministratorModal from "./components/AdministratorModal";
import {
  getStatusErrorMessage,
  valueOrDefault,
  formatDateInput,
} from "../../utils";
import {
  adaptLegalStatuses,
  adaptGenders,
  adaptCardTypes,
  adaptOrganizationTypes,
  adaptLanguages,
  adaptOption,
} from "../../utils/adapters";
import {
  LEGAL_STATUSES,
  CARD_TYPES,
  GENDERS,
  ORGANIZATION_TYPES,
} from "../../constants";
import {useTranslation} from "react-i18next";

// A function to adapt values entered in form to a format accepted by the server
export const adaptValues = (formValues, administrators) => {
  return {
    aggregator: {
      name: valueOrDefault(formValues.name, undefined),
      other_info: valueOrDefault(formValues.other_info, undefined),
      contact_email: valueOrDefault(formValues.contact_email, undefined),
      phone: valueOrDefault(formValues.phone, undefined),
      legal_address: valueOrDefault(formValues.legal_address, undefined),
      physical_address: valueOrDefault(formValues.physical_address, undefined),
      district: valueOrDefault(formValues.district, undefined),
      legal_status:
        valueOrDefault(formValues.legal_status && formValues.legal_status.value, undefined),
      year_of_establishment: valueOrDefault(formValues.year_of_establishment, undefined),
      org_type:
        valueOrDefault(formValues.org_type && formValues.org_type.value, undefined),
      tier: valueOrDefault(formValues.tier, undefined),
      wings_vendor_id: valueOrDefault(formValues.wings_vendor_id, undefined),
      membership_fee: valueOrDefault(formValues.membership_fee, undefined),
      language:
        valueOrDefault(formValues.language && formValues.language.value, undefined),
      membership_fee_needed: valueOrDefault(formValues.membership_fee_needed, false),
      administrators: administrators.map(administrator => (
        {
          person: {
            uuid: valueOrDefault(administrator.uuid, uuidv4()),
            first_name: valueOrDefault(administrator.first_name, undefined),
            middle_name: valueOrDefault(administrator.middle_name, undefined),
            last_name: valueOrDefault(administrator.last_name, undefined),
            contact_phone: valueOrDefault(administrator.contact_phone, undefined),
            location: valueOrDefault(administrator.location, undefined),
            identification_card_number:
              valueOrDefault(administrator.identification_card_number, undefined),
            gender:
              valueOrDefault(
                administrator.gender &&
                administrator.gender.value,
                undefined,
              ),
            day_of_birth: valueOrDefault(formatDateInput(administrator.day_of_birth), undefined),
          },
          email: valueOrDefault(administrator.email, undefined),
          position: valueOrDefault(administrator.position, undefined),
        }
      )),
    },
  };
};

// A function to adapt fields errors received from server
export const adaptErrors = error => {
  if (!error.data) {
    return undefined;
  }
  let administratorsErrors = undefined;
  if (error.data.aggregator && error.data.aggregator.administrators) {
    administratorsErrors = error.data.aggregator.administrators.map(item => {
      const administratorErrors = {
        ...item,
        ...item.person,
      };
      delete administratorErrors.person;
      return administratorErrors;
    });
  }
  let aggregatorErrors = { ...error.data.aggregator };
  delete aggregatorErrors.administrators;
  const result = {
    aggregator: aggregatorErrors,
    administrators: administratorsErrors,
  };
  return result;
};

// A function to adapt values received from server to
// a format compatible with the form
export const adaptInitialFromValues = data => valueOrDefault(
  data &&
  data.aggregator &&
  {
    ...data.aggregator,
    legal_status:
      adaptOption(
        data.legal_status &&
         adaptLegalStatuses(LEGAL_STATUSES)
           .find(status => status.value === data.aggregator.legal_status),
        "value", "label"),
    org_type:
      adaptOption(
        data.aggregator.org_type &&
        adaptOrganizationTypes(ORGANIZATION_TYPES).
          find(status => status.value === data.aggregator.org_type),
        "value", "label"),
    language: { value: data.aggregator.language, label: data.aggregator.language },
  }
  , {});

// A function to adapt values received from server to
// a format compatible with the form
export const adaptInitialAdministrators = results => valueOrDefault(
  results &&
  results.data &&
  results.data.aggregator &&
  results.data.aggregator.administrators &&
  results.data.aggregator.administrators.map(administrator => (
    {
      uuid: valueOrDefault(administrator.person && administrator.person.uuid, uuidv4()),
      first_name:
        valueOrDefault(administrator.person && administrator.person.first_name, undefined),
      middle_name:
        valueOrDefault(administrator.person && administrator.person.middle_name, undefined),
      last_name: valueOrDefault(administrator.person && administrator.person.last_name, undefined),
      contact_phone:
        valueOrDefault(administrator.person && administrator.person.contact_phone, undefined),
      location: valueOrDefault(administrator.person && administrator.person.location, undefined),
      identification_card_number:
        valueOrDefault(
          administrator.person &&
          administrator.person.identification_card_number,
          undefined,
        ),
      gender:
        valueOrDefault(
          administrator.person &&
          administrator.person.gender &&
          adaptCardTypes(GENDERS)
            .find(gender => administrator.person.gender === gender.value),
          undefined,
        ),
      day_of_birth:
        valueOrDefault(moment(new Date(administrator.person.day_of_birth)), undefined),
      email: valueOrDefault(administrator.email, undefined),
      position: valueOrDefault(administrator.position, undefined),
    }))
  , []);

// This component is used to display the detail
// of a Aggregator Organization Creation Request
export const RepresentativeAggregatorCreationRequestDetails = (
  {
    isFetching,
    errorMessage,
    addAggregatorCreationRequest,
    addAggregatorCreationRequestReset,
    history,
    formValues,
    legalStatuses,
    formValuesAdministrator,
    cardTypes,
    genders,
    resetAdministratorFrom,
    organizationTypes,
    listLanguages,
    languages,
    fieldsErrors,
    updateAggregatorCreationRequest,
    updateAggregatorCreationRequestReset,
    id,
    initialFormValues,
    aggregatorCreationRequestDetails,
    aggregatorCreationRequestDetailsReset,
  },
) => {
  // On component mount the data are fetched or read
  // on component unmount data are reset
  useEffect(() => {
    if (id) {
      aggregatorCreationRequestDetails(id).then(results => {
        setAdministrators(adaptInitialAdministrators(results));
      });
    }
    listLanguages();
    return () => {
      addAggregatorCreationRequestReset();
      updateAggregatorCreationRequestReset();
      aggregatorCreationRequestDetailsReset();
    };
  }, []);

  // The part of state used ot manage administrators that will be sent
  // to server after user press validate
  const [administrators, setAdministrators] = useState([]);

  // A part of state used to display or hide the Aggregator Administrator modal
  const [isAdministratorModalOpen, setIsAdministratorModalOpen] = useState(false);

  // This state is used to manage the current in edit record
  const [inEditAdministrator, setInEditAdministrator] = useState(undefined);

  // A state to store the current record to delete
  const [currentToDeleteAdministrator, setCurrentToDeleteAdministrator] = useState(undefined);

  // A state to decide if the delete modal is open or closed
  const [isDeleteAdministratorModalOpen, setIsDeleteAdministratorModalOpen] = useState(false);

  const { t } = useTranslation();

  // On click validate a requests are made
  // to save Aggregator, after saving the
  // Aggregators list is displayed
  const onClickValidate = () => {
    const data = adaptValues(formValues, administrators);
    if (id) {
      updateAggregatorCreationRequest(id, data).then(result => {
        if (result) {
          history.push("/representative/representativeAggregatorCreationRequests");
        }
      });
    } else {
      addAggregatorCreationRequest(data).then(result => {
        if (result) {
          history.push("/representative/representativeAggregatorCreationRequests");
        }
      });
    }
  };

  // On click open Aggregator Administrator modal the modal is displayed
  const onClickAddAdministrator = () => {
    setInEditAdministrator(undefined);
    setIsAdministratorModalOpen(true);
  };

  // On click close Aggregator Administrator modal the modal is closed
  const onClickCloseAdministratorModal = () => {
    setInEditAdministrator(undefined);
    setIsAdministratorModalOpen(false);
    resetAdministratorFrom();
  };

  // On click validate Aggregator Administrator modal the modal is closed
  // validation on data will be performed when user will submit the form
  // as temporary id we will use the current date time
  const onClickValidateAdministratorModal = () => {
    if (inEditAdministrator) {
      const updatedAdministrator = { ...formValuesAdministrator, id: inEditAdministrator.id };
      const updatedAdministrators = [
        ...administrators.filter(administrator => administrator.id !== inEditAdministrator.id),
        updatedAdministrator,
      ];
      setAdministrators(updatedAdministrators);
    } else {
      const newAdministrator = { ...formValuesAdministrator, id: new Date() };
      const updatedAdministrators = [...administrators, newAdministrator];
      setAdministrators(updatedAdministrators);
    }
    setIsAdministratorModalOpen(false);
    setInEditAdministrator(undefined);
    resetAdministratorFrom();
  };

  // On click edit on Aggregator Administrator the modal
  // is displayed to allow user modify the record
  const onClickEditAdministrator = id => {
    setInEditAdministrator(administrators.find(administrator => administrator.id === id));
    setIsAdministratorModalOpen(true);
  };

  // When user clicks edit Aggregator, it is redirected
  // to Aggregator edit page
  const onClickDeleteAdministrator = id => {
    setCurrentToDeleteAdministrator(administrators.find(administrator => administrator.id === id));
    setIsDeleteAdministratorModalOpen(true);
  };

  // When user confirm that it want to delete the record
  // the record is actually deleted
  const onClickConfirmDeleteAdministrator = () => {
    const updatedAdministrators = administrators.filter(administrator => (
      administrator.id !== currentToDeleteAdministrator.id
    ));
    setAdministrators(updatedAdministrators);
    setCurrentToDeleteAdministrator(undefined);
    setIsDeleteAdministratorModalOpen(false);
  };

  // When user press cancel on delete modal
  // the modal is closed and the current record
  // to delete is reset
  const onClickCancelDeleteAdministrator = () => {
    setCurrentToDeleteAdministrator(undefined);
    setIsDeleteAdministratorModalOpen(false);
  };

  const fieldsErrorsWithData = {
    aggregator: formValues,
    administrators,
    fieldsErrors,
  };

  return (
    <PageStandard title={t("representativeAggregatorCreationRequestDetails.title")}>
      <Form
        initialValues={initialFormValues}
        isFetching={isFetching}
        errorMessage={errorMessage}
        onClickValidate={onClickValidate}
        onClickAddAdministrator={onClickAddAdministrator}
        administrators={administrators}
        legalStatuses={legalStatuses}
        onClickEditAdministrator={onClickEditAdministrator}
        onClickDeleteAdministrator={onClickDeleteAdministrator}
        isDeleteAdministratorModalOpen={isDeleteAdministratorModalOpen}
        onClickConfirmDeleteAdministrator={onClickConfirmDeleteAdministrator}
        onClickCancelDeleteAdministrator={onClickCancelDeleteAdministrator}
        organizationTypes={organizationTypes}
        languages={languages}
        fieldsErrorsWithData={fieldsErrorsWithData}
      />
      <AdministratorModal
        isModalOpen={isAdministratorModalOpen}
        onClickValidate={onClickValidateAdministratorModal}
        cardTypes={cardTypes}
        genders={genders}
        onClickClose={onClickCloseAdministratorModal}
        initialValues={inEditAdministrator}
      />
    </PageStandard>
  );
};

// propTypes for the AggregatorCreationRequestDetails component
RepresentativeAggregatorCreationRequestDetails.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  addAggregatorCreationRequest: PropTypes.func.isRequired,
  addAggregatorCreationRequestReset: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  legalStatuses: PropTypes.array.isRequired,
  formValuesAdministrator: PropTypes.object.isRequired,
  cardTypes: PropTypes.array.isRequired,
  genders: PropTypes.array.isRequired,
  resetAdministratorFrom: PropTypes.func.isRequired,
  organizationTypes: PropTypes.array.isRequired,
  listLanguages: PropTypes.func.isRequired,
  languages: PropTypes.array.isRequired,
  fieldsErrors: PropTypes.object,
  updateAggregatorCreationRequest: PropTypes.func.isRequired,
  updateAggregatorCreationRequestReset: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  initialFormValues: PropTypes.object.isRequired,
  aggregatorCreationRequestDetails: PropTypes.func.isRequired,
  aggregatorCreationRequestDetailsReset: PropTypes.func.isRequired,
};

// defaultProps for the RepresentativeAggregatorCreationRequestDetails component
RepresentativeAggregatorCreationRequestDetails.defaultProps = {
  fieldsErrors: undefined,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = (state, props) => {
  return {
    // Current on edit aggregator creation request
    id: valueOrDefault(props.match.params.id, ""),
    initialFormValues: adaptInitialFromValues(state.aggregatorCreationRequestDetails.data),
    // Loading and errors properties
    isFetching:
      state.addAggregatorCreationRequest.isLoading ||
      state.updateAggregatorCreationRequest.isLoading ||
      state.listLanguages.isFetching,
    errorMessage:
      state.listLanguages.errorMessage ||
      getStatusErrorMessage(state.addAggregatorCreationRequest.error) ||
      getStatusErrorMessage(state.updateAggregatorCreationRequest.error) ||
      "",
    // Errors related to Aggregator Creation Request
    fieldsErrors:
      adaptErrors(state.addAggregatorCreationRequest.error) ||
      adaptErrors(state.updateAggregatorCreationRequest.error) ||
      undefined,
    // Related entities lists
    legalStatuses: adaptLegalStatuses(LEGAL_STATUSES),
    cardTypes: adaptCardTypes(CARD_TYPES),
    genders: adaptGenders(GENDERS),
    organizationTypes: adaptOrganizationTypes(ORGANIZATION_TYPES),
    languages: adaptLanguages(state.listLanguages.data.results),
    // Form values of Aggregator Organization
    formValues:
      state.form.aggregatorCreationRequestDetails &&
      state.form.aggregatorCreationRequestDetails.values ||
      {},
    // Form values of Aggregator Administrator
    formValuesAdministrator:
      state.form.administratorRequestDetails &&
      state.form.administratorRequestDetails.values ||
      {},
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    addAggregatorCreationRequest: data => dispatch(addAggregatorCreationRequest(data)),
    addAggregatorCreationRequestReset: () => dispatch(addAggregatorCreationRequestReset()),
    resetAdministratorFrom: () => dispatch(reset("administratorRequestDetails")),
    listLanguages: () => dispatch(listLanguages()),
    updateAggregatorCreationRequest:
      (id, data) => dispatch(updateAggregatorCreationRequest(id, data)),
    updateAggregatorCreationRequestReset: () => dispatch(updateAggregatorCreationRequestReset()),
    aggregatorCreationRequestDetails: id => dispatch(aggregatorCreationRequestDetails(id)),
    aggregatorCreationRequestDetailsReset: () => dispatch(aggregatorCreationRequestDetailsReset()),
  };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(RepresentativeAggregatorCreationRequestDetails));
