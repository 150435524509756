import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { Button, Select } from "@wfp/ui";

import { Grid, Row } from "../../../../components/Grid";
import { QUALITIES } from "../../../../constants";
import { isEmpty } from "../../../../utils";
import {
  getSelectProps,
  blankItem,
  selectItem,
} from "../../../../utils/filters";
import { getLocalisedName, translateOptions } from "../../../../utils/i18n";
import { OfferStatus } from "../OfferCard/interfaces";
import style from "./style.scss";

export interface Commodity {
  picture_urls: string[];
  backend_creation_date: string;
  price: string;
  quality: string;
  commodity: {
    name: string;
  };
  aggregator: {
    id: number;
    name: string;
  };
}

export interface FilterableField {
  accessor: string;
  label: string;
  choices: FilterChoice;
}

export interface FilterChoice {
  value: string;
  label: string;
}

export interface SortableField {
  accessor: string;
  label: string;
  descending: boolean;
  ascendingLabel?: string;
  descendingLabel: string;
}

export interface FilterPanelProps {
  commodities: { id: number; name: string; name_translations: any }[];
  aggregators: { id: number; name: string }[];
  unitsOfMeasure: { id: number; name: string }[];
  updateFilters: (filters: {
    commodity_id: number;
    aggregator_id: number;
    quality: string;
    sort: string;
    unit_of_measure_id: string;
    status: string;
  }) => void;
  filters: {
    commodity_id: number;
    aggregator_id: number;
    quality: string;
    sort: string;
    unit_of_measure_id: string;
    status: string;
  };
  setFilters: (filters: {
    commodity_id: number;
    aggregator_id: number;
    quality: string;
    sort: string;
    unit_of_measure_id: string;
  }) => void;
}

const Filters = (props: FilterPanelProps) => {
  const { t } = useTranslation();
  const [refreshKey, setRefreshKey] = useState(new Date().valueOf())
  const getProps = (label: string, key: string) => (
    { key: `${refreshKey}-${key}`, ...getSelectProps(t(label), key, props.filters, props.setFilters)}
  );

  const offerLabels: { [key: string]: string } = {
    [OfferStatus.Accepted]: t("offerStatuses.accepted"),
    [OfferStatus.ExchangeDone]: t("offerStatuses.exchange_done"),
    [OfferStatus.New]: t("offerStatuses.new"),
    [OfferStatus.Negotiating]: t("offerStatuses.negotiating"),
    [OfferStatus.Recalled]: t("offerStatuses.recalled"),
    [OfferStatus.Rejected]: t("offerStatuses.rejected"),
    [OfferStatus.OfferDone]: t("offerStatuses.offer_done"),
    [OfferStatus.ExchangeDetailsUpdated]: t("offerStatuses.exchange_details_updated"),
    [OfferStatus.ExchangeDetailsDisputed]: t("offerStatuses.exchange_details_disputed"),
    [OfferStatus.ExchangePaymentPending]: t("offerStatuses.exchange_payment_pending"),
  };

  return (
    <Grid className={style.container}>
      <Row className={style.options}>
        <Select {...getProps("filters.statuses", "status")}>
          {blankItem}
          {Object.keys(offerLabels).map(option => (
            selectItem(offerLabels[option], option.toString())
          ))}
        </Select>

        <Select {...getProps("filters.commodities", "commodity_id")}>
          {blankItem}
          {(props.commodities || []).map((option: any) => (
            selectItem(getLocalisedName(option), option.id.toString())
          ))}
        </Select>

        <Select {...getProps("filters.qualities", "quality")}>
          {blankItem}
          {translateOptions(QUALITIES, t).map((option: any) => (
            selectItem(option.label, option.value)
          ))}
        </Select>

        <Select {...getProps("filters.aggregators", "aggregator_id")}>
          {blankItem}
          {(props.aggregators || []).map((option) => (
            selectItem(option.name, option.id.toString())
          ))}
        </Select>
      </Row>

      <Row className={style.filterActionButtons}>
         <Button
          kind="ghost"
          onClick={() => {
            props.updateFilters({} as any)
            setRefreshKey(new Date().valueOf())
          }}
          disabled={isEmpty(props.filters)}
        >
          {String(t("filters.clearAll"))}
        </Button> 
        <Button
          onClick={() => props.updateFilters(props.filters)}
          disabled={isEmpty(props.filters)}
        >
          {String(t("filters.apply"))}
        </Button>
      </Row>
    </Grid>
  );
};

export default Filters;
