import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../../components/Modal";
import PropTypes from "prop-types";
import Form from "../Form";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  addCommodityQualityGradesDetails,
  commodityQualityGradesDetails,
  commodityQualityGradesDetailsReset,
  updateCommodityQualityGradesDetails,
} from "../../../../actions";
import { adaptInitialFormValues, getTitleName } from "../../../../utils/wfpManagerConfigurationUtils";
import { getCountryObjectFromCache } from "../../../../utils";

export const CommodityQualityGradesModal = ({
  initialFormValues,
  isFetching,
  errorMessage,
  modalOptions,
  setModalOptions,
  commodityQualityGradesDetails,
  commodityQualityGradesDetailsReset,
  updateCommodityQualityGradesDetails,
  addCommodityQualityGradesDetails,
}) => {

  const { t } = useTranslation();
  const buttonRef = useRef();
  const [initialValues, setInitialValues] = useState(undefined);

  useEffect(() => {
    commodityQualityGradesDetails(modalOptions.id);
    return () => {
      commodityQualityGradesDetailsReset();
    };
  }, [modalOptions.id]);

  useEffect(() => {
    setInitialValues( modalOptions.type !== "create" ?
      adaptInitialFormValues(initialFormValues, modalOptions.id) : undefined);
  }, [initialFormValues]);

  const onSubmitEdit = (formValues) => {
    updateCommodityQualityGradesDetails(modalOptions.id, formValues);
  };

  const onSubmitCreate = (formValues) => {
    const country = getCountryObjectFromCache();
    const submitData = {...formValues, countries: [country.id]};
    addCommodityQualityGradesDetails(submitData);
  };

  const onSubmit = (formValues) => {
    switch(modalOptions.type){
    case "edit":
      onSubmitEdit(formValues);
      break;
    case "create":
      onSubmitCreate(formValues);
      break;
    default:
      break;
    }
    setModalOptions({ type: undefined, id: undefined, isOpen: false });
  };

  return (
    <Modal
      isOpen={modalOptions.isOpen}
      title={
        t("configuration.item.commodityQualityGrades") + " " + t(getTitleName(modalOptions.type))
      }
      onCancel={() => setModalOptions({ type: undefined, isOpen: false, id: undefined })}
      onValidate={() => buttonRef.current.click()}
      hideCancelButton={modalOptions.type === "view" ? true : false}
      validateText={modalOptions.type !== "view" ? t("negotiationCard.confirm") : t("common.back")}
    >
      <Form
        id={modalOptions.id}
        initialValues={initialValues}
        isFetching={isFetching}
        errorMessage={errorMessage}
        modalOptions={modalOptions}
        onSubmit={onSubmit}
        buttonRef={buttonRef}
      />
    </Modal>
  );
};

CommodityQualityGradesModal.defaultProps = {};

CommodityQualityGradesModal.propTypes = {
  initialFormValues: PropTypes.object,
  isFetching: PropTypes.bool,
  errorMessage: PropTypes.string,
  modalOptions: PropTypes.object,
  setModalOptions: PropTypes.func,
  commodityQualityGradesDetails: PropTypes.func.isRequired,
  commodityQualityGradesDetailsReset: PropTypes.func.isRequired,
  updateCommodityQualityGradesDetails: PropTypes.func.isRequired,
  addCommodityQualityGradesDetails: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    initialFormValues: state.commodityQualityGradesDetail.data,
    isFetching: state.commodityQualityGradesDetail.isFetching,
    errorMessage: state.commodityQualityGradesDetail.errorMessage,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    commodityQualityGradesDetails: (id) => dispatch(commodityQualityGradesDetails(id)),
    commodityQualityGradesDetailsReset: () => dispatch(commodityQualityGradesDetailsReset()),
    addCommodityQualityGradesDetails: (values) =>
      dispatch(addCommodityQualityGradesDetails(values, "", true)),
    updateCommodityQualityGradesDetails: (id, values) =>
      dispatch(updateCommodityQualityGradesDetails(id, values, "", true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommodityQualityGradesModal);
