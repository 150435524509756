import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import {v4 as uuidv4} from "uuid";

import {displayErrorHelpText, findInTree} from "../../utils";
import style from "./style.scss";

// A wrapper of the ReactSelect with WFP UI Kit styles
const TreePicker = ({
  id,
  label,
  tree,
  isMulti,
  loading,
  placeholder,
  smallMenu,
  errorMessage,
  isDisabled,
  input: { value, onFocus, onChange, onInputChange },
}) => {
  const [levelOptions, setLevelOptions] = useState([tree]);
  const [levelValue, setLevelValue] = useState(value ? [value] : []);

  useEffect(() => {
    if (value) {
      let option = findInTree(tree, value, "value");
      if (option) {
        const values = [option];
        const options = option.children ? [option.children] : [];
        while (option.parentId) {
          option = findInTree(tree, option.parentId, "value");
          values.unshift(option);
          options.unshift(option.children);
        }
        options.unshift(tree);
        setLevelOptions(options);
        setLevelValue(values);
      }
    } else {
      setLevelOptions([tree]);
    }
  }, [tree, value]);

  const onSelect = (option, level) => {
    if (!option) {
      setLevelOptions([tree]);
      setLevelValue([]);
      onChange(option);
      return;
    }

    if (option.children && option.children.length) {
      if (levelOptions.length - level <= 1) {
        setLevelOptions([...levelOptions, option.children]);
      } else {
        setLevelOptions([...levelOptions.splice(0, level + 1), option.children]);
      }
    }
    setLevelValue([...levelValue.splice(0, level), option]);
    onChange(option);
  };

  return (
    <div className="wfp--form-item">
      <label htmlFor={id} className="wfp--label">{label}</label>
      {levelOptions.map((options, level) => {
        return (
          <div
            key={uuidv4()} // walkaround to refresh lower levels when upper levels are changed
            className={style.wrapper}
          >
            <ReactSelect
              value={levelValue[level] || undefined}
              onFocus={onFocus}
              onChange={(option) => onSelect(option, level)}
              onInputChange={onInputChange}
              className="wfp--react-select-container"
              classNamePrefix="wfp--react-select"
              id={id}
              name={id}
              options={options}
              isMulti={isMulti}
              isLoading={loading}
              placeholder={placeholder}
              maxMenuHeight={smallMenu ? "140" : undefined}
              isDisabled={isDisabled}
              isClearable
            />
          </div>

        );
      })}
      {displayErrorHelpText(errorMessage)}
    </div>
  );
};

// propTypes for the Select component
TreePicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  tree: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.any,
    children: PropTypes.any,
  })),
  input: PropTypes.object.isRequired,
  isMulti: PropTypes.bool,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
  smallMenu: PropTypes.bool,
  errorMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
};

// defaultProps for the Select component
TreePicker.defaultProps = {
  label: "",
  isMulti: false,
  smallLabel: false,
  loading: false,
  placeholder: "",
  smallMenu: false,
  errorMessage: "",
  isDisabled: false,
};

export default TreePicker;
