import React from "react";
import PropTypes from "prop-types";
import { getErrors } from "../../../../utils";
import { adaptOptions } from "../../../../utils/adapters";
import { useTranslation } from "react-i18next";
import { Col, Grid, Row } from "../../../../components/Grid";
import { Field, reduxForm } from "redux-form";
import Input from "../../../../components/Input";
import FormWrapper from "../../../../components/FormWrapper";
import Select from "../../../../components/Select";
import Module from "../../../../components/Module";
import { MandatoryLabel } from "../../../../components/MandatoryLabel";
import Button from "../../../../components/Button";

// This component is used to display the list
// of commodities for the WFP Manager
export const CommodityEditForm = ({
  onValidate,
  unitsOfMeasure,
  countries,
  fieldErrors,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <Grid>
      <Row>
        <Col sm={8}>
          <Module
            title={"Edit Commodity"}
            footer={<Button onClick={onValidate}>{t("common.save")}</Button>}
          >
            <Grid>
              <FormWrapper>
                <Row>
                  <Col sm={12}>
                    <Field
                      placeholder={t("commodityEdit.namePlaceholder")}
                      component={Input}
                      name="name"
                      id="name"
                      errorMessage={getErrors(fieldErrors, "name")}
                      label={
                        <MandatoryLabel
                          label={t("commodityEdit.nameLabel")}
                        ></MandatoryLabel>
                      }
                    />
                  </Col>
                </Row>
                { i18n.language !== "en" && <Row>
                  <Col sm={12}>
                    <Field
                      placeholder={t(
                        "commodityEdit.nameTranslationPlaceholder",
                      )}
                      component={Input}
                      name="name_translation"
                      id="name_translation"
                      errorMessage={getErrors(fieldErrors, "name_translations")}
                      label={
                        <MandatoryLabel
                          label={`${t("commodityEdit.nameTranslationLabel")} (${i18n.language})`}
                        ></MandatoryLabel>
                      }
                    />
                  </Col>
                </Row>}
                <Row>
                  <Col sm={12}>
                    <Field
                      placeholder={t("commodityEdit.wingsIdPlaceholder")}
                      component={Input}
                      name="wings_id"
                      id="wings_id"
                      errorMessage={getErrors(fieldErrors, "wings_id")}
                      label={t("commodityEdit.wingsIdLabel")}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Field
                      placeholder={t("commodityEdit.unitsOfMeasurePlaceholder")}
                      component={Select}
                      name="units"
                      id="units"
                      label={t("commodityEdit.unitsOfMeasureLabel")}
                      loading={false}
                      errorMessage={getErrors(fieldErrors, "units")}
                      options={adaptOptions(unitsOfMeasure, "id", "name")}
                      isMulti
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Field
                      placeholder={t("commodityEdit.countriesPlaceholder")}
                      component={Select}
                      options={adaptOptions(countries, "id", "name")}
                      name="countries"
                      id="countries"
                      label={t("commodityEdit.countriesLabel")}
                      loading={false}
                      errorMessage={getErrors(fieldErrors, "countries")}
                      isMulti
                    />
                  </Col>
                </Row>
              </FormWrapper>
            </Grid>
          </Module>
        </Col>
      </Row>
    </Grid>
  );
};

// propTypes for the Commodities component
CommodityEditForm.propTypes = {
  countries: PropTypes.array.isRequired,
  unitsOfMeasure: PropTypes.array.isRequired,
  onValidate: PropTypes.func.isRequired,
  fieldErrors: PropTypes.object.isRequired,
};

// The component uses the redux store
export default reduxForm({
  form: "commodityEdit",
  enableReinitialize: true,
})(CommodityEditForm);
