import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { userDetail } from "../../actions";
import WfpManagerMainNavigation from "../../components/WfpManagerMainNavigation";
import { getCountryObjectFromCache } from "../../utils";

// The header used in all pages to allow user access to all section of the application
export class WfpManagerHeader extends Component {
  // Binding of methods and state initialization
  constructor(props) {
    super(props);
    this.onClickChangeCountry = this.onClickChangeCountry.bind(this);
    this.state = {
      country: {
        name: "",
        code: "",
        flag: "",
      },
    };
  }

  // When component mount the user is fetched
  // and country is read
  componentDidMount() {
    this.props.userDetail();
    const country = getCountryObjectFromCache();
    this.setState({ country });
  }

  // On click change country, country selection page is displayed
  onClickChangeCountry() {
    this.props.history.push("/wfpManager/countrySelection");
  }

  render() {
    return (
      <WfpManagerMainNavigation
        onClickChangeCountry={this.onClickChangeCountry}
        loading={this.props.isFetching}
        user={this.props.currentUser}
        country={this.state.country}
      />
    );
  }
}

// propTypes for the WfpManagerHeader component
WfpManagerHeader.propTypes = {
  history: PropTypes.any.isRequired,
  isFetching: PropTypes.bool.isRequired,
  userDetail: PropTypes.func.isRequired,
  currentUser: PropTypes.any,
};

// defaultProps for the WfpManagerHeader component
WfpManagerHeader.defaultProps = {
  currentUser: undefined,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = state => {
  return {
    currentUser: state.userDetail && state.userDetail.data,
    isFetching: !state.userDetail || state.userDetail.isFetching,
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    userDetail: () => dispatch(userDetail()),
  };
};

// The component needs to use the router to redirect user to the search page
// after entering some text in the search box
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WfpManagerHeader));
