import React, {useEffect, useState} from "react";
import PageStandard from "../../components/PageStandard";
import {useTranslation} from "react-i18next";
import {adaptCIAMUsers} from "../../utils/adapters";
import {
    API_URL_CIAM_USER_CREATE,
    listCIAMUsersPendingCreation,
    API_URL_USER_LIST,
} from "../../actions";
import {connect} from "react-redux";
import {List} from "./components/List";
import client, { AX_OMIT_COUNTRY_HEADER_NAME } from "../../client";
import {displayErrorMessage} from "../../utils";
import Modal from "../../components/Modal";
import { InlineNotification } from "@wfp/ui";

export interface CIAMUser {
    id: number;
    email: string;
    user_profile: string;
    first_name: string;
    last_name: string;
}

interface Props {
    isFetching: boolean;
    errorMessage: string;
    records: CIAMUser[];
    listCIAMUsersPendingCreation: () => void;
    submitUser: (userId: number) => void;
}

type NotificationType = "success" | "error";

interface notificationStateInterface {
    state: boolean;
    message: string;
    type: NotificationType
}

export const PlatformAccess = (props: Props) => {

    const { t } = useTranslation();

    const [inProgress, setInProgress] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [rejectModalOptions, setRejectModalOptions] = useState<{isOpen: boolean, id: undefined | number}>({ isOpen: false, id: undefined })
    const [notificationState, setNotificationState] = useState<notificationStateInterface>({state: false, message: "", type: "error"})
    
    const startNotification = (message: string, type: NotificationType) => {
        setNotificationState({state: true, message, type})
        return setTimeout(() => {
            setNotificationState({state: false, message: "", type})
        }, 5000)
    }

    useEffect(() => {
        props.listCIAMUsersPendingCreation();
    }, []);

    const submitUser = async (user_id: number) => {
        setInProgress(true);
        setErrorMessage(undefined);
        const user = props.records.find(user => user.id === user_id) || { email: "User not found"}
        try {
            const response = await client.post(API_URL_CIAM_USER_CREATE, {user_id});
            if (response.data && response.data.ciam_user_created) {
                props.listCIAMUsersPendingCreation();
                const notificationMessage = t("platformAccess.approveSuccess", {email: user.email})
                startNotification(notificationMessage, "success")
            } else {
                setErrorMessage(response.data.error_msg);
                const notificationMessage = t("platformAccess.approveError", {email: user.email})
                startNotification(notificationMessage, "error")
            }
        } catch (e: any) {
            const errorMessage = e.response && e.response.data && typeof e.response.data === "string" ?
                `[${e.response.status}] ${e.response.data}` : e.message;
            const notificationMessage = t("platformAccess.approveError", {email: user.email})
            startNotification(notificationMessage, "error")
            setErrorMessage(errorMessage);
        } finally {
            setInProgress(false);
        }
    }
    
    const rejectUser = async (user_id: number | undefined) => {
        if(!user_id){
            setErrorMessage(t("platformAccess.unknownUser"));
            const notificationMessage = t("platformAccess.userNotFound")
            startNotification(notificationMessage, "error")
            return
        }
        setInProgress(true);
        setErrorMessage(undefined);
        setRejectModalOptions({id: undefined, isOpen: false})
        const user = props.records.find(user => user.id === user_id) || { email: "User not found"}
        const notificationMessage = t("platformAccess.rejectError", {email: user.email})
        try {
            const url = API_URL_USER_LIST + `${user_id}`
            const response = await client.delete(url);
            if (response.status === 204) {
                props.listCIAMUsersPendingCreation();
                const notificationMessage = t("platformAccess.rejectSuccess", {email: user.email})
                startNotification(notificationMessage, "success")
            } else {
                setErrorMessage(response.data.error_msg);
                startNotification(notificationMessage, "error")
            }
        } catch (e: any) {
            const errorMessage = e.response && e.response.data && typeof e.response.data === "string" ?
                `[${e.response.status}] ${e.response.data}` : e.message;
            startNotification(notificationMessage, "error")
            if(e.response && e.response.status === 404){
                setErrorMessage("Error: Permission denied")
            } else {
                setErrorMessage(errorMessage);
            }
        } finally {
            setInProgress(false);
        }
    }

    const selectedRejectedRow = props.records.find((item) => item.id === rejectModalOptions.id) || {first_name: "", last_name: ""};

    return (
        <div>
            <PageStandard title={t("platformAccess.title")}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "baseline"}}>
                    {displayErrorMessage(errorMessage)}
                    <div style={{width: "100%"}}/>
                    {notificationState.state && <InlineNotification
                        kind={notificationState.type}
                        lowContrast
                        statusIconDescription={t(`common.${notificationState.type}`)}
                        subtitle={notificationState.message}
                        title={t(`common.${notificationState.type}`)}
                        onCloseButtonClick={() => setNotificationState({state: false, message: "", type: notificationState.type})}
                        role={notificationState.type}
                    />}
                </div>
                <List
                    isFetching={props.isFetching || inProgress}
                    errorMessage={props.errorMessage}
                    records={props.records}
                    submitUser={submitUser}
                    setRejectModalOptions={setRejectModalOptions}
                />
            </PageStandard>
            <Modal
                isOpen={!!rejectModalOptions.isOpen && !!rejectModalOptions.id}
                onValidate={() => rejectUser(rejectModalOptions.id)}
                title={t("common.reject") + " " + t("platformAccess.title")}
                danger={true}
                onCancel={() => setRejectModalOptions({isOpen: false, id: undefined})}
            >
                {`${t("platformAccess.rejectText")}` + `${selectedRejectedRow.first_name} ${selectedRejectedRow.last_name}`}
            </Modal>
       </div>
    );
}

export const mapStateToProps = (state: any) => {
    return {
        isFetching: state.listCIAMUsersPendingCreation.isFetching,
        errorMessage: state.listCIAMUsersPendingCreation.errorMessage,
        records: state.listCIAMUsersPendingCreation.data ? adaptCIAMUsers(
            state.listCIAMUsersPendingCreation.data.users,
        ) : [],
    };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch: any) => {
    return {
        listCIAMUsersPendingCreation: () => dispatch(listCIAMUsersPendingCreation()),
    };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(PlatformAccess);
