import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { listCountries, updateExternalUser, addExternalUser } from "../../actions";
import { getCountryObjectFromCache, isEmpty } from "../../utils";
import Form from "./components/Form";
import { useTranslation } from "react-i18next";
import PageStandard from "../../components/PageStandard";
import { v4 } from "uuid";

// This component is used to display the list
// of commodities for the WFP ExternalUser
export const ExternalUserDetails = ({
  history,
  addExternalUser,
  updateExternalUser,
  listCountries,
}) => {
  useEffect(() => {
    listCountries();
  }, []);

  const { t } = useTranslation();
  const externalUser =
    history && history.location.state && history.location.state.externalUser;

  const adaptFormValues = (formValues) => {

    const externalUser = {
      ...formValues,
      person: { ...formValues.person, uuid: v4() },
      ciam_user_created: true,
      country: getCountryObjectFromCache().id,
    };
    return externalUser;
  };

  const adaptFromInitial = (externalUser) => {
    if (!externalUser) {
      return;
    }
    return {
      ...externalUser,
    };
  };

  const onValidate = (formValues) => {
    let promise;
    if (externalUser && externalUser.id) {
      promise = updateExternalUser(externalUser.id, adaptFormValues(formValues, externalUser));
    } else {
      promise = addExternalUser(adaptFormValues(formValues));
    }

    promise.then((result) => {
      if (result) {
        history.push("/wfpManager/externalUsers");
      }
    });
  };

  return (
    <PageStandard title={t("externalUsers.pageTitle")}>
      <Form
        onValidate={onValidate}
        initialValues={adaptFromInitial(externalUser)}
      />
    </PageStandard>
  );
};

// propTypes for the Commodities component
ExternalUserDetails.propTypes = {
  history: PropTypes.object.isRequired,
  addExternalUser: PropTypes.func.isRequired,
  updateExternalUser: PropTypes.func.isRequired,
  listCountries: PropTypes.func.isRequired,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = (state) => {
  return {
    // Commodities management
    formValues:
      (state.form.externalUserDetails && state.form.externalUserDetails.values) || {},
    fieldErrors:
      (!isEmpty(state.addExternalUser.error) && state.addExternalUser.error) ||
      (!isEmpty(state.updateExternalUser.error) && state.updateExternalUser.error) ||
      {},
    countries: state.userDetail.data.countries,
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    listCountries: () => dispatch(listCountries({ page_size: 999 })),
    updateExternalUser: (id, externalUser) => dispatch(updateExternalUser(id, externalUser)),
    addExternalUser: (externalUser) => dispatch(addExternalUser(externalUser)),
  };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(ExternalUserDetails);
