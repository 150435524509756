import React, { useEffect } from "react";
import { iconWfpLogoEmblem } from "@wfp/icons";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import Icon from "../../components/Icon";
import style from "./style.scss";

// A page displayed when login callback is received
export const LogoutCallback = ({ history }) => {
  // After logout, local storage is cleared
  useEffect(() => {
    localStorage.clear();
    history.push("/login");
  }, []);

  return (
    <div className={style.wrapper}>
      <Icon
        icon={iconWfpLogoEmblem}
        width="150"
        height="150"
        fill="#ffffff"
      />
    </div>
  );
};

// propTypes for the LogoutCallback component
LogoutCallback.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(LogoutCallback);
