import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import Select from "..";

const RHFSelect = ({ rules, name, control, ...props }) => {
  return (
    <Controller
      render={({field}) => <Select input={field} {...props}/>}
      name={name}
      control={control}
      rules={rules}
    />
  );
};

RHFSelect.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  options: PropTypes.array,
  isMulti: PropTypes.bool,
  rules: PropTypes.object,
};

export default RHFSelect;
