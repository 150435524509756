import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../../components/Modal";
import PropTypes from "prop-types";
import Form from "../Form";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  addAdministrativeAreaTypesDetails,
  administrativeAreaTypesDetails,
  administrativeAreaTypesDetailsReset,
  updateAdministrativeAreaTypesDetails,
} from "../../../../actions";
import { adaptInitialFormValues, getTitleName } from "../../../../utils/wfpManagerConfigurationUtils";
import { getCountryObjectFromCache } from "../../../../utils";

export const adaptFormValues = (formValues) => {
  return {
    ...formValues,
    parent: formValues.parent.value,
    country: getCountryObjectFromCache().id,
  };
};

export const formatParentValues = (initialFormValues, modalOptions, administrativeAreaTypes, t) => {
  const data = adaptInitialFormValues(initialFormValues, modalOptions.id);
  let parent;
  if(data){
    parent = data.parent !== null ? administrativeAreaTypes.find(area => area.id === data.parent) : undefined;
  }
  return {
    ...data,
    parent: parent ? {
      label: parent.name,
      value: parent.id,
    } : {
      label: t("administrativeAreaTypes.noParent"),
      value: null,
    },
  };
};

export const AdministrativeAreaTypesEditModal = ({
  administrativeAreaTypes,
  initialFormValues,
  isFetching,
  errorMessage,
  modalOptions,
  setModalOptions,
  administrativeAreaTypesDetails,
  administrativeAreaTypesDetailsReset,
  updateAdministrativeAreaTypesDetails,
  addAdministrativeAreaTypesDetails,
}) => {

  const { t } = useTranslation();
  const buttonRef = useRef();
  const [initialValues, setInitialValues] = useState(undefined);

  useEffect(() => {
    administrativeAreaTypesDetails(modalOptions.id);
    return () => {
      administrativeAreaTypesDetailsReset();
    };
  }, [modalOptions.id]);

  useEffect(() => {
    setInitialValues( modalOptions.type !== "create" ? formatParentValues(initialFormValues, modalOptions, administrativeAreaTypes, t) : undefined);
  }, [initialFormValues]);

  const onSubmitEdit = (formValues) => {
    updateAdministrativeAreaTypesDetails(modalOptions.id, formValues);
  };

  const onSubmitCreate = (formValues) => {
    addAdministrativeAreaTypesDetails(formValues);
  };

  const onSubmit = (formValues) => {
    const adaptedValues = adaptFormValues(formValues);
    switch(modalOptions.type){
    case "edit":
      onSubmitEdit(adaptedValues);
      break;
    case "create":
      onSubmitCreate(adaptedValues);
      break;
    default:
      break;
    }
    setModalOptions({ type: undefined, id: undefined, isOpen: false });
  };

  const administrativeAreaTypesFilteredByCountry = administrativeAreaTypes.filter(areaType => areaType.country === getCountryObjectFromCache().id);

  return (
    <Modal
      isOpen={modalOptions.isOpen}
      title={
        t("configuration.item.administrativeAreaTypes") + " " + t(getTitleName(modalOptions.type))
      }
      onCancel={() => setModalOptions({ type: undefined, isOpen: false, id: undefined })}
      onValidate={() => buttonRef.current.click()}
      hideCancelButton={modalOptions.type === "view" ? true : false}
      validateText={modalOptions.type !== "view" ? t("negotiationCard.confirm") : t("common.back")}
    >
      <Form
        id={modalOptions.id}
        initialValues={initialValues}
        isFetching={isFetching}
        errorMessage={errorMessage}
        modalOptions={modalOptions}
        onSubmit={onSubmit}
        buttonRef={buttonRef}
        administrativeAreaTypes={administrativeAreaTypesFilteredByCountry}
      />
    </Modal>
  );
};

AdministrativeAreaTypesEditModal.defaultProps = {};

AdministrativeAreaTypesEditModal.propTypes = {
  initialFormValues: PropTypes.object,
  isFetching: PropTypes.bool,
  errorMessage: PropTypes.string,
  modalOptions: PropTypes.object,
  setModalOptions: PropTypes.func,
  administrativeAreaTypesDetails: PropTypes.func.isRequired,
  administrativeAreaTypesDetailsReset: PropTypes.func.isRequired,
  updateAdministrativeAreaTypesDetails: PropTypes.func.isRequired,
  addAdministrativeAreaTypesDetails: PropTypes.func.isRequired,
  administrativeAreaTypes: PropTypes.array,
};

export const mapStateToProps = (state) => {
  return {
    initialFormValues: state.administrativeAreaTypesDetail.data,
    isFetching: state.administrativeAreaTypesDetail.isFetching,
    errorMessage: state.administrativeAreaTypesDetail.errorMessage,
    administrativeAreaTypes: state.listAdministrativeAreaTypes.data.results,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    administrativeAreaTypesDetails: (id) => dispatch(administrativeAreaTypesDetails(id)),
    administrativeAreaTypesDetailsReset: () => dispatch(administrativeAreaTypesDetailsReset()),
    addAdministrativeAreaTypesDetails: (values) =>
      dispatch(addAdministrativeAreaTypesDetails(values, "", true)),
    updateAdministrativeAreaTypesDetails: (id, values) =>
      dispatch(updateAdministrativeAreaTypesDetails(id, values, "", true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdministrativeAreaTypesEditModal);
