import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Col, Grid, Row } from "../../../../components/Grid";
import Loading from "../../../../components/Loading";
// import { formatDateTimeDefault } from "../../../../utils";
import style from "./style.scss";

export const RepresentativesTable = ({
  representatives,
  isFetchingRepresentatives,
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      Header: "column.name",
      Cell: (representative) =>
        `${representative.person.first_name} ${representative.person.last_name}`,
      width: 2,
    },
    {
      Header: "column.mobile",
      Cell: (representative) => representative.person.contact_phone,
      width: 2,
    },
    {
      Header: "column.email",
      Cell: (representative) => representative.person.contact_email,
      width: 4,
    },
    // {
    //   Header: "column.lastLogin",
    //   Cell: (representative) => {
    //     return representative.last_login_date
    //       ? formatDateTimeDefault(
    //         new Date(representative.last_login_date),
    //       )
    //       : "";
    //   },
    //   width: 2,
    // },
  ];

  return isFetchingRepresentatives ? (
    <Loading isVisible={true} />
  ) : (
    <Grid className={style.repsTable}>
      <Row className={style.theader}>
        {columns.map((column) => (
          <Col
            className={style.th}
            sm={column.width}
            key={`repstable_col_${column.Header.toLowerCase()}`}
          >
            {t(column.Header)}
          </Col>
        ))}
      </Row>

      {representatives.map((representative, i) => {
        return (
          <Row className={style.trow} key={`reptable_row_${i}`}>
            {columns.map((column) => (
              <Col
                className={style.tcell}
                sm={column.width}
                key={`repstable_col_${column.Header.toLowerCase()}_row_${i}`}
              >
                {column.Cell(representative)}
              </Col>
            ))}
          </Row>
        );
      })}
    </Grid>
  );
};

RepresentativesTable.propTypes = {
  representatives: PropTypes.array,
  isFetchingRepresentatives: PropTypes.bool,
};

export default RepresentativesTable;
