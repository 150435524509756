import React, {useEffect, useRef, useState} from "react";
import Modal from "../../../../components/Modal";
import PropTypes from "prop-types";
import Form from "../Form";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {
  addRecallReasonsDetails,
  recallReasonsDetails,
  recallReasonsDetailsReset,
  updateRecallReasonsDetails,
} from "../../../../actions";
import { adaptInitialFormValues, getTitleName } from "../../../../utils/wfpManagerConfigurationUtils";

export const RecallReasonsEditModal = ({
  initialFormValues,
  isFetching,
  errorMessage,
  modalOptions,
  setModalOptions,
  recallReasonsDetails,
  recallReasonsDetailsReset,
  updateRecallReasonsDetails,
  addRecallReasonsDetails,
}) => {

  const { t } = useTranslation();
  const buttonRef = useRef();
  const [initialValues, setInitialValues] = useState();
  useEffect(() => {
    recallReasonsDetails(modalOptions.id);
    return () => {
      recallReasonsDetailsReset();
    };
  }, [modalOptions.id]);

  useEffect(() => {
    setInitialValues(adaptInitialFormValues(initialFormValues, modalOptions.id));
  }, [initialFormValues]);

  const onSubmitEdit = (formValues) => {
    updateRecallReasonsDetails(modalOptions.id, formValues);
    setModalOptions({ type: undefined, id: undefined, isOpen: false });
  };

  const onSubmitCreate = (formValues) => {
    addRecallReasonsDetails(formValues);
    setModalOptions({ type: undefined, id: undefined, isOpen: false });
  };

  return (
    <Modal
      isOpen={modalOptions.isOpen}
      title={
        t("configuration.item.traderOfferRecallReasons") + " " + t(getTitleName(modalOptions.type))
      }
      onCancel={() => setModalOptions({ type: undefined, isOpen: false, id: undefined })}
      onValidate={() => buttonRef.current.click()}
    >
      <Form
        id={modalOptions.id}
        initialValues={initialValues}
        isFetching={isFetching}
        errorMessage={errorMessage}
        modalOptions={modalOptions}
        onSubmit={modalOptions.type === "create" ? onSubmitCreate : onSubmitEdit}
        buttonRef={buttonRef}
      />
    </Modal>
  );
};

RecallReasonsEditModal.defaultProps = {};

RecallReasonsEditModal.propTypes = {
  initialFormValues: PropTypes.object,
  isFetching: PropTypes.bool,
  errorMessage: PropTypes.string,
  modalOptions: PropTypes.object,
  setModalOptions: PropTypes.func,
  recallReasonsDetails: PropTypes.func.isRequired,
  recallReasonsDetailsReset: PropTypes.func.isRequired,
  updateRecallReasonsDetails: PropTypes.func.isRequired,
  addRecallReasonsDetails: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    initialFormValues: state.recallReasonsDetails.data,
    isFetching: state.recallReasonsDetails.isFetching,
    errorMessage: state.recallReasonsDetails.errorMessage,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    recallReasonsDetails: (id) => dispatch(recallReasonsDetails(id)),
    recallReasonsDetailsReset: () => dispatch(recallReasonsDetailsReset()),
    addRecallReasonsDetails: (values) =>
      dispatch(addRecallReasonsDetails(values, "", true)),
    updateRecallReasonsDetails: (id, values) =>
      dispatch(updateRecallReasonsDetails(id, values, "", true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecallReasonsEditModal);
