import React from "react";
import PropTypes from "prop-types";

import Module from "../../../../components/Module";
import Loading from "../../../../components/Loading";
import Table from "../../../../components/Table";
import ShowHideArea from "../../../../components/ShowHideArea";
import { displayErrorMessage } from "../../../../utils";
import {withTranslation} from "react-i18next";

// The list of columns and their properties
export const columns = () => [
  {
    Header: "column.commodity",
    accessor: "commodity.name",
  },
  {
    Header: "column.totalQuantity",
    accessor: "total_quantity",
  },
];

// The list of a deposits of aggregator
export const Deposits = (
  {
    isFetchingAggregator,
    errorMessageAggregator,
    deposits,
    isVisible,
    onPageChangeDeposits,
    depositsPage,
    t,
  },
) => {

  return (
    <ShowHideArea isVisible={isVisible}>
      {isFetchingAggregator && <Loading isVisible />}
      {displayErrorMessage(errorMessageAggregator)}
      <Module
        title={t("aggregatorView.deposits.title")}
        table={
          <Table
            loading={false}
            columns={columns()}
            data={deposits || []}
            onPageChange={onPageChangeDeposits}
            page={depositsPage}
          />
        }
      />
    </ShowHideArea>
  );
};

// propTypes for the Market component
Deposits.propTypes = {
  isFetchingAggregator: PropTypes.bool.isRequired,
  errorMessageAggregator: PropTypes.string.isRequired,
  deposits: PropTypes.array.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onPageChangeDeposits: PropTypes.func.isRequired,
  depositsPage: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

// defaultProps for the Market component
Deposits.defaultProps = {
  errorMessageAggregator: "",
};

export default withTranslation()(Deposits);
