/* eslint-disable max-len */
import style from "./style.scss";
import { Modal as WFPModal } from "@wfp/ui";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import MarkdownPreview from "../MarkdownPreview";

const TermsAndConditionsModal = ({ isBusy, onTermsSubmit, show, termsAndConditions }) => {
  const { t } = useTranslation();

  return (
    <WFPModal
      inPortal={false}
      open={show}
      primaryButtonText={
        isBusy
          ? t("termsAndConditions.waiting")
          : t("termsAndConditions.accept")
      }
      primaryButtonDisabled={isBusy}
      secondaryButtonText={undefined}
      onRequestSubmit={onTermsSubmit}
      hideClose
    >
      <div className={style.modalContainer}>
        {termsAndConditions && <MarkdownPreview source={termsAndConditions.long_text} />}
      </div>
    </WFPModal>
  );
};

// propTypes for the Toggle component
TermsAndConditionsModal.propTypes = {
  isBusy: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  onTermsSubmit: PropTypes.func.isRequired,
  termsAndConditions: PropTypes.object.isRequired,
};

export default TermsAndConditionsModal;
