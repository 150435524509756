import React from "react";
import ReactDOM from "react-dom";

import { configureSentry } from "./sentry";

// Sentry setup
configureSentry();

// Import of global WFP UI Kit styles
import "./assets/css/wfp.css";

// Import of global styles of the application
import "./assets/scss/base.scss";

// Import tippy styles
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "./assets/scss/custom-datepicker.scss";


import "./i18n";
import Application from "./containers/Application";

// It renders the root component in the DOM
ReactDOM.render(<Application />, document.getElementById("index"));
