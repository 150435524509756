import React from "react";
import PropTypes from "prop-types";
import {
  Module as WFPModule,
  ModuleBody,
  ModuleFooter,
  ModuleHeader,
} from "@wfp/ui";

import style from "./style.scss";

// A wrapper of the Module component form WFP UI Kit
const Module = ({
  title,
  subtitle,
  children,
  footer,
  filter,
  table,
  actions,
}) => (
  <div className={style.wrapper}>
    <WFPModule>
      <div className={style.header}>
        {
          title || subtitle || filter
            ? <ModuleHeader filter={filter}>
              <span>{title}</span>
              {
                subtitle ? (
                  <div className={style.subtitle}>
                    {subtitle}
                  </div>
                ) : ""
              }
            </ModuleHeader>
            : ""
        }
        {
          !!actions && (
            <div className={style.actionsWrapper}>
              {actions}
            </div>
          )
        }
      </div>
      {
        children
          ? <ModuleBody>
            {children}
          </ModuleBody>
          : ""
      }
      {table && <div className={style.table}>{table}</div>}
      {
        footer
          ? <ModuleFooter>
            <div className={style.footer}>
              {footer}
            </div>
          </ModuleFooter>
          : ""
      }
    </WFPModule>
  </div>
);

// propTypes for the Module component
Module.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  children: PropTypes.node,
  footer: PropTypes.node,
  filter: PropTypes.node,
  table: PropTypes.node,
  actions: PropTypes.node,
};

// defaultProps for the Module component
Module.defaultProps = {
  subtitle: undefined,
  footer: undefined,
  children: undefined,
  actions: undefined,
  filter: "",
  table: "",
  title: "",
};

// A component used to separate sections in a module
export const SectionTitle = ({ title, withSeparator }) => (
  <div className={withSeparator ? style.sectionTitleSeparator : style.sectionTitle}>
    <h4>{title}</h4>
  </div>
);

// propTypes for the SectionTitle component
SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  withSeparator: PropTypes.bool,
};

// defaultProps for the SectionTitle component
SectionTitle.defaultProps = {
  withSeparator: false,
};

export default Module;
