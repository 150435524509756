
import React, { useContext, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { displayErrorMessage, isEmpty } from "../../../../utils";
import { Grid, Row, Col } from "../../../../components/Grid";
import { HelpContext } from "../../../HelpMessageRoute";
import { iconEdit, iconDelete } from "@wfp/icons";
import { menu } from "../../../../utils/table";
import { StepNavigation, StepNavigationItem } from "@wfp/ui";
import { withTranslation } from "react-i18next";
import Button from "../../../../components/Button";
import ButtonsWrapper from "../../../../components/ButtonsWrapper";
import FormWrapper from "../../../../components/FormWrapper";
import Loading from "../../../../components/Loading";
import ModalConfirm from "../../../../components/ModalConfirm";
import Module from "../../../../components/Module";
import PropTypes from "prop-types";
import Spacer from "../../../../components/Spacer";
import style from "./style.scss";
import TableInMemory from "../../../../components/Table";
import TraderDetailsStepOne from "./Components/TraderDetailsStepOne";
import RepresentativeForm from "../RepresentativeForm";
import ExchangeLocationForm from "../ExchangeLocationForm";

// The list of columns related to Trader Representatives
export const traderRepresentativesColumns = (onClickEdit, onClickDelete, t) => [
  {
    Header: "traderDetails.form.column.name",
    accessor: "person.full_name",
  },
  {
    Header: "traderDetails.form.column.email",
    accessor: "email",
  },
  {
    Header: "",
    Cell: (data) =>
      menu([
        {
          key: data.row.original.id,
          icon: iconEdit,
          label: t("common.edit"),
          onClick: onClickEdit,
          visible: true,
          data: data.row.original,
        },
        {
          key: data.row.original.id + 2,
          icon: iconDelete,
          label: t("common.delete"),
          onClick: onClickDelete,
          visible: true,
          data: data.row.original,
        },
      ]),
    width: 40,
  },
];

const getErrors = (object, key) =>
  (object && object[key] && !isEmpty(object[key]))
    ? object[key]
    : "";

const formResolver = (values, t) => {
  let errors = {};
  if(values.name === "" || !values.name){
    errors.name = t("traderDetails.form.mandatoryFieldBlankError");
  }
  if(values.location === "" || !values.location){
    errors.location = t("traderDetails.form.mandatoryFieldBlankError");
  }
  if(values.language === "" || !values.language){
    errors.language = t("traderDetails.form.mandatoryFieldBlankError");
  }
  return { values, errors};
};

// The details of a Trader Organization
export const Form = ({
  isFetching,
  errorMessage,
  fieldErrors,
  onClickValidateAndClose,
  onClickValidateAndContinue,
  traderRepresentatives,
  onClickEditRepresentative,
  onClickDeleteRepresentative,
  isDeleteRepresentativeModalOpen,
  onClickConfirmDeleteRepresentative,
  onClickCancelDeleteRepresentative,
  currentToDeleteRepresentative,
  isFetchingDeleteRepresentative,
  errorMessageDeleteRepresentative,
  administrativeAreas,
  initialStep,
  t,
  initialValues,
  exchangeLocations,
  exchangeLocationTypes,
  // Add Representative Props
  initialFormValuesRepresentative,
  isFetchingRepresentative,
  errorMessageRepresentative,
  onClickValidateRepresentativeForm,
  fieldErrorsRepresentative,
  cardTypes,
  genders,
  onClickBackRepresentativeForm,
  isOnEditRepresentative,
  // Assign exchange location props
  onClickValidateExchangeLocationsForm,
  isFetchingExchangeLocations,
  errorMessageExchangeLocations,
  fieldErrorsExchangeLocations,
  onClickBackExchangeLocationForm,
  isOnEditExchangeLocation,
  hasCustomExchangeLocationFeatureEnabled,
}) => {
  if (isFetching) {
    return <Loading isVisible />;
  }

  if (errorMessage) {
    return <>{displayErrorMessage(errorMessage)}</>;
  }

  const buttonRef = useRef();
  const exchangeLocationFormRef = useRef();

  const methods = useForm({
    defaultValues: initialValues,
    resolver: formResolver,
    context: t,
  });
  const { handleSubmit, formState: { errors }} = methods;

  const [showBlankFieldErrors, setShowBlankFieldErrors] = useState(false);
  const help = useContext(HelpContext);
  const [step, setStep] = useState(initialStep || 0);

  const [representativeWarning, setRepresentativeWarning] = useState("");
  const deleteRepresentativeMessage =
    (
      !!currentToDeleteRepresentative
      && !!currentToDeleteRepresentative.person
      && (
        t("traderDetails.form.deleteRepresentativeMessage")
        + " "
        + currentToDeleteRepresentative.person.full_name
        + "?"
      )
    )
    || "";

  const getStepTitle = () => {
    switch (step) {
    case 0:
      return `${t("traderDetails.form.trader")} ${t("traderDetails.form.details").toLowerCase()}`;
    case 1:
      return t("traderDetails.form.addTraderRepresentatives");
    case 2:
      return t("traderDetails.form.addExchangeLocations");
    default:
      return "";
    }
  };

  const onClickNext = () => {
    if (step === 0) {
      if (getErrors(errors, "name")
      || getErrors(errors, "location")){
        setShowBlankFieldErrors(true);
        return;
      }
      buttonRef.current.click();
    }
    if (step === 1) {
      if (traderRepresentatives && traderRepresentatives.length > 0){
        buttonRef.current.click();
      } else {
        setRepresentativeWarning(t("traderDetails.form.traderRepresentativeWarning"));
        return;
      }
    }
    if (step === 2) {
      if (exchangeLocationFormRef.current) {
        exchangeLocationFormRef.current.submitExchangeLocationsForm();
      }
    }
  };

  const nextButtonLabel = () => {
    switch(step) {
    case 0:
    case 1:
      return t("common.saveContinue");
    case 2:
    default:
      return t("common.finish");
    }
  };

  return (
    <>
      <Grid>
        <Row>
          <Col sm={2}/>
          <Col sm={8}>
            {help}
          </Col>
        </Row>
        <Row>
          <Col sm={2}>
            <StepNavigation
              className={style.stepNav}
              onSelectionChange={(e) => setStep(e)}
              selectedPage={step}
            >
              <StepNavigationItem
                key={0}
                label={t("traderDetails.form.trader")}
                page={0}
              />
              <StepNavigationItem
                key={1}
                label={t("traderDetails.form.addTraderRepresentatives")}
                page={1}
              />
              {hasCustomExchangeLocationFeatureEnabled &&
                <StepNavigationItem
                  key={2}
                  label={t("traderDetails.form.addExchangeLocations")}
                  page={2}
                />}
            </StepNavigation>
          </Col>
          <Col sm={8}>
            <Module
              title={`${getStepTitle()}`}
              footer={
                <ButtonsWrapper>
                  <Button onClick={onClickNext}>
                    {nextButtonLabel()}
                  </Button>
                </ButtonsWrapper>
              }
            >
              <FormWrapper>
                <FormProvider>
                  <form onSubmit={handleSubmit(
                    (step === 0) || (hasCustomExchangeLocationFeatureEnabled && step === 1) ? (formState) => onClickValidateAndContinue(formState, step) : onClickValidateAndClose,
                  )}
                  >
                    {step === 0 && (
                      <TraderDetailsStepOne
                        methods={methods}
                        showBlankFieldErrors={showBlankFieldErrors}
                        administrativeAreas={administrativeAreas}
                        fieldErrors={fieldErrors}
                      />
                    )}
                    <button ref={buttonRef} style={{display: "none"}}/>
                  </form>
                </FormProvider>
              </FormWrapper>
              {step === 1 && (
                <Grid>
                  <Row className={style.instructionText}>
                    {t("traderDetails.form.representativeInstructionText")}
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <RepresentativeForm
                        initialValues={initialFormValuesRepresentative}
                        isFetching={isFetchingRepresentative}
                        errorMessage={errorMessageRepresentative}
                        onClickValidate={onClickValidateRepresentativeForm}
                        fieldErrors={fieldErrorsRepresentative}
                        cardTypes={cardTypes}
                        genders={genders}
                        onClickClose={onClickBackRepresentativeForm}
                        isOnEdit={isOnEditRepresentative}
                      />
                      <Spacer height="1rem" />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      {
                        traderRepresentatives && traderRepresentatives.length ?
                          <TableInMemory
                            loading={false}
                            columns={traderRepresentativesColumns(
                              onClickEditRepresentative,
                              onClickDeleteRepresentative,
                              t,
                            )}
                            data={traderRepresentatives}
                            pageSize={10}
                          /> :
                          representativeWarning ? displayErrorMessage(representativeWarning) :
                            (
                              <div className="wfp--module__title">
                                <div className="wfp--module__header" style={{marginBottom: "1rem"}}/>
                                {t("traderDetails.form.noTraderRepresentatives")}
                              </div>
                            )
                      }
                      <Spacer height="2rem" />
                    </Col>
                  </Row>
                </Grid>
              )}
              {step === 2 && (
                <Grid>
                  <Row className={style.instructionText}>
                    <Col sm={12}>
                      {t("traderDetails.form.exchangeLocationsInstructionText")}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <ExchangeLocationForm
                        ref={exchangeLocationFormRef}
                        initialValues={initialValues}
                        isFetching={isFetchingExchangeLocations}
                        errorMessage={errorMessageExchangeLocations}
                        onClickValidate={onClickValidateExchangeLocationsForm}
                        fieldErrors={fieldErrorsExchangeLocations}
                        onClickClose={onClickBackExchangeLocationForm}
                        isOnEdit={isOnEditExchangeLocation}
                        administrativeAreas={administrativeAreas}
                        exchangeLocationTypes={exchangeLocationTypes}
                        exchangeLocations={exchangeLocations}
                        t={t}
                      />
                      <Spacer height="1rem" />
                    </Col>
                  </Row>
                </Grid>
              )}
            </Module>
          </Col>
        </Row>
      </Grid>
      <ModalConfirm
        isOpen={isDeleteRepresentativeModalOpen}
        onValidate={onClickConfirmDeleteRepresentative}
        onCancel={onClickCancelDeleteRepresentative}
        errorMessage={errorMessageDeleteRepresentative}
        isLoading={isFetchingDeleteRepresentative}
        title={t("traderDetails.form.deleteRepresentativeModalTitle")}
        text={deleteRepresentativeMessage}
      />
    </>
  );
};

// propTypes for the Form component
Form.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  fieldErrors: PropTypes.object.isRequired,
  onClickValidateAndClose: PropTypes.func.isRequired,
  onClickValidateAndContinue: PropTypes.func.isRequired,
  traderRepresentatives: PropTypes.array.isRequired,
  onClickEditRepresentative: PropTypes.func.isRequired,
  onClickDeleteRepresentative: PropTypes.func.isRequired,
  isDeleteRepresentativeModalOpen: PropTypes.bool.isRequired,
  onClickConfirmDeleteRepresentative: PropTypes.func.isRequired,
  onClickCancelDeleteRepresentative: PropTypes.func.isRequired,
  currentToDeleteRepresentative: PropTypes.object,
  isFetchingDeleteRepresentative: PropTypes.bool.isRequired,
  errorMessageDeleteRepresentative: PropTypes.string.isRequired,
  administrativeAreas: PropTypes.array.isRequired,
  initialStep: PropTypes.number,
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  initialFormValuesRepresentative: PropTypes.object,
  isFetchingRepresentative: PropTypes.bool,
  errorMessageRepresentative: PropTypes.string,
  onClickValidateRepresentativeForm: PropTypes.func.isRequired,
  fieldErrorsRepresentative: PropTypes.object,
  cardTypes: PropTypes.array,
  genders: PropTypes.array,
  onClickBackRepresentativeForm: PropTypes.func,
  isOnEditRepresentative: PropTypes.string,
  onClickValidateExchangeLocationsForm: PropTypes.func.isRequired,
  isFetchingExchangeLocations: PropTypes.bool,
  errorMessageExchangeLocations: PropTypes.string,
  fieldErrorsExchangeLocations: PropTypes.object,
  onClickBackExchangeLocationForm: PropTypes.func,
  isOnEditExchangeLocation: PropTypes.string,
  exchangeLocations: PropTypes.array.isRequired,
  exchangeLocationTypes: PropTypes.array,
  hasCustomExchangeLocationFeatureEnabled: PropTypes.bool.isRequired,
};

// defaultProps for the Form component
Form.defaultProps = {
  errorMessage: "",
  id: "",
  currentToDeleteRepresentative: undefined,
  exchangeLocationTypes: [],

};

export default withTranslation()(Form);
