import { createBrowserHistory } from "history";
import { configureStore } from "@reduxjs/toolkit";

import { createReduxHistoryContext } from "redux-first-history";

import createRootReducer from "../reducers";

export const {
  routerMiddleware,
  routerReducer,
} = createReduxHistoryContext({ history: createBrowserHistory() });

// Here we create the store, createRootReducer creates the root reducer
// used by redux to manage the state of the application.
const store = preloadedState => (
  configureStore(
    {
      reducer: createRootReducer(history),
      preloadedState,
      middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: false,
        }).concat(routerMiddleware),
    },
  )
);

export default store;
