import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../../components/Modal";
import PropTypes from "prop-types";
import Form from "../Form";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  addAggregatorUmbrellaOrganizationsDetails,
  aggregatorUmbrellaOrganizationsDetails,
  aggregatorUmbrellaOrganizationsDetailsReset,
  updateAggregatorUmbrellaOrganizationsDetails,
} from "../../../../actions";
import { getTitleName } from "../../../../utils/wfpManagerConfigurationUtils";
import { getCountryObjectFromCache } from "../../../../utils";

export const adaptInitialFormValues = (initialFormValues, administrativeAreas, administrativeAreaTypes) => {
  const area = administrativeAreas ? administrativeAreas.find(area => area.id === initialFormValues.location) : undefined;
  let name = "---";
  if(area){
    const levelID = area.level;
    name = area.name;
    if(levelID){
      const areaType = administrativeAreaTypes.find(type => type.level === levelID);
      name = areaType ? name + ` (${areaType.name})` : name;
    }
  }
  return {
    ...initialFormValues,
    location: {
      value: initialFormValues.location,
      label: name,
    },
  };
};

export const adaptSubmitValues = (formValues) => ({
  ...formValues,
  location: formValues.location.value,
  country: getCountryObjectFromCache().id,
});

export const AggregatorUmbrellaOrganizationsEditModal = ({
  initialFormValues,
  isFetching,
  errorMessage,
  modalOptions,
  setModalOptions,
  aggregatorUmbrellaOrganizationsDetails,
  aggregatorUmbrellaOrganizationsDetailsReset,
  updateAggregatorUmbrellaOrganizationsDetails,
  addAggregatorUmbrellaOrganizationsDetails,
  administrativeAreaTypes,
  administrativeAreas,
}) => {

  const { t } = useTranslation();
  const buttonRef = useRef();
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    aggregatorUmbrellaOrganizationsDetails(modalOptions.id);
    return () => {
      aggregatorUmbrellaOrganizationsDetailsReset();
    };
  }, [modalOptions.id]);

  useEffect(() => {
    setInitialValues(initialFormValues);
  }, [initialFormValues]);

  const onSubmitEdit = (formValues) => {
    updateAggregatorUmbrellaOrganizationsDetails(modalOptions.id, adaptSubmitValues(formValues));
    setModalOptions({ type: undefined, id: undefined, isOpen: false });
  };

  const onSubmitCreate = (formValues) => {
    addAggregatorUmbrellaOrganizationsDetails(adaptSubmitValues(formValues));
    setModalOptions({ type: undefined, id: undefined, isOpen: false });
  };
  return (
    <Modal
      isOpen={modalOptions.isOpen}
      title={
        t("configuration.item.aggregatorUmbrellaOrganizations") + " " + t(getTitleName(modalOptions.type))
      }
      onCancel={() => setModalOptions({ type: undefined, isOpen: false, id: undefined })}
      onValidate={() => buttonRef.current.click()}
      hideCancelButton={modalOptions.type === "view" ? true : false}
      validateText={modalOptions.type !== "view" ? t("negotiationCard.confirm") : t("common.back")}
    >
      <Form
        id={modalOptions.id}
        initialValues={initialValues}
        isFetching={isFetching}
        errorMessage={errorMessage}
        modalOptions={modalOptions}
        onSubmit={modalOptions.type === "create" ? onSubmitCreate : onSubmitEdit}
        buttonRef={buttonRef}
        administrativeAreaTypes={administrativeAreaTypes}
        administrativeAreas={administrativeAreas}
      />
    </Modal>
  );
};

AggregatorUmbrellaOrganizationsEditModal.defaultProps = {};

AggregatorUmbrellaOrganizationsEditModal.propTypes = {
  initialFormValues: PropTypes.object,
  isFetching: PropTypes.bool,
  errorMessage: PropTypes.string,
  modalOptions: PropTypes.object,
  setModalOptions: PropTypes.func,
  aggregatorUmbrellaOrganizationsDetails: PropTypes.func.isRequired,
  aggregatorUmbrellaOrganizationsDetailsReset: PropTypes.func.isRequired,
  updateAggregatorUmbrellaOrganizationsDetails: PropTypes.func.isRequired,
  addAggregatorUmbrellaOrganizationsDetails: PropTypes.func.isRequired,
  administrativeAreas: PropTypes.array.isRequired,
  administrativeAreaTypes: PropTypes.array.isRequired,
};

export const mapStateToProps = (state) => {
  const initialFormValues = adaptInitialFormValues(
    state.aggregatorUmbrellaOrganizationsDetails.data,
    state.listAdministrativeAreas.data.results,
    state.listAdministrativeAreaTypes.data.results,
  );
  return {
    initialFormValues: initialFormValues,
    isFetching: state.aggregatorUmbrellaOrganizationsDetails.isFetching,
    errorMessage: state.aggregatorUmbrellaOrganizationsDetails.errorMessage,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    aggregatorUmbrellaOrganizationsDetails: (id) => dispatch(aggregatorUmbrellaOrganizationsDetails(id)),
    aggregatorUmbrellaOrganizationsDetailsReset: () => dispatch(aggregatorUmbrellaOrganizationsDetailsReset()),
    addAggregatorUmbrellaOrganizationsDetails: (values) =>
      dispatch(addAggregatorUmbrellaOrganizationsDetails(values, "", true)),
    updateAggregatorUmbrellaOrganizationsDetails: (id, values) =>
      dispatch(updateAggregatorUmbrellaOrganizationsDetails(id, values, "", true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AggregatorUmbrellaOrganizationsEditModal);
