import React from "react";
import Tag from "../components/Tag";
import style from "./style.scss";
import PopupMenu from "../components/PopupMenu";
import Button from "../components/Button";
import { Icon, colors } from "@wfp/ui";
import { iconCheckmarkGlyph, iconCloseGlyph } from "@wfp/icons";
// Calculate minimum rows to show in a table
// if table is empty, five rows are displayed
// to make "No rows found" message visible inside the table
// if there are some rows minimum rows is set to zero
// to hide empty rows
export const calculateMinimumRows = (data) => (data.length ? 0 : 5);

// The component used to render a cell aligned to right
export const alignRightCell = (text) => (
  <div className={style.alignRight}>
    <span>{text}</span>
  </div>
);

// A function that returns the style to align header to right
export const alignRightHeaderStyle = () => ({
  justifyContent: "flex-end",
  textAlign: "right",
});

export const alignRightHeader = (text) => (
  <span style={{ justifyContent: "flex-end", textAlign: "right" }}>{text}</span>
);

// The component used to render a cell in two lines with some styles
export const tagCell = (text, type = "wfp") => (
  <div>
    <Tag type={type}>{text}</Tag>
  </div>
);

// A component used to display a cell with a percentage, a color and a label
export const statusCell = (text, color, percentage) => (
  <div className={style.percentageContainer}>
    <div className={style.percentageWrapper}>
      <div
        className={style.percentageValue}
        style={{ width: `${percentage}%`, backgroundColor: color }}
      />
    </div>
    <span className={style.percentageText} style={{ color: color }}>
      {text}
    </span>
  </div>
);

// A component used to display a cell with a percentage, a color and a label
// eslint-disable-next-line no-unused-vars
export const statusCell2 = (text, color, percentage) => (
  <div className={style.percentageContainer}>
    {/* <div className={style.percentageWrapper}>
      <div
        className={style.percentageValue}
        style={{ width: `${percentage}%`, backgroundColor: color }}
      />
    </div> */}
    <span className={style.percentageText} style={{ color: color }}>
      {text}
    </span>
  </div>
);
// The component used to render a popup menu in a table
export const menu = (items) => <PopupMenu items={items} />;

// A component to render a button inside the list to view the detail of a record
export const generateViewButton = (record, onClick, label, t) => (
  <Button kind="secondary" small onClick={() => onClick(record)}>
    {t ? (label || t("common.view")) : "common.view" }
  </Button>
);

export const generateDeleteButton = (record, onClick, label, t) => (
  <Button kind="danger" small onClick={() => onClick(record)}>
    {t ? label || t("common.delete") : "common.delete"}
  </Button>
);

export const generateRecallButton = (record, onClick, t) => (
  <Button kind="secondary" small onClick={() => onClick(record)}>
    {t ? t("common.recall") : "common.recall"}
  </Button>
);

// True / False icon for Cells within the table.
// Usage: Cell: boolIconCell.
export const boolIconCell = ({ value }) => {
  return (
    <Icon
      data-testid={"boolIconCell"}
      fill={value ? colors["sdg-13"].hex : colors["sdg-08"].hex}
      icon={value ? iconCheckmarkGlyph : iconCloseGlyph}
    />
  );
};

export const generateDateTimeByUserCell = (dateTime, user, t) => {
  let fullName;
  if(user && typeof user === "object"){
    fullName = user.first_name + " " + user.last_name;
  }
  return (
    <div>
      <div className={style.dateValue}>
        {new Date(dateTime).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })}
      </div>
      <div className={style.byUserValue}>{t("configuration.byFullName", {fullName: fullName ? fullName : "System"})}</div>
    </div>
  );
};

export const generateDateTimeCell = (dateTime) => {
  if(!dateTime) return ("---");
  return (
    <div>
      <div className={style.dateValue}>
        {new Date(dateTime).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })}
      </div>
    </div>
  );
};

export const byLastUpdateDateTime = (a, b) => {
  const dateA = new Date(a.last_update_datetime);
  const dateB = new Date(b.last_update_datetime);

  return dateB - dateA;
};
