// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_markdownEditor_DY_8Gl9zyhBoYWZ7Xts1 ul, .style_markdownEditor_DY_8Gl9zyhBoYWZ7Xts1 ol {\n  list-style: revert;\n}", "",{"version":3,"sources":["webpack://./src/components/MarkdownEditor/style.scss"],"names":[],"mappings":"AACC;EACG,kBAAA;AAAJ","sourcesContent":[".markdownEditor {\n ul, ol {\n    list-style: revert;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"markdownEditor": "style_markdownEditor_DY_8Gl9zyhBoYWZ7Xts1"
};
export default ___CSS_LOADER_EXPORT___;
