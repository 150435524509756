// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_instructionText_JBUxzxIUvEImGMp2pZnH {\n  padding: 4px;\n}\n\n.style_deposits_XkTRiN9L9CzOX20yYxjf {\n  max-width: 650px;\n  margin: 0 auto;\n}\n\n.style_labels_Dgeaf2T8mWUqG3p3FnBE {\n  line-height: 140%;\n  font-weight: 600;\n  margin-bottom: 0.6rem;\n}", "",{"version":3,"sources":["webpack://./src/containers/RepresentativeHome/components/SeeProgressModal/style.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAEA;EACI,gBAAA;EACA,cAAA;AACJ;;AAEA;EACI,iBAAA;EACA,gBAAA;EACA,qBAAA;AACJ","sourcesContent":[".instructionText{\n    padding: 4px;\n}\n\n.deposits {\n    max-width: 650px;\n    margin: 0 auto;\n}\n\n.labels {\n    line-height: 140%;\n    font-weight: 600;\n    margin-bottom: 0.6rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"instructionText": "style_instructionText_JBUxzxIUvEImGMp2pZnH",
	"deposits": "style_deposits_XkTRiN9L9CzOX20yYxjf",
	"labels": "style_labels_Dgeaf2T8mWUqG3p3FnBE"
};
export default ___CSS_LOADER_EXPORT___;
