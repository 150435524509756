import React from "react";
import {withTranslation} from "react-i18next";
import Modal from "../../../../components/Modal";
import {InjectedFormProps} from "redux-form";
import {Offer, OfferPaymentTimeOption, OfferStatus} from "../OfferCard/interfaces";
import style from "./style.scss";
import {connect} from "react-redux";
import {RecallDto} from "./Interfaces/RecallDto";
import Loading from "../../../../components/Loading";
import {RejectionDto} from "./Interfaces/RejectionDto";
import {
  addOfferComment,
  API_URL_DEPOSIT_DETAILS,
  createMarketOrder,
  listOfferComments,
  listOfferCommentsReset,
  listOfferNegotiationProposals,
  listOfferNegotiationProposalsReset,
  listRecalls,
  listRejections,
  updateDeposit,
  updateMarketOffer,
} from "../../../../actions";
import {OfferComment, OfferCommentDto} from "./OfferCommentCard";
import OfferCard from "../OfferCard";
import NegotiationCard from "./NegotiatedDetailsCard";
import {NegotiationProposal} from "./NegotiatedDetailsCard/offer-negotiation-proposal";
import {UserGroup} from "../../../../utils/roles";
import OfferCommentForm from "./OfferCommentForm";
import {v4 as uuidv4} from "uuid";
import WaitingForResponse from "./WaitingForResponse";
import {OFFER_TYPES} from "../../../../constants";
import {adaptMarketOffers} from "../../../../utils/adapters";
import client from "../../../../client";
import {Deposit} from "../../../Deposits/DepositCard/deposit-dtos";
import i18n from "../../../../i18n";
import {formatNumber} from "../../../../utils";
import Button from "../../../../components/Button";
import moment from "moment";
import {useForm} from "react-hook-form";
import {AxiosResponse} from "axios";


export interface OfferCommentsThreadProps {
  open: boolean;
  id: number;
  offer: Offer;
  onCancel: () => void;
  t: (s: string, params?: any) => string;
  recallsList?: RecallDto[];
  rejectionsList?: RejectionDto[];
  negotiationProposals: NegotiationProposal[];
  onValidate: () => void;
  isLoading: boolean;
  isAcceptingProposalPending: boolean;
  triggerReset?: string | undefined;
  commentsList: OfferCommentDto[];
  addOfferComment: (text: string, offer: Offer) => Promise<void>;
  onRecallOffer: () => Promise<void>;
  onSendBackExchange: () => Promise<void>;
  refreshContent: (offer: Offer) => void;
  onAcceptOffer: (offer: Offer, proposal: NegotiationProposal) => Promise<void>;
  onCounterOffer: (proposal: NegotiationProposal) => void;
  listMarketOffers: () => void;
  setSeeReasonsOffer: (offer: Offer) => void;
  offerCommentFormMethods: any;
}

function sortByDate(o: (NegotiationProposal | OfferCommentDto)[]) {
  return o.sort((a,b) => new Date(a.backend_creation_time) > new Date(b.backend_creation_time) ? 1 : -1);
}

type state = { timesBackIsPressed: number, shouldRerender: number };

class OfferCommentsModal extends React.Component<
  InjectedFormProps<any, OfferCommentsThreadProps> & OfferCommentsThreadProps, state
> {

  constructor(props: (
    InjectedFormProps<any, OfferCommentsThreadProps, string>
    & OfferCommentsThreadProps)
    | Readonly<InjectedFormProps<any, OfferCommentsThreadProps, string>
    & OfferCommentsThreadProps>
  ){
    super(props)
    this.state = {
      timesBackIsPressed: 0,
      shouldRerender: new Date().valueOf()
    };
  }

  messagesEndRef = React.createRef<HTMLDivElement>();

  scrollToBottom = () => {
    this.messagesEndRef.current?.scrollIntoView(false)
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps: Readonly<InjectedFormProps<any, OfferCommentsThreadProps> & OfferCommentsThreadProps>) {
    this.scrollToBottom();
    if (prevProps.triggerReset !== this.props.triggerReset) {
      this.props.refreshContent(this.props.offer);
    }
  }

  isOwn(proposalOrComment: OfferCommentDto | NegotiationProposal) {
    return proposalOrComment.user.roles.includes(
        UserGroup.TRADER_REPR
    );
  }

  isProposal(proposalOrComment: OfferCommentDto | NegotiationProposal) {
    return (
      (proposalOrComment as unknown as NegotiationProposal).responded !==
      undefined
    );
  }

  render() {
    const {
      offer,
      onValidate,
      onCancel,
      onRecallOffer,
      onSendBackExchange,
      onAcceptOffer,
      t,
      isLoading,
      isAcceptingProposalPending,
      refreshContent,
      addOfferComment,
      onCounterOffer,
      offerCommentFormMethods,
    } = this.props;

    const acceptAndRefresh = async (offer: Offer, proposal: NegotiationProposal) => {
      await onAcceptOffer(offer, proposal);
    };

    const showActions = (proposal: NegotiationProposal) => {
      const lastProposal = this.props.negotiationProposals[this.props.negotiationProposals.length-1];
      return (
          proposal.uuid === lastProposal.uuid
          && !this.isOwn(lastProposal)
          && offer
          && (offer.status === OfferStatus.Negotiating || offer.status === OfferStatus.ExchangeDetailsUpdated)
      )
    }

    const isWaitingForAction = () => {
        if (this.props.offer?.status !== OfferStatus.Negotiating) {
          return false;
        }

       if (this.props.negotiationProposals.length === 0) {
         return false;
       }

      const lastProposal = this.props.negotiationProposals[this.props.negotiationProposals.length-1];
      return this.isOwn(lastProposal);
    }

    const items = [
      ...(this.props.commentsList || []),
      ...(this.props.negotiationProposals || []),
    ].sort((a: OfferCommentDto | NegotiationProposal, b: OfferCommentDto | NegotiationProposal) => {
      const aCreationTime = moment(Date.parse(a.backend_creation_time))
      const bCreationTime = moment(Date.parse(b.backend_creation_time))
      if (aCreationTime.isSame(bCreationTime, 'minute') && !!(a as NegotiationProposal).proposal_type && showActions(a as NegotiationProposal)) {
        return 1;
      } else {
        return Date.parse(a.backend_creation_time) - Date.parse(b.backend_creation_time);
      }
    });

    const onAddOfferSubmit = async (offerCommentFormValues: any) => {
      return new Promise ((resolve, reject) => {
        if (offerCommentFormValues) {
          addOfferComment(offerCommentFormValues.comment, offer).then((result: unknown) => {
            offer.latest_comment = (result as AxiosResponse<OfferCommentDto>).data;
            refreshContent(offer)
            resolve(true)
          });
        }else{
          reject(false)
        }
      })
    };

    const offerLabels: { [key: string]: string } = {
      [OfferStatus.Accepted]: t("offerStatuses.accepted"),
      [OfferStatus.ExchangeDone]: t("offerStatuses.exchange_done"),
      [OfferStatus.New]: t("offerStatuses.new"),
      [OfferStatus.Negotiating]: t("offerStatuses.negotiating"),
      [OfferStatus.Recalled]: t("offerStatuses.recalled"),
      [OfferStatus.Rejected]: t("offerStatuses.rejected"),
      [OfferStatus.OfferDone]: t("offerStatuses.offer_done"),
      [OfferStatus.ExchangeDetailsUpdated]: t("offerStatuses.exchange_details_updated"),
      [OfferStatus.ExchangeDetailsDisputed]: t("offerStatuses.exchange_details_disputed"),
      [OfferStatus.ExchangePaymentPending]: t("offerStatuses.exchange_payment_pending"),
    };

    const renderModalFooter = (props: any) => {
      return (
        <div className={style.modalFooter}>
          <Button kind={"secondary"} onClick={() => {
            if(offerCommentFormMethods.getValues("comment") && this.state.timesBackIsPressed < 1){
              this.setState({...this.state, timesBackIsPressed: this.state.timesBackIsPressed + 1})
              offerCommentFormMethods.setError("comment", {message: "offerCommentForm.backCheckMessage"})
            }else{
              this.setState({ shouldRerender: new Date().valueOf(), timesBackIsPressed: 0})
              offerCommentFormMethods.resetField("comment")
              offerCommentFormMethods.setValue("comment", undefined)
              props.onRequestClose()
            }
          }}>
            {props.primaryButtonText}
          </Button>
        </div>
       )
     }

    return (
      <>
        <Modal
          className={`offer-comments-modal ${offer?.offer_type === OFFER_TYPES.DIRECT_OFFER ? style.directOffer : ''}`}
          hideCancelButton={true}
          isOpen={!!offer}
          validateText={t("commentsModal.back")}
          onValidate={onValidate}
          onRequestClose={onCancel}
          footer={renderModalFooter}
        >
          <div className={style.offerCommentsModalContainer}>
            <div className={style.header}>
              <OfferCard
                  onSeeProgressClick={() => {}}
                  onClickAggregatorDetails={() => {}}
                  onShowRecall={() => {}}
                  offer={offer}
                  onClickMakeOffer={() => {}}
                  hideActions={true}
                  shortVersion={true}
              />
            </div>
            <div className={style.content}>
              <div className={style.commentItemsContainer}>
                {isLoading || isAcceptingProposalPending ? (
                    <Loading isVisible />
                ) : (
                    items ? (
                        <div className={style.offerCommentsContainer}>
                          <NegotiationCard
                              offer={offer}
                              onRecallOffer={async () => {}}
                              onAcceptOffer={async () => {}}
                              onCounterOffer={async () => {}}
                              proposal={{
                                user: {
                                  id: offer?.trader_representative?.id,
                                  first_name: offer?.trader_representative?.person.first_name,
                                  last_name: offer?.trader_representative?.person.last_name,
                                  roles: ['Trader Representative'],
                                },
                                uuid: 'hardcoded_original_offer',
                                mobile_creation_time: offer?.publishing_date,
                                mobile_last_change_time: offer?.publishing_date,
                                minimum_quantity: Number(offer?.minimum_quantity),
                                maximum_quantity: Number(offer?.maximum_quantity),
                                price: Number(offer?.price?.split(" ")[1]),
                                planned_exchange_time: offer?.planned_exchange_time,
                                delivery_location: offer?.delivery_location,
                                text: offer?.comment || "",
                                responded: true,
                                trader_market_offer: offer?.id,
                                backend_creation_time: offer?.publishing_date,
                                frontend_creation_time: offer?.publishing_date,
                                proposer: 'trader',
                                custom_delivery_location: offer?.custom_delivery_location_details?.id,
                                custom_delivery_location_type: offer?.custom_delivery_location_type,
                              }}
                              showActions={false}
                          />
                          {items.map((o, idx) =>
                              this.isProposal(o) ? (
                                  <NegotiationCard
                                      key={`comment-like-item-${idx}`}
                                      offer={offer}
                                      onRecallOffer={onRecallOffer}
                                      onAcceptOffer={acceptAndRefresh}
                                      onCounterOffer={
                                        offer?.status !== OfferStatus.ExchangeDetailsUpdated
                                            ? () => onCounterOffer(o as NegotiationProposal)
                                            : undefined
                                      }
                                      onSendBackExchange={
                                        offer?.status == OfferStatus.ExchangeDetailsUpdated
                                            ? onSendBackExchange
                                            : undefined
                                      }
                                      proposal={o as NegotiationProposal}
                                      showActions={showActions(o as NegotiationProposal)}
                                  />
                              ) : (
                                  <OfferComment
                                      key={o.uuid}
                                      statusLabel={offerLabels[(o as OfferCommentDto)?.trader_market_offer_status]}
                                      comment={o as OfferCommentDto}
                                  />
                              )
                          )}{" "}
                        </div>
                    ) : (
                        t("commentsModal.noComments")
                    )
                )}
              </div>
              {isWaitingForAction() && !isLoading && <WaitingForResponse/>}
              <div ref={this.messagesEndRef} />
            </div>
            <div className={style.footer}>
              <OfferCommentForm
                  errorMessage=""
                  onValidate={onAddOfferSubmit}
                  offer={offer}
                  methods={offerCommentFormMethods}
                  shouldRerender={this.state.shouldRerender}
              />
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    recallsList: state.listRecalls && state.listRecalls.data.results,
    rejectionsList: state.listRejections && state.listRejections.data.results,
    isLoading:
      state.listRecalls &&
      state.listRejections &&
      state.listOfferComments &&
      state.listOfferNegotiationProposals &&
      (state.listRecalls.isFetching ||
        state.listRejections.isFetching ||
        state.listOfferComments.isFetching ||
        state.listOfferNegotiationProposals.isFetching),
    isAcceptingProposalPending:
        state.listRecalls &&
        state.listRejections &&
        state.listOfferComments &&
        state.listOfferNegotiationProposals &&
        (state.listRecalls.isFetching ||
            state.listRejections.isFetching ||
            state.listOfferComments.isFetching ||
            state.listOfferComments.isResetting ||
            state.listOfferNegotiationProposals.isFetching ||
            state.listOfferNegotiationProposals.isResetting),
    commentsList:
      state.listOfferComments && state.listOfferComments.data.results,
    negotiationProposals:
      state.listOfferNegotiationProposals &&
      sortByDate(state.listOfferNegotiationProposals.data.results),
  };
};

const mapDispatchToProps = (dispatch: any, props: OfferCommentsThreadProps) => {
  return {
    addOfferComment: (text: string, offer: Offer) =>
      dispatch(
        addOfferComment({
          trader_market_offer: offer.id,
          text,
          uuid: uuidv4(),
          trader_market_offer_status: offer.status,
        })
      ),
    onAcceptOffer: async (offer: Offer, negotiationProposal: NegotiationProposal) => {
      dispatch(listOfferCommentsReset());
      dispatch(listOfferNegotiationProposalsReset());

      const newOffer = {
        ...offer,
        price: negotiationProposal.price,
        status: offer.status === OfferStatus.ExchangeDetailsUpdated
            ? (
                offer.payment_time_option === OfferPaymentTimeOption.ReceiveUponExchange
                    ? OfferStatus.ExchangeDone
                    : OfferStatus.ExchangePaymentPending
            )
            : (
                offer.offer_type === OFFER_TYPES.DIRECT_OFFER
                    ? OfferStatus.OfferDone
                    : OfferStatus.Accepted
            ),
        planned_exchange_time: negotiationProposal.planned_exchange_time,
        exchange_confirmation_time: negotiationProposal.proposal_type === "EXCHANGE_NEGOTIATION" ? new Date().toISOString() : undefined,
        minimum_quantity: negotiationProposal.minimum_quantity,
        maximum_quantity: negotiationProposal.maximum_quantity,
        delivery_location: negotiationProposal.delivery_location,
        custom_delivery_location: negotiationProposal.custom_delivery_location,
      }

      if (offer.status === OfferStatus.ExchangeDetailsUpdated || offer.status === OfferStatus.Negotiating) {
        dispatch(
            addOfferComment({
              trader_market_offer: newOffer.id,
              text: offer.status === OfferStatus.ExchangeDetailsUpdated ? i18n.t("autoComment.confirmExchangeUpdate", {
                trader: newOffer.trader.name,
                unit: newOffer.unit_of_measure.code,
                previousQuantity: formatNumber(offer.minimum_quantity),
                newQuantity: formatNumber(newOffer.minimum_quantity),
              }) : i18n.t("autoComment.confirmInitialNegotiation", {
                organization: offer.trader.name,
                details: i18n.t("autoComment.proposalDetailsPresentation", {
                  quantity:
                      !newOffer.maximum_quantity || newOffer.maximum_quantity === newOffer.minimum_quantity
                          ? formatNumber(newOffer.minimum_quantity)
                          : `${formatNumber(newOffer.minimum_quantity)} - ${formatNumber(newOffer.maximum_quantity)}`,
                  unit: newOffer.unit_of_measure.code,
                  commodity: newOffer.buy_commodity.name.toLowerCase(),
                  symbol: newOffer.currency.symbol,
                  price: formatNumber(newOffer.price),
                  grade: newOffer?.quality,
                }),
              }),
              uuid: uuidv4(),
              trader_market_offer_status: offer.status === OfferStatus.Negotiating ? (offer.offer_type === OFFER_TYPES.MARKET_OFFER ? OfferStatus.Accepted : OfferStatus.OfferDone) : (offer.payment_time_option === OfferPaymentTimeOption.ReceiveUponExchange
                  ? OfferStatus.ExchangeDone
                  : OfferStatus.ExchangePaymentPending),
            })
        ).then(() => {
          dispatch(
              listOfferComments({
                trader_market_offer_id: offer.id,
              })
          );
        })
      }

      if (
          offer.status !== OfferStatus.ExchangeDetailsUpdated
          && offer.offer_type === OFFER_TYPES.DIRECT_OFFER
      ) {
        const currentDateTime = new Date();

        const response = await client.get(`${API_URL_DEPOSIT_DETAILS}${offer.deposit?.uuid}/`);
        const fullDepositData: Deposit = response.data;

        // split deposit if direct offer & accepted quantity is lower then deposit quantity
        if (Number(negotiationProposal.minimum_quantity) < Number(fullDepositData?.quantity)) {
          // calculate quantity of deposit which left
          const restDepositQuantity = Number(fullDepositData?.quantity) - Number(negotiationProposal.minimum_quantity);

          // and create twin deposit with rest amount
          const uuid = uuidv4();
          const newDeposit = {
            commodity: fullDepositData?.commodity.id,
            unit_of_measure: fullDepositData?.unit_of_measure.code,
            quality: fullDepositData?.quality,
            farmer_uuid: fullDepositData?.farmer.person.uuid,
            offer: undefined,
            uuid,
            mobile_creation_time: currentDateTime.toISOString(),
            mobile_last_change_time: currentDateTime.toISOString(),
            original_deposit_uuid: undefined,
            quantity: restDepositQuantity.toFixed(5),
            price: fullDepositData?.price,
            picture_urls: fullDepositData?.picture_urls,
            paid_to_farmer_on_deposit: false,
            aggregator_administrator: fullDepositData.aggregator_administrator
          }
          const depositToUpdate = {
            commodity: fullDepositData?.commodity.id,
            unit_of_measure: fullDepositData?.unit_of_measure.code,
            quality: fullDepositData?.quality,
            farmer_uuid: fullDepositData?.farmer.person.uuid,
            offer: undefined,
            uuid: fullDepositData?.uuid,
            mobile_creation_time: currentDateTime.toISOString(),
            mobile_last_change_time: currentDateTime.toISOString(),
            original_deposit_uuid: uuid,
            quantity: Number(negotiationProposal.minimum_quantity).toFixed(5),
            price: fullDepositData?.price,
            picture_urls: fullDepositData?.picture_urls,
            paid_to_farmer_on_deposit: false,
            aggregator_administrator: fullDepositData.aggregator_administrator
          }

          await dispatch(updateDeposit(newDeposit.uuid, newDeposit))
          await dispatch(updateDeposit(depositToUpdate.uuid, depositToUpdate))
        }

        const order = {
          uuid: uuidv4(),
          trader_market_offer: offer.id,
          deposit_uuid: fullDepositData?.uuid,
          aggregator_administrator: fullDepositData?.aggregator_administrator,
          picked_up_quantity: Number(negotiationProposal.minimum_quantity).toFixed(5),
          mobile_creation_time: currentDateTime.toISOString(),
          mobile_last_change_time: currentDateTime.toISOString(),
        }

        await dispatch(createMarketOrder(order.uuid, order))
      }

      dispatch(
          listRejections({
            trader_market_offer_id: offer.id,
          })
      );
      dispatch(
          listRecalls({
            trader_market_offer_id: offer.id,
          })
      );

      dispatch(updateMarketOffer(negotiationProposal.trader_market_offer, newOffer))
          .then(() => {
            const newOfferAdapted = adaptMarketOffers([newOffer])[0];
            props.setSeeReasonsOffer(newOfferAdapted);
          });

      dispatch(
          listOfferNegotiationProposals({
            trader_market_offer_id: offer.id,
          })
      );
    },
    refreshContent: (offer: Offer) => {
      if (offer) {

        dispatch(listOfferCommentsReset());
        dispatch(listOfferNegotiationProposalsReset());
        dispatch(
            listRejections({
              trader_market_offer_id: offer.id,
            })
        );
        dispatch(
            listRecalls({
              trader_market_offer_id: offer.id,
            })
        );
        dispatch(
            listOfferComments({
              trader_market_offer_id: offer.id,
            })
        );
        dispatch(
            listOfferNegotiationProposals({
              trader_market_offer_id: offer.id,
            })
        );
      }
    },
  };
};
const resolver = (values: { comment: string}) => {
  let errors: {
    comment?: string
  } = {};
  if(values.comment === "" || !values.comment){
    errors.comment = "offerCommentForm.errorMessage";
  }
  return {values, errors};
};


 const withUseFormHook = (Component: typeof OfferCommentsModal) => {
  return (props: any) => {
    const offerCommentFormMethods = useForm({ defaultValues: {comment: undefined}, resolver, reValidateMode: "onSubmit"});
      return <Component {...props} offerCommentFormMethods={offerCommentFormMethods} />
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withUseFormHook(OfferCommentsModal)));
