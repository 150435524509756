import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  listTraderRepresentatives,
  listTraders,
  updateTrader,
  updateTraderRepresentative,
  updateTraderRepresentativeReset,
  updateTraderReset,
  userDetail,
} from "../../actions";
import { Grid, Row, Col } from "../../components/Grid";
import Loading from "../../components/Loading";
import PageStandard from "../../components/PageStandard";
import { isEmpty } from "../../utils";
import {
  formatLanguageLabel,
  getUserLanguage,
  languages,
  onChangeLanguage,
} from "../../utils/i18n";
import Form from "./components/Form";
import style from "./style.scss";

const Profile = ({
  currentUser,
  formData,
  hasUpdated,
  history,
  initialValues,
  isFetching,
  listTraderRepresentatives,
  listTraders,
  trader,
  traderRepresentative,
  updateTrader,
  updateTraderReset,
  updateTraderRepresentative,
  updateTraderRepresentativeReset,
  userDetail,
}) => {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(true);
  const language = (formData && formData.values) && formData.values.language;

  const { representative, organization } = currentUser;
  const firstTimeLogin = (
    (!isEmpty(currentUser) && !currentUser.terms_of_service_accepted_date)
    || (history.location.state && history.location.state.updateDetails)
  );

  const onSubmit = () => {
    const { name, physical_address, ...representativeForm } = (formData || {}).values;
    const traderForm = { name, physical_address };

    // Update the trader details
    updateTrader(trader.id, { ...trader, ...traderForm });

    // Update the trader representative details
    updateTraderRepresentative(traderRepresentative.id, {
      ...traderRepresentative,
      language: representativeForm.language.value,
      organization: traderRepresentative.organization.id,
      person: { ...traderRepresentative.person, ...representativeForm },
    });
  };

  useEffect(() => {
    return () => {
      // Fetch user details when finished updating (in case they've changed their name).
      userDetail();

      // Reset update data.
      updateTraderRepresentativeReset();
      updateTraderReset();
    };
  }, []);

  useEffect(() => {
    if (hasUpdated) {
      history.push("/representative/", { fromProfileUpdate: true });
    }
  }, [hasUpdated]);

  useEffect(() => {
    representative && listTraderRepresentatives(representative);
    organization && listTraders(organization);
  }, [representative, organization]);

  useEffect(async () => {
    if (language) {
      await onChangeLanguage(language);
    }
  }, [language]);

  return (
    <PageStandard title={t("profile.title")}>
      {isFetching ? (
        <Loading isVisible />
      ) : (
        <Grid>
          <Row className={style.formRow}>
            <Col sm={9}>
              <div className={style.header}>
                {firstTimeLogin && t("profile.welcome")}
              </div>

              <Form
                {...{
                  initialValues,
                  isValid,
                  onSubmit,
                  setIsValid,
                  t,
                  trader,
                  traderRepresentative,
                }}
              />
            </Col>
          </Row>
        </Grid>
      )}
    </PageStandard>
  );
};

Profile.propTypes = {
  currentUser: PropTypes.object,
  formData: PropTypes.object,
  hasUpdated: PropTypes.bool,
  history: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  isFetching: PropTypes.bool,
  listTraderRepresentatives: PropTypes.func.isRequired,
  listTraders: PropTypes.func.isRequired,
  trader: PropTypes.object,
  traderRepresentative: PropTypes.object,
  updateTrader: PropTypes.func.isRequired,
  updateTraderReset: PropTypes.func.isRequired,
  updateTraderRepresentative: PropTypes.func.isRequired,
  updateTraderRepresentativeReset: PropTypes.func.isRequired,
  userDetail: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const trader = state.listTraders.data.results.find(
    ({ id }) => id === state.userDetail.data.organization,
  );
  const traderRepresentative = state.listTraderRepresentatives.data.results.find(
    ({ id }) => id === state.userDetail.data.representative,
  );
  const selectedLanguage = languages.find(({ value }) => value === getUserLanguage());
  const initialValues = trader && traderRepresentative ? {
    name: trader.name,
    physical_address: trader.physical_address,
    first_name: traderRepresentative.person.first_name,
    last_name: traderRepresentative.person.last_name,
    contact_phone: traderRepresentative.person.contact_phone,
    language: {
      label: formatLanguageLabel(selectedLanguage),
      value: selectedLanguage.value,
    },
  } : {};
  return {
    currentUser: state.userDetail.data,
    formData: state.form.userDetails,
    hasUpdated: (
      !!state.updateTrader.data.id
      && !!state.updateTraderRepresentative.data.id
    ),
    initialValues,
    isFetching: (
      state.listTraderRepresentatives.isFetching
      || state.listTraders.isFetching
      || state.userDetail.isFetching
    ),
    trader,
    traderRepresentative,
  };
};

const mapDispatchToProps = (dispatch) => ({
  listTraderRepresentatives: (id) => dispatch(listTraderRepresentatives({ id })),
  listTraders: (id) => dispatch(listTraders({ id })),
  updateTrader: (id, data) => dispatch(updateTrader(id, data)),
  updateTraderReset: () => dispatch(updateTraderReset()),
  updateTraderRepresentative: (id, data) => dispatch(updateTraderRepresentative(id, data)),
  updateTraderRepresentativeReset: () => dispatch(updateTraderRepresentativeReset()),
  userDetail: () => dispatch(userDetail("", "", true)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
