import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../../components/Modal";
import PropTypes from "prop-types";
import Form from "../Form";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  addFarmerDeletionReasonsDetails,
  farmerDeletionReasonsDetails,
  farmerDeletionReasonsDetailsReset,
  updateFarmerDeletionReasonsDetails,
} from "../../../../actions";
import { adaptInitialFormValues, getTitleName } from "../../../../utils/wfpManagerConfigurationUtils";

export const FarmerDeletionReasonsEditModal = ({
  initialFormValues,
  isFetching,
  errorMessage,
  modalOptions,
  setModalOptions,
  farmerDeletionReasonsDetails,
  farmerDeletionReasonsDetailsReset,
  updateFarmerDeletionReasonsDetails,
  addFarmerDeletionReasonsDetails,
}) => {

  const { t } = useTranslation();
  const buttonRef = useRef();
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    farmerDeletionReasonsDetails(modalOptions.id);
    return () => {
      farmerDeletionReasonsDetailsReset();
    };
  }, [modalOptions.id]);

  useEffect(() => {
    setInitialValues(adaptInitialFormValues(initialFormValues, modalOptions.id));
  }, [initialFormValues]);

  const onSubmitEdit = (formValues) => {
    updateFarmerDeletionReasonsDetails(modalOptions.id, formValues);
    setModalOptions({ type: undefined, id: undefined, isOpen: false });
  };

  const onSubmitCreate = (formValues) => {
    addFarmerDeletionReasonsDetails(formValues);
    setModalOptions({ type: undefined, id: undefined, isOpen: false });
  };

  return (
    <Modal
      isOpen={modalOptions.isOpen}
      title={
        t("configuration.item.farmerDeletionReasons") + " " + t(getTitleName(modalOptions.type))
      }
      onCancel={() => setModalOptions({ type: undefined, isOpen: false, id: undefined })}
      onValidate={() => buttonRef.current.click()}
    >
      <Form
        id={modalOptions.id}
        initialValues={initialValues}
        isFetching={isFetching}
        errorMessage={errorMessage}
        modalOptions={modalOptions}
        onSubmit={modalOptions.type === "create" ? onSubmitCreate : onSubmitEdit}
        buttonRef={buttonRef}
      />
    </Modal>
  );
};

FarmerDeletionReasonsEditModal.defaultProps = {};

FarmerDeletionReasonsEditModal.propTypes = {
  initialFormValues: PropTypes.object,
  isFetching: PropTypes.bool,
  errorMessage: PropTypes.string,
  modalOptions: PropTypes.object,
  setModalOptions: PropTypes.func,
  farmerDeletionReasonsDetails: PropTypes.func.isRequired,
  farmerDeletionReasonsDetailsReset: PropTypes.func.isRequired,
  updateFarmerDeletionReasonsDetails: PropTypes.func.isRequired,
  addFarmerDeletionReasonsDetails: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    initialFormValues: state.farmerDeletionReasonsDetail.data,
    isFetching: state.farmerDeletionReasonsDetail.isFetching,
    errorMessage: state.farmerDeletionReasonsDetail.errorMessage,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    farmerDeletionReasonsDetails: (id) => dispatch(farmerDeletionReasonsDetails(id)),
    farmerDeletionReasonsDetailsReset: () => dispatch(farmerDeletionReasonsDetailsReset()),
    addFarmerDeletionReasonsDetails: (values) =>
      dispatch(addFarmerDeletionReasonsDetails(values, "", true)),
    updateFarmerDeletionReasonsDetails: (id, values) =>
      dispatch(updateFarmerDeletionReasonsDetails(id, values, "", true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FarmerDeletionReasonsEditModal);
