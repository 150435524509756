import { useState } from "react";
import style from "./style.scss";
import { iconCaretRight, iconCaretLeft, iconEllipsis } from "@wfp/icons";
import Icon from "../../../../components/Icon";
import Button from "../../../../components/Button";
import { useTranslation } from "react-i18next";
import Modal from "../../../../components/Modal";
import noImage from "./no-image.png";
import React from "react";

export const Carousel = ({
  imageUrls,
  sizePx,
  width,
  height,
}: {
  imageUrls: string[];
  sizePx: number;
  width?: number;
  height?: number;
}) => {
  const [current, setCurrent] = useState(0);
  const { t } = useTranslation();

  const [showLargePicture, setShowLargePicture] = useState(false);

  const canShowNext = () => current + 1 < imageUrls.length;
  const canShowPrevious = () => current - 1 >= 0;

  const url = imageUrls[current];
  const imgWidth = width ? width : sizePx;
  const imgHeight = height ? height : sizePx;
  return  (
    <div className={style.carousel}>
      {<img onClick={() => {setShowLargePicture(true)}} width={imgWidth} height={imgHeight} className={style.image} src={url || noImage } />}
      {canShowPrevious() && (
        <div
          className={style.leftArrow}
          onClick={() => setCurrent(current - 1)}
        >
          <Icon fill="white" width="1rem" height="1rem" icon={iconCaretLeft} />
        </div>
      )}
      {canShowNext() && (
        <div
          className={style.rightArrow}
          onClick={() => setCurrent(current + 1)}
        >
          <Icon fill="white" width="1rem" height="1rem" icon={iconCaretRight} />
        </div>
      )}
      <Modal validateText={t("common.back")} hideCancelButton isOpen={showLargePicture} onValidate={() => setShowLargePicture(false)}>
         <img className={style.image} src={url} height="480px" />
      </Modal>
    </div>
  )
};
