import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Controller, useForm, FormProvider } from "react-hook-form";

import Input from "../../../../components/Input";
import { MandatoryLabel } from "../../../../components/MandatoryLabel";
import { getMandatoryFieldErrors } from "../../../../utils";
import Select from "../../../../components/Select";
import Checkbox from "../../../../components/Checkbox";
import { setDefaultValues } from "../../../../utils/wfpManagerConfigurationUtils";
import Loading from "../../../../components/Loading";

const defaultValues = {
  name: "",
  code: "",
  value_format: {value: "", label: ""},
  value_minimum_length: 0,
  value_maximum_length: 0,
  active: false,
};

export const resolver = (values, t) => {
  let errors = {};
  if(values.name === "" || !values.name){
    errors.name = t("traderDetails.form.mandatoryFieldBlankError");
  }
  if(values.code === "" || !values.code){
    errors.code = t("traderDetails.form.mandatoryFieldBlankError");
  }
  if(values.value_format === "" || !values.value_format || (values.value_format && !values.value_format.value)){
    errors.value_format = t("traderDetails.form.mandatoryFieldBlankError");
  }
  if(Number(values.value_maximum_length) < Number(values.value_minimum_length)) {
    errors.value_maximum_length = t("farmerIdentification.form.maximumLengthValidationErrorText");
  }
  if(Number(values.value_minimum_length) > Number(values.value_maximum_length)) {
    errors.value_minimum_length = t("farmerIdentification.form.minimumLengthValidationErrorText");
  }
  if(Number(values.value_maximum_length) === 0 || !values.value_maximum_length){
    errors.value_maximum_length = t("farmerIdentification.form.maximumLengthValueValidation");
  }
  if(Number(values.value_minimum_length) === 0 || !values.value_minimum_length){
    errors.value_minimum_length = t("farmerIdentification.form.minimumLengthValueValidation");
  }
  return { values, errors };
};

const Form = ({
  initialValues,
  t,
  modalOptions,
  buttonRef,
  onSubmit,
  isFetching,
}) => {
  const methods = useForm({ resolver, context: t, mode: "all" });

  const { control, handleSubmit, setValue, getValues, formState: { errors }, reset, trigger, watch } = methods;

  const minValue = watch("value_minimum_length");
  const maxValue = watch("value_maximum_length");

  useEffect(() => {
    trigger();
  }, [minValue, maxValue]);

  useEffect(() => {
    setDefaultValues(modalOptions, initialValues, setValue, reset);
    trigger();
  }, [initialValues]);

  useEffect(() => {
    reset();
  }, [modalOptions.id]);

  const identificationFormatOptions = [
    {
      value: "NUMERIC",
      label: t("farmerIdentification.form.formatOptions.numeric"),
    },
    {
      value: "ALPHANUMERIC",
      label: t("farmerIdentification.form.formatOptions.alphanumeric"),
    },
  ];

  if(isFetching){
    return <Loading isVisible />;
  }

  console.log(errors);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          render={({field, fieldState}) => {
            return (
              <Input
                name="name"
                id="input-name"
                label={
                  <MandatoryLabel label={t("configuration.common.name")}/>
                }
                errorMessage={getMandatoryFieldErrors(errors, t, getValues, "name")}
                isDisabled={modalOptions.type === "view"}
                input={field}
                meta={fieldState}
              />
            );
          }}
          defaultValue={defaultValues.name}
          name="name"
        />
        <Controller
          control={control}
          render={({field, fieldState}) => (
            <Input
              label={
                <MandatoryLabel label={t("farmerIdentification.form.identificationCode")}/>}
              errorMessage={getMandatoryFieldErrors(errors, t, getValues, "code")}
              isDisabled={modalOptions.type === "view"}
              input={field}
              meta={fieldState}
              id={"input-code"}
            />
          )}
          defaultValue={defaultValues.code}
          name="code"
          id="code"
        />
        <Controller
          control={control}
          render={({field, fieldState}) => (
            <Select
              id="select-value-format"
              options={identificationFormatOptions}
              label={
                <MandatoryLabel label={t("farmerIdentification.form.identificationFormat")}/>}
              errorMessage={getMandatoryFieldErrors(errors, t, getValues, "value_format")}
              isDisabled={modalOptions.type === "view"}
              input={field}
              meta={fieldState}
            />
          )}
          isRequired
          name="value_format"
          defaultValue={defaultValues.value_format}
          id="value_format"
        />
        <Controller
          control={control}
          render={({field, fieldState}) => (
            <Input
              type="number"
              label={
                <MandatoryLabel label={t("farmerIdentification.form.minimumLengthValidation")}/>}
              errorMessage={getMandatoryFieldErrors(errors, t, getValues, "value_minimum_length")}
              isDisabled={modalOptions.type === "view"}
              input={field}
              meta={fieldState}
              id={"input-value_minimum_length"}
            />
          )}
          name="value_minimum_length"
          id="value_minimum_length"
          defaultValue={defaultValues.value_minimum_length}
        />
        <Controller
          control={control}
          render={({field, fieldState}) => (
            <Input
              type="number"
              label={
                <MandatoryLabel label={t("farmerIdentification.form.maximumLengthValidation")}/>}
              errorMessage={getMandatoryFieldErrors(errors, t, getValues, "value_maximum_length")}
              isDisabled={modalOptions.type === "view"}
              input={field}
              meta={fieldState}
              id={"input-value_maximum_length"}
            />
          )}
          name="value_maximum_length"
          id="value_maximum_length"
          defaultValue={defaultValues.value_maximum_length}
        />
        <Controller
          control={control}
          render={({ field, fieldState }) => {
            return (
              <Checkbox
                label={t("configuration.common.active")}
                id={"active"}
                name={"active"}
                defaultChecked={defaultValues.active}
                isDisabled={modalOptions.type === "view"}
                input={field}
                meta={fieldState}
              />
            );
          }}
          defaultValue={defaultValues.active}
          name="active"
        />
        <button data-testid="submit-button" style={{display: "none"}} ref={buttonRef}/>
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  modalOptions: PropTypes.object.isRequired,
  fieldErrors: PropTypes.object,
  initialValues: PropTypes.object,
  t: PropTypes.func.isRequired,
  buttonRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.func.isRequired,
};

export default withTranslation()(Form);
