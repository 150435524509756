import React, {useEffect, useMemo, useState} from "react";
import {reduxForm, Field} from "redux-form";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import { Blockquote } from "@wfp/ui";
import { DELIVERY_LOCATIONS, NEGOTIABLE_OPTIONS } from "../../../../constants";
import FormWrapper from "../../../../components/FormWrapper";
import { Grid, Row, Col } from "../../../../components/Grid";
import Input from "../../../../components/Input";
import Loading from "../../../../components/Loading";
import Select from "../../../../components/Select";
import {
  displayErrorMessage,
  formatNumber,
  getErrors,
  getRHFErrors,
  isFutureDate,
} from "../../../../utils";
import { getLocalisedName, translateOptions } from "../../../../utils/i18n";
import {
  hasFilledRequiredFields,
  normalizeNumberDecimalToDec,
} from "../../../../utils/reduxForm";
import DepositCard from "../../../Deposits/DepositCard";
import style from "./style.scss";
import { star } from "../../../../utils";
import DatePicker from "../../../../components/DatePicker";
import { useSelector } from "react-redux";
import moment from "moment";

const requiredFields = [
  "minimum_quantity",
  "price",
  "delivery_location",
  "exchange_date_direct",
  "negotiable",
];

export const getAllErrors = (fieldErrors, formErrors, value) => {
  const errorForm = getRHFErrors(formErrors, value);
  const fieldForm = getErrors(fieldErrors, value);
  return errorForm || fieldForm;
};

// The details of a Market Offer with the form to edit it
export const Form = ({
  isFetching,
  errorMessage,
  fieldErrors,
  formErrors,
  trader,
  deposit,
  depositQuantityExceededError,
  administrativeAreas,
  customExchangeLocations,
  exchangeLocationTypes,
  t,
  form,
  setAsNegotiable,
  // These props are used in the validate function, but not in the component.
  /* eslint-disable no-unused-vars */
  linkedDeposit,
  setIsValid,
  /* eslint-enable no-unused-vars */
}) => {

  if (isFetching) {
    return <Loading isVisible />;
  }

  if (errorMessage) {
    return <>{displayErrorMessage(errorMessage)}</>;
  }

  const selectedUnitCode = deposit.id
    ? deposit.unit_of_measure.code
    : deposit.unit_of_measure_code;

  const { values } = useSelector(state => state.form[form]);
  const [isNegotiableTouched, setNegotiableAsTouched] = useState(undefined);
  const { minimum_quantity, price } = values || {};

  useEffect(() => {
    if (deposit && !isNegotiableTouched && Number(minimum_quantity) !== Number(deposit.quantity) || Number(price) !== Number(deposit.price)) {
      const yes = NEGOTIABLE_OPTIONS.find((option) => option.value === true);
      setAsNegotiable(yes);
    }
  }, [minimum_quantity, price]);

  const deliveryLocationOptions = translateOptions(DELIVERY_LOCATIONS, t).map(location => {
    const deliveryLocation = location.value === "drop_at_trader"
      ? trader.physical_address || ""
      : (deposit && deposit.aggregator ) && deposit.aggregator.physical_address || "";
    return {
      ...location,
      label: deliveryLocation ? `${location.label}: ${deliveryLocation}` : location.label,
      location: deliveryLocation,
    };
  });

  const formattedCustomExchangeLocations = customExchangeLocations.filter(location => {
    return !deliveryLocationOptions.find((option) => {
      const currentOptionAddress = option && option.location;
      if(currentOptionAddress){
        return currentOptionAddress == location.physical_address;
      }
      return true;
    });
  });

  const aggregatedDeliveryLocations = useMemo(() => {
    const customLocations = formattedCustomExchangeLocations.reduce((prev, item) => {

      const traderAssignment = item.assignments.find(assignment => assignment.trader_organization === trader.id);
      if (!traderAssignment) { return prev; }

      const location = administrativeAreas.find(area => area.value === item.location);

      return [...prev, ...traderAssignment.types.map((type) => {
        const exchangeLocationType = exchangeLocationTypes.find(t => t.id === type);
        return ({
          label: `${item.name}${exchangeLocationType ? ", "  + exchangeLocationType.name : ""}${location ? ", "  + location.label : ""}`,
          value: {
            deliveryLocationType: "custom_exchange_location",
            custom_delivery_location: item.id,
            custom_delivery_location_type: type,
          },
        });
      })];
    }, []);

    return [...deliveryLocationOptions, ...customLocations];
  }, [deliveryLocationOptions, formattedCustomExchangeLocations, trader, administrativeAreas, exchangeLocationTypes]);

  return (
    <div style={{marginTop: "0.5rem", marginBottom: "-1.5rem"}}>
      <Grid>
        <Row>
          <Col sm={12}>
            <div className={style.depositContainer}>
              {!!(deposit.direct_offers && deposit.direct_offers.length) && (
                <Blockquote kind="warning" withIcon>
                  {t("directOfferDetails.form.alreadyUnderOffer")}
                </Blockquote>
              )}
              {!!deposit.id && (
                <DepositCard
                  isSummaryCard={true}
                  onMakeOfferClick={null}
                  deposit={deposit}
                />
              )}
            </div>
            <FormWrapper>
              <Grid>
                <span className={style.subHeading}>
                  {t("directOfferDetails.form.offerProposal")}
                </span>
                <Row>
                  <Col sm={12}>
                    <Field
                      component={Input}
                      name="minimum_quantity"
                      id="minimum_quantity"
                      label={star(t("directOfferDetails.form.quantity", {
                        commodity: getLocalisedName(deposit.commodity),
                        units:
                        deposit &&
                        deposit.unit_of_measure &&
                        deposit.unit_of_measure.code,
                      }))}
                      normalize={normalizeNumberDecimalToDec(5)}
                      errorMessage={
                        getAllErrors(fieldErrors, formErrors,  "minimum_quantity") ||
                      depositQuantityExceededError
                      }
                      props={{
                        helperText: t("directOfferDetails.form.quantityHelperText", {
                          quantity: Number(deposit.quantity).toFixed(2),
                        }),
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Field
                      component={Input}
                      name="price"
                      id="price"
                      label={star(t("directOfferDetails.form.price", {
                        currency: trader.offers_currency && trader.offers_currency.code,
                        unit: selectedUnitCode,
                      }))}
                      normalize={normalizeNumberDecimalToDec(2)}
                      errorMessage={getAllErrors(fieldErrors, formErrors,  "price")}
                      props={{
                        helperText: t("directOfferDetails.form.priceHelperText", {
                          price: Number(deposit.price).toFixed(2),
                          currency: trader.offers_currency && trader.offers_currency.code,
                          unit: selectedUnitCode,
                        }),
                      }}
                    />
                  </Col>
                  <Col sm={12}>
                    <Field
                      onChange={(event) => setNegotiableAsTouched(event)}
                      component={Select}
                      name="negotiable"
                      id="negotiable"
                      label={star(t("directOfferDetails.form.isNegotiable"))}
                      options={translateOptions(NEGOTIABLE_OPTIONS, t)}
                      loading={false}
                      errorMessage={getAllErrors(fieldErrors, formErrors,  "negotiable")}
                      props={{
                        placeholder: t("directOfferDetails.form.selectHelperText"),
                      }}
                    />
                  </Col>
                </Row>
              </Grid>
              <Grid>
                <Row>
                  <Col sm={5}>
                    <Field
                      component={DatePicker}
                      withTime
                      name="exchange_date_direct"
                      id="exchange_date_direct"
                      label={star(t("directOfferDetails.form.exchangeDate"))}
                      isOutsideRange={isFutureDate}
                      errorMessage={getAllErrors(fieldErrors, formErrors,  "exchange_date_direct")}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Field
                      component={Select}
                      name="delivery_location"
                      id="delivery_location"
                      label={star(t("directOfferDetails.form.deliveryLocation"))}
                      options={aggregatedDeliveryLocations}
                      loading={false}
                      errorMessage={getAllErrors(fieldErrors, formErrors,  "delivery_location")}
                      props={{
                        placeholder: t("directOfferDetails.form.selectHelperText"),
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Field
                      component={Input}
                      name="comment"
                      id="comment"
                      label={t("marketOfferDetails.form.comment")}
                      errorMessage={getAllErrors(fieldErrors, formErrors,  "comment")}
                      props={{
                        placeholder: t("directOfferDetails.form.commentHelperText"),
                      }}
                    />
                  </Col>
                </Row>
                {trader.is_wfp_vendor && (
                  <Row>
                    <Col sm={12}>
                      <Field
                        component={Input}
                        name="contract_number"
                        id="contract_number"
                        label={t("directOfferDetails.form.contractNumber")}
                        errorMessage={getAllErrors(fieldErrors, formErrors,  "contract_number")}
                      />
                    </Col>
                  </Row>
                )}
              </Grid>
            </FormWrapper>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

const validate = (values, props) => {
  const errors = {};

  if (parseFloat(values.minimum_quantity) > parseFloat(props.linkedDeposit.quantity)) {
    errors.minimum_quantity = `${props.t("directOfferDetails.form.depositQuantityExceeded")}`
    + ` ${formatNumber(props.linkedDeposit.quantity)}`;
    + ` ${props.linkedDeposit.unit_of_measure.code}`;
  }
  else if (!parseFloat(values.minimum_quantity) > 0) {
    errors.minimum_quantity = props.t("directOfferDetails.form.depositNonZero");
  }
  else if (!parseFloat(values.price) > 0) {
    errors.price = props.t("directOfferDetails.form.priceNonZero");
  }
  else if(!values.exchange_date_direct || isFutureDate((moment(values.exchange_date_direct)))) {
    errors.exchange_date_direct = props.t("directOfferDetails.form.invalidExchangeDate");
  }

  hasFilledRequiredFields(requiredFields, values, errors);
  props.setIsValid(_.isEmpty(errors));
  return errors;
};

// propTypes for the Form component
Form.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  fieldErrors: PropTypes.object.isRequired,
  trader: PropTypes.object.isRequired,
  deposit: PropTypes.object,
  depositQuantityExceededError: PropTypes.string,
  t: PropTypes.func.isRequired,
  setIsValid: PropTypes.func.isRequired,
  linkedDeposit: PropTypes.object,
  customExchangeLocations: PropTypes.array,
  form: PropTypes.string,
  setAsNegotiable: PropTypes.func.isRequired,
  formErrors: PropTypes.object,
  administrativeAreas: PropTypes.array.isRequired,
  exchangeLocationTypes: PropTypes.array.isRequired,
};

// defaultProps for the Form component
Form.defaultProps = {
  errorMessageMarketOffer: "",
  linkedDeposit: {},
};

// eslint-disable-next-line max-len
export default reduxForm({
  form: "directOfferDetails",
  enableReinitialize: true,
  validate,
})(withTranslation()(Form));
