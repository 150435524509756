import React from "react";
import { Wrapper, SecondaryNavigation, SecondaryNavigationTitle } from "@wfp/ui";
import PropTypes from "prop-types";

import Breadcrumb from "../Breadcrumb";
import style from "./style.scss";

// A page component with a title and a children as prop
const PageStandard = ({ title, children, additional }) => (
  <>
    <SecondaryNavigation pageWidth="full" additional={additional}>
      <Breadcrumb />
      <SecondaryNavigationTitle>
        {title}
      </SecondaryNavigationTitle>
    </SecondaryNavigation>
    <Wrapper pageWidth="full" spacing="md" background="lighter">
      <div className={style.wrapper}>
        {children}
      </div>
    </Wrapper>
  </>
);

// propTypes for the PageStandard component
PageStandard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  additional: PropTypes.node,
};

// defaultProps for PageStandard component
PageStandard.defaultProps = {
  additional: undefined,
};

export default PageStandard;
