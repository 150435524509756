import {
  MainNavigation,
  MainNavigationItem,
  SubNavigation,
  SubNavigationContent,
  SubNavigationGroup,
  SubNavigationHeader,
  SubNavigationItem,
  SubNavigationList,
  SubNavigationTitle,
} from "@wfp/ui";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { I18nConst } from "../../utils/i18n";

import { languages, onChangeLanguage } from "../../utils/i18n";
import style from "./style.scss";
import Button from "../Button";

const LoggedOutMainNavigation = () => {
  const { t } = useTranslation();
  const mainNavigationProps = {
    logo: <Link to="/representative/">{t("header.wfp")}</Link>,
    pageWidth: "full",
  };

  const selectedLanguage =
    localStorage.getItem(I18nConst.LANGUAGE_LOCAL_STORAGE_KEY) || "en";

  const selectedLangShortForm = useMemo(() => {
    if (!selectedLanguage) return selectedLanguage;
    const parts = selectedLanguage.split("_");
    return parts.length ? parts[0] : selectedLanguage;
  }, [selectedLanguage]);

  const changeLanguage = async (option) => {
    await onChangeLanguage(option);
  };

  const subNavigation = (
    <SubNavigation>
      <SubNavigationHeader>
        <SubNavigationTitle>{t("header.chooseLanguage")}</SubNavigationTitle>
      </SubNavigationHeader>
      <SubNavigationContent>
        <SubNavigationList>
          <SubNavigationGroup>
            {languages.map((language) => (
              <SubNavigationItem key={`navbar-languages-${language.value}`}>
                <Link href="#" onClick={() => changeLanguage(language)}>
                  {language.engLabel ? (
                    <>
                      {language.engLabel}&nbsp;
                      <span className={style.nativeLabel}>
                        ({language.label})
                      </span>
                    </>
                  ) : (
                    language.label
                  )}
                </Link>
              </SubNavigationItem>
            ))}
          </SubNavigationGroup>
        </SubNavigationList>
      </SubNavigationContent>
    </SubNavigation>
  );

  return (
    <MainNavigation {...mainNavigationProps}>
      <MainNavigationItem>
        <Button
          onClick={() =>
            window
              .open(
                "https://play.google.com/store/apps/details?id=org.wfp.farm2go",
                "_blank",
              )
              .focus()
          }
        >
          {t("loggedOutNavigation.openApp")}
        </Button>
      </MainNavigationItem>
      <MainNavigationItem
        key={"header-navigation-dropdown-language-select"}
        className="wfp--main-navigation__user"
        {...{ subNavigation }}
      >
        <span>{selectedLangShortForm.toUpperCase()}</span>
      </MainNavigationItem>
    </MainNavigation>
  );
};

export default LoggedOutMainNavigation;
