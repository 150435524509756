import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Loading from "../../../../components/Loading";
import Module from "../../../../components/Module";
import Table from "../../../../components/Table";
import { displayErrorMessage } from "../../../../utils";
import { generateDateTimeCell } from "../../../../utils/table";
import { withRouter } from "react-router-dom";
import { UserGroup } from "../../../../utils/roles";
import { Checkbox as WFPCheckbox } from "@wfp/ui";

export const generateFullName = (first_name, last_name) => {
  let full_name = "";
  if (first_name) {
    full_name += first_name + " ";
  }
  if (last_name) {
    full_name += last_name;
  }
  return full_name !== "" ? full_name : "---";
};

export const List = ({
  isFetching,
  errorMessage,
  latestUserActivity,
  filterUserRole,
}) => {
  if (isFetching) {
    return <Loading isVisible />;
  }

  if (errorMessage) {
    return <>{displayErrorMessage(errorMessage)}</>;
  }

  const columns = [
    {
      Header: "userActivity.list.column.organization_name",
      accessor: "organization_name",
      width: 400,
    },
    {
      Header: "userActivity.list.column.mcf_partner",
      width: 80,
      Cell: (obj) => {
        const record = obj.row.original;
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <WFPCheckbox
              checked={record.managed_individuals && record.managed_individuals.some(
                (ind) => ind === "mastercard_foundation_participants",
              )}
              disabled={true}
              hideLabel={true}
            />
          </div>
        );
      },
    },
    {
      Header: "userActivity.list.column.name",
      accessor: "",
      width: 200,
      Cell: ({ row: { original } }) =>
        generateFullName(original.first_name, original.last_name),
    },
  ];

  if (filterUserRole === UserGroup.AGGREGATOR_ADMIN) {
    columns.push(
      {
        Header: "userActivity.list.column.last_mobile_sync",
        accessor: "last_mobile_sync",
        width: 400,
        Cell: ({ value }) => generateDateTimeCell(value),
      },
      {
        Header: "userActivity.list.column.last_mobile_sync_event",
        accessor: "last_mobile_sync_event",
        Cell: ({ value }) => {
          if (!value) return "---";
          const replaced = value.replace(/_/g, " ");
          return (
            replaced.toLowerCase().charAt(0).toUpperCase() +
            replaced.slice(1).toLowerCase()
          );
        },
      },
      {
        Header: "userActivity.list.column.mobile_version",
        accessor: "user_mobile_version",
        Cell: ({ value }) => (value ? value : "---"),
      },
    );
  } else {
    columns.push({
      Header: "userActivity.list.column.last_login",
      accessor: "last_login",
      width: 400,
      Cell: ({ value }) => {
        return generateDateTimeCell(value);
      },
    });
  }

  const data = !filterUserRole
    ? latestUserActivity
    : latestUserActivity.filter((item) => item.user_role === filterUserRole);

  return (
    <>
      <Module
        table={
          <Table
            {...{
              columns,
              data: data,
              loading: isFetching,
              page: 1,
              totalItems: latestUserActivity.length,
              showSort: true,
            }}
          />
        }
      />
    </>
  );
};

// propTypes for the List component
List.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  latestUserActivity: PropTypes.array,
  filterUserRole: PropTypes.string,
};

// defaultProps for the List component
List.defaultProps = {
  errorMessage: "",
};

export default withTranslation()(withRouter(List));
