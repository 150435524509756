import i18n from "i18next";
import moment from "moment";
import React from "react";

import { MandatoryLabel } from "../components/MandatoryLabel";
import MessageInformation from "../components/MessageInformation";
import ErrorHelpText from "../components/ErrorHelpText";
import MessageError from "../components/MessageError";
import MessageWarning from "../components/MessageWarning";
import {
  DATE_FORMAT_DEFAULT,
  DATE_FORMAT_DEFAULT_WITH_TIME,
  DATE_FORMAT_INPUT,
  UserProfile,
} from "../constants";
import MessageSuccess from "../components/MessageSuccess";

/**
 * Function to redirect user to a given url.
 *
 * @param location {string} The location to redirect to.
 * @return {void}
 */
export const redirect = (location) => window.location.assign(location);

/**
 * Check if a given value is empty.
 *
 * @param value {
 *     number
 *     | string
 *     | object
 *     | boolean
 *     | undefined
 *   } The value to check.
 * @return {boolean}
 */
export const isEmpty = (value) => {
  if (typeof value === "number") {
    return isNaN(value) || value === 0;
  }
  if (typeof value === "string") {
    return String(value).trim() === "";
  }
  if (typeof value === "object" && value) {
    return Object.keys(value).length === 0;
  }
  return !value;
};

/**
 * A function that return the value passed as parameter or undefined if
 * the value is empty.
 *
 * @param value
 * @param defaultValue
 * @return {undefined|*}
 */
export const valueOrDefault = (value, defaultValue = undefined) =>
  isEmpty(value) ? defaultValue : value;

/**
 * Decide whether or not to display an error message.
 *
 * @param errorMessage
 * @param icon
 * @return {JSX.Element}
 */
export const displayErrorMessage = (errorMessage, icon = false) =>
  errorMessage && <MessageError icon={icon}>{errorMessage}</MessageError>;

/**
 * Decide whether or not to display an success message.
 *
 * @param successMessage
 * @param icon
 * @return {JSX.Element}
 */
export const displaySuccessMessage = (successMessage, icon = false) =>
  successMessage && (
    <MessageSuccess icon={icon}>{successMessage}</MessageSuccess>
  );

/**
 * Decide whether or not to display an error message.
 *
 * @param informationMessage
 * @return {JSX.Element}
 */
export const displayInformationMessage = (informationMessage) =>
  informationMessage && (
    <MessageInformation>{informationMessage}</MessageInformation>
  );

/**
 * Decide whether or not to display an error message.
 *
 * The error message can be a warning or an error, based on the type of
 * error.
 *
 * @param error
 * @return {JSX.Element|undefined}
 */
export const displayDetailedErrorMessage = (error) => {
  if (error && error.type && error.type !== "none") {
    if (error.type === "error") {
      return <MessageError>{error.message}</MessageError>;
    }
    return <MessageWarning>{error.message}</MessageWarning>;
  }
  return undefined;
};

/**
 * A function to get a comma separated strings from an array.
 *
 * @param array
 */
export const arrayToString = (array) =>
  array.reduce(
    (message, element) => (message ? `${message}, ${element}` : element),
    "",
  );

/**
 * @param {*} object // Errors object from react-hook-form useForm.
 * @param {*} key // Input name to get the error from.
 * @returns Error message or empty string.
 */
export const getRHFErrors = (object, key) =>
  object && object[key] && !isEmpty(object[key]) ? object[key] : "";

/**
 * A function to extract errors related to a field from an error
 * object.
 *
 * @param object
 * @param key
 * @return {*|string}
 */
export const getErrors = (object, key) =>
  object && object.data && object.data[key] && !isEmpty(object.data[key])
    ? arrayToString(object.data[key])
    : "";

/**
 * Sets the errors on a specific form field, for client side validation
 * messages.
 *
 * @param object
 * @param key
 * @param message
 */
export const setFieldError = (object, key, message) => {
  if (object && object.data) {
    object.data[key] = message;
  } else {
    object = {
      data: {},
    };
  }
  object.data[key] = message;
};

/**
 * Decide whether or not to display an error message.
 *
 * @param errorMessage
 * @return {JSX.Element}
 */
export const displayErrorHelpText = (errorMessage) =>
  errorMessage && <ErrorHelpText>{errorMessage}</ErrorHelpText>;

/**
 * A function to retrieve the error message from a status code and
 * status text.
 *
 * It will only retrieve the message if body does not contain any data.
 *
 * @param data
 * @return {false|string|string|string|*|string}
 */
export const getStatusErrorMessage = (data) =>
  data &&
  isEmpty(data.data) &&
  data.status &&
  data.statusText &&
  `${data.status} ${data.statusText}`;

export const getValidationErrorMessage = (data) =>
  data &&
  !isEmpty(data) &&
  Object.values(data)
    .reduce((prev, curr) => {
      if (typeof curr === "string") {
        return [...prev, curr];
      } else if (Array.isArray(curr)) {
        return [...prev, ...curr];
      } else {
        return prev;
      }
    }, [])
    .join("\n");

/**
 * This function is used to get an object, containing an error with a
 * type and a message.
 *
 * If the data parameter contains a non_field_error, the type will be
 * "warning" and the message will be the non_field_error content.
 *
 * Otherwise, it will be returned "error", as type and the message will
 * be the default error message, as, usually, if no error is found, no
 * error message is returned, and the type will be "none".
 *
 * @param data
 * @return {{type: string, message}|{type: string, message: string}}
 */
export const getDetailedError = (data) => {
  if (!data) {
    return {
      type: "none",
      message: "",
    };
  }
  if (data.non_field_error) {
    return {
      type: "warning",
      message: arrayToString(data.non_field_error),
    };
  }
  // FIXME: This is unreachable, no?
  if (data.non_field_error) {
    return {
      type: "error",
      message: data.message,
    };
  }
};

/**
 * Converts a date string from one format to another.
 *
 * @param date {string} The date string.
 * @param fromFormat {string} The original format of the date.
 * @param toFormat {string} The desired format of the date.
 * @return {string}
 */
export const dateToString = (date, fromFormat, toFormat) =>
  (date && moment(date, fromFormat).format(toFormat)) || "";

/**
 * Returns a moment object from a given datetime string.
 *
 * @param date {string} The date string.
 * @param format {string} The format of the date string.
 * @return {moment|string}
 */
export const stringToDate = (date, format) =>
  (date && moment(date, format)) || "";

/**
 * A function to get a string representation of a date in the default
 * format.
 *
 * @param date
 * @return {string}
 */
export const formatDateDefault = (date) =>
  dateToString(date, DATE_FORMAT_INPUT, DATE_FORMAT_DEFAULT);

/**
 * A function to get a string representation of a datetime in the default
 * format.
 *
 * @param date
 * @return {string}
 */
export const formatDateTimeDefault = (date) =>
  dateToString(date, DATE_FORMAT_INPUT, DATE_FORMAT_DEFAULT_WITH_TIME);

/**
 * A function to get a string representation of a date in the input
 * format.
 *
 * @param date
 * @return {string}
 */
export const formatDateInput = (date) =>
  dateToString(date, DATE_FORMAT_DEFAULT, DATE_FORMAT_INPUT);

/**
 * Check if a date is in the future.
 *
 * @param date {moment} The date to check.
 * @param currentDate {Date | moment} The date to compare against.
 * @return {boolean}
 */
export const isFutureDate = (date, currentDate = new Date()) =>
  date.startOf("day") < moment(currentDate).startOf("day");

/**
 * A function to append a "/" at the end of an URL if needed.
 *
 * @param url {string} The url in question.
 * @return {string}
 */
export const completeUrl = (url) => (url.slice(-1) === "/" ? url : `${url}/`);

/**
 * A function to convert a number to a string in locale format
 *
 * @param number {number | string} The number to convert.
 * @return {string}
 */
export const formatNumber = (number, useGrouping = true) => {
  return (
    (number &&
      !isNaN(number) &&
      parseFloat(number).toLocaleString("en-EN", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        useGrouping,
      })) ||
    "0"
  );
};

/**
 * TODO
 * @param userProfile
 * @return {*}
 */
export const extractRoleName = (userProfile) => {
  switch (userProfile) {
  case UserProfile.REPRESENTATIVE_TRADER: {
    return i18n.t("role.trader");
  }
  case UserProfile.ADMINISTRATOR: {
    return i18n.t("role.aggregator");
  }
  case UserProfile.WFP_MANAGER: {
    return i18n.t("role.wfpManager");
  }
  default:
    return userProfile;
  }
};

/**
 * TODO
 * @param tree
 * @param value
 * @param key
 * @param reverse
 * @return {null|*}
 */
export const findInTree = (tree, value, key, reverse = false) => {
  const stack = [...tree];
  while (stack.length) {
    const node = stack[reverse ? "pop" : "shift"]();
    if (node[key] === value || node[key] === value[key]) return node;
    node.children && stack.push(...node.children);
  }
  return null;
};

/**
 * TODO
 * @return {{}|*}
 */
export const getCountryObjectFromCache = () => {
  const profile = localStorage.getItem("user_profile");
  const countries = JSON.parse(localStorage.getItem("countries"));
  if (!countries) return {};
  const selectedCountryCode =
    profile === "wfp_manager"
      ? localStorage.getItem("selected_country")
      : localStorage.getItem("country_code");
  return countries.find((item) => item.code === selectedCountryCode) || {};
};

/**
 * A function to get the current country's currency code from cache.
 *
 * @return {string|null}
 */
export const getCurrencySymbolFromCache = () => {
  const country = getCountryObjectFromCache();
  return country && country.currency ? country.currency.symbol : null;
};

export const getCurrencyCodeFromCache = () => {
  const country = getCountryObjectFromCache();
  return country && country.currency ? country.currency.code : null;
};

export const getLastOfferUpdateDatetime = (offer, lastProposal) => {
  if (!offer) {
    return new Date().toISOString();
  }
  if (!lastProposal && !offer.latest_comment) {
    return offer.last_update_datetime;
  }

  const dates = [offer.last_update_datetime];

  if (lastProposal) {
    const proposalCreationDate = lastProposal.mobile_creation_time
      ? lastProposal.mobile_creation_time
      : lastProposal.backend_creation_time;

    dates.push(proposalCreationDate);
  }

  if (offer.latest_comment) {
    const lastCommentCreationDate = offer.latest_comment.mobile_creation_time
      ? offer.latest_comment.mobile_creation_time
      : offer.latest_comment.backend_creation_time;

    dates.push(lastCommentCreationDate);
  }

  const sorted = dates.sort(function (a, b) {
    return a < b ? -1 : a > b ? 1 : 0;
  });

  return sorted.pop();
};

/**
 * Star a label, to mark as required.
 * @param label The label to pass to the MandatoryLabel component.
 * @return {JSX.Element}
 */
export const star = (label) => <MandatoryLabel label={label} />;

/**
 * React Hook Form return Mandatory Field Error when errors are present in a form.
 * @param {*} errors Object of errors from React Hook Form Methods
 * @param {*} t Translation Function
 * @param {*} getValues Function from React Hook Forms
 * @param {*} fieldName Name of the field that is being checked for errors.
 * @returns {JSX.Element}
 */
export const getMandatoryFieldErrors = (errors, t, getValues, fieldName) => {
  const values = getValues();
  const formErrors = Object.hasOwnProperty.call(errors, fieldName)
    ? errors[fieldName]
    : undefined;
  if (formErrors) {
    return formErrors;
  }
  if (!values[fieldName]) {
    return t("traderDetails.form.mandatoryFieldBlankError");
  }
};

export const getMandatoryFormFieldErrors = (
  errors,
  t,
  getValues,
  fieldName,
) => {
  const formErrors = Object.hasOwnProperty.call(errors, fieldName)
    ? errors[fieldName]
    : undefined;
  if (formErrors) {
    if (formErrors.type === "required") {
      return t("traderDetails.form.mandatoryFieldBlankError");
    }
    return formErrors.message;
  }
};

export const getDeletedItemName = (id, dataList, fieldName) => {
  if (!id) return "";

  return dataList.find((item) => item.id === id)[fieldName];
};

/**
 * @param {*} str
 * @returns string with first letter capitalized
 */
export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 *
 * @param {*} params URL params
 * @returns Correctly formatted URL params
 * Ref: https://github.com/axios/axios/issues/604#issuecomment-420135579
 */
export const parseParams = (params) => {
  const keys = Object.keys(params);
  let options = "";

  keys.forEach((key) => {
    if (params[key] === undefined || params[key] === null) return;
    const isParamTypeObject = typeof params[key] === "object";
    const isParamTypeArray = isParamTypeObject && params[key].length >= 0;

    if (!isParamTypeObject) {
      options += `${key}=${params[key]}&`;
    }

    if (isParamTypeObject && isParamTypeArray) {
      params[key].forEach((element) => {
        options += `${key}=${element}&`;
      });
    }
  });

  return options ? options.slice(0, -1) : options;
};
