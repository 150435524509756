import React from "react";
import { Wrapper, SecondaryNavigation, SecondaryNavigationTitle, Tab, Tabs } from "@wfp/ui";
import PropTypes from "prop-types";

import Breadcrumb from "../Breadcrumb";
import style from "./style.scss";

// A page component with a title and a children as prop
const PageStandardWithTabs = ({ title, tabs, additional }) => (
  <div>
    <SecondaryNavigation className={style.secondaryNavigation} pageWidth="full" additional={additional}>
      <Breadcrumb />
      <SecondaryNavigationTitle>
        {title}
      </SecondaryNavigationTitle>
    </SecondaryNavigation>
    <Tabs className={style.tabs}>
      {tabs.map(({id, label, Component, props, hide}) => {
        if (hide) {
          return null;
        }
        return (
          <Tab key={id} label={label}>
            <Wrapper pageWidth="full" spacing="md" background="lighter">
              <Component {...props} />
            </Wrapper>
          </Tab>
        );
      })}
    </Tabs>
  </div>
);

// propTypes for the PageStandardWithTabs component
PageStandardWithTabs.propTypes = {
  title: PropTypes.string.isRequired,
  additional: PropTypes.node,
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
};

// defaultProps for PageStandardWithTabs component
PageStandardWithTabs.defaultProps = {
  additional: undefined,
};

export default PageStandardWithTabs;
