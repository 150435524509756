import React from "react";
import PropTypes from "prop-types";

import Module from "../../../../components/Module";
import Loading from "../../../../components/Loading";
import Table from "../../../../components/Table";
import ShowHideArea from "../../../../components/ShowHideArea";
import { displayErrorMessage } from "../../../../utils";
import {withTranslation} from "react-i18next";

// The list of columns and their properties
export const columns = () => [
  {
    Header: "column.name",
    accessor: "person.full_name",
  },
  {
    Header: "column.produce",
    accessor: "produce",
  },
];

// The list of a farmers of aggregator
export const Farmers = (
  {
    isFetchingAggregator,
    errorMessageAggregator,
    farmers,
    isVisible,
    onPageChangeFarmers,
    farmersPage,
    t,
  },
) => {

  return (
    <ShowHideArea isVisible={isVisible}>
      {isFetchingAggregator && <Loading isVisible />}
      {displayErrorMessage(errorMessageAggregator)}
      <Module
        title={t("aggregatorView.farmers.title")}
        table={
          <Table
            loading={false}
            columns={columns()}
            data={farmers || []}
            onPageChange={onPageChangeFarmers}
            page={farmersPage}
          />
        }
      />
    </ShowHideArea>
  );
};

// propTypes for the Market component
Farmers.propTypes = {
  isFetchingAggregator: PropTypes.bool.isRequired,
  errorMessageAggregator: PropTypes.string.isRequired,
  farmers: PropTypes.array.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onPageChangeFarmers: PropTypes.func.isRequired,
  farmersPage: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

// defaultProps for the Market component
Farmers.defaultProps = {
  errorMessageAggregator: "",
};

export default withTranslation()(Farmers);
