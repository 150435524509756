import { formatNumber } from "../../../../../utils";

const ProgressBar = (props: any) => {
    const { completed, min} = props;
    const height = 10;
    const containerStyles = {
      height: height,
      width: '100%',
      backgroundColor: "#F0F2F4",
      borderRadius: 50,
    }
  
    const fillerStyles = {
      height: '100%',
      width: `${completed}%`,
      backgroundColor: "#007DBC",
      borderRadius: 'inherit',
      textAlign: 'right',
      fontSize: 10,
    }

    const minStyles = {
        height: height,
        width: 2,
        left: `${min}%`,
        backgroundColor: "black",
        textAlign: 'right',
        position: 'absolute',
        zIndex: 1000,
      }

    const labelStyles = {
      padding: 5,
      color: 'white',
      fontWeight: 'bold'
    }

    const max = Math.min(100, completed);
  
    return (
      <div style={containerStyles}>
        <div style={fillerStyles as any}>
          <span style={labelStyles as any}> </span>
        </div>
      </div>
    );
  };
  
  export default ProgressBar;