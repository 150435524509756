import React, { useRef } from "react";
import PropTypes from "prop-types";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { getErrors, getRHFErrors } from "../../../../utils";
import { useTranslation } from "react-i18next";
import { Col, Grid, Row } from "../../../../components/Grid";
import Input from "../../../../components/Input";
import FormWrapper from "../../../../components/FormWrapper";
import Module from "../../../../components/Module";
import { MandatoryLabel } from "../../../../components/MandatoryLabel";
import Button from "../../../../components/Button";

// This component is used to display the list
// of commodities for the WFP Manager
export const ExternalUserDetailsForm = ({
  onValidate,
  initialValues,
}) => {
  const { t } = useTranslation();
  const buttonRef = useRef();

  const methods = useForm({ defaultValues: initialValues });

  const { handleSubmit, control , errors } = methods;

  const formFields = [
    {
      placeholder: t("managerDetails.firstName"),
      id: "person.first_name",
      label: t("managerDetails.firstName"),
    },
    {
      placeholder: t("managerDetails.lastName"),
      id: "person.last_name",
      label: t("managerDetails.lastName"),
    },
    {
      placeholder: t("managerDetails.email"),
      id: "email",
      label: t("managerDetails.email"),
    },
    {
      placeholder: t("ExternalUser.organizationPlaceholder"),
      id: "organization",
      label: t("externalUserDetails.organization"),
    },
  ];

  return (
    <Grid>
      <FormWrapper>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onValidate)}>
            <Row>
              <Col sm={8}>
                <Module
                  title={t("externalUsers.pageTitle")}
                  footer={<Button onClick={() => buttonRef && buttonRef.current && buttonRef.current.click()}>{t("common.save")}</Button>}
                >
                  <Grid>
                    <FormWrapper>
                      {formFields.map(({
                        placeholder,
                        id,
                        label,
                      }) =>
                        (<Row key={`row-${id}`}>
                          <Col sm={12}>
                            <Controller
                              control={control}
                              placeholder={placeholder}
                              render={({field, fieldState}) => (
                                <Input
                                  label={
                                    <MandatoryLabel
                                      label={label}
                                    />
                                  }
                                  input={field}
                                  meta={fieldState}
                                  errorMessage={getRHFErrors(
                                    errors,
                                    id,
                                  )}
                                  id={`${id}-input`}
                                />
                              )}
                              name={id}
                              id={id}
                              errorMessage={getErrors(errors, id)}
                            />
                          </Col>
                        </Row>))
                      }
                    </FormWrapper>
                  </Grid>
                </Module>
              </Col>
            </Row>
            <button type={"submit"} ref={buttonRef} style={{display: "none"}}/>
          </form>
        </FormProvider>
      </FormWrapper>
    </Grid>
  );
};

// propTypes for the Commodities component
ExternalUserDetailsForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
};

// The component uses the redux store
export default ExternalUserDetailsForm;
