import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { iconInfoOutline } from "@wfp/icons";
import Icon from "../Icon";
import style from "./style.scss";

// The details of a Market Offer with the form to edit it
export const HelpMessage = ({ message }) => {
  return (
    <div className={style.helpMessage}>
      <div className={style.helpIcon}>
        <Icon icon={iconInfoOutline} width="45" height="45"/>
      </div>
      <div className="">
        <div className={style.helpBody}>
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{message}</ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

// propTypes for the HelpMessage component§
HelpMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

// defaultProps for the HelpMessage component
HelpMessage.defaultProps = {
  message: "",
};

// eslint-disable-next-line max-len
export default withRouter(HelpMessage);
