import React, { useRef } from "react";
import PropTypes from "prop-types";
import { iconArrowRight, iconWfpLogoEmblem } from "@wfp/icons";

import { Controller, FormProvider } from "react-hook-form";
import Map from "./Map";
import Select from "../../../../components/Select";
import Button from "../../../../components/Button";
import Icon from "../../../../components/Icon";
import style from "./style.scss";
import {withTranslation} from "react-i18next";

// The form to select a country
export const Form = (
  {
    countries,
    onClickValidate,
    t,
    methods,
  },
) => {
  const buttonRef = useRef();
  const {control, handleSubmit, watch} = methods;
  const isAddProceedButtonDisabled = !watch("country") ? true : false;

  return (
    <div className={style.wrapper}>
      <div className={style.content}>
        <Icon
          icon={iconWfpLogoEmblem}
          width="80"
          height="80"
          fill="#ffffff"
        />
        <div className={style.map}>
          <Map />
        </div>
        <span className={style.title}>{t("countrySelection.form.title")}</span>
        <span className={style.info}>{t("countrySelection.form.info")}</span>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onClickValidate)}>
            <div className={style.form}>
              <Controller
                control={control}
                render={({field, fieldState}) => (
                  <Select
                    id={"country-select"}
                    options={countries}
                    loading={false}
                    input={field}
                    errorMessage={fieldState.errorMessage}
                  />
                )}
                name="country"
                id="country"
              />
            </div>
            <Button
              onClick={() => buttonRef.current.click()}
              icon={iconArrowRight}
              disabled={isAddProceedButtonDisabled}
            >
              {t("countrySelection.form.proceed")}
            </Button>
            <button type={"submit"} ref={buttonRef} style={{display: "none"}}/>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

// propTypes for the Form component
Form.propTypes = {
  countries: PropTypes.array.isRequired,
  onClickValidate: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  methods: PropTypes.object.isRequired,
};

export default withTranslation()(Form);
