import React, { useState } from "react";
import PropTypes from "prop-types";
import Filters from "../Filters";
import { AddNewContentButton, formatNewFilters } from "../..";
import InfoHubTable from "../InfoHubTable";

import style from "../../style.scss";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { valueOrDefault } from "../../../../utils";
import { listAllInfoHubSubjects } from "../../../../actions";
import { withRouter } from "react-router-dom";
import {byLastUpdateDateTime} from "../../../../utils/table";

export const renderCountry = (countryID, countries) => {
  const selectedCountry = Array.isArray(countries) && countries.find((country) => country.id === countryID);
  const countryName =  selectedCountry ? selectedCountry.name : "---";

  return (
    <div className={style.countryCell}>
      {countryName}
    </div>
  );
};

export const AllCountriesTable = ({
  t,
  history,
  country,
  topics,
  infoHubSubjects,
  listAllInfoHubSubjects,
  count,
  isFetching,
  setViewModalOptions,
  setConfirmModalOptions,
  types,
  countries,
}) => {
  const defaultFilters = {
    exclude_country: country.id,
    exclude_status: "draft",
  };
  const [filters, setFilters] = useState(defaultFilters);
  const [showFilters, setShowFilters] = useState(false);

  const loadPage = (paginationFilters) => {
    listAllInfoHubSubjects({
      ...paginationFilters,
      ...filters,
    });
  };

  const updateFilters = (newFilters) => {
    const formattedFilters = formatNewFilters(newFilters);
    const aggregatedFilters = {
      ...filters,
      ...formattedFilters,
    };

    setFilters(aggregatedFilters);
    loadPage(aggregatedFilters);
  };

  const clearFilters = () => {
    setFilters(defaultFilters);
    loadPage(defaultFilters);
  };

  const filtersProps = {
    showFilters,
    setShowFilters,
    filters,
    setFilters,
    updateFilters,
    clearFilters,
    types: types,
    topics: topics.map(topic => ({
      label: topic.name,
      value: topic.id,
    })),
    status: [
      {
        label: "Draft",
        value: "draft",
      }, {
        label: "Published",
        value: "published",
      }, {
        label: "Withdrawn",
        value: "withdrawn",
      },
    ],
    listAllInfoHubSubjects,
  };

  const tableProps = () => ({
    data: infoHubSubjects.sort(byLastUpdateDateTime),
    updateFilters,
    isFetching: isFetching,
    count: count,
    actions: {
      view: (id) => setViewModalOptions({ isOpen: true, id: id }),
      copy: (id) => setConfirmModalOptions({ isOpen: true, id: id, type: "copy" }),
    },
    isAllCountries: true,
  });

  const columns = [
    {
      Header: t("infoHub.table.column.country"),
      accessor: "country",
      Cell: ({ value }) => renderCountry(value, countries),
    },
  ];

  return (
    <>
      <div className={!showFilters ? style.filterContainerClosed : style.filterContainerOpen}>
        <Filters {...filtersProps} />
        <AddNewContentButton history={history} t={t}/>
      </div>
      <InfoHubTable columns={columns} {...tableProps()}/>
    </>
  );
};

AllCountriesTable.propTypes = {
  t: PropTypes.func.isRequired,
  country: PropTypes.object.isRequired,
  topics: PropTypes.array.isRequired,
  infoHubSubjects: PropTypes.array,
  listAllInfoHubSubjects: PropTypes.func.isRequired,
  count: PropTypes.number,
  isFetching: PropTypes.bool,
  setViewModalOptions: PropTypes.func.isRequired,
  setConfirmModalOptions: PropTypes.func.isRequired,
  types: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
};

export const mapStateToProps = (state) => ({
  infoHubSubjects: valueOrDefault(state.listAllInfoHubSubjects.data.results, []),
  count: valueOrDefault(state.listAllInfoHubSubjects.data.count, 0),
  isFetching: state.listAllInfoHubSubjects.isFetching,
});

export const mapDispatchToProps = (dispatch) => ({
  listAllInfoHubSubjects: (filters) => dispatch(listAllInfoHubSubjects(filters)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(AllCountriesTable)));
