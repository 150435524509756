import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PageStandard from "../../components/PageStandard";
import { valueOrDefault } from "../../utils";
import List from "./components/List";
import PropTypes from "prop-types";
import {
  administrativeAreaTypesDetailsReset,
  listAdministrativeAreaTypes,
  listAdministrativeAreaTypesReset,
} from "../../actions";
import Button from "../../components/Button";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import { iconAdd } from "@wfp/icons";
import AdministrativeAreaTypesEditModal from "./components/Modal";

export const WfpManagerAdministrativeAreaTypesView = ({
  isFetching,
  errorMessage,
  administrativeAreaTypes,
  page,
  count,
  listAdministrativeAreaTypes,
  listAdministrativeAreaTypesReset,
  administrativeAreaTypesDetailsReset,
}) => {
  const { t } = useTranslation();
  const [modalOptions, setModalOptions] = useState({isOpen: false, id: undefined, type: undefined});

  // On component mount the data are fetched
  useEffect(() => {
    listAdministrativeAreaTypes();
    return () => {
      listAdministrativeAreaTypesReset();
    };
  }, []);

  const onClickAdd = () => {
    administrativeAreaTypesDetailsReset();
    setModalOptions({isOpen: true, id: "add", type: "create"});
  };

  return (
    <PageStandard
      additional={(
        <ButtonsWrapper>
          <Button icon={iconAdd} onClick={onClickAdd}>
            {t("common.add") + " " + t("configuration.item.administrativeAreaTypes")}
          </Button>
        </ButtonsWrapper>
      )}
      title={t("configuration.item.administrativeAreaTypes")}
    >
      <List
        {...{
          isFetching,
          errorMessage,
          administrativeAreaTypes,
          page,
          count,
          setModalOptions,
        }}
      />
      <AdministrativeAreaTypesEditModal
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
      />
    </PageStandard>
  );
};

WfpManagerAdministrativeAreaTypesView.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  administrativeAreaTypes: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  listAdministrativeAreaTypes: PropTypes.func.isRequired,
  listAdministrativeAreaTypesReset: PropTypes.func.isRequired,
  administrativeAreaTypesDetailsReset: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    administrativeAreaTypes: state.listAdministrativeAreaTypes.data.results,
    isFetching: state.listAdministrativeAreaTypes.isFetching,
    errorMessage: state.listAdministrativeAreaTypes.errorMessage,
    page: 1,
    count: valueOrDefault(state.listAdministrativeAreaTypes.data.results.length, 0),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    listAdministrativeAreaTypes: () => dispatch(listAdministrativeAreaTypes()),
    listAdministrativeAreaTypesReset: () => dispatch(listAdministrativeAreaTypesReset()),
    administrativeAreaTypesDetailsReset: () => dispatch(administrativeAreaTypesDetailsReset()),
  };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(WfpManagerAdministrativeAreaTypesView);
