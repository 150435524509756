import React from "react";
import PropTypes from "prop-types";
import { Blockquote } from "@wfp/ui";

import style from "./style.scss";

// A wrapper of the Blockquote component form WFP UI Kit
const MessageWarning = ({ children }) => (
  <div className={style.wrapper}>
    <Blockquote warning>
      {children}
    </Blockquote>
  </div>
);

// propTypes for the MessageWarning component
MessageWarning.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MessageWarning;
