import React from "react";
import PropTypes from "prop-types";
import { iconWfpLogoEmblem } from "@wfp/icons";
import { Loading } from "@wfp/ui";

import Icon from "../Icon";
import style from "./style.scss";

// A message informing user is be redirected to another page
const PageInformation = ({ title, text }) => (
  <div className={style.wrapper}>
    <Icon
      icon={iconWfpLogoEmblem}
      width="150"
      height="150"
      style={{ marginBottom: "3rem" }}
      fill="#ffffff"
    />
    <h4 className={style.title}>{title}</h4>
    <p className={style.text}>{text}</p>
    <Loading withOverlay={false} small />
  </div>
);

// propTypes for the PageInformation component
PageInformation.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

// defaultProps for the PageInformation component
PageInformation.defaultProps = {
  title: "Application is loading",
  text: "Please wait...",
};

export default PageInformation;
