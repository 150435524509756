import {
  RESOURCE_UPDATE_REQUEST_,
  RESOURCE_UPDATE_SUCCESS_,
  RESOURCE_UPDATE_ERROR_,
  RESOURCE_UPDATE_RESET_,
} from "../actions/resourceUpdate";

// A generic reducer used to UPDATE a resource
const resourceUpdate = resource => (
  state = { isLoading: false, data: {}, error: {} },
  action,
) => {
  switch (action.type) {
  case `${RESOURCE_UPDATE_REQUEST_}${resource}`:
    return { ...state, isLoading: true, data: {}, error: {} };
  case `${RESOURCE_UPDATE_SUCCESS_}${resource}`:
    return { ...state, isLoading: false, data: action.data, error: {} };
  case `${RESOURCE_UPDATE_ERROR_}${resource}`:
    return { ...state, isLoading: false, data: {}, error: action.error };
  case `${RESOURCE_UPDATE_RESET_}${resource}`:
    return { ...state, isLoading: false, data: {}, error: {} };
  default:
    return state;
  }
};

export default resourceUpdate;
