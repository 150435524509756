// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_directOfferDetailsModal_axClGhPr42OUgXNWJONg .style_wfp--modal-container_J3dWOLvmKikbpC4BSaoR {\n  padding: 2.5rem 0rem 0rem 0rem !important;\n}", "",{"version":3,"sources":["webpack://./src/containers/DirectOfferDetails/style.scss"],"names":[],"mappings":"AACE;EACE,yCAAA;AAAJ","sourcesContent":[".directOfferDetailsModal {\n  .wfp--modal-container {\n    padding: 2.5rem 0rem 0rem 0rem !important;\n  }\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"directOfferDetailsModal": "style_directOfferDetailsModal_axClGhPr42OUgXNWJONg",
	"wfp--modal-container": "style_wfp--modal-container_J3dWOLvmKikbpC4BSaoR"
};
export default ___CSS_LOADER_EXPORT___;
