import React from "react";
import PropTypes from "prop-types";

import style from "./style.scss";

// An help text used to display an error related to a field
const ErrorHelpText = ({ children }) => (
  <small className={style.errorMessage}>{children}</small>
);

// propTypes for the ErrorHelpText component
ErrorHelpText.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorHelpText;
