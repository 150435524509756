import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { Select, SelectItem } from "@wfp/ui";

import Button from "../../../components/Button";
import { Grid, Row } from "../../../components/Grid";
import { QUALITIES } from "../../../constants";
import { isEmpty } from "../../../utils";
import {
  getSelectProps,
  blankItem,
  selectItem,
} from "../../../utils/filters";
import { getLocalisedName, translateOptions } from "../../../utils/i18n";
import style from "./style.scss";

export interface Commodity {
  picture_urls: string[];
  backend_creation_date: string;
  price: string;
  quality: string;
  commodity: {
    name: string;
  };
  aggregator: {
    id: number;
    name: string;
  };
}

export interface FilterableField {
  accessor: string;
  label: string;
  choices: FilterChoice;
}

export interface FilterChoice {
  value: string;
  label: string;
}

export interface SortableField {
  accessor: string;
  label: string;
  descending: boolean;
  ascendingLabel?: string;
  descendingLabel: string;
}

export interface FilterPanelProps {
  commodities: { id: number; name: string }[];
  aggregators: { id: number; name: string }[];
  unitsOfMeasure: { id: number; name: string }[];
  updateFilters: (filters: {
    commodity_id: number;
    aggregator_id: number;
    quality: string;
    sort: string;
    unit_of_measure_id: string;
  }) => void;
  filters: {
    commodity_id: number;
    aggregator_id: number;
    quality: string;
    sort: string;
    unit_of_measure_id: string;
  };
  setFilters: (filters: {
    commodity_id: number;
    aggregator_id: number;
    quality: string;
    sort: string;
    unit_of_measure_id: string;
  }) => void;
}

const Filters = (props: FilterPanelProps) => {

  const { t } = useTranslation();
  const [refreshKey, setRefreshKey] = useState(new Date().valueOf())
  const getProps = (label: string, key: string) => (
   { key: `${refreshKey}-${key}`, ...getSelectProps(t(label), key, props.filters, props.setFilters)}
  );

  return (
    <Grid className={style.container}>
      <Row className={style.options}>
        <Select {...getProps("filters.commodities", "commodity_id")} value={props.filters?.commodity_id}>
          {blankItem}
          {(props.commodities || []).map((option) => (
            selectItem(getLocalisedName(option), option.id.toString())
          ))}
        </Select>

        <Select {...getProps("filters.qualities", "quality")} value={props.filters?.quality}>
          {blankItem}
          {translateOptions(QUALITIES, t).map((option: any) => (
            selectItem(option.label, option.value)
          ))}
        </Select>

        <Select {...getProps("filters.aggregators", "aggregator_id")} value={props.filters?.aggregator_id}>
          {blankItem}
          {(props.aggregators || []).map((option) => (
            selectItem(option.name, option.id.toString())
          ))}
        </Select>

        <Select {...getProps("filters.units", "unit_of_measure_id")} value={props.filters?.unit_of_measure_id}>
          {blankItem}
          {(props.unitsOfMeasure || []).map((option) => (
            selectItem(option.name, option.id.toString())
          ))}
        </Select>
      </Row>

      <Row className={style.filterActionButtons}>
        <Button
          kind="ghost"
          onClick={() => {
            setRefreshKey(new Date().valueOf())
            props.updateFilters({} as any)}
          }
          disabled={isEmpty(props.filters)}
        >
          {String(t("filters.clearAll"))}
        </Button>
        <Button
          onClick={() => props.updateFilters(props.filters)}
          disabled={isEmpty(props.filters)}
        >
          {String(t("filters.apply"))}
        </Button>
      </Row>
    </Grid>
  );
};

export default Filters;
