import React from "react";
import { useTranslation } from "react-i18next";
import { Column, usePagination, useTable, useSortBy } from "react-table";
import { Pagination, Table as TableWFP } from "@wfp/ui";
import style from "./style.scss";

interface Props {
  loading: boolean;
  data: any[];
  columns: Column<any>[];
  totalItems?: number;
  pageIndex: number;
  pageSize: number;
  onPageChange: (page: { page: number; pageSize: number }) => void;
  pageCount: number;
  hidePagination?: boolean;
  showSort?: boolean;
  loadPage?: (filters: any) => void;
  sortBy?: { id: string; desc: boolean }[];
}

export const NewTableComponent = ({
  loading,
  data,
  columns,
  totalItems,
  pageIndex,
  pageSize,
  onPageChange,
  pageCount,
  hidePagination = false,
  showSort = false,
  loadPage,
  sortBy,
}: Props) => {
  const { t } = useTranslation();
  const memoColumns = React.useMemo<Column[]>(
    () => (columns?.length ? columns : []),
    [columns]
  );

  const memoData = React.useMemo(() => data, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
  } = useTable(
    {
      columns: memoColumns,
      data: memoData,
      manualPagination: true, // Tell the usePagination hook that we'll handle our own data fetching
      pageCount, // This should be calculated from your server data
      initialState: { pageIndex, pageSize }, // Initial state
    },
    usePagination
  );

  const changePage: any = (page: { page: number; pageSize: number }) => {
    onPageChange(page);
  };

  const getClassName = (column: Column, type: string) => {
    // @ts-ignore
    if (column.alignRight || (column.alignCellRight && type === "cell")) {
      return style.alignRight;
      // @ts-ignore
    } else if (
      // @ts-ignore
      column.alignCentre ||
      // @ts-ignore
      (column.alignCellCentre && type === "cell")
    ) {
      return style.alignCentre;
    }
  };

  return (
    <>
      <TableWFP {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  // @ts-ignore
                  className={getClassName(column, "heading")}
                  style={{ width: column.width }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className={getClassName(cell.column, "cell")}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </TableWFP>
      {!hidePagination && (
        <Pagination
          pageSize={pageSize}
          pageSizes={[10, 20, 30]}
          page={pageIndex + 1}
          totalItems={totalItems || data.length}
          onChange={changePage}
        />
      )}
    </>
  );
};

export default NewTableComponent;
