import React from "react";
import PropTypes from "prop-types";
import { Loading as WFPLoading } from "@wfp/ui";
import style from "./style.scss";

// A loading message shown over page content
const Loading = ({ children, isVisible, small }) => (
  <>
    {
      isVisible
        ? <div className={small ? style.wrapperSmall : style.wrapper}>
          <p className={style.text}>{children}</p>
          <WFPLoading fill="red" withOverlay={false} small />
        </div>
        : undefined
    }
  </>
);

// propTypes for the FormGroup component
Loading.propTypes = {
  children: PropTypes.any,
  isVisible: PropTypes.bool.isRequired,
  small: PropTypes.bool,
};

// defaultProps for the FormGroup component
Loading.defaultProps = {
  children: "Wait while loading",
  small: false,
};

export default Loading;
