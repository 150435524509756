import React from "react";
import PropTypes from "prop-types";
import { Button as WFPButton } from "@wfp/ui";

// A wrapper of the Button component form WFP UI Kit
const Button = ({ children, onClick, kind, small, icon, disabled, testID, className, iconReverse }) => (
  <WFPButton
    onClick={onClick}
    kind={kind}
    small={small}
    disabled={disabled}
    icon={icon}
    data-testid={testID}
    className={className}
    iconReverse={iconReverse}
  >
    {children}
  </WFPButton>
);

// propTypes for the Button component
Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  kind: PropTypes.string,
  small: PropTypes.bool,
  icon: PropTypes.any,
  disabled: PropTypes.bool,
  testID: PropTypes.string,
  className: PropTypes.string,
  iconReverse: PropTypes.bool,
};

// defaultProps for the Button component
Button.defaultProps = {
  kind: "primary",
  small: false,
  icon: undefined,
  disabled: false,
  testID: undefined,
};

export default Button;
