import React, { useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Module, TextArea } from "@wfp/ui";
import style from "./style.scss";
import { displayErrorMessage } from "../../../utils";
import Icon from "../../../components/Icon";
import { iconCheckmarkOutline } from "@wfp/icons";
import FormWrapper from "../../../components/FormWrapper";
import { Controller, FormProvider, useForm } from "react-hook-form";
import Button from "../../../components/Button";
import Checkbox from "../../../components/Checkbox";
import axios from "axios";
import FeedbackSent from "./FeedbackSent";

const AccountDeletionFeedback = () => {
  const { t } = useTranslation();
  const btnRef = React.useRef();

  const [serverError, setServerError] = useState();
  const [serverSuccess, setServerSuccess] = useState();
  const methods = useForm();
  const {
    control,
    handleSubmit,
    watch,
  } = methods;

  const onClickValidate = async (formValues) => {
    if (new Set(Object.values(formValues)).size === 1) {
      setServerError(t("accountDeletion.verify.form.required"));
      return;
    }

    const response = await axios.request({
      url: process.env.API_BASE_URL + "api/account_deletion_feedback/",
      method: "post",
      data: formValues,
      validateStatus: false,
    });

    if (response.status !== 201) {
      setServerError(response.data);
    } else {
      setServerError();
      setServerSuccess(t("accountDeletion.verify.form.success"));
    }
  };

  const feedbackArray = [
    {
      id: 1,
      labelText: t("accountDeletion.verify.form.feedbackOption1"),
    },
    {
      id: 2,
      labelText: t("accountDeletion.verify.form.feedbackOption2"),
    },
    {
      id: 3,
      labelText: t("accountDeletion.verify.form.feedbackOption3"),
    },
    {
      id: 4,
      labelText: t("accountDeletion.verify.form.feedbackOption4"),
    },
    {
      id: 5,
      labelText: t("accountDeletion.verify.form.other"),
    },
  ];
  const additionalFeedback = watch("question_5");

  if (serverSuccess) {
    return <FeedbackSent serverSuccess={serverSuccess} />;
  }

  return (
    <>
      {serverError && displayErrorMessage(serverError, true)}

      <div className={style.container}>
        <Module className={style.moduleContainer}>
          <div className={style.container}>
            <div className={style.title}>
              {t("accountDeletion.verify.title")}
            </div>
            <Icon
              icon={iconCheckmarkOutline}
              fill={"#007DBC"}
              width={50}
              height={50}
            />
            <div className={style.body}>
              <div className={style.subtitle}>
                {t("accountDeletion.verify.subtitle")}
              </div>
              <div className={style.description}>
                {t("accountDeletion.verify.description")}
              </div>
              <FormWrapper>
                <FormProvider {...methods}>
                  <form onSubmit={handleSubmit(onClickValidate)}>
                    {feedbackArray.map(({ id, labelText }) => (
                      <div key={"checkbox_" + id} className={style.checkbox}>
                        <div
                          key={"checkBoxLabel_" + id}
                          className={style.checkboxLabel}
                        >
                          <Controller
                            key={"" + id}
                            id={"question_" + id}
                            name={"question_" + id}
                            control={control}
                            render={({ field, fieldState }) => (
                              <Checkbox
                                id={id}
                                input={field}
                                meta={{ error: "" }}
                                errorMessage={
                                  fieldState.error && fieldState.error.message
                                }
                                label={labelText}
                                labelInFront={true}
                              />
                            )}
                          />
                        </div>
                      </div>
                    ))}
                    {additionalFeedback && (
                      <Controller
                        key={"other_text"}
                        id={"other_text"}
                        name={"other_text"}
                        control={control}
                        render={({ field, fieldState }) => (
                          <TextArea
                            errorMessage={
                              fieldState.error && fieldState.error.message
                            }
                            labelText={t(
                              "accountDeletion.verify.form.additionalFeedback",
                            )}
                            placeholder={t(
                              "accountDeletion.verify.form.insertYourComment",
                            )}
                            onChange={field.onChange}
                          />
                        )}
                        rules={{
                          required:
                            additionalFeedback &&
                            t("traderDetails.form.mandatoryFieldBlankError"),
                        }}
                      />
                    )}
                    <div className={style.footer}>
                      <Button
                        kind="primary"
                        onClick={() => btnRef.current.click()}
                      >
                        {t("accountDeletion.verify.form.submitButtonText")}
                      </Button>
                    </div>
                    <button
                      ref={btnRef}
                      type="submit"
                      style={{ display: "none" }}
                    />
                  </form>
                </FormProvider>
              </FormWrapper>
            </div>
          </div>
        </Module>
      </div>
    </>
  );
};

AccountDeletionFeedback.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(AccountDeletionFeedback);
