import React from "react";
import { SelectItem } from "@wfp/ui";

export const getSelectProps = (label, key, filters, setFilters) => ({
  small: true,
  labelText: label,
  onChange: (event) => setFilters({
    ...filters,
    [key]: event.target.value,
  }),
});

export const blankItem = (
  <SelectItem
    text="--"
    value={undefined}
  />
);

export const selectItem = (text, value) => (
  <SelectItem {...{text, value}} />
);
