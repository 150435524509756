import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { iconAdd } from "@wfp/icons";

import {
  deleteExternalUser,
  listExternalUsers,
  listExternalUsersReset,
  externalUsersCurrentPage,
} from "../../actions";
import Button from "../../components/Button";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import ModalConfirm from "../../components/ModalConfirm";
import PageStandard from "../../components/PageStandard";
import { getCountryObjectFromCache, valueOrDefault } from "../../utils";
import List from "./components/List";

// This component is used to display the list
// of aggregators for the WFP ExternalUser
export const ExternalUsers = (
  {
    isFetching,
    errorMessage,
    externalUsers,
    page,
    count,
    listExternalUsers,
    listExternalUsersReset,
    history,
    externalUsersCurrentPage,
    deleteExternalUser,
  },
) => {
  const {t} = useTranslation();
  const [externalUserToDelete, setExternalUserToDelete] = useState({});

  // On component mount the data are fetched
  useEffect(() => {
    listExternalUsers(1);
    return () => {
      listExternalUsersReset();
      externalUsersCurrentPage(0);
    };
  }, []);

  // A function used to manage pagination of aggregators page
  const onPageChange = page => {
    externalUsersCurrentPage(page);
    listExternalUsers(page + 1);
  };

  // On click view aggregator the detail is displayed
  const onClickView = record => {
    history.push(`/wfpManager/externalUserDetails/${record.id}`, { externalUser: record });
  };

  const onClickDelete = record => {
    setExternalUserToDelete(record);
  };

  const onClickConfirmDeleteExternalUser = () => {
    deleteExternalUser(externalUserToDelete.id).then(() => {
      listExternalUsers(1);
    });
    setExternalUserToDelete({});
  };

  const onClickCancelDeleteExternalUser = () => setExternalUserToDelete({});

  // When user clicks add External User, it is redirected
  // to External User creation page
  const onClickAdd = () => {
    history.push("/wfpManager/externalUserDetails/0");
  };

  return (
    <PageStandard
      title={
        `${t("externalUsers.pageTitle")} ${t("common.in")} ${getCountryObjectFromCache().name}`
      }
      additional={(
        <ButtonsWrapper>
          <Button icon={iconAdd} onClick={onClickAdd}>{t("externalUsers.newExternalUser")}</Button>
        </ButtonsWrapper>
      )}
    >
      <List
        isFetching={isFetching}
        errorMessage={errorMessage}
        externalUsers={externalUsers}
        page={page}
        onPageChange={onPageChange}
        count={count}
        onClickView={onClickView}
        onClickDelete={onClickDelete}
      />
      <ModalConfirm
        isOpen={externalUserToDelete.id}
        onValidate={onClickConfirmDeleteExternalUser}
        onCancel={onClickCancelDeleteExternalUser}
        title={t("externalUsers.deleteExternalUserModalTitle")}
        text={t("externalUsers.deleteExternalUserModalText", {
          first_name: externalUserToDelete && externalUserToDelete.person && externalUserToDelete.person.first_name,
          last_name: externalUserToDelete && externalUserToDelete.person && externalUserToDelete.person.last_name,
        })}
        isLoading={false}
      />
    </PageStandard>
  );
};

// propTypes for the Aggregators component
ExternalUsers.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  externalUsers: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  listExternalUsers: PropTypes.func.isRequired,
  listExternalUsersReset: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  externalUsersCurrentPage: PropTypes.func.isRequired,
  deleteExternalUser: PropTypes.func.isRequired,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = state => {
  const selectedCountry = getCountryObjectFromCache();
  return {
    isFetching: state.listExternalUsers.isFetching,
    errorMessage: state.listExternalUsers.errorMessage,
    externalUsers: state.listExternalUsers.data.results.filter(
      externalUser => externalUser && externalUser.country.id === selectedCountry.id,
    ), //adapt if needed
    page: state.externalUsersCurrentPage.number,
    count: valueOrDefault(state.listExternalUsers.data.count, 0),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    listExternalUsers: page => dispatch(listExternalUsers({page: page})),
    listExternalUsersReset: () => dispatch(listExternalUsersReset()),
    externalUsersCurrentPage: page => dispatch(externalUsersCurrentPage(page)),
    deleteExternalUser: (id) => dispatch(deleteExternalUser(id)),
  };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExternalUsers));
