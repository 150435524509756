import React, { Component} from "react";
import { withRouter } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  listCommodities,
  listMineAggregators,
  listDeposits,
  listDepositsReset,
  depositsCurrentPage,
  depositsCurrentSort,
  listUnitsOfMeasure,
  listAggregatorAdministrators,
  listMarketOffers,
} from "../../actions";
import Button from "../../components/Button";
import { Col, Grid, Row } from "../../components/Grid";
import Loading from "../../components/Loading";
import Module from "../../components/Module";
import PageStandard from "../../components/PageStandard";
import ShowHideArea from "../../components/ShowHideArea";
import { displayErrorMessage } from "../../utils";
import { orderByLocalName } from "../../utils/i18n";
import AggregatorDetailsModal from "../AggregatorDetailsModal";
import { HelpContext } from "../HelpMessageRoute";
import DepositsList from "./DepositsList";
import Filters from "./Filters";

export const depositOrderings = {
  commodityName: "commodity",
  depositDate: "backend_creation_time",
  aggregatorName: "aggregator",
};

export const getOrdering = (sort) => {
  const cols = columns();
  const sortColumn = cols[sort.columnIndex];
  let ordering;
  switch (sortColumn.accessor) {
  case "aggregator.name":
    ordering = depositOrderings.aggregatorName;
    break;
  case "commodity.name":
    ordering = depositOrderings.commodityName;
    break;
  default:
    ordering = depositOrderings.depositDate;
    break;
  }

  return sort.descending ? `-${ordering}` : ordering;
};

// The list of columns and their properties
const columns = (onClickViewPictures, onClickMakeOffer) => [
  {
    Header: "column.commodity",
    accessor: "commodity.name",
    canSort: true,
  },
  {
    Header: "column.quantity",
    accessor: "formatted_quantity",
  },
  {
    Header: "column.quality",
    accessor: "quality",
  },
  {
    Header: "column.price",
    accessor: "formatted_price",
  },
  {
    Header: "column.aggregator",
    accessor: "aggregator.name",
    canSort: true,
  },
  {
    Header: "column.aggregatorLocation",
    accessor: "aggregator.location.name",
  },
  {
    Header: "column.depositDate",
    accessor: "deposit_date",
    canSort: true,
  },
  {
    width: 60,
    Header: "",
    // eslint-disable-next-line react/display-name
    Cell: (data) => {
      const { t } = useTranslation();
      return (
        <Button onClick={onClickViewPictures(data)}>
          {t("directOffersHome.viewPictures")}
        </Button>
      );
    },
  },
  {
    width: 60,
    Header: "",
    // eslint-disable-next-line react/display-name
    Cell: (data) => {
      const { t } = useTranslation();
      return (
        <Button onClick={onClickMakeOffer(data)}>
          {t("directOffersHome.makeOffer")}
        </Button>
      );
    },
  },
];

export class Deposits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isViewPicturesModalOpen: false,
      viewPictureUrls: [],
      filters: props.history.location.state,
      aggregator: {},
      pagination: {
        page: 1,
        page_size: 10,
      },
    };
  }

  componentDidMount() {
    const filters = this.props.history.location && this.props.history.location.state || {};
    this.props.listMineAggregators();
    this.props.listCommodities();
    this.props.listUnitsOfMeasure();

    this.props.listDeposits(
      this.state.pagination.page_size || 10,
      this.state.pagination.page || 1,
      getOrdering(this.props.sort),
      filters && filters.commodity_id,
      filters && filters.aggregator_id,
      filters && filters.quality,
      filters && filters.unit_of_measure_id,
    );
  }

  render() {
    const {
      t,
      deposits,
      isFetchingDeposits,
      errorMessageDeposits,
      listDeposits,
      totalItems,
      sort,
      depositsCurrentSort,
      history,
      listAggregatorAdministrators,
      listMarketOffers,
    } = this.props;

    const sortChangeHandler = (sort, pageSize) => {
      depositsCurrentSort(sort);
      listDeposits(pageSize, 1, getOrdering(sort));
    };

    const toggleViewPictures = (data) => () => {
      this.setState({
        viewPictureUrls:
          data && data.row && data.row.original
            ? data.row.original.picture_urls
            : [],
        isViewPicturesModalOpen: !this.state.isViewPicturesModalOpen,
      });
    };

    const makeOffer = (data) => () => {
      this.props.history.push({
        pathname: "/representative/directOfferDetails",
        state: data && data.row && data.row.original,
      });
    };

    const groups = deposits.map((d) => {
      return {
        price: d.average_proposed_price_per_unit,
        quality: `${d.quality}`,
        aggregator_name: d.aggregator_name,
        name: d.commodity_name,
        ...d,
      };
    });

    const onPageChange = (page) => {
      this.setState((prevState) => ({
        ...prevState,
        pagination: {
          page: page.page,
          page_size: page.pageSize,
        },
      }));
      listDeposits(page.pageSize, page.page, getOrdering(sort),
        // eslint-disable-next-line max-len
        this.state.filters && this.state.filters.commodity_id ? this.state.filters.commodity_id : undefined,
        // eslint-disable-next-line max-len
        this.state.filters && this.state.filters.aggregator_id ? this.state.filters.aggregator_id : undefined,
        this.state.filters && this.state.filters.quality ? this.state.filters.quality : undefined,
        // eslint-disable-next-line max-len
        this.state.filters && this.state.filters.unit_of_measure_id ? this.state.filters.unit_of_measure_id : undefined);
    };

    const updateFilters = (filters) => {
      this.setState((prevState) => ({
        ...prevState,
        filters: filters,
        pagination: {
          ...prevState.pagination,
          page: 1,
        },
      }), () => {
        listDeposits(
          this.state.pagination.page_size,
          this.state.pagination.page,
          filters.sort,
          filters.commodity_id,
          filters.aggregator_id,
          filters.quality,
          filters.unit_of_measure_id,
        );
      });
    };

    const makeOfferClickHandler = (deposit) => {
      history.push("/representative/directOfferDetails", deposit);
    };

    const onClickAggregatorDetails = (aggregator_id) => {
      listAggregatorAdministrators(aggregator_id);
      listMarketOffers(1, 1000, {aggregator_id});
      const selectedAggregator = this.props.aggregators.find((a) => a.id === aggregator_id);
      this.setState({
        aggregator: selectedAggregator,
      });
    };

    const actions = (
      <>
        <Button
          kind="secondary"
          small
          onClick={() => {
            onPageChange(this.state.pagination);
          }}
        >
          {t("marketOfferView.refresh")}
        </Button>
      </>
    );

    return (
      <PageStandard title={t("farmersMarket.title")}>
        {isFetchingDeposits && <Loading isVisible />}
        {displayErrorMessage(errorMessageDeposits)}
        <ShowHideArea isVisible={!isFetchingDeposits}>
          <Grid>
            <Row>
              <Col sm={12}>{this.context}</Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Module
                  title={t("farmersMarket.deposits")}
                  actions={actions}
                  table={
                    <>
                      <Filters
                        commodities={this.props.commodities}
                        aggregators={this.props.aggregators}
                        unitsOfMeasure={this.props.unitsOfMeasure}
                        updateFilters={updateFilters}
                        filters={this.state.filters}
                        setFilters={(filters) => this.setState((prevState) => ({
                          ...prevState,
                          filters: filters,
                          pagination: {
                            ...prevState.pagination,
                            page: 1,
                          },
                        }))}
                      />
                      <DepositsList
                        loading={isFetchingDeposits}
                        columns={columns(toggleViewPictures, makeOffer)}
                        data={groups}
                        page={this.state.pagination.page}
                        onPageChange={onPageChange}
                        totalItems={totalItems}
                        sort={sort}
                        onSortChange={sortChangeHandler}
                        onMakeOfferClick={makeOfferClickHandler}
                        onClickAggregatorDetails={onClickAggregatorDetails}
                      />
                    </>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </ShowHideArea>
        <AggregatorDetailsModal
          isOpen={!!this.state.aggregator.id}
          aggregator={this.state.aggregator}
          commodities={this.props.commodities}
          onValidate={() => {
            this.setState({aggregator: {}});
          }}
        />
      </PageStandard>
    );
  }
}

Deposits.contextType = HelpContext;

const mapStateToProps = (state) => {
  return {
    deposits: state.listDeposits.data.results,
    totalItems: state.listDeposits.data.count,
    depositsPage: state.depositsCurrentPage.number,
    sort: state.depositsCurrentSort.sort,
    isFetchingDeposits: state.listDeposits.isFetching,
    errorMessageDeposits: state.listDeposits.errorMessage,
    commodities: orderByLocalName(state.listCommodities.data.results),
    aggregators: state.listMineAggregators.data.results,
    unitsOfMeasure: state.listUnitsOfMeasure.data.results,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listMarketOffers: (page, page_size, filters) =>
      dispatch(
        listMarketOffers({
          page,
          page_size: page_size,
          quality: filters.quality,
          status: filters.status,
          buy_commodity_id: filters.commodity_id,
          tagged_aggregator_id: filters.aggregator_id,
          unit_of_measure: filters.unit_of_measure_id,
          ordering: "-last_updated_datetime",
        }),
      ),
    listDeposits: (
      page_size,
      page,
      ordering,
      commodity_id,
      aggregator,
      quality,
      unit_of_measure_id,
    ) =>
      dispatch(
        listDeposits({
          page_size,
          page,
          ordering,
          commodity_id: commodity_id,
          aggregator_id: aggregator,
          quality,
          unit_of_measure_id: unit_of_measure_id,
          deleted: false,
          is_sold: false,
          exclude_direct_offer_same_trader: true,
        }),
      ),
    listDepositsReset: () => dispatch(listDepositsReset()),
    depositsCurrentPage: (page) => dispatch(depositsCurrentPage(page)),
    depositsCurrentSort: (sort) => dispatch(depositsCurrentSort(sort)),
    listCommodities: () => dispatch(listCommodities({page: 1, page_size: 100})),
    listMineAggregators: () => dispatch(listMineAggregators()),
    listUnitsOfMeasure: () => dispatch(listUnitsOfMeasure()),
    listAggregatorAdministrators: (aggregator_id) => dispatch(
      listAggregatorAdministrators({ aggregator: aggregator_id }),
    ),
  };
};

// propTypes for the Market component
Deposits.propTypes = {
  deposits: PropTypes.array.isRequired,
  listDeposits: PropTypes.func.isRequired,
  isFetchingDeposits: PropTypes.bool.isRequired,
  totalItems: PropTypes.number.isRequired,
  errorMessageDeposits: PropTypes.string,
  sort: PropTypes.object.isRequired,
  depositsCurrentSort: PropTypes.func.isRequired,
  listAggregatorAdministrators: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  listMineAggregators: PropTypes.func,
  listCommodities: PropTypes.func,
  listUnitsOfMeasure: PropTypes.func,
  commodities: PropTypes.array,
  aggregators: PropTypes.array,
  unitsOfMeasure: PropTypes.array,
  listMarketOffers: PropTypes.func,
};

// defaultProps for the Market component
Deposits.defaultProps = {
  errorMessageDeposits: "",
  totalItems: 0,
  sort: {
    columnIndex: 6,
    descending: true,
  },
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withTranslation()(Deposits)));
