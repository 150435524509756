// Utilities for Configuration within WFP Manager section.

// Sets default value of a configuration form given a modal type of edit / view.
export const setDefaultValues = (modalOptions, initialValues, setValue, reset) => {
  if(modalOptions.type && modalOptions.type !== "create" && initialValues){
    Object.keys(initialValues).forEach((key) => {
      setValue(key, initialValues[key], { shouldDirty: true });
    });
  } else {
    reset();
  }
};

// Find selected object from List response.
export const adaptInitialFormValues = (data, id) => {
  if(!data) return;
  const object = Object.prototype.hasOwnProperty.call(data, "results")
    ? data.results.find((item) => item.id === id)
    : { ...data };

  return object;
};

// Return title name for action buttons within configuration table.
export const getTitleName = (type) => {
  switch (type) {
  case "edit":
    return "common.edit";
  case "view":
    return "common.view";
  case "delete":
    return "common.delete";
  default:
    return "";
  }
};
