import React, { forwardRef, useImperativeHandle, useMemo } from "react";
import { Loading } from "@wfp/ui";
import { Row } from "../../../../components/Grid";
import FormWrapper from "../../../../components/FormWrapper";
import { Controller, FormProvider, useForm } from "react-hook-form";
import MultiCheckboxTable from "../../../../components/MultiCheckboxTable";
import { displayErrorMessage } from "../../../../utils";
import { boolIconCell } from "../../../../utils/table";
import style from "./style.scss";
import PropTypes from "prop-types";

// eslint-disable-next-line react/display-name
const ExchangeLocationForm = forwardRef(({
  isFetching,
  errorMessage,
  onClickValidate,
  t,
  initialValues,
  administrativeAreas,
  exchangeLocations,
  exchangeLocationTypes,
}, ref) => {
  const methods = useForm({
    defaultValues: initialValues,
    // resolver: formResolver,
    context: t,
  });
  const { handleSubmit, control } = methods;

  const submitExchangeLocationsForm = () => {
    handleSubmit(onClickValidate)();
  };

  useImperativeHandle(ref, () => ({
    submitExchangeLocationsForm,
  }));

  const displayLocationName = (locationId) => {
    const administrativeArea = administrativeAreas.find(area => area.value === locationId);
    return administrativeArea ? administrativeArea.label : "Unavailable";
  };

  const tableColumns = useMemo(() => [
    {
      Header: t("traderDetails.form.table.outletName"),
      accessor: "name",
      Cell: ({value}) => value,
      width: 300,
      disableSortBy: true,
    },
    {
      Header: t("traderDetails.form.table.location"),
      accessor: "location",
      // width: 400,
      Cell: ({ row: { original } }) => displayLocationName(original.location),
    },
    {
      Header: t("traderDetails.form.table.status"),
      accessor: "active",
      Cell: boolIconCell,
    },
  ], [t]);

  const exchangeLocationTypesField = useMemo(() => [...exchangeLocationTypes], [exchangeLocationTypes]);

  return (
    <div className={style.exchangeLocationFormContainer}>
      {
        isFetching ? (
          <Row center={"xs"}>
            <Loading isVisible />
          </Row>
        ) : (
          <FormWrapper>
            <FormProvider {...methods}>
              <div className={style.exchangeLocationForm}>
                {displayErrorMessage(errorMessage)}
                <Controller
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <MultiCheckboxTable
                        name="available_exchange_locations"
                        id="trader-location-type-assignments"
                        fields={exchangeLocationTypesField}
                        columns={tableColumns}
                        records={exchangeLocations}
                        label={""}
                        // errorMessage={isSubmitted && getMandatoryFormFieldErrors(errors, t, getValues, "assignments")}
                        // isDisabled={modalOptions.type === "view"}
                        input={field}
                        meta={fieldState}
                      />
                    );
                  }}
                  defaultValue={initialValues.assignments}
                  name="available_exchange_locations"
                />
              </div>
            </FormProvider>
          </FormWrapper>
        )
      }
    </div>
  );
});

ExchangeLocationForm.propTypes = {
  isFetching: PropTypes.bool,
  errorMessage: PropTypes.string,
  onClickValidate: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  administrativeAreas: PropTypes.array.isRequired,
  exchangeLocations: PropTypes.array.isRequired,
  exchangeLocationTypes: PropTypes.array.isRequired,
};

export default ExchangeLocationForm;
