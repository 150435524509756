import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { withTranslation } from "react-i18next";
import { Col, Grid, Row } from "../../../../../../components/Grid";
import Input from "../../../../../../components/Input";
import Select from "../../../../../../components/Select";
import Toggle from "../../../../../../components/Toggle";
import { AGGREGATOR_TIERS } from "../../../../../../constants";
import { getCountryObjectFromCache } from "../../../../../../utils";
import { adaptTiers } from "../../../../../../utils/adapters";

const AggregatorConfigurationStep = ({
  methods,
  getErrors,
  showFieldErrors,
  legalStatuses,
  aggregatorUmbrellaOrganizations,
  t,
}) => {
  const {
    control,
    formState: { errors },
    watch,
  } = methods;
  const legallyRecognizedValue = watch("legally_recognized");
  const isWfpVendorValue = watch("is_wfp_vendor");
  const hasMembershipStructureValue = watch("has_membership_structure");
  const membershipFeeNeededValue = watch("membership_fee_needed");

  const getCurrency = () => {
    const country = getCountryObjectFromCache();
    return country.currency && country.currency.code;
  };

  return (
    <>
      <Grid>
        <Row>
          <Col sm={12}>
            <Controller
              control={control}
              render={({ field }) => (
                <Select
                  id="umbrella_organization-select"
                  input={field}
                  errorMessage={
                    showFieldErrors
                      ? t(getErrors(errors, "umbrella_organization"))
                      : ""
                  }
                  label={t(
                    "aggregatorDetails.form.aggregatorUmbrellaOrganization",
                  )}
                  placeholder={t(
                    "aggregatorDetails.form.selectAggregatorUmbrellaOrganization",
                  )}
                  options={aggregatorUmbrellaOrganizations}
                  loading={false}
                />
              )}
              name="umbrella_organization"
              id="umbrella_organization"
              rules={{ required: false }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Controller
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  input={field}
                  meta={fieldState}
                  label={t("aggregatorDetails.form.yearOfEstablishment")}
                  placeholder={t(
                    "aggregatorDetails.form.insertYearOfEstablishment",
                  )}
                  errorMessage={getErrors(errors, "year_of_establishment")}
                  id="year_of_establishment-input"
                />
              )}
              name="year_of_establishment"
              id="year_of_establishment"
            />
          </Col>
          <Col sm={6}>
            <Controller
              control={control}
              render={({ field }) => (
                <Select
                  input={field}
                  label={t("aggregatorDetails.form.tier")}
                  placeholder={t("aggregatorDetails.form.tierPlaceholder")}
                  options={adaptTiers(AGGREGATOR_TIERS)}
                  errorMessage={
                    showFieldErrors ? getErrors(errors, "tier") : ""
                  }
                  id="tier-select"
                />
              )}
              name="tier"
              id="tier"
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <Controller
              control={control}
              render={({ field }) => (
                <Toggle
                  input={field}
                  label={t("aggregatorDetails.form.legallyRecognized")}
                  loading={false}
                  errorMessage={
                    showFieldErrors
                      ? getErrors(errors, "legally_recognized")
                      : ""
                  }
                  id="legally_recognized-toggle"
                />
              )}
              name="legally_recognized"
              id="legally_recognized"
            />
          </Col>
          {legallyRecognizedValue && (
            <Col sm={4}>
              <Controller
                control={control}
                render={({ field }) => (
                  <Select
                    input={field}
                    label={t("aggregatorDetails.form.legalStatus")}
                    placeholder={t(
                      "aggregatorDetails.form.legalStatusPlaceholder",
                    )}
                    options={legalStatuses}
                    loading={false}
                    errorMessage={
                      showFieldErrors
                        ? getErrors(errors, "legal_registration_status")
                        : ""
                    }
                    id="legal_registration_status-select"
                  />
                )}
                name="legal_registration_status"
                id="legal_registration_status"
              />
            </Col>
          )}
        </Row>
      </Grid>
      <Grid>
        <Row>
          <Col sm={4}>
            <Controller
              control={control}
              render={({ field }) => (
                <Toggle
                  input={field}
                  label={t("aggregatorDetails.form.isWfpVendor")}
                  errorMessage={
                    showFieldErrors ? getErrors(errors, "is_wfp_vendor") : ""
                  }
                  id="is_wfp_vendor-toggle"
                />
              )}
              name="is_wfp_vendor"
              id="is_wfp_vendor"
            />
          </Col>
          {isWfpVendorValue && (
            <Col sm={4}>
              <Controller
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    input={field}
                    meta={fieldState}
                    label={t("aggregatorDetails.form.wingsId")}
                    errorMessage={
                      showFieldErrors
                        ? getErrors(errors, "wings_vendor_id")
                        : ""
                    }
                    id="wings_vendor_id-input"
                  />
                )}
                name="wings_vendor_id"
                id="wings_vendor_id"
              />
            </Col>
          )}
        </Row>
      </Grid>
      <Grid>
        <Row>
          <Col sm={4}>
            <Controller
              control={control}
              render={({ field }) => (
                <Toggle
                  input={field}
                  id="has_membership_structure-toggle"
                  label={t("aggregatorDetails.form.hasMembershipStructure")}
                  errorMessage={getErrors(errors, "has_membership_structure")}
                />
              )}
              name="has_membership_structure"
              id="has_membership_structure"
            />
          </Col>
          {hasMembershipStructureValue && (
            <Col sm={2}>
              <Controller
                control={control}
                render={({ field }) => (
                  <Toggle
                    input={field}
                    label={t("aggregatorDetails.form.displayMembershipFee")}
                    errorMessage={getErrors(errors, "membership_fee_needed")}
                    id="membership_fee_needed-toggle"
                  />
                )}
                name="membership_fee_needed"
                id="membership_fee_needed"
              />
            </Col>
          )}
          {hasMembershipStructureValue && membershipFeeNeededValue && (
            <Col sm={6}>
              <Controller
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    input={field}
                    meta={fieldState}
                    label={`${t(
                      "aggregatorDetails.form.membershipFee",
                    )} ${getCurrency()} ?`}
                    errorMessage={getErrors(errors, "membership_fee")}
                    id="membership_fee-input"
                  />
                )}
                name="membership_fee"
                id="membership_fee"
              />
            </Col>
          )}
        </Row>
      </Grid>
    </>
  );
};

AggregatorConfigurationStep.propTypes = {
  methods: PropTypes.object.isRequired,
  getErrors: PropTypes.func.isRequired,
  showFieldErrors: PropTypes.bool.isRequired,
  legalStatuses: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  aggregatorUmbrellaOrganizations: PropTypes.array,
};

export default withTranslation()(AggregatorConfigurationStep);
