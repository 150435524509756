import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../../components/Modal";
import PropTypes from "prop-types";
import Form from "../Form";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  addUnitsOfMeasureDetails,
  unitsOfMeasureDetails,
  unitsOfMeasureDetailsReset,
  updateUnitsOfMeasureDetails,
} from "../../../../actions";
import { adaptInitialFormValues, getTitleName } from "../../../../utils/wfpManagerConfigurationUtils";

const formatFormValues = (formValues) => {
  return {...formValues, type: "LAND"};
};

export const LandUnitsOfMeasureEditModal = ({
  initialFormValues,
  isFetching,
  errorMessage,
  modalOptions,
  setModalOptions,
  unitsOfMeasureDetails,
  unitsOfMeasureDetailsReset,
  updateUnitsOfMeasureDetails,
  addUnitsOfMeasureDetails,
}) => {
  const { t } = useTranslation();
  const buttonRef = useRef();
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    unitsOfMeasureDetails(modalOptions.id);
    return () => {
      unitsOfMeasureDetailsReset();
    };
  }, [modalOptions.id]);

  useEffect(() => {
    setInitialValues(adaptInitialFormValues(initialFormValues, modalOptions.id));
  }, [initialFormValues]);

  const onSubmitEdit = (formValues) => {
    const updatedValues = formatFormValues(formValues);
    updateUnitsOfMeasureDetails(modalOptions.id, updatedValues);
  };

  const onSubmitCreate = (formValues) => {
    const updatedValues = formatFormValues(formValues);
    addUnitsOfMeasureDetails(updatedValues);
  };

  const onSubmit = (formValues) => {
    switch(modalOptions.type){
    case "edit":
      onSubmitEdit(formValues);
      break;
    case "create":
      onSubmitCreate(formValues);
      break;
    default:
      break;
    }
    setModalOptions({ type: undefined, id: undefined, isOpen: false });
  };

  return (
    <Modal
      isOpen={modalOptions.isOpen}
      title={
        t("configuration.item.landUnitsOfMeasure") + " " + t(getTitleName(modalOptions.type))
      }
      onCancel={() => setModalOptions({ type: undefined, isOpen: false, id: undefined })}
      onValidate={() => buttonRef.current.click()}
      hideCancelButton={modalOptions.type === "view" ? true : false}
      validateText={modalOptions.type !== "view" ? t("negotiationCard.confirm") : t("common.back")}
    >
      <Form
        id={modalOptions.id}
        initialValues={initialValues}
        isFetching={isFetching}
        errorMessage={errorMessage}
        modalOptions={modalOptions}
        onSubmit={onSubmit}
        buttonRef={buttonRef}
      />
    </Modal>
  );
};

LandUnitsOfMeasureEditModal.defaultProps = {};

LandUnitsOfMeasureEditModal.propTypes = {
  initialFormValues: PropTypes.object,
  isFetching: PropTypes.bool,
  errorMessage: PropTypes.string,
  modalOptions: PropTypes.object,
  setModalOptions: PropTypes.func,
  unitsOfMeasureDetails: PropTypes.func,
  unitsOfMeasureDetailsReset: PropTypes.func.isRequired,
  updateUnitsOfMeasureDetails: PropTypes.func.isRequired,
  addUnitsOfMeasureDetails: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    initialFormValues: {
      ...state.unitsOfMeasureDetail.data,
      metric_conversion: parseFloat(state.unitsOfMeasureDetail.data.metric_conversion),
    },
    isFetching: state.unitsOfMeasureDetail.isFetching,
    errorMessage: state.unitsOfMeasureDetail.errorMessage,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    unitsOfMeasureDetails: (id) => dispatch(unitsOfMeasureDetails(id)),
    unitsOfMeasureDetailsReset: () => dispatch(unitsOfMeasureDetailsReset()),
    addUnitsOfMeasureDetails: (values) =>
      dispatch(addUnitsOfMeasureDetails(values, "", true)),
    updateUnitsOfMeasureDetails: (id, values) =>
      dispatch(updateUnitsOfMeasureDetails(id, values, "", true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandUnitsOfMeasureEditModal);
