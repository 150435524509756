import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import PageStandardWithTabs from "../../components/PageStandardWithTabs";
import { connect } from "react-redux";
import { getCountryObjectFromCache, valueOrDefault } from "../../utils";
import Button from "../../components/Button";
import { iconAdd } from "@wfp/icons";
import style from "./style.scss";
import {
  listAllInfoHubSubjects,
  listAllInfoHubSubjectsReset,
  listCountries,
  listCountryInfoHubSubjects,
  listCountryInfoHubSubjectsReset,
  listInfoHubTopics,
  listInfoHubTopicsReset,
  updateInfoHubSubjectDetail,
} from "../../actions";
import { withRouter } from "react-router-dom";
import moment from "moment";
import ViewModal from "./Components/ViewModal";
import client from "../../client";
import AllCountriesTable from "./Components/AllCountriesTable";
import SelectedCountryTable from "./Components/SelectedCountryTable";
import ModalConfirm from "../../components/ModalConfirm";

export const onClickAddNewContent = (history) => {
  history.push("/wfpManager/infohub/newContent");
};

export const formatNewFilters = (newFilters) => {
  return Object.keys(newFilters).reduce((acc, key) => {
    // No value, we skip the filter
    if(!newFilters[key])return acc;

    // If it's an array, we map the values
    else if(Array.isArray(newFilters[key])){
      acc[key] = newFilters[key].map((item) => item.value);
    }
    // If it's an object, we check if it has a value property from the Select component
    else if(typeof newFilters[key] === "object" && Object.hasOwnProperty.call(newFilters[key], "value")) {
      acc[key] = newFilters[key].value;
    }
    // If it's an object but doesn't have a value, its a Date
    else if (typeof newFilters[key] === "object") {
      acc[key] = moment(newFilters[key].toString()).format("YYYY-MM-DD");
    }
    // If it's a string, we just add it
    else {
      acc[key] = newFilters[key];
    }
    return acc;
  }, {});
};

export const AddNewContentButton = ({history, t}) => {
  return (
    <div className={style.filterButton}>
      <Button icon={iconAdd} onClick={() => onClickAddNewContent(history)}>
        {t("infoHub.newContent")}
      </Button>
    </div>
  );
};

AddNewContentButton.propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export const InfoHub = ({
  t,
  history,
  country,
  listCountryInfoHubSubjects,
  listAllInfoHubSubjects,
  listAllInfoHubSubjectsReset,
  listCountryInfoHubSubjectsReset,
  listInfoHubTopics,
  listInfoHubTopicsReset,
  listCountries,
  topics,
  countries,
}) => {

  const [viewModalOptions, setViewModalOptions] = useState({ isOpen: false, id: undefined});
  const [confirmModalOptions, setConfirmModalOptions] = useState({ isOpen: false, id: undefined, type: undefined});

  useEffect(() => {
    listInfoHubTopics();
    listAllInfoHubSubjects({ exclude_country: country.id, exclude_status: "draft", ordering: "-last_update_datetime" });
    listCountryInfoHubSubjects({ country: country.id, ordering: "-last_update_datetime"});
    listCountries();
    return () => {
      listInfoHubTopicsReset();
      listAllInfoHubSubjectsReset();
      listCountryInfoHubSubjectsReset();
    };
  }, []);

  const types = [
    {
      label: "Aggregator Information",
      value: "Aggregator Information",
    },
    {
      label: "Farmer Training",
      value: "Farmer Training",
    },
    {
      label: "MCF CP Information",
      value: "MCF CP Information",
    },
    {
      label: "MCF Participants Activities",
      value: "MCF Participants Activities",
    },
  ];

  const updateSubjectStatus = (id, status) => {
    client.patch(`/api/infohub_subjects/${id}/`, { status: status }).then((result) => {
      if(result) listCountryInfoHubSubjects({ country: country.id });
    });
  };

  const resetConfirmModalOptions = () => {
    setConfirmModalOptions({ isOpen: false, id: undefined, type: undefined});
  };

  const modalProps = {
    modalOptions: viewModalOptions,
    setModalOptions: setViewModalOptions,
    topics,
    actions: {
      publish: (id) => updateSubjectStatus(id, "published"),
      withdraw: (id) => updateSubjectStatus(id, "withdrawn"),
    },
  };

  const generateConfirmModalValidation = (type, id) => {
    switch(type){
    case "withdraw":
      updateSubjectStatus(id, "withdrawn");
      resetConfirmModalOptions();
      return;
    case "copy":
      resetConfirmModalOptions();
      history.push(`/wfpManager/infohub/newContent/${id}`, { isCopy: true });
      return;
    default:
      alert("No action defined, please contact support.");
      resetConfirmModalOptions();
      return;
    }
  };

  const generateConfirmModalTitle = (type) => {
    switch(type){
    case "withdraw":
      return t("infoHub.confirmModal.withdrawTitle");
    case "copy":
      return t("infoHub.confirmModal.copyTitle");
    default:
      return "Error";
    }
  };

  const generateConfirmModalText = (type) => {
    switch(type){
    case "withdraw":
      return t("infoHub.confirmModal.withdrawText");
    case "copy":
      return t("infoHub.confirmModal.copyText");
    default:
      return "Unknown Confirmation modal type, please contact support.";
    }
  };

  const confirmModalProps = {
    isOpen: confirmModalOptions.isOpen,
    title:  generateConfirmModalTitle(confirmModalOptions.type),
    text:   generateConfirmModalText(confirmModalOptions.type),
    onValidate: () => generateConfirmModalValidation(confirmModalOptions.type, confirmModalOptions.id),
    onCancel: () => setConfirmModalOptions({ isOpen: false, id: undefined, type: undefined}),
  };

  const tabs = [
    {
      id: "tab1",
      Component: () => (
        <SelectedCountryTable
          country={country}
          topics={topics}
          setViewModalOptions={setViewModalOptions}
          setConfirmModalOptions={setConfirmModalOptions}
          types={types}
        />
      ),
      label: t("infoHub.contentsForCountry", { country: country.name }),
    },
    {
      id: "tab2",
      Component: () => (
        <AllCountriesTable
          country={country}
          topics={topics}
          setViewModalOptions={setViewModalOptions}
          setConfirmModalOptions={setConfirmModalOptions}
          types={types}
          countries={countries}
        />
      ),
      label: t("infoHub.allContents"),
    },
  ];

  return (
    <>
      <PageStandardWithTabs
        title={t("infoHub.title")}
        tabs={tabs}
      />
      {viewModalOptions.id && <ViewModal {...modalProps} />}
      {confirmModalOptions.id && <ModalConfirm {...confirmModalProps} />}
    </>
  );
};

InfoHub.propTypes = {
  // Translation
  t: PropTypes.func.isRequired,
  //history
  history: PropTypes.object.isRequired,
  // Dispatch
  listCountryInfoHubSubjects: PropTypes.func.isRequired,
  listAllInfoHubSubjects: PropTypes.func.isRequired,
  listInfoHubTopics: PropTypes.func.isRequired,
  listInfoHubTopicsReset: PropTypes.func.isRequired,
  listAllInfoHubSubjectsReset: PropTypes.func.isRequired,
  listCountryInfoHubSubjectsReset: PropTypes.func.isRequired,
  listCountries: PropTypes.func.isRequired,
  // State
  country: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  topics: PropTypes.array,
};

export const mapStateToProps = (state) => ({
  country: getCountryObjectFromCache(),
  countries: valueOrDefault(state.listCountries.data.results, []),
  topics: valueOrDefault(state.listInfoHubTopics.data.results, []),
});

export const mapDispatchToProps = (dispatch) => ({
  listCountryInfoHubSubjects: (filters) => dispatch(listCountryInfoHubSubjects({...filters, page_size: 9999, page: 1})),
  listAllInfoHubSubjects: (filters) => dispatch(listAllInfoHubSubjects({...filters, page_size: 9999, page: 1})),
  listAllInfoHubSubjectsReset: () => dispatch(listAllInfoHubSubjectsReset()),
  listCountryInfoHubSubjectsReset: () => dispatch(listCountryInfoHubSubjectsReset()),
  listInfoHubTopics: () => dispatch(listInfoHubTopics()),
  listInfoHubTopicsReset: () => dispatch(listInfoHubTopicsReset()),
  updateInfoHubSubjectDetail: (id, data) => dispatch(updateInfoHubSubjectDetail(id, data)),
  listCountries: () => dispatch(listCountries()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(InfoHub)));
