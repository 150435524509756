// Normalizer to allow user only enter numbers
export const normalizeNumber = value => {
  if (!value) {
    return value;
  }
  return value.replace(/[^\d]/g, "");
};

export const normalizeNumberDecimal = value => {
  if (!value) {
    return value;
  }
  const newValue = value.replace(",", ".").replace("..", ".").replace(/[^\d.]/g, "");
  const values = newValue.split(".").slice(0, 2);
  if (values.length === 2) {
    return values[0] + "." + values[1];
  } else {
    return newValue;
  }
};

export const normalizeNumberDecimalToDec = numDec => value => {
  if (!value) {
    return value;
  }
  const newValue = value.replace(",", ".").replace("..", ".").replace(/[^\d.]/g, "");
  const values = newValue.split(".").slice(0, 2);
  if (values.length === 2) {
    return values[0] + "." + values[1].slice(0, numDec);
  } else {
    return newValue;
  }
};

export const hasFilledRequiredFields = (reqFields, values, errors) => {
  for (const field of reqFields) {
    if (!values[field]) {
      errors[field] = "";
    }
  }
};
