import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { change } from "redux-form";

import {
  listCommodities,
  listExchangeLocations,
  listUnitsOfMeasure,
  addMarketOffer,
  addMarketOfferReset,
  listTraders,
  aggregatorsCurrentPage,
  addOfferComment,
  listDeposits,
  listAggregatorsForOfferReset,
  listAggregatorsForOffer,
  listAdministrativeAreas,
  listAggregators,
  listCommodityQualityGrades,
  listCommodityQualityGradesReset,
  listExchangeLocationTypes,
} from "../../actions";
import PageStandard from "../../components/PageStandard";
import Form from "./components/Form";
import {
  getStatusErrorMessage,
  valueOrDefault,
  formatDateInput,
  getCountryObjectFromCache,
} from "../../utils";
import {
  adaptCommodities,
  adaptUnitsOfMeasure,
  adaptTrader,
} from "../../utils/adapters";
import { useTranslation } from "react-i18next";
import { ORDER_TYPES } from "../../constants";
import { OfferStatus } from "../RepresentativeHome/components/OfferCard/interfaces";
import { v4 as uuidv4 } from "uuid";
import DirectOfferDetails from "../DirectOfferDetails";
import { orderByLocalName } from "../../utils/i18n";

// A function to adapt values entered in form to a format accepted by the server
export const adaptFromValues = (
  formValues,
  selectedAggregator,
  unitsOfMeasure,
) => {
  // if exact quantity order we follow the convention of minimum_value = maximum_value
  const maximum_quantity =
    formValues.order_type.value === ORDER_TYPES.EXACT
      ? formValues.minimum_quantity
      : formValues.maximum_quantity;

  const unitOfMeasure = unitsOfMeasure.find(
    (unitOfMeasure) =>
      unitOfMeasure.data.id === formValues.unit_of_measure.value,
  );

  const has_custom_exchange_location =
    typeof formValues.delivery_location.value === "object";
  return {
    buy_commodity: valueOrDefault(
      formValues.buy_commodity && formValues.buy_commodity.value,
    ),
    unit_of_measure: valueOrDefault(unitOfMeasure && unitOfMeasure.value),
    quality: valueOrDefault(formValues.quality && formValues.quality.value),
    delivery_location: has_custom_exchange_location
      ? formValues.delivery_location.value.deliveryLocationType
      : valueOrDefault(
        formValues.delivery_location && formValues.delivery_location.value,
        undefined,
      ),
    negotiable: valueOrDefault(
      formValues.negotiable && formValues.negotiable.value,
    ),
    tagged_aggregator: selectedAggregator.id,
    price: valueOrDefault(formValues.price),
    minimum_quantity: valueOrDefault(formValues.minimum_quantity),
    maximum_quantity: valueOrDefault(maximum_quantity),
    valid_until: valueOrDefault(formatDateInput(formValues.valid_until)),
    contract_number: valueOrDefault(formValues.contract_number),
    planned_exchange_time:
      formValues.exchange_date && formValues.exchange_date.toISOString
        ? formValues.exchange_date.toISOString()
        : undefined,
    comment: valueOrDefault(formValues.comment),
    custom_delivery_location: has_custom_exchange_location
      ? formValues.delivery_location.value.custom_delivery_location
      : undefined,
    custom_delivery_location_type: has_custom_exchange_location
      ? formValues.delivery_location.value.custom_delivery_location_type
      : undefined,
  };
};

// This component is used to display the detail
// of a Market Offer
export const MarketOfferDetails = ({
  isFetching,
  errorMessage,
  listAggregatorsForOffer,
  listCommodities,
  listUnitsOfMeasure,
  commodities,
  unitsOfMeasure,
  fieldErrors,
  formValues,
  addMarketOffer,
  addMarketOfferReset,
  history,
  trader,
  listTraders,
  isFetchingAggregators,
  errorMessageAggregators,
  aggregators,
  aggregatorsCurrentPage,
  aggregatorsPage,
  aggregatorsCount,
  clearMinimumNumber,
  clearMinimumQuantity,
  clearPosition,
  setUnitOfMeasurement,
  listAggregatorsForOfferReset,
  listDeposits,
  matchingDeposits,
  areMatchingDepositsFetching,
  customExchangeLocations,
  exchangeLocationTypes,
  listExchangeLocations,
  listExchangeLocationTypes,
  listAdministrativeAreas,
  administrativeAreas,
  listAggregators,
  listCommodityQualityGrades,
  listCommodityQualityGradesReset,
  commodityQualityGrades,
}) => {
  // This part of state is used to handle
  // the state of modal used to manage tagged aggregators
  const [isAggregatorsModalOpen, setAggregatorsModalOpen] = useState(false);

  // This state is used to store selected aggregators
  const [selectedAggregator, setSelectedAggregator] = useState({});
  const [directOfferModalOpen, setDirectOfferModalOpen] = useState(undefined);
  const [isValid, setIsValid] = useState(false);
  const [orderType, setOrderType] = useState({});

  const isRangeOrder = orderType && orderType.value === ORDER_TYPES.RANGE;

  const { t } = useTranslation();

  // On component mount the data are fetched
  // market offer if fetched if we are in edit
  // on component unmount data are reset
  useEffect(() => {
    listCommodities();
    listUnitsOfMeasure();
    listDeposits();
    listTraders();
    listExchangeLocations();
    listExchangeLocationTypes();
    listAdministrativeAreas();
    listAggregators();
    listCommodityQualityGrades();
    return () => {
      addMarketOfferReset();
      aggregatorsCurrentPage(0);
      listAggregatorsForOfferReset();
      listCommodityQualityGradesReset();
    };
  }, []);

  // On click validate a request is made
  const onClickValidate = () => {
    const data = adaptFromValues(
      formValues,
      selectedAggregator,
      unitsOfMeasure,
    );
    addMarketOffer(data).then((result) => {
      if (result) {
        history.push("/representative/");
      }
    });
  };

  const onClickSeeDeposits = () => {
    history.push("/representative/market/", {
      commodity_id: formValues.buy_commodity && formValues.buy_commodity.value,
      quality: formValues.quality && formValues.quality.value,
      unit_of_measure_id:
        formValues.unit_of_measure && formValues.unit_of_measure.value,
    });
  };

  // On click open tagged aggregator modal,
  // the modal is displayed
  // form is reset before to open the modal
  // and list is filtered with only
  // commodities as parameter
  const onClickManageAggregators = () => {
    const commodity = valueOrDefault(
      formValues.buy_commodity && formValues.buy_commodity.value,
      undefined,
    );
    const quality_grade = valueOrDefault(
      formValues.quality && formValues.quality.value,
      undefined,
    );
    clearMinimumNumber();
    clearMinimumQuantity();
    clearPosition();

    if (commodity && quality_grade) {
      listAggregatorsForOffer({ commodity, quality_grade });
      setAggregatorsModalOpen(true);
    }
  };

  useEffect(() => {
    onClickManageAggregators();
  }, [formValues.quality, formValues.buy_commodity]);

  // On click validate on tagged aggregator modal
  // the modal is closed
  const onClickValidateAggregatorsModal = () => {
    setAggregatorsModalOpen(false);
  };

  // On click search on aggregator modal
  // aggregator are filtered according
  // to data entered by user
  const onClickSearchAggregators = () => {
    filterAggregators(0);
  };

  // A function used to filter aggregators
  const filterAggregators = (page) => {
    const min_farmers_num = valueOrDefault(
      formValues.min_farmers_num,
      undefined,
    );
    const min_deposit_quantity = valueOrDefault(
      formValues.min_deposit_quantity,
      undefined,
    );
    const location = valueOrDefault(formValues.location, undefined);
    const commodity = valueOrDefault(
      formValues.buy_commodity && formValues.buy_commodity.value,
      undefined,
    );
    const quality_grade = valueOrDefault(
      formValues.quality && formValues.quality.value,
      undefined,
    );
    const filters = {
      min_farmers_num,
      min_deposit_quantity,
      location,
      commodity,
      quality_grade,
      page: page + 1,
    };
    aggregatorsCurrentPage(page);
    listAggregatorsForOffer(filters);
  };

  // A function used to manage pagination of aggregators page
  const onPageChangeAggregators = (page) => filterAggregators(page);

  // When user selects an an aggregator
  // it is added to the list of selected aggregators
  const onClickSelectAggregator = (record) =>
    setSelectedAggregator(record.original);

  // A function to remove a selected aggregator from the list
  const onClickRemoveAggregator = () => setSelectedAggregator({});

  const depositDtos = matchingDeposits.map((d) => {
    return {
      price: d.average_proposed_price_per_unit,
      quality: `${d.quality}`,
      name: d.commodity_name,
      ...d,
    };
  });

  return (
    <PageStandard title={t("marketOfferDetails.title")}>
      <Form
        formValues={formValues}
        matchingDeposits={depositDtos}
        customExchangeLocations={customExchangeLocations}
        updateMatchingDeposits={(commodity_id, quality, unit_id) => {
          listDeposits(commodity_id, quality, unit_id);
        }}
        onClickSeeDeposits={onClickSeeDeposits}
        onSelectDeposit={setDirectOfferModalOpen}
        isFetching={isFetching}
        errorMessage={errorMessage}
        aggregators={aggregators}
        commodities={commodities}
        unitsOfMeasure={unitsOfMeasure}
        onClickValidate={onClickValidate}
        fieldErrors={fieldErrors}
        trader={trader}
        isAggregatorsModalOpen={isAggregatorsModalOpen}
        onClickValidateAggregatorsModal={onClickValidateAggregatorsModal}
        onClickSearchAggregators={onClickSearchAggregators}
        errorMessageAggregators={errorMessageAggregators}
        isFetchingAggregators={isFetchingAggregators}
        aggregatorsPage={aggregatorsPage}
        onPageChangeAggregators={onPageChangeAggregators}
        aggregatorsCount={aggregatorsCount}
        selectedAggregator={selectedAggregator}
        onClickSelectAggregator={onClickSelectAggregator}
        onClickRemoveAggregator={onClickRemoveAggregator}
        setUnitOfMeasurement={setUnitOfMeasurement}
        areMatchingDepositsFetching={areMatchingDepositsFetching}
        setIsValid={setIsValid}
        isValid={isValid}
        setOrderType={setOrderType}
        isRangeOrder={isRangeOrder}
        t={t}
        administrativeAreas={administrativeAreas}
        exchangeLocationTypes={exchangeLocationTypes}
        commodityQualityGrades={commodityQualityGrades}
      />
      <DirectOfferDetails
        isOpen={!!directOfferModalOpen}
        onCancel={() => {
          setDirectOfferModalOpen(undefined);
        }}
        deposit={directOfferModalOpen}
      />
    </PageStandard>
  );
};

// propTypes for the MarketOfferDetails component
MarketOfferDetails.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  listAggregatorsForOffer: PropTypes.func.isRequired,
  listCommodities: PropTypes.func.isRequired,
  listUnitsOfMeasure: PropTypes.func.isRequired,
  aggregators: PropTypes.array.isRequired,
  commodities: PropTypes.array.isRequired,
  unitsOfMeasure: PropTypes.array.isRequired,
  fieldErrors: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  addMarketOffer: PropTypes.func.isRequired,
  addMarketOfferReset: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  listTraders: PropTypes.func.isRequired,
  trader: PropTypes.object.isRequired,
  isFetchingAggregators: PropTypes.bool.isRequired,
  errorMessageAggregators: PropTypes.string.isRequired,
  aggregatorsCurrentPage: PropTypes.func.isRequired,
  aggregatorsPage: PropTypes.number.isRequired,
  aggregatorsCount: PropTypes.number.isRequired,
  clearMinimumNumber: PropTypes.func.isRequired,
  clearMinimumQuantity: PropTypes.func.isRequired,
  setUnitOfMeasurement: PropTypes.func.isRequired,
  clearPosition: PropTypes.func.isRequired,
  listAggregatorsForOfferReset: PropTypes.func.isRequired,
  listAggregators: PropTypes.func.isRequired,
  listDeposits: PropTypes.func.isRequired,
  matchingDeposits: PropTypes.array.isRequired,
  areMatchingDepositsFetching: PropTypes.bool.isRequired,
  customExchangeLocations: PropTypes.array.isRequired,
  exchangeLocationTypes: PropTypes.array.isRequired,
  listExchangeLocations: PropTypes.func.isRequired,
  listExchangeLocationTypes: PropTypes.func.isRequired,
  administrativeAreas: PropTypes.array,
  listAdministrativeAreas: PropTypes.func.isRequired,
  listCommodityQualityGrades: PropTypes.func.isRequired,
  listCommodityQualityGradesReset: PropTypes.func.isRequired,
  commodityQualityGrades: PropTypes.array.isRequired,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = (state) => {
  const aggregatorsForOffer = state.listAggregatorsForOffer.data.results;
  const aggregatorsForOfferCount = state.listAggregatorsForOffer.data.count;
  let aggregators = state.listAggregators.data.results;
  let aggregatorsCount = valueOrDefault(state.listAggregators.data.count, 0);
  if (aggregatorsForOffer.length > 0) {
    aggregators = aggregatorsForOffer;
    aggregatorsCount = aggregatorsForOfferCount;
  }

  return {
    // Properties related to market offer details
    // if we are on creation, no market offer is fetched
    // Loading and errors properties
    isFetching:
      state.listCommodities.isFetching ||
      state.listUnitsOfMeasure.isFetching ||
      state.listTraders.isFetching ||
      state.addMarketOffer.isLoading ||
      state.listAdministrativeAreas.isFetching ||
      state.listExchangeLocationTypes.isFetching ||
      state.listAdministrativeAreas.isFetching ||
      state.listCommodityQualityGrades.isFetching,
    errorMessage:
      state.listCommodities.errorMessage ||
      state.listUnitsOfMeasure.errorMessage ||
      state.listTraders.errorMessage ||
      state.listCommodityQualityGrades.errorMessage ||
      getStatusErrorMessage(state.addMarketOffer.error) ||
      "",
    // Related entities lists
    commodities: adaptCommodities(
      orderByLocalName(state.listCommodities.data.results),
    ),
    unitsOfMeasure: adaptUnitsOfMeasure(state.listUnitsOfMeasure.data.results),
    // Form errors
    fieldErrors: state.addMarketOffer.error,
    // Form values
    formValues:
      (state.form.marketOfferDetails && state.form.marketOfferDetails.values) ||
      {},
    matchingDeposits: state.listDeposits.data.results
      .filter((d) => !d.has_direct_offer_same_trader)
      .slice(0, 3),
    areMatchingDepositsFetching: state.listDeposits.isFetching,
    // Trader details to get the currency
    trader: adaptTrader(state.listTraders.data.results),
    // Aggregators management
    isFetchingAggregators: state.listAggregatorsForOffer.isFetching,
    errorMessageAggregators: state.listAggregatorsForOffer.errorMessage,
    aggregators: aggregators,
    aggregatorsPage: state.aggregatorsCurrentPage.number,
    aggregatorsCount: aggregatorsCount,
    customExchangeLocations: state.listExchangeLocations.data.results,
    exchangeLocationTypes: state.listExchangeLocationTypes.data.results,
    administrativeAreas: state.listAdministrativeAreas.data.results,
    commodityQualityGrades: state.listCommodityQualityGrades.data.results,
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  const countryID = getCountryObjectFromCache().id;

  return {
    listAdministrativeAreas: () =>
      dispatch(
        listAdministrativeAreas({
          page_size: 9999,
          page: 1,
          country: countryID,
        }),
      ),
    listAggregators: () => dispatch(listAggregators()),
    listAggregatorsForOffer: (filters) =>
      dispatch(listAggregatorsForOffer({ ...filters, page_size: "10" })),
    listAggregatorsForOfferReset: () =>
      dispatch(listAggregatorsForOfferReset()),
    listCommodities: () =>
      dispatch(listCommodities({ page_size: 200, grown_by_farmers: true })),
    listUnitsOfMeasure: () => dispatch(listUnitsOfMeasure()),
    listExchangeLocations: () => dispatch(listExchangeLocations()),
    listExchangeLocationTypes: () => dispatch(listExchangeLocationTypes()),
    listDeposits: (commodity_id, quality, unit_of_measure_id) =>
      dispatch(
        listDeposits({
          page_size: 99,
          page: 1,
          commodity_id,
          quality,
          unit_of_measure_id,
          is_sold: false,
        }),
      ),
    addMarketOffer: (data) => dispatch(addMarketOffer(data)),
    addMarketOfferReset: () => dispatch(addMarketOfferReset()),
    listTraders: () => dispatch(listTraders()),
    aggregatorsCurrentPage: (page) => dispatch(aggregatorsCurrentPage(page)),
    clearMinimumNumber: () =>
      dispatch(change("marketOfferDetails", "min_farmers_num", undefined)),
    setUnitOfMeasurement: (unit) =>
      dispatch(change("marketOfferDetails", "unit_of_measure", unit)),
    clearMinimumQuantity: () =>
      dispatch(change("marketOfferDetails", "min_deposit_quantity", undefined)),
    clearPosition: () =>
      dispatch(change("marketOfferDetails", "position", undefined)),
    addOfferComment: (text, offerId) =>
      dispatch(
        addOfferComment({
          trader_market_offer: offerId,
          text,
          uuid: uuidv4(),
          trader_market_offer_status: OfferStatus.New,
        }),
      ),
    listCommodityQualityGrades: () => dispatch(listCommodityQualityGrades()),
    listCommodityQualityGradesReset: () =>
      dispatch(listCommodityQualityGradesReset()),
  };
};

// The component uses the redux store
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(MarketOfferDetails));
