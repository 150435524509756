import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../../components/Modal";
import PropTypes from "prop-types";
import Form from "../Form";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  addHelpMessagesDetails,
  helpMessagesDetails,
  helpMessagesDetailsReset,
  updateHelpMessagesDetails,
} from "../../../../actions";
import { adaptInitialFormValues, getTitleName } from "../../../../utils/wfpManagerConfigurationUtils";

export const HelpMessagesEditModal = ({
  initialFormValues,
  isFetching,
  errorMessage,
  modalOptions,
  setModalOptions,
  helpMessagesDetails,
  helpMessagesDetailsReset,
  updateHelpMessagesDetails,
  addHelpMessagesDetails,
}) => {

  const { t } = useTranslation();
  const buttonRef = useRef();
  const [initialValues, setInitialValues] = useState(undefined);

  useEffect(() => {
    helpMessagesDetails(modalOptions.id);
    return () => {
      helpMessagesDetailsReset();
    };
  }, [modalOptions.id]);

  useEffect(() => {
    setInitialValues( modalOptions.type !== "create" ? adaptInitialFormValues(initialFormValues, modalOptions.id) : undefined);
  }, [initialFormValues]);

  const onSubmitEdit = (formValues) => {
    updateHelpMessagesDetails(modalOptions.id, formValues);
  };

  const onSubmitCreate = (formValues) => {
    addHelpMessagesDetails(formValues);
  };

  const onSubmit = (formValues) => {
    switch(modalOptions.type){
    case "edit":
      onSubmitEdit(formValues);
      break;
    case "create":
      onSubmitCreate(formValues);
      break;
    default:
      break;
    }
    setModalOptions({ type: undefined, id: undefined, isOpen: false });
  };

  return (
    <Modal
      isOpen={modalOptions.isOpen}
      title={
        t("configuration.item.webHelpMessages") + " " + t(getTitleName(modalOptions.type))
      }
      onCancel={() => setModalOptions({ type: undefined, isOpen: false, id: undefined })}
      onValidate={() => buttonRef.current.click()}
      hideCancelButton={modalOptions.type === "view" ? true : false}
      validateText={modalOptions.type !== "view" ? t("negotiationCard.confirm") : t("common.back")}
    >
      <Form
        id={modalOptions.id}
        initialValues={initialValues}
        isFetching={isFetching}
        errorMessage={errorMessage}
        modalOptions={modalOptions}
        onSubmit={onSubmit}
        buttonRef={buttonRef}
      />
    </Modal>
  );
};

HelpMessagesEditModal.defaultProps = {};

HelpMessagesEditModal.propTypes = {
  initialFormValues: PropTypes.object,
  isFetching: PropTypes.bool,
  errorMessage: PropTypes.string,
  modalOptions: PropTypes.object,
  setModalOptions: PropTypes.func,
  helpMessagesDetails: PropTypes.func.isRequired,
  helpMessagesDetailsReset: PropTypes.func.isRequired,
  updateHelpMessagesDetails: PropTypes.func.isRequired,
  addHelpMessagesDetails: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    initialFormValues: state.helpMessagesDetail.data,
    isFetching: state.helpMessagesDetail.isFetching,
    errorMessage: state.helpMessagesDetail.errorMessage,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    helpMessagesDetails: (id) => dispatch(helpMessagesDetails(id)),
    helpMessagesDetailsReset: () => dispatch(helpMessagesDetailsReset()),
    addHelpMessagesDetails: (values) =>
      dispatch(addHelpMessagesDetails(values, "", true)),
    updateHelpMessagesDetails: (id, values) =>
      dispatch(updateHelpMessagesDetails(id, values, "", true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HelpMessagesEditModal);
