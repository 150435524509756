import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  countryDashboard,
  listCommodities,
  listCommoditiesReset,
  listUnitsOfMeasure,
  updateCommodity,
} from "../../actions";
import Button from "../../components/Button";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import PageStandard from "../../components/PageStandard";
import { getCountryObjectFromCache, valueOrDefault } from "../../utils";
import { adaptCommoditiesList } from "../../utils/adapters";
import { orderByLocalName } from "../../utils/i18n";
import List from "./components/List";
import { Controller, useForm } from "react-hook-form";
import { Col, Row } from "../../components/Grid";
import Input from "../../components/Input";
import { iconSearch } from "@wfp/icons";
import { iconClose } from "@wfp/icons";
import style from "./style.scss";
import { withRouter } from "react-router-dom";

/**
 * This component is used to display the list of commodities for the WFP Manager
 */
export const Commodities = ({
  isFetching,
  errorMessage,
  commodities,
  count,
  listCommodities,
  listCommoditiesReset,
  listUnitsOfMeasure,
  unitsOfMeasure,
  countryDashboardData,
  getCountryDashboardData,
  updateCommodity,
  history,
}) => {
  // if (isFetching) {
  //   return <Loading isVisible />;
  // }

  const { t } = useTranslation();

  const [showAll, setShowAll] = useState(false);
  const [commodityUpdateErrorMessage, setCommodityUpdateErrorMessage] =
    useState(undefined);

  const [paginationState, setPaginationState] = useState({
    page: 0,
    pageSize: 10,
    name: undefined,
  });

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      name: new URLSearchParams(location.search).get("name"),
    },
  });

  // On component mount the data are fetched
  useEffect(() => {
    listUnitsOfMeasure();
    getCountryDashboardData();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setPaginationState({
      page: params.get("page")
        ? parseInt(params.get("page"), 10)
        : paginationState.page,
      pageSize:
        params.get("pageSize") && parseInt(params.get("pageSize"), 10) > 0
          ? parseInt(params.get("pageSize"), 10)
          : paginationState.pageSize,
      name: params.get("name") ? params.get("name") : undefined,
    });
  }, [location.search]);

  useEffect(() => {
    listCommodities(
      paginationState.page,
      paginationState.pageSize,
      paginationState.name,
    );

    return () => {
      listCommoditiesReset();
    };
  }, [paginationState]);

  // A function used to manage pagination of commodities page
  const onPageChange = (page) => {
    const params = new URLSearchParams(location.search);

    // Step 2: Update or add new parameters
    params.set("page", page.page - 1);
    params.set("pageSize", page.pageSize);

    // Step 3: Navigate to the updated URL
    history.push(`${location.pathname}?${params.toString()}`);
  };

  const onClickShowAll = () => {
    listCommodities(0, 9999, paginationState.name);
    setShowAll(true);
  };
  const onClickHideAll = () => {
    listCommodities(
      paginationState.page,
      paginationState.pageSize,
      paginationState.name,
    );
    setShowAll(false);
  };

  const onClickAddCommodity = (commodity) => {
    const countryToAdd = getCountryObjectFromCache();
    const updatedCountryList = [
      ...new Set(commodity.countries).add(countryToAdd.id).values(),
    ];
    const commodityToPut = { ...commodity.dto, countries: updatedCountryList };
    updateCommodity(commodityToPut)
      .then((resp) => {
        resp &&
          resp.data &&
          listCommodities(
            paginationState.page,
            paginationState.pageSize,
            paginationState.name,
          );
      })
      .catch((error) => {
        setCommodityUpdateErrorMessage(error.data);
      });
    getCountryDashboardData();
  };

  const filterCommodities = (filters) => {
    history.push(`?name=${filters.name}&page=0`);
  };

  const clearFilter = () => {
    setValue("name", "");
    history.push(`?page=0&page_size=${paginationState.pageSize}`);
  };

  // TODO: set is fetching to true when filtering data

  return (
    <PageStandard
      title={
        showAll
          ? t("commodities.all")
          : `${t("commodities.title")} ${t("common.in")} ${
            getCountryObjectFromCache().name
          }`
      }
      additional={
        <ButtonsWrapper>
          {!showAll ? (
            <Button onClick={onClickShowAll}>{t("commodities.showAll")}</Button>
          ) : (
            <Button onClick={onClickHideAll}>{t("commodities.hideAll")}</Button>
          )}
        </ButtonsWrapper>
      }
    >
      <Row className={style.filtersContainer}>
        <Col sm={6}>
          <div className={style.filtersTitle}>
            <span>{t("commodities.filters")}:</span>
          </div>
          <div className={style.filterRow}>
            <Controller
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  input={field}
                  meta={fieldState}
                  placeholder={t("commodities.typeCommodityName")}
                  id="commodity-name-input"
                />
              )}
              isRequired
              name="name"
              id="commodity-name"
            />
            <Button
              className={style.searchButton}
              icon={iconSearch}
              onClick={handleSubmit(filterCommodities)}
            >
              {t("commodities.search")}
            </Button>
            {paginationState.name && (
              <Button
                className={style.searchButton}
                icon={iconClose}
                onClick={clearFilter}
                kind="secondary"
              >
                {t("commodities.clearFilter")}
              </Button>
            )}
          </div>
        </Col>
      </Row>
      <List
        {...{
          isFetching,
          errorMessage: errorMessage || commodityUpdateErrorMessage,
          commodities,
          page: paginationState.page,
          pageSize: paginationState.pageSize,
          onPageChange,
          countryDashboardData,
          unitsOfMeasure,
          count,
          showAll,
          onClickAddCommodity,
        }}
      />
    </PageStandard>
  );
};

// propTypes for the Commodities component
Commodities.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  commodities: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  listCommodities: PropTypes.func.isRequired,
  listCommoditiesReset: PropTypes.func.isRequired,
  listUnitsOfMeasure: PropTypes.func.isRequired,
  unitsOfMeasure: PropTypes.array.isRequired,
  getCountryDashboardData: PropTypes.func.isRequired,
  countryDashboardData: PropTypes.object,
  updateCommodity: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = (state) => {
  return {
    // Commodities management
    isFetching:
      state.listCommodities.isFetching || state.countryDashboard.isFetching,
    errorMessage: state.listCommodities.errorMessage,
    commodities: adaptCommoditiesList(
      orderByLocalName(state.listCommodities.data.results),
      state.listUnitsOfMeasure.data.results,
    ),
    countryDashboardData: state.countryDashboard.data,
    count: valueOrDefault(state.listCommodities.data.count, 0),
    unitsOfMeasure: state.listUnitsOfMeasure.data.results,
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    listCommodities: (page, page_size, name) =>
      dispatch(
        listCommodities({ page: page + 1, page_size, name, ordering: "name" }),
      ),
    // listAllCommodities: (page) => dispatch(listAllCommodities({ page })),
    updateCommodity: (commodity) =>
      dispatch(updateCommodity(commodity.id, commodity)),
    listUnitsOfMeasure: () => dispatch(listUnitsOfMeasure()),
    listCommoditiesReset: () => dispatch(listCommoditiesReset()),
    getCountryDashboardData: () => dispatch(countryDashboard()),
  };
};

// The component uses the redux store
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Commodities));
