import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PageStandard from "../../components/PageStandard";
import { valueOrDefault } from "../../utils";
import List from "./components/List";
import PropTypes from "prop-types";
import {
  deleteTraderMarketOfferNegotiationProposalReasonsDetails,
  listTraderMarketOfferNegotiationProposalReasons,
  listTraderMarketOfferNegotiationProposalReasonsReset,
  traderMarketOfferNegotiationProposalReasonsDetailsReset,
} from "../../actions";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import Button from "../../components/Button";
import { iconAdd } from "@wfp/icons";
import TraderMarketOfferNegotiationProposalReasonsEditModal from "./components/Modal";

export const WfpManagerTraderMarketOfferNegotiationProposalReasonsView = ({
  isFetching,
  errorMessage,
  traderMarketOfferNegotiationProposalReasons,
  page,
  count,
  listTraderMarketOfferNegotiationProposalReasons,
  listTraderMarketOfferNegotiationProposalReasonsReset,
  traderMarketOfferNegotiationProposalReasonsDetailsReset,
}) => {
  const { t } = useTranslation();
  const [modalOptions, setModalOptions] = useState({isOpen: false, id: undefined, type: undefined});

  const onClickAdd = () => {
    traderMarketOfferNegotiationProposalReasonsDetailsReset();
    setModalOptions({isOpen: true, id: "add", type: "create"});
    listTraderMarketOfferNegotiationProposalReasons();
  };

  // On component mount the data are fetched
  useEffect(() => {
    listTraderMarketOfferNegotiationProposalReasons();
    return () => {
      listTraderMarketOfferNegotiationProposalReasonsReset();
    };
  }, []);

  return (
    <PageStandard
      title={t("configuration.item.traderMarketOfferNegotiationProposalReasons")}
      additional={(
        <ButtonsWrapper>
          <Button icon={iconAdd} onClick={onClickAdd}>
            {t("common.add") + " " + t("configuration.item.traderMarketOfferNegotiationProposalReasons")}
          </Button>
        </ButtonsWrapper>
      )}
    >
      <List
        {...{
          isFetching,
          errorMessage,
          traderMarketOfferNegotiationProposalReasons,
          page,
          count,
          setModalOptions,
        }}
      />
      <TraderMarketOfferNegotiationProposalReasonsEditModal
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
      />
    </PageStandard>
  );
};

WfpManagerTraderMarketOfferNegotiationProposalReasonsView.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  traderMarketOfferNegotiationProposalReasons: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  listTraderMarketOfferNegotiationProposalReasons: PropTypes.func.isRequired,
  listTraderMarketOfferNegotiationProposalReasonsReset: PropTypes.func.isRequired,
  traderMarketOfferNegotiationProposalReasonsDetailsReset: PropTypes.func.isRequired,
  deleteTraderMarketOfferNegotiationProposalReasonsDetails: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    traderMarketOfferNegotiationProposalReasons:
      state.listTraderMarketOfferNegotiationProposalReasons.data.results,
    isFetching: state.listTraderMarketOfferNegotiationProposalReasons.isFetching,
    errorMessage: state.listTraderMarketOfferNegotiationProposalReasons.errorMessage,
    page: 1,
    count: valueOrDefault(
      state.listTraderMarketOfferNegotiationProposalReasons.data.results.length,
      0,
    ),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    listTraderMarketOfferNegotiationProposalReasons: () =>
      dispatch(listTraderMarketOfferNegotiationProposalReasons()),
    listTraderMarketOfferNegotiationProposalReasonsReset: () =>
      dispatch(listTraderMarketOfferNegotiationProposalReasonsReset()),
    traderMarketOfferNegotiationProposalReasonsDetailsReset: () => dispatch(traderMarketOfferNegotiationProposalReasonsDetailsReset()),
    deleteTraderMarketOfferNegotiationProposalReasonsDetails: id => dispatch(deleteTraderMarketOfferNegotiationProposalReasonsDetails(id, true)),
  };
};

// The component uses the redux store
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WfpManagerTraderMarketOfferNegotiationProposalReasonsView);
