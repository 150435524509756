/* eslint-disable react/jsx-closing-bracket-location */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import { iconEdit, iconDelete } from "@wfp/icons";
import { StepNavigation, StepNavigationItem } from "@wfp/ui";
import style from "./style.scss";
import Module from "../../../../components/Module";
import Loading from "../../../../components/Loading";
import { displayErrorMessage, isEmpty } from "../../../../utils";
import { Grid, Row, Col } from "../../../../components/Grid";
import Spacer from "../../../../components/Spacer";
import ButtonsWrapper from "../../../../components/ButtonsWrapper";
import Button from "../../../../components/Button";
import TableInMemory from "../../../../components/Table";
import { menu } from "../../../../utils/table";
import ModalConfirm from "../../../../components/ModalConfirm";
import { withTranslation } from "react-i18next";
import AggregatorDetailsProfile from "./Components/AggregatorDetailsProfile";
import AggregatorConfigurationStep from "./Components/AggregatorConfigurationStep";
import AddAdministratorForm from "../AdministratorModal";

// The list of columns related to Aggregator Administrators
export const aggregatorAdministratorsColumns = (onClickEdit, onClickDelete) => [
  {
    Header: "Name",
    accessor: "person.full_name",
  },
  {
    Header: "Account type",
    Width: 80,
    Cell: (data) => {
      const admin = data.row.original;
      switch (admin.account_type) {
      case "mastercard_foundation_admin": {
        return "MCF";
      }
      case "aggregator_admin":
      default:
        return "Aggregator";
      }
    },
  },
  {
    Header: "E-mail",
    accessor: "email",
  },
  {
    Header: "",
    Cell: (data) =>
      menu([
        {
          key: data.row.original.id,
          icon: iconEdit,
          label: "Edit",
          onClick: onClickEdit,
          visible: true,
          data: data.row.original,
        },
        {
          key: data.row.original.id + 2,
          icon: iconDelete,
          label: "Delete",
          onClick: onClickDelete,
          visible: true,
          data: data.row.original,
        },
      ]),
    width: 40,
  },
];

const getErrors = (object, key) =>
  object && object[key] && !isEmpty(object[key]) ? object[key] : "";

const formResolver = (values, t) => {
  let errors = {};
  if (values.name === "" || !values.name) {
    errors.name = t("traderDetails.form.mandatoryFieldBlankError");
  }
  if (values.org_type === "" || !values.org_type) {
    errors.org_type = t("traderDetails.form.mandatoryFieldBlankError");
  }
  if (values.location === "" || !values.location) {
    errors.location = t("traderDetails.form.mandatoryFieldBlankError");
  }
  if (values.language === "" || !values.language) {
    errors.location = t("traderDetails.form.mandatoryFieldBlankError");
  }
  return { values, errors };
};

// The details of a Aggregator Organization
export const Form = ({
  id,
  isFetching,
  errorMessage,
  onClickValidateAndClose,
  onClickValidateAndContinue,
  legalStatuses,
  aggregatorAdministrators,
  onClickEditAdministrator,
  onClickDeleteAdministrator,
  isDeleteAdministratorModalOpen,
  onClickConfirmDeleteAdministrator,
  onClickCancelDeleteAdministrator,
  currentToDeleteAdministrator,
  isFetchingDeleteAdministrator,
  errorMessageDeleteAdministrator,
  organizationTypes,
  administrativeAreas,
  initialStep,
  t,
  initialValues,
  aggregatorUmbrellaOrganizations,
  //Add Admin AGG
  initialFormValuesAdministrator,
  isFetchingAdministrator,
  errorMessageAdministrator,
  fieldErrorsAdministrator,
  onClickValidateAdministratorModal,
  isOnEditAdministrator,
  onClickCloseAdministrator,
}) => {
  if (isFetching) {
    return <Loading isVisible />;
  }

  const buttonRef = useRef();
  const [showFieldErrors, setShowFieldErrors] = useState(false);
  const methods = useForm({
    defaultValues: initialValues,
    resolver: formResolver,
    context: t,
  });
  const {
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
  } = methods;

  const locationValue = watch("location");

  const nameValue = watch("name");
  const orgTypeValue = watch("org_type");

  useEffect(() => {
    trigger();
  }, [nameValue, orgTypeValue, locationValue]);

  if (errorMessage) {
    return <>{displayErrorMessage(errorMessage)}</>;
  }

  const nextButtonLabel = () => {
    switch (step) {
    case 0:
      return t("common.next");
    case 1:
      return t("common.saveContinue");
    case 2:
      return t("common.finish");
    default:
      return t("common.next");
    }
  };

  const deleteAdministratorMessage =
    (currentToDeleteAdministrator &&
      currentToDeleteAdministrator.person &&
      t("aggregatorDetails.form.deleteAdministratorMessage") +
        " " +
        currentToDeleteAdministrator.person.full_name +
        "?") ||
    "";

  const [step, setStep] = useState(initialStep || 0);

  const [aggregatorAdminWarning, setAggregatorAdminWarning] = useState("");

  const next = () => {
    // mandatory field validation for first step -- TODO refactor this component into smaller bits
    if (step === 0) {
      setShowFieldErrors(true);
      if (
        getErrors(errors, "name") ||
        getErrors(errors, "location") ||
        getErrors(errors, "org_type")
      ) {
        return;
      }
    }

    if (step === 2) {
      if (aggregatorAdministrators && aggregatorAdministrators.length > 0) {
        buttonRef.current.click();
      } else {
        setAggregatorAdminWarning(
          t("aggregatorDetails.form.aggregatorAdminWarning"),
        );
        return;
      }
    } else if (step === 1) {
      buttonRef.current.click();
    } else {
      setStep(step + 1);
    }
  };

  const getStepHeading = () => {
    switch (step) {
    case 0:
      return t("aggregatorDetails.aggregatorDetails");
    case 1:
      return t("aggregatorDetails.configuration");
    case 2:
      return t("aggregatorDetails.addAdministrators");
    }
  };

  return (
    <>
      <Grid>
        <Row>
          <Col sm={2}>
            <StepNavigation
              className={style.stepNav}
              onSelectionChange={(e) => !!id && setStep(e)}
              selectedPage={step}
            >
              <StepNavigationItem
                key={0}
                label={t("aggregatorDetails.aggregatorDetails")}
                page={0}
              />
              <StepNavigationItem
                key={1}
                label={t("aggregatorDetails.configuration")}
                page={1}
              />
              <StepNavigationItem
                status={!id ? "disabled" : undefined}
                key={2}
                label={t("aggregatorDetails.addAdministrators")}
                page={2}
              />
            </StepNavigation>
          </Col>
          <Col sm={8}>
            <Module
              title={getStepHeading()}
              footer={
                <ButtonsWrapper>
                  <Button onClick={next}>{nextButtonLabel()}</Button>
                </ButtonsWrapper>
              }
            >
              <FormProvider>
                <form
                  onSubmit={handleSubmit(
                    step === 1
                      ? onClickValidateAndContinue
                      : onClickValidateAndClose,
                  )}
                >
                  {step === 0 && (
                    <AggregatorDetailsProfile
                      methods={methods}
                      showFieldErrors={showFieldErrors}
                      legalStatuses={legalStatuses}
                      organizationTypes={organizationTypes}
                      administrativeAreas={administrativeAreas}
                      aggregatorAdministrators={aggregatorAdministrators}
                    />
                  )}
                  {step === 1 && (
                    <AggregatorConfigurationStep
                      methods={methods}
                      getErrors={getErrors}
                      showFieldErrors={showFieldErrors}
                      legalStatuses={legalStatuses}
                      aggregatorUmbrellaOrganizations={
                        aggregatorUmbrellaOrganizations
                      }
                    />
                  )}
                  <button ref={buttonRef} style={{ display: "none" }} />
                </form>
              </FormProvider>
              {step === 2 && (
                <Grid>
                  <Row className={style.instructionText}>
                    <Col sm={12}>

                      <div style={{fontSize: 16, lineHeight: 1.4, backgroundColor: "#EFF2F5", borderLeftWidth: 8, borderLeftColor: "#0B77C2", borderLeftStyle: "solid", borderRadius: 8, padding: 16, marginBottom: 12}}>
                        {t("aggregatorDetails.form.adminInstructionText")}
                      </div>

                    </Col>
                  </Row>
                  <AddAdministratorForm
                    id={id}
                    aggregator={initialValues}
                    initialValues={initialFormValuesAdministrator}
                    isFetching={isFetchingAdministrator}
                    errorMessage={errorMessageAdministrator}
                    fieldErrors={fieldErrorsAdministrator}
                    onClickValidate={onClickValidateAdministratorModal}
                    isOnEdit={isOnEditAdministrator}
                    onClickClose={onClickCloseAdministrator}
                  />
                  <Row>
                    <Col sm={12}>
                      {aggregatorAdministrators &&
                      aggregatorAdministrators.length ? (<TableInMemory
                          loading={false}
                          columns={aggregatorAdministratorsColumns(
                            onClickEditAdministrator,
                            onClickDeleteAdministrator,
                          )}
                          data={aggregatorAdministrators}
                          pageSize={10}
                        />
                        ) : aggregatorAdminWarning ? (
                          displayErrorMessage(aggregatorAdminWarning)
                        ) : (
                          <div className="wfp--module__title">
                            <div
                              className="wfp--module__header"
                              style={{ marginBottom: "1rem" }}
                            />
                            {t(
                              "aggregatorDetails.administratorModal.noAggregators",
                            )}
                          </div>
                        )}
                      <Spacer height="2rem" />
                    </Col>
                  </Row>
                </Grid>
              )}
            </Module>
          </Col>
        </Row>
      </Grid>
      <ModalConfirm
        isOpen={isDeleteAdministratorModalOpen}
        onValidate={onClickConfirmDeleteAdministrator}
        onCancel={onClickCancelDeleteAdministrator}
        errorMessage={errorMessageDeleteAdministrator}
        isLoading={isFetchingDeleteAdministrator}
        title={t("aggregatorDetails.form.deleteConfirmation")}
        text={deleteAdministratorMessage}
      />
    </>
  );
};

// propTypes for the Form component
Form.propTypes = {
  id: PropTypes.number,
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onClickValidateAndClose: PropTypes.func.isRequired,
  onClickValidateAndContinue: PropTypes.func.isRequired,
  legalStatuses: PropTypes.array.isRequired,
  aggregatorAdministrators: PropTypes.array.isRequired,
  onClickEditAdministrator: PropTypes.func.isRequired,
  onClickDeleteAdministrator: PropTypes.func.isRequired,
  isDeleteAdministratorModalOpen: PropTypes.bool.isRequired,
  onClickConfirmDeleteAdministrator: PropTypes.func.isRequired,
  onClickCancelDeleteAdministrator: PropTypes.func.isRequired,
  currentToDeleteAdministrator: PropTypes.object,
  isFetchingDeleteAdministrator: PropTypes.bool.isRequired,
  errorMessageDeleteAdministrator: PropTypes.string.isRequired,
  organizationTypes: PropTypes.array.isRequired,
  administrativeAreas: PropTypes.array.isRequired,
  aggregatorUmbrellaOrganizations: PropTypes.array.isRequired,
  initialStep: PropTypes.number,
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  initialFormValuesAdministrator: PropTypes.object,
  isFetchingAdministrator: PropTypes.bool,
  errorMessageAdministrator: PropTypes.string,
  fieldErrorsAdministrator: PropTypes.object,
  onClickValidateAdministratorModal: PropTypes.func,
  onClickCloseAdministrator: PropTypes.func,
  isOnEditAdministrator: PropTypes.bool,
};

// defaultProps for the Form component
Form.defaultProps = {
  errorMessage: "",
  id: "",
  currentToDeleteAdministrator: undefined,
};

// eslint-disable-next-line max-len
export default withTranslation()(Form);
