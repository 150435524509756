import clock from './waiting.png';
import style from "./style.scss";
import { useTranslation } from 'react-i18next';

export default () => {
    const {t} = useTranslation();
    return (<div className={style.container}>
        <div className={style.content}>
            <div>
                <img src={clock} />
            </div>
            <div>{t("commentsModal.waitingForResponse")}</div>
        </div>
    </div>);
};