import React from "react";
import PropTypes from "prop-types";
import { Modal as WFPModal } from "@wfp/ui";

// A wrapper of the Modal component form WFP UI Kit
const Modal = (
  {
    children,
    isOpen,
    title,
    validateText,
    cancelText,
    onValidate,
    onCancel,
    onRequestClose,
    hideCancelButton,
    modalLabel,
    className,
    danger,
    type,
    footer,
    passiveModal,
    wide,
    primaryButtonDisabled,
  },
) => (
  <WFPModal
    className={className}
    inPortal={false}
    open={isOpen}
    modalLabel={modalLabel}
    modalHeading={title}
    primaryButtonText={validateText}
    primaryButtonDisabled={primaryButtonDisabled}
    secondaryButtonText={hideCancelButton ? undefined : cancelText}
    onRequestSubmit={onValidate}
    onSecondarySubmit={onCancel}
    onRequestClose={onRequestClose}
    danger={danger}
    type={type}
    modalFooter={footer}
    passiveModal={passiveModal}
    wide={wide}
    hideClose
  >
    {children}
  </WFPModal>
);

// propTypes for the Modal component
Modal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.node,
  validateText: PropTypes.string,
  cancelText: PropTypes.string,
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onRequestClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  hideCancelButton: PropTypes.bool,
  modalLabel: PropTypes.any,
  className: PropTypes.string,
  type: PropTypes.string,
  danger: PropTypes.bool,
  footer: PropTypes.any,
  passiveModal: PropTypes.bool,
  wide: PropTypes.bool,
  primaryButtonDisabled: PropTypes.bool,
};

// defaultProps for the Modal component
Modal.defaultProps = {
  isOpen: false,
  validateText: "Confirm",
  cancelText: "Cancel",
  onCancel: undefined,
  hideCancelButton: false,
  title: undefined,
  primaryButtonDisabled: false,
};

export default Modal;
