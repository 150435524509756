import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { userDetail } from "../../actions";
import RepresentativeMainNavigation from "../../components/RepresentativeMainNavigation";

// The header used in all pages to allow user access to all section of the application
export class RepresentativeHeader extends Component {
  // Binding of methods and state initialization
  constructor(props) {
    super(props);
    this.onClickNewMarketOffer = this.onClickNewMarketOffer.bind(this);
    this.onClickNewDirectOffer = this.onClickNewDirectOffer.bind(this);
    this.onClickOffers = this.onClickOffers.bind(this);
  }

  // When component mount the user is fetched
  componentDidMount() {
    this.props.userDetail();
  }

  // On click new market offer the creation page is displayed
  onClickNewMarketOffer() {
    this.props.history.push("/representative/marketOfferDetails");
  }

  onClickNewDirectOffer() {
    this.props.history.push("/representative/market");
  }

  onClickOffers() {
    this.props.history.push("/representative/");
  }

  render() {
    return (
      <RepresentativeMainNavigation
        onClickNewMarketOffer={this.onClickNewMarketOffer}
        onClickNewDirectOffer={this.onClickNewDirectOffer}
        onClickOffers={this.onClickOffers}
        loading={this.props.isFetching}
        user={this.props.currentUser}
      />
    );
  }
}

// propTypes for the RepresentativeHeader component
RepresentativeHeader.propTypes = {
  history: PropTypes.any.isRequired,
  isFetching: PropTypes.bool.isRequired,
  userDetail: PropTypes.func.isRequired,
  currentUser: PropTypes.any,
};

// defaultProps for the RepresentativeHeader component
RepresentativeHeader.defaultProps = {
  currentUser: undefined,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = state => {
  return {
    currentUser: state.userDetail && state.userDetail.data,
    isFetching: !state.userDetail || state.userDetail.isFetching,
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    userDetail: () => dispatch(userDetail()),
  };
};

// The component needs to use the router to redirect user to the search page
// after entering some text in the search box
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RepresentativeHeader));
