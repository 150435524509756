import React from "react";
import PropTypes from "prop-types";
import { Blockquote } from "@wfp/ui";

import style from "./style.scss";

// A wrapper of the Blockquote component form WFP UI Kit
const MessageSuccess = ({ children, icon }) => (
  <div className={style.wrapper}>
    <Blockquote kind='success' withIcon={icon}>{children}</Blockquote>
  </div>
);

// propTypes for the MessageSuccess component
MessageSuccess.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.bool.isRequired,
};

export default MessageSuccess;
