// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_stepNav_X3Dk42QErpmURK7NVooD {\n  margin-top: 30px;\n}\n\n.style_label_w2p9D5du2TOmlQrY4Whg {\n  font-family: \"Open Sans\", sans-serif;\n  font-size: 0.875rem;\n  color: #001f2f;\n  font-weight: 600;\n  display: inline-block;\n  vertical-align: baseline;\n  margin-bottom: 0.5rem;\n}\n\n.style_mandatoryAsterisk_o3x5uKbvH2WLBkx5soWT {\n  color: red;\n}\n\n.style_instructionText_BeuLkYpyAmRzLhDAymrF {\n  padding: 8px 0;\n}\n\n.style_tooltip_cI39M1GLU4W64TBLem_g {\n  top: 5px;\n  position: relative;\n  margin-left: 0.5rem;\n}", "",{"version":3,"sources":["webpack://./src/containers/InfoHub/Components/Form/style.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,oCAAA;EACA,mBAAA;EACA,cAAA;EACA,gBAAA;EACA,qBAAA;EACA,wBAAA;EACA,qBAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,QAAA;EACA,kBAAA;EACA,mBAAA;AACF","sourcesContent":[".stepNav {\n  margin-top: 30px;\n}\n\n.label {\n  font-family: \"Open Sans\", sans-serif;\n  font-size: 0.875rem;\n  color: #001f2f;\n  font-weight: 600;\n  display: inline-block;\n  vertical-align: baseline;\n  margin-bottom: 0.5rem;\n}\n\n.mandatoryAsterisk {\n  color: red;\n}\n\n.instructionText {\n  padding: 8px 0;\n}\n\n.tooltip {\n  top: 5px;\n  position: relative;\n  margin-left: 0.5rem;\n};\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepNav": "style_stepNav_X3Dk42QErpmURK7NVooD",
	"label": "style_label_w2p9D5du2TOmlQrY4Whg",
	"mandatoryAsterisk": "style_mandatoryAsterisk_o3x5uKbvH2WLBkx5soWT",
	"instructionText": "style_instructionText_BeuLkYpyAmRzLhDAymrF",
	"tooltip": "style_tooltip_cI39M1GLU4W64TBLem_g"
};
export default ___CSS_LOADER_EXPORT___;
