import React from "react";
import PropTypes from "prop-types";

import Modal from "../Modal";
import Loading from "../Loading";
import { displayErrorMessage, displayDetailedErrorMessage } from "../../utils";

// A component used tho show a confirmation message
// if the error object is passed, a detailed
// error message is displayed, otherwise
// a standard error message is displayed,
// if not empty
const ModalConfirm = ({
  isOpen,
  onValidate,
  onCancel,
  errorMessage,
  error,
  isLoading,
  title,
  text,
}) => (
  <Modal
    isOpen={isOpen}
    title={title}
    onValidate={onValidate}
    onCancel={onCancel}
    validateText="Confirm"
  >
    {
      error ? (
        displayDetailedErrorMessage(error)
      ) : (
        displayErrorMessage(errorMessage)
      )
    }
    {isLoading ? <Loading small isVisible /> : text}
  </Modal>
);

// propTypes for the ModalConfirm component
ModalConfirm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onValidate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.any.isRequired,
  error: PropTypes.object,
};

// defaultProps for the ModalConfirm component
ModalConfirm.defaultProps = {
  errorMessage: "",
  error: undefined,
};

export default ModalConfirm;
