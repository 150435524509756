import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Col, Grid, Row } from "../../../../../../components/Grid";
import style from "./style.scss";
import Tag from "../../../../../../components/Tag";
import MarkdownPreview from "../../../../../../components/MarkdownPreview";
import { Blockquote } from "@wfp/ui";

const InfoHubReviewStep = ({ t, methods}) => {
  const { watch } = methods;
  const values = watch();

  return (
    <div className={style.container}>
      <Grid>
        <Row>
          <Col>
            <div className={style.contentType}>
              {values.content_type && values.content_type.label}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={style.title}>
              {values.name}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>

            <div className={style.topicContainer}>
              {values.topics && values.topics.map((topic, index) => (
                <Tag key={`tag-${index}`} className={style.topic}>
                  {topic.label}
                </Tag>
              ))}
            </div>
          </Col>

        </Row>
        <Row>
          <Col>
            <MarkdownPreview source={values.content} />
          </Col>
        </Row>
        <div className={style.divider} />
        <Row>
          <Col>
            {
              values.aggregators && values.aggregators.length > 0 && (
                <div className={style.aggregatorTitle}>
                  {t("infoHub.form.sharedWith")}
                </div>
              )
            }
            <div className={style.aggregatorContainer}>
              {values.aggregators && values.aggregators.map((aggregator, index) =>(
                <div key={`tag-${index}`} className={style.aggregator}>
                  {aggregator.label || t("infoHub.form.unknownAggregator", {id: aggregator.value})}
                </div>
              ))}
              {(!values.aggregators || values.aggregators.length === 0) && (
                <Blockquote kind="warning">
                  {t("infoHub.form.noAggregator")}
                </Blockquote>
              )}
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

InfoHubReviewStep.propTypes = {
  t: PropTypes.func,
  methods: PropTypes.object,
};

export default withTranslation()(InfoHubReviewStep);
