import React from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Button from "../../components/Button";
import { Col, Grid, Row } from "../../components/Grid";
import Loading from "../../components/Loading";
import MapPin from "../../components/MapPin";
import Modal from "../../components/Modal";
import { OFFER_TYPES } from "../../constants";
import { OfferStatus } from "../RepresentativeHome/components/OfferCard/interfaces";
import styles from "./style.scss";
import RepresentativesTable from "./components/RepresentativesTable";

const TraderDetailsModal = ({
  trader,
  isOpen,
  onValidate,
  representatives,
  isFetchingTraderRepresentatives,
  onClickEdit,
  offers: allOffers,
}) => {
  const { t } = useTranslation();

  const offers = allOffers.filter(({ tagged_aggregator: { id } }) => id === trader.id);
  const stats = [
    {
      label: "aggregatorDetails.aggregatorStats.depositPurchaseRequests",
      value: offers.filter(
        (o) => o.offer_type === OFFER_TYPES.DIRECT_OFFER,
      ).length,
    },
    {
      label: "aggregatorDetails.aggregatorStats.traderDemands",
      value: offers.filter(
        (o) => o.offer_type === OFFER_TYPES.MARKET_OFFER,
      ).length,
    },
    {
      label: "aggregatorDetails.aggregatorStats.offersExchanged",
      value: offers.filter(
        (o) => o.status === OfferStatus.ExchangeDone,
      ).length,
    },
  ];

  return (
    <Modal
      onValidate={onValidate}
      validateText={t("common.done")}
      title={trader.name}
      wide
      isOpen={isOpen}
      hideCancelButton={true}
    >
      {onClickEdit && (
        <Button
          className={styles.editButton}
          onClick={() => onClickEdit(trader)}
          kind="secondary"
        >
          {`${t("common.edit")}`}
        </Button>
      )}
      <Grid className={styles.detailsLabel}>
        <Row>
          <Col sm={12}>
            <div>
              <MapPin />&nbsp;
              {trader.physical_address},&nbsp;
              {!!trader.location_details && trader.location_details.name},&nbsp;
              {!!trader.country && trader.country.name}
            </div>
          </Col>
        </Row>
      </Grid>
      <RepresentativesTable
        representatives={representatives}
        isFetchingRepresentatives={isFetchingTraderRepresentatives}
      />
      <Grid className={styles.aggregatorStats}>
        <Row>
          {isFetchingTraderRepresentatives ? (
            <Col sm={3}><Loading isVisible/></Col>
          ) : (
            <Col sm={3}>
              <Row>
                <h5>{t("aggregatorDetails.aggregatorStats.heading")}</h5>
              </Row>
              {
                stats.map(({ label, value }) => (
                  <Row key={`statsField-${label}`}>
                    <span className={styles.boldText}>
                      {t(label)}
                    </span>
                    &nbsp;: {value}
                  </Row>
                ))
              }
            </Col>
          )}
        </Row>
      </Grid>
    </Modal>
  );
};

TraderDetailsModal.propTypes = {
  trader: PropTypes.object,
  isOpen: PropTypes.bool,
  onValidate: PropTypes.func,
  representatives: PropTypes.array,
  isFetchingTraderRepresentatives: PropTypes.bool,
  onClickEdit: PropTypes.func,
  offers: PropTypes.array,
};

export default withRouter(TraderDetailsModal);
