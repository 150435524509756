import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../../components/Modal";
import PropTypes from "prop-types";
import Form from "../Form";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  addExchangeLocationsDetails,
  exchangeLocationsDetails,
  exchangeLocationsDetailsReset,
  updateExchangeLocationsDetails,
} from "../../../../actions";
import { adaptInitialFormValues } from "../../../../utils/wfpManagerConfigurationUtils";

export const ExchangeLocationsEditModal = ({
  initialFormValues,
  isFetching,
  errorMessage,
  modalOptions,
  setModalOptions,
  exchangeLocationsDetails,
  exchangeLocationsDetailsReset,
  exchangeLocationTypes,
  updateExchangeLocationsDetails,
  addExchangeLocationsDetails,
  traders,
  administrativeAreas,
}) => {
  const { t } = useTranslation();
  const buttonRef = useRef();
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    exchangeLocationsDetails(modalOptions.id);
    return () => {
      exchangeLocationsDetailsReset();
    };
  }, [modalOptions.id]);

  useEffect(() => {
    let formValues = adaptInitialFormValues(initialFormValues, modalOptions.id);
    if (formValues) {
      formValues = {
        ...formValues,
        traders: formValues.assignments ? formValues.assignments.reduce((prev, curr) => {
          if (curr.types && !curr.types.length) { return prev; }
          const trader = traders.find(tra => tra.id === curr.trader_organization);
          if (trader) {
            return [...prev, {label: trader.name, value: trader.id}];
          } else {
            return prev;
          }
        }, []) : [],
        assignments: formValues.assignments && formValues.assignments.map(assignment => ({
          id: assignment.trader_organization,
          list: assignment.types,
        })),
      };
    }
    setInitialValues(formValues);
  }, [initialFormValues]);

  const onSubmitEdit = (formValues) => {
    updateExchangeLocationsDetails(modalOptions.id, formValues);
  };

  const onSubmitCreate = (formValues) => {
    addExchangeLocationsDetails(formValues);
  };

  const onSubmit = (formValues) => {
    const body = {
      ...formValues,
      assignments: formValues.assignments ? formValues.assignments.map(assignment => ({
        trader_organization: assignment.id,
        types: assignment.list.filter((type) => formValues.types.some(t => t === type)),
      })) : [],
      location: typeof formValues.location === "number" ? formValues.location : formValues.location.value,
    };

    switch(modalOptions.type){
    case "edit":
      onSubmitEdit(body);
      break;
    case "create":
      onSubmitCreate(body);
      break;
    default:
      break;
    }
    setModalOptions({ type: undefined, id: undefined, isOpen: false });
  };

  return (
    <Modal
      isOpen={modalOptions.isOpen}
      modalLabel={
        t("viewExchangeDetailsModal.exchangeLocation")
      }
      title={
        t("viewExchangeDetailsModal.addNewExchangeLocation")
      }
      onCancel={() => setModalOptions({ type: undefined, isOpen: false, id: undefined })}
      onValidate={() => buttonRef.current.click()}
      hideCancelButton={modalOptions.type === "view" ? true : false}
      validateText={modalOptions.type !== "view" ? t("negotiationCard.confirm") : t("common.back")}
    >
      <Form
        id={modalOptions.id}
        initialValues={initialValues}
        isFetching={isFetching}
        errorMessage={errorMessage}
        modalOptions={modalOptions}
        onSubmit={onSubmit}
        buttonRef={buttonRef}
        exchangeLocationTypes={exchangeLocationTypes}
        traders={traders}
        administrativeAreas={administrativeAreas}
      />
    </Modal>
  );
};

ExchangeLocationsEditModal.defaultProps = {};

ExchangeLocationsEditModal.propTypes = {
  initialFormValues: PropTypes.object,
  isFetching: PropTypes.bool,
  errorMessage: PropTypes.string,
  modalOptions: PropTypes.object,
  setModalOptions: PropTypes.func,
  exchangeLocationsDetails: PropTypes.func,
  exchangeLocationsDetailsReset: PropTypes.func.isRequired,
  updateExchangeLocationsDetails: PropTypes.func.isRequired,
  addExchangeLocationsDetails: PropTypes.func.isRequired,
  exchangeLocationTypes: PropTypes.array.isRequired,
  traders: PropTypes.array.isRequired,
  administrativeAreas: PropTypes.array.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    initialFormValues: state.exchangeLocationsDetail.data,
    isFetching: state.exchangeLocationsDetail.isFetching,
    errorMessage: state.exchangeLocationsDetail.errorMessage,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    exchangeLocationsDetails: (id) => dispatch(exchangeLocationsDetails(id)),
    exchangeLocationsDetailsReset: () => dispatch(exchangeLocationsDetailsReset()),
    addExchangeLocationsDetails: (values) =>
      dispatch(addExchangeLocationsDetails(values, "", true)),
    updateExchangeLocationsDetails: (id, values) =>
      dispatch(updateExchangeLocationsDetails(id, values, "", true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeLocationsEditModal);
