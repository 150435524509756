// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_header_DhgnWzbR5pPJ0RjKqOAt {\n  margin: 0.25rem 1.25rem;\n}\n\n.style_formRow_wmP6wpvWAdXLqjW_qx42 {\n  justify-content: center;\n}", "",{"version":3,"sources":["webpack://./src/containers/Profile/style.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ;;AAEA;EACE,uBAAA;AACF","sourcesContent":[".header {\n    margin: 0.25rem 1.25rem;\n}\n\n.formRow {\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "style_header_DhgnWzbR5pPJ0RjKqOAt",
	"formRow": "style_formRow_wmP6wpvWAdXLqjW_qx42"
};
export default ___CSS_LOADER_EXPORT___;
