import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Loading from "../../../../components/Loading";
import Module from "../../../../components/Module";
import NewTable from "../../../../components/NewTable";
import { displayErrorMessage, findInTree } from "../../../../utils";
import { generateViewButton } from "../../../../utils/table";
import { OfferStatus } from "../../../RepresentativeHome/components/OfferCard/interfaces";
import { Checkbox as WFPCheckbox } from "@wfp/ui";
import style from "./style.scss";

// The list of Aggregators that user can view and delete
export const List = ({
  isFetching,
  isFetchingAggregators,
  errorMessage,
  aggregators,
  page,
  pageSize,
  onPageChange,
  count,
  onClickView,
  onClickEdit,
  administrativeAreas,
  offers,
}) => {
  if (isFetching) {
    return <Loading isVisible />;
  }
  if (errorMessage) {
    return <>{displayErrorMessage(errorMessage)}</>;
  }

  const { t } = useTranslation();

  const getRelevantOffers = (id) =>
    offers.filter((offer) => offer.tagged_aggregator.id === id);

  const columns = [
    {
      Header: t("column.aggregator"),
      accessor: "name",
    },
    {
      id: "administrators",
      Header: t("column.administrators"),
      Cell: (obj) => {
        const record = obj.row.original;
        return (
          <div className={style.administratorCell}>
            {record.administrators.map((administrator) => {
              return (
                <div key={administrator.email}>
                  <span>{`${administrator.person_details.first_name} ${administrator.person_details.last_name}`}</span>
                  <br />
                  <span>{`(${administrator.email})`}</span>
                </div>
              );
            })}
          </div>
        );
      },
    },
    {
      id: "administrativeArea",
      width: 80,
      Header: t("column.administrativeArea"),
      Cell: (obj) => {
        const record = obj.row.original;
        const area = findInTree(administrativeAreas, record.location, "value");
        return (area && area.label) || record.location;
      },
    },
    {
      id: "mcfPartner",
      width: 80,
      Header: t("column.mcfPartner"),
      Cell: (obj) => {
        const record = obj.row.original;
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <WFPCheckbox
              checked={
                record.managed_individuals &&
                record.managed_individuals.some(
                  (ind) => ind === "mastercard_foundation_participants",
                )
              }
              disabled={true}
              hideLabel={true}
            />
          </div>
        );
      },
    },
    {
      Header: t("column.commoditiesInterestInMarket"),
      accessor: "commodities_grown",
      width: 400,
    },
    {
      id: "registered_individuals",
      Header: t("column.registered_individuals"),
      Cell: (obj) => {
        const record = obj.row.original;
        return (
          Number(record.total_no_of_farmers) +
          Number(record.total_no_of_mcf_participants)
        );
      },
      width: 80,
    },
    {
      Header: t("column.femalePercent"),
      accessor: "women_individuals_percentage",
      width: 80,
    },
    {
      id: "depositsCollected",
      width: 80,
      Header: t("column.depositsCollected"),
      Cell: (obj) => obj.row.original.deposits.length,
    },
    {
      id: "offersReceived",
      width: 80,
      Header: t("column.offersReceived"),
      Cell: (obj) => {
        return getRelevantOffers(obj.row.original.id).length;
      },
    },
    {
      id: "exchangesDone",
      width: 80,
      Header: t("column.exchangesDone"),
      alignRight: true,
      Cell: (obj) =>
        getRelevantOffers(obj.row.original.id).filter(
          (o) => o.status === OfferStatus.ExchangeDone,
        ).length,
    },
    // {
    //   Header: "column.farmerPaymentsDone",
    //   accessor: "",
    // },
    {
      id: "viewButton",
      width: 80,
      Cell: (obj) =>
        generateViewButton(obj.row.original, onClickView, undefined, t),
    },
    {
      id: "editButton",
      width: 80,
      Cell: (obj) =>
        generateViewButton(obj.row.original, onClickEdit, t("common.edit"), t),
    },
  ];

  return (
    <>
      <Module
        table={
          <NewTable
            loading={isFetchingAggregators}
            columns={columns}
            data={aggregators}
            totalItems={count}
            pageIndex={page}
            pageSize={pageSize}
            onPageChange={onPageChange}
          />
        }
      />
    </>
  );
};

// propTypes for the List component
List.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  isFetchingAggregators: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  aggregators: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  onClickView: PropTypes.func.isRequired,
  onClickEdit: PropTypes.func.isRequired,
  administrativeAreas: PropTypes.array.isRequired,
  offers: PropTypes.array,
};

// defaultProps for the List component
List.defaultProps = {
  errorMessage: "",
};

const mapStateToProps = (state) => {
  return {
    offers: state.listMarketOffers && state.listMarketOffers.data.results,
  };
};

export default connect(mapStateToProps)(withRouter(List));
