import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col, Grid, Row } from "../../../../components/Grid";
import Loading from "../../../../components/Loading";
import { formatDateTimeDefault } from "../../../../utils";
import { AggregatorAdmin } from "./aggregator-admins-response";
import style from "./style.scss";

export const AdminsTable = ({
  administrators,
  isFetchingAdministrators,
}: {
  administrators: AggregatorAdmin[];
  isFetchingAdministrators: boolean;
}) => {
  const { t } = useTranslation();

  const { admins, mcfPartners } = useMemo(() => {
    return {
      admins: administrators.filter(
        (ad) => ad.account_type === "aggregator_admin"
      ),
      mcfPartners: administrators.filter(
        (ad) => ad.account_type === "mastercard_foundation_admin"
      ),
    };
  }, [administrators]);

  const columns = [
    {
      Header:
        admins.length > 0
          ? t("aggregatorDetails.administratorModal.aggregatorAdmin")
          : t("aggregatorDetails.administratorModal.mcfAdmin"),
      Cell: (admin: AggregatorAdmin) =>
        `${admin.person.first_name} ${admin.person.last_name}`,
      width: 2,
    },
    {
      Header: t("aggregatorDetails.administratorModal.mobile"),
      Cell: (admin: AggregatorAdmin) => admin.person.contact_phone,
      width: 2,
    },
    {
      Header: t("aggregatorDetails.administratorModal.email"),
      Cell: (admin: AggregatorAdmin) => admin.person.contact_email,
      width: 4,
    },
    {
      Header: t("aggregatorDetails.administratorModal.lastSyncEndDate"),
      Cell: (admin: AggregatorAdmin) => {
        return admin.last_synchronization_completed_date
          ? formatDateTimeDefault(
              new Date(admin.last_synchronization_completed_date)
            )
          : "";
      },
      width: 2,
    },
    {
      Header: t("aggregatorDetails.administratorModal.appVersion"),
      Cell: (admin: AggregatorAdmin) => admin.mobile_app_version,
      width: 2,
    },
  ];

  return isFetchingAdministrators ? (
    <Loading isVisible={isFetchingAdministrators} />
  ) : (
    <div className={style.tableContainer}>
      {admins.length > 0 && (
        <>
          <Row className={style.theader}>
            {columns.map((c) => (
              <Col
                className={style.th}
                sm={c.width}
                key={`adminstable_col_${c.Header.toLowerCase()}`}
              >
                {c.Header}
              </Col>
            ))}
          </Row>

          {admins.map((admin, i) => {
            return (
              <Row className={style.trow} key={`adminstable_row_${i}`}>
                {columns.map((c) => (
                  <Col
                    className={style.tcell}
                    sm={c.width}
                    key={`adminstable_col_${c.Header.toLowerCase()}_row_${i}`}
                  >
                    {c.Cell(admin)}
                  </Col>
                ))}
              </Row>
            );
          })}
        </>
      )}

      {mcfPartners.length > 0 && (
        <>
          <Row className={style.theader}>
            {columns.map((c, ind) => (
              <Col
                className={style.th}
                sm={c.width}
                key={`adminstable_col_${c.Header.toLowerCase()}`}
              >
                {admins.length > 0
                  ? ind === 0
                    ? t("aggregatorDetails.administratorModal.mcfAdmin")
                    : ""
                  : c.Header}
              </Col>
            ))}
          </Row>

          {mcfPartners.map((admin, i) => {
            return (
              <Row className={style.trow} key={`adminstable_row_${i}`}>
                {columns.map((c) => (
                  <Col
                    className={style.tcell}
                    sm={c.width}
                    key={`adminstable_col_${c.Header.toLowerCase()}_row_${i}`}
                  >
                    {c.Cell(admin)}
                  </Col>
                ))}
              </Row>
            );
          })}
        </>
      )}
    </div>
  );
};

export default AdminsTable;
