import React from "react";
import PropTypes from "prop-types";

import { displayErrorHelpText } from "../../utils";
import style from "./style.scss";

// A wrapper of the TextInput component form WFP UI Kit
const MultilineInput = ({
  id,
  type,
  label,
  placeholder,
  errorMessage,
  isDisabled,
  maxChars,
  input: { value, onFocus, onChange },
}) => (
  <div className="wfp--form-item">
    {
      label
        ? <label htmlFor={id} className="wfp--label">{label}</label>
        : ""
    }
    <div className={style.wrapper}>
      <textarea
        value={value}
        onFocus={onFocus}
        onChange={(e) => (
          maxChars ?
            onChange(e.target.value.substring(0, maxChars)):
            onChange(e.target.value)
        )}
        id={id}
        name={id}
        type={type}
        placeholder={placeholder}
        className="wfp--text-area"
        disabled={isDisabled}
        rows={2}
      />
      {
        maxChars && (
          <span className={style.charsCount}>
            {maxChars - (value && value.length || 0)}/{maxChars}
          </span>
        )
      }
    </div>
    {displayErrorHelpText(errorMessage)}
  </div>
);

// propTypes for the Input component
MultilineInput.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  input: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  maxChars: PropTypes.number,
};

// defaultProps for the MultilineInput component
MultilineInput.defaultProps = {
  type: "text",
  label: "",
  placeholder: "",
  errorMessage: "",
  isDisabled: false,
  maxChars: undefined,
};

export default MultilineInput;
