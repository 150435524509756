import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { iconAdd } from "@wfp/icons";

import {
  deleteManager,
  listManagers,
  listManagersReset,
  managersCurrentPage,
} from "../../actions";
import Button from "../../components/Button";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import ModalConfirm from "../../components/ModalConfirm";
import PageStandard from "../../components/PageStandard";
import { getCountryObjectFromCache, valueOrDefault } from "../../utils";
import List from "./components/List";

const adaptManagers = (managers) => {
  return managers.map(manager => ({
    ...manager,
    countriesString: manager && manager.countries && manager.countries.map(c => c.code).join(","),
  }));
};

// This component is used to display the list
// of aggregators for the WFP Manager
export const Managers = (
  {
    isFetching,
    errorMessage,
    managers,
    page,
    count,
    listManagers,
    listManagersReset,
    history,
    managersCurrentPage,
    deleteManager,
  },
) => {
  const {t} = useTranslation();
  const [managerToDelete, setManagerToDelete] = useState({});

  // On component mount the data are fetched
  useEffect(() => {
    listManagers(1);
    return () => {
      listManagersReset();
      managersCurrentPage(0);
    };
  }, []);

  // A function used to manage pagination of aggregators page
  const onPageChange = page => {
    managersCurrentPage(page);
    listManagers(page + 1);
  };

  // On click view aggregator the detail is displayed
  const onClickView = record => {
    history.push(`/wfpManager/managerDetails/${record.id}`, { manager: record });
  };

  const onClickDelete = record => {
    setManagerToDelete(record);
  };

  const onClickConfirmDeleteManager = () => {
    deleteManager(managerToDelete.id);
    setManagerToDelete({});
    onPageChange(0);
  };

  const onClickCancelDeleteManager = () => setManagerToDelete({});

  // When user clicks add Aggregator, it is redirected
  // to Aggregator creation page
  const onClickAdd = () => {
    history.push("/wfpManager/managerDetails/0");
  };

  return (
    <PageStandard
      title={
        `${t("managers.pageTitle")} ${t("common.in")} ${getCountryObjectFromCache().name}`
      }
      additional={(
        <ButtonsWrapper>
          <Button icon={iconAdd} onClick={onClickAdd}>{t("managers.newManager")}</Button>
        </ButtonsWrapper>
      )}
    >
      <List
        isFetching={isFetching}
        errorMessage={errorMessage}
        managers={managers}
        page={page}
        onPageChange={onPageChange}
        count={count}
        onClickView={onClickView}
        onClickDelete={onClickDelete}
      />
      <ModalConfirm
        isOpen={managerToDelete.id}
        onValidate={onClickConfirmDeleteManager}
        onCancel={onClickCancelDeleteManager}
        title={t("managers.deleteManagerModalTitle")}
        text={t("managers.deleteManagerModalText", {
          first_name: managerToDelete.first_name,
          last_name: managerToDelete.last_name,
        })}
        isLoading={false}
      />
    </PageStandard>
  );
};

// propTypes for the Aggregators component
Managers.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  managers: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  listManagers: PropTypes.func.isRequired,
  listManagersReset: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  managersCurrentPage: PropTypes.func.isRequired,
  deleteManager: PropTypes.func.isRequired,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = state => {
  const selectedCountry = getCountryObjectFromCache();
  return {
    // Aggregators management
    isFetching: state.listManagers.isFetching,
    errorMessage: state.listManagers.errorMessage,
    managers: adaptManagers(state.listManagers.data.results).filter(
      manager => manager.countries.map(({ id }) => id).includes(selectedCountry.id),
    ), //adapt if needed
    page: state.managersCurrentPage.number,
    count: valueOrDefault(state.listManagers.data.count, 0),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    listManagers: page => dispatch(listManagers({page: page, page_size: 999})),
    listManagersReset: () => dispatch(listManagersReset()),
    managersCurrentPage: page => dispatch(managersCurrentPage(page)),
    deleteManager: (id) => dispatch(deleteManager(id)),
  };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Managers));
