import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { iconWfpHumEmergencyResponsePos } from "@wfp/icons";

import Icon from "../../components/Icon";
import style from "./style.scss";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";

// A page displayed when user navigate to unknown route
export class NotFound extends Component {
  render() {
    const { t } = this.props;

    return (
      <div className={style.wrapper}>
        <div>
          <Icon
            icon={iconWfpHumEmergencyResponsePos}
            width="200"
            height="200"
            style={{ marginBottom: "3rem", transform: "rotate(10deg)" }}
          />
          <h1 className="wfp--story__title">{t("notFound.title")}</h1>
          <p>
            {t("notFound.goBackTo")}{" "}<Link to="/login">{t("notFound.loginPage")}</Link>
          </p>
        </div>
      </div>
    );
  }
}

NotFound.propTypes = {
  t: PropTypes.func.isRequired,
};

// The router is used to allow user to navigate to home page
export default withRouter(withTranslation()(NotFound));
