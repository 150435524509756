import {
  RESOURCE_LIST_REQUEST_,
  RESOURCE_LIST_SUCCESS_,
  RESOURCE_LIST_ERROR_,
  RESOURCE_LIST_RESET_,
} from "../actions/resourceList";

// A generic reducer used to list a resource
const resourceList = resource => (
  state = { isFetching: false, data: { results: [] }, errorMessage: "", isResetting: false },
  action,
) => {
  switch (action.type) {
  case `${RESOURCE_LIST_REQUEST_}${resource}`:
    return { ...state, isFetching: true, data: { results: [] }, errorMessage: "", isResetting: false };
  case `${RESOURCE_LIST_SUCCESS_}${resource}`:{
    return { ...state, isFetching: false, data: action.data, errorMessage: "", isResetting: false };}
  case `${RESOURCE_LIST_ERROR_}${resource}`:
    return {
      ...state,
      isFetching:
            false,
      data: { results: [] }, errorMessage: action.errorMessage, isResetting: false,
    };
  case `${RESOURCE_LIST_RESET_}${resource}`:
    return { ...state, isFetching: false, data: { results: [] }, errorMessage: "", isResetting: true };
  default:
    return state;
  }
};

export default resourceList;
