import React from "react";
import style from "./style.scss";
import Button from "../../../components/Button";
import { Deposit } from "../DepositCard/deposit-dtos";
import { useTranslation } from "react-i18next";
import {
  dateToString,
  formatNumber,
  getCurrencySymbolFromCache,
} from "../../../utils";
import { getLocalisedName } from "../../../utils/i18n";
import { DATE_FORMAT_DEFAULT_WITH_TIME, DATE_FORMAT_ISO_STRING } from "../../../constants";

export const DepositCardHeader = (props: {
  deposit: Deposit;
  userOrg?: number;
  onMakeOffer: (deposit: Deposit) => void;
  onClickAggregatorDetails?: (id: number) => void;
  isSummaryCard?: boolean;
}) => {
  const { t } = useTranslation();

  if (!props.deposit) {
    return <div>Card</div>;
  }

  const depositCurrency = getCurrencySymbolFromCache();

  const getPriceNumber = (): string => {
    return formatNumber(props.deposit.price);
  }

  const formatQuantityNumber = () => {
      return formatNumber(props.deposit.quantity);
  }


  const formatDate = (date: string) => dateToString(
      date,
      DATE_FORMAT_ISO_STRING,
      DATE_FORMAT_DEFAULT_WITH_TIME,
  )

  return (
    <div className={`${style.container} ${props.isSummaryCard && style.withBorder}`}>

    <div className={style.header}>
      <div className={style.depositType}>
        <span>
          {t("farmersMarket.depositCard.deposit")} #{props.deposit.id}
        </span>
        <div className={style.depositAggregatorName}>
          {props.deposit.aggregator.name}
        </div>
      </div>
      <div className={style.depositTitle}>
        <span>
          {t("marketOfferView.details.detailTitle", {
            quantity: formatQuantityNumber(),
            commodity: getLocalisedName(props.deposit.commodity),
            quality: props.deposit.quality,
            price: getPriceNumber(),
            unit: props.deposit.unit_of_measure.code,
            currency: depositCurrency,
          })}
        </span>
        <div className={style.depositLastUpdated}>
          {t("farmersMarket.depositCard.depositDate")}: {formatDate(props.deposit.backend_creation_time)}
        </div>
        <div className={style.actions}>
          {!props.isSummaryCard && (
              !props.deposit.has_direct_offer_same_trader ? (
                  <>
                    <Button
                        className={style.actionButton}
                        small={true}
                        kind={"secondary"}
                        onClick={() => props.onMakeOffer(props.deposit)}
                    >
                      {`${t("farmersMarket.depositCard.makeOffer")}`}
                    </Button>
                  </>
              ) : (
                  <div className={style.organisationHasOffered}>
                    {t("farmersMarket.depositCard.existingOffer")}
                  </div>
              )
          )}
        </div>
      </div>
    </div>
  </div>
  );
};

export default DepositCardHeader;
