import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Grid, Row } from "../../../../components/Grid";
import Spacer from "../../../../components/Spacer";
import Table from "../../../../components/Table";
import { displayErrorMessage } from "../../../../utils";
import PropTypes from "prop-types";
import style from "./style.scss";
import Icon from "../../../../components/Icon";
import {iconClose} from "@wfp/icons";
import { MandatoryLabel } from "../../../../components/MandatoryLabel";

const AggregatorSelection = ({
  aggregators,
  isFetchingAggregators,
  onClickSelectAggregator,
  selectedAggregator,
  availableAggregatorsColumns,
  errorMessageAggregators,
  onPageChangeAggregators,
  aggregatorsCount,
  aggregatorsPage,
  onClickRemoveAggregator,
  isAggregatorsModalOpen,
  selectedCommodity,
}) => {
  const { t } = useTranslation();
  return (
    <Grid>
      <Row>
        <Col sm={12}>
          <Spacer height="0.75rem" />
          <MandatoryLabel
            label={
              <div className={style.selectedAggregatorsTitle}>
                {t("marketOfferDetails.form.taggedAggregator")}
              </div>
            }
          />
          <Spacer height="0.75rem" />
        </Col>
      </Row>
      {isAggregatorsModalOpen && <Row>
        <Col sm={12}>
          <Grid>
            <Row>
              <Col sm={12}>
                <Spacer height="1.5rem" />
                {displayErrorMessage(errorMessageAggregators)}
                <Table
                  loading={isFetchingAggregators}
                  columns={availableAggregatorsColumns(
                    onClickSelectAggregator,
                    selectedAggregator,
                    selectedCommodity,
                  )}
                  data={aggregators}
                  totalItems={aggregatorsCount}
                  page={aggregatorsPage}
                  onPageChange={onPageChangeAggregators}
                  pageSize={10}
                />
                <Spacer height="1.5rem" />
              </Col>
              {
                <Col sm={12}>
                  <div className={style.selectedAggregatorsItems}>
                    {selectedAggregator && selectedAggregator.id && (
                      <>
                        {
                          <div
                            className={style.selectedAggregatorsItem}
                            key={selectedAggregator.id}
                          >
                            <span className={style.selectedAggregatorTitle}>
                              {t("marketOfferDetails.form.selectedAggregator")}
                            </span>
                            <span>{selectedAggregator.name}</span>
                            <button
                              className={style.selectedAggregatorsRemove}
                              onClick={() =>
                                onClickRemoveAggregator(selectedAggregator.id)
                              }
                            >
                              <Icon
                                width="7"
                                height="7"
                                fill="#333333"
                                icon={iconClose}
                              />
                            </button>
                          </div>
                        }
                      </>
                    )}
                  </div>
                </Col>
              }
            </Row>
          </Grid>
          <Spacer height="1rem" />
        </Col>
      </Row>}
    </Grid>
  );
};

AggregatorSelection.propTypes = {
  aggregators: PropTypes.array.isRequired,
  isFetchingAggregators: PropTypes.bool.isRequired,
  onClickSelectAggregator: PropTypes.func.isRequired,
  selectedAggregator: PropTypes.object,
  availableAggregatorsColumns: PropTypes.func.isRequired,
  errorMessageAggregators: PropTypes.string.isRequired,
  onPageChangeAggregators: PropTypes.func.isRequired,
  aggregatorsCount: PropTypes.number.isRequired,
  aggregatorsPage: PropTypes.number.isRequired,
  onClickRemoveAggregator: PropTypes.func.isRequired,
  isAggregatorsModalOpen: PropTypes.bool.isRequired,
  selectedCommodity: PropTypes.object,
};

export default AggregatorSelection;
