import moment from "moment";
import { DELIVERY_LOCATIONS } from "../../../../../constants";
import { formatNumber } from "../../../../../utils";
import { CustomDeliveryLocationDetails } from "../../OfferCard/interfaces";

export interface NegotiationProposal {
  user: {
    id: number,
    first_name: string,
    last_name: string,
    roles: string[],
  }

  uuid: string;
  // Unique identifier for the object
  mobile_creation_time?: string;
  // When the object was created on the mobile
  mobile_last_change_time?: string;
  // When the object was last changed on the mobile

  minimum_quantity: number;
  maximum_quantity: number;

  price: number;
  planned_exchange_time?: string;
  // The time that the exchange for this offer is planned to take place
  delivery_location: string;
  text: string;
  responded: boolean;
  // Has the target user responded to this proposal?
  trader_market_offer: number;
  // Which offer is this proposal for?
  backend_creation_time: string;
  frontend_creation_time: string;
  proposer: 'aggregator' | 'trader';
  proposal_type?: string;
  custom_delivery_location_details?: CustomDeliveryLocationDetails;
  custom_delivery_location?: number;
  custom_delivery_location_type?: number;
}


export const makeProposal = (
  proposal: Partial<NegotiationProposal>,
  trader: {
    physical_address: string
  },
  aggregator:{
    physical_address: string
  },
  t: Function,
  customExchangeLocations: [],
) => {
  let addressHolder: string = "";
  let value;

  switch(proposal.delivery_location){
    case "drop_at_trader":
      const traderDeliveryLocationType = DELIVERY_LOCATIONS.find(d => d.value === "drop_at_trader");
      value = "drop_at_trader";
      const traderPhysicalAddress = trader.physical_address
      addressHolder = traderDeliveryLocationType ? `${t(traderDeliveryLocationType.translationKey)}: `: "";
      addressHolder = traderPhysicalAddress ? addressHolder + traderPhysicalAddress : "" ;
      break
    case "pick_from_aggregator":
      const aggregatorDeliveryLocationType = DELIVERY_LOCATIONS.find(d => d.value === "pick_from_aggregator");
      value = "pick_from_aggregator";
      const aggregatorPhysicalAddress = aggregator.physical_address
      addressHolder = aggregatorDeliveryLocationType ? `${t(aggregatorDeliveryLocationType.translationKey)}: `: "";
      addressHolder = aggregatorPhysicalAddress ? addressHolder + aggregatorPhysicalAddress : "" ;
      break
    case "custom_exchange_location":
      const customExchangeLocation = customExchangeLocations.find((loc: any) => loc.id === proposal.custom_delivery_location) as any;
      value = {
        deliveryLocationType: "custom_exchange_location",
        custom_delivery_location: customExchangeLocation.id
      };
      const name = customExchangeLocation?.name
      const physical_address = customExchangeLocation?.physical_address
      addressHolder = `${name}, ${physical_address}`;
      break
    default:
      addressHolder = "";
      break
  }
  const label = addressHolder && addressHolder !== "" ? addressHolder : "Name unavailable";
  return {
    price:  proposal.price && formatNumber(proposal.price, false),
    minimum_quantity: proposal.minimum_quantity && formatNumber(proposal.minimum_quantity, false),
    maximum_quantity: proposal.maximum_quantity && formatNumber(proposal.maximum_quantity, false),
    planned_exchange_time: moment(proposal.planned_exchange_time).toDate(),
    delivery_location: {
      label, value,
    },
    text: ""
}};
