import React from "react";

export default () => (
  <svg
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3274_66589)">
      <path
        d="M9.64282 5C9.64282 8.5 5.14282 11.5 5.14282 11.5C5.14282 11.5 0.642822 8.5 0.642822 5C0.642822 3.80653 1.11693 2.66193 1.96084 1.81802C2.80476 0.974106 3.94935 0.5 5.14282 0.5C6.3363 0.5 7.48089 0.974106 8.3248 1.81802C9.16872 2.66193 9.64282 3.80653 9.64282 5Z"
        stroke="#5A6872"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.14282 6.5C5.97125 6.5 6.64282 5.82843 6.64282 5C6.64282 4.17157 5.97125 3.5 5.14282 3.5C4.3144 3.5 3.64282 4.17157 3.64282 5C3.64282 5.82843 4.3144 6.5 5.14282 6.5Z"
        stroke="#5A6872"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3274_66589">
        <rect width="10.2857" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
