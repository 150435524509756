import { InputGroup, RadioButton } from "@wfp/ui";
import styles from "./style.scss";
import { displayErrorHelpText } from "../../utils";

interface Option {
  id: string | number;
  name: string;
}

interface Props {
  label: string;
  helperText: string;
  name: string;
  defaultValue?: string | number;
  options: Option[];
  input: { value?: string | number; onChange?: (val: string | number) => void };
  meta: { error?: string };
  errorMessage?: string;
}

export const Radio = ({
  name,
  label,
  helperText,
  options,
  input: { value, onChange },
  meta: { error },
  errorMessage,
}: Props) => {
  console.log(errorMessage);

  return (
    <div className={styles.container}>
      <InputGroup
        style={{ flexDirection: "column" }}
        name={name}
        helperText={helperText}
        labelText={label}
      >
        {options.map((option) => (
          <RadioButton
            key={`${option.id}`}
            value={option.id}
            id={`${option.id}`}
            labelText={option.name}
            name={option.name}
            checked={value === option.id}
            onChange={() => onChange && onChange(option.id)}
          />
        ))}
      </InputGroup>
      {displayErrorHelpText(errorMessage)}
    </div>
  );
};
