/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { iconAdd } from "@wfp/icons";
import { connect } from "react-redux";
import PageStandard from "../../components/PageStandard";
import { getDeletedItemName, valueOrDefault } from "../../utils";
import List from "./components/List";
import PropTypes from "prop-types";
import { deletePaymentMethodsDetails, listPaymentMethods, listPaymentMethodsReset, paymentMethodsDetailsReset } from "../../actions";

import PaymentMethodsEditModal from "./components/Modal";
import Modal from "../../components/Modal";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import Button from "../../components/Button";

export const WfpManagerPaymentMethodsView = ({
  isFetching,
  errorMessage,
  paymentMethods,
  page,
  count,
  listPaymentMethods,
  listPaymentMethodsReset,
  paymentMethodsDetailsReset,
  deletePaymentMethodsDetails,
}) => {
  const { t } = useTranslation();
  const [modalOptions, setModalOptions] = useState({isOpen: false, id: undefined, type: undefined});
  const [deleteModalOptions, setDeleteModalOptions] = useState({isOpen: false, id: undefined});

  // On component mount the data are fetched
  useEffect(() => {
    listPaymentMethods();
    return () => {
      listPaymentMethodsReset();
    };
  }, []);

  const onClickAdd = () => {
    paymentMethodsDetailsReset();
    setModalOptions({isOpen: true, id: "add", type: "create"});
    listPaymentMethods();
  };

  const onDeleteSubmit = () => {
    deletePaymentMethodsDetails(deleteModalOptions.id);
    setDeleteModalOptions({isOpen: false, id: undefined});
    listPaymentMethods();
  };

  return (
    <PageStandard
      additional={(
        <ButtonsWrapper>
          <Button icon={iconAdd} onClick={onClickAdd}>
            {t("paymentMethods.newPaymentMethods")}
          </Button>
        </ButtonsWrapper>
      )}
      title={t("configuration.item.paymentMethods")}
    >
      <List
        {...{
          isFetching,
          errorMessage,
          paymentMethods,
          page,
          count,
          modalOptions,
          setModalOptions,
          setDeleteModalOptions,
        }}
      />
      <PaymentMethodsEditModal
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
      />
      <Modal
        isOpen={deleteModalOptions.isOpen}
        onValidate={onDeleteSubmit}
        title={t("common.delete") + " " + t("configuration.item.paymentMethods")}
        danger={true}
        onCancel={() => setDeleteModalOptions({isOpen: false, id: undefined})}
        isLoading={false}
      >
        {t("configuration.common.deleteText") + getDeletedItemName(deleteModalOptions.id, paymentMethods, "name")}
      </Modal>
    </PageStandard>
  );
};

WfpManagerPaymentMethodsView.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  listPaymentMethods: PropTypes.func.isRequired,
  listPaymentMethodsReset: PropTypes.func.isRequired,
  paymentMethodsDetailsReset: PropTypes.func.isRequired,
  deletePaymentMethodsDetails: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    paymentMethods: state.listPaymentMethods.data.results,
    isFetching: state.listPaymentMethods.isFetching,
    errorMessage: state.listPaymentMethods.errorMessage,
    page: 1,
    count: valueOrDefault(state.listPaymentMethods.data.results.length, 0),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    listPaymentMethods: () => dispatch(listPaymentMethods()),
    listPaymentMethodsReset: () => dispatch(listPaymentMethodsReset()),
    paymentMethodsDetailsReset: () => dispatch(paymentMethodsDetailsReset()),
    deletePaymentMethodsDetails: id => dispatch(deletePaymentMethodsDetails(id, true)),
  };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(WfpManagerPaymentMethodsView);
