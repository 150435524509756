import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../../components/Modal";
import PropTypes from "prop-types";
import Form from "../Form";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  addFarmerIdentificationDetails,
  farmerIdentificationDetails,
  farmerIdentificationDetailsReset,
  updateFarmerIdentificationDetails,
} from "../../../../actions";
import { getTitleName } from "../../../../utils/wfpManagerConfigurationUtils";

const adaptInitialFormValues = (data) => {
  const object = Object.prototype.hasOwnProperty.call(data, "results")
    ? data.results[0]
    : { ...data };
  const format = object ? object.value_format : undefined ;
  const returnData = format ? {
    ...object,
    value_format: {
      label: format && format.charAt(0).toUpperCase() + format.slice(1).toLowerCase(),
      value: format,
    },
  }: {};
  return returnData;
};

const adaptFormValues = (formValues) => {
  const format = formValues.value_format;
  return { ...formValues, value_format: format.value };
};

export const FarmerIdentificationEditModal = ({
  initialFormValues,
  isFetching,
  errorMessage,
  modalOptions,
  setModalOptions,
  farmerIdentificationDetails,
  farmerIdentificationDetailsReset,
  updateFarmerIdentificationDetails,
  addFarmerIdentificationDetails,
}) => {
  const { t } = useTranslation();
  const buttonRef = useRef();
  const [initialValues, setInitialValues] = useState( modalOptions.type === "create" ? undefined : initialFormValues);

  useEffect(() => {
    farmerIdentificationDetails(modalOptions.id);
    return () => {
      farmerIdentificationDetailsReset();
    };
  }, [modalOptions.id]);

  useEffect(() => {
    setInitialValues(modalOptions.type === "create" ? undefined : initialFormValues);
  }, [initialFormValues]);

  const onSubmitEdit = (formValues) => {
    const updatedValues = adaptFormValues(formValues);
    updateFarmerIdentificationDetails(modalOptions.id, updatedValues);
  };

  const onSubmitCreate = (formValues) => {
    const updatedValues = adaptFormValues(formValues);
    addFarmerIdentificationDetails(updatedValues);
  };

  const onSubmit = (formValues) => {
    switch(modalOptions.type){
    case "edit":
      onSubmitEdit(formValues);
      break;
    case "create":
      onSubmitCreate(formValues);
      break;
    default:
      break;
    }
    setModalOptions({ type: undefined, id: undefined, isOpen: false });
  };

  return (
    <Modal
      isOpen={modalOptions.isOpen}
      title={
        t("configuration.item.farmerIdentification") + " " + t(getTitleName(modalOptions.type))
      }
      onCancel={() => setModalOptions({ type: undefined, isOpen: false, id: undefined })}
      onValidate={() => buttonRef.current.click()}
      hideCancelButton={modalOptions.type === "view" ? true : false}
      validateText={modalOptions.type !== "view" ? t("negotiationCard.confirm") : t("common.back")}
    >
      <Form
        id={modalOptions.id}
        initialValues={initialValues}
        isFetching={isFetching}
        errorMessage={errorMessage}
        modalOptions={modalOptions}
        onSubmit={onSubmit}
        buttonRef={buttonRef}
      />
    </Modal>
  );
};

FarmerIdentificationEditModal.defaultProps = {};

FarmerIdentificationEditModal.propTypes = {
  initialFormValues: PropTypes.object,
  isFetching: PropTypes.bool,
  errorMessage: PropTypes.string,
  modalOptions: PropTypes.object,
  setModalOptions: PropTypes.func,
  farmerIdentificationDetails: PropTypes.func.isRequired,
  farmerIdentificationDetailsReset: PropTypes.func.isRequired,
  updateFarmerIdentificationDetails: PropTypes.func.isRequired,
  addFarmerIdentificationDetails: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    initialFormValues: state.farmerIdentificationDetail.data
      ? adaptInitialFormValues(state.farmerIdentificationDetail.data)
      : undefined,
    isFetching: state.farmerIdentificationDetail.isFetching,
    errorMessage: state.farmerIdentificationDetail.errorMessage,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    farmerIdentificationDetails: (id) => dispatch(farmerIdentificationDetails(id)),
    farmerIdentificationDetailsReset: () => dispatch(farmerIdentificationDetailsReset()),
    addFarmerIdentificationDetails: (values) =>
      dispatch(addFarmerIdentificationDetails(values, "", true)),
    updateFarmerIdentificationDetails: (id, values) =>
      dispatch(updateFarmerIdentificationDetails(id, values, "", true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FarmerIdentificationEditModal);
