import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Loading from "../../../../components/Loading";
import Module from "../../../../components/Module";
import Table from "../../../../components/Table";
import { displayErrorMessage } from "../../../../utils";
import { boolIconCell, generateDateTimeByUserCell, generateViewButton } from "../../../../utils/table";
import { withRouter } from "react-router-dom";

// The list of Aggregators that user can view and delete
export const List = ({
  isFetching,
  errorMessage,
  aggregatorUmbrellaOrganizations,
  administrativeAreas,
  administrativeAreaTypes,
  aggregators,
  page,
  count,
  setModalOptions,
}) => {
  const { t } = useTranslation();

  if (isFetching) {
    return <Loading isVisible />;
  }

  if (errorMessage) {
    return <>{displayErrorMessage(errorMessage)}</>;
  }

  const columns = [
    {
      Header: "configuration.common.name",
      accessor: "name",
      width: 800,
    },
    {
      Header: "aggregatorUmbrellaOrganizations.location",
      accessor: "location",
      width: 400,
      Cell: ({value}) => {
        const area = administrativeAreas.find(area => area.id === value);
        let returnString = "---";
        if(area){
          const levelID = area.level;
          returnString = area.name;
          if(levelID){
            const areaType = administrativeAreaTypes.find(type => type.level === levelID);
            returnString = areaType ? returnString + ` (${areaType.name})` : returnString;
          }
        }
        return returnString;
      },
    },
    {
      Header: "aggregatorUmbrellaOrganizations.aggregators",
      accessor: "aggregators",
      width: 400,
      Cell: ({row: {original}}) => {
        const aggregatorList = aggregators.filter(aggregator => aggregator.umbrella_organization === original.id);
        if(aggregatorList.length === 0)return "---";
        return aggregatorList.map((aggregator, index) => {
          return (
            <div key={aggregator.id} style={{marginTop: index !== 0 && "8px"}}>
              {aggregator.name}
            </div>
          );
        });
      },
    },
    {
      Header: "configuration.list.column.lastUpdated",
      accessor: "lastUpdated",
      width: 400,
      Cell: ({ row: { original } }) => generateDateTimeByUserCell(original.last_update_datetime, original.last_update_user, t),
    },
    {
      Header: "configuration.list.column.created",
      accessor: "created",
      width: 400,
      Cell: ({ row: { original } }) => generateDateTimeByUserCell(original.creation_datetime, original.creation_user, t),
    },
    {
      Header: "configuration.common.active",
      accessor: "active",
      Cell: boolIconCell,
    },
    {
      width: 80,
      accessor: "view",
      Cell: ({row: { original: record }}) => generateViewButton(
        record,
        () => setModalOptions({id: record.id, isOpen: true, type: "view"}),
        "View",
        t,
      ),
      Header: "",
    },
    {
      width: 80,
      accessor: "view",
      Cell: ({row: { original: record }}) => generateViewButton(
        record,
        () => setModalOptions({id: record.id, isOpen: true, type: "edit"}),
        "Edit",
        t,
      ),
      Header: "",
    },
  ];

  return (
    <>
      <Module
        table={
          <Table
            {...{
              columns,
              data: aggregatorUmbrellaOrganizations,
              loading: isFetching,
              page,
              totalItems: count,
            }}
          />
        }
      />
    </>
  );
};

// propTypes for the List component
List.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  aggregatorUmbrellaOrganizations: PropTypes.list,
  administrativeAreas: PropTypes.list,
  administrativeAreaTypes: PropTypes.list,
  aggregators: PropTypes.list,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  setModalOptions: PropTypes.func.isRequired,
  setDeleteModalOptions: PropTypes.func.isRequired,
};

// defaultProps for the List component
List.defaultProps = {
  errorMessage: "",
};

export default withTranslation()(withRouter(List));
