import React from "react";
import { reduxForm, Field, InjectedFormProps } from "redux-form";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { iconInfoOutline } from "@wfp/icons";
import { listRecallReasons } from "../../../../actions";
import FormWrapper from "../../../../components/FormWrapper";
import Icon from "../../../../components/Icon";
import Input from "../../../../components/Input";
import { MandatoryLabel } from "../../../../components/MandatoryLabel";
import Modal from "../../../../components/Modal";
import Select from "../../../../components/Select";
import { OFFER_TYPES } from "../../../../constants";
import { adaptOptions } from "../../../../utils/adapters";
import OfferCard from "../OfferCard";
import { Offer } from "../OfferCard/interfaces";
import style from "./style.scss";

export interface SendingBackProps {
  showSendingBackExchangeModal: boolean;
  id: number;
  offers: Offer[];
  onCancel: () => void;
  onValidateSendingBack: (offer: Offer) => void;
  listRecallReasons: () => void;
  t: (s: string) => string;
  reasons: any[];
}

class SendingBackExchange extends React.Component<InjectedFormProps<any, SendingBackProps> & SendingBackProps> {
  componentDidMount(){
    this.props.listRecallReasons();
  }

  render() {
    const { showSendingBackExchangeModal, onValidateSendingBack, onCancel, id, t } = this.props;

    const onValidate = () => {
      const offerToRecall = this.props.offers.find((o) => o.id === this.props.id);
      if (offerToRecall) {
        onValidateSendingBack(offerToRecall);
      }
      onCancel && onCancel();
    };

    const offerToSendingBack = this.props.offers.find((o) => o.id === this.props.id);

    const offerTypeHeader = () => {
      if (offerToSendingBack) {
        const translation = (offerToSendingBack.offer_type === OFFER_TYPES.MARKET_OFFER)
            ? t("marketOfferView.marketOffer")
            : t("marketOfferView.directOffer");
        return translation.toUpperCase();
      } else {
        return "";
      }
    };

    return (
      <>
        <Modal
          danger={false}
          modalLabel={
            <>
              <div>
                <Icon width="42" height="42" icon={iconInfoOutline}/>
              </div>
              <div>
                {offerTypeHeader()} #{id}
              </div>
            </>
          }
          isOpen={showSendingBackExchangeModal}
          validateText={t("sendingBackExchangeModal.submitButtonText")}
          title={t("sendingBackExchangeModal.heading")}
          onValidate={onValidate}
          onCancel={onCancel}
          className={style.dangerModal}
        >
          <div className={style.instructionText}>
            {t("sendingBackExchangeModal.instructionText")}
          </div>
          {offerToSendingBack && (
            <OfferCard
              onSeeProgressClick={() => {}}
              onClickAggregatorDetails={() => {}}
              onShowRecall={() => {}}
              offer={offerToSendingBack}
              onClickMakeOffer={() => {}}
              hideActions={true}
            />
          )}
          <FormWrapper>
            <Field
              component={Input}
              name="send_back_comments"
              id="send_back_comments"
              label={t("sendingBackExchangeModal.comments")}
            />
          </FormWrapper>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    reasons: state.listRecallReasons && state.listRecallReasons.data.results,
  };
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    listRecallReasons: () => dispatch(listRecallReasons()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(
  reduxForm<any, SendingBackProps>({
    form: "sendingBackExchange",
    enableReinitialize: true,
  })(SendingBackExchange))
);
