import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PageStandard from "../../components/PageStandard";
import { valueOrDefault } from "../../utils";
import List from "./components/List";
import PropTypes from "prop-types";
import { listUnitsOfMeasure, listUnitsOfMeasureReset, unitsOfMeasureDetailsReset } from "../../actions";
import { filterUnitsOfMeasureByType } from "../../utils/adapters";
// import ButtonsWrapper from "../../components/ButtonsWrapper";
// import Button from "../../components/Button";
// import { iconAdd } from "@wfp/icons";
import LandUnitsOfMeasureEditModal from "./components/Modal";

export const WfpManagerLandUnitsOfMeasureView = ({
  isFetching,
  errorMessage,
  landUnitsOfMeasure,
  page,
  count,
  listUnitsOfMeasure,
  listUnitsOfMeasureReset,
  // unitsOfMeasureDetailsReset,
}) => {
  const { t } = useTranslation();
  const [modalOptions, setModalOptions] = useState({isOpen: false, id: undefined, type: undefined});

  // On component mount the data are fetched
  useEffect(() => {
    listUnitsOfMeasure();
    return () => {
      listUnitsOfMeasureReset();
    };
  }, []);

  // TODO: Units of measure are linked to commodity which is linked to a country.
  // We do not want to allow users to create units of measure globally.
  // This will be integrated into the commodities
  // const onClickAdd = () => {
  //   unitsOfMeasureDetailsReset();
  //   setModalOptions({isOpen: true, id: "add", type: "create"});
  //   listUnitsOfMeasure();
  // };

  return (
    <PageStandard
      // additional={(
      //   <ButtonsWrapper>
      //     <Button icon={iconAdd} onClick={onClickAdd}>
      //       {t("common.add") + " " + t("configuration.item.landUnitsOfMeasure")}
      //     </Button>
      //   </ButtonsWrapper>
      // )}
      title={t("configuration.item.landUnitsOfMeasure")}
    >
      <List
        {...{
          isFetching,
          errorMessage,
          landUnitsOfMeasure,
          page,
          count,
          setModalOptions,
        }}
      />
      <LandUnitsOfMeasureEditModal
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
      />
    </PageStandard>
  );
};

WfpManagerLandUnitsOfMeasureView.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  landUnitsOfMeasure: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  listUnitsOfMeasure: PropTypes.func.isRequired,
  listUnitsOfMeasureReset: PropTypes.func.isRequired,
  unitsOfMeasureDetailsReset: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  const data = filterUnitsOfMeasureByType(state, "LAND");
  return {
    landUnitsOfMeasure: data,
    isFetching: state.listUnitsOfMeasure.isFetching,
    errorMessage: state.listUnitsOfMeasure.errorMessage,
    page: 1,
    count: valueOrDefault(data.length, 0),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    listUnitsOfMeasure: () => dispatch(listUnitsOfMeasure()),
    listUnitsOfMeasureReset: () => dispatch(listUnitsOfMeasureReset()),
    unitsOfMeasureDetailsReset: () => dispatch(unitsOfMeasureDetailsReset()),
  };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(WfpManagerLandUnitsOfMeasureView);
