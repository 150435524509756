import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { isTokenExpired, isUserWfpManager, redirectToCorrectLocation, setupClient, setupCountry } from "../../utils/authentication";
import WfpManagerHeader from "../WfpManagerHeader";
import WfpManagerFooter from "../WfpManagerFooter";
import HelpMessageRoute from "../HelpMessageRoute";
import PageInformation from "../../components/PageInformation";
import {withTranslation} from "react-i18next";

// A class to render a route protected by login
export class WfpManagerRoute extends Component {
  // By default the content is loading and the user is considered as not logged in
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isLoggedIn: false,
    };
  }

  // Here we check if the authentication token exists
  // and if user profile is WFP Manager
  componentDidMount() {
    const ciamAccessToken = localStorage.getItem("ciam_access_token");
    const userProfile = localStorage.getItem("user_profile");
    const selectedCountry = localStorage.getItem("selected_country");
    const expiryDate = new Date(localStorage.getItem("expiry_date"));
    if (
      !ciamAccessToken ||
      !userProfile ||
      isTokenExpired(expiryDate)
    ){
      this.setState({ isLoading: false, isLoggedIn: false });
      this.props.history.push("/login");
    }else if(
      !isUserWfpManager(userProfile)
    ) {
      redirectToCorrectLocation(userProfile, this.props.history);
    } else {
      setupClient(ciamAccessToken);
      setupCountry(selectedCountry);
      this.setState({ isLoading: false, isLoggedIn: true });
    }
  }

  // The component is rendered based on state
  // if there is a network error or the page is loading, a message is displayed
  // if the user is logged in, the component is displayed
  // if there are no errors but the user is not logged in, a redirecting message is displayed
  render() {
    const {t} = this.props;

    if (this.state.isLoading) {
      return (
        <PageInformation
          title={t("route.checkingUser.title")}
          text={t("route.checkingUser.text")}
        />
      );
    }

    if (this.state.isLoggedIn) {
      if (this.props.fullScreen) {
        return <HelpMessageRoute key={this.props.path} {...this.props} />;
      }
      return (
        <div>
          <WfpManagerHeader />
          <HelpMessageRoute key={this.props.path} {...this.props} />
          <WfpManagerFooter />
        </div>
      );
    }

    return (
      <PageInformation
        title={t("route.redirect.title")}
        text={t("route.redirect.text")}
      />
    );
  }
}

// propTypes for the WfpManagerRoute component
WfpManagerRoute.propTypes = {
  history: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool,
  path: PropTypes.string,
  t: PropTypes.func.isRequired,
};

// defaultProps for the WfpManagerRoute component
WfpManagerRoute.defaultProps = {
  fullScreen: false,
};

export default withTranslation()(withRouter(WfpManagerRoute));
