import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { iconEmail, iconChat } from "@wfp/icons";

import { marketOfferDetails } from "../../actions";
import PageStandard from "../../components/PageStandard";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import Button from "../../components/Button";
import Details from "./components/Details";
import { isEmpty, redirect } from "../../utils";
import { adaptMarketOffer } from "../../utils/adapters";
import {useTranslation} from "react-i18next";

// A function to extract the email from market offer
export const getEmailFromMarketOffer = marketOffer => (
  marketOffer.trader &&
  marketOffer.trader.contact_email ||
  ""
);

// This component is used to display the detail
// of a Market Offer in read only
export const MarketOfferView = (
  {
    id,
    marketOfferDetails,
    isFetchingMarketOffer,
    errorMessageMarketOffer,
    marketOffer,
    email,
    history,
  },
) => {
  // The part of state used to decide if the email button
  // is visible, according to uer type
  const [isEmailVisible, setIsEmailVisible] = useState(false);

  // The part of state used to decide if the send sms button
  // is visible, according to uer type
  const [isSmsVisible, setIsSmsVisible] = useState(false);

  const {t} = useTranslation();

  // On component mount the data are fetched
  useEffect(() => {
    marketOfferDetails(id);
  }, []);

  // When the component receives the email of trader
  // it decides if display the button to send an email
  // to te trader if user is a WFP Manger
  // and the trader has an email
  useEffect(() => {
    if (!email) {
      setIsEmailVisible(false);
    } else {
      const userProfile = localStorage.getItem("user_profile");
      if (userProfile === "wfp_manager") {
        setIsEmailVisible(true);
      }
      if (userProfile === "representative") {
        setIsSmsVisible(true);
      }
    }
  }, [email]);

  // On click send email a mailto is performed
  const onClickSendEmail = () => {
    redirect(`mailto:${email}`);
  };
    // On click send sms the sms send page is displayed
  const onClickSendSms = () => {
    history.push(`/representative/marketOfferSms/${id}`);
  };

  // On click view Aggregator details, user is redirected
  // to the right page based on user profile
  const onClickViewAggregator = (record) => {
    if(!record) {
      return;
    }
    const userProfile = localStorage.getItem("user_profile");
    if (userProfile === "wfp_manager") {
      history.push(`/wfpManager/wfpManagerAggregatorView/${record.id}`);
    } else {
      history.push(`/representative/representativeAggregatorView/${record.id}`);
    }
  };

  return (
    <PageStandard
      title={t("marketOfferView.title")}
      additional={
        <ButtonsWrapper>
          {
            isEmailVisible && (
              <Button icon={iconEmail} onClick={onClickSendEmail}>Contact Trader</Button>
            )
          }
          {
            isSmsVisible && (
              <Button icon={iconChat} onClick={onClickSendSms}>Send SMS</Button>
            )
          }
        </ButtonsWrapper>
      }
    >
      <Details
        isFetchingMarketOffer={isFetchingMarketOffer}
        errorMessageMarketOffer={errorMessageMarketOffer}
        marketOffer={marketOffer}
        onClickViewAggregator={() => onClickViewAggregator(marketOffer.tagged_aggregator)}
      />
    </PageStandard >
  );
};

// propTypes for the MarketOfferView component
MarketOfferView.propTypes = {
  id: PropTypes.string.isRequired,
  marketOfferDetails: PropTypes.func.isRequired,
  isFetchingMarketOffer: PropTypes.bool.isRequired,
  errorMessageMarketOffer: PropTypes.string.isRequired,
  marketOffer: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = (state, props) => {
  return {
    // Properties related to market offer details
    id: props.match.params.id,
    isFetchingMarketOffer:
      state.marketOfferDetails.isFetching ||
      isEmpty(state.marketOfferDetails.data),
    errorMessageMarketOffer: state.marketOfferDetails.errorMessage,
    marketOffer: adaptMarketOffer(state.marketOfferDetails.data),
    // Email management
    email: getEmailFromMarketOffer(state.marketOfferDetails.data),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    marketOfferDetails: id => dispatch(marketOfferDetails(id)),
  };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(MarketOfferView);
