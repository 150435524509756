import React from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { Footer } from "@wfp/ui";
import style from "./style.scss";
/**
 * A wrapper of the Footer component included in de WFP UI Kit
 */
export const RepresentativeFooterComponent = () => {

  const { t } = useTranslation();
  const version = localStorage.getItem("version") || "";
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear() || `Invalid date, got: ${currentDate}`;

  return (
    <Footer pageWidth="full" metaContent={t("footer.text", { currentYear: currentYear })}>
      <div className="wfp--footer-info">
        <div className="wfp--footer__info">
          <div className="wfp--footer__info__item">
            <p className="wfp--footer__label">
              {t("common.home")}
            </p>
            <ul className="wfp--footer__list">
              <li>
                <a href="https://cdn.wfp.org/legal/terms/">
                  {t("common.termsAndConditions")}
                </a>
              </li>
              <li className={style.version}>
                {t("footer.version", { version })}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Footer>
  );
};

// withRouter is needed because the component need to use the Link component of react-router-dom
export default withRouter(RepresentativeFooterComponent);
