// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_adminsTable_lvnG67_4wmjqtq3dnHtS {\n  font-family: \"Open Sans\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 140%;\n  color: #5a6872;\n}\n\n.style_theader_Yi5F1QSD0NKB2R8P6X4y,\n.style_trow_Q3AzawQFnwqxQhMUG_EG {\n  padding: 17px 16px;\n}\n\n.style_theader_Yi5F1QSD0NKB2R8P6X4y {\n  font-weight: 600;\n}\n\n.style_th_GZ9TX7SP4cGMKwFt1xbu {\n  border-right: 0.8px solid #c5cdd2;\n  overflow: hidden;\n}\n\n.style_trow_Q3AzawQFnwqxQhMUG_EG {\n  border-bottom: 1px solid #dae9f4;\n}\n\n.style_tcell_v9GqNTgTrOSKSanE8cUb {\n  padding-left: 2px;\n  overflow: hidden;\n}", "",{"version":3,"sources":["webpack://./src/containers/TraderDetailsModel/components/RepresentativesTable/style.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AACF;;AAEA;;EAEE,kBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,iCAAA;EACA,gBAAA;AACF;;AACA;EACE,gCAAA;AAEF;;AAAA;EACE,iBAAA;EACA,gBAAA;AAGF","sourcesContent":[".adminsTable {\n  font-family: \"Open Sans\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 140%;\n  color: #5a6872;\n}\n\n.theader,\n.trow {\n  padding: 17px 16px;\n}\n\n.theader {\n  font-weight: 600;\n}\n\n.th {\n  border-right: 0.8px solid #c5cdd2;\n  overflow: hidden;\n}\n.trow {\n  border-bottom: 1px solid #dae9f4;\n}\n.tcell {\n  padding-left: 2px;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adminsTable": "style_adminsTable_lvnG67_4wmjqtq3dnHtS",
	"theader": "style_theader_Yi5F1QSD0NKB2R8P6X4y",
	"trow": "style_trow_Q3AzawQFnwqxQhMUG_EG",
	"th": "style_th_GZ9TX7SP4cGMKwFt1xbu",
	"tcell": "style_tcell_v9GqNTgTrOSKSanE8cUb"
};
export default ___CSS_LOADER_EXPORT___;
