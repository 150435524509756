import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { applicationVersion } from "../../actions";
import style from "./style.scss";
import {withTranslation} from "react-i18next";

// A banner used to inform the user that an update of the application is available
export class UpdateApplicationBanner extends Component {
  // Binding of methods and state initialization
  constructor(props) {
    super(props);
    this.onClickUpdate = this.onClickUpdate.bind(this);
    this.state = {
      isApplicationBannerVisible: false,
    };
  }

  // Every times component receives props a version check is performed
  // if it is the first time that user opens this page, no banner is displayed
  componentDidUpdate(props) {
    if (props.location.pathname !== this.props.location.pathname) {
      this.props.applicationVersion().then(version => {
        if (!localStorage.getItem("version")) {
          localStorage.setItem("version", version);
        } else if (localStorage.getItem("version") !== version) {
          this.setState({ isApplicationBannerVisible: true });
        }
      });
    }
  }

  // On click update, current version is stored and page is refreshed
  onClickUpdate() {
    localStorage.setItem("version", this.props.version);
    window.location.reload(true);
  }

  render() {
    const {t} = this.props;

    return (
      <>
        {
          this.state.isApplicationBannerVisible &&
          <div
            className={style.banner}
            onClick={this.onClickUpdate}
            dangerouslySetInnerHTML={{__html: t("updateApplicationBanner.info")}}
          />
        }
      </>
    );
  }
}

// propTypes for the UpdateApplicationBanner component
UpdateApplicationBanner.propTypes = {
  location: PropTypes.any.isRequired,
  applicationVersion: PropTypes.func.isRequired,
  version: PropTypes.any.isRequired,
  t: PropTypes.func.isRequired,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = state => {
  return {
    version: state.applicationVersion && state.applicationVersion.version || "",
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    applicationVersion: () => dispatch(applicationVersion()),
  };
};

// The component needs to use the router to redirect user to the search page
// after entering some text in the search box
// eslint-disable-next-line max-len
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UpdateApplicationBanner)));
