// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_administratorCell_gL_5Ga9khgbwjRFBTV2m {\n  display: flex;\n  flex-direction: column;\n}\n.style_administratorCell_gL_5Ga9khgbwjRFBTV2m > *:not(:last-child) {\n  padding-bottom: 0.25em; /* Adjust the margin value as needed */\n  border-bottom: 0.5px #d3e1e8 solid;\n}\n.style_administratorCell_gL_5Ga9khgbwjRFBTV2m > *:not(:first-child) {\n  padding-top: 0.25em; /* Adjust the margin value as needed */\n}", "",{"version":3,"sources":["webpack://./src/containers/Aggregators/components/List/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AACE;EACE,sBAAA,EAAA,sCAAA;EACA,kCAAA;AACJ;AAEE;EACE,mBAAA,EAAA,sCAAA;AAAJ","sourcesContent":[".administratorCell {\n  display: flex;\n  flex-direction: column;\n\n  & > *:not(:last-child) {\n    padding-bottom: 0.25em; /* Adjust the margin value as needed */\n    border-bottom: 0.5px #d3e1e8 solid;\n  }\n\n  & > *:not(:first-child) {\n    padding-top: 0.25em; /* Adjust the margin value as needed */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"administratorCell": "style_administratorCell_gL_5Ga9khgbwjRFBTV2m"
};
export default ___CSS_LOADER_EXPORT___;
