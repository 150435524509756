import React, { useEffect } from "react";
import PropTypes from "prop-types";
import style from "./style.scss";
import { infoHubSubjectDetail, infoHubSubjectDetailReset, listAggregators, listCountries, listInfoHubSubjectAggregators, listInfoHubSubjectAggregatorsReset } from "../../../../actions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Loading from "../../../../components/Loading";
import Modal from "../../../../components/Modal";
import { Col, Grid, Row } from "../../../../components/Grid";
import MarkdownPreview from "../../../../components/MarkdownPreview";
import Tag from "../../../../components/Tag";
import { getCountryObjectFromCache, isEmpty, valueOrDefault } from "../../../../utils";
import moment from "moment";
import Button from "../../../../components/Button";

export const ViewModal = ({
  modalOptions,
  setModalOptions,
  infoHubSubjectDetail,
  infoHubSubjectDetailReset,
  listInfoHubSubjectAggregators,
  listInfoHubSubjectAggregatorsReset,
  infoHubSubjectAggregators,
  infoHubSubject,
  history,
  t,
  isFetching,
  countries,
  listCountries,
  listAggregators,
  actions,
  country,
}) => {
  const { id, isOpen } = modalOptions;
  const subjectCountry = countries.length > 0 && infoHubSubject ? countries.find(country => country.id === infoHubSubject.country) : { name: "Unknown", id: null};
  const isSubjectWithinUserCountry = subjectCountry && subjectCountry.id === country.id;

  const setOnValidateType = () => {
    if(infoHubSubject && infoHubSubject.status === "draft") {
      return "publish";
    }else if(isSubjectWithinUserCountry && infoHubSubject && infoHubSubject.status !== "withdrawn") {
      return "withdraw";
    }else if (isSubjectWithinUserCountry && infoHubSubject && infoHubSubject.status === "withdrawn") {
      return "resume";
    }else{
      return "copy";
    }
  };

  const onValidateType = setOnValidateType();

  useEffect(() => {
    if(!id) return;
    infoHubSubjectDetail(id);
    listCountries();
    listInfoHubSubjectAggregators(id);
    listAggregators();
  }, [id]);

  const onClose = () => {
    infoHubSubjectDetailReset();
    listInfoHubSubjectAggregatorsReset();
    setModalOptions({ isOpen: false, id: null });
  };

  const onValidate = () => {
    switch(onValidateType){
    case "withdraw":
      actions.withdraw(id);
      onClose();
      break;
    case "resume":
      history.push(`/wfpManager/infohub/newContent/${id}`);
      break;
    case "copy":
      history.push(`/wfpManager/infohub/newContent/${id}`, { isCopy: true });
      break;
    case "publish":
      actions.publish(id);
      onClose();
    }
  };

  const getOnValidateButtonTitle = () => {
    switch(onValidateType){
    case "withdraw":
      return t("infoHub.table.actions.withdraw");
    case "resume":
      return t("infoHub.table.actions.resume");
    case "copy":
      return t("infoHub.makeCopy");
    case "publish":
      return t("infoHub.table.actions.publish");
    }
  };

  const getOnValidateButtonKind = () => {
    switch(onValidateType){
    case "withdraw":
      return "danger--primary";
    default:
      return "primary";
    }
  };

  const footer = () => {
    return (
      <div className={style.footer}>
        <Button
          className={style.button}
          onClick={() => onClose()}
          kind={"secondary"}
        >
          {t("infoHub.close")}
        </Button>
        <Button
          className={style.button}
          onClick={() => onValidate()}
          kind={getOnValidateButtonKind()}
        >
          {getOnValidateButtonTitle()}
        </Button>
      </div>
    );
  };

  const header = () => {
    if(isFetching) return null;

    const publishedBy = infoHubSubject && infoHubSubject.creation_user ? infoHubSubject.creation_user.first_name + " " + infoHubSubject.creation_user.last_name : "Unknown";
    const countryName = subjectCountry && subjectCountry.name || "Unknown";
    return (
      <>
        <Row>
          <Col>
            <div className={style.contentType}>
              {infoHubSubject.content_type}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={style.title}>
              {infoHubSubject.name}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={style.topicContainer}>
              {infoHubSubject.topics && infoHubSubject.topics.map((topic, index) => (
                <Tag key={`tag-${index}`} className={style.topic}>
                  {topic.name}
                </Tag>
              ))}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={style.metaData}>
              <span>
                {t("infoHub.country") + ": "}
              </span>
              {countryName}
            </div>
            <div className={style.metaData}>
              <span>
                {t("infoHub.publishedBy") + ": "}
              </span>
              {publishedBy}
            </div>
            <div className={style.metaData}>
              <span>
                {t("infoHub.date") + ": "}
              </span>
              {moment(infoHubSubject.creation_datetime).format("DD MMMM YYYY, HH:mm")}
            </div>
          </Col>
        </Row>
      </>
    );
  };

  if(!infoHubSubject) return null;

  return (
    <Modal
      id={id}
      isOpen={isOpen}
      footer={() => footer()}
      title={header()}
    >
      <div data-testid="view-modal">
        {isFetching && <Loading isVisible />}
        {!isFetching && Object.keys(infoHubSubject).length > 0 && (
          <div className={style.container}>
            <Grid>
              <div className={style.divider} />
              <Row>
                <Col>
                  <MarkdownPreview source={infoHubSubject.content} />
                </Col>
              </Row>

            </Grid>
          </div>
        )}
        {!isFetching && isSubjectWithinUserCountry && (
          <div className={style.container}>
            <Grid>
              <div className={style.divider} style={{marginTop: "2.5rem"}}/>
              <Row>
                <Col>
                  {
                    infoHubSubjectAggregators && infoHubSubjectAggregators.length > 0 && (
                      <div className={style.aggregatorTitle}>
                        {t("infoHub.form.sharedWith")}
                      </div>
                    )
                  }
                  <div className={style.aggregatorContainer}>
                    {infoHubSubjectAggregators && infoHubSubjectAggregators.map((aggregator, index) =>(
                      <div key={`tag-${index}`} className={style.aggregator}>
                        {aggregator && aggregator.name || t("infoHub.form.unknownAggregator", {id: aggregator && aggregator.id})}
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>

        )}
      </div>
    </Modal>
  );
};

ViewModal.propTypes = {
  modalOptions: PropTypes.object.isRequired,
  setModalOptions: PropTypes.func.isRequired,
  infoHubSubjectDetail: PropTypes.func.isRequired,
  infoHubSubjectDetailReset: PropTypes.func.isRequired,
  infoHubSubject: PropTypes.object,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  topics: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  listCountries: PropTypes.func.isRequired,
  listInfoHubSubjectAggregators: PropTypes.func.isRequired,
  listInfoHubSubjectAggregatorsReset: PropTypes.func.isRequired,
  infoHubSubjectAggregators: PropTypes.array.isRequired,
  listAggregators: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired,
  country: PropTypes.object.isRequired,
};

export const mapStateToProps = state => {
  const getInfoHubSubjectAggregators = (selectedAggregators, allAggregators) => {
    return valueOrDefault(Array.isArray(selectedAggregators) && selectedAggregators.map(aggregatorID => {
      return allAggregators.find(aggregator => aggregator.id === aggregatorID);
    }), undefined);
  };
  return {
    infoHubSubject: valueOrDefault(!isEmpty(state.infoHubSubjectDetail.data) && state.infoHubSubjectDetail.data, undefined),
    infoHubSubjectAggregators: getInfoHubSubjectAggregators(
      valueOrDefault(state.listInfoHubSubjectAggregators.data, []),
      valueOrDefault(state.listAggregators.data.results, []),
    ),
    isFetching: state.infoHubSubjectDetail.isFetching ||
      state.listCountries.isFetching ||
      state.listAggregators.isFetching ||
      state.listInfoHubSubjectAggregators.isFetching,
    countries: valueOrDefault(state.listCountries.data.results, []),
    country: getCountryObjectFromCache(),
  };
};

export const mapDispatchToProps = dispatch => ({
  infoHubSubjectDetail: id => dispatch(infoHubSubjectDetail(id)),
  infoHubSubjectDetailReset: () => dispatch(infoHubSubjectDetailReset()),
  listInfoHubSubjectAggregators: (id) => dispatch(listInfoHubSubjectAggregators({subject: id}, "aggregators/")),
  listInfoHubSubjectAggregatorsReset: () => dispatch(listInfoHubSubjectAggregatorsReset()),
  listAggregators: () => dispatch(listAggregators({page_size: 9999})),
  listCountries: () => dispatch(listCountries()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(ViewModal)));
