import React from "react";
import PropTypes from "prop-types";

import Module from "../../../../components/Module";
import Loading from "../../../../components/Loading";
import { displayErrorMessage } from "../../../../utils";
import Table from "../../../../components/Table";
import { generateDeleteButton, generateViewButton } from "../../../../utils/table";
import { useTranslation } from "react-i18next";

// The list of Aggregators that user can view and delete
export const List = (
  {
    isFetching,
    errorMessage,
    managers,
    page,
    onPageChange,
    count,
    onClickView,
    onClickDelete,
  },
) => {

  const { t } = useTranslation();

  if (isFetching) {
    return <Loading isVisible />;
  }

  if (errorMessage) {
    return (
      <>
        {displayErrorMessage(errorMessage)}
      </>
    );
  }

  const columns = [
    {
      Header: "column.managerFirstName",
      accessor: "first_name",
    },
    {
      Header: "column.managerLastName",
      accessor: "last_name",
    },
    {
      Header: "column.managerEmail",
      accessor: "email",
      width: 200,
    },
    {
      Header: "column.countries",
      accessor: "countriesString",
      width: 200,
    },
    {
      width: 80,
      Cell: obj => generateViewButton(obj.row.original, onClickView, t("common.edit"), t),
    },
    {
      width: 80,
      Cell: obj => generateDeleteButton(obj.row.original, onClickDelete, undefined, t),
    },
  ];

  return (
    <>
      <Module
        table={
          <Table
            loading={isFetching}
            columns={columns}
            data={managers}
            totalItems={count}
            page={page}
            onPageChange={onPageChange}
          />
        }
      />
    </>
  );
};

// propTypes for the List component
List.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  managers: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  onClickView: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};

// defaultProps for the List component
List.defaultProps = {
  errorMessage: "",
};

export default List;
