import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useLocation, withRouter } from "react-router-dom";
import { iconAdd } from "@wfp/icons";
import { iconSearch } from "@wfp/icons";
import { iconClose } from "@wfp/icons";
import {
  listAggregators,
  listAggregatorsReset,
  aggregatorsCurrentPage,
  listAggregatorAdministratorsReset,
  listAggregatorAdministrators,
  treeAdministrativeAreas,
  listCommodities,
  listMarketOffers,
} from "../../actions";
import PageStandard from "../../components/PageStandard";
import List from "./components/List";
import { getCountryObjectFromCache, valueOrDefault } from "../../utils";
import {
  adaptAdministrativeAreas,
  adaptAggregatorAdministrators,
  adaptWfpManagerAggregators,
} from "../../utils/adapters";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";
import AggregatorDetailsModal from "../AggregatorDetailsModal";
import { Col, Row } from "../../components/Grid";
import { Controller, useForm } from "react-hook-form";
import Input from "../../components/Input";
import style from "./style.scss";
// This component is used to display the list
// of aggregators for the WFP Manager
export const Aggregators = ({
  isFetching,
  isFetchingAggregators,
  errorMessage,
  aggregators,
  count,
  listAggregators,
  listAggregatorsReset,
  listAggregatorAdministrators,
  treeAdministrativeAreas,
  history,
  administrativeAreas,
  administrators,
  commodities,
  listCommodities,
  listMarketOffers,
}) => {
  const { t } = useTranslation();

  const location = useLocation();

  const [selectedAggregator, setSelectedAggregator] = useState({});
  const [paginationState, setPaginationState] = useState({
    page: 0,
    pageSize: 10,
    name: undefined,
  });

  // On component mount the data are fetched
  useEffect(() => {
    const country = getCountryObjectFromCache();
    treeAdministrativeAreas(country.id);
    listCommodities();
    listMarketOffers();
    return () => {
      listAggregatorsReset();
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setPaginationState({
      page: params.get("page")
        ? parseInt(params.get("page"), 10)
        : paginationState.page,
      pageSize:
        params.get("pageSize") && parseInt(params.get("pageSize"), 10) > 0
          ? parseInt(params.get("pageSize"), 10)
          : paginationState.pageSize,
      name: params.get("name") ? params.get("name") : undefined,
    });
  }, [location.search]);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      name: new URLSearchParams(location.search).get("name"),
    },
  });

  useEffect(() => {
    listAggregators(
      paginationState.page,
      paginationState.pageSize,
      paginationState.name,
    );
  }, [paginationState]);

  // A function used to manage pagination of aggregators page
  const onPageChange = (page) => {
    const params = new URLSearchParams(location.search);

    // Step 2: Update or add new parameters
    params.set("page", page.page - 1);
    params.set("pageSize", page.pageSize);

    // Step 3: Navigate to the updated URL
    history.push(`${location.pathname}?${params.toString()}`);
  };

  // When user clicks add Aggregator, it is redirected
  // to Aggregator creation page
  const onClickAdd = () => {
    history.push("/wfpManager/aggregatorDetails");
  };

  const onClickView = (record) => {
    listAggregatorAdministrators(record.id);
    setSelectedAggregator(record);
  };

  const onClickEdit = (aggregator) => {
    history.push(`/wfpManager/aggregatorDetails/${aggregator.id}`);
  };

  const filterAggregators = (filters) => {
    history.push(`?name=${filters.name}&page=0`);
  };

  const clearFilter = () => {
    setValue("name", "");
    history.push(`?page=0&page_size=${paginationState.pageSize}`);
  };

  return (
    <>
      <PageStandard
        title={`${t("aggregators.pageTitle")} ${t("common.in")} ${
          getCountryObjectFromCache().name
        }`}
        additional={
          <ButtonsWrapper>
            <Button icon={iconAdd} onClick={onClickAdd}>
              {t("aggregators.newAggregator")}
            </Button>
          </ButtonsWrapper>
        }
      >
        <Row className={style.filtersContainer}>
          <Col sm={6}>
            <div className={style.filtersTitle}>
              <span>{t("aggregators.filters")}:</span>
            </div>
            <div className={style.filterRow}>
              <Controller
                control={control}
                render={({ field, fieldState }) => (
                  <Input
                    input={field}
                    meta={fieldState}
                    placeholder={t("aggregators.typeAggregatorName")}
                    id="aggregator-name-input"
                  />
                )}
                isRequired
                name="name"
                id="aggregator-name"
              />
              <Button
                className={style.searchButton}
                icon={iconSearch}
                onClick={handleSubmit(filterAggregators)}
              >
                {t("aggregators.search")}
              </Button>
              {paginationState.name && (
                <Button
                  className={style.searchButton}
                  icon={iconClose}
                  onClick={clearFilter}
                  kind="secondary"
                >
                  {t("commodities.clearFilter")}
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <List
          {...{
            administrativeAreas,
            aggregators,
            count,
            errorMessage,
            isFetching,
            isFetchingAggregators,
            onClickEdit,
            onClickView,
            onPageChange,
            page: paginationState.page,
            pageSize: paginationState.pageSize,
          }}
        />
      </PageStandard>
      <AggregatorDetailsModal
        hideActions
        isOpen={selectedAggregator.id}
        aggregator={selectedAggregator}
        isFetchingAggregatorAdministrators={isFetching}
        onValidate={() => setSelectedAggregator({})}
        administrators={administrators}
        commodities={commodities}
        onClickEdit={onClickEdit}
      />
    </>
  );
};

// propTypes for the Aggregators component
Aggregators.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  isFetchingAggregators: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  aggregators: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  listAggregators: PropTypes.func.isRequired,
  listAggregatorsReset: PropTypes.func.isRequired,
  listAggregatorAdministrators: PropTypes.func.isRequired,
  treeAdministrativeAreas: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  // aggregatorsCurrentPage: PropTypes.func.isRequired,
  administrativeAreas: PropTypes.array.isRequired,
  administrators: PropTypes.array.isRequired,
  commodities: PropTypes.array.isRequired,
  listCommodities: PropTypes.func.isRequired,
  listMarketOffers: PropTypes.func.isRequired,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = (state) => {
  return {
    // Aggregators management
    isFetching:
      state.listAggregatorAdministrators.isFetching ||
      state.listMarketOffers.isFetching ||
      state.listAggregators.isFetching,
    isFetchingAggregators: state.listAggregators.isFetching,
    errorMessage: state.listAggregators.errorMessage,
    aggregators: adaptWfpManagerAggregators(state.listAggregators.data.results),
    administrators: adaptAggregatorAdministrators(
      state.listAggregatorAdministrators.data.results,
    ),
    commodities: state.listCommodities.data.results,
    administrativeAreas: adaptAdministrativeAreas(
      state.treeAdministrativeAreas.data.administrative_areas,
    ),
    // page: state.aggregatorsCurrentPage.number,
    count: valueOrDefault(state.listAggregators.data.count, 0),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    listMarketOffers: () =>
      dispatch(
        listMarketOffers({
          ordering: "-last_updated_datetime",
        }),
      ),
    listAggregators: (page, page_size, name) =>
      dispatch(listAggregators({ page: page + 1, page_size, name })),
    listAggregatorsReset: () => dispatch(listAggregatorsReset()),
    aggregatorsCurrentPage: (page) => dispatch(aggregatorsCurrentPage(page)),
    listAggregatorAdministrators: (aggregator) =>
      dispatch(listAggregatorAdministrators({ aggregator })),
    listAggregatorAdministratorsReset: () =>
      dispatch(listAggregatorAdministratorsReset()),
    treeAdministrativeAreas: (countryId) =>
      dispatch(
        treeAdministrativeAreas({}, `${countryId}/administrative_areas/`),
      ),
    listCommodities: () => dispatch(listCommodities({ page_size: 999 })),
  };
};

// The component uses the redux store
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Aggregators));
