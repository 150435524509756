import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import style from "./style.scss";
import { Blockquote } from "@wfp/ui";
import InfoHubReviewStep from "../InfoHubReviewStep";

const InfoHubMobilePreviewStep = ({ t, methods }) => {
  return (
    <div className={style.container}>
      <Blockquote kind="warning">
        <div className={style.mobilePreviewWarning}>
          {t("infoHub.form.mobilePreviewWarning")}
        </div>
      </Blockquote>
      <div className={style.mobilePreviewContainer}>
        <InfoHubReviewStep methods={methods} />
      </div>
    </div>
  );
};

InfoHubMobilePreviewStep.propTypes = {
  t: PropTypes.func.isRequired,
  methods: PropTypes.object.isRequired,
};

export default withTranslation()(InfoHubMobilePreviewStep);
