import * as Sentry from "@sentry/browser";

// Configure sentry to log errors in production
export const configureSentry = (force = false) => {
  if (process.env.NODE_ENV === "production" || force) {
    Sentry.init(
      {
        dsn: process.env.SENTRY_DSN,
        environment: process.env.SENTRY_ENVIRONMENT,
      },
    );
  }
};

// Configure sentry to log errors in production
export const captureException = (error, force = false) => {
  if (process.env.NODE_ENV === "production" || force) {
    Sentry.captureException(error);
  }
};
