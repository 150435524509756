import React from "react";
import PropTypes from "prop-types";

import FormWrapper from "../../../../components/FormWrapper";
import { displayInformationMessage } from "../../../../utils";
import Modal from "../../../../components/Modal";
import {withTranslation} from "react-i18next";

// A modal used to create and upload farmer from a file
export const UploadFarmersModal = (
  {
    isModalOpen,
    onClickValidate,
    onClickClose,
    t,
  },
) => {

  return (
    <Modal
      isOpen={isModalOpen}
      title={t("aggregatorDetails.termsConfirmationModal.title")}
      onValidate={onClickValidate}
      onCancel={onClickClose}
    >
      <FormWrapper>
        {displayInformationMessage(
          t("aggregatorDetails.termsConfirmationModal.message"),
        )}
      </FormWrapper>
    </Modal >
  );
};

// propTypes for the UploadFarmersModal component
UploadFarmersModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClickValidate: PropTypes.func.isRequired,
  onClickClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(UploadFarmersModal);
