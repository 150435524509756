import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PageStandard from "../../components/PageStandard";
import { getDeletedItemName, valueOrDefault } from "../../utils";
import List from "./components/List";
import PropTypes from "prop-types";
import { deleteHelpMessagesDetails, helpMessagesDetailsReset, listHelpMessages, listHelpMessagesReset } from "../../actions";
import HelpMessagesEditModal from "./components/Modal";
import Modal from "../../components/Modal";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import Button from "../../components/Button";
import { iconAdd } from "@wfp/icons";

export const WfpManagerHelpMessagesView = ({
  isFetching,
  errorMessage,
  helpMessages,
  page,
  count,
  listHelpMessages,
  listHelpMessagesReset,
  helpMessagesDetailsReset,
  deleteHelpMessagesDetails,
}) => {
  const { t } = useTranslation();
  const [modalOptions, setModalOptions] = useState({isOpen: false, id: undefined, type: undefined});
  const [deleteModalOptions, setDeleteModalOptions] = useState({isOpen: false, id: undefined});

  // On component mount the data are fetched
  useEffect(() => {
    listHelpMessages();
    return () => {
      listHelpMessagesReset();
    };
  }, []);

  const onClickAdd = () => {
    helpMessagesDetailsReset();
    setModalOptions({isOpen: true, id: "add", type: "create"});
  };

  const onDeleteSubmit = () => {
    deleteHelpMessagesDetails(deleteModalOptions.id);
    setDeleteModalOptions({isOpen: false, id: undefined});
    listHelpMessages();
  };

  return (
    <PageStandard
      additional={(
        <ButtonsWrapper>
          <Button icon={iconAdd} onClick={onClickAdd}>
            {t("common.add") + " " + t("configuration.item.webHelpMessages")}
          </Button>
        </ButtonsWrapper>
      )}
      title={t("configuration.item.webHelpMessages")}
    >
      <List
        {...{
          isFetching,
          errorMessage,
          helpMessages,
          page,
          count,
          setDeleteModalOptions,
          setModalOptions,
        }}
      />
      <HelpMessagesEditModal
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
      />
      <Modal
        isOpen={deleteModalOptions.isOpen}
        onValidate={onDeleteSubmit}
        title={t("common.delete") + " " + t("configuration.item.webHelpMessages")}
        danger={true}
        onCancel={() => setDeleteModalOptions({isOpen: false, id: undefined})}
        isLoading={false}
      >
        {t("configuration.common.deleteText") + getDeletedItemName(deleteModalOptions.id, helpMessages, "name")}
      </Modal>
    </PageStandard>
  );
};

WfpManagerHelpMessagesView.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  helpMessages: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  listHelpMessages: PropTypes.func.isRequired,
  listHelpMessagesReset: PropTypes.func.isRequired,
  helpMessagesDetailsReset: PropTypes.func.isRequired,
  deleteHelpMessagesDetails: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    helpMessages: state.listHelpMessages.data.results,
    isFetching: state.listHelpMessages.isFetching,
    errorMessage: state.listHelpMessages.errorMessage,
    page: 1,
    count: valueOrDefault(state.listHelpMessages.data.results.length, 0),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    listHelpMessages: () => dispatch(listHelpMessages()),
    listHelpMessagesReset: () => dispatch(listHelpMessagesReset()),
    helpMessagesDetailsReset: () => dispatch(helpMessagesDetailsReset()),
    deleteHelpMessagesDetails: id => dispatch(deleteHelpMessagesDetails(id, true)),
  };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(WfpManagerHelpMessagesView);
