import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { iconEdit, iconDelete } from "@wfp/icons";

import {
  aggregatorCreationRequestDetails,
  aggregatorCreationRequestDetailsReset,
  deleteAggregatorCreationRequest,
  deleteAggregatorCreationRequestReset,
} from "../../actions";
import PageStandard from "../../components/PageStandard";
import Details from "./components/Details";
import { isEmpty, getDetailedError } from "../../utils";
import { adaptAggregatorCreationRequest } from "../../utils/adapters";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import Button from "../../components/Button";
import ModalConfirm from "../../components/ModalConfirm";
import {useTranslation} from "react-i18next";

// This component is used to display the detail
// of an aggregator creation request
export const RepresentativeAggregatorCreationRequestView = (
  {
    id,
    aggregatorCreationRequestDetails,
    isFetching,
    errorMessage,
    aggregatorCreationRequest,
    history,
    aggregatorCreationRequestDetailsReset,
    deleteAggregatorCreationRequest,
    deleteAggregatorCreationRequestReset,
    errorDeleteAggregatorCreationRequest,
    isFetchingDeleteAggregatorCreationRequest,
  },
) => {

  // A state to decide if the delete modal is open or closed
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const {t} = useTranslation();

  // On component mount the data are fetched
  useEffect(() => {
    aggregatorCreationRequestDetails(id);
    return () => {
      aggregatorCreationRequestDetailsReset();
      deleteAggregatorCreationRequestReset();
    };
  }, []);

  // When user clicks delete Aggregator, a confirm modal is displayed
  const onClickDelete = () => {
    setIsDeleteModalOpen(true);
  };

  // When user confirm that it want to delete the record
  // the record is actually deleted
  const onClickConfirmDelete = () => {
    deleteAggregatorCreationRequest(id).then(result => {
      if (result) {
        setIsDeleteModalOpen(false);
        deleteAggregatorCreationRequest();
        history.push("/representative/representativeAggregatorCreationRequests");
      }
    });
  };

  // When user press cancel on delete modal
  // the modal is closed and the current record
  // to delete is reset
  const onClickCancelDelete = () => {
    setIsDeleteModalOpen(false);
    deleteAggregatorCreationRequestReset();
  };

  // When user clicks edit Aggregator, it is redirected
  // to Aggregator edit page
  const onClickEdit = () => {
    history.push(`/representative/representativeAggregatorCreationRequestDetails/${id}`);
  };

  return (
    <PageStandard
      title={t("aggregatorCreationRequestView.title")}
      additional={(
        <ButtonsWrapper>
          {
            (aggregatorCreationRequest.status === "submitted" ||
              aggregatorCreationRequest.status === "rejected") &&
              <>
                <Button icon={iconDelete} onClick={onClickDelete}>
                  {t("aggregatorCreationRequestView.deleteRequest")}
                </Button>
                <Button icon={iconEdit} onClick={onClickEdit}>
                  {t("aggregatorCreationRequestView.editRequest")}
                </Button>
              </>
          }
        </ButtonsWrapper>
      )}
    >
      <Details
        isFetching={isFetching}
        errorMessage={errorMessage}
        aggregatorCreationRequest={aggregatorCreationRequest}
      />
      <ModalConfirm
        isOpen={isDeleteModalOpen}
        onValidate={onClickConfirmDelete}
        onCancel={onClickCancelDelete}
        error={errorDeleteAggregatorCreationRequest}
        isLoading={isFetchingDeleteAggregatorCreationRequest}
        title={t("aggregatorCreationRequestView.deleteRequestModalTitle")}
        text={t("aggregatorCreationRequestView.deleteRequestModalText")}
      />
    </PageStandard>
  );
};

// propTypes for the RepresentativeAggregatorCreationRequestView component
RepresentativeAggregatorCreationRequestView.propTypes = {
  id: PropTypes.string.isRequired,
  aggregatorCreationRequestDetails: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  aggregatorCreationRequest: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  aggregatorCreationRequestDetailsReset: PropTypes.func.isRequired,
  deleteAggregatorCreationRequest: PropTypes.func.isRequired,
  deleteAggregatorCreationRequestReset: PropTypes.func.isRequired,
  errorDeleteAggregatorCreationRequest: PropTypes.object.isRequired,
  isFetchingDeleteAggregatorCreationRequest: PropTypes.bool.isRequired,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = (state, props) => {
  return {
    // Properties related to aggregator creation request details
    id: props.match.params.id,
    isFetching:
      state.aggregatorCreationRequestDetails.isFetching ||
      isEmpty(state.aggregatorCreationRequestDetails.data),
    errorMessage: state.aggregatorCreationRequestDetails.errorMessage,
    aggregatorCreationRequest:
      adaptAggregatorCreationRequest(state.aggregatorCreationRequestDetails.data),
    // Delete Aggregator properties
    isFetchingDeleteAggregatorCreationRequest: state.deleteAggregatorCreationRequest.isLoading,
    errorDeleteAggregatorCreationRequest:
      getDetailedError(state.deleteAggregatorCreationRequest.error),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    aggregatorCreationRequestDetails: id => dispatch(aggregatorCreationRequestDetails(id)),
    aggregatorCreationRequestDetailsReset: () => dispatch(aggregatorCreationRequestDetailsReset()),
    deleteAggregatorCreationRequest: id => dispatch(deleteAggregatorCreationRequest(id)),
    deleteAggregatorCreationRequestReset: () => dispatch(deleteAggregatorCreationRequestReset()),
  };
};

// The component uses the redux store
export default
connect(mapStateToProps, mapDispatchToProps)(RepresentativeAggregatorCreationRequestView);
