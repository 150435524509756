// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_version_BPM47ywphv9FXN3R7z8K {\n  font-size: small;\n  font-style: italic;\n}", "",{"version":3,"sources":["webpack://./src/components/WfpManagerFooterComponent/style.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,kBAAA;AACF","sourcesContent":[".version {\n  font-size: small;\n  font-style: italic;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"version": "style_version_BPM47ywphv9FXN3R7z8K"
};
export default ___CSS_LOADER_EXPORT___;
