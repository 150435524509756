import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { marketOfferDetails, sendBulkSms, sendBulkSmsReset } from "../../actions";
import PageStandard from "../../components/PageStandard";
import Form from "./components/Form";
import { isEmpty, arrayToString } from "../../utils";
import { adaptMarketOffer } from "../../utils/adapters";
import {useTranslation} from "react-i18next";

// This component is used to display the detail
// of a Market Offer in read only and send sms to aggregators
export const MarketOfferSms = (
  {
    id,
    marketOfferDetails,
    isFetching,
    errorMessage,
    marketOffer,
    formValuesMarketOfferSms,
    sendBulkSms,
    sendBulkSmsReset,
    history,
    fieldsErrorMessage,
  },
) => {
  // The part of state used to select aggregators from a table
  const [selectedAggregator, setSelectedAggregator] = useState({});

  // The part of state used to display the success modal
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  // The part of state used to list aggregators from a table
  const [remainingAggregators, setRemainingAggregators] = useState([]);

  const {t} = useTranslation();

  // On component mount the data are fetched
  useEffect(() => {
    marketOfferDetails(id).then(result => {
      setRemainingAggregators(result.data.tagged_aggregators);
    });
    return () => {
      sendBulkSmsReset();
    };
  }, []);

  // On click view Aggregator details, user is redirected
  // to the right page based on user profile
  const onSelectAggregator = (record) => {
    setRemainingAggregators(
      remainingAggregators.filter(aggregator => aggregator.id !== record.id),
    );
    setSelectedAggregator({...record});
  };

  // On click view Aggregator details, user is redirected
  // to the right page based on user profile
  const onUnselectAggregator = (record) => {
    setSelectedAggregator({});
    setRemainingAggregators([record, ...remainingAggregators]);
  };

  // On click send email a mailto is performed
  const onClickSendSms = () => {
    const persons = [];
    selectedAggregator.administrators.map(administrator => {
      persons.push(administrator.person.id);
    });

    const data = {
      text: formValuesMarketOfferSms.text || "",
      persons,
    };

    sendBulkSms(data).then(result => {
      if (result) {
        setIsSuccessModalOpen(true);
      }
    });
  };

  // On confirm success modal user is redirected
  const onConfirmSuccessModal = () => {
    history.push("/representative/");
  };

  return (
    <PageStandard title={t("marketOfferSms.title")}>
      <Form
        isFetching={isFetching}
        errorMessage={errorMessage}
        marketOffer={marketOffer}
        onClickSendSms={onClickSendSms}
        selectedAggregator={selectedAggregator}
        onSelectAggregator={onSelectAggregator}
        remainingAggregators={remainingAggregators}
        onUnselectAggregator={onUnselectAggregator}
        fieldsErrorMessage={fieldsErrorMessage}
        isSuccessModalOpen={isSuccessModalOpen}
        onConfirmSuccessModal={onConfirmSuccessModal}
      />
    </PageStandard >
  );
};

// propTypes for the MarketOfferSms component
MarketOfferSms.propTypes = {
  id: PropTypes.string.isRequired,
  marketOfferDetails: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  marketOffer: PropTypes.object.isRequired,
  formValuesMarketOfferSms: PropTypes.object.isRequired,
  sendBulkSms: PropTypes.func.isRequired,
  sendBulkSmsReset: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  fieldsErrorMessage: PropTypes.string.isRequired,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = (state, props) => {
  return {
    // Properties related to market offer details and bulk sms
    id: props.match.params.id,
    isFetching:
      state.marketOfferDetails.isFetching ||
      state.sendBulkSms.isLoading ||
      isEmpty(state.marketOfferDetails.data),
    errorMessage:
      state.marketOfferDetails.errorMessage ||
      state.sendBulkSms.errorMessage,
    marketOffer: adaptMarketOffer(state.marketOfferDetails.data),
    // Form values of Aggregator Administrator
    formValuesMarketOfferSms:
      state.form.marketOfferSms &&
      state.form.marketOfferSms.values ||
      {},
    // Fields error message
    fieldsErrorMessage:
      state.sendBulkSms.error &&
      state.sendBulkSms.error.data &&
      arrayToString(state.sendBulkSms.error.data.errors) ||
      "",
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    marketOfferDetails: id => dispatch(marketOfferDetails(id)),
    sendBulkSms: data => dispatch(sendBulkSms(data)),
    sendBulkSmsReset: () => dispatch(sendBulkSmsReset()),
  };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(MarketOfferSms);
