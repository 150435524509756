import React, { useState } from "react";
import style from "./style.scss";
import Icon from "../../components/Icon";
import { iconUser, iconDelete, iconChevronRight } from "@wfp/icons";
import FormWrapper from "../../components/FormWrapper";
import { FormProvider, useForm, Controller } from "react-hook-form";
import Input from "../../components/Input";
import { MandatoryLabel } from "../../components/MandatoryLabel";
import Checkbox from "../../components/Checkbox";
import Button from "../../components/Button";
import { Module } from "@wfp/ui";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { displayErrorMessage } from "../../utils";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { setLocalStorage } from "./utils";

export const AccountDeletion = ({ history, location }) => {
  const { t } = useTranslation();
  const btnRef = React.useRef();
  const methods = useForm();
  const { control, handleSubmit } = methods;
  const expiredLink = location && location.state && location.state.isExpired;
  const [serverError, setServerError] = useState(
    expiredLink ? t("accountDeletion.expiredLink") : false,
  );

  const getVerificationUuid = async (email) => {
    try {
      const response = await axios.request({
        url: process.env.API_BASE_URL + "api/account_deletion/generate_link/",
        method: "post",
        data: {
          email: email,
        },
        // ValidateStatus will stop the axios interceptors from firing.
        // Without this the user gets redirected to the login screen.
        validateStatus: false,
      });
      if (response.data.error || response.status === 400) {
        setServerError(t("accountDeletion.serverError"));
      }
      return response.status;
    } catch {
      setServerError(t("accountDeletion.serverError"));
    }
    return;
  };

  const onClickValidate = async (e) => {
    // History.push will fire interceptiors.
    // Set The localStorage to prevent interceptors from redirecting user to login.
    setLocalStorage();
    await getVerificationUuid(e.email).then(() => {
      history.push("/accountDeletion/confirmation");
    });
  };

  return (
    <div className={style.container}>
      {serverError && displayErrorMessage(serverError)}
      <Module className={style.moduleContainer}>
        <div className={style.container}>
          <h1>{t("accountDeletion.title")}</h1>
          <div className={style.iconContainer}>
            <Icon
              icon={iconUser}
              width="50"
              height="50"
              style={{ marginBottom: "1rem" }}
              fill="#007DBC"
            />
            <Icon
              icon={iconChevronRight}
              width="30"
              height="30"
              style={{ marginBottom: "1rem" }}
              fill="#007DBC"
            />
            <Icon
              icon={iconDelete}
              width="50"
              height="50"
              style={{ marginBottom: "1rem" }}
              fill="#007DBC"
            />
          </div>
          <div className={style.body}>
            <div className={style.subtitle}>
              {t("accountDeletion.confirmationTitle")}
            </div>
            <p>{t("accountDeletion.confirmationText")}</p>
            <FormWrapper>
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onClickValidate)}>
                  <Controller
                    control={control}
                    render={({ field, fieldState }) => (
                      <Input
                        label={
                          <MandatoryLabel
                            label={t("accountDeletion.form.email")}
                          />
                        }
                        placeholder={t("accountDeletion.form.emailPlaceholder")}
                        id="email"
                        input={field}
                        meta={{
                          error: fieldState.error && fieldState.error.message,
                        }}
                        testid="email"
                      />
                    )}
                    name="email"
                    id="email"
                    rules={{
                      required: t(
                        "traderDetails.form.mandatoryFieldBlankError",
                      ),
                    }}
                  />
                  <div className={style.subtitle}>
                    {t("accountDeletion.accountDeletionDescriptionTitle")}
                  </div>
                  <p>{t("accountDeletion.accountDeletionDescription1")}</p>
                  <p>{t("accountDeletion.accountDeletionDescription2")}</p>
                  <p>{t("accountDeletion.accountDeletionDescription3")}</p>
                  <div className={style.checkbox}>
                    <div className={style.checkboxLabel}>
                      <Controller
                        id="confirm"
                        name="confirm"
                        control={control}
                        render={({ field, fieldState }) => (
                          <Checkbox
                            id="confirm"
                            input={field}
                            meta={{ error: "" }}
                            errorMessage={
                              fieldState.error && fieldState.error.message
                            }
                            label={
                              <>
                                {t("accountDeletion.form.confirmationCheckbox")}
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href="https://www.wfp.org/privacy-policy"
                                >
                                  {t("accountDeletion.form.privacyPolicy")}
                                </a>
                              </>
                            }
                            labelInFront={true}
                            testid="confirm"
                          />
                        )}
                        rules={{
                          required: t(
                            "traderDetails.form.mandatoryFieldBlankError",
                          ),
                        }}
                      />
                    </div>
                  </div>
                  <div className={style.footer}>
                    <Button
                      kind="danger--primary"
                      onClick={() => btnRef.current.click()}
                      testID="submit"
                    >
                      {t("accountDeletion.form.submitButtonText")}
                    </Button>
                    <a href={"mailto:global.farm2go@wfp.org"}>
                      {t("accountDeletion.form.contactUs")}
                    </a>
                  </div>
                  <button ref={btnRef} style={{ display: "none" }} />
                </form>
              </FormProvider>
            </FormWrapper>
          </div>
        </div>
      </Module>
    </div>
  );
};

AccountDeletion.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
export default withRouter(AccountDeletion);
