import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../../components/Modal";
import PropTypes from "prop-types";
import Form from "../Form";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  addPaymentMethodsDetails,
  paymentMethodsDetails,
  paymentMethodsDetailsReset,
  updatePaymentMethodsDetails,
} from "../../../../actions";
import { adaptInitialFormValues, getTitleName } from "../../../../utils/wfpManagerConfigurationUtils";

export const PaymentMethodsEditModal = ({
  initialFormValues,
  isFetching,
  errorMessage,
  modalOptions,
  setModalOptions,
  paymentMethodsDetails,
  paymentMethodsDetailsReset,
  updatePaymentMethodsDetails,
  addPaymentMethodsDetails,
}) => {

  const { t } = useTranslation();
  const buttonRef = useRef();
  const [initialValues, setInitialValues] = useState();

  useEffect(() => {
    paymentMethodsDetails(modalOptions.id);
    return () => {
      paymentMethodsDetailsReset();
    };
  }, [modalOptions.id]);

  useEffect(() => {
    setInitialValues(adaptInitialFormValues(initialFormValues, modalOptions.id));
  }, [initialFormValues]);

  const onSubmitEdit = (formValues) => {
    updatePaymentMethodsDetails(modalOptions.id, formValues);
    setModalOptions({ type: undefined, id: undefined, isOpen: false });
  };

  const onSubmitCreate = (formValues) => {
    addPaymentMethodsDetails(formValues);
    setModalOptions({ type: undefined, id: undefined, isOpen: false });
  };

  return (
    <Modal
      isOpen={modalOptions.isOpen}
      title={
        t("configuration.item.paymentMethods") + " " + t(getTitleName(modalOptions.type))
      }
      onCancel={() => setModalOptions({ type: undefined, isOpen: false, id: undefined })}
      onValidate={() => buttonRef.current.click()}
      hideCancelButton={modalOptions.type === "view" ? true : false}
      validateText={modalOptions.type !== "view" ? t("negotiationCard.confirm") : t("common.back")}
    >
      <Form
        id={modalOptions.id}
        initialValues={initialValues}
        isFetching={isFetching}
        errorMessage={errorMessage}
        modalOptions={modalOptions}
        onSubmit={modalOptions.type === "create" ? onSubmitCreate : onSubmitEdit}
        buttonRef={buttonRef}
      />
    </Modal>
  );
};

PaymentMethodsEditModal.defaultProps = {};

PaymentMethodsEditModal.propTypes = {
  initialFormValues: PropTypes.object,
  isFetching: PropTypes.bool,
  errorMessage: PropTypes.string,
  modalOptions: PropTypes.object,
  setModalOptions: PropTypes.func,
  paymentMethodsDetails: PropTypes.func.isRequired,
  paymentMethodsDetailsReset: PropTypes.func.isRequired,
  updatePaymentMethodsDetails: PropTypes.func.isRequired,
  addPaymentMethodsDetails: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    initialFormValues: state.paymentMethodsDetail.data,
    isFetching: state.paymentMethodsDetail.isFetching,
    errorMessage: state.paymentMethodsDetail.errorMessage,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    paymentMethodsDetails: (id) => dispatch(paymentMethodsDetails(id)),
    paymentMethodsDetailsReset: () => dispatch(paymentMethodsDetailsReset()),
    addPaymentMethodsDetails: (values) =>
      dispatch(addPaymentMethodsDetails(values, "", true)),
    updatePaymentMethodsDetails: (id, values) =>
      dispatch(updatePaymentMethodsDetails(id, values, "", true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodsEditModal);
