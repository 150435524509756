import {displayErrorHelpText} from "../../utils";
import React, {useMemo, useState} from "react";
import style from "./style.scss";
import {useTranslation} from "react-i18next";
import { Column, usePagination, useTable, useSortBy } from "react-table";
import { Checkbox, Table } from "@wfp/ui"

interface MultiCheckboxField {
    id: string;
    label: string;
    name: string;
}

type ResultRecord = {
    id: string | number;
    list: (string | number)[];
}

interface Props {
    id: string;
    busy: boolean;
    label: string;
    records: any[];
    recordProp: string;
    columns: Column<any>[];
    fields: MultiCheckboxField[];
    fieldProp: string;
    loading?: boolean;
    placeholder: string;
    errorMessage?: string;
    isDisabled?: boolean;
    input: { value: ResultRecord[], onChange: (record: ResultRecord[]) => void };
    customClassName: string;
}


const MultiCheckboxTable: React.FC<Props> = ({
    id,
    label,
    columns = [],
    records,
    fields,
    errorMessage,
    isDisabled,
    input: { value, onChange },
    busy = false
}) => {
    const { t } = useTranslation();

    const [valueState, setValue] = useState<ResultRecord[]>(value || []);

    const onChangeItem = (recordId: number | string, fieldId: number | string) => {
        if (valueState.some(record => record.id === recordId)) {
            const result = valueState.reduce((prev: ResultRecord[], curr: ResultRecord) => {
                if (curr.id === recordId) {
                    if (curr.list.includes(fieldId)) {
                        const list = curr.list.filter(item => item !== fieldId);
                        return list.length ? [...prev, {
                            ...curr, list
                        }] : prev;
                    } else {
                        const list = [...curr.list, fieldId];
                        return [...prev, {
                            ...curr, list
                        }];
                    }
                } else {
                    return [...prev, curr];
                }
            }, []);
            setValue(result); onChange(result);
        } else {
            const result = [...valueState, {id: recordId, list: [fieldId]}];
            setValue(result); onChange(result);
        }
    }

    const memoData = useMemo(() => records, [records, valueState]);
    const memoColumns = useMemo(() => {
        return [...columns, ...fields.sort((f1, f2) => f1.name.localeCompare(f2.name)).map((field) => ({
            Header: field.name,
            id: `column_id_${field.id}`,
            Cell: ({row}: {row: any}) => {
                return (<div className={style.headingText}>
                    <Checkbox
                        labelText=""
                        checked={valueState.some(record => record.id === row.original.id && record.list.some((id: string | number) => id === field.id))}
                        // defaultChecked={valueState.some(record => record.id === row.original.id && record.list.some((id: string | number) => id === field.id))}
                        onChange={() => onChangeItem(row.original.id, field.id)}
                        id={`multi_checkbox_row_${row.original.id}_field_${field.id}`}
                        name={`multi_checkbox_row_${row.original.id}_field_${field.id}`}
                        disabled={isDisabled}
                        hideLabel={true}
                    />
                </div>)
            },
          }))]  as Column<any>[]
    }, [columns, fields, valueState])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        gotoPage,
        setPageSize,
        state: { pageIndex, pageSize },
      } = useTable(
          {
            data: memoData,
            columns: memoColumns,
            initialState: { pageIndex: 0, pageSize: 100 },
          },
          useSortBy,
          usePagination,
      );

    return (
        <div className="wfp--form-item">
            <label htmlFor={id} className="wfp--label">
            {label}
            </label>
        <div id={id} className={style.wrapper}>
            <Table  {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    style = {{ width: column.width}}
                                >
                                { column.render("Header") }
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                            {row.cells.map(cell => (
                                <td
                                    {...cell.getCellProps()}
                                    // className={getClassName(cell.column, "cell")}
                                >
                                    {cell.render('Cell')}
                                </td>
                            ))}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
        {displayErrorHelpText(errorMessage)}
        </div>
    )
};


export default MultiCheckboxTable;