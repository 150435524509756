import React from "react";
import { withRouter, Link } from "react-router-dom";
import { iconWarningSolid } from "@wfp/icons";

import Icon from "../../components/Icon";
import style from "./style.scss";
import {useTranslation} from "react-i18next";

// A page displayed when user navigate to error message route
export const ErrorMessage = () => {

  const {t} = useTranslation();

  return (
    <div className={style.wrapper}>
      <div>
        <div className={style.icon}>
          <Icon
            icon={iconWarningSolid}
            width="200"
            height="200"
          />
        </div>
        <h1 className="wfp--story__title">{t("errorMessage.title")}</h1>
        <p>
          {t("errorMessage.tryToGoBackTo")}{" "}
          <Link to="/login">{t("errorMessage.loginPage")}</Link>
        </p>
      </div>
    </div>
  );
};

// The router is used to allow user to navigate to home page
export default withRouter(ErrorMessage);
