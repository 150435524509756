import React, { Component } from "react";
import { Route, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import RepresentativeFooter from "../RepresentativeFooter";
import {withTranslation} from "react-i18next";
import LoggedOutMainNavigation from "../../components/LoggedOutMainNavigation";

export class LoggedOutRoute extends Component {
  render() {

    return (
      <>
        <LoggedOutMainNavigation />
        <Route {...this.props} />
        <RepresentativeFooter />
      </>
    );
  }
}

// PropTypes for the LoggedOutRoute component
LoggedOutRoute.propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};

export default withTranslation()(withRouter(LoggedOutRoute));
