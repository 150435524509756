import { CURRENT_PAGE_ } from "../actions/currentPage";

// A generic reducer used to store which is the current page of a paginated element
const currentPage = element => (state = { number: 0 }, action) => {
  switch (action.type) {
  case `${CURRENT_PAGE_}${element}`:
    return { ...state, number: action.number };
  default:
    return state;
  }
};

export default currentPage;
