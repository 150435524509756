import React from "react";
import { useTranslation } from "react-i18next";
import { Column, usePagination, useTable } from "react-table";
import { Table as TableWFP, Pagination } from "@wfp/ui";
import { iconChevronDown, iconChevronUp } from "@wfp/icons";
import style from "./style.scss";
import OfferCard from "../OfferCard";
import { Offer } from "../OfferCard/interfaces";

interface Props {
  data: any[];
  columns: Column<any>[];
  // loading: boolean;
  // defaultPageSize: number;
  // subComponent: Node;
  // expandAll: boolean;
  // showPagination: boolean;
  totalItems: number;
  // page: number;
  onPageChange?: (page: { page: number; pageSize: number }) => void;
  sort: {
    columnIndex: number;
    descending: boolean;
  };
  onSortChange: (
    sort: { columnIndex: number; descending: boolean },
    pageSize: number
  ) => void;

  onMakeOfferClick: (deposit: any) => void;
  onSeeReasonsClick: (offer: Offer) => void;
  onSeeProgressClick: (offer: Offer) => void;
  onClickAggregatorDetails: (id: number) => void;
  onShowRecall: (id: number) => void;
  filterPanel: React.ReactNode;
  pagination: {
    page: number,
    pageSize: number,
  };
}

export const OffersList = ({
  data,
  columns,
  // loading,
  // defaultPageSize = 10,
  // subComponent,
  // expandAll = false,
  // showPagination = true,
  totalItems,
  // page,
  sort,
  pagination,
  onPageChange,
  onSortChange,
  onMakeOfferClick,
  onSeeReasonsClick,
  onSeeProgressClick,
  onClickAggregatorDetails,
  onShowRecall,
  filterPanel,
}: Props) => {
  const { t } = useTranslation();
  const memoColumns = React.useMemo(
    () =>
      columns && columns.length
        ? columns.map((column, index) => ({
            ...column,
            id: `id_${index}`,
            Header: t(column.Header as string),
          }))
        : [],
    [columns]
  );
  const memoData = React.useMemo(() => data, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      data: memoData,
      columns: memoColumns,
      initialState: { pageIndex: pagination.page - 1, pageSize: pagination.pageSize },
    },
    usePagination
  );

  const changePage: any = (page: { page: number; pageSize: number }) => {
    if (onPageChange) {
      onPageChange(page);
    } else {
      gotoPage(page.page - 1);
    }
    // Update PageSize
    if (pageSize !== page.pageSize) {
      setPageSize(page.pageSize);
    }
  };

  return (
    <div className={style.container}>
      {[filterPanel].concat(data.map(datum => {
        return (
          <OfferCard
            onClickSeeReasons={onSeeReasonsClick}
            key={`${datum.id}`}
            offer={datum}
            {...{
              onClickAggregatorDetails,
              onSeeProgressClick,
              onShowRecall,
            }}
          />
        );
      }))}
      <Pagination
        pageSize={pageSize}
        pageSizes={[10, 20, 30]}
        page={pageIndex + 1}
        totalItems={totalItems}
        onChange={changePage}
      />
    </div>
  );
};

export default OffersList;
