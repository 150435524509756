import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../../components/Modal";
import PropTypes from "prop-types";
import Form from "../Form";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  addTraderMarketOfferRejectionReasonsDetails,
  traderMarketOfferRejectionReasonsDetails,
  traderMarketOfferRejectionReasonsDetailsReset,
  updateTraderMarketOfferRejectionReasonsDetails,
} from "../../../../actions";
import { adaptInitialFormValues, getTitleName } from "../../../../utils/wfpManagerConfigurationUtils";

export const AggregatorOfferRejectionReasonsEditModal = ({
  initialFormValues,
  isFetching,
  errorMessage,
  modalOptions,
  setModalOptions,
  traderMarketOfferRejectionReasonsDetails,
  traderMarketOfferRejectionReasonsDetailsReset,
  updateTraderMarketOfferRejectionReasonsDetails,
  addTraderMarketOfferRejectionReasonsDetails,
}) => {

  const { t } = useTranslation();
  const buttonRef = useRef();
  const [initialValues, setInitialValues] = useState(undefined);

  useEffect(() => {
    traderMarketOfferRejectionReasonsDetails(modalOptions.id);
    return () => {
      traderMarketOfferRejectionReasonsDetailsReset();
    };
  }, [modalOptions.id]);

  useEffect(() => {
    setInitialValues(adaptInitialFormValues(initialFormValues, modalOptions.id));
  }, [initialFormValues]);

  const onSubmitEdit = (formValues) => {
    updateTraderMarketOfferRejectionReasonsDetails(modalOptions.id, formValues);
  };

  const onSubmitCreate = (formValues) => {
    addTraderMarketOfferRejectionReasonsDetails(formValues);
  };

  const onSubmit = (formValues) => {
    switch(modalOptions.type){
    case "edit":
      onSubmitEdit(formValues);
      break;
    case "create":
      onSubmitCreate(formValues);
      break;
    default:
      break;
    }
    setModalOptions({ type: undefined, id: undefined, isOpen: false });
  };

  return (
    <Modal
      isOpen={modalOptions.isOpen}
      title={
        t("configuration.item.aggregatorOfferRejectionReasons") + " " + t(getTitleName(modalOptions.type))
      }
      onCancel={() => setModalOptions({ type: undefined, isOpen: false, id: undefined })}
      onValidate={() => buttonRef.current.click()}
      hideCancelButton={modalOptions.type === "view" ? true : false}
      validateText={modalOptions.type !== "view" ? t("negotiationCard.confirm") : t("common.back")}
    >
      <Form
        id={modalOptions.id}
        initialValues={initialValues}
        isFetching={isFetching}
        errorMessage={errorMessage}
        modalOptions={modalOptions}
        onSubmit={onSubmit}
        buttonRef={buttonRef}
      />
    </Modal>
  );
};

AggregatorOfferRejectionReasonsEditModal.defaultProps = {};

AggregatorOfferRejectionReasonsEditModal.propTypes = {
  initialFormValues: PropTypes.object,
  isFetching: PropTypes.bool,
  errorMessage: PropTypes.string,
  modalOptions: PropTypes.object,
  setModalOptions: PropTypes.func,
  traderMarketOfferRejectionReasonsDetails: PropTypes.func.isRequired,
  traderMarketOfferRejectionReasonsDetailsReset: PropTypes.func.isRequired,
  updateTraderMarketOfferRejectionReasonsDetails: PropTypes.func.isRequired,
  addTraderMarketOfferRejectionReasonsDetails: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    initialFormValues: state.traderMarketOfferRejectionReasonsDetails.data,
    isFetching: state.traderMarketOfferRejectionReasonsDetails.isFetching,
    errorMessage: state.traderMarketOfferRejectionReasonsDetails.errorMessage,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    traderMarketOfferRejectionReasonsDetails: (id) => dispatch(traderMarketOfferRejectionReasonsDetails(id)),
    traderMarketOfferRejectionReasonsDetailsReset: () => dispatch(traderMarketOfferRejectionReasonsDetailsReset()),
    addTraderMarketOfferRejectionReasonsDetails: (values) =>
      dispatch(addTraderMarketOfferRejectionReasonsDetails(values, "", true)),
    updateTraderMarketOfferRejectionReasonsDetails: (id, values) =>
      dispatch(updateTraderMarketOfferRejectionReasonsDetails(id, values, "", true)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AggregatorOfferRejectionReasonsEditModal);
