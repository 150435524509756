// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container_dq85vcDwQFnd1whf08Ef {\n  padding: 1rem;\n  border-top: none;\n  color: black;\n  margin-bottom: 10px;\n  background-color: white;\n}\n\n.style_options_Lu28FwD0X9_TRYcoRn5g {\n  padding: 0 5px;\n  display: flex;\n  gap: 10px;\n}\n\n.style_optionsContainer_U2mWUP123NZniQR08XpQ {\n  display: flex;\n  flex-direction: row;\n}\n\n.style_label_eiJ7EpkirH07rTQ2u6jn {\n  margin-top: 3px;\n  height: 30px;\n  font-weight: 500;\n}\n\n.style_filterActionButtons_O2LIJcpBRoLFe0C_DEJT {\n  display: flex;\n  justify-content: right;\n  gap: 10px;\n  margin-right: -3px;\n}", "",{"version":3,"sources":["webpack://./src/containers/Deposits/Filters/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EAEA,YAAA;EACA,mBAAA;EACA,uBAAA;AAAF;;AAGA;EACE,cAAA;EACA,aAAA;EACA,SAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;AAAF;;AAGA;EACE,eAAA;EACA,YAAA;EACA,gBAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,kBAAA;AAAF","sourcesContent":[".container {\n  padding: 1rem;\n  border-top: none;\n  //border-bottom: 1px solid #d3e1e8;\n  color: black;\n  margin-bottom: 10px;\n  background-color: white;\n}\n\n.options {\n  padding: 0 5px;\n  display: flex;\n  gap: 10px;\n}\n\n.optionsContainer {\n  display: flex;\n  flex-direction: row;\n}\n\n.label {\n  margin-top: 3px;\n  height: 30px;\n  font-weight: 500;\n}\n\n.filterActionButtons {\n  display: flex;\n  justify-content: right;\n  gap: 10px;\n  margin-right: -3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container_dq85vcDwQFnd1whf08Ef",
	"options": "style_options_Lu28FwD0X9_TRYcoRn5g",
	"optionsContainer": "style_optionsContainer_U2mWUP123NZniQR08XpQ",
	"label": "style_label_eiJ7EpkirH07rTQ2u6jn",
	"filterActionButtons": "style_filterActionButtons_O2LIJcpBRoLFe0C_DEJT"
};
export default ___CSS_LOADER_EXPORT___;
