// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_exchangeLocationFormContainer_VPAw3rl3KxocJ8d5ToM0 {\n  box-shadow: 2px 8px 24px -6px rgb(66, 68, 90);\n  margin-bottom: 2rem;\n}\n\n.style_exchangeLocationForm_i_WdHu1YfAa3lWBr9hlZ .wfp--form-item {\n  flex-direction: row;\n}", "",{"version":3,"sources":["webpack://./src/containers/TraderDetails/components/ExchangeLocationForm/style.scss"],"names":[],"mappings":"AAAA;EACI,6CAAA;EACA,mBAAA;AACJ;;AAGI;EACI,mBAAA;AAAR","sourcesContent":[".exchangeLocationFormContainer {\n    box-shadow: 2px 8px 24px -6px rgba(66, 68, 90, 1);\n    margin-bottom: 2rem;\n}\n\n.exchangeLocationForm {\n    :global(.wfp--form-item) {\n        flex-direction: row;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"exchangeLocationFormContainer": "style_exchangeLocationFormContainer_VPAw3rl3KxocJ8d5ToM0",
	"exchangeLocationForm": "style_exchangeLocationForm_i_WdHu1YfAa3lWBr9hlZ"
};
export default ___CSS_LOADER_EXPORT___;
