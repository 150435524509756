// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_adminsTable_vogVQCHDQkWIM0vAqRU4 {\n  font-family: \"Open Sans\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  color: #5a6872;\n}\n\n.style_theader_wYiffAnJYdZQ6CdHJKyN,\n.style_trow_TCL1aR3Bw59VHOQNvEEY {\n  padding: 4px 0;\n}\n\n.style_theader_wYiffAnJYdZQ6CdHJKyN {\n  font-weight: 600;\n}\n\n.style_tcell_xUVf1DGJV6vSn5n3Ncbz {\n  padding-left: 2px;\n  overflow: hidden;\n  line-height: 1.4;\n}\n\n.style_tableContainer_YdbujF_lxqraU6KfsbFQ {\n  margin: 24px 0;\n  padding: 16px 0;\n  border-top: 1px solid #dfe3e6;\n  border-bottom: 1px solid #dfe3e6;\n}", "",{"version":3,"sources":["webpack://./src/containers/Aggregators/components/AdminsTable/style.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EAEA,cAAA;AAAF;;AAGA;;EAEE,cAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;;AASA;EACE,iBAAA;EACA,gBAAA;EACA,gBAAA;AANF;;AASA;EACE,cAAA;EACA,eAAA;EACA,6BAAA;EACA,gCAAA;AANF","sourcesContent":[".adminsTable {\n  font-family: \"Open Sans\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  // line-height: 140%;\n  color: #5a6872;\n}\n\n.theader,\n.trow {\n  padding: 4px 0;\n}\n\n.theader {\n  font-weight: 600;\n}\n\n.th {\n  // overflow: hidden;\n}\n.trow {\n}\n\n.tcell {\n  padding-left: 2px;\n  overflow: hidden;\n  line-height: 1.4;\n}\n\n.tableContainer {\n  margin: 24px 0;\n  padding: 16px 0;\n  border-top: 1px solid #dfe3e6;\n  border-bottom: 1px solid #dfe3e6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adminsTable": "style_adminsTable_vogVQCHDQkWIM0vAqRU4",
	"theader": "style_theader_wYiffAnJYdZQ6CdHJKyN",
	"trow": "style_trow_TCL1aR3Bw59VHOQNvEEY",
	"tcell": "style_tcell_xUVf1DGJV6vSn5n3Ncbz",
	"tableContainer": "style_tableContainer_YdbujF_lxqraU6KfsbFQ"
};
export default ___CSS_LOADER_EXPORT___;
