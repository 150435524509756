import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import FormWrapper from "../../../../../../components/FormWrapper";
import { Col, Grid, Row } from "../../../../../../components/Grid";
import { getMandatoryFieldErrors, getRHFErrors } from "../../../../../../utils";
import { withTranslation } from "react-i18next";
import Input from "../../../../../../components/Input";
import Select from "../../../../../../components/Select";
import { Tooltip } from "@wfp/ui";
import style from "../../style.scss";
import { iconInfo } from "@wfp/icons";
import TreePicker from "../../../../../../components/TreePicker";
import { MandatoryLabel } from "../../../../../../components/MandatoryLabel";
import Icon from "../../../../../../components/Icon";
import { languages } from "../../../../../../utils/i18n";
import MultiCheckbox from "../../../../../../components/MultiCheckbox";

const AggregatorDetailsProfile = ({
  methods,
  t,
  showFieldErrors,
  organizationTypes,
  administrativeAreas,
  aggregatorAdministrators,
}) => {
  const {
    control,
    formState: { errors },
    watch,
    getValues,
  } = methods;
  const locationValue = watch("location");

  return (
    <FormWrapper>
      <Grid>
        <Row>
          <Col sm={6}>
            <Controller
              control={control}
              render={({ field }) => (
                <Input
                  input={field}
                  meta={{ error: "" }}
                  id="name-input"
                  placeholder={t("aggregatorDetails.form.insertName")}
                  label={
                    <MandatoryLabel
                      label={t("aggregatorDetails.form.center")}
                    />
                  }
                  errorMessage={
                    showFieldErrors
                      ? t(getMandatoryFieldErrors(errors, t, getValues, "name"))
                      : ""
                  }
                />
              )}
              name="name"
              id="name"
              rules={{ required: true }}
            />
          </Col>
          <Col sm={6}>
            <Controller
              control={control}
              render={({ field }) => (
                <Select
                  id="org_type-select"
                  input={field}
                  errorMessage={
                    showFieldErrors
                      ? t(
                        getMandatoryFieldErrors(
                          errors,
                          t,
                          getValues,
                          "org_type",
                        ),
                      )
                      : ""
                  }
                  label={
                    <MandatoryLabel
                      label={t("aggregatorDetails.form.organizationType")}
                    />
                  }
                  placeholder={t(
                    "aggregatorDetails.form.organizationTypePlaceholder",
                  )}
                  options={organizationTypes}
                  loading={false}
                />
              )}
              name="org_type"
              id="org_type"
              rules={{ required: true }}
            />
          </Col>
        </Row>
      </Grid>
      <Grid>
        <Row>
          <Col sm={6}>
            <Controller
              control={control}
              render={({ field, fieldState }) => (
                <MultiCheckbox
                  label={
                    <MandatoryLabel
                      label={t(
                        "aggregatorDetails.form.individualsManagedByTheAggregator",
                      )}
                    />
                  }
                  sublabel={t("aggregatorDetails.form.selectAtLeastOneOption")}
                  id={"exchange-location-types"}
                  options={[
                    {
                      id: "farmers",
                      value: "farmers",
                      name: t("aggregatorDetails.form.individuals.farmers"),
                      disabled: aggregatorAdministrators && aggregatorAdministrators.length && aggregatorAdministrators.some(admin => admin.account_type === "aggregator_admin"),
                    },
                    {
                      id: "mastercard_foundation_participants",
                      value: "mastercard_foundation_participants",
                      name: t(
                        "aggregatorDetails.form.individuals.mastercard_foundation_participants",
                      ),
                      disabled: aggregatorAdministrators && aggregatorAdministrators.length && aggregatorAdministrators.some(admin => admin.account_type === "mastercard_foundation_admin"),
                    },
                  ]}
                  vertical
                  name={"managed_individuals"}
                  labelInFront
                  errorMessage={
                    showFieldErrors
                      ? t(
                        getMandatoryFieldErrors(
                          errors,
                          t,
                          getValues,
                          "managed_individuals",
                        ),
                      )
                      : ""
                  }
                  input={field}
                  meta={fieldState}
                />
              )}
              name="managed_individuals"
              id="managed_individuals"
              rules={{ required: true }}
            />
          </Col>
        </Row>
      </Grid>
      <Grid>
        <Row>
          <Col sm={12}>
            <Controller
              control={control}
              render={({ field }) => (
                <Select
                  id="language-select"
                  input={field}
                  errorMessage={
                    showFieldErrors
                      ? t(
                        getMandatoryFieldErrors(
                          errors,
                          t,
                          getValues,
                          "language",
                        ),
                      )
                      : ""
                  }
                  label={
                    <MandatoryLabel
                      label={t("aggregatorDetails.form.language")}
                    />
                  }
                  placeholder={t("aggregatorDetails.form.primaryLanguage")}
                  options={languages.map((language) => {
                    return {
                      label: language.engLabel
                        ? `${language.engLabel} (${language.label})`
                        : language.label,
                      value: language.value,
                    };
                  })}
                  loading={false}
                />
              )}
              name="language"
              id="language"
              rules={{ required: true }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <MandatoryLabel
              className="wfp--label"
              label={t("aggregatorDetails.form.location")}
            />
            <Tooltip
              className={style.tooltip}
              content={t("aggregatorDetails.form.locationHintTooltip")}
              createRefWrapper
              modifiers={[]}
              placement="top"
              trigger="click"
            >
              <Icon width="20" className={style.infoIcon} icon={iconInfo} />
            </Tooltip>
            <Controller
              control={control}
              render={({ field }) => (
                <TreePicker
                  input={field}
                  label={""}
                  placeholder={
                    locationValue
                      ? t("aggregatorDetails.form.addLevelOptional", {
                        level: locationValue.children
                          ? [
                            ...new Set(
                              locationValue.children.map((e) => e.type),
                            ),
                          ].join(" / ")
                          : "Level",
                      })
                      : t("aggregatorDetails.form.locationPlaceholder")
                  }
                  errorMessage={
                    showFieldErrors
                      ? t(
                        getMandatoryFieldErrors(
                          errors,
                          t,
                          getValues,
                          "location",
                        ),
                      )
                      : ""
                  }
                  id="location-treePicker"
                  tree={administrativeAreas}
                />
              )}
              name="location"
              id="location"
              rules={{ required: true }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="wfp--label">
              {t("aggregatorDetails.form.centerPhysicalAddress")}
            </div>
            <Tooltip
              className={style.tooltip}
              content={t("aggregatorDetails.form.centerPhysicalAddressInfoTip")}
              createRefWrapper
              modifiers={[]}
              placement="top"
              trigger="click"
            >
              <Icon width="20" className={style.infoIcon} icon={iconInfo} />
            </Tooltip>
            <Controller
              control={control}
              render={({ field, fieldState }) => (
                <Input
                  input={field}
                  meta={fieldState}
                  errorMessage={getRHFErrors(errors, "physical_address")}
                  placeholder={t(
                    "aggregatorDetails.form.insertPhysicalAddress",
                  )}
                  id="physical_address-input"
                />
              )}
              name="physical_address"
              id="physical_address"
            />
          </Col>
        </Row>
      </Grid>
    </FormWrapper>
  );
};

AggregatorDetailsProfile.propTypes = {
  methods: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  showFieldErrors: PropTypes.bool,
  organizationTypes: PropTypes.array,
  administrativeAreas: PropTypes.array,
  aggregatorAdministrators: PropTypes.array,
};

export default withTranslation()(AggregatorDetailsProfile);
