import React, { useState } from "react";
import PropTypes from "prop-types";
import DatePickerComponent from "react-datepicker";
import "./style.scss";

import { displayErrorHelpText } from "../../utils";
import {DATEPICKER_FORMAT_DEFAULT, DATETIMEPICKER_FORMAT_DEFAULT} from "../../constants";
import {getHours, isToday, setHours, setMinutes} from "date-fns";

// This function returns a value or undefined
export const valueOrUndefined = value => value || undefined;

// A DatePicker component used to select a single date
const DatePicker = ({
  id,
  label,
  placeholder,
  withPortal,
  withTime,
  errorMessage,
  isOutsideRange,
  input: { value, onChange },
}) => {

  const [focused, setFocused] = useState({ focused: false });

  return (
    <div className="wfp--form-item">
      {label ? <label htmlFor={id} className="wfp--label">{label}</label> : ""}
      <DatePickerComponent
        id={id}
        placeholderText={placeholder}
        dateFormat={withTime ? DATETIMEPICKER_FORMAT_DEFAULT : DATEPICKER_FORMAT_DEFAULT}
        selected={valueOrUndefined(value)}
        onChange={onChange}
        focused={focused.focused}
        autoComplete="off"
        onFocus={setFocused}
        isOutsideRange={isOutsideRange}
        withPortal={valueOrUndefined(withPortal)}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        isClearable={true}
        showTimeSelect={withTime}
        minDate={withTime ? new Date() : undefined}
        minTime={withTime ? ((value && isToday(value) || !value) ? setHours(setMinutes(new Date(), 0), getHours(new Date()) + 1) : setHours(setMinutes(new Date(), 0), 4)) : undefined}
        maxTime={withTime ? setHours(setMinutes(new Date(), 30), 18) : undefined}
      />
      {displayErrorHelpText(errorMessage)}
    </div>
  );
};

// propTypes for the DatePicker component
DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.any.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  withPortal: PropTypes.bool,
  withTime: PropTypes.bool,
  errorMessage: PropTypes.string,
  isOutsideRange: PropTypes.func,
};

// defaultProps for the DatePicker component
DatePicker.defaultProps = {
  placeholder: "",
  label: "",
  withPortal: false,
  withTime: false,
  errorMessage: "",
  isOutsideRange: () => false,
};

export default DatePicker;
