// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_contextMenuItem_LPnMqf97WYix4VZbE9BC {\n  display: flex;\n  align-items: baseline;\n  padding: 2px;\n}\n.style_contextMenuItemLabel_va4c5rLXE1ekg3kztCAe {\n  margin-left: 16px;\n  color: #031c2d;\n}\n\n.style_nativeLabel_Yy9MNdCPsHqviKO5xMgc {\n  font-style: italic;\n}\n\n.style_navigationButton_QUkHeKpVwA3EIbKMMl94 {\n  background-color: #34a3d9;\n}", "",{"version":3,"sources":["webpack://./src/components/RepresentativeMainNavigation/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qBAAA;EACA,YAAA;AACF;AACE;EACE,iBAAA;EACA,cAAA;AACJ;;AAGA;EACE,kBAAA;AAAF;;AAGA;EACE,yBAAA;AAAF","sourcesContent":[".contextMenuItem {\n  display: flex;\n  align-items: baseline;\n  padding: 2px;\n\n  &Label {\n    margin-left: 16px;\n    color: #031c2d;\n  }\n}\n\n.nativeLabel {\n  font-style: italic;\n}\n\n.navigationButton {\n  background-color: #34a3d9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contextMenuItem": "style_contextMenuItem_LPnMqf97WYix4VZbE9BC",
	"contextMenuItemLabel": "style_contextMenuItemLabel_va4c5rLXE1ekg3kztCAe",
	"nativeLabel": "style_nativeLabel_Yy9MNdCPsHqviKO5xMgc",
	"navigationButton": "style_navigationButton_QUkHeKpVwA3EIbKMMl94"
};
export default ___CSS_LOADER_EXPORT___;
