import React from "react";

export default () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 0C4.0111 0 3.0444 0.293245 2.22215 0.842652C1.3999 1.39206 0.759043 2.17295 0.380604 3.08658C0.00216642 4.00021 -0.0968502 5.00555 0.0960758 5.97545C0.289002 6.94535 0.765206 7.83627 1.46447 8.53553C2.16373 9.23479 3.05465 9.711 4.02455 9.90392C4.99445 10.0969 5.99979 9.99783 6.91342 9.6194C7.82705 9.24096 8.60794 8.60009 9.15735 7.77785C9.70676 6.9556 10 5.98891 10 5C9.99851 3.67438 9.47124 2.40347 8.53389 1.46612C7.59653 0.528757 6.32562 0.00149353 5 0ZM2.85714 8.70589V8.21428C2.85746 7.93022 2.97044 7.65788 3.1713 7.45701C3.37217 7.25615 3.64451 7.14317 3.92857 7.14286H6.07143C6.35549 7.14317 6.62783 7.25615 6.8287 7.45701C7.02956 7.65788 7.14255 7.93022 7.14286 8.21428V8.70589C6.49256 9.08561 5.75304 9.28571 5 9.28571C4.24696 9.28571 3.50744 9.08561 2.85714 8.70589ZM7.85446 8.18778C7.84736 7.7193 7.65648 7.27236 7.32296 6.94329C6.98943 6.61422 6.53997 6.42937 6.07143 6.42857H3.92857C3.46004 6.42937 3.01057 6.61422 2.67705 6.94329C2.34352 7.27236 2.15264 7.7193 2.14554 8.18778C1.49788 7.60948 1.04116 6.8481 0.835836 6.00445C0.630516 5.1608 0.686287 4.27469 0.995766 3.46345C1.30524 2.6522 1.85383 1.95409 2.56888 1.46156C3.28394 0.969023 4.13173 0.705295 5 0.705295C5.86827 0.705295 6.71606 0.969023 7.43112 1.46156C8.14617 1.95409 8.69476 2.6522 9.00424 3.46345C9.31371 4.27469 9.36949 5.1608 9.16417 6.00445C8.95885 6.8481 8.50212 7.60948 7.85446 8.18778Z"
      fill="#5A6872"
    />
  </svg>
);
