import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import PageStandard from "../../components/PageStandard";
import List from "./components/List";
import { connect } from "react-redux";
import TermsAndConditionsTextEditModal from "./components/Modal";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import Button from "../../components/Button";
import { iconAdd } from "@wfp/icons";
import { listTermsAndConditionsText, listTermsAndConditionsTextReset } from "../../actions";

const WfpManagerTermsAndConditionsText = ({
  t,
  isFetching,
  errorMessage,
  termsAndConditionsTexts,
  page,
  count,
  listTermsAndConditionsText,
  listTermsAndConditionsTextReset,
}) => {
  const [modalOptions, setModalOptions] = useState({id: undefined, isOpen: false, type: undefined});

  useEffect(() => {
    listTermsAndConditionsText();
    return () => {
      listTermsAndConditionsTextReset();
    };
  }, []);

  const onClickAdd = () => {
    // Reset details
    setModalOptions({isOpen: true, id: "add", type: "create"});
  };
  return (
    <PageStandard
      additional={(
        <ButtonsWrapper>
          <Button icon={iconAdd} onClick={onClickAdd}>
            {t("common.add") + " " + t("configuration.item.termsAndConditionsText")}
          </Button>
        </ButtonsWrapper>
      )}
      title={t("configuration.item.termsAndConditionsText")}
    >
      <List
        {...{
          isFetching,
          errorMessage,
          termsAndConditionsTexts,
          page,
          count,
          setModalOptions,
        }}
      />
      <TermsAndConditionsTextEditModal
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
      />
    </PageStandard>
  );
};

WfpManagerTermsAndConditionsText.propTypes = {
  t: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  termsAndConditionsTexts: PropTypes.array,
  page: PropTypes.number,
  count: PropTypes.number,
  listTermsAndConditionsText: PropTypes.func.isRequired,
  listTermsAndConditionsTextReset: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    termsAndConditionsTexts: state.listTermsAndConditionsText.data.results,
    isFetching: state.listTermsAndConditionsText.isFetching,
    errorMessage: state.listTermsAndConditionsText.errorMessage,
    page: 1,
    count: state.listTermsAndConditionsText.data.count,
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    listTermsAndConditionsText: () => dispatch(listTermsAndConditionsText()),
    listTermsAndConditionsTextReset: () => dispatch(listTermsAndConditionsTextReset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WfpManagerTermsAndConditionsText));
