import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { Col, Grid, Row } from "../../../../../../components/Grid";
import Input from "../../../../../../components/Input";
import { MandatoryLabel } from "../../../../../../components/MandatoryLabel";
import Select from "../../../../../../components/Select";
import TreePicker from "../../../../../../components/TreePicker";
import { getErrors, getRHFErrors } from "../../../../../../utils";
import { languages } from "../../../../../../utils/i18n";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Icon from "../../../../../../components/Icon";
import { Tooltip } from "@wfp/ui";
import style from "../../style.scss";
import { iconInfo } from "@wfp/icons";
import Toggle from "../../../../../../components/Toggle";

const TraderDetailsStepOne = ({
  methods,
  administrativeAreas,
  showBlankFieldErrors,
  fieldErrors,
  t,
}) => {
  const { formState: { errors }, watch, control, trigger } = methods;

  const locationValue = watch("location");
  const nameValue = watch("name");
  const languageValue = watch("language");
  const isWfpVendorValue = watch("is_wfp_vendor");

  useEffect(() => {
    trigger();
  }, [nameValue, locationValue, languageValue, isWfpVendorValue]);

  const getMandatoryFieldErrors = (fieldName) => {
    const serverSideErrors = getErrors(fieldErrors, fieldName);
    if (serverSideErrors) {
      return serverSideErrors;
    }
    if (showBlankFieldErrors && getRHFErrors(errors, fieldName)) {
      return t("traderDetails.form.mandatoryFieldBlankError");
    }
  };

  return (
    <Grid>
      <Row>
        <Col sm={12}>
          <Controller
            control={control}
            render={({field}) => (
              <Input
                input={field}
                meta={{error: ""}}
                id="name-input"
                label={<MandatoryLabel label={t("traderDetails.form.name")} />}
                errorMessage={showBlankFieldErrors ? t(getMandatoryFieldErrors("name")) : ""}
              />
            )}
            name="name"
            id="name"
            rules={{
              required: true,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Controller
            control={control}
            render={({field}) => (
              <Select
                id="language-select"
                input={field}
                errorMessage={showBlankFieldErrors ? t(getMandatoryFieldErrors("language")) : ""}
                label={<MandatoryLabel label={t("aggregatorDetails.form.language")} />}
                placeholder={t("traderDetails.form.primaryLanguage")}
                options={languages.map(language => {
                  return {
                    label: language.engLabel ? `${language.engLabel} (${language.label})` : language.label,
                    value: language.value,
                  };
                })} loading={false}
              />
            )}
            name="language"
            id="language"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <MandatoryLabel className="wfp--label" label={t("traderDetails.form.administrativeArea")} />
          <Tooltip
            className={style.tooltip}
            content={t("traderDetails.form.locationHintTooltip")}
            createRefWrapper
            modifiers={[]}
            placement="top"
            trigger="click"
          >
            <Icon width="20" className={style.infoIcon} icon={iconInfo}/>
          </Tooltip>
          <Controller
            control={control}
            render={({field}) => (
              <TreePicker
                input={field}
                label={""}
                placeholder={locationValue ? t("aggregatorDetails.form.addLevelOptional", {
                  level: locationValue.children ? [...new Set(locationValue.children.map(e => e.type))].join(" / ") : "Level",
                }) : t("aggregatorDetails.form.locationPlaceholder")}
                errorMessage={showBlankFieldErrors ? t(getMandatoryFieldErrors("location")) : ""}
                id="location-treePicker"
                tree={administrativeAreas}
              />
            )}
            name="location"
            id="location"
            rules={{
              required: true,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <div className="wfp--label">
            {t("traderDetails.form.traderPhysicalAddress")}
          </div>
          <Tooltip
            className={style.tooltip}
            content={t("traderDetails.form.traderPhysicalAddressInfoTip")}
            createRefWrapper
            modifiers={[]}
            placement="top"
            trigger="click"
          >
            <Icon width="20" className={style.infoIcon} icon={iconInfo}/>
          </Tooltip>
          <Controller
            control={control}
            render={({field, fieldState}) => (
              <Input
                input={field}
                meta={fieldState}
                errorMessage={getRHFErrors(
                  errors,
                  "physical_address",
                )}
                id="physical_address-input"
              />
            )}
            name="physical_address"
            id="physical_address"
          />
        </Col>
      </Row>
      <Row>
        <Col sm={4}>
          <Controller
            control={control}
            render={({ field }) => (
              <Toggle
                input={field}
                label={t("traderDetails.form.isWfpVendor")}
                errorMessage={showBlankFieldErrors ? getErrors(errors, "is_wfp_vendor") : ""}
                id="is_wfp_vendor-toggle"
              />
            )}
            name="is_wfp_vendor"
            id="is_wfp_vendor"
          />
        </Col>
        {isWfpVendorValue && <Col sm={4}>
          <Controller
            control={control}
            render={({ field, fieldState }) => (
              <Input
                input={field}
                meta={fieldState}
                label={t("traderDetails.form.wingsId")}
                errorMessage={showBlankFieldErrors ? getErrors(errors, "wings_id") : ""}
                id="wings_id-input"
              />
            )}
            name="wings_id"
            id="wings_id"
          />
        </Col>}
      </Row>
    </Grid>
  );
};

TraderDetailsStepOne.propTypes = {
  methods: PropTypes.object.isRequired,
  administrativeAreas: PropTypes.array,
  showBlankFieldErrors: PropTypes.bool.isRequired,
  fieldErrors: PropTypes.object,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(TraderDetailsStepOne);
