// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "icon {\n  margin-right: 10px;\n}\n\n.style_container_YCEpRB481Z4OVoeHHgOT {\n  width: auto;\n}", "",{"version":3,"sources":["webpack://./src/containers/Deposits/DepositsList/style.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,WAAA;AACJ","sourcesContent":["icon {\n    margin-right: 10px;\n}\n\n.container {\n    width: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container_YCEpRB481Z4OVoeHHgOT"
};
export default ___CSS_LOADER_EXPORT___;
