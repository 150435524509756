import React from "react";
import PropTypes from "prop-types";
import { Icon as WFPIcon } from "@wfp/ui";

// A wrapper of the Icon component form WFP UI Kit
const Icon = ({ icon, fill, width, height, style, description }) => (
  <WFPIcon
    icon={icon}
    fill={fill}
    width={width}
    height={height}
    style={style}
    description={description}
  />
);

// propTypes for the Icon component
Icon.propTypes = {
  icon: PropTypes.any.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  style: PropTypes.object,
  fill: PropTypes.string,
  description: PropTypes.string,
};

// defaultProps for the Icon component
Icon.defaultProps = {
  style: {},
  fill: "#0a6eb4",
  description: "",
};

export default Icon;
