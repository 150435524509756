// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_wrapper_CB1aW0vvSwyKGZuSRDD2 {\n  margin-bottom: 1rem;\n}", "",{"version":3,"sources":["webpack://./src/components/MessageSuccess/style.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF","sourcesContent":[".wrapper {\n  margin-bottom: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "style_wrapper_CB1aW0vvSwyKGZuSRDD2"
};
export default ___CSS_LOADER_EXPORT___;
