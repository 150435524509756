import { Deposit } from "../../../Deposits/DepositCard/deposit-dtos";
import {NegotiationProposal} from "../OfferCommentsModal/NegotiatedDetailsCard/offer-negotiation-proposal";
import {OfferCommentDto} from "../OfferCommentsModal/OfferCommentCard";

    export interface NameTranslations {
        en: string;
    }

    export interface BuyCommodity {
        id: number;
        name_translations: NameTranslations;
        name: string;
        wings_id: string;
        countries: number[];
        units: number[];
    }

    export interface Currency {
        id: number;
        code: string;
        symbol: string;
        name: string;
    }

    export interface UnitOfMeasure {
        id: number;
        name: string;
        code: string;
        type: string;
    }

    export interface Trader {
        id: number;
        name: string;
        contact_email: string;
    }

    export interface Person {
        first_name: string;
        middle_name: string;
        last_name: string;
        contact_phone: string;
    }

    export interface TraderRepresentative {
        id: number;
        person: Person;
        position: string;
        ciam_user_created: boolean;
        terms_of_service_accepted_date: Date;
        login_user: number;
    }

    export interface CustomDeliveryLocationDetails {
        active: boolean;
        id: number;
        location: number;
        name: string;
        name_translations: any;
        physical_address: string;
    }

    export interface Offer {
        id: number;
        buy_commodity: BuyCommodity;
        currency: Currency;
        price: string;
        minimum_quantity: string;
        maximum_quantity: string;
        negotiable: boolean;
        unit_of_measure: UnitOfMeasure;
        quality: string;
        last_update_datetime: string;
        status: OfferStatus;
        offer_type: string;
        valid_until: string;
        trader: Trader;
        orders: {
            deposit: Deposit
        }[],
        deposit?: Deposit;
        trader_representative: TraderRepresentative;
        tagged_aggregator: {
            id: number,
            name: string,
        }
        publishing_date: string;
        delivery_location: string;
        comment?: string;
        contract_number: string;
        planned_exchange_time?: any;
        exchange_confirmation_time?: string;
        latest_negotiation_proposal: NegotiationProposal;
        latest_comment: OfferCommentDto;
        payment_time_option?: OfferPaymentTimeOption;
        custom_delivery_location_details?: CustomDeliveryLocationDetails;
        custom_delivery_location: number;
        custom_delivery_location_type: number;
    }

    export enum OfferStatus {
        New = "NEW",
        Accepted = "ACCEPTED",
        Rejected = "REJECTED",
        OfferDone = "READY_FOR_EXCHANGE",
        ExchangeAgreed = "EXCHANGE_AGREED",
        ExchangeDone = "EXCHANGE_DONE",
        Recalled = "RECALLED",
        Negotiating = "NEGOTIATING",
        ExchangeDetailsUpdated = "EXCHANGE_DETAILS_UPDATED",
        ExchangeDetailsDisputed = "EXCHANGE_DETAILS_DISPUTED",
        ExchangePaymentPending = "EXCHANGE_PAYMENT_PENDING",
    }

    export enum OfferPaymentTimeOption {
        ReceiveUponExchange = "RECEIVE_UPON_EXCHANGE",
        ReceiveOnFutureDate = "RECEIVE_ON_FUTURE_DATE",
    }
