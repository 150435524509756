import Axios from "axios";
import { v4 as uuidv4 } from "uuid";
import jwtDecode from "jwt-decode";

import client from "../client";
import { ServerUserProfile } from "../constants";

const API_URL_USER_DETAIL = "/api/user/";

// Creates a client without interceptors to prevent redirect to login before
// actually save the JWT token
export const authenticationClient = Axios.create({
  baseURL: process.env.API_BASE_URL,
  responseType: "json",
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json",
  },
});

// Call the user endpoint and retrieve the user infos
export const getUserInformation = () => (
  client.get(API_URL_USER_DETAIL).then(response => {
    return response.data;
  })
);

// Here we add the authentication header to client to be used in each request
// made by the client
export const setupClient = token => (
  client.defaults.headers["Authorization"] = `Bearer ${token.replace("\"", "")}`
);

// A function to add an header that contains the country WFP Manager selected
// to filter all requests by that country
export const setupCountry = country => (
  client.defaults.headers["org.wfp.farm2go.country"] = country
);

// A function to check if token is expired
export const isTokenExpired = expiryDate => {
  const currentDate = new Date();
  return currentDate > expiryDate;
};

// A function to get the name of the logged in user
export const getUserDescription = user => user.full_name || user.username;

// A function to generate a random nonce
export const generateNonce = () => uuidv4();

// A function to check if the URL contains the access token from CIAM
export const getAccessTokenByUrl = url => {
  const start = url.indexOf("access_token=");
  const end = url.indexOf("&", start);
  return url.slice(start + 13, end);
};

// A function to check if the URL contains the id token form CIAM
export const getIdTokenByUrl = url => {
  const start = url.indexOf("id_token=");
  const end = url.indexOf("&", start);
  return url.slice(start + 9, end);
};

// A function to extract the expires_in field in seconds
export const getExpiresInByUrl = url => {
  const start = url.indexOf("expires_in=");
  const end = url.indexOf("&", start);
  return url.slice(start + 11, end);
};

// A function to check get the nonce paramter contained in the URL
export const getNonceByUrl = url => {
  const start = url.indexOf("id_token=");
  const end = url.indexOf("&", start);
  const jwt = url.slice(start + 9, end);
  return jwtDecode(jwt).nonce;
};

// A function used to read the authentication URL starting from environment
// variables
export const getLoginUrl = nonce => (
  process.env.AUTHENTICATION_URL
  + `/oauth2/authorize?client_id=${process.env.AUTHENTICATION_CLIENT_ID}`
  + `&redirect_uri=${encodeURIComponent(`${window.location.origin}/ciam_callback/`)}`
  + `&response_type=${encodeURIComponent("id_token token")}`
  + "&scope=openid"
  + `&nonce=${nonce}`
);

export const isUserWfpManager = (user_profile) => ServerUserProfile.WFP_MANAGER === user_profile;
export const isUserRepresentative = (user_profile) => ServerUserProfile.REPRESENTATIVE === user_profile;
export const isUserAdministrator = (user_profile) => ServerUserProfile.ADMINISTRATOR === user_profile;
export const isUserGuest = (user_profile) => {
  return ServerUserProfile.GUEST === user_profile;
};

// Function to redirect users based on user_profile
export const redirectToCorrectLocation = (user_profile, history) => {
  switch(user_profile){
  case ServerUserProfile.WFP_MANAGER:
    history.push("/wfpManager");
    break;
  case ServerUserProfile.REPRESENTATIVE:
    history.push("/representative");
    break;
  default:
    history.push("/loginForbidden/");
    break;
  }
};
