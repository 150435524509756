import React from "react";
import PropTypes from "prop-types";

import Module from "../../../../components/Module";
import Loading from "../../../../components/Loading";
import { displayErrorMessage } from "../../../../utils";
import { Grid, Row, Col } from "../../../../components/Grid";
import style from "./style.scss";
import {withTranslation} from "react-i18next";

// The details of an aggregator
export const Details = (
  {
    isFetching,
    errorMessage,
    aggregatorCreationRequest,
    t,
  },
) => {

  if (isFetching) {
    return <Loading isVisible />;
  } else if (errorMessage) {
    return displayErrorMessage(errorMessage);
  } else {
    return (
      <Module title={t("aggregatorCreationRequestView.details.title")}>
        <Grid>
          <Row>
            <Col sm={4}>
              <table className={style.details}>
                <tbody>
                  <tr>
                    <td>{t("column.profile")}</td>
                    <td>{aggregatorCreationRequest.aggregator.org_type}</td>
                  </tr>
                  <tr>
                    <td>{t("column.name")}</td>
                    <td>{aggregatorCreationRequest.aggregator.name}</td>
                  </tr>
                  <tr>
                    <td>{t("column.legalStatus")}</td>
                    <td>{aggregatorCreationRequest.aggregator.legal_status}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col sm={5}>
              <table className={style.details}>
                <tbody>
                  <tr>
                    <td>{t("column.tier")}</td>
                    <td>{aggregatorCreationRequest.aggregator.tier}</td>
                  </tr>
                  <tr>
                    <td>{t("column.contactEmail")}</td>
                    <td>{aggregatorCreationRequest.aggregator.contact_email}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Grid>
      </Module>
    );
  }
};

// propTypes for the Details component
Details.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  aggregatorCreationRequest: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

// defaultProps for the Details component
Details.defaultProps = {
  errorMessage: "",
};

export default withTranslation(Details);
