import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import style from "./style.scss";

export interface StatisticRecord {
  value: number | string;
  label: string;
  change?: number;
}

interface Props {
  heading: string;
  records: StatisticRecord[];
  expandable?: boolean;
}

const SummaryStatisticsCard: React.FC<Props> = ({
  heading,
  records,
  expandable = false,
}) => {
  const { t } = useTranslation();

  const [isExpanded, expand] = useState(false);
  const handleToggle = () => {
    expand((prevState) => !prevState);
  };

  return (
    <div className={style.card}>
      <div className={style.heading}>
        {t("home.systemActivitySummary.cards." + heading)}
      </div>
      <div className={style.recordsList}>
        {records?.length &&
          records
            .map((record: StatisticRecord) => {
              return (
                <div className={style.record}>
                  <span className={style.recordValue}>{record.value}</span>
                  <span className={style.recordLabel}>
                    {t("home.systemActivitySummary.cards." + record.label)}
                  </span>
                  {record.change !== undefined && (
                    <span className={style.recordLabel}>{record.change}</span>
                  )}
                </div>
              );
            })
            .slice(0, expandable && !isExpanded ? 3 : records?.length)}
        {expandable && (
          <a className={style.link} onClick={handleToggle}>
            {t(
              isExpanded
                ? "home.systemActivitySummary.cards.showLess"
                : "home.systemActivitySummary.cards.viewAll"
            )}
          </a>
        )}
      </div>
    </div>
  );
};

export default SummaryStatisticsCard;
