import Axios from "axios";
import { redirect } from "../utils";
import { isTokenExpired } from "../utils/authentication";

export const COUNTRY_HEADER_NAME = "org.wfp.farm2go.country";
export const AX_OMIT_COUNTRY_HEADER_NAME = "omit_country_header";

// Default headers to be used for request
const headers = {
  "Accept": "application/json;version=2",
  "Content-Type": "application/json",
};

// Creates the default axios client with some defaults
const client = Axios.create({
  baseURL: process.env.API_BASE_URL,
  responseType: "json",
  headers,
});

client.interceptors.request.use(async (config) => {
  if (config.headers[AX_OMIT_COUNTRY_HEADER_NAME]){
    delete config.headers[COUNTRY_HEADER_NAME];
    delete config.headers[AX_OMIT_COUNTRY_HEADER_NAME];
  } else {
    const countryCode = localStorage.getItem("country_code");
    config.headers[COUNTRY_HEADER_NAME] = countryCode;
  }
  return config;
});

// Function to redirect to login if user is not logged in
export const redirectToLogin = () => {
  redirect("/login");
};

// An interceptor used to redirect user to login page or error page
// if it is not logged in due to token expiration
export const redirectInterceptor = config => {
  const ciamAccessToken = localStorage.getItem("ciam_access_token");
  const expiryDate = new Date(localStorage.getItem("expiry_date"));
  if (!ciamAccessToken || isTokenExpired(expiryDate)) {
    redirectToLogin();
  }
  return config;
};

export const countryHeaderInterceptor = config => {
  // allow accessing the country agnostic api if this header is explicitly set to empty
  if (config.headers[AX_OMIT_COUNTRY_HEADER_NAME]) {
    config.headers[COUNTRY_HEADER_NAME] = "";
  } else {
    const countryCode = localStorage.getItem("country_code");
    config.headers[COUNTRY_HEADER_NAME] = countryCode;
  }
  return config;
};

// Setup interceptors to check if user is logged in before each request
// All axios requests will fire this interceptor,
// even those not called by this client.

client.interceptors.request.use(redirectInterceptor);
client.interceptors.request.use(countryHeaderInterceptor);

export default client;
