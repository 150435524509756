import React from "react";
import { Wrapper, SecondaryNavigation, SecondaryNavigationTitle, Tabs, Tab } from "@wfp/ui";
import PropTypes from "prop-types";

import Breadcrumb from "../../components/Breadcrumb";
import style from "./style.scss";

// A page component with a title, some tabs and a children as prop
const PageTabs = ({ title, children, additional, tabs, selected }) => (
  <>
    <SecondaryNavigation pageWidth="full" additional={additional}>
      <Breadcrumb />
      <SecondaryNavigationTitle>
        {title}
      </SecondaryNavigationTitle>
      <Tabs
        inline
        selected={selected}
      >
        {tabs.map((tab) => <Tab key={tab.id} label={tab.label} onClick={tab.onClick} />)}
      </Tabs>
    </SecondaryNavigation>
    <Wrapper pageWidth="lg" spacing="md" background="lighter">
      <div className={style.wrapper}>
        {children}
      </div>
    </Wrapper>
  </>
);

// propTypes for the PageTabs component
PageTabs.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  additional: PropTypes.node,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
  })).isRequired,
  selected: PropTypes.number,
};

// defaultProps for PageTabs component
PageTabs.defaultProps = {
  additional: undefined,
  selected: 0,
};

export default PageTabs;
