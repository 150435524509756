import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Loading from "../../../components/Loading";
import axios from "axios";
import { setLocalStorage } from "../utils";

export const AccountDeletionVerification = ({ match, history }) => {
  const verifyUUID = async (uuid) => {
    await axios.request({
      url: process.env.API_BASE_URL + "api/account_deletion/" + uuid + "/verify/",
      method: "post",
      validateStatus: false,
    }).then((response) => {
      if (response.status === 200) {
        history.push("/accountDeletion/feedback");
      } else if (response.status === 404) {
        history.push("/accountDeletion", { isExpired: true });
      } else if (response.data.error || response.status === 400) {
        history.push("/accountDeletion", {
          errorMessage: response.data.error,
        });
      }
    });
  };

  useEffect(async () => {
    setLocalStorage();
    const uuid = match && match.params && match.params.uuid;
    await verifyUUID(uuid);
  }, []);

  return <Loading isVisible />;
};

AccountDeletionVerification.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(AccountDeletionVerification);
