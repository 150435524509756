import React from "react";
import PropTypes from "prop-types";
import MDEditor from "@uiw/react-md-editor";

// Style import to un-overwrite the default style for lists.
// Without this styling lists are not rendered correctly.
import style from "./style.scss";

export default function MarkdownPreview({
  source,
}) {
  const transformLinkUri = (link) => {
    if (!link.startsWith("http://") && !link.startsWith("https://")) {
      link = "https://" + link;
    }
    return link;
  };

  return (
    <div className={style.markdownPreview}>
      <MDEditor.Markdown
        source={source}
        linkTarget={"_blank"}
        transformLinkUri={transformLinkUri}
        wrapperElement={{
          "data-color-mode": "light",
        }}
      />
    </div>
  );
}

MarkdownPreview.propTypes = {
  source: PropTypes.string,
};
