import React from "react";
import { useTranslation, withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Loading from "../../../../components/Loading";
import Module from "../../../../components/Module";
import Table from "../../../../components/Table";
import { displayErrorMessage } from "../../../../utils";
import { boolIconCell, generateDateTimeByUserCell } from "../../../../utils/table";
import { withRouter } from "react-router-dom";
import { displaySelectedCountryTranslation } from "../../../../utils/i18n";

// The list of Aggregators that user can view and delete
export const List = ({
  isFetching,
  errorMessage,
  commodityQualityGrades,
  page,
  count,
  // setModalOptions,
  // setDeleteModalOptions,
}) => {
  const { t } = useTranslation();

  if (isFetching) {
    return <Loading isVisible />;
  }

  if (errorMessage) {
    return <>{displayErrorMessage(errorMessage)}</>;
  }

  const columns = [
    {
      Header: "configuration.common.name",
      accessor: "name",
      width: 400,
    },
    {
      Header: "commodities.list.column.translation",
      accessor: "",
      width: 600,
      Cell: ({row: { original }}) => {
        return displaySelectedCountryTranslation(original, "name_translations", t);
      },
    },
    {
      Header: "configuration.common.code",
      accessor: "code",
      width: 100,
    },
    {
      Header: "configuration.list.column.lastUpdated",
      accessor: "lastUpdated",
      width: 400,
      Cell: ({ row: { original } }) => generateDateTimeByUserCell(original.last_update_datetime, original.last_update_user, t),
    },
    {
      Header: "configuration.list.column.created",
      accessor: "created",
      width: 400,
      Cell: ({ row: { original } }) => generateDateTimeByUserCell(original.creation_datetime, original.creation_user, t),
    },
    {
      Header: "configuration.common.active",
      accessor: "active",
      Cell: boolIconCell,
    },
    // Commented out due to: User Story 200864: WFP Manager web - Hide commodity quality grade CTAs
    // {
    //   width: 80,
    //   accessor: "view",
    //   Cell: ({row: { original: record }}) => generateViewButton(
    //     record,
    //     () => setModalOptions({id: record.id, isOpen: true, type: "view"}),
    //     "View",
    //     t,
    //   ),
    //   Header: "",
    // },
    // {
    //   width: 80,
    //   accessor: "view",
    //   Cell: ({row: { original: record }}) => generateViewButton(
    //     record,
    //     () => setModalOptions({id: record.id, isOpen: true, type: "edit"}),
    //     "Edit",
    //     t,
    //   ),
    //   Header: "",
    // },
    // {
    //   width: 80,
    //   accessor: "view",
    //   Cell: ({row: { original: record }}) => generateDeleteButton(
    //     record,
    //     () => setDeleteModalOptions({id: record.id, isOpen: true}),
    //     "Delete",
    //     t,
    //   ),
    //   Header: "",
    // },
  ];

  return (
    <>
      <Module
        table={
          <Table
            {...{
              columns,
              data: commodityQualityGrades,
              loading: isFetching,
              page,
              totalItems: count,
            }}
          />
        }
      />
    </>
  );
};

// propTypes for the List component
List.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  commodityQualityGrades: PropTypes.list,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  setModalOptions: PropTypes.func.isRequired,
  setDeleteModalOptions: PropTypes.func.isRequired,
};

// defaultProps for the List component
List.defaultProps = {
  errorMessage: "",
};

export default withTranslation()(withRouter(List));
