// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_helpMessage_nBYX3b7DD9nHgXbpxhs1 {\n  box-shadow: inset 5px 0 0 0 #007DBC;\n  background: #FFFFFF;\n  padding: 12px 12px 12px 40px;\n  display: flex;\n  align-items: center;\n  margin: 0 16px;\n}\n\n.style_helpIcon_lCNqvUorPlmwcwbKECxw {\n  height: 100%;\n  margin-right: 40px;\n}\n\n.style_helpTitle_zbElcoevbx2yYWVaEPb1 {\n  font-weight: 600;\n  margin-bottom: 8px;\n}\n\n.style_helpBody_A3rM_X7uQWBdBp4TrjQw {\n  font-size: 14px;\n  width: 100%;\n}\n\n.style_background_yziOzpGCwk17WRUWWUhz {\n  background-color: #EBF1F4;\n  padding: 20px 0;\n}", "",{"version":3,"sources":["webpack://./src/components/HelpMessage/style.scss"],"names":[],"mappings":"AAAA;EACE,mCAAA;EACA,mBAAA;EACA,4BAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;AACF;;AAEA;EACE,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,eAAA;EACA,WAAA;AACF;;AAEA;EACE,yBAAA;EACA,eAAA;AACF","sourcesContent":[".helpMessage {\n  box-shadow: inset 5px 0 0 0 #007DBC;\n  background:#FFFFFF;\n  padding: 12px 12px 12px 40px;\n  display: flex;\n  align-items: center;\n  margin: 0 16px;\n}\n\n.helpIcon {\n  height: 100%;\n  margin-right: 40px;\n}\n\n.helpTitle {\n  font-weight: 600;\n  margin-bottom: 8px;\n}\n\n.helpBody {\n  font-size: 14px;\n  width: 100%;\n}\n\n.background {\n  background-color: #EBF1F4;\n  padding: 20px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpMessage": "style_helpMessage_nBYX3b7DD9nHgXbpxhs1",
	"helpIcon": "style_helpIcon_lCNqvUorPlmwcwbKECxw",
	"helpTitle": "style_helpTitle_zbElcoevbx2yYWVaEPb1",
	"helpBody": "style_helpBody_A3rM_X7uQWBdBp4TrjQw",
	"background": "style_background_yziOzpGCwk17WRUWWUhz"
};
export default ___CSS_LOADER_EXPORT___;
