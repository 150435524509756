// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_errorMessage_TcqWQN9vluj2SFe7pLLr {\n  margin-top: 0.2rem;\n  font-size: 0.7rem;\n  color: red;\n  position: relative;\n  order: 99;\n}", "",{"version":3,"sources":["webpack://./src/components/ErrorHelpText/style.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,iBAAA;EAEA,UAAA;EACA,kBAAA;EACA,SAAA;AAFF","sourcesContent":["//@import \"~@wfp/ui/src/globals/scss/vars\";\n\n.errorMessage {\n  margin-top: 0.2rem;\n  font-size: 0.7rem;\n  //color: $color__dark-red;\n  color: red;\n  position: relative;\n  order: 99;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorMessage": "style_errorMessage_TcqWQN9vluj2SFe7pLLr"
};
export default ___CSS_LOADER_EXPORT___;
