import React, {useMemo} from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import Button from "../../../../components/Button";
import {DATE_FORMAT_DEFAULT_WITH_TIME, DATE_FORMAT_ISO_STRING, OFFER_TYPES,} from "../../../../constants";
import {dateToString, formatNumber, getCurrencySymbolFromCache, getLastOfferUpdateDatetime} from "../../../../utils";
import {getLocalisedName} from "../../../../utils/i18n";
import {Offer, OfferStatus} from "./interfaces";

import style from "./style.scss";
import ProgressBar from "../SeeProgressModal/ProgressBar";
import {NegotiationProposal} from "../OfferCommentsModal/NegotiatedDetailsCard/offer-negotiation-proposal";
import {UserGroup} from "../../../../utils/roles";

const ignore = () => {};

export type AddressHolder = {
  name: string,
  physical_address: string,
  [key: string]: any,
}

const OfferCard = (props: {
  offer: Offer;
  onSeeProgressClick: (offer: Offer) => void;
  onClickMakeOffer?: () => void;
  onClickSeeReasons?: (offer: Offer) => void;
  onShowRecall: (id: number) => void;
  onClickAggregatorDetails: (aggregator_id: number) => void;
  hideActions?: boolean;
  shortVersion?: boolean;
  listAggregators: [];
  listMineAggregators: [];
  listTraders: [];
  customExchangeLocations: [],
  customExchangeLocationTypes: [],
  administrativeAreas: []
}) => {
  const { t } = useTranslation();
  if (!props.offer?.id) {
    return <div/>;
  }
  // returns last proposal assigned to  offer, if negotiations are in progress, if no: undefined
  const lastProposal: NegotiationProposal | undefined = (
      props.offer?.status === OfferStatus.Negotiating
      || props.offer?.status === OfferStatus.ExchangeDetailsUpdated
  )
      ? props.offer.latest_negotiation_proposal
      : undefined;

  const latestOfferActivity = getLastOfferUpdateDatetime(props.offer, lastProposal);

  const offerCurrency = getCurrencySymbolFromCache() || t("offerCard.currencyUnavailable");
  const price = lastProposal
      ? formatNumber(lastProposal.price)
      : (props.offer.price?.includes(" ") ? props.offer.price?.split(" ")[1] : formatNumber(props.offer.price) || 0);

  const renderNegotiableTag = () => (
      <div className={style.negotiableTag}>
        {t(
            props.offer.negotiable
                ? "marketOfferView.details.negotiable"
                : "marketOfferView.details.nonNegotiable"
        ).toUpperCase()}
      </div>
  )

  const offerLabels: { [key: string]: string } = {
    [OfferStatus.Accepted]: t("offerStatuses.accepted"),
    [OfferStatus.ExchangeDone]: t("offerStatuses.exchange_done"),
    [OfferStatus.ExchangeAgreed]: t("offerStatuses.exchange_agreed"),
    [OfferStatus.New]: t("offerStatuses.new"),
    [OfferStatus.Recalled]: t("offerStatuses.recalled"),
    [OfferStatus.Rejected]: t("offerStatuses.rejected"),
    [OfferStatus.OfferDone]: t("offerStatuses.offer_done"),
    [OfferStatus.Negotiating]: t("offerStatuses.negotiating"),
    [OfferStatus.ExchangeDetailsUpdated]: t("offerStatuses.exchange_details_updated"),
    [OfferStatus.ExchangeDetailsDisputed]: t("offerStatuses.exchange_details_disputed"),
    [OfferStatus.ExchangePaymentPending]: t("offerStatuses.exchange_payment_pending"),
  };

  const isLastProposalFromTrader = (proposal: NegotiationProposal) => {
    return proposal?.user.roles.includes(
        UserGroup.TRADER_REPR
    );
  }

  const getStatusClass = (status: OfferStatus) => {
    switch (status) {
      case OfferStatus.Accepted:
      case OfferStatus.OfferDone:
      case OfferStatus.ExchangeAgreed:
        return style.successStatus;
      case OfferStatus.Recalled:
      case OfferStatus.Rejected:
      case OfferStatus.ExchangeDetailsDisputed:
        return style.alertStatus;
      case OfferStatus.ExchangeDone:
        return style.expiredStatus;
      case OfferStatus.ExchangeDetailsUpdated:
        return style.warningStatus;
      case OfferStatus.Negotiating: {
        return isLastProposalFromTrader(props.offer.latest_negotiation_proposal) ? style.newStatus : style.warningStatus;
      }
      default:
        return style.newStatus;
    }
  };

  const canRecallOffer = (status: OfferStatus) => {
    return [OfferStatus.New, OfferStatus.Accepted].includes(status);
  }

  const offerTypeHeader = () => {
    const translation = (props.offer.offer_type === OFFER_TYPES.MARKET_OFFER)
        ? t("marketOfferView.marketOffer")
        : t("marketOfferView.directOffer");
    return translation.toUpperCase();
  }

  const formatQuantityNumber = () => {
    return lastProposal
        ? (lastProposal.minimum_quantity === lastProposal.maximum_quantity ? formatNumber(lastProposal.minimum_quantity) : `${formatNumber(lastProposal.minimum_quantity)} - ${formatNumber(lastProposal.maximum_quantity)}`)
        : props.offer.minimum_quantity === props.offer.maximum_quantity ? formatNumber(props.offer.minimum_quantity) : `${formatNumber(props.offer.minimum_quantity)} - ${formatNumber(props.offer.maximum_quantity)}`
  }

  const formatDate = (date: string) => dateToString(
      date,
      DATE_FORMAT_ISO_STRING,
      DATE_FORMAT_DEFAULT_WITH_TIME,
  )

  const renderDeliveryLocation = () => {
    const location = {
      delivery_location: lastProposal ? lastProposal.delivery_location : props.offer.delivery_location,
      custom_delivery_location: lastProposal ? lastProposal.custom_delivery_location : props.offer.custom_delivery_location_details?.id,
      custom_delivery_location_type: lastProposal ? lastProposal.custom_delivery_location_type : props.offer.custom_delivery_location_type
    }
    let addressHolder: AddressHolder = { name: '', physical_address: '' };
    switch(location.delivery_location) {
      case "drop_at_trader":
        addressHolder = props.listTraders.find(({id}) => id === props.offer.trader.id) as unknown as AddressHolder
        return (
          <span>
            {addressHolder.name || "Name unavailable"}
            <br/>
            {addressHolder.physical_address  || "Address unavailable"}
          </span>
        );
      case "pick_from_aggregator":
        addressHolder = props.listMineAggregators.find(({ id }) => id === props.offer.tagged_aggregator.id) as unknown as AddressHolder
        return (
          <span>
            {addressHolder.name || "Name unavailable"}
            <br/>
            {addressHolder.physical_address  || "Address unavailable"}
          </span>
        );
      case "custom_exchange_location":
        const customExchangeLocation = props.customExchangeLocations?.find((loc: {id: number}) => loc.id === Number(location.custom_delivery_location)) as any;
        const customExchangeLocationType = props.customExchangeLocationTypes?.find((type: {id: number}) => type.id === Number(location.custom_delivery_location_type)) as any;
        const area = customExchangeLocation ? props.administrativeAreas?.find((area: {id: number}) => area.id === Number(customExchangeLocation.location)) as any : undefined;
        let name = customExchangeLocation?.name || ""
        let type = customExchangeLocationType?.name || ""
        return (
          <span>
            {name || "Name unavailable"}
            {
              type ? (
                <>
                  <br/>
                  {type}
                </>
              ) : undefined
            }
            {
              area ? (<>
                <br/>
                {area.name}
              </>) : undefined
            }
          </span>
        );
        default:
          return "Unknown delivery type"
    }
    
  }

  const commentsButtonLabel = () => {
    switch (props.offer.status) {
      case OfferStatus.Recalled:
      case OfferStatus.Rejected:
        return "offerCard.seeReasons";
      case OfferStatus.Negotiating: {
        return isLastProposalFromTrader(props.offer.latest_negotiation_proposal) ? "offerCard.viewNegotiation" : "offerCard.viewCounterOffer";
      }
      case OfferStatus.ExchangeDetailsUpdated:
        return "offerCard.reviewExchangeQuantity";
      case OfferStatus.ExchangeDone:
        return "offerCard.exchangeDetails";
      default:
        return "offerCard.viewComments";
    }
  }

  const columns = () => {
    const offerDetailsColumns = [
      { label: "offerQuantity", value: formatQuantityNumber() + " " + props.offer.unit_of_measure.code },
      {
        label: "offerPrice",
        labelSuffix: ` (${offerCurrency}/${props.offer.unit_of_measure.code})`,
        value: `${price} ${offerCurrency}/${props.offer.unit_of_measure.code}`,
      },
      {
        label: "exchangeDateAndTime", value: formatDate(
            lastProposal
                ? lastProposal.planned_exchange_time
                : props.offer.planned_exchange_time
        )
      },
      { label: "exchangeLocation", value: renderDeliveryLocation() },
    ];

    const depositDetailsColumns = props.offer.offer_type === OFFER_TYPES.DIRECT_OFFER && props.offer.deposit ? [
      { label: "depositQuantity", value: formatNumber(props.offer.deposit.quantity) + " " + props.offer.unit_of_measure.code },
      {
        label: "depositPrice",
        labelSuffix: ` (${offerCurrency}/${props.offer.unit_of_measure.code})`,
        value: `${props.offer.deposit.price} ${offerCurrency}/${props.offer.unit_of_measure.code}`,
      },
    ] : [];

    const offerCommodityColumns = [
      { label: "commodity", value: getLocalisedName(props.offer?.buy_commodity) },
      {
        label: "quality",
        value: props.offer.quality,
      },
    ];

    const firstColumn = props.shortVersion ? [...offerCommodityColumns, ...depositDetailsColumns] : offerDetailsColumns;
    const secondColumn = props.shortVersion ? [{
      label: "offerStatus",
      style: style.otherDetailCenter,
      value: (
          <div className={getStatusClass(props.offer.status)}>
            {offerLabels[props.offer.status.toString()]}
          </div>
      )
    }] : depositDetailsColumns;

    return [
      {
        "style": style.offerDetails,
        "fields": firstColumn,
      },
      {
        "style": style.offerDetails,
        "fields": [
          ...secondColumn,
          {
            label: "aggregator",
            style: style.otherDetailBaseline,
            value: (
                <div
                    onClick={() => props.onClickAggregatorDetails(
                        props.offer.tagged_aggregator.id
                    )}
                    className={`${style.detailValue} ${style.aggregatorName}`}
                >
                  {props.offer.tagged_aggregator.name}
                </div>
            )
          },
        ],
      },
    ]
  };

  const actionButtons = [
    {
      show: () => true,
      onClick: () => (props.onClickSeeReasons || ignore)(props.offer),
      label:  commentsButtonLabel(),
      kind: props.offer.status === OfferStatus.ExchangeDetailsUpdated || (props.offer.status === OfferStatus.Negotiating && !isLastProposalFromTrader(props.offer.latest_negotiation_proposal)) ? "primary" : "secondary",
    },
    {
      show: canRecallOffer,
      onClick: () => props.onShowRecall(props.offer.id),
      label: "offerCard.recallOffer",
      kind: "danger",
    },
  ];

  const renderDetails = (details: any[]) => {
    return details.map(({label, labelSuffix, value, style: itemStyle}, i) => (
        <div
            key={`offerCard_details_${props.offer.id}_${label}`}
            className={`${style.detailItem} ${itemStyle}`}
        >
          <div className={style.detailLabel}>
            {t(`marketOfferView.details.columns.${label}`)}{labelSuffix}:
          </div>
          <div className={style.detailValue}>
            {value}
          </div>
        </div>
    ));
  }

  const getProgressStats = () => {
    const { offer } = props;
    if (!offer) return {
      completed: undefined,
      min: undefined,
      total: undefined,
    };
    const total = (
        offer.orders
            ?.map((offer) => parseInt(offer.deposit.quantity))
            .reduce((a, b) => a + b, 0)
        || 0
    )
    const completed = (total / parseInt(props.offer.maximum_quantity)) * 100;
    const min = (parseInt(offer.minimum_quantity) / parseInt(offer.maximum_quantity)) * 100;
    return { completed, min, total };
  }

  const { completed, min, total } = getProgressStats();

  return (
    <div className={
      `${style.container} ${props.shortVersion && style.shortVersion}`
    }>

      <div className={`${style.header}`}>
        <div className={style.offerHeader}>
          <div>
            <div className={style.offerType}>
              {
                `${offerTypeHeader()} #${props.offer.id}`
              }
            </div>
            <div className={style.offerTitle}>
              {t("marketOfferView.details.detailTitle", {
                quantity: formatQuantityNumber(),
                commodity: getLocalisedName(props.offer?.buy_commodity),
                quality: props.offer?.quality,
                price: price,
                unit: props.offer?.unit_of_measure?.code,
                currency: offerCurrency,
              })}
            </div>
          </div>
          <div className={style.offerLastUpdated}>
            {t("marketOfferView.details.latestOfferActivity")}{":\n"}<span>{formatDate(latestOfferActivity)}</span>
          </div>
        </div>
        {!props.shortVersion && <div className={style.offerSubheader}>
          <div className={getStatusClass(props.offer.status)}>
            {offerLabels[props.offer.status.toString()]}
          </div>
          {renderNegotiableTag()}
        </div>}
      </div>


        <div className={style.content}>
          <div className={style.details}>
            <div className={style.columns}>
              {
                columns().map((column, i) => (
                    <div
                        key={`offerCard_${props.offer.id}_column_${i}`}
                        className={column.style}
                    >
                      <div className={style.detailList}>
                        {renderDetails(column.fields)}
                      </div>
                    </div>
                ))
              }
            </div>
            {props.offer.offer_type === OFFER_TYPES.MARKET_OFFER && (
                <div className={style.progressBarWrapper}>
                  <div className={style.addedCommoditiesLabel}>
                    {t("marketOfferView.details.addedCommodities")}
                  </div>
                  <div className={style.progressTotalLabel}>
                    {total} {props.offer.unit_of_measure.code} {t("marketOfferView.details.total")}
                  </div>
                  <div className={style.progressBar}>
                    <ProgressBar {...{completed, min}}/>
                  </div>
                </div>
            )}
          </div>
          <div className={style.actions}>
            {!props.hideActions && (
                <>
                  {actionButtons.map(({ label, show, kind, onClick }) => (
                      show(props.offer.status) && (
                          <Button
                              key={`action_button_${props.offer.id}_${label}`}
                              className={style.actionButton}
                              small={true}
                              kind={kind || "secondary"}
                              onClick={onClick}
                          >
                            {`${t(label)}`}
                          </Button>
                      )
                  ))}
                </>
            )}
          </div>
        </div>
     {!props.shortVersion ? <>
        <div className={style.pictures} />
      </> : undefined}

    </div>
  );
};

export const mapStateToProps = (state: any) => {
  return {
    listAggregators: state.listAggregators.data.results,
    listMineAggregators: state.listMineAggregators.data.results,
    listTraders: state.listTraders.data.results,
    customExchangeLocations: state.listExchangeLocations.data.results,
    customExchangeLocationTypes: state.listExchangeLocationTypes.data.results,
    administrativeAreas: state.listAdministrativeAreas.data.results,
  };
};

export default connect(mapStateToProps)(OfferCard);
