// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_stepNav_enH_ps_wIO72sXCfX8Nw {\n  margin-top: 30px;\n}\n\n.style_label_KFfbA0uHgB7f4wVEnuq7 {\n  font-family: \"Open Sans\", sans-serif;\n  font-size: 0.875rem;\n  color: #001f2f;\n  font-weight: 600;\n  display: inline-block;\n  vertical-align: baseline;\n  margin-bottom: 0.5rem;\n}\n\n.style_mandatoryAsterisk_YC5VpKlNo9DrTpnR6b0x {\n  color: red;\n}\n\n.style_instructionText_QM4SuVn2Wtv4z5omjgBA {\n  margin: 2em 0;\n  font-size: 1em;\n  color: #5A6872;\n  font-family: \"Open Sans\", sans-serif;\n}\n\n.style_tooltip_MexQjKqqWqZ5Xdq6MDF7 {\n  top: 7px;\n  position: relative;\n  margin-left: 0.5rem;\n}", "",{"version":3,"sources":["webpack://./src/containers/TraderDetails/components/Form/style.scss"],"names":[],"mappings":"AACA;EACI,gBAAA;AAAJ;;AAGA;EACE,oCAAA;EACA,mBAAA;EACA,cAAA;EACA,gBAAA;EACA,qBAAA;EACA,wBAAA;EACA,qBAAA;AAAF;;AAGA;EACE,UAAA;AAAF;;AAGA;EACE,aAAA;EACA,cAAA;EACA,cAAA;EACA,oCAAA;AAAF;;AAGA;EACE,QAAA;EACA,kBAAA;EACA,mBAAA;AAAF","sourcesContent":["\n.stepNav {\n    margin-top: 30px;\n  }\n\n.label {\n  font-family: \"Open Sans\", sans-serif;\n  font-size: 0.875rem;\n  color: #001f2f;\n  font-weight: 600;\n  display: inline-block;\n  vertical-align: baseline;\n  margin-bottom: 0.5rem;\n}\n\n.mandatoryAsterisk {\n  color: red;\n}\n\n.instructionText {\n  margin: 2em 0;\n  font-size: 1em;\n  color: #5A6872;\n  font-family: \"Open Sans\", sans-serif;\n}\n\n.tooltip {\n  top: 7px;\n  position: relative;\n  margin-left: 0.5rem;\n};"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stepNav": "style_stepNav_enH_ps_wIO72sXCfX8Nw",
	"label": "style_label_KFfbA0uHgB7f4wVEnuq7",
	"mandatoryAsterisk": "style_mandatoryAsterisk_YC5VpKlNo9DrTpnR6b0x",
	"instructionText": "style_instructionText_QM4SuVn2Wtv4z5omjgBA",
	"tooltip": "style_tooltip_MexQjKqqWqZ5Xdq6MDF7"
};
export default ___CSS_LOADER_EXPORT___;
