import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PageStandard from "../../components/PageStandard";
import { getDeletedItemName, valueOrDefault } from "../../utils";
import List from "./components/List";
import PropTypes from "prop-types";
import { deleteFarmerDeletionReasonsDetails, farmerDeletionReasonsDetailsReset, listFarmerDeletionReasons, listFarmerDeletionReasonsReset } from "../../actions";
import Modal from "../../components/Modal";
import FarmerDeletionReasonsEditModal from "./components/Modal";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import Button from "../../components/Button";
import { iconAdd } from "@wfp/icons";

export const WfpManagerFarmerDeletionReasonsView = ({
  isFetching,
  errorMessage,
  farmerDeletionReasons,
  page,
  count,
  listFarmerDeletionReasons,
  listFarmerDeletionReasonsReset,
  farmerDeletionReasonsDetailsReset,
  deleteFarmerDeletionReasonsDetails,
}) => {
  const { t } = useTranslation();
  const [modalOptions, setModalOptions] = useState({isOpen: false, id: undefined, type: undefined});
  const [deleteModalOptions, setDeleteModalOptions] = useState({isOpen: false, id: undefined});

  // On component mount the data are fetched
  useEffect(() => {
    listFarmerDeletionReasons();
    return () => {
      listFarmerDeletionReasonsReset();
    };
  }, []);

  const onClickAdd = () => {
    farmerDeletionReasonsDetailsReset();
    setModalOptions({isOpen: true, id: "add", type: "create"});
    listFarmerDeletionReasons();
  };

  const onDeleteSubmit = () => {
    deleteFarmerDeletionReasonsDetails(deleteModalOptions.id);
    setDeleteModalOptions({isOpen: false, id: undefined});
    listFarmerDeletionReasons();
  };

  return (
    <PageStandard
      additional={(
        <ButtonsWrapper>
          <Button icon={iconAdd} onClick={onClickAdd}>
            {t("common.add") + " " + t("configuration.item.farmerDeletionReasons")}
          </Button>
        </ButtonsWrapper>
      )}
      title={t("configuration.item.farmerDeletionReasons")}
    >
      <List
        {...{
          isFetching,
          errorMessage,
          farmerDeletionReasons,
          page,
          count,
          setModalOptions,
          setDeleteModalOptions,
        }}
      />
      <FarmerDeletionReasonsEditModal
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
      />
      <Modal
        isOpen={deleteModalOptions.isOpen}
        onValidate={onDeleteSubmit}
        title={t("common.delete") + " " + t("configuration.item.farmerDeletionReasons")}
        danger={true}
        onCancel={() => setDeleteModalOptions({isOpen: false, id: undefined})}
        isLoading={false}
      >
        {t("configuration.common.deleteText") + getDeletedItemName(deleteModalOptions.id, farmerDeletionReasons, "text")}
      </Modal>
    </PageStandard>
  );
};

WfpManagerFarmerDeletionReasonsView.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  farmerDeletionReasons: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  listFarmerDeletionReasons: PropTypes.func.isRequired,
  listFarmerDeletionReasonsReset: PropTypes.func.isRequired,
  farmerDeletionReasonsDetailsReset: PropTypes.func.isRequired,
  deleteFarmerDeletionReasonsDetails: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    farmerDeletionReasons: state.listFarmerDeletionReasons.data.results,
    isFetching: state.listFarmerDeletionReasons.isFetching,
    errorMessage: state.listFarmerDeletionReasons.errorMessage,
    page: 1,
    count: valueOrDefault(state.listFarmerDeletionReasons.data.results.length, 0),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    listFarmerDeletionReasons: () => dispatch(listFarmerDeletionReasons()),
    listFarmerDeletionReasonsReset: () => dispatch(listFarmerDeletionReasonsReset()),
    farmerDeletionReasonsDetailsReset: () => dispatch(farmerDeletionReasonsDetailsReset()),
    deleteFarmerDeletionReasonsDetails: id => dispatch(deleteFarmerDeletionReasonsDetails(id, true)),
  };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(WfpManagerFarmerDeletionReasonsView);
