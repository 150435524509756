import React from "react";
import PropTypes from "prop-types";
import { getErrors } from "../../../../utils";
import { adaptOptions } from "../../../../utils/adapters";
import { useTranslation } from "react-i18next";
import { Col, Grid, Row } from "../../../../components/Grid";
import { Field, reduxForm } from "redux-form";
import Input from "../../../../components/Input";
import FormWrapper from "../../../../components/FormWrapper";
import Select from "../../../../components/Select";
import Module from "../../../../components/Module";
import { MandatoryLabel } from "../../../../components/MandatoryLabel";
import Button from "../../../../components/Button";

// This component is used to display the list
// of commodities for the WFP Manager
export const CommodityEditForm = ({
  onValidate,
  countries,
  fieldErrors,
}) => {
  const { t } = useTranslation();

  return (
    <Grid>
      <Row>
        <Col sm={8}>
          <Module
            title={t("managerDetails.formTitle")}
            footer={<Button onClick={onValidate}>{t("common.save")}</Button>}
          >
            <Grid>
              <FormWrapper>
                <Row>
                  <Col sm={12}>
                    <Field
                      placeholder={t("managerDetails.firstName")}
                      component={Input}
                      name="first_name"
                      id="first_name"
                      errorMessage={getErrors(fieldErrors, "first_name")}
                      label={
                        <MandatoryLabel
                          label={t("managerDetails.firstName")}
                        ></MandatoryLabel>
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Field
                      placeholder={t(
                        "managerDetails.lastName",
                      )}
                      component={Input}
                      name="last_name"
                      id="last_name"
                      errorMessage={getErrors(fieldErrors, "last_name")}
                      label={
                        <MandatoryLabel
                          label={t("managerDetails.lastName")}
                        ></MandatoryLabel>
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Field
                      placeholder={t("managerDetails.email")}
                      component={Input}
                      name="email"
                      id="email"
                      errorMessage={getErrors(fieldErrors, "email")}
                      label={<MandatoryLabel label={t("managerDetails.email")} /> }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Field
                      placeholder={t("commodityEdit.countriesPlaceholder")}
                      component={Select}
                      options={adaptOptions(countries, "id", "name")}
                      name="countries"
                      id="countries"
                      label={t("commodityEdit.countriesLabel")}
                      loading={false}
                      errorMessage={getErrors(fieldErrors, "countries")}
                      isMulti
                    />
                  </Col>
                </Row>
              </FormWrapper>
            </Grid>
          </Module>
        </Col>
      </Row>
    </Grid>
  );
};

// propTypes for the Commodities component
CommodityEditForm.propTypes = {
  countries: PropTypes.array.isRequired,
  onValidate: PropTypes.func.isRequired,
  fieldErrors: PropTypes.object.isRequired,
};

// The component uses the redux store
export default reduxForm({
  form: "managerDetails",
  enableReinitialize: true,
})(CommodityEditForm);
