import React from "react";
import PropTypes from "prop-types";

import Module from "../../../../components/Module";
import Loading from "../../../../components/Loading";
import Table from "../../../../components/Table";
import ShowHideArea from "../../../../components/ShowHideArea";
import { displayErrorMessage } from "../../../../utils";
import { statusCell } from "../../../../utils/table";
import {withTranslation} from "react-i18next";

// The list of columns and their properties
export const columns = () => [
  {
    Header: "column.trader",
    accessor: "trader_name",
  },
  {
    Header: "column.offerId",
    accessor: "offer_id",
    width: 70,
  },
  {
    Header: "column.commodity",
    accessor: "commodity.name",
  },
  {
    Header: "column.price",
    accessor: "price",
    width: 90,
  },
  {
    Header: "column.minQuantity",
    accessor: "minimum_quantity",
  },
  {
    Header: "column.maxQuantity",
    accessor: "maximum_quantity",
  },
  {
    Header: "column.quality",
    accessor: "quality",
    width: 80,
  },
  {
    Header: "column.quantityAggregated",
    accessor: "quantity",
  },
  {
    Header: "column.transactionValue",
    accessor: "current_value",
  },
  {
    Header: "column.percentOfFarmersParticipated",
    accessor: "farmer_contributors_percentage",
  },
  {
    Header: "column.percentOfFemaleFarmersParticipated",
    accessor: "women_percentage",
  },
  {
    Header: "column.status",
    accessor: "status",
    Cell: row => statusCell(
      row.text,
      row.color,
      row.percentage,
    ),
    width: 100,
  },
];

// The list of a market orders of aggregator
export const Market = (
  {
    isFetchingAggregator,
    errorMessageAggregator,
    marketOrders,
    isVisible,
    onPageChangeMarketOrders,
    marketOrdersPage,
    t,
  },
) => {

  return (
    <ShowHideArea isVisible={isVisible}>
      {isFetchingAggregator && <Loading isVisible />}
      {displayErrorMessage(errorMessageAggregator)}
      <Module
        title={t("aggregatorView.market.title")}
        table={
          <Table
            loading={false}
            columns={columns()}
            data={marketOrders || []}
            onPageChange={onPageChangeMarketOrders}
            page={marketOrdersPage}
          />
        }
      />
    </ShowHideArea>
  );
};

// propTypes for the Market component
Market.propTypes = {
  isFetchingAggregator: PropTypes.bool.isRequired,
  errorMessageAggregator: PropTypes.string.isRequired,
  marketOrders: PropTypes.array.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onPageChangeMarketOrders: PropTypes.func.isRequired,
  marketOrdersPage: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

// defaultProps for the Market component
Market.defaultProps = {
  errorMessageAggregator: "",
};

export default withTranslation()(Market);
