import React, {useRef, useState} from "react";
import { withTranslation } from "react-i18next";
import FormWrapper from "../../../../../components/FormWrapper";
import { Offer } from "../../OfferCard/interfaces";
import style from "./style.scss";
import { listRecallReasons } from "../../../../../actions";
import { connect } from "react-redux";
import Button from "../../../../../components/Button";
import { Col, Grid, Row } from "../../../../../components/Grid";
import { Controller, FormProvider } from "react-hook-form";
import { displayErrorHelpText, getErrors, getMandatoryFieldErrors } from "../../../../../utils";

export interface OfferCommentFormProps {
  offer: Offer;
  onValidate: (values: OfferCommentFormFields) => Promise<any>;
  t: (s: string) => string;
  errorMessage: string;
  methods: any;
  shouldRerender: number;
}

const defaultValues = {
  comment: undefined,
};

interface OfferCommentFormFields {
  comment: string
}

const OfferComment = (props: OfferCommentFormProps) => {
    const buttonRef = useRef<HTMLButtonElement>(null)
    const { onValidate, t, methods, shouldRerender} = props

    const { control, handleSubmit, formState: { errors }, setValue, setError, watch } = methods;

    const [isMessageBoxBusy, setBusy] = useState<boolean>(false);

    const validateAndReset = (values: OfferCommentFormFields) => {
      setBusy(true);
      onValidate(values).then(() => {
        setValue("comment", "");
      }).catch(() => {
        setError("comment", { message: `An error has occurred` })
      }).finally(() => setBusy(false));
    }
    const errorMessage = () => {
      if(errors && errors.comment && errors.comment.message){
        return t(`${errors.comment.message}`)
      }else if(errors && errors.comment){
        return t(`${errors.comment}`)
      }
    }
    return (
      <Grid className={style.wide}>
        <FormWrapper>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(validateAndReset)}>
          <Row>
            <Col sm={11}>
              <Controller
                render={({field}) => {
                  return (
                    <div className="wfp--form-item">
                    <div style={{width: "100%"}}>
                      <input
                        value={field.value}
                        onChange={field.onChange}
                        id={"comment"}
                        name={"comment"}
                        type={"text"}
                        placeholder={t("offerCommentForm.commentPlaceholder")}
                        className="wfp--text-input"
                      />
                    </div>
                    {errorMessage() && displayErrorHelpText("* " + errorMessage())}
                  </div>
                  )
                }}
                key={shouldRerender}
                name="comment"
                control={control}
                defaultValue={defaultValues.comment}
              />
            </Col>
            <Col sm={1}>
              <Button disabled={isMessageBoxBusy} onClick={() => buttonRef && buttonRef.current && buttonRef.current.click()}>{t("common.send")}</Button>
              <button style={{ display: "none" }} ref={buttonRef} />
            </Col>
          </Row>
          </form>
          </FormProvider>
        </FormWrapper>
      </Grid>
    );
  }

const mapStateToProps = (state: any) => {
  return {
    reasons: state.listRecallReasons && state.listRecallReasons.data.results,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    listRecallReasons: () => dispatch(listRecallReasons()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withTranslation()(OfferComment)
);
