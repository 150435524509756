// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_container_F6oGUhs2Gj_XnysXsVGH {\n  display: block;\n  width: 100%;\n  text-align: center;\n  margin-top: 1rem;\n  margin-bottom: 2.5rem;\n}\n\n.style_content_et7OgkcUbUoDotfJKFsG {\n  color: #5A6872;\n  margin-top: 20px;\n  margin-bottom: 20px;\n}", "",{"version":3,"sources":["webpack://./src/containers/RepresentativeHome/components/OfferCommentsModal/WaitingForResponse/style.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,cAAA;EACA,gBAAA;EACA,mBAAA;AACJ","sourcesContent":[".container {\n    display: block;\n    width: 100%;\n    text-align: center;\n    margin-top: 1rem;\n    margin-bottom: 2.5rem;\n}\n\n.content {\n    color: #5A6872;\n    margin-top: 20px;\n    margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "style_container_F6oGUhs2Gj_XnysXsVGH",
	"content": "style_content_et7OgkcUbUoDotfJKFsG"
};
export default ___CSS_LOADER_EXPORT___;
