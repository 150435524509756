// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_modalContainer_G7Mk4zvgstpbwRN5wIhv {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  max-height: 50vh;\n  overflow: scroll;\n}\n\n.style_wrapper_IXdr5NtadhyHcFvCeY9P {\n  width: 100%;\n  height: 300px;\n  overflow: auto;\n  margin-top: 24px;\n}\n\n.style_centerText_O8S7MHVBKCa3PJEY4rfg {\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/components/TermsAndConditionsModal/style.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAGA;EACE,WAAA;EACA,aAAA;EACA,cAAA;EACA,gBAAA;AAAF;;AAGA;EACE,kBAAA;AAAF","sourcesContent":[".modalContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  max-height: 50vh;\n  overflow: scroll;\n}\n\n\n.wrapper {\n  width: 100%;\n  height: 300px;\n  overflow: auto;\n  margin-top: 24px;\n}\n\n.centerText {\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": "style_modalContainer_G7Mk4zvgstpbwRN5wIhv",
	"wrapper": "style_wrapper_IXdr5NtadhyHcFvCeY9P",
	"centerText": "style_centerText_O8S7MHVBKCa3PJEY4rfg"
};
export default ___CSS_LOADER_EXPORT___;
