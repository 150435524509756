import React from "react";

import WfpManagerFooterComponent from "../../components/WfpManagerFooterComponent";

// The footer used in all pages to allow user access to utils and links
export const WfpManagerFooter = () => {
  return (
    <WfpManagerFooterComponent />
  );
};

export default WfpManagerFooter;
