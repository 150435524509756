import React from "react";
import PropTypes from "prop-types";
import MDEditor from "@uiw/react-md-editor";

// Style import to un-overwrite the default style for lists.
// Without this styling lists are not rendered correctly.
import style from "./style.scss";

export default function MarkdownEditor({
  field,
}) {

  return (
    <div className={style.markdownEditor}>
      <MDEditor
        {...field}
        data-color-mode="light"
      />
    </div>
  );
}

MarkdownEditor.propTypes = {
  field: PropTypes.object.isRequired,
};
