import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

import Button from "../../../../components/Button";
import Loading from "../../../../components/Loading";
import Module from "../../../../components/Module";
import ShowHideArea from "../../../../components/ShowHideArea";
import { displayErrorMessage } from "../../../../utils";
import {
  statusCell2,
  generateViewButton,
  generateRecallButton,
} from "../../../../utils/table";
import AggregatorDetailsModal from "../../../AggregatorDetailsModal";
import CounterOfferModal from "../CounterOfferModal";
import OfferCommentsModal from "../OfferCommentsModal";
import {
  makeProposal,
} from "../OfferCommentsModal/NegotiatedDetailsCard/offer-negotiation-proposal";
import OffersList from "../OffersList";
import RecallOffer from "../RecallOfferModal";
import SeeProgressModal from "../SeeProgressModal";
import SendingBackExchange from "../SendingBackExchangeModal";
import {OfferStatus} from "../OfferCard/interfaces";
import client from "../../../../client";
import {API_URL_DEPOSIT_DETAILS} from "../../../../actions";
import {OFFER_TYPES} from "../../../../constants";

// The list of columns and their properties
export const columns = (onClickView, onClickRecall, t) => [
  {
    Header: "#",
    accessor: "id",
    width: 100,
  },
  {
    Header: "column.commodity",
    accessor: "buy_commodity.name",
    width: 160,
  },
  {
    Header: "column.price",
    accessor: "price",
    width: 160,
  },
  {
    Header: "column.minQuantity",
    accessor: "formatted_minimum_quantity",
    width: 220,
  },
  {
    Header: "column.maxQuantity",
    accessor: "formatted_maximum_quantity",
    width: 220,
  },
  {
    Header: "column.quality",
    accessor: "quality",
    width: 120,
  },
  {
    Header: "column.status",
    accessor: "status",
    Cell: (row) => statusCell2(row.text, row.color, row.percentage),
    width: 170,
  },
  {
    Header: "column.validity",
    accessor: "valid_until",
    width: 120,
  },
  {
    Header: "column.offerType",
    accessor: "offer_type",
    width: 70,
  },
  {
    width: 80,
    accessor: "id",
    Cell: (obj) => generateViewButton(obj.row.original.id, onClickView, undefined, t),
  },
  {
    width: 80,
    accessor: "id",
    Cell: (obj) => generateRecallButton(obj.row.original.id, onClickRecall,  t),
  },
];

// The list of market offers
export const MarketOffers = ({
  addCounterOffer,
  administrativeAreas,
  aggregators,
  commodities,
  counterOfferFieldErrors,
  customExchangeLocations,
  exchangeLocationTypes,
  filterPanel,
  filters,
  errorMessageMarketOffers,
  errorMessageTrader,
  isFetching,
  isFetchingMarketOffers,
  listAggregatorAdministrators,
  listMarketOffers,
  marketOffers,
  marketOffersCount,
  onPageChangeMarketOffers,
  onValidateRecall,
  onValidateSendingBack,
  pagination,
  refreshContent,
  t,
  trader,
}) => {
  const [showRecallOfferModal, setShowRecallOfferModal] = useState(false);
  const [showSendingBackExchangeModal, setShowSendingBackExchangeModal] = useState(false);
  const [offerIdForRecall, setOfferIdforRecall] = useState(0);
  const [offerIdForSendingBackExchange, setOfferIdforSendingBackExchange] = useState(0);
  const [seeProgressOffer, setSeeProgressOffer] = useState(undefined);
  const [seeReasonsOffer, setSeeReasonsOffer] = useState(undefined);
  const [selectedAggregator, setSelectedAggregator] = useState({});
  const [proposalToCounter, setProposalToCounter] = useState({});
  const [resetCommentsModal, triggerResetCommentsModal] = useState(undefined);

  // TODO: refactor all these states into one offer & intent pair structure state
  const [counterOfferModalOffer, setCounterOfferModalOffer] = useState({});

  const cancelRecallModal = () => {
    setShowRecallOfferModal(false);
    setOfferIdforRecall[0];
  };

  const cancelSendingBackModal = () => {
    setShowSendingBackExchangeModal(false);
    setOfferIdforSendingBackExchange(0);
  };

  const showRecallModalForOffer = (id) => {
    setOfferIdforRecall(id);
    setShowRecallOfferModal(true);
  };

  const showSendBackExchangeModalForOffer = (id) => {
    setOfferIdforSendingBackExchange(id);
    setShowSendingBackExchangeModal(true);
  };

  if (isFetching) {
    return <Loading isVisible />;
  }

  if (errorMessageTrader) {
    return <>{displayErrorMessage(errorMessageTrader)}</>;
  }

  const onClickAggregatorDetails = (aggregator_id) => {
    listAggregatorAdministrators(aggregator_id);
    listMarketOffers(1,1000, { aggregator_id });
    const selectedAggregator = aggregators.find((a) => a.id === aggregator_id);
    setSelectedAggregator(selectedAggregator);
  };

  const actions = (
    <>
      <Button
        kind="secondary"
        small
        onClick={() => listMarketOffers(
          pagination.page,
          pagination.pageSize,
          filters,
        )}
      >
        {t("marketOfferView.refresh")}
      </Button>
    </>
  );

  const recallOfferFromComments = () => {
    const offerId = seeReasonsOffer.id;
    setSeeReasonsOffer(undefined);
    showRecallModalForOffer(offerId);
  };

  const sendBackExchange = () => {
    const offerId = seeReasonsOffer.id;
    setSeeReasonsOffer(undefined);
    showSendBackExchangeModalForOffer(offerId);
  };

  const makeCounterOfferFromComments = async (proposal) => {
    const offer = {...seeReasonsOffer};
    const aggregator  = aggregators.find((item) => item.id ===  offer.tagged_aggregator.id);
    setSeeReasonsOffer(undefined);
    setProposalToCounter(makeProposal(proposal, trader, aggregator, t, customExchangeLocations));
    setCounterOfferModalOffer(offer);
    //sad walkaround to fetch deposit details for validation
    if (offer.offer_type === OFFER_TYPES.DIRECT_OFFER) {
      const {data} = await client.get(`${API_URL_DEPOSIT_DETAILS}${offer.deposit.uuid}/`);
      setCounterOfferModalOffer({
        ...offer,
        deposit: data,
      });
    }
  };

  const submitCounterOffer = () => {
    const offer = {...counterOfferModalOffer};
    const proposal = addCounterOffer(offer);
    listMarketOffers(
      pagination.page,
      pagination.pageSize,
      filters,
    );
    setSeeReasonsOffer({...offer, latest_negotiation_proposal: {...proposal, user: {
      id: offer.trader_representative.id,
      first_name: offer.trader_representative.person.first_name,
      last_name: offer.trader_representative.person.last_name,
      roles: ["Trader Representative"],
    }}});
    setCounterOfferModalOffer({});
    refreshContent(offer);
    triggerResetCommentsModal(uuidv4());
  };

  return (
    <>
      {displayErrorMessage(errorMessageMarketOffers)}
      <Module
        title={t("home.marketOffers.offerProgress")}
        actions={actions}
        table={
          <>
            {isFetchingMarketOffers && <Loading isVisible/>}
            <ShowHideArea isVisible={!isFetchingMarketOffers}>
              <OffersList
                filterPanel={filterPanel}
                onClickAggregatorDetails={onClickAggregatorDetails}
                onShowRecall={showRecallModalForOffer}
                onPageChange={onPageChangeMarketOffers}
                onSeeProgressClick={setSeeProgressOffer}
                onSeeReasonsClick={(offer) => {
                  setSeeReasonsOffer(offer);
                  refreshContent(offer);
                }}
                totalItems={marketOffersCount}
                data={marketOffers}
                pagination={pagination}
              />
            </ShowHideArea>
          </>
        }
      />
      <RecallOffer
        showRecallOfferModal={showRecallOfferModal}
        onCancel={cancelRecallModal}
        onValidateRecall={(offer) => {
          onValidateRecall(offer);
          triggerResetCommentsModal(uuidv4());
        }}
        offers={marketOffers}
        id={offerIdForRecall}
      />
      <SendingBackExchange
        showSendingBackExchangeModal={showSendingBackExchangeModal}
        onCancel={cancelSendingBackModal}
        onValidateSendingBack={(offer) => {
          onValidateSendingBack(offer);
          setShowSendingBackExchangeModal(false);
          setOfferIdforSendingBackExchange(0);
          setTimeout(() => {
            setSeeReasonsOffer({
              ...offer,
              status: OfferStatus.ExchangeDetailsDisputed,
            });
            triggerResetCommentsModal(uuidv4());
          }, 300);
        }}
        offers={marketOffers}
        id={offerIdForSendingBackExchange}
      />
      <SeeProgressModal
        open={!!seeProgressOffer}
        offer={seeProgressOffer}
        onValidate={() => setSeeProgressOffer(undefined)}
      />
      <OfferCommentsModal
        offer={seeReasonsOffer}
        setSeeReasonsOffer={setSeeReasonsOffer}
        onValidate={() => {
          setSeeReasonsOffer(undefined);
          // This means the list will refresh every time a modal is closed,
          // regardless of whether it's changed.
          listMarketOffers();
        }}
        onCancel={() => {
          listMarketOffers();
          setSeeReasonsOffer(undefined);
        }}
        triggerReset={resetCommentsModal}
        onRecallOffer={recallOfferFromComments}
        onSendBackExchange={sendBackExchange}
        onCounterOffer={makeCounterOfferFromComments}
      />
      <AggregatorDetailsModal
        isOpen={!!selectedAggregator.id}
        commodities={commodities}
        aggregator={selectedAggregator}
        isFetchingAggregatorAdministrators={false}
        onValidate={() => setSelectedAggregator({})}
      />
      <CounterOfferModal
        administrativeAreas={administrativeAreas}
        trader={trader}
        aggregators={aggregators}
        customExchangeLocations={customExchangeLocations}
        exchangeLocationTypes={exchangeLocationTypes}
        offer={counterOfferModalOffer}
        fieldErrors={counterOfferFieldErrors}
        initialValues={proposalToCounter}
        onValidate={submitCounterOffer}
        onCancel={() => {
          setSeeReasonsOffer(counterOfferModalOffer);
          setCounterOfferModalOffer({});
        }}
      />
    </>
  );
};

// propTypes for the MarketOffers component
MarketOffers.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessageTrader: PropTypes.string,
  isFetchingMarketOffers: PropTypes.bool.isRequired,
  errorMessageMarketOffers: PropTypes.string,
  marketOffers: PropTypes.array.isRequired,
  onPageChangeMarketOffers: PropTypes.func.isRequired,
  marketOffersCount: PropTypes.number.isRequired,
  onValidateRecall: PropTypes.func.isRequired,
  onValidateSendingBack: PropTypes.func.isRequired,
  aggregators: PropTypes.array,
  pagination: PropTypes.object,
  t: PropTypes.func.isRequired,
  filterPanel: PropTypes.node,
  filters: PropTypes.object,
  listAggregatorAdministrators: PropTypes.func.isRequired,
  listMarketOffers: PropTypes.func.isRequired,
  administrativeAreas: PropTypes.array,
  refreshContent: PropTypes.func.isRequired,
  addCounterOffer: PropTypes.func.isRequired,
  commodities: PropTypes.array.isRequired,
  counterOfferFieldErrors: PropTypes.object.isRequired,
  customExchangeLocations: PropTypes.array.isRequired,
  exchangeLocationTypes: PropTypes.array.isRequired,
  trader: PropTypes.object.isRequired,
};

// defaultProps for the MarketOffers component
MarketOffers.defaultProps = {
  errorMessageTrader: "",
  errorMessageMarketOffers: "",
};

export default withTranslation()(MarketOffers);
