import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { iconEdit, iconDelete } from "@wfp/icons";

import {
  aggregatorDetails,
  deleteAggregator,
  deleteAggregatorReset,
  aggregatorDetailsReset,
  listAggregatorMarketOrders,
  listAggregatorMarketOrdersReset,
  listFarmers,
  listFarmersReset,
  listDeposits,
  listDepositsReset,
  depositsCurrentPage,
  listAggregatorAdministrators,
  listAggregatorAdministratorsReset,
  aggregatorMarketOrdersCurrentPage,
  farmersCurrentPage,
  aggregatorAdministratorsCurrentPage,
} from "../../actions";
import PageTabs from "../../components/PageTabs";
import Details from "./components/Details";
import Farmers from "./components/Farmers";
import Deposits from "./components/Deposits";
import Market from "./components/Market";
import Administrators from "./components/Administrators";
import { isEmpty, getDetailedError } from "../../utils";
import {
  adaptAggregator,
  adaptMarketOrders,
  adaptFarmers,
  adaptAggregatorAdministrators,
  adaptDeposits,
} from "../../utils/adapters";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import Button from "../../components/Button";
import ModalConfirm from "../../components/ModalConfirm";
import {useTranslation} from "react-i18next";

// This component is used to display the detail
// of an aggregator
export const WfpManagerAggregatorView = (
  {
    id,
    aggregatorDetails,
    isFetchingAggregator,
    errorMessageAggregator,
    aggregator,
    history,
    deleteAggregator,
    isFetchingDeleteAggregator,
    errorDeleteAggregator,
    deleteAggregatorReset,
    aggregatorDetailsReset,
    listAggregatorMarketOrders,
    listFarmers,
    listAggregatorAdministrators,
    listAggregatorMarketOrdersReset,
    listFarmersReset,
    listDeposits,
    listDepositsReset,
    listAggregatorAdministratorsReset,
    marketOrders,
    farmers,
    administrators,
    aggregatorMarketOrdersCurrentPage,
    farmersCurrentPage,
    aggregatorAdministratorsCurrentPage,
    marketOrdersPage,
    farmersPage,
    administratorsPage,
    depositsCurrentPage,
    deposits,
    depositsPage,
  },
) => {
  // A state to decide if the delete modal is open or closed
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  // A state to determine the current active  tab
  const [currentTab, setCurrentTab] = useState(0);

  const {t} = useTranslation();

  // On component mount the data are fetched
  useEffect(() => {
    aggregatorDetails(id);
    listAggregatorMarketOrders(id, 1);
    listFarmers(id, 1);
    listDeposits(id, 1);
    listAggregatorAdministrators(id, 1);
    aggregatorMarketOrdersCurrentPage(0);
    farmersCurrentPage(0);
    aggregatorAdministratorsCurrentPage(0);
    return () => {
      listAggregatorMarketOrdersReset();
      listFarmersReset();
      listDepositsReset();
      listAggregatorAdministratorsReset();
      deleteAggregatorReset();
      aggregatorDetailsReset();
    };
  }, []);

  // When user clicks edit Aggregator, it is redirected
  // to Aggregator edit page
  const onClickEdit = () => {
    history.push(`/wfpManager/aggregatorDetails/${id}`);
  };

  // When user clicks delete Aggregator, a confirm modal is displayed
  const onClickDelete = () => {
    setIsDeleteModalOpen(true);
  };

  // When user confirm that it want to delete the record
  // the record is actually deleted
  const onClickConfirmDelete = () => {
    deleteAggregator(id).then(result => {
      if (result) {
        setIsDeleteModalOpen(false);
        deleteAggregatorReset();
        history.push("/wfpManager/aggregators");
      }
    });
  };

  // When user press cancel on delete modal
  // the modal is closed and the current record
  // to delete is reset
  const onClickCancelDelete = () => {
    setIsDeleteModalOpen(false);
    deleteAggregatorReset();
  };

  // It changes the current tab
  const onClickDetailsTab = () => {
    setCurrentTab(0);
  };

  // It changes the current tab
  const onClickMarketTab = () => {
    setCurrentTab(1);
  };

  // It changes the current tab
  const onClickFarmersTab = () => {
    setCurrentTab(2);
  };

  // It changes the current tab
  const onClickAdministratorsTab = () => {
    setCurrentTab(3);
  };

  // It changes the current tab
  const onClickDepositsTab = () => {
    setCurrentTab(4);
  };

  // On page change of market orders
  const onPageChangeMarketOrders = page => {
    aggregatorMarketOrdersCurrentPage(page);
    listAggregatorMarketOrders(id, page + 1);
  };

  // On page change of farmers
  const onPageChangeFarmers = page => {
    farmersCurrentPage(page);
    listAggregatorMarketOrders(id, page + 1);
  };

  // On page change of deposits
  const onPageChangeDeposits = page => {
    depositsCurrentPage(page);
    listDeposits(id, page + 1);
  };

  // On page change of administrators
  const onPageChangeAdministrators = page => {
    aggregatorAdministratorsCurrentPage(page);
    listAggregatorMarketOrders(id, page + 1);
  };

  return (
    <PageTabs
      title={t("aggregatorView.title")}
      additional={(
        <ButtonsWrapper>
          <Button icon={iconDelete} onClick={onClickDelete}>
            {t("aggregatorView.deleteAggregator")}
          </Button>
          <Button icon={iconEdit} onClick={onClickEdit}>
            {t("aggregatorView.editAggregator")}
          </Button>
        </ButtonsWrapper>
      )}
      tabs={[
        {
          id: "details",
          label: t("aggregatorView.tabs.details"),
          onClick: onClickDetailsTab,
        },
        {
          id: "market",
          label: t("aggregatorView.tabs.market"),
          onClick: onClickMarketTab,
        },
        {
          id: "farmers",
          label: t("aggregatorView.tabs.farmers"),
          onClick: onClickFarmersTab,
        },
        {
          id: "administrators",
          label: t("aggregatorView.tabs.administrators"),
          onClick: onClickAdministratorsTab,
        },
        {
          id: "deposits",
          label: t("aggregatorView.tabs.deposits"),
          onClick: onClickDepositsTab,
        },
      ]}
    >
      <Details
        isVisible={currentTab === 0}
        isFetchingAggregator={isFetchingAggregator}
        errorMessageAggregator={errorMessageAggregator}
        aggregator={aggregator}
      />
      <Market
        isVisible={currentTab === 1}
        isFetchingAggregator={isFetchingAggregator}
        errorMessageAggregator={errorMessageAggregator}
        marketOrders={marketOrders}
        onPageChangeMarketOrders={onPageChangeMarketOrders}
        marketOrdersPage={marketOrdersPage}
      />
      <Farmers
        isVisible={currentTab === 2}
        isFetchingAggregator={isFetchingAggregator}
        errorMessageAggregator={errorMessageAggregator}
        farmers={farmers}
        onPageChangeFarmers={onPageChangeFarmers}
        farmersPage={farmersPage}
      />
      <Administrators
        isVisible={currentTab === 3}
        isFetchingAggregator={isFetchingAggregator}
        errorMessageAggregator={errorMessageAggregator}
        administrators={administrators}
        onPageChangeAdministrators={onPageChangeAdministrators}
        administratorsPage={administratorsPage}
      />
      <Deposits
        isVisible={currentTab === 4}
        isFetchingAggregator={isFetchingAggregator}
        errorMessageAggregator={errorMessageAggregator}
        deposits={deposits}
        onPageChangeDeposits={onPageChangeDeposits}
        depositsPage={depositsPage}
      />
      <ModalConfirm
        isOpen={isDeleteModalOpen}
        onValidate={onClickConfirmDelete}
        onCancel={onClickCancelDelete}
        error={errorDeleteAggregator}
        isLoading={isFetchingDeleteAggregator}
        title={t("aggregatorView.deleteAggregatorModalTitle")}
        text={
          t("aggregatorView.deleteAggregatorModalText", {aggregator: aggregator.name})
        }
      />
    </PageTabs>
  );
};

// propTypes for the WfpManagerAggregatorView component
WfpManagerAggregatorView.propTypes = {
  id: PropTypes.string.isRequired,
  aggregatorDetails: PropTypes.func.isRequired,
  isFetchingAggregator: PropTypes.bool.isRequired,
  errorMessageAggregator: PropTypes.string.isRequired,
  aggregator: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  deleteAggregator: PropTypes.func.isRequired,
  isFetchingDeleteAggregator: PropTypes.bool.isRequired,
  errorDeleteAggregator: PropTypes.object.isRequired,
  deleteAggregatorReset: PropTypes.func.isRequired,
  aggregatorDetailsReset: PropTypes.func.isRequired,
  listAggregatorMarketOrders: PropTypes.func.isRequired,
  listFarmers: PropTypes.func.isRequired,
  listAggregatorAdministrators: PropTypes.func.isRequired,
  listAggregatorMarketOrdersReset: PropTypes.func.isRequired,
  listFarmersReset: PropTypes.func.isRequired,
  listAggregatorAdministratorsReset: PropTypes.func.isRequired,
  marketOrders: PropTypes.array.isRequired,
  farmers: PropTypes.array.isRequired,
  administrators: PropTypes.array.isRequired,
  aggregatorMarketOrdersCurrentPage: PropTypes.func.isRequired,
  farmersCurrentPage: PropTypes.func.isRequired,
  aggregatorAdministratorsCurrentPage: PropTypes.func.isRequired,
  marketOrdersPage: PropTypes.number.isRequired,
  farmersPage: PropTypes.number.isRequired,
  administratorsPage: PropTypes.number.isRequired,
  listDeposits: PropTypes.func.isRequired,
  listDepositsReset: PropTypes.func.isRequired,
  deposits: PropTypes.array.isRequired,
  depositsCurrentPage: PropTypes.func.isRequired,
  depositsPage: PropTypes.number.isRequired,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = (state, props) => {
  return {
    // Properties related to Aggregator id
    id: props.match.params.id,
    // Loading and error messages properties
    isFetchingAggregator:
      state.aggregatorDetails.isFetching ||
      state.listAggregatorMarketOrders.isFetching ||
      state.listFarmers.isFetching ||
      state.listDeposits.isFetching ||
      state.listAggregatorAdministrators.isFetching ||
      isEmpty(state.aggregatorDetails.data),
    errorMessageAggregator:
      state.aggregatorDetails.errorMessage ||
      state.listAggregatorMarketOrders.errorMessage ||
      state.listFarmers.errorMessage ||
      state.listDeposits.errorMessage ||
      state.listAggregatorAdministrators.errorMessage,
    // Aggregator details properties
    aggregator: adaptAggregator(state.aggregatorDetails.data),
    // Properties related to linked entities
    marketOrders: adaptMarketOrders(state.listAggregatorMarketOrders.data.results),
    farmers: adaptFarmers(state.listFarmers.data.results),
    administrators: adaptAggregatorAdministrators(state.listAggregatorAdministrators.data.results),
    deposits: adaptDeposits(state.listDeposits.data.results),
    // Delete Aggregator properties
    isFetchingDeleteAggregator: state.deleteAggregator.isLoading,
    errorDeleteAggregator: getDetailedError(state.deleteAggregator.error),
    // Pagination properties
    marketOrdersPage: state.aggregatorMarketOrdersCurrentPage.number,
    farmersPage: state.farmersCurrentPage.number,
    depositsPage: state.depositsCurrentPage.number,
    administratorsPage: state.aggregatorAdministratorsCurrentPage.number,
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = dispatch => {
  return {
    aggregatorDetails: id => dispatch(aggregatorDetails(id)),
    aggregatorDetailsReset: () => dispatch(aggregatorDetailsReset()),
    deleteAggregator: id => dispatch(deleteAggregator(id)),
    deleteAggregatorReset: () => dispatch(deleteAggregatorReset()),
    listAggregatorMarketOrders: (aggregator, page) =>
      dispatch(listAggregatorMarketOrders({ aggregator, page })),
    listFarmers: (aggregator, page) => dispatch(listFarmers({ aggregator, page })),
    listAggregatorAdministrators: (aggregator, page) =>
      dispatch(listAggregatorAdministrators({ aggregator, page })),
    listAggregatorMarketOrdersReset: () => dispatch(listAggregatorMarketOrdersReset()),
    listFarmersReset: () => dispatch(listFarmersReset()),
    listAggregatorAdministratorsReset: () => dispatch(listAggregatorAdministratorsReset()),
    aggregatorMarketOrdersCurrentPage: page => dispatch(aggregatorMarketOrdersCurrentPage(page)),
    farmersCurrentPage: page => dispatch(farmersCurrentPage(page)),
    aggregatorAdministratorsCurrentPage: page =>
      dispatch(aggregatorAdministratorsCurrentPage(page)),
    listDeposits: (aggregator, page) => dispatch(listDeposits({ aggregator, page })),
    listDepositsReset: () => dispatch(listDepositsReset()),
    depositsCurrentPage: page => dispatch(depositsCurrentPage(page)),
  };
};

// The component uses the redux store
export default connect(mapStateToProps, mapDispatchToProps)(WfpManagerAggregatorView);
