import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import PageStandard from "../../components/PageStandard";
import { getDeletedItemName, valueOrDefault } from "../../utils";
import List from "./components/List";
import AggregatorOfferRejectionReasonsEditModal from "./components/Modal";
import PropTypes from "prop-types";
import {
  listTraderMarketOfferRejectionReasons,
  listTraderMarketOfferRejectionReasonsReset,
  deleteTraderMarketOfferRejectionReasonsDetails,
  traderMarketOfferRejectionReasonsDetailsReset,
} from "../../actions";
import Modal from "../../components/Modal";
import ButtonsWrapper from "../../components/ButtonsWrapper";
import Button from "../../components/Button";
import { iconAdd } from "@wfp/icons";

export const WfpManagerAggregatorOfferRejectionReasonsView = ({
  isFetching,
  errorMessage,
  traderMarketOfferRejectionReasons,
  page,
  count,
  listTraderMarketOfferRejectionReasons,
  listTraderMarketOfferRejectionReasonsReset,
  traderMarketOfferRejectionReasonsDetailsReset,
  deleteTraderMarketOfferRejectionReasonsDetails,
}) => {
  const { t } = useTranslation();
  const [modalOptions, setModalOptions] = useState({isOpen: false, id: undefined, type: undefined});
  const [deleteModalOptions, setDeleteModalOptions] = useState({isOpen: false, id: undefined});

  const onClickAdd = () => {
    traderMarketOfferRejectionReasonsDetailsReset();
    setModalOptions({isOpen: true, id: "add", type: "create"});
    listTraderMarketOfferRejectionReasons();
  };

  const onDeleteSubmit = () => {
    deleteTraderMarketOfferRejectionReasonsDetails(deleteModalOptions.id);
    setDeleteModalOptions({isOpen: false, id: undefined});
  };

  // On component mount the data are fetched
  useEffect(() => {
    listTraderMarketOfferRejectionReasons();
    return () => {
      listTraderMarketOfferRejectionReasonsReset();
    };
  }, []);

  return (
    <PageStandard
      title={t("configuration.item.aggregatorOfferRejectionReasons")}
      additional={(
        <ButtonsWrapper>
          <Button icon={iconAdd} onClick={onClickAdd}>
            {t("common.add") + " " + t("configuration.item.aggregatorOfferRejectionReasons")}
          </Button>
        </ButtonsWrapper>
      )}
    >
      <List
        {...{
          isFetching,
          errorMessage,
          traderMarketOfferRejectionReasons,
          page,
          count,
          setModalOptions,
          setDeleteModalOptions,
        }}
      />
      <AggregatorOfferRejectionReasonsEditModal
        modalOptions={modalOptions}
        setModalOptions={setModalOptions}
      />
      <Modal
        isOpen={deleteModalOptions.isOpen}
        onValidate={onDeleteSubmit}
        title={t("common.delete") + " " + t("configuration.item.aggregatorOfferRejectionReasons")}
        danger={true}
        onCancel={() => setDeleteModalOptions({isOpen: false, id: undefined})}
        isLoading={false}
      >
        {t("configuration.common.deleteText") + getDeletedItemName(deleteModalOptions.id, traderMarketOfferRejectionReasons, "text")}
      </Modal>
    </PageStandard>
  );
};

WfpManagerAggregatorOfferRejectionReasonsView.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  traderMarketOfferRejectionReasons: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  listTraderMarketOfferRejectionReasons: PropTypes.func.isRequired,
  listTraderMarketOfferRejectionReasonsReset: PropTypes.func.isRequired,
  traderMarketOfferRejectionReasonsDetailsReset: PropTypes.func.isRequired,
  deleteTraderMarketOfferRejectionReasonsDetails: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    traderMarketOfferRejectionReasons: state.listTraderMarketOfferRejectionReasons.data.results,
    isFetching: state.listTraderMarketOfferRejectionReasons.isFetching,
    errorMessage: state.listTraderMarketOfferRejectionReasons.errorMessage,
    page: 1,
    count: valueOrDefault(state.listTraderMarketOfferRejectionReasons.data.results.length, 0),
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    listTraderMarketOfferRejectionReasons: () => dispatch(listTraderMarketOfferRejectionReasons()),
    listTraderMarketOfferRejectionReasonsReset: () =>
      dispatch(listTraderMarketOfferRejectionReasonsReset()),
    traderMarketOfferRejectionReasonsDetailsReset: () => dispatch(traderMarketOfferRejectionReasonsDetailsReset()),
    deleteTraderMarketOfferRejectionReasonsDetails: id => dispatch(deleteTraderMarketOfferRejectionReasonsDetails(id, true)),
  };
};

// The component uses the redux store
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WfpManagerAggregatorOfferRejectionReasonsView);
