import React, { useEffect } from "react";
import { connect } from "react-redux";
import PageStandard from "../../components/PageStandard";
import { valueOrDefault } from "../../utils";
import List from "./components/List";
import PropTypes from "prop-types";
import { listNotificationEvents, listNotificationEventsReset, listNotificationTextTemplates, listNotificationTextTemplatesReset } from "../../actions";
import { useTranslation } from "react-i18next";

export const WfpManagerSystemNotifications = ({
  isFetching,
  errorMessage,
  notificationEvents,
  notificationTextTemplates,
  page,
  count,
  listNotificationEvents,
  listNotificationEventsReset,
  listNotificationTextTemplates,
  listNotificationTextTemplatesReset,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    listNotificationEvents();
    listNotificationTextTemplates();
    return () => {
      listNotificationEventsReset();
      listNotificationTextTemplatesReset();
    };
  }, []);

  return (
    <PageStandard
      title={t("configuration.item.systemNotifications")}
    >
      <List
        {...{
          isFetching,
          errorMessage,
          notificationEvents,
          notificationTextTemplates,
          page,
          count,
        }}
      />
    </PageStandard>
  );
};

WfpManagerSystemNotifications.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  notificationEvents: PropTypes.arrayOf(PropTypes.object),
  notificationTextTemplates: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.number,
  count: PropTypes.number,
  listNotificationEvents: PropTypes.func.isRequired,
  listNotificationEventsReset: PropTypes.func.isRequired,
  listNotificationTextTemplates: PropTypes.func.isRequired,
  listNotificationTextTemplatesReset: PropTypes.func.isRequired,
};

export const mapStateToProps = (state) => {
  return {
    notificationEvents: state.listNotificationEvents.data,
    notificationTextTemplates: state.listNotificationTextTemplates.data.results,
    isFetching: state.listNotificationEvents.isFetching || state.listNotificationTextTemplates.isFetching,
    errorMessage: state.listNotificationEvents.errorMessage || state.listNotificationTextTemplates.errorMessage,
    page: 1,
    count: valueOrDefault(state.listNotificationTextTemplates.data.results.length, 0),
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    listNotificationTextTemplates: () => dispatch(listNotificationTextTemplates()),
    listNotificationTextTemplatesReset: () => dispatch(listNotificationTextTemplatesReset()),
    listNotificationEvents: () => dispatch(listNotificationEvents()),
    listNotificationEventsReset: () => dispatch(listNotificationEventsReset()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WfpManagerSystemNotifications);
