// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_fieldGroup_hdmE9g7_SEOoalSobOc0 {\n  margin-bottom: 2rem;\n}\n\n.style_fieldGroupHeader_qYwtP6k2hz0hZcBs9ZDQ {\n  font-size: 16px;\n  margin-top: 10px;\n  margin-bottom: 20px;\n  font-weight: 600;\n}\n\n.style_readonlyField_OAMS4Oesi6XN6BV25DNn {\n  font-size: 14px;\n  align-items: baseline;\n}\n\n.style_readonlyFields_fRacb3DKcPvmTHOl_Gl8 {\n  margin-bottom: 20px;\n}\n\n.style_readonlyLabel_oHw8AcLd1I0ytoc6JpsB {\n  font-weight: 600;\n}", "",{"version":3,"sources":["webpack://./src/containers/Profile/components/Form/style.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;AACF;;AAIA;EACE,eAAA;EACA,qBAAA;AADF;;AAIA;EACE,mBAAA;AADF;;AAIA;EACE,gBAAA;AADF","sourcesContent":[".fieldGroup {\n  margin-bottom: 2rem;\n}\n\n.fieldGroupHeader {\n  font-size: 16px;\n  margin-top: 10px;\n  margin-bottom: 20px;\n  font-weight: 600;\n}\n\n.fields {}\n\n.readonlyField {\n  font-size: 14px;\n  align-items: baseline;\n}\n\n.readonlyFields {\n  margin-bottom: 20px;\n}\n\n.readonlyLabel {\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldGroup": "style_fieldGroup_hdmE9g7_SEOoalSobOc0",
	"fieldGroupHeader": "style_fieldGroupHeader_qYwtP6k2hz0hZcBs9ZDQ",
	"readonlyField": "style_readonlyField_OAMS4Oesi6XN6BV25DNn",
	"readonlyFields": "style_readonlyFields_fRacb3DKcPvmTHOl_Gl8",
	"readonlyLabel": "style_readonlyLabel_oHw8AcLd1I0ytoc6JpsB"
};
export default ___CSS_LOADER_EXPORT___;
