import client from "../client";
import { completeUrl, parseParams } from "../utils";
import { resourceList } from "./resourceList";

//  Actions available for generic delete resource reducer
export const RESOURCE_DELETE_REQUEST_ = "RESOURCE_DELETE_REQUEST_";
export const RESOURCE_DELETE_SUCCESS_ = "RESOURCE_DELETE_SUCCESS_";
export const RESOURCE_DELETE_ERROR_ = "RESOURCE_DELETE_ERROR_";
export const RESOURCE_DELETE_RESET_ = "RESOURCE_DELETE_RESET_";

// Action creator for start fetching a resource
export const resourceDeleteRequest = resource => () => {
  return {
    type: `${RESOURCE_DELETE_REQUEST_}${resource}`,
  };
};

// Action creator for a successful delete resource request
export const resourceDeleteSuccess = resource => () => {
  return {
    type: `${RESOURCE_DELETE_SUCCESS_}${resource}`,
  };
};

// Action creator for a successful delete resource request
export const resourceDeleteError = resource => (errorMessage, error) => {
  return {
    type: `${RESOURCE_DELETE_ERROR_}${resource}`,
    errorMessage,
    error,
  };
};

// Action creator for resetting the data
export const resourceDeleteReset = resource => () => {
  return {
    type: `${RESOURCE_DELETE_RESET_}${resource}`,
  };
};

// Here we dispatch the request action and then, after it is resolved, the success action
// or, if fails, the error action
export const resourceDelete = (resource, url) => (id, refreshListOnSuccess = false, params) => {
  return dispatch => {
    dispatch(resourceDeleteRequest(resource)());
    const completedUrl = id ? completeUrl(`${url}${id}`) : url;
    return client.delete(
      completedUrl, {
        params,
        paramsSerializer: (params) => parseParams(params),
      })
      .then(() => {
        dispatch(resourceDeleteSuccess(resource)());
        if(refreshListOnSuccess) dispatch(resourceList(resource, url)());
        return true;
      })
      .catch(error => {
        dispatch(resourceDeleteError(resource)(error.message, error.response.data));
        return false;
      });
  };
};
