import React, { useMemo } from "react";
import { withTranslation, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Loading from "../../../../components/Loading";
import Module from "../../../../components/Module";
import {
  displayErrorMessage,
  formatNumber,
  getCountryObjectFromCache,
} from "../../../../utils";
import { generateViewButton } from "../../../../utils/table";
import {
  displaySelectedCountryTranslation,
  getLocalisedName,
} from "../../../../utils/i18n";
import NewTable from "../../../../components/NewTable";

// The list of Aggregators that user can view and delete
export const List = ({
  isFetching,
  errorMessage,
  commodities,
  page,
  pageSize,
  onPageChange,
  count,
  countryDashboardData,
  unitsOfMeasure,
  showAll,
  onClickAddCommodity,
}) => {
  if (isFetching) {
    return <Loading isVisible />;
  }

  if (errorMessage) {
    return <>{displayErrorMessage(errorMessage)}</>;
  }

  const { t } = useTranslation();

  const commodityDataFromDashboard = (obj, field, subfield = null) => {
    const { commodities_stats } = countryDashboardData;
    if (!commodities_stats) return 0;
    const relevantCommodity = commodities_stats.find(
      ({ commodity_id }) => commodity_id === obj.row.original.id,
    );
    if (!relevantCommodity) return 0;
    return subfield
      ? relevantCommodity[field][subfield]
      : relevantCommodity[field];
  };

  const getLandUnitForCountry = () => {
    if (countryDashboardData && countryDashboardData.country_info) {
      return getLocalisedName(
        countryDashboardData.country_info.land_unit_of_measurement,
      );
    }
  };

  const renderAddToCountryCellContent = (obj) => {
    const currentCountry = getCountryObjectFromCache();
    const commodityCountries = obj.row.original.countries;
    return !commodityCountries.includes(currentCountry.id) ? (
      generateViewButton(
        obj.row.original,
        onClickAddCommodity,
        t("commodities.list.addButton", {
          country: getCountryObjectFromCache().name,
        }),
        t,
      )
    ) : (
      <>
        {t("commodities.list.availableTo", {
          country: currentCountry.name,
        })}
      </>
    );
  };

  const columns = [
    {
      id: "commodity",
      Header: t("commodities.list.column.commodity"),
      accessor: "name",
      width: 200,
    },
    {
      id: "translations",
      Header: t("commodities.list.column.translation"),
      accessor: "translations",
      Cell: ({ row: { original } }) => {
        return displaySelectedCountryTranslation(
          original,
          "name_translations",
          t,
        );
      },
    },
  ];

  const showAllColumns = [
    ...columns,
    {
      id: "unitsOfMeasurement",
      Header: t("commodities.list.column.unitsOfMeasurement"),
      accessor: "units",
    },
    { id: "cellContent", Cell: (obj) => renderAddToCountryCellContent(obj) },
  ];

  const hideAllColumns = [
    ...columns,
    {
      id: "farmersWithCommodity",
      Header: t("commodities.list.column.farmersWithCommodity"),
      alignRight: true,
      Cell: (obj) =>
        formatNumber(
          commodityDataFromDashboard(obj, "farmers_with_commodity_count"),
        ),
    },
    {
      id: "sumOfLandSize",
      Header: `${t(
        "commodities.list.column.sumOfLandSize",
      )} ${getLandUnitForCountry()}`,
      alignRight: true,
      Cell: (obj) =>
        formatNumber(
          commodityDataFromDashboard(obj, "land_with_commodity_sum"),
        ),
    },
    {
      id: "quantityDeposited",
      Header: t("commodities.list.column.quantityDeposited"),
      headerClassName: "test",
      alignRight: true,
      Cell: (obj) =>
        (() => {
          const deposits = commodityDataFromDashboard(
            obj,
            "deposits_with_commodity_quantity_sum_full",
          );
          if (deposits === 0) return deposits;
          const quantitiesByUnit = deposits.reduce((acc, cur) => {
            const unitOfMeasure = unitsOfMeasure.find(
              (item) => item.id === parseInt(cur.unit),
            ) || { code: " ---" };
            return {
              ...acc,
              [unitOfMeasure.code]: acc[unitOfMeasure.code]
                ? acc[unitOfMeasure.code] + Number(cur.quantity)
                : Number(cur.quantity),
            };
          }, {});

          return Object.keys(quantitiesByUnit).reduce((acc, cur) => {
            return acc + `${acc ? "; " : ""}${quantitiesByUnit[cur]}${cur}`;
          }, "");
        })(),
    },
  ];

  const displayColumns = useMemo(() => {
    return [
      ...(showAll ? showAllColumns : hideAllColumns),
      {
        id: "wingsId",
        Header: t("commodities.list.column.wingsId"),
        accessor: "wings_id",
        width: 200,
      },
    ];
  }, [showAll, showAllColumns, hideAllColumns]);

  return (
    <>
      <Module
        table={
          <NewTable
            loading={isFetching}
            columns={displayColumns}
            data={commodities}
            totalItems={count}
            pageIndex={page}
            pageSize={pageSize}
            onPageChange={onPageChange}
            hidePagination={showAll}
          />
        }
      />
    </>
  );
};

// propTypes for the List component
List.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  commodities: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  countryDashboardData: PropTypes.object,
  unitsOfMeasure: PropTypes.array.isRequired,
  showAll: PropTypes.bool,
  onClickAddCommodity: PropTypes.func.isRequired,
};

// defaultProps for the List component
List.defaultProps = {
  errorMessage: "",
  showAll: false,
};

export default withTranslation()(List);
