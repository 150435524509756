import React from "react";
import PropTypes from "prop-types";
import { Toggle as WFPToggle } from "@wfp/ui";

import { displayErrorHelpText } from "../../utils";
import style from "./style.scss";

// A wrapper of the Toggle component form WFP UI Kit
const Toggle = ({
  id,
  label,
  customLabels,
  errorMessage,
  defaultToggled,
  input: { value, onChange },
}) => (
  <div className="wfp--form-item">
    {
      label
        ? <label htmlFor={id} className="wfp--label">{label}</label>
        : ""
    }
    <div className={style.wrapper}>
      <WFPToggle
        toggled={value === "" ? defaultToggled : value}
        onChange={onChange}
        id={id}
        name={id}
        labelA={customLabels ? (customLabels.left || "") : "NO"}
        labelB={customLabels ? (customLabels.right || "") : "YES"}
      />
    </div>
    {displayErrorHelpText(errorMessage)}
  </div>
);

// propTypes for the Toggle component
Toggle.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  input: PropTypes.object.isRequired,
  errorMessage: PropTypes.string,
  customLabels: PropTypes.object,
  defaultToggled: PropTypes.bool,
};

// defaultProps for the Input component
Toggle.defaultProps = {
  label: "",
  errorMessage: "",
  customLabels: undefined,
  defaultToggled: false,
};

export default Toggle;
