import client, { AX_OMIT_COUNTRY_HEADER_NAME } from "../client";
import { completeUrl } from "../utils";
import { resourceList } from "./resourceList";

// Actions available for generic update resource reducer
export const RESOURCE_UPDATE_REQUEST_ = "RESOURCE_UPDATE_REQUEST_";
export const RESOURCE_UPDATE_SUCCESS_ = "RESOURCE_UPDATE_SUCCESS_";
export const RESOURCE_UPDATE_ERROR_ = "RESOURCE_UPDATE_ERROR_";
export const RESOURCE_UPDATE_RESET_ = "RESOURCE_UPDATE_RESET_";

// Action creator for start updating a resource
export const resourceUpdateRequest = (resource) => () => {
  return {
    type: `${RESOURCE_UPDATE_REQUEST_}${resource}`,
  };
};

// Action creator for a successful update resource request
export const resourceUpdateSuccess = (resource) => (data) => {
  return {
    type: `${RESOURCE_UPDATE_SUCCESS_}${resource}`,
    data,
  };
};

// Action creator for a successful list resource request
export const resourceUpdateError = (resource) => (error) => {
  return {
    type: `${RESOURCE_UPDATE_ERROR_}${resource}`,
    error,
  };
};

// Action creator for resetting the data
export const resourceUpdateReset = (resource) => () => {
  return {
    type: `${RESOURCE_UPDATE_RESET_}${resource}`,
  };
};

// Here we dispatch the request action and then, after it is resolved, the success action
// or, if fails, the error action
export const resourceUpdate =
  (resource, url, noCountryHeader = false) =>
    (id, data, appendUrl = "", refreshListOnSuccess = false) => {
      return (dispatch) => {
        dispatch(resourceUpdateRequest(resource)());
        return client
          .put(completeUrl(`${url}${id}/${appendUrl}`), data, {
            headers: noCountryHeader ? { [AX_OMIT_COUNTRY_HEADER_NAME]: true } : {},
          })
          .then((result) => {
            dispatch(resourceUpdateSuccess(resource)(result.data));
            if(refreshListOnSuccess) dispatch(resourceList(resource, url)());
            return result;
          })
          .catch((error) => {
            dispatch(resourceUpdateError(resource)(error.response));
            return undefined;
          });
      };
    };
