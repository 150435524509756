import React from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import { iconEdit, iconDelete } from "@wfp/icons";

import Select from "../../../../components/Select";
import Input from "../../../../components/Input";
import Toggle from "../../../../components/Toggle";
import FormWrapper from "../../../../components/FormWrapper";
import Module, { SectionTitle } from "../../../../components/Module";
import Loading from "../../../../components/Loading";
import MessageInformation from "../../../../components/MessageInformation";
import { displayErrorMessage, isEmpty } from "../../../../utils";
import { Grid, Row, Col } from "../../../../components/Grid";
import Spacer from "../../../../components/Spacer";
import ButtonsWrapper from "../../../../components/ButtonsWrapper";
import Button from "../../../../components/Button";
import TableInMemory from "../../../../components/Table";
import { menu } from "../../../../utils/table";
import { arrayToString } from "../../../../utils";
import ModalConfirm from "../../../../components/ModalConfirm";
import MessageError from "../../../../components/MessageError";
import style from "./style.scss";
import {useTranslation} from "react-i18next";
import i18n from "../../../../i18n";

// The list of columns related to Aggregator Administrators
export const aggregatorAdministratorsColumns = (onClickEdit, onClickDelete, t) => [
  {
    Header: "column.lastName",
    accessor: "last_name",
  },
  {
    Header: "column.position",
    accessor: "position",
  },
  {
    Header: "",
    Cell: row => menu(
      [
        {
          key: `${row.original.id}_edit`,
          icon: iconEdit,
          label: t("common.edit"),
          onClick: () => onClickEdit(row.original.id),
          visible: true,
          data: row.original,
        },
        {
          key: `${row.original.id}_delete`,
          icon: iconDelete,
          label: t("common.delete"),
          onClick: () => onClickDelete(row.original.id),
          visible: true, data: row.original,
        },
      ],
    ),
    width: 40,
  },
];

// A function used to display field errors related to Aggregator
// and Administrator records sent to server
export const displayFieldsErrors = fieldsErrorsWithData => {
  if (!fieldsErrorsWithData.fieldsErrors) {
    return undefined;
  }
  let aggregatorContent = undefined;
  let administratorsContent = undefined;
  if(!isEmpty(fieldsErrorsWithData.fieldsErrors.aggregator)){
    aggregatorContent = (
      <>
        <span className={style.errorTitle}>
          {/* eslint-disable-next-line max-len */}
          {i18n.t("representativeAggregatorCreationRequestDetails.form.aggregatorHasFollowingErrors")}
        </span>
        {
          Object.keys(fieldsErrorsWithData.fieldsErrors.aggregator).map(key => (
            <div key={key}>
              <span className={style.errorLabel}>
                {key.replace(/_/g, " ")}
              </span>
              {arrayToString(fieldsErrorsWithData.fieldsErrors.aggregator[key])}
            </div>
          ))
        }
      </>
    );
  }
  if(!isEmpty(fieldsErrorsWithData.fieldsErrors.administrators)){
    administratorsContent = (
      <>
        {
          fieldsErrorsWithData.fieldsErrors.administrators.map((item, index) => {
            if(isEmpty(item)) {
              return undefined;
            }
            const administrator = fieldsErrorsWithData.administrators[index];
            return (
              <div key={`administrator_${index}`}>
                <span className={style.errorTitle}>
                  {
                    // eslint-disable-next-line max-len
                    i18n.t("representativeAggregatorCreationRequestDetails.form.administratorHasFollowingErrors",
                      {administrator: administrator.last_name},
                    )}
                </span>
                {
                  Object.keys(item).map(key => (
                    <div key={key}>
                      <span className={style.errorLabel}>
                        {key.replace(/_/g, " ")}
                      </span>
                      {arrayToString(item[key])}
                    </div>
                  ))
                }
              </div>
            );
          })
        }
      </>
    );
  }
  return (
    <MessageError>
      {aggregatorContent}
      {administratorsContent}
    </MessageError>
  );
};

// The details of a Aggregator Organization
export const Form = (
  {
    isFetching,
    errorMessage,
    onClickValidate,
    onClickAddAdministrator,
    administrators,
    legalStatuses,
    onClickEditAdministrator,
    onClickDeleteAdministrator,
    isDeleteAdministratorModalOpen,
    onClickConfirmDeleteAdministrator,
    onClickCancelDeleteAdministrator,
    organizationTypes,
    languages,
    fieldsErrorsWithData,
  },
) => {

  const { t } = useTranslation();

  if (isFetching) {
    return <Loading isVisible />;
  }

  if (errorMessage) {
    return (
      <>
        {displayErrorMessage(errorMessage)}
      </>
    );
  }

  {/* eslint-disable-next-line max-len */}
  return (
    <>
      {displayFieldsErrors(fieldsErrorsWithData)}
      <Grid>
        <Row>
          <Col sm={8}>
            <Module
              title={t("representativeAggregatorCreationRequestDetails.form.title")}
              footer={
                <ButtonsWrapper>
                  <Button onClick={onClickValidate}>{t("common.validate")}</Button>
                </ButtonsWrapper>
              }
            >
              <FormWrapper>
                <Grid>
                  <Row>
                    <Col sm={6}>
                      <Field
                        component={Input}
                        name="name"
                        id="name"
                        label={t("fieldName.name")}
                      />
                    </Col>
                  </Row>
                </Grid>
                <Grid>
                  <Row>
                    <Col sm={12}>
                      <Field
                        component={Input}
                        name="other_info"
                        id="other_info"
                        label={t("fieldName.otherInformation")}
                      />
                    </Col>
                  </Row>
                </Grid>
                <Grid>
                  <Row>
                    <Col sm={4}>
                      <Field
                        component={Input}
                        name="contact_email"
                        id="contact_email"
                        label={t("fieldName.email")}
                      />
                    </Col>
                    <Col sm={4}>
                      <Field
                        component={Input}
                        name="phone"
                        id="phone"
                        label={t("fieldName.phoneNumber")}
                      />
                    </Col>
                    <Col sm={4}>
                      <Field
                        component={Input}
                        name="wings_vendor_id"
                        id="wings_vendor_id"
                        label={t("fieldName.wingsId")}
                      />
                    </Col>
                  </Row>
                </Grid>
                <Grid>
                  <Row>
                    <Col sm={4}>
                      <Field
                        component={Input}
                        name="legal_address"
                        id="legal_address"
                        label={t("fieldName.legalAddress")}
                      />
                    </Col>
                    <Col sm={4}>
                      <Field
                        component={Input}
                        name="physical_address"
                        id="physical_address"
                        label={t("fieldName.physicalAddress")}
                      />
                    </Col>
                    <Col sm={4}>
                      <Field
                        component={Input}
                        name="district"
                        id="district"
                        label={t("fieldName.district")}
                      />
                    </Col>
                  </Row>
                </Grid>
                <Grid>
                  <Row>
                    <Col sm={4}>
                      <Field
                        component={Select}
                        name="legal_status"
                        id="legal_status"
                        label={t("fieldName.legalStatus")}
                        options={legalStatuses}
                        loading={false}
                      />
                    </Col>
                    <Col sm={4}>
                      <Field
                        component={Input}
                        name="year_of_establishment"
                        id="year_of_establishment"
                        label={t("fieldName.yearOfEstablishment")}
                      />
                    </Col>
                    <Col sm={4}>
                      <Field
                        component={Input}
                        name="tier"
                        id="tier"
                        label={t("fieldName.tier")}
                      />
                    </Col>
                  </Row>
                </Grid>
                <Grid>
                  <Row>
                    <Col sm={4}>
                      <Field
                        component={Select}
                        name="org_type"
                        id="org_type"
                        label={t("fieldName.organizationType")}
                        options={organizationTypes}
                        loading={false}
                      />
                    </Col>
                    <Col sm={4}>
                      <Field
                        component={Input}
                        name="membership_fee"
                        id="membership_fee"
                        label={t("fieldName.membershipFee")}
                      />
                    </Col>
                    <Col sm={4}>
                      <Field
                        component={Select}
                        name="language"
                        id="language"
                        label={t("fieldName.language")}
                        options={languages}
                        loading={false}
                      />
                    </Col>
                  </Row>
                </Grid>
              </FormWrapper>
              <Grid>
                <Row>
                  <Col sm={12}>
                    <SectionTitle title={t("section.options")} />
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <Field
                      component={Toggle}
                      name="membership_fee_needed"
                      id="membership_fee_needed"
                      label={t("fieldName.displayMembershipFee")}
                    />
                  </Col>
                </Row>
              </Grid>
              <Grid>
                <Row>
                  <Col sm={12}>
                    <SectionTitle title={t("section.aggregators")} />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Button
                      kind="secondary"
                      onClick={onClickAddAdministrator}
                    >
                      {t("representativeAggregatorCreationRequestDetails.form.addAdministrator")}
                    </Button>
                    <Spacer height="1rem" />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <TableInMemory
                      loading={false}
                      columns={
                        aggregatorAdministratorsColumns(
                          onClickEditAdministrator,
                          onClickDeleteAdministrator,
                          t,
                        )
                      }
                      data={administrators}
                      pageSize={10}
                    />
                    <Spacer height="2rem" />
                  </Col>
                </Row>
              </Grid>
            </Module>
          </Col>
          <Col sm={4}>
            <Module title="Help">
              <MessageInformation>
                {t("representativeAggregatorCreationRequestDetails.form.info.one")}
              </MessageInformation>
              <p>
                {t("representativeAggregatorCreationRequestDetails.form.info.two")}
              </p>
              <Spacer height="1rem" />
              <p>
                {t("representativeAggregatorCreationRequestDetails.form.info.three")}
              </p>
            </Module>
          </Col>
        </Row>
      </Grid>
      <ModalConfirm
        isOpen={isDeleteAdministratorModalOpen}
        onValidate={onClickConfirmDeleteAdministrator}
        onCancel={onClickCancelDeleteAdministrator}
        title={
          t("representativeAggregatorCreationRequestDetails.form.deleteAdministratorModalTitle")
        }
        text={t("representativeAggregatorCreationRequestDetails.form.deleteAdministratorModalText")}
        isLoading={false}
      />
    </>
  );
};

// propTypes for the Form component
Form.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  legalStatuses: PropTypes.array.isRequired,
  onClickAddAdministrator: PropTypes.func.isRequired,
  onClickEditAdministrator: PropTypes.func.isRequired,
  onClickDeleteAdministrator: PropTypes.func.isRequired,
  isDeleteAdministratorModalOpen: PropTypes.bool.isRequired,
  onClickConfirmDeleteAdministrator: PropTypes.func.isRequired,
  onClickCancelDeleteAdministrator: PropTypes.func.isRequired,
  organizationTypes: PropTypes.array.isRequired,
  languages: PropTypes.array.isRequired,
  onClickValidate: PropTypes.func.isRequired,
  administrators: PropTypes.array.isRequired,
  fieldsErrorsWithData: PropTypes.object.isRequired,
};

// defaultProps for the Form component
Form.defaultProps = {
  errorMessage: "",
  id: "",
  currentToDeleteAdministrator: undefined,
};

export default reduxForm(
  { form: "aggregatorCreationRequestDetails", enableReinitialize: true },
)(Form);
