import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { countryDashboard } from "../../../../actions";
import { Col, Grid, Row } from "../../../../components/Grid";
import Loading from "../../../../components/Loading";
import { displayErrorMessage } from "../../../../utils";

import style from "../../style.scss";
import SummaryCard from "../SummaryCard";
import ReportsHyperlinkCard from "../ReportsHyperlinkCard";
import { isExternalUser } from "../../../../utils/roles";

export const SummaryView = ({
  isFetchingCountryDashboardData,
  errorMessageManagerCountryDashboard,
  countryDashboardData,
  getCountryDashboardData,
  user,
}) => {
  // Set country code
  useEffect(() => {
    getCountryDashboardData();
  }, []);

  const _isExternalUser = isExternalUser(user);

  const extractDashboardData = (field, subfield) => {
    if (
      !countryDashboardData ||
      !countryDashboardData[field] ||
      !countryDashboardData[field][subfield]
    )
      return 0;
    return countryDashboardData[field][subfield];
  };

  const cardRows = [
    {
      cards: [
        {
          heading: "totalFarmerRegistrations",
          subheading: "inPast7Days",
          hValue: extractDashboardData(
            "farmers_stats_full",
            "farmers_registration_count",
          ),
          sValue: extractDashboardData(
            "farmers_stats_seven_days",
            "farmers_registration_count",
          ),
        },
        {
          heading: "totalDepositsCollected",
          subheading: "inPast7Days",
          hValue: extractDashboardData(
            "farmers_stats_full",
            "deposits_collected_count",
          ),
          sValue: extractDashboardData(
            "farmers_stats_seven_days",
            "deposits_collected_count",
          ),
        },
        {
          heading: "totalOffersCreated",
          subheading: "inPast7Days",
          hValue: extractDashboardData(
            "farmers_stats_full",
            "offers_created_count",
          ),
          sValue: extractDashboardData(
            "farmers_stats_seven_days",
            "offers_created_count",
          ),
        },
      ],
      Component: SummaryCard,
    },
    {
      cards: [
        {
          heading: "totalExchangesDone",
          subheading: "inPast7Days",
          hValue: extractDashboardData(
            "farmers_stats_full",
            "exchanges_done_count",
          ),
          sValue: extractDashboardData(
            "farmers_stats_seven_days",
            "exchanges_done_count",
          ),
        },
        {
          heading: "totalFarmerPaymentsDone",
          subheading: "inPast7Days",
          hValue: extractDashboardData(
            "farmers_stats_full",
            "farmers_payment_done_count",
          ),
          sValue: extractDashboardData(
            "farmers_stats_seven_days",
            "farmers_payment_done_count",
          ),
        },
      ],
      Component: SummaryCard,
    },
    {
      cards: [
        {
          links: {
            dotsDashboard:
              "https://dots.wfp.org/workspace/module/view/latest/ri.workshop.main.module.152e6633-d6d3-413e-84f7-017f48715f5c",
            tableau:
              "https://analytics.wfp.org/views/DTUProductsBusinessMetrics/Farm2Go?%3Aembed=y&%3Aiid=1&%3AisGuestRedirectFromVizportal=y#1",
            yammer:
              "https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI5OTIzNTY4NDM1MiJ9/all",
          },
        },
      ],
      Component: ReportsHyperlinkCard,
      hide: _isExternalUser,
    },
  ];

  return (
    <div style={{ marginTop: "1rem" }}>
      {isFetchingCountryDashboardData ? (
        <Loading isVisible={true} />
      ) : (
        <Grid>
          <Row data-testid={"row"}>
            <Col sm={12}>
              {displayErrorMessage(errorMessageManagerCountryDashboard)}

              <div className={style.summaryCards}>
                {cardRows.map(
                  ({ cards, Component, hide }, i) =>
                    !hide && (
                      <div
                        key={`summaryCardRow-${i}`}
                        className={style.summaryCardRow}
                      >
                        {cards.map((card, j) => (
                          <Component key={`summaryCard-${i}-${j}`} {...card} />
                        ))}
                      </div>
                    ),
                )}
              </div>
            </Col>
          </Row>
        </Grid>
      )}
    </div>
  );
};

// propTypes for the SummaryView component
SummaryView.propTypes = {
  getCountryDashboardData: PropTypes.func,
  isFetchingCountryDashboardData: PropTypes.bool,
  errorMessageManagerCountryDashboard: PropTypes.string,
  countryDashboardData: PropTypes.object,
  user: PropTypes.object,
};

// Starting from the redux state it gets data related to logged in user
export const mapStateToProps = (state) => {
  return {
    isFetchingCountryDashboardData: state.countryDashboard.isFetching,
    errorMessageManagerCountryDashboard: state.countryDashboard.errorMessage,
    countryDashboardData: state.countryDashboard.data,
    user: state.userDetail.data,
  };
};

// Maps functions to dispatch actions
export const mapDispatchToProps = (dispatch) => {
  return {
    getCountryDashboardData: () => dispatch(countryDashboard()),
  };
};

// The component uses the redux store
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SummaryView));
