import {
  valueOrDefault,
  arrayToString,
  isEmpty,
  formatNumber,
  formatDateDefault,
  getCountryObjectFromCache,
  findInTree,
} from "./";
import { COMMODITY_UNITS, DELIVERY_LOCATIONS } from "../constants";
import { languages } from "./i18n";

// A function to adapt a single object to the format { value: x, label: y }
// starting from a given pair ok keys
export const adaptOption = (data, valueKey, labelKey) =>
  !isEmpty(data) && { value: data[valueKey], label: data[labelKey] };

// A function to adapt a list of object to the format { value: x, label: y }
// starting from a given pair ok keys
// it is possible to add an additional field to te label
// it is also possible to attach received data in an additional key
// to make them available using the key "data"
// it is also possible to add a prefix to the label
export const adaptOptions = (
  data,
  valueKey,
  labelKey,
  labelPrefixKey = "",
  additionalLabelKey = "",
  withData = false,
) => {
  if (!data) {
    return [];
  }
  return data.map((item) => {
    let result = { value: item[valueKey] };
    let label = item[labelKey];
    if (labelPrefixKey) {
      label = `${item[labelPrefixKey]} ${label}`;
    }
    if (additionalLabelKey) {
      label = `${label} (${item[additionalLabelKey]})`;
    }
    result = { ...result, label };
    if (withData) {
      result = { ...result, data: item };
    }
    return result;
  });
};

// A function to get the color of the status tag
export const getStatusColor = (status) =>
  (status === "completed" && "#008800") ||
  (status === "declined" && "#cc0000") ||
  "#0a6eb4";

// A function to adapt trader to a custom format
export const adaptMarketOffers = (marketOffers) =>
  valueOrDefault(
    marketOffers &&
      marketOffers.map((marketOffer) => ({
        ...marketOffer,
        price: `${marketOffer.currency.symbol} ${formatNumber(
          marketOffer.price,
        )}`,
        quantity: `${marketOffer.unit_of_measure.code} ${formatNumber(
          marketOffer.minimum_quantity,
        )}`,
        formatted_minimum_quantity: `${
          marketOffer.unit_of_measure.code
        } ${formatNumber(marketOffer.minimum_quantity)}`,
        formatted_maximum_quantity: `${
          marketOffer.unit_of_measure.code
        } ${formatNumber(marketOffer.maximum_quantity)}`,
        status: valueOrDefault(marketOffer.status, ""),
      })),
    [],
  );

// A function to adapt a trader to a custom format
// if user is logged in as Trader Representative
// the list will contain only an item
export const adaptTrader = (traders) =>
  valueOrDefault(traders.length && traders[0], {});

// A function to adapt a single record on Trader
// with full details
export const adaptTraderDetails = (trader) => {
  if (isEmpty(trader)) {
    return {};
  }
  return {
    ...trader,
    provided_services: valueOrDefault(
      arrayToString(
        trader.provided_services
          ? trader.provided_services.map((commodity) => commodity.name)
          : [],
      ),
      "No services",
    ),
  };
};

// A function to adapt traders to a custom format
export const adaptTraders = (traders) =>
  valueOrDefault(
    traders &&
      traders.map((trader) => ({
        ...trader,
      })),
    [],
  );

// A function to adapt market offer to a custom format
export const adaptMarketOffer = (marketOffer) => {
  if (!marketOffer) {
    return {};
  }
  const deliveryLocation = DELIVERY_LOCATIONS.find(
    (item) => item.value === marketOffer.delivery_location,
  );
  return {
    ...marketOffer,
    delivery_location: valueOrDefault(
      deliveryLocation && deliveryLocation.label,
      "Unknown",
    ),
  };
};

// A function to adapt aggregators received from server to a custom format
export const adaptAggregators = (aggregators) =>
  valueOrDefault(
    aggregators &&
      aggregators.map((aggregator) => ({
        ...aggregator,
        allDeposits: aggregator.deposits,
        deposits: valueOrDefault(
          arrayToString(
            groupAndSumDepositsByCommodityAndUnit(aggregator.deposits).map(
              (item) =>
                `${Math.round(item.quantity)} ${item.unit_of_measure.code} ${
                  item.commodity.name
                }`,
            ),
          ),
          "No deposit",
        ),
      })),
    [],
  );

export const groupAndSumDepositsByCommodityAndUnit = (deposits) => {
  const groupedAndSummedDepositsArray = deposits.reduce((prev, item) => {
    const groupBy = `${item.commodity.name}_${item.unit_of_measure.code}`;
    if (prev[groupBy]) {
      prev[groupBy] = {
        ...prev[groupBy],
        quantity: prev[groupBy].quantity + Math.round(item.quantity),
      };
    } else {
      prev[groupBy] = {
        quantity: Math.round(item.quantity),
        unit_of_measure: item.unit_of_measure,
        commodity: item.commodity,
      };
    }
    return prev;
  }, {});
  return Object.values(groupedAndSummedDepositsArray).sort(
    (depositGroupA, depositGroupB) => {
      return depositGroupA.commodity.name.localeCompare(
        depositGroupB.commodity.name,
      );
    },
  );
};

// A function to adapt aggregators received from server to a custom format
export const adaptWfpManagerAggregators = (aggregators) =>
  valueOrDefault(
    aggregators &&
      aggregators.map((aggregator) => ({
        ...aggregator,
        women_farmer_percentage: `${aggregator.women_farmer_percentage} %`,
        commodities_grown: valueOrDefault(
          arrayToString(
            aggregator.commodities_grown.map((commodity) => commodity.name),
          ),
          "No commodities",
        ),
      })),
    [],
  );

// A function to adapt aggregators received from server to a custom format
export const adaptWfpManagerAggregatorCreationRequests = (
  aggregatorCreationRequests,
) =>
  valueOrDefault(
    aggregatorCreationRequests &&
      aggregatorCreationRequests.map((aggregatorCreationRequest) => ({
        ...aggregatorCreationRequest,
      })),
    [],
  );

// A function to adapt data to a custom format
export const adaptCommodities = (commodities) =>
  adaptOptions(commodities, "id", "name", undefined, undefined, true);

// A function to adapt data to a custom format
export const adaptCommoditiesList = (commodities, unitsOfMeasure) =>
  valueOrDefault(
    commodities &&
      commodities.map((commodity) => {
        let translations = "";
        Object.keys(commodity.name_translations).map(
          (key) =>
            (translations += translations
              ? `; ${key.toUpperCase()}: ${commodity.name_translations[key]}`
              : `${key.toUpperCase()}: ${commodity.name_translations[key]}`),
        );
        let units = "";
        if (unitsOfMeasure && unitsOfMeasure.length) {
          commodity.units.forEach((unitId, index) => {
            const unitOfMeasure = unitsOfMeasure.find(
              (unit) => unit.id === unitId,
            );
            units +=
              index < commodity.units.length - 1
                ? `${unitOfMeasure.name}, `
                : `${unitOfMeasure.name}`;
          });
        }

        return {
          ...commodity,
          units,
          translations: translations,
          dto: commodity,
        };
      }),
    [],
  );

// A function to adapt data to a custom format
export const adaptProducts = (products) => adaptOptions(products, "id", "name");

// A function to adapt data to a custom format
export const adaptLanguages = (languages) =>
  adaptOptions(languages, "language", "language");

// A function to adapt data to a custom format
export const adaptLegalStatuses = legalStatuses => adaptOptions(legalStatuses, "id", "name");

// A function to adapt tier data to a custom format
export const adaptTiers = tiers => adaptOptions(tiers, "value", "label");

// A function to adapt tier data to a custom format
export const adaptUmbrellaOrganizations = orgs => adaptOptions(orgs, "id", "name");

// A function to adapt data to a custom format
export const adaptCardTypes = (cardTypes) =>
  adaptOptions(cardTypes, "value", "label");

// A function to adapt data to a custom format
export const adaptGenders = (genders) =>
  adaptOptions(genders, "value", "label");

// A function to adapt data to a custom format
export const adaptOrganizationTypes = organizationTypes => (
  adaptOptions(organizationTypes, "id", "name")
);

// A function to adapt data to a custom format
export const adaptMembershipFeeStatuses = (membershipFeeStatuses) =>
  adaptOptions(membershipFeeStatuses, "value", "label");

// A function to adapt the list of Trader Representatives to a custom format
export const adaptTraderRepresentatives = (traderRepresentatives) =>
  valueOrDefault(traderRepresentatives, []);

// A function to adapt the list of Aggregator Administrators to a custom format
export const adaptAggregatorAdministrators = (aggregatorAdministrators) =>
  valueOrDefault(aggregatorAdministrators, []);

// A function to adapt the list of CIAM users to a custom format
export const adaptCIAMUsers = (ciamUsers) => {
  return valueOrDefault(ciamUsers, []);
};

// A function to adapt units of measure received from server to a custom format
export const adaptUnitsOfMeasure = (unitsOfMeasure) => {
  const commodityUnits = unitsOfMeasure
    ? unitsOfMeasure.filter((unit) => unit.type === COMMODITY_UNITS)
    : [];
  return adaptOptions(commodityUnits, "id", "name", undefined, undefined, true);
};

// A function to adapt aggregator to a custom format
export const adaptAggregator = (aggregator) =>
  valueOrDefault(
    !isEmpty(aggregator) && {
      ...aggregator,
      commodities_grown: valueOrDefault(
        arrayToString(
          aggregator.commodities_grown.map((commodity) => commodity.name),
        ),
        "No commodities",
      ),
    },
    {},
  );

// A function to adapt aggregator to a custom format
export const adaptAggregatorCreationRequest = (aggregator) =>
  valueOrDefault(
    !isEmpty(aggregator) && {
      ...aggregator,
    },
    {},
  );

// A function to adapt farmers to a custom format
export const adaptFarmers = (farmers) =>
  valueOrDefault(
    farmers &&
      farmers.map((farmer) => ({
        ...farmer,
        produce: arrayToString(
          farmer.produce.map((item) => item.commodity.name),
        ),
      })),
    [],
  );

// A function to adapt deposits to a custom format
export const adaptDeposits = (deposits) =>
  valueOrDefault(
    deposits &&
      deposits.map((deposit) => {
        const price = formatNumber(valueOrDefault(deposit.price, 0));
        const currency = valueOrDefault(deposit.currency.code, "");
        const quantity = formatNumber(
          valueOrDefault(deposit.quantity),
          0,
        );
        const unitOfMeasure = valueOrDefault(deposit.unit_of_measure.code, "");

        return {
          ...deposit,
          formatted_price: `${price} ${currency}`,
          formatted_quantity: `${quantity} ${unitOfMeasure}`,
          deposit_date: valueOrDefault(
            formatDateDefault(deposit.backend_creation_time),
            "",
          ),
        };
      }),
    [],
  );

export const adaptPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString){
    return "";
  }

  const leadingZerosRemoved = phoneNumberString.replace(/^0+/, "");

  if (leadingZerosRemoved.includes("+")){
    return leadingZerosRemoved;
  } else {
    const prefix = `+${getCountryObjectFromCache().phone_prefix}`;
    return `${prefix}${leadingZerosRemoved}`;
  }
};

export const removeCountryCode = (phoneNumberString) => {
  const prefix = `+${getCountryObjectFromCache().phone_prefix}`;
  return phoneNumberString.replace(prefix, "");
};

// A function to adapt market orders to a custom format
export const adaptMarketOrders = (marketOrders) =>
  valueOrDefault(
    marketOrders &&
      marketOrders.map((marketOrder) => ({
        ...marketOrder,
        price: `${marketOrder.currency.symbol} ${formatNumber(
          marketOrder.price,
        )}`,
        minimum_quantity: `${marketOrder.unit_of_measure.code} ${formatNumber(
          marketOrder.minimum_quantity,
        )}`,
        quantity: `${marketOrder.unit_of_measure.code} ${formatNumber(
          marketOrder.quantity,
        )}`,
        current_value: `${marketOrder.currency.symbol} ${formatNumber(
          marketOrder.current_value,
        )}`,
        farmer_contributors_percentage: `${marketOrder.farmer_contributors_percentage} %`,
        women_percentage: `${marketOrder.women_percentage} %`,
        status: valueOrDefault(
          marketOrder.status && {
            text: marketOrder.status.label,
            color: getStatusColor(marketOrder.status.code),
            percentage:
              marketOrder.status.percentage > 100
                ? 100
                : marketOrder.status.percentage,
          },
          { text: "Unknown", color: "#0a6eb4", percentage: 0 },
        ),
      })),
    [],
  );

export const adaptAdministrativeAreas = (areas, parentId = undefined) => {
  if (!areas) {
    return [];
  }
  return areas.map((area) => {
    return {
      value: area.id,
      label: area.name,
      parentId,
      type: area.type,
      children:
        area.children && area.children.length
          ? adaptAdministrativeAreas(area.children, area.id)
          : undefined,
    };
  });
};

export const filterUnitsOfMeasureByType = (state, type) => {
  return state.listUnitsOfMeasure.data.results.filter((object) => object.type === type);
};

/**
 * @param {string} code // Language code
 * @returns Select Option for Languages
 */
export const adaptLanguageForInitialValues = (code) => {
  const language = languages.find(lang => lang.value === code);
  if(!language)return;
  return {
    value: code,
    label: language.engLabel ?
      `${language.engLabel} (${language.label})`
      : (
        language.label
      ),
  };
};

/**
 * @param {object} data // Initial Form Values.
 * @param {list} administrativeAreas // List of administrative areas as a tree.
 * @returns { label: string, value: string, children?: list}
 */
export const adaptLocationForInitialValues = (data, administrativeAreas) => {
  const location = data.location;
  const locationDetails = data.location_details;
  let result;
  if(location){
    result = findInTree(administrativeAreas, location, "value");
  }else if (locationDetails){
    result = {label: locationDetails.name, value: locationDetails.id};
  }
  return result;
};
