// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_instructionText_K0b8gljz1X5sdd2Z2H0d {\n  padding: 4px;\n  margin-bottom: 8px;\n}\n\n.style_dangerModal_IM13g4UnGABk8BdD3cdb {\n  border-top-color: red;\n}", "",{"version":3,"sources":["webpack://./src/containers/RepresentativeHome/components/SendingBackExchangeModal/style.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,kBAAA;AACJ;;AAEA;EACI,qBAAA;AACJ","sourcesContent":[".instructionText{\n    padding: 4px;\n    margin-bottom: 8px;\n}\n\n.dangerModal {\n    border-top-color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"instructionText": "style_instructionText_K0b8gljz1X5sdd2Z2H0d",
	"dangerModal": "style_dangerModal_IM13g4UnGABk8BdD3cdb"
};
export default ___CSS_LOADER_EXPORT___;
