import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Controller, useForm, FormProvider } from "react-hook-form";

import Input from "../../../../components/Input";
import Checkbox  from "../../../../components/Checkbox";
import { MandatoryLabel } from "../../../../components/MandatoryLabel";
import { getMandatoryFieldErrors } from "../../../../utils";
import { setDefaultValues } from "../../../../utils/wfpManagerConfigurationUtils";
import Loading from "../../../../components/Loading";
import Select from "../../../../components/Select";

const defaultValues = {
  name: "",
  location: {
    label: "",
    value: undefined,
  },
  active: false,
};

export const resolver = (values, t) => {
  let errors = {};
  if(values.name === "" || !values.name){
    errors.name = t("traderDetails.form.mandatoryFieldBlankError");
  }
  if(!values.location || !values.location.value || values.location.value === ""){
    errors.location = t("traderDetails.form.mandatoryFieldBlankError");
  }
  return {values, errors};
};

export function generateAdministrativeAreaOptions(administrativeAreas, administrativeAreaTypes) {
  return administrativeAreas.length > 0 && administrativeAreas.map((area) => {
    const matchingAdministrativeAreaType = administrativeAreaTypes.find(type => type.level === area.level);
    const name = matchingAdministrativeAreaType && matchingAdministrativeAreaType.level ? `${area.name} (${matchingAdministrativeAreaType.name})` : area.name;
    return ({
      label: name,
      value: area.id,
    });
  }) || [];
}

const Form = ({
  initialValues,
  administrativeAreas,
  administrativeAreaTypes,
  t,
  modalOptions,
  buttonRef,
  onSubmit,
  isFetching,
}) => {
  const methods = useForm({resolver, context: t});

  const { control, handleSubmit, setValue, getValues, formState: { errors }, reset } = methods;
  useEffect(() => {
    setDefaultValues(modalOptions, initialValues, setValue, reset);
  }, [initialValues]);

  useEffect(() => {
    reset();
  }, [modalOptions.id]);

  if(isFetching){
    return <Loading isVisible />;
  }
  const administrativeAreaOptions = generateAdministrativeAreaOptions(administrativeAreas, administrativeAreaTypes);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          render={({ field, fieldState }) => {
            return (
              <Input
                name="name"
                id="input-name"
                label={<MandatoryLabel label={t("configuration.common.name")} />}
                errorMessage={getMandatoryFieldErrors(errors, t, getValues, "name")}
                isDisabled={modalOptions.type === "view"}
                input={field}
                meta={fieldState}
              />
            );
          }}
          defaultValue={defaultValues.name}
          name="name"
        />
        <Controller
          control={control}
          render={({field}) => (
            <Select
              id={"location-select"}
              options={administrativeAreaOptions}
              loading={false}
              input={field}
              errorMessage={getMandatoryFieldErrors(errors, t, getValues, "location")}
              label={<MandatoryLabel label={t("aggregatorUmbrellaOrganizations.location")} />}
              isDisabled={modalOptions.type === "view"}
            />
          )}
          name="location"
          id="location"
        />
        <Controller
          control={control}
          render={({ field, fieldState }) => {
            return (
              <Checkbox
                label={<MandatoryLabel label={t("configuration.common.active")} />}
                id={"active"}
                name={"active"}
                defaultChecked={defaultValues.active}
                isDisabled={modalOptions.type === "view"}
                input={field}
                meta={fieldState}
              />
            );
          }}
          defaultValue={defaultValues.active}
          name="active"
        />
        <button data-testid={"hidden-button"} style={{ display: "none" }} ref={buttonRef} />
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  modalOptions: PropTypes.object.isRequired,
  fieldErrors: PropTypes.object,
  initialValues: PropTypes.object,
  administrativeAreas: PropTypes.array,
  administrativeAreaTypes: PropTypes.array,
  t: PropTypes.func.isRequired,
  buttonRef: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.func.isRequired,
};

export default withTranslation()(Form);
