import { Col, Grid, Row } from "../../../../../../components/Grid";
import { Controller, useWatch } from "react-hook-form";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";
import Select from "../../../../../../components/Select";
import Input from "../../../../../../components/Input";
import MarkdownEditor from "../../../../../../components/MarkdownEditor";
import { Tooltip } from "@wfp/ui";
import { iconInfo } from "@wfp/icons";

import style from "./style.scss";
import Icon from "../../../../../../components/Icon";
import MultiSelect from "../../../../../../components/MultiSelect";
import MultiCheckboxTable from "../../../../../../components/MultiCheckboxTable";

const InfoHubContentTypeStep = ({
  methods,
  getErrors,
  t,
  contentTypes,
  topics,
  aggregators,
  isCopy,
}) => {

  const {control, formState: { errors }, resetField, getValues, setValue } = methods;

  const selectedContentType = useWatch({ control, name: "content_type" });

  const isMCFContentType = useMemo(() => {
    return selectedContentType ? selectedContentType.value.includes("MCF") : false;
  }, [selectedContentType]);

  useEffect(() => {
    const selectedTopics = getValues("topics");
    if (!(selectedTopics && selectedTopics.some(topic => {
      if (isMCFContentType) {
        return topic.target === "MCF_FOUNDATION";
      } else {
        return topic.target === "AGGREGATOR";
      }
    }))) {
      setValue("topics", []);
      resetField("aggregators");
    }
  }, [isMCFContentType, getValues]);

  const renderTooltipContent = () => {
    return (
      <div>
        <span className={style.boldStart}>
          {t("infoHub.form.aggregatorInformationHeading")}
        </span>
        {t("infoHub.form.aggregatorInformationToolTip")}
        <br/>
        <span className={style.boldStart}>
          {t("infoHub.form.farmerTrainingHeading")}
        </span>
        {t("infoHub.form.farmerTrainingToolTip")}
      </div>
    );
  };

  const inputList = [
    {
      id: "content_type",
      label: t("infoHub.form.contentType"),
      placeholder: t("infoHub.form.contentTypePlaceholder"),
      options: contentTypes.map((contentType) => ({
        label: contentType,
        value: contentType,
      })),
      rules: { required: true },
      isMulti: false,
      Component: Select,
      tooltip: renderTooltipContent,
      disabled: isCopy,
    },
    // title
    {
      id: "name",
      label: t("infoHub.form.title"),
      placeholder: t("infoHub.form.titlePlaceholder"),
      helperText: t("infoHub.form.titleHelperText"),
      Component: Input,
      rules: { required: true },
    },
    // topic
    {
      id: "topics",
      label: t("infoHub.form.topic"),
      placeholder: t("infoHub.form.topicPlaceholder"),
      helperText: t("infoHub.form.topicHelperText"),
      options: topics
        .filter(topic => {
          if (isMCFContentType) {
            return topic.target === "MCF_FOUNDATION";
          } else {
            return topic.target === "AGGREGATOR";
          }
        }).map(topic => ({
          label: topic.name,
          value: topic.id,
          target: topic.target,
        })),
      rules: { required: true },
      isMulti: true,
      Component: Select,
    },
    // content
    {
      id: "content",
      label: t("infoHub.form.content"),
      placeholder: t("infoHub.form.contentPlaceholder"),
      helperText: t("infoHub.form.contentHelperText"),
      rules: { required: true },
      Component: MarkdownEditor,
    },
    // aggregators
    {
      id: "aggregators",
      label: t("infoHub.form.aggregators"),
      placeholder: t("infoHub.form.aggregatorsPlaceholder"),
      options: aggregators
        .filter(aggregator => {
          if (isMCFContentType) {
            return aggregator.managed_individuals.some(
              (ind) => ind === "mastercard_foundation_participants",
            );
          } else {
            return aggregator.managed_individuals.some(
              (ind) => ind === "farmers",
            );
          }
        })
        .map(aggregator => ({
          label: aggregator.name,
          value: aggregator.id,
        })),
      rules: { required: true },
      Component: MultiSelect,
      helperText: t("infoHub.form.aggregatorsHelperText"),
    },
    // linked traders
    // {
    //   id: "linkedTraders",
    //   label: t("infoHub.form.linkedTraders"),
    //   placeholder: t("infoHub.form.linkedTraders"),
    //   records: aggregators,
    //   fields: [{
    //     name: "evoucher-shop",
    //     label: "E-voucher shop",
    //   }, {
    //     name: "ffc",
    //     label: "FFC",
    //   }],
    //   columns: [
    //     {
    //       Header: t("Trader name"),
    //       accessor: "name",
    //       Cell: (props) => (<div className={style.headingText}>
    //         {/* eslint-disable-next-line react/prop-types */}
    //         {props.value}
    //       </div>),
    //       width: 300,
    //     },
    //   ],
    //   rules: { required: true },
    //   Component: MultiCheckboxTable,
    // },
  ];

  const renderComponent = (input) => {
    switch(input.Component) {
    case MultiSelect:
      return (
        <input.Component id={`multiselect-${input.id}`}
          input={input.field}
          errorMessage={getErrors(errors, input.id) || ""}
          label={input.label}
          options={input.options}
          helperText={input.helperText}
          placeholder={input.placeholder}
        />
      );
    case Select:
      return (
        <input.Component
          id={`select-${input.id}`}
          input={input.field}
          errorMessage={getErrors(errors, input.id) || ""}
          label={input.label}
          placeholder={
            input.placeholder
          }
          options={input.options}
          loading={false}
          helperText={input.helperText}
          isMulti={input.isMulti}
          isDisabled={input.disabled}
        />
      );
    case Input:
      return (
        <input.Component
          id={`input-${input.id}`}
          input={input.field}
          errorMessage={getErrors(errors, input.id) || ""}
          label={input.label}
          placeholder={
            input.placeholder
          }
          helperText={input.helperText}
          meta={input.fieldState}
        />
      );
    case MultiCheckboxTable:
      return (
        <input.Component
          id={`multi-checkbox-table-${input.id}`}
          input={input.field}
          columns={input.columns}
          records={input.records}
          errorMessage={getErrors(errors, input.id) || ""}
          label={input.label}
          meta={input.fieldState}
        />
      );
    case MarkdownEditor:
      return (
        <>
          <div>
            <label htmlFor={`input-${input.id}`} className="wfp--label">
              {input.label}
            </label>
          </div>
          <input.Component
            field={input.field}
          />
          <br/>
        </>
      );
    }
  };
  return (
    <>
      <Grid>
        {
          inputList.map((input, index) => {
            return (
              <Row key={`row-${index}`}>
                <Col sm={input.Component === Select ? 6 : 12} key={`col-${index}`}>
                  {input.tooltip && input.label && (
                    <>
                      <div className="wfp--label">
                        {input.label}
                      </div>
                      <Tooltip
                        className={style.tooltip}
                        content={input.tooltip}
                        createRefWrapper
                        modifiers={[]}
                        placement="top"
                        trigger="click"
                      >
                        <Icon width="20" className={style.infoIcon} icon={iconInfo}/>
                      </Tooltip>
                    </>
                  )
                  }
                  <Controller
                    control={control}
                    render={({field, fieldState}) => (
                      renderComponent({...input, field, fieldState, label: input.tooltip ? "" : input.label})
                    )}
                    name={input.id}
                    id={input.id}
                    rules={input.rules}
                  />
                </Col>
              </Row>
            );
          })
        }
      </Grid>
    </>
  );
};

InfoHubContentTypeStep.propTypes = {
  methods: PropTypes.object,
  getErrors: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  contentTypes: PropTypes.array,
  topics: PropTypes.array,
  aggregators: PropTypes.array,
  isCopy: PropTypes.bool,
};

export default withTranslation()(InfoHubContentTypeStep);
